<div class="row">


    <div *ngIf="selfDoctor!='1'" class="row"
        style="background-color: #fff; width: 27%; padding: 16px; box-shadow: 0 0 8px #ccc; margin-left: 24px; border-radius: 6px;">
        <div class="col-lg-12 inventoryDetails">
            <div class="row" style="margin-top: -4px;">
                <div class="col-lg-12">
                    <div class="row appointmentHead">
                        <div class="col-12 pl-2 ml-1">
                            <div class="inventory-outer fs-18 mb-0 pb-0">
                                <div class="inventory-outer  pl-1 fs-18 mb-0 pb-0">
                                    <div class="appointment float-right searchIcon active"
                                        style="width: 100%; margin-right: 6px; border-radius: 4px; border-color: #cccc; background-color: #F3F6FA;">
                                        <div class="form-group searchField pull-left"
                                            style="width: 85%; background-color: #F3F6FA;">
                                            <input type="text" placeholder="Search Doctors by Name or Phone number"
                                                class="form-control" #searchInput [(ngModel)]="searchString"
                                                (keyup)="searchDoctor()"
                                                style="font-size: 15px; border-radius: 4px !important; border-right: 0px solid #000 !important; padding: 8px !important; background-color: #F3F6FA !important;" />
                                            <a type="button" (click)="closeSearch()"
                                                style="padding-left: 10px; padding-right: 10px;font-size: 34px; top: -6px;">×</a>
                                        </div>
                                        <div class="searchbtn" style="width: 15%;">
                                            <button (click)="searchDoctor()"><img src="assets/images/searchIcon.svg"
                                                    alt="" />
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div
                                style="background-color: #E9ECEF; padding:20px !important;margin-top: 50px; margin-right: 5px;">
                                <span style="padding: 20px; font-size: 20px;">
                                    <!-- section to show selected doctors {{selecteddocnamesstring}} -->
                                    {{selecteddoctorsnames}}
                                </span>
                            </div>
                            <div class="todaysAppointments notFound border-0"
                                *ngIf="recentlyDoctors && recentlyDoctors.length == 0" style="display: flex; justify-content: center;
                            align-items: center;
                            height: 90vh;font-size: 18px;font-family: mukta;">No Doctor found
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12" style="margin-top: -7px;">
                    <div class="mt-3 pl-1 no-shadow inventory-list">
                        <div class="appointments recentlyDoctors pr-4">
                            <ul>
                                <a *ngFor="let doctor of recentlyDoctors;">
                                    <li  class="clearfix" style=" background-color: #f3fefb; ">
                                        <a >
                                            <figure *ngIf="doctor.gender=='male'" (click)="getDoctorData(doctor)" style="text-align: center;">
                                                <img height="45px" width="45px"
                                                    style="border: solid 1px #ccc;border-radius: 81px; margin-top: 13px;"
                                                    *ngIf="doctor.profile_photo"
                                                    src="{{profileImagePath}}{{doctor.profile_photo}}" alt="" />
                                                <img height="45px" width="45px"
                                                    style="border: solid 1px #ccc;border-radius: 81px; margin-top: 0px;"
                                                    *ngIf="!doctor.profile_photo" src="assets/images/doctor_male.png"
                                                    alt="" />
                                            </figure>
                                            <figure *ngIf="doctor.gender=='female'" (click)="getDoctorData(doctor)">
                                                <img height="45px" width="45px"
                                                    style="border: solid 1px #ccc;border-radius: 81px;"
                                                    *ngIf="doctor.profile_photo"
                                                    src="{{profileImagePath}}{{doctor.profile_photo}}" alt="" />
                                                <img height="45px" width="45px"
                                                    style="border: solid 1px #ccc;border-radius: 81px; text-align: center;"
                                                    *ngIf="!doctor.profile_photo" src="assets/images/doctor_female.png"
                                                    alt="" />
                                            </figure>
                                            <figcaption style="padding-left: 0px; width: 60%; margin:14px 0px;"
                                                (click)="docRecordCount(doctor.id)">
                                                <span class="capitalize"
                                                    style="font-size: 17px; color: #333; font-family: 'Mukta', sans-serif;">{{doctor.first_name}}</span>
                                                <span class="capitalize"
                                                    style="font-size: 17px; color: #333; font-family: 'Mukta', sans-serif;">
                                                    {{doctor.middle_name}}</span>
                                                <span class="capitalize"
                                                    style="font-size: 17px; color: #333; font-family: 'Mukta', sans-serif;">
                                                    {{doctor.last_name}}</span>
                                                <span style="font-size: 15px; color: grey; margin-top: -4px;"
                                                    class="phone_number"><span>+{{doctor.phone_number | slice:0:2 }}
                                                        {{doctor.phone_number | slice:2}} </span>
                                                </span>
                                            </figcaption>
                                            <figure class="doctorCheckbox">
                                                <span>
                                                    <span *ngIf="checks">
                                                        <input type="checkbox" class="docCheckbox"
                                                            (change)="onCheckboxChange($event,doctor.id, doctor.first_name + ' ' + doctor.last_name, doctor);"
                                                            [checked]='false'>
                                                    </span>
                                                    <span *ngIf="!checks">
                                                        <input type="checkbox" class="docCheckbox"
                                                            (change)="onCheckboxChange($event,doctor.id, doctor.first_name + ' ' + doctor.last_name, doctor);"
                                                            [checked]='doctor.checks'>
                                                    </span>
                                                </span>
                                            </figure>
                                        </a>
                                    </li>
                                </a>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row"
        style="background-color: #fff; width: 68%; padding: 16px; box-shadow: 0 0 8px #ccc; margin-left: 24px; border-radius: 6px;"
        [ngStyle]="{'width' : selfDoctor!='1' ? '68%' : '100%'}">

        <div class="row">
            <div class="col-lg-12">
                <div class="row pr-3 ml-0 pb-3">
                    <div class="scheduleandAddNew-outer">
                        <div class="form-group animateLabel calendarIcon mb-0 pt-0 calender">
                            <div class="appointment float-right searchIcon active"
                                style="width:150px;height:44px; border-radius: 4px !important; border-color: #cccc;">
                                <input [(ngModel)]="count_from_startdate" ngbDatepicker #sdfrom="ngbDatepicker"
                                    type="text" class="form-control" placeholder="DD/MM/YYYY" name="startdate" (ngModelChange)="getStartDate(count_from_startdate)"
                                    style="font-size: 15px; padding: 8px !important; border-right: 0px solid #ccc !important"
                                    readonly>
                                <img (click)="sdfrom.toggle()" style="padding-right: 10px;" class="calendarbg"
                                    src="assets/images/calendarIcon.svg" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="scheduleandAddNew-outer">
                        <div class="form-group animateLabel calendarIcon mb-0 pt-0 calender">
                            <div class="appointment float-right searchIcon active"
                                style="width:150px;height:44px; border-radius: 4px !important; border-color: #cccc;">
                                <input [(ngModel)]="count_to_endtdate" ngbDatepicker #edfrom="ngbDatepicker" type="text"
                                    class="form-control" placeholder="DD/MM/YYYY" name="enddate" (ngModelChange)="getEndDate(count_to_endtdate)"
                                    style="font-size: 15px; padding: 8px !important; border-right: 0px solid #ccc !important"
                                    readonly>
                                <img (click)="edfrom.toggle()" style="padding-right: 10px;" class="calendarbg"
                                    src="assets/images/calendarIcon.svg" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="scheduleandAddNew-outer">
                        <div class="form-group animateLabel calendarIcon mb-0 pt-0 calender">
                            <button class="btn btn-info pr-4 pl-4"
                                style="background-color:#4687BF;font-size: 18px;padding:6px;"
                                (click)="dateRangeCount(count_from_startdate,count_to_endtdate,true);doctorPatLabReportsRecords();">Submit</button>
                        </div>
                    </div>

                    <div class=" mb-0 pt-0 calender pl-2">
                        <button class="btn btn-info pl-4 pr-4"
                            style="background-color:#4687BF;font-size: 18px;padding:6px;"
                            (click)="clearCount();closeSearch();">Clear</button>
                    </div>


                    <div class=" mb-0 pt-0 pl-2" *ngIf="selfDoctor!='1'">
                        <button class="btn btn-info pr-4 pl-4"
                            style="background-color:#4687BF;font-size: 18px;color:#fff;padding:6px;"
                            (click)="clearCount();reload();">Reset</button>
                    </div>

                    <div class=" mb-0 pt-0 pl-2" *ngIf="selfDoctor!='1'">
                        <select (change)="reportCategory($event.target.value)"
                            style="background-color:#229090;font-size: 18px;color:#fff;padding: 9px;border-radius: 4px;border-color:#229090;border-style: solid;">
                            <option style="color:#fff !important;" selected disabled hidden>Select Category</option>
                            <option *ngFor="let cat of reportCategories" style="color:#fff !important;"
                                [ngValue]="cat.id">{{cat.categoryName}}</option>
                        </select>
                    </div>
                    <div class=" mb-0 pt-0 pl-2" *ngIf="selfDoctor!='1'">
                        <select (change)="selectSalesPerson($event.target.value)"
                            style="background-color:#229090;font-size: 18px;color:#fff;padding: 9px;border-radius: 4px;border-color:#229090;border-style: solid;">
                            <option style="color:#fff !important;" selected disabled hidden>Sales Person</option>
                            <option *ngFor="let salesperson of salesPersons" style="color:#fff !important;"
                                [value]="salesperson.id">{{salesperson.name}}</option>
                        </select>
                    </div>

                    <div class=" mb-0 pt-0 pl-2" style="align-self: center; font-weight: bold; font-size: 16px;">Joining Date: {{doctorJoiningDate}} </div>
                </div>
            </div>
        </div>
        <div style="width:100%; height:90%;">
            <div class="row">
                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3" (click)="doctorpatientsRecords()">
                        <!-- <div class="card border-info shadow text-info p-3 my-card" *ngIf="selfDoctor!='1'">
                            <span class="fa fa-user" aria-hidden="true"
                                style="font-size: 30px; padding-left: 4px;"></span>
                        </div> -->
                        <div class="text-info text-center mt-1">
                            <h5 style="font-size: 14px;">Patients</h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>{{patientCount}}</h2>
                        </div>
                        <ng-template #contentPatRecords let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title">Total Doctors Patients</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" style="overflow-y: scroll; height:600px;">
                                        <div style="padding-bottom: 10px;">
                                            <a style="cursor: pointer"
                                                (click)="DownloadExcel('Doctor_Patients_Records.xlsx')">
                                                <button class="btn btn-outline-success">Download Excel</button>
                                            </a>
                                        </div>
                                        <table class="table table-hover" id="doctor-patients-records-table">
                                            <thead>
                                                <tr style="padding:10px;">
                                                    <th>Patient Name</th>
                                                    <th>Phone</th>
                                                    <th>Created At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let docPatientRecords of selctedDoctPatientsRecords.totPatientRecords">
                                                    <td>{{ docPatientRecords.patName }}</td>
                                                    <td>{{ docPatientRecords.phone_number }}</td>
                                                    <td>{{ docPatientRecords.createdAt | date : "d-MM-y | 'Time:'
                                                        hh:mm:ss a" }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                        <button class="btn btn-lg btn-outline-primary" (click)="open(contentPatRecords)">Show
                            Data</button>
                        <!-- <br> -->
                        <div style="padding-top: 20px;">
                            <div class="meter">
                                <span style="width:70%;"><span class="progress"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3" >
                        <!-- <div class="card border-info shadow text-info p-3 my-card" *ngIf="selfDoctor!='1'"><img
                                src="assets/images/docadmin_prescription_icon.png" alt="" height="30px" width="30px" />
                        </div> -->
                        <div class="text-info text-center mt-1">
                            <h5 style="font-size: 14px;">Prescriptions</h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>{{selctedDoctPatPrescriptionsRecords.length}}</h2>
                        </div>
                        <ng-template #contentPatPrescriptionsRecords let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title1">Total Prescriptions Records</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" style="overflow-y: scroll; height:600px;">
                                        <div style="padding-bottom: 10px;">
                                            <a style="cursor: pointer"
                                                (click)="DownloadExcel('Prescriptions_Records.xlsx')">
                                                <button class="btn btn-outline-success">Download Excel</button>
                                            </a>
                                        </div>
                                        <table class="table table-hover" id="doctor-patients-records-table">
                                            <thead>
                                                <tr style="padding:10px;">
                                                    <th>Patient Name</th>
                                                    <th>Phone</th>
                                                    <th>Visit Date</th>
                                                    <th>Url</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let docPatPrescriptionsRecords of selctedDoctPatPrescriptionsRecords">
                                                    <td>{{ docPatPrescriptionsRecords.patName }}</td>
                                                    <td>{{ docPatPrescriptionsRecords.phone_number }}</td>
                                                    <td>{{ docPatPrescriptionsRecords.visit_date | date : "d-MM-y |
                                                        'Time:' hh:mm:ss a" }}</td>

                                                        <td style="cursor: pointer;" (click)="openPdfUrl(docPatPrescriptionsRecords.prescriptionPDFURL)" >{{ docPatPrescriptionsRecords.prescriptionPDFURL ? "VIEW" : 'NA' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                        <button class="btn btn-lg btn-outline-primary"
                            (click)="open(contentPatPrescriptionsRecords)">Show Data</button>
                        <div style="padding-top: 20px;">
                            <div class="meter">
                                <span style="width:90%;"><span class="progress"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3" (click)="doctorPatAppointmentsRecords()">
                        <!-- <div class="card border-info shadow text-info p-3 my-card" *ngIf="selfDoctor!='1'"><span
                                class="fa fa-calendar" aria-hidden="true" style="font-size: 30px;"></span></div> -->
                        <div class="text-info text-center mt-1">
                            <h5 style="font-size: 14px;">Appointments</h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>{{appointmentCount}}</h2>
                        </div>
                        <ng-template #contentPatAppointmentsRecords let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title1">Total Appointments Records</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" style="overflow-y: scroll; height:600px;">
                                        <div style="padding-bottom: 10px;">
                                            <a style="cursor: pointer"
                                                (click)="DownloadExcel('Appointment_Records.xlsx')">
                                                <button class="btn btn-outline-success">Download Excel</button>
                                            </a>
                                        </div>
                                        <table class="table table-hover" id="doctor-patients-records-table">
                                            <thead>
                                                <tr style="padding:10px;">
                                                    <th>Patient Name</th>
                                                    <th>Phone</th>
                                                    <th>Appointment Created</th>
                                                    <th>Visit Date</th>
                                                    <th>Booked From</th>
                                                    <th>Pdf Url</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let docAppointmentsRecords of selctedDoctPatAppointmentsRecords">
                                                    <td>{{ docAppointmentsRecords.patName }}</td>
                                                    <td>{{ docAppointmentsRecords.phone_number }}</td>
                                                    <td>{{ docAppointmentsRecords.createdAt | date : "d-MM-y | 'Time:'
                                                        hh:mm:ss a" }}</td>
                                                    <td>{{ docAppointmentsRecords.visit_date | date : "d-MM-y | 'Time:'
                                                        hh:mm:ss a" }}</td>
                                                    <td>{{ docAppointmentsRecords.bookedFrom }}</td>
                                                    <td style="cursor: pointer;" (click)="openPdfUrl(docAppointmentsRecords.prescriptionPDFURL)" >{{ docAppointmentsRecords.prescriptionPDFURL ? "VIEW" : 'NA' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                        <button class="btn btn-lg btn-outline-primary"
                            (click)="open(contentPatAppointmentsRecords)">Show Data</button>
                        <div style="padding-top: 20px;">
                            <div class="meter">
                                <span style="width:90%;"><span class="progress"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3" >
                        <!-- <div class="card border-info shadow text-info p-3 my-card" *ngIf="selfDoctor!='1'"><span
                                class="fa fa-file" aria-hidden="true" style="font-size: 30px;"></span></div> -->
                        <div class="text-info text-center mt-1">
                            <h5 style="font-size: 14px;">Lab Requisitions</h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>{{selctedDoctPatRequisitionsRecords.length}}</h2>
                        </div>
                        <ng-template #contentPatRequisitionsRecords let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title1">Total Lab Requisitions Records</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" style="overflow-y: scroll; height:600px;">
                                        <div style="padding-bottom: 10px;">
                                            <a style="cursor: pointer"
                                                (click)="DownloadExcel('Lab_Requisitions_Records.xlsx')">
                                                <button class="btn btn-outline-success">Download Excel</button>
                                            </a>
                                        </div>
                                        <table class="table table-hover" id="doctor-patients-records-table">
                                            <thead>
                                                <tr style="padding:10px;">
                                                    <th>Patient Name</th>
                                                    <th>Phone</th>
                                                    <th>Requisition Created</th>
                                                    <th>Tets Name</th>
                                                    <th>Shared Lab Name</th>
                                                    <th>Url</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let docRequisitionsRecords of selctedDoctPatRequisitionsRecords">
                                                    <td>{{ docRequisitionsRecords.patName }}</td>
                                                    <td>{{ docRequisitionsRecords.phone_number }}</td>
                                                    <td>{{ docRequisitionsRecords.createdAt | date : "d-MM-y | 'Time:'
                                                        hh:mm:ss a" }}</td>
                                                    <td>{{ docRequisitionsRecords.tests }}</td>
                                                    <td *ngIf="docRequisitionsRecords.sharedtolab">{{ docRequisitionsRecords.sharedtolab.labName }}</td>
                                                    <td *ngIf="!docRequisitionsRecords.sharedtolab"> NA</td>
                                                    <td style="cursor: pointer;" (click)="openPdfUrl(docRequisitionsRecords.url)" >{{ docRequisitionsRecords.url ? "VIEW" : 'NA' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                        <button class="btn btn-lg btn-outline-primary"
                            (click)="open(contentPatRequisitionsRecords)">Show Data</button>
                        <div style="padding-top: 20px;">
                            <div class="meter">
                                <span style="width:50%;"><span class="progress"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="padding-top: 20px;">
                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3" >
                        <!-- <div class="card border-info shadow text-info p-3 my-card" *ngIf="selfDoctor!='1'">
                            <span class="fa fa-file-text" aria-hidden="true"
                                style="font-size: 30px;padding-left:2px;"></span>
                        </div> -->
                        <div class="text-info text-center mt-1">
                            <h5 style="font-size: 14px;">Lab Reports</h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>{{selctedDoctPatLabReportsRecords.length}}</h2>
                        </div>
                        <ng-template #contentPatLabReportsRecords let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title1">Total Lab Reports Records</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" style="overflow-y: scroll; height:600px;">
                                        <div style="padding-bottom: 10px;">
                                            <a style="cursor: pointer"
                                                (click)="DownloadExcel('Lab_Reports_Records.xlsx')">
                                                <button class="btn btn-outline-success">Download Excel</button>
                                            </a>
                                        </div>
                                        <table class="table table-hover" id="doctor-patients-records-table">
                                            <thead>
                                                <tr style="padding:10px;">
                                                    <th>Patient Name</th>
                                                    <th>Phone</th>
                                                    <th>Lab Report Created</th>
                                                    <th>Uploaded By</th>
                                                    <th>Url</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let docLabReportsRecords of selctedDoctPatLabReportsRecords">
                                                    <td>{{ docLabReportsRecords.patName }}</td>
                                                    <td>{{ docLabReportsRecords.phone_number }}</td>
                                                    <td>{{ docLabReportsRecords.documentDate | date : "d-MM-y | 'Time:'
                                                        hh:mm:ss a" }}</td>
                                                    <td>{{ docLabReportsRecords.uploadedBy }}</td>
                                                    <!-- <td>{{ docLabReportsRecords.url }}</td> -->
                                                    <td style="cursor: pointer;" (click)="openPdfUrl(docLabReportsRecords.url)" >{{ docLabReportsRecords.url ? "VIEW" : 'NA' }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                        <button class="btn btn-lg btn-outline-primary" (click)="open(contentPatLabReportsRecords)">Show
                            Data</button>
                        <div style="padding-top: 20px;">
                            <div class="meter">
                                <span style="width:50%;"><span class="progress"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3" (click)="doctorCustomersRecords()">
                        <!-- <div class="card border-info shadow text-info p-3 my-card" *ngIf="selfDoctor!='1'"><img
                                src="assets/images/docadmin_health_icon.png" alt="" height="30px" width="30px" /></div> -->
                        <div class="text-info text-center mt-1">
                            <h5 style="font-size: 14px;">Health App Installed. <i class="text-success fa fa-check"
                                    aria-hidden="true"></i></h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>Cust:{{healthAppCustomers}} (Pat:{{healthAppPatients}})  (HL Inst:{{totDocHealtAppInstaCount}})</h2>
                        </div>
                        <ng-template #contentDocCustomersRecords let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title1">Total Doctors Customers Health App
                                    Installed </h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" style="overflow-y: scroll; height:600px;">
                                        <div style="padding-bottom: 10px;">
                                            <a style="cursor: pointer"
                                                (click)="DownloadExcel('Health_Installed_Records.xlsx')">
                                                <button class="btn btn-outline-success">Download Excel</button>
                                            </a>
                                        </div>
                                        <table class="table table-hover" id="doctor-patients-records-table">
                                            <thead>
                                                <tr style="padding:10px;">
                                                    <th>Patient Name</th>
                                                    <th>Phone</th>
                                                    <th>Customer Created</th>
                                                    <th>Device Info</th>
                                                    <th>App Version</th>
                                                    <th>Health App cr date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let contentDocCustomersRecords of selctedDoctCostumersRecords">
                                                    <td>{{ contentDocCustomersRecords.patName }}</td>
                                                    <td>{{ contentDocCustomersRecords.phone_number }}</td>
                                                    <td>{{ contentDocCustomersRecords.createdAt | date : "d-MM-y |
                                                        'Time:' hh:mm:ss a" }}</td>
                                                    <td>{{ contentDocCustomersRecords.deviceInfo }}</td>
                                                    <td>{{ contentDocCustomersRecords.appversion }}</td>
                                                    <td>{{ contentDocCustomersRecords.dateupdated | date : "d-MM-y |
                                                        'Time:' hh:mm:ss a" }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                        <button class="btn btn-lg btn-outline-primary" (click)="open(contentDocCustomersRecords)">Show
                            Data</button>
                        <div style="padding-top: 20px;">
                            <div class="meter">
                                <span style="width:70%;"><span class="progress"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3" (click)="patHealthNotInstalledRecords()">
                        <!-- <div class="card border-info shadow text-info p-3 my-card" *ngIf="selfDoctor!='1'"><img
                                src="assets/images/docadmin_health_icon.png" alt="" height="30px" width="30px" /></div> -->
                        <div class="text-info text-center mt-1">
                            <h5 style="font-size: 14px;">Health App Not Installed. <i
                                    class="text-danger fa fa-window-close" aria-hidden="true"></i></h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>{{healthAppNotInstalledCustomers}}</h2>
                        </div>
                        <ng-template #contentHealthNotInstalledRecords let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title1">Total Doctors Customers Health App Not
                                    Installed</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" style="overflow-y: scroll; height:600px;">
                                        <div style="padding-bottom: 10px;">
                                            <a style="cursor: pointer"
                                                (click)="DownloadExcel('Health_Not_Installed__Records.xlsx')">
                                                <button class="btn btn-outline-success">Download Excel</button>
                                            </a>
                                        </div>
                                        <table class="table table-hover" id="doctor-patients-records-table">
                                            <thead>
                                                <tr style="padding:10px;">
                                                    <th>Patient Name</th>
                                                    <th>Phone</th>
                                                    <th>Created</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let DocPatHealthNotinstalledRecords of selctedDoctPatHealthNotInstalledRecords">
                                                    <td>{{ DocPatHealthNotinstalledRecords.patName }}</td>
                                                    <td>{{ DocPatHealthNotinstalledRecords.phone_number }}</td>
                                                    <td>{{ DocPatHealthNotinstalledRecords.createdAt | date : "d-MM-y |
                                                        'Time:' hh:mm:ss a" }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                        <button class="btn btn-lg btn-outline-primary"
                            (click)="open(contentHealthNotInstalledRecords)">Show Data</button>
                        <div style="padding-top: 20px;">
                            <div class="meter">
                                <span style="width:30%;"><span class="progress"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3" (click)="patGivenVaccRecords()">
                        <!-- <div class="card border-info shadow text-info p-3 my-card" *ngIf="selfDoctor!='1'"><img
                                src="assets/images/docadmin_syringe_icon.png" alt="" height="30px" width="30px" /></div> -->
                        <div class="text-info text-center mt-1">
                            <h5 style="font-size: 14px;">Given Vaccination</h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>{{totalPatTakenVaccine}}</h2>
                        </div>
                        <ng-template #contentPatGivenVaccRecords let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title1">Total Doctors Patient Given Vaccination
                                    Records</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" style="overflow-y: scroll; height:600px;">
                                        <div style="padding-bottom: 10px;">
                                            <a style="cursor: pointer"
                                                (click)="DownloadExcel('Given_Vaccination_Records.xlsx')">
                                                <button class="btn btn-outline-success">Download Excel</button>
                                            </a>
                                        </div>
                                        <table class="table table-hover" id="doctor-patients-records-table">
                                            <thead>
                                                <tr style="padding:10px;">
                                                    <th>Patient Name</th>
                                                    <th>D.O.B</th>
                                                    <th>Phone Number</th>
                                                    <th>Vaccine Name</th>
                                                    <th>Vaccine Brand</th>
                                                    <th>Due Date</th>
                                                    <th>Given Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let DocPatGivenVaccRecords of selctedDoctPatGivaenVaccRecords">
                                                     <td>{{ DocPatGivenVaccRecords.Name }}</td>
                                                    <td>{{ DocPatGivenVaccRecords.dob | date : "d-MM-y" }}</td>
                                                    <td>{{ DocPatGivenVaccRecords.phone_number }}</td>
                                                    <td>{{ DocPatGivenVaccRecords.name }}</td>
                                                    <td>{{ DocPatGivenVaccRecords.brandvaccine }}</td>
                                                    <td>{{ DocPatGivenVaccRecords.Due_date | date : "d-MM-y" }}</td>
                                                    <td>{{ DocPatGivenVaccRecords.taken_on | date : "d-MM-y" }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                        <button class="btn btn-lg btn-outline-primary" (click)="open(contentPatGivenVaccRecords)">Show
                            Data</button>
                        <div style="padding-top: 20px;">
                            <div class="meter">
                                <span style="width:40%;"><span class="progress"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row" style="padding-top: 20px;">
                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3" (click)="patNotGivenVaccRecords()">
                        <!-- <div class="card border-info shadow text-info p-3 my-card" *ngIf="selfDoctor!='1'">
                            <img src="assets/images/docadmin_pending_vaccine_icon.png" alt="" height="30px"
                                width="30px" />
                        </div> -->
                        <div class="text-info text-center mt-1">
                            <h5 style="font-size: 14px;">Pending Vaccine</h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>{{totalPatPendingVaccine}}</h2>
                        </div>
                        <ng-template #contentPatNotGivenVaccRecords let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title1">Total Doctors Patient Not Given
                                    Vaccination Records</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" style="overflow-y: scroll; height:600px;">
                                        <div style="padding-bottom: 10px;">
                                            <a style="cursor: pointer"
                                                (click)="DownloadExcel('Not_Given_Vaccination_Records.xlsx')">
                                                <button class="btn btn-outline-success">Download Excel</button>
                                            </a>
                                        </div>
                                        <table class="table table-hover" id="doctor-patients-records-table">
                                            <thead>
                                                <tr style="padding:10px;">
                                                    <th>Patient Name</th>
                                                    <th>D.O.B</th>
                                                    <th>Phone Number</th>
                                                    <th>Vaccie Name</th>
                                                    <th>Due Date</th>
                                                    <th>Age</th>
                                                    <th>Health App</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let DocPatNotGivenVaccRecords of selctedDoctPatNotGivaenVaccRecords">
                                                    <td>{{ DocPatNotGivenVaccRecords.Name }}</td>
                                                    <td>{{ DocPatNotGivenVaccRecords.dob | date : "d-MM-y" }}</td>
                                                    <td>{{ DocPatNotGivenVaccRecords.PhoneNumber }}</td>
                                                    <td>{{ DocPatNotGivenVaccRecords.VaccineName }}</td>
                                                    <td>{{ DocPatNotGivenVaccRecords.DueDate | date : "d-MM-y" }}</td>
                                                    <td>{{DocPatNotGivenVaccRecords.age}}</td>
                                                    <td>
                                                        <ng-container *ngIf="DocPatNotGivenVaccRecords.fcmtoken; else noToken">
                                                            Yes
                                                          </ng-container>
                                                          <ng-template #noToken>
                                                            No
                                                          </ng-template>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                        <button class="btn btn-lg btn-outline-primary"
                            (click)="open(contentPatNotGivenVaccRecords)">Show Data</button>
                        <div style="padding-top: 20px;">
                            <div class="meter">
                                <span style="width:60%;"><span class="progress"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3" (click)="patPushNotificationsRecords()">
                        <!-- <div class="card border-info shadow text-info p-3 my-card" *ngIf="selfDoctor!='1'"><span
                                class="fa fa-bell" aria-hidden="true" style="font-size: 30px;"></span></div> -->
                        <div class="text-info text-center mt-1">
                            <h5 style="font-size: 14px;">Push Notifications</h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>{{totalSendNotifications}}</h2>
                        </div>
                        <ng-template #DocPatPushNotificationsRecords let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title1">Total Push Notifications Shared</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" style="overflow-y: scroll; height:600px;">
                                        <div style="padding-bottom: 10px;">
                                            <a style="cursor: pointer"
                                                (click)="DownloadExcel('Push_Notifications_Records.xlsx')">
                                                <button class="btn btn-outline-success">Download Excel</button>
                                            </a>
                                        </div>
                                        <table class="table table-hover" id="doctor-patients-records-table">
                                            <thead>
                                                <tr style="padding:10px;">
                                                    <th>Patient Name</th>
                                                    <th>Phone</th>
                                                    <th>Notification Text</th>
                                                    <th>Notification Title</th>
                                                    <th>Shared Date</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let docPatPushNotificationsRecords of selctedDoctPatPushNotificationsRecords">
                                                    <td>{{ docPatPushNotificationsRecords.patName }}</td>
                                                    <td>{{ docPatPushNotificationsRecords.phone_number }}</td>
                                                    <td>{{ docPatPushNotificationsRecords.notificationtext }}</td>
                                                    <td>{{ docPatPushNotificationsRecords.notificationtitle }}</td>
                                                    <td>{{ docPatPushNotificationsRecords.date | date : "d-MM-y |
                                                        'Time:' hh:mm:ss a" }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                        <button class="btn btn-lg btn-outline-primary"
                            (click)="open(DocPatPushNotificationsRecords)">Show Data</button>
                        <div style="padding-top: 20px;">
                            <div class="meter">
                                <span style="width:80%;"><span class="progress"></span></span>
                            </div>
                        </div>
                    </div>
                </div>


                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3">
                        <!-- <div class="card border-info shadow text-info p-3 my-card" *ngIf="selfDoctor!='1'"><span
                                class="fa fa-bell" aria-hidden="true" style="font-size: 30px;"></span></div> -->
                        <div class="text-info text-center mt-1">
                            <h5 style="font-size: 14px;">Pending Vaccines List</h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>{{totalPendingVaccineForSevenDaysLength}}</h2>
                        </div>
                        <ng-template #totalPendingVaccineForSevenDaysRecords let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title1">Pending Vaccines List</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" style="overflow-y: scroll; height:600px;">
                                        <div style="padding-bottom: 10px;">
                                            <a style="cursor: pointer"
                                                (click)="DownloadExcel('Pending_Vaccines_On_7th_Day.xlsx')">
                                                <button class="btn btn-outline-success">Download Excel</button>
                                            </a>
                                        </div>
                                        <table class="table table-hover" id="doctor-patients-records-table">
                                            <thead>
                                                <tr style="padding:10px;">
                                                    <th>Patient Name</th>
                                                    <th>Phone</th>
                                                    <th>Vaccine Name</th>
                                                    <th>Due Date</th>
                                                    <th>Doctor Name</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let element of totalPendingVaccineForSevenDays">
                                                    <td>{{ element.ChildName }}</td>
                                                    <td>{{ element.PhoneNumber }}</td>
                                                    <td>{{ element.VaccineName }}</td>
                                                    <td>{{ element.DueDate }}</td>
                                                    <td>{{ element.doctorName }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                        <button class="btn btn-lg btn-outline-primary"
                            (click)="open(totalPendingVaccineForSevenDaysRecords)">Show Data</button>
                        <div style="padding-top: 20px;">
                            <div class="meter">
                                <span style="width:80%;"><span class="progress"></span></span>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3" (click)="getMedicalCondition()">
                        <!-- <div class="card border-info shadow text-info p-3 my-card" *ngIf="selfDoctor!='1'">
                            <span class="fa fa-user" aria-hidden="true"
                                style="font-size: 30px; padding-left: 4px;"></span>
                        </div> -->
                        <div class="text-info text-center mt-1">
                            <h5 style="font-size: 14px;">Medical Conditions</h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>{{medicalConditionCount}}</h2>
                        </div>
                        <ng-template #contentPatMedicalRecords let-modal>
                            <div class="modal-header">
                                <h4 class="modal-title" id="modal-basic-title">Total Doctors Patients</h4>
                                <button type="button" class="close" aria-label="Close"
                                    (click)="modal.dismiss('Cross click')">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div class="modal-body">
                                <form>
                                    <div class="form-group" style="overflow-y: scroll; height:600px;">
                                        <div style="padding-bottom: 10px;">
                                            <a style="cursor: pointer"
                                                (click)="DownloadExcel('Doctor_Patients_Records.xlsx')">
                                                <button class="btn btn-outline-success">Download Excel</button>
                                            </a>
                                        </div>
                                        <table class="table table-hover" id="doctor-patients-records-table">
                                            <thead>
                                                <tr style="padding:10px;">
                                                    <th>Patient Name</th>
                                                    <th>Medical Conditions</th>
                                                    <th>Allergies</th>
                                                    <th>Created At</th>
                                                    <th>Updated At</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr
                                                    *ngFor="let docPatientRecords of selctedDoctPatientsRecords.allMedicalConditions
                                                    ">
                                                    <td>{{ docPatientRecords.CName }}</td>
                                                    <td >{{ docPatientRecords.affected_comment ? docPatientRecords.affected_comment:'NA' }}</td>
                                                    <td>{{ docPatientRecords.allergies }}</td>
                                                    <td>{{ docPatientRecords.createdAt | date : "d-MM-y | 'Time:'
                                                        hh:mm:ss a" }}</td>
                                                        <td>{{ docPatientRecords.updatedAt | date : "d-MM-y | 'Time:'
                                                            hh:mm:ss a" }}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </form>
                            </div>
                        </ng-template>
                        <button class="btn btn-lg btn-outline-primary" (click)="open(contentPatMedicalRecords)">Show
                            Data</button>
                        <!-- <br> -->
                        <div style="padding-top: 20px;">
                            <div class="meter">
                                <span style="width:70%;"><span class="progress"></span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3">
                        <div class="card border-info shadow text-info p-3 my-card"><span class="fa fa-stethoscope"
                                aria-hidden="true" style="font-size: 30px;padding-left: 4px;"></span></div>
                        <div class="text-info text-center mt-3" style="padding-top:20px;">
                            <h5>Uninstalled Health App</h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>0</h2>
                        </div>
                    </div>
                </div>
                <div class="col-md-3">
                    <div class="card border-info mx-sm-1 p-3">
                        <div class="card border-info shadow text-info p-3 my-card"><span class="fa fa-file"
                                aria-hidden="true" style="font-size: 30px;padding-left: 4px;"></span></div>
                        <div class="text-info text-center mt-3" style="padding-top:20px;">
                            <h5>Given Vaccination</h5>
                        </div>
                        <div class="text-success text-center mt-2">
                            <h2>0</h2>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>