

<div class="form" style="margin-left: 5px;">
  
    <div class="form-item">
      <input type="text" class="form-control" [(ngModel)]="searchString" [value]="field"   [placeholder]="placeholder" [disabled]="disabled"
      (keyup)="inputText(label)"[maxlength]="maxLength" (keypress)="numberOnly($event)" (ngModelChange)="onTextChange($event)"style="border-radius: 6px;"required >
      <label [attr.for]="" >{{label}}</label>
    </div>
   
    
  </div>
