import { Injectable, EventEmitter, Output } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";


@Injectable()


export class refreshService {
  private messageSource = new BehaviorSubject<any>('defaultMessage');
  currentMessage = this.messageSource.asObservable();
  public AppointmentRefreshEvent: EventEmitter<number> = new EventEmitter<number>();
  public ProfileRefreshEvent: EventEmitter<number> = new EventEmitter<number>();
  public VitalsRefreshEvent: EventEmitter<number> = new EventEmitter<number>();
  public OpenNewVitalsRefreshEvent: EventEmitter<number> = new EventEmitter<number>();
  public DocumentRefreshEvent: EventEmitter<number> = new EventEmitter<number>();
  public openCertificateModal: EventEmitter<number> = new EventEmitter<number>();
  public PatientRefreshEvent: EventEmitter<number> = new EventEmitter<number>();
  public VisitRefreshEvent: EventEmitter<number> = new EventEmitter<number>();
  // public DoctorData: EventEmitter<any> = new EventEmitter<any>();
  //@Output() public DoctorData= new EventEmitter<{}>();
  public shareReceipt: EventEmitter<number> = new EventEmitter<number>();
  private topInfoNavFunctionSource = new Subject<void>();
  openVitals$ = this.topInfoNavFunctionSource.asObservable();

  EventName_Rx: String = "Event_Rx";
  EventName_Appointment: String = "Event_Appointment";
  EventName_Visits: String = "Event_Visits";
  EventName_Overview: String = "Event_Overview";
  EventName_Requisitions: String = "Event_Requisitions"
  EventName_Reports: String = "Event_Reports"
  EventName_Immunization: String = "Event_Immunization"
  EventName_Milestone: String = "Event_Milestone"
  EventName_AllDocuments: String = "Event_AllDocuments"

  EventName_Referral: String = "Event_Referral"
  EventName_Certificate: String = "Event_Certificate"
  EventName_Receipt: String = "Event_Receipt"
  EventName_Notes: String = "Event_Notes"
  EventName_EditPatient: String = "Event_EditPatient"
  EventName_Upload: String = "Event_Upload"
  EventName_OverviewScreen:string="event_overview"
  EventName_RxScreen:string="event_Rx"
  EventName_Vitals:string="event_Vitals"
  EventName_appointmentVital:string="event_appointmentVitals"
  public Patient_History:EventEmitter<any>=new EventEmitter<any>();
  public Patient_RxUI:EventEmitter<any>=new EventEmitter<any>();
  constructor() { }

  appointrefreshed() {
    this.AppointmentRefreshEvent.emit(0);
  }
  profileRefershed(){
    this.ProfileRefreshEvent.emit(0);
  }
  vitalsRefreshed() {
    this.VitalsRefreshEvent.emit(0);
  }
 openNewVitals() {
    this.OpenNewVitalsRefreshEvent.emit(0);
  }
  documentRefershed(){
    this.DocumentRefreshEvent.emit(0);
  }
  openCertificate(){
    this.openCertificateModal.emit(0)
  }
  patientRefreshed(){
    this.PatientRefreshEvent.emit(0);
  }
  visitRefreshed(){
    this.VisitRefreshEvent.emit(0)
  }
  goToshareRecepit(){
    this.shareReceipt.emit(0);
  }
  // doctordata(d){
  //   this.DoctorData.emit(d);
  // }
 public topNav_Menu:EventEmitter<any>=new EventEmitter<any>();

  public PatTopNav_MenuSelected: EventEmitter<any> = new EventEmitter<any>();
  OnPatTopNav_MenuSelected(patientId: String, eventName: String) {
    this.PatTopNav_MenuSelected.emit({ PatientId: patientId, EventName: eventName });
  }

  allNavigation_Menu(eventName:string){
    this.topNav_Menu.emit({EventName:eventName})
  }
  patientHistory(evetName:any){
    this.Patient_History.emit(evetName)
  }
  switchRxUI(evetName:any){
    this.Patient_RxUI.emit(evetName)
  }
  setFlag(message){
    this.messageSource.next(message);
    console.log(message,'is passed msg for vitalsVisitId')

  }
  returnflag(){
    return this.currentMessage
  }
  triggerOpenVitalGraphFunction() {
    this.topInfoNavFunctionSource.next();
  }
}
