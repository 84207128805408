import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../../../services/admin.service'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery'
import { AuthService } from '../../../../services/auth.service';
@Component({
  selector: 'app-lab-edit',
  templateUrl: './lab-edit.component.html',
  styleUrls: ['./lab-edit.component.scss']
})
export class LabEditComponent implements OnInit {

  errors:any = {}
  lab:any = {}
  labId = null
  userId = null
  emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/
  constructor(private adminService: AdminService, private router: Router, private toaster: ToastrService, private authService: AuthService,  private route: ActivatedRoute) {
    this.route.params.subscribe(params => {
      this.labId = params.id
    })
    this.userId = this.authService.getAdminUser()
    if(this.labId){
      this.getLabDetails()
    }else{
      this.lab = {}
    }
  }

  ngOnInit() {

  }

  getLabDetails(){
    var req_param = {lab_id: this.labId}
    this.adminService.getLabDetails(req_param).subscribe(response =>{
      if(response.success){
        this.lab = response.lab
      }else{
        this.lab = {}
      }
    })
  }
  
  addLabelClass(e) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  

  removeLabelClass(e){
    if(e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }
  

  validateLab(){
    this.errors = {}
    this.lab.name = this.lab.name ? this.lab.name.trim() : null
    this.lab.email_id = this.lab.email_id ? this.lab.email_id.trim() : null
    if(!this.lab.name){
      this.errors['name'] = "Please enter name"
    }
    if(!this.lab.email_id){
      this.errors['email'] = "Please enter email id"
    }else{
      if(!this.emailRegex.test(this.lab.email_id))
        this.errors['email'] = "Please enter valid email id"
    }
    if(Object.keys(this.errors).length == 0) 
      return true
    else
      return false
  }

  addLab(){
    let valid = this.validateLab()
    if(valid){
      let params = {lab: this.lab, user_id: this.userId}
      this.adminService.updateLabs(params).subscribe(respose => {
        if(respose.success){
          this.toaster.success("Lab list has been updated successfully")
          this.router.navigate(['/admin/settings/labs'])
        }else{
         this.toaster.error(respose.error)
        }
      })
    }
  }

  goBack(){
    this.router.navigate(['/admin/settings/labs'])
  }
}
