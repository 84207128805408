import { Component, OnInit } from '@angular/core';
import { GlobalService } from '../../../services/global.service';


@Component({
  selector: 'app-remove-doctor-account',
  templateUrl: './remove-doctor-account.component.html',
  styleUrls: ['./remove-doctor-account.component.scss']
})
export class RemoveDoctorAccountComponent implements OnInit {

  checkValue: boolean = false;
  phoneNumber: number;

  constructor(private globalService: GlobalService) { }

  ngOnInit(): void {
  }

  onCheck(value: boolean): void {
    this.checkValue = value;
  }

  requestAccountRemoval(): void {
    console.log(this.checkValue, this.phoneNumber)
    if (this.phoneNumber?.toString().length == 10 && this.checkValue) {
      //api call

      let postData = {
        phoneNumber: `91${this.phoneNumber}`,
        userType: 'doctor'
      }

      this.globalService.removeAccount(postData).subscribe(response => {
        if (response) {
          console.log("Response", response);

          this.phoneNumber = null;
          
          alert(response.result);

        }
      }, (err) => {
        alert("Something went wrong, Please try again later");
        console.error(err)
      })
    }

  }

}
