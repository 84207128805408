<div style="margin-left: 5px;">
    <label class="fieldsetlable" [attr.for]="">{{label}}</label>
    <input type="text" class="form-control" [placeholder]="placeholder" name="name" [(ngModel)]="searchString" 
        (keyup)="inputText(label)" [maxlength]="maxLength"  (keypress)="numberOnly($event)" [disabled]="disabled"
        style="border-radius: 8px !important; color: #333333 !important; font-size: 20px !important;width:240px;font-weight: 700;" 
        type= {{keyboard_type}} (ngModelChange)="onTextChange($event)">
</div>

<!-- <div  style="margin-left: 5px;" class="form">
  
    <div class="form-item">
        <input type="text" class="form-control" [placeholder]="placeholder" name="name" [(ngModel)]="searchString" 
        (keyup)="inputText(label)" [maxlength]="maxLength"  (keypress)="numberOnly($event)" [disabled]="disabled"
        style="border-radius: 4px !important; color: #333333 !important; font-size: 1rem !important;" 
        type= {{keyboard_type}} (ngModelChange)="onTextChange($event)">
      <label for="username" [attr.for]="">{{label}}</label>
    </div>
    </div> -->