import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSelect } from '@angular/material/select';
import { MatOption } from '@angular/material/core';
import { Router } from '@angular/router';
import { FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../services/auth.service';
import { openClose } from '../../animations'
import { MatTableDataSource } from '@angular/material/table';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core'
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { DomSanitizer } from '@angular/platform-browser';
import { LabmoduleService } from '../../services/labmodule.service';


import * as moment from 'moment';
import { PatientService } from '../../services/patient.service';
import { elementAt } from 'rxjs-compat/operator/elementAt';

let labRequisitions = [{ patientName: "Mrunalini lohar", doctor: "Dr .Uday Pimpalgaonkar", date: "11/4/2022", time: "11:30", test: "RTPCR, Free or total triiodothyronine (free T3 or total T3)", status: "done", action: "https://copious-opd-prod.s3.ap-south-1.amazonaws.com/images/requisitions/2022-31-20-11smEwo.pdf" },
{ patientName: "Aaalen Mirza", doctor: "Dr .Sagar", date: "12/4/2022", time: "11:30", test: "RTPCR", status: "Confirmed", action: "" },
{ patientName: "Dashanand Dhature", doctor: "Dr .Ashok Gapchup", date: "11/4/2022", time: "11:30", test: "RTPCR", status: "done", action: "" }]





export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};



@Component({
  selector: 'app-lab-home',
  templateUrl: './lab-home.component.html',
  animations: [openClose],
  styleUrls: ['./lab-home.component.css'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
})
export class LabHomeComponent implements OnInit {
  @ViewChild("uploadDocuments") uploadDocuments;
  @ViewChild("requisitionContent_modal") requisitionContent_modal
  @ViewChild("viewReportModal") viewReportModal
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl(),
  });
  fromDateForRequisition:any
  labUplaodLable="Requisitions"
  deleteAllReport:any
  toDateForRequisition:any
  fromDateForAllReports:any
  toDateForForAllReports:any
  currentLabReportObj:any={}
  moment: any = moment;
  reportPdfURL:any
 userId:any
 requisitionData:any=""
  documentPhotoURL:any
  documentPdfURL
  documentURL
  searchPatientRequisition: string
  uploadFileName: any
  uploadFileType
  requisitionId:any
  requisitionStatusValue:string
  labRequisitionsList:any=[]
  uploadedReportLIst:any=[]
  allUploadedReportList:any=[]
  allUploadedReportListOrg:any=[]
  requisitionStatusName:string
  searchPatientAllReports:string
  showUploadPdf:any=""
  patientCommnets:any
  requisitionStatus:string[]=['Open','Confirmed','Done','Sample collected']
  displayedColumns: string[] = ['sr no', 'patient', 'doctor','dateTime', 'test', 'status','labcomments', 'action',];
  constructor(private LabService: LabmoduleService,  private sanitizer: DomSanitizer,public dialog: MatDialog,public router: Router, private authService: AuthService,  private confirmationService: ConfirmationService,) { 
    // if (this.authService.isLoggedIn()) {
    //   this.router.navigate(['/lab/lab-home'])

    // }
     this.labRequisitionsList=labRequisitions
    this.fromDateForRequisition = new Date(new Date().setDate(new Date().getDate() - 7));
    this.toDateForRequisition = new Date();
  }

  ngOnInit(): void {

    //trying out AUTO REFRESH WINDOW when version is NEW
    this.authService.getVersion().subscribe(response => {
      console.log(response, localStorage.getItem("version"));
      let currentwebapp = "";
      if (localStorage.getItem("version")) {
        currentwebapp = localStorage.getItem("version");
      }
      if (response.webapp != currentwebapp) {
        console.log("Need to be refreshed");
        localStorage.setItem("version", response.webapp);
        window.location.reload();
      }
    });

    this.userId = this.authService.getUser()

    this.getRequisitionsByLabId()
    // this.getLabReportsByReqId()
  }
  openDialog(content) {
    this.dialog.open(content);
  }
  closeDialog() {
    this.dialog.closeAll()
  }

  viewPdf(url){
    //this.showUploadPdf =(url.name);
    window.open(url.name, '_blank');
  }
  currentlyselectedDocandPatientforUpload:any = null;
  onClickUploadDocuments(document) {
    this.requisitionData=document
    this.labUplaodLable="Requisitions"
    this.patientCommnets=""
    this.fileconvertedtobase64=[]
    this.currentlyselectedDocandPatientforUpload = null;
    // var documentUrl=document.action+"#toolbar=0"
    if (document.photoURL != '' && document.photoURL != null) {
      this.documentPdfURL = ""
      this.documentPhotoURL = this.sanitizer.bypassSecurityTrustResourceUrl(document.photoURL);
    }
    else {
      this.documentPhotoURL = ""

      this.documentPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(document.url);

    }
    if (document.url == null && document.photoURL == "") {
      this.patientCommnets = document.comments
    }

    this.currentlyselectedDocandPatientforUpload = document;
    this.openDialog(this.uploadDocuments);
  }

  labcommentsForeditBox = ""

  onClickSatusUpdate(element) {
    this.requisitionId = element.id
    this.labcommentsForeditBox = element.labcomments;
    this.openDialog(this.requisitionContent_modal)
  }
  openRequisitionUrl(url) {
    window.open(url, '_blank');
  

  }
  onFileDropped($event) {
    this.fileBrowseHandler($event)

  }



  deleteFile(index: number) {
    this.fileconvertedtobase64.splice(index, 1);
  }

  getRequisitionsByLabId() {

    let locallabstr = localStorage.getItem("LAB_DETAILS");
    if (locallabstr) {
      let lab = JSON.parse(locallabstr);
      var arr: any = []
      let req_vars = {
        labid: lab.id,
        fromdate: moment(this.fromDateForRequisition).format("YYYY-MM-DD"),
        todate: moment(this.toDateForRequisition).format("YYYY-MM-DD")
      }
      this.LabService.getRequisitionsByLabId(req_vars).subscribe(response => {
        console.log("getRequisitionsByLabId", response)
        var requisitionsAll: any = response.requisitions
        for (let index = 0; index < requisitionsAll.length; index++) {
          const element = requisitionsAll[index];
          var objDocument: any = {}
          objDocument["id"] = element.id
          objDocument["services"] = element.Doctor.services
          objDocument["doctorId"] = element.doctorId ? element.doctorId : null
          objDocument["patientid"] = element.PatientId;
          objDocument["patientLastName"] = element.Patient.User ? element.Patient.User.last_name : '';
          objDocument["patientName"] = element.Patient.User ? element.Patient.User.first_name + " " + element.Patient.User.last_name + " " : '';
          if (element.Doctor.User.first_name == "SYSTEM") {
            objDocument["doctorName"] = "self"
          } else {
            objDocument["doctorName"] = element.Doctor.User.first_name + " " + element.Doctor.User.last_name
          }

          objDocument["gender"] = element.Patient.User ? element.Patient.User.gender : ''
          objDocument["mobileNumber"] = element.Patient.User ? element.Patient.User.phone_number : ''
          objDocument["date"] = element.createdAt
          objDocument["test"] = element.tests
          objDocument["url"] = element.url
          objDocument["status"] = element.status
        
       
            objDocument["labcomments"] = element.labcomments
          
         
          objDocument["comments"] = element.comments
          objDocument["photoURL"] = element.photoURL
          arr.push(objDocument);
        }

        this.labRequisitionsList = arr
        console.log("test", this.labRequisitionsList)
        this.labRequisitionsList = new MatTableDataSource(this.labRequisitionsList)

        //call local search if there
        this.onSearchPatientRequisition(null);
      })
    }
  }
  updateRequisitionByLab() {
    var labcomments: any = document.getElementById('labcomments').textContent;
 
    let req_vars={
      id:this.requisitionId,
      status:this.requisitionStatusName,
      labcomments:labcomments ? labcomments:""
    }
    this.LabService.updateRequisitionByLab(req_vars).subscribe(response => {
      if(response){
        console.log(response)
        this.getRequisitionsByLabId();
      }
    
     // this.labcommentsForeditBox = '';
      this.closeDialog();
    })

  }
  dateRangeForRequisitionList(type: any, event: any) {
    if (type == 'start') {
      this.fromDateForRequisition = event.value
    }
    else {
      this.toDateForRequisition = event.value
    }

  }
  onDropdownSelecStatus(status) {
    this.requisitionStatusName = status
  }
  applyFilter(filterValue: string) {
    if (filterValue) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.labRequisitionsList.filter = filterValue;
      this.allUploadedReportList.filter = filterValue
    }

  }
  onSearchPatientRequisition = (event: Event) => {
    this.applyFilter(this.searchPatientRequisition);
  }


  fileconvertedtobase64 = [];
  allfilesTouploadOK = true;
  fileBrowseHandler(files) {

    // this.fileconvertedtobase64 = [];
    this.allfilesTouploadOK = true;
    var doc = JSON.parse(localStorage.getItem("LAB_DOCTOR"));
    var services: any = {};
    if (doc && doc.services) {
      services = JSON.parse(doc.services);
    }
    if (files && files.length > 0) {
      for (var i = 0; i < files.length; i++) {

        var reader = new FileReader();
        reader.readAsDataURL(files[i]);
        let loopfiletype = files[0].type;
        let loopfilename = files[i].name;

        if ((files[i].type == "application/pdf" || files[i].type == 'image/jpeg' || files[i].type == 'image/png')
          && files[i].size < 10 * 1024 * 1024) {
          if (services.labuploadchecklastname && !loopfilename.toLowerCase().includes(this.currentlyselectedDocandPatientforUpload.patientLastName.toLowerCase())) {
            //special case for when patine phone has to match
            alert("Patient Last Name (Surname) not found in Document file name. Please check if correct file is selected or else change file name to have Patient's Last Name (Surname) in it.");

          } else {
            reader.onload = () => {
              console.log(reader.result);
              this.fileconvertedtobase64.push({ data: reader.result, fileext: loopfiletype, name: loopfilename });
              console.log("base64 converted ", this.fileconvertedtobase64.length);
            };
          }
        } else {
          this.allfilesTouploadOK = false;
        }
      }
    }

  }

  saveChangesLabReoprt() {
    console.log('DUMMY', this.currentlyselectedDocandPatientforUpload);

    let lab: any = {}
    let locallabstr = localStorage.getItem("LAB_DETAILS");
    let uploadedBy = "lab"
    if (locallabstr) {
      lab = JSON.parse(locallabstr);
      uploadedBy = lab.name
    }

    if (!this.allfilesTouploadOK) {
      alert("");
      return;
    }

    if (this.fileconvertedtobase64 && this.fileconvertedtobase64.length > 0 && this.currentlyselectedDocandPatientforUpload) {

      for (var i = 0; i < this.fileconvertedtobase64.length; i++) {
        var postData = {
          data: {
            patientId: this.currentlyselectedDocandPatientforUpload.patientid, // Patient id
            fileData: this.fileconvertedtobase64[i].data, // base 64 string
            documentType: 'Lab Report', // Lab Report or Prescription or Requisition...etc
            fileExtension: this.fileconvertedtobase64[i].type, //pdf or jpg etc
            fileName: this.fileconvertedtobase64[i].name,
            notes: '', //this.notes, //createdBy
            uploadedBy: uploadedBy,
            requisitionid: this.currentlyselectedDocandPatientforUpload.id,
            labid: lab.id,
            documentDate: moment().format("YYYY-MM-DD"), // date selection,
            isLocked: this.currentlyselectedDocandPatientforUpload?.services && JSON.parse(this.currentlyselectedDocandPatientforUpload.services).lockReports || 0
          }
        }


        this.LabService.LabUploadReport(postData).subscribe(response => {

          if (response) {
            console.log("Report upload sucessfully")

          }
          this.dialog.closeAll();
          //  this.getLabReportsByReqId()

        })
      }


    }

  }

  getLabReportsByReqId() {
    var postData = {
      requisitionid: this.currentlyselectedDocandPatientforUpload.id,
    }
    this.LabService.getLabReportsByReqId(postData).subscribe(response => {

      if (response) {
        console.log("get uploaded report", response)
        this.uploadedReportLIst = response.reports

      }

    })

  }

  onClickGetReport(report){
   // window.open(report, '_blank');
   this.labUplaodLable="Preview"
    this.documentPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(report);

 
  }
  onClickDeleteReport(element, doc) {
    this.deleteAllReport = doc
    this.currentLabReportObj = element

  }
  deleteReport() {
    let postData = {
      labreportid: this.currentLabReportObj.id
    }
    this.LabService.deleteLabReport(postData).subscribe(response => {
      // if (confirm("Are you sure to delete ")) {
      console.log("delete", response);
      if (this.deleteAllReport == "allReport") {
        this.closeDialog();
      }
      else {
        this.getLabReportsByReqId();
        console.log(this.requisitionData)
        if(this.requisitionData.photoURL!='' && this.requisitionData.photoURL!=null){
          this.documentPdfURL=""
          this.documentPhotoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.requisitionData.photoURL);
        }
        else{
          this.documentPhotoURL=""
       
            this.documentPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.requisitionData.url);
            
        }
        
        this.openDialog(this.uploadDocuments);
      }

    });

  }
  onclickViewAllReports(element) {
    let lab: any = {}
    let locallabstr = localStorage.getItem("LAB_DETAILS");

    if (locallabstr) {
      lab = JSON.parse(locallabstr);
      let postData = {
        patient_id: element.patientid,
        labid: lab.id

      }
      this.LabService.getAllLabReportOfPatient(postData).subscribe(response => {

        if (response) {
          console.log("get uploaded report", response)
          this.allUploadedReportList = response.reports
          this.allUploadedReportListOrg = response.reports

        }

      })
    }
  }



  onSearchPatientAllReports(value: string): void {
    this.allUploadedReportList = this.allUploadedReportListOrg.filter((val) =>
      val.emailMessageIdAttchName.toLowerCase().includes(value)
    );
  }
  getReportsByFromDateAndToDate() {
    var allReports = []
    allReports = this.allUploadedReportListOrg
    var startDate: any = moment(this.fromDateForAllReports).format('YYYY-MM-DD')
    var endDate: any = moment(this.toDateForForAllReports).format('YYYY-MM-DD')

    allReports = allReports.filter(m => {
      var createdDate: any = moment(m.createdAt).format('YYYY-MM-DD');
      return createdDate >= (startDate) && createdDate <= (endDate)
    });
    console.log("dateFilter", allReports)
    this.allUploadedReportList = allReports
  }

}
