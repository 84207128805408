import { Component, OnInit, ViewChild, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import * as moment from 'moment';
import { PatientService } from '../../../services/patient.service'
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { UtilService } from '../../../util/utilService'
import { refreshService } from '../../../services/refersh.service';
import { AddVitalsComponent } from '../add-vitals/add-vitals.component';

interface patientInterface {
  _id: number;
  id: number;
  formattedDOB: string;
  vaccinationStartDate: string;
  createdAt: string;

  User: {
    dob: string,
    gender: string
  }
}


let visits = [
  {
    date: 'Birth',
    time: '24/10/2020',
    ageAtVisit: '0y 0m 1d',
    visitBy: 'Dr Uday',

    vitals: {
      weight: '2',
      height: '45',
      bmi: '16',
      cc: '10',
      hc: '1',
      temperature: '98.6',
      spo2: '99'
    },

    bpHeart: {
      systolic: '-',
      diastolic: '-',
      heartrate: '99',
      pulse: '98'
    },

    bloodAnalysis: {
      haemoglobin: '-',
      random: '-',
      bloodsugarF: '-',
      bloodsugarPP: '-',
      hb1ac: '97'

    },

    lipidProfile: {
      ldl: '-',
      hdl: '-',
      totalCholestrol: '-',
      tryglycerides: '-'
    }
  },

  {
    date: '18/04/2022',
    time: '1:35 PM',
    ageAtVisit: '0y 0m 1d',
    visitBy: 'Dr Aaleen',

    vitals: {
      weight: '2',
      height: '45',
      bmi: '16',
      cc: '10',
      hc: '1',
      temperature: '98.6',
      spo2: '99'
    },

    bpHeart: {
      systolic: '-',
      diastolic: '-',
      heartrate: '99',
      pulse: '98'
    },

    bloodAnalysis: {
      haemoglobin: '-',
      random: '-',
      bloodsugarF: '-',
      bloodsugarPP: '-',
      hb1ac: '97'

    },

    lipidProfile: {
      ldl: '-',
      hdl: '-',
      totalCholestrol: '-',
      tryglycerides: '-'
    }
  },

  {
    date: '18/04/2022',
    time: '1:35 PM',
    ageAtVisit: '0y 0m 1d',
    visitBy: 'Dr Aaleen',

    vitals: {
      weight: '2',
      height: '45',
      bmi: '16',
      cc: '10',
      hc: '1',
      temperature: '98.6',
      spo2: '99'
    },

    bpHeart: {
      systolic: '-',
      diastolic: '-',
      heartrate: '99',
      pulse: '98'
    },

    bloodAnalysis: {
      haemoglobin: '-',
      random: '-',
      bloodsugarF: '-',
      bloodsugarPP: '-',
      hb1ac: '97'

    },

    lipidProfile: {
      ldl: '-',
      hdl: '-',
      totalCholestrol: '-',
      tryglycerides: '-'
    }
  }
]

@Component({
  selector: 'patient-vitals',
  templateUrl: './vitals.component.html',
  styleUrls: ['./vitals.component.scss']
})
export class VitalsComponent implements OnInit {

  @ViewChild("addVitalsModal") addVitalsModal;
  @ViewChild("editVitalsModal") editVitalsModal;
  @ViewChild("addVitalsComponent") addVitalsComponent:AddVitalsComponent;

  displayedColumns: string[] = ['vitals', 'birth', '22/04/22', '22/04/22', '22/04/22', '22/04/22', '22/04/22', '16/04/22', '16/04/22', '16/04/22', '16/04/22', '14/04/22', 'action'];
  visitDate:any
  gender=""
  ageYears:any=""
  @Input() patientObj: patientInterface;

  @Input() graphName: string = '';
  @Input() showGraphs: boolean = false;

  isVisitEdit: boolean = false;
  isPediatric: boolean = false;
  isEditableVisit: boolean = false;
  visitDeleteFlag:boolean=false
  birthVisit: any = null;
  visitsForVisitScreen = [];
  totalAmount: any = []
  selectedVisit: any;
  errors: any = {};
  selectedVisitdate: any;
  patientResponse: any;
  visitDateDiff: any;
  minMaxPercentile: any;

  showLoading: boolean = false;
  latestVisitWithValues = '';

  moment: any = moment;

  dob: any;
  sendToQuickRx: any;
  goToHomePage = false;
  visits: any;
  oldOrBirthVitalsArray: any;
  recentVitalsArray: any;
  pediatricVisitDate = "";
  adultPreviousVisit: any;
  adultPreviousVisitDate: any;
  adultPreviousVisitBMI: any = "";
  showLMP:boolean=false
  adultPreviousVisitCholRatioLany = "";
  adultLatestVisitBMI: any = "";
  adultLatestVisit: any
  adultLatestVisitDate: any;
  adultLatestVisitCholRatio: any;
  adultPreviousVisitCholRatio: any;
  patientAge:any
  ageFlagForHCAndCC:boolean=false
  paramPatientId:any;
 allVisits: any = [];
 visitIdFromEditVitalGraph:any;
  allVisitsBackup: any = [];

  toggleFormat: boolean = false;

  showLipidProfile: boolean = true;
  showBloodAnalysis: boolean = true;
  showBpHeart: boolean = true;
  patientDataFromLocal
  getflag: any;
  selectedVisitIdFromGraph: any;





  constructor(private refreshservice: refreshService,public dialog: MatDialog, private util: UtilService, private patientService: PatientService, private modalService: NgbModal, private router: Router, private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.patientDataFromLocal = JSON.parse(localStorage.getItem("patientData"));
    this.refreshservice.VitalsRefreshEvent.subscribe(valuesent => {
      console.log("GOT REFRESH EVENT");
      this.getPatientInfo("Visit");
      // this.getPatientsVisitInfoFN(); 
     // this.closeDialog();
    

    });
   setTimeout(()=>{
    this.getPatientInfo('Overview');
   },500)

    
    this.route.queryParams.subscribe(params => {
      console.log("params " ,params);
      this.paramPatientId = params.patientId
   
    })
    //     if (params.action) {
    //       var patData: any362 = localStorage.getItem('patientDataForVitals');
    //       console.log("patData", JSON.parse(patData))
    //       patData = JSON.parse(patData);
    //       this.isPediatric = patData.patientTypes.length == 2 ? true : false;
          
    //       this.patientResponse = patData;

    //       console.log('Pat-data', patData)


    //       if (params.action == 'edit') {
    //         var visits: any = patData.visits && patData.visits.length ? patData.visits : [];

    //         var visit = "";
    //         visits.map((element, index) => {
    //           if (element._id && element._id === "synthetic_record") {
    //             visits.splice(index, 1);
    //           }
    //           if (new Date(element.date).getDate() == new Date().getDate() && new Date(element.date).getMonth() == new Date().getMonth() && new Date(element.date).getFullYear() == new Date().getFullYear()) {
    //             visit = element
    //           }
    //         });
    //         if (visit) {
    //           setTimeout(() => {
    //             this.onSelectVisit(visit, 0);
    //           }, 500);
    //         } else {
    //           setTimeout(() => {
    //             this.addVitals();
    //             this.opensDialog(this.addVitalsModal);
    //           }, 1000);
    //         }

    //       } else {
    //         setTimeout(() => {
    //           this.addVitals();
    //           this.opensDialog(this.addVitalsModal);
    //         }, 500);
    //       }
    //     }
    //     else {
    //       setTimeout(() => {
    //         this.addNewVitalsLink();
    //         this.opensDialog(this.addVitalsModal);
    //       }, 500);
    //     }
    //   }
    // });
  //  this.addVitals();

    this.selectedVisit = {
      weight: '',
      height: '',
      Temp: '',
      spo2: '',
      Bloodhaemoglobin: '',
      bpSys: '',
      bpDia: '',
      Heartrate: '',
      Pulse: '',
      BloodSugarRandom: '',
      Bloodsugar_F: '',
      Bloodsugar_PP: '',
      Blood_Hba1c: '',
      Chol_HDL: '',
      Chol_LDL: '',
      TotalCholesterol: '',
      Triglycerides_LFT: '',
      addedBy: 'doctor',
      date: this.reverseDate(new Date())
    };
  }


  validateNumber(e, usedFor) {

    if (e.charCode === 13 && usedFor === 'receipt') {
      this.totalAmount.push(e.key)

    }
    console.log("amount", this.totalAmount)
    if (usedFor === 'receipt') {
      return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.which == 13) || (e.keyCode == 9)
    } else {
      return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.which == 13) || (e.keyCode == 9) || (e.keyCode == 110) || (e.keyCode == 46)
    }
  }

  getAge(birthDate, visitDate) {
    birthDate = new Date(birthDate)
    let age = this.util.calculateAgeForVisit(birthDate, visitDate)
    let ageStr = null
    ageStr = age['years'] ? `${age['years']}Y` : null
    // ageStr = ageStr ? age['months'] ? `${ageStr}, ${age['months']}M` : `${ageStr}` : 0;
    ageStr = `${age['years']}y ${age['months']}m ${age['days']}d`


    console.log('AGE STRING:::::', ageStr)

  

  
    return ageStr
  }


  getPatientsVisitInfoFN() {
    this.showLoading = true;
var patientID = this.patientDataFromLocal.id
    //clear all past data of this comp[oenent for fresh reload]
    this.birthVisit = null;
     this.latestVisitWithValues = "";
    this.allVisits = [];
   /// this.selectedVisit = null;

    //now call api for ffresh data
console.log("this.patientObj.id : patientID", this.paramPatientId , patientID, this.patientObj.id, this.patientDataFromLocal.id);

    this.patientService.getPatientsVisitInfo(this.paramPatientId ? this.paramPatientId : this.patientDataFromLocal.id).subscribe(response => {
      this.showLoading = false;
      let flattenedVisitList = []
      if (response) {

        console.log('TRUEVistiGraph', response)
        console.log('TRUEVistiGraph visits', response[0].data)

        const visits = response ? response : {};
        const lastVisit = visits[visits.length - 1] ? visits[visits.length - 1] : '';
        if (this.isPediatric) {


          this.birthVisit = lastVisit.data[lastVisit?.data.length - 1] ? lastVisit.data[lastVisit.data?.length - 1] : '';
        console.log("lastVisit.data",lastVisit);
        
         
          
         // flattenedVisitList.push(lastVisit.data)
       //  this.visitsForVisitScreen = lastVisit;
          console.log('hmm', this.birthVisit)
        }
        if (lastVisit && lastVisit.data.length === 1) {
         visits.pop();
       
        }

        var arr = [];
        for (let index = 0; index < visits.length; index++) {
          const element = visits[index];

          for (let j = 0; j < element.data.length; j++) {
            const elementForMonth = element.data[j];

            if (new Date().getTime() < new Date(elementForMonth.date).getTime()) {
              //commenting here - condition unknown
              // element.data.splice(j, 1);
            }

          }

        }
        if (visits.length) {
          this.latestVisitWithValues = '';
          var visit = visits[0].data[0];
          var keys = Object.keys(visit);
          var strToShowNonNullValues = '';
          for (let m = 0; m < keys.length; m++) {
            const elementKey = keys[m];

            if (visit[elementKey] && visit[elementKey] !== '0' && visit[elementKey] !== 0 && visit[elementKey] !== null && visit[elementKey] !== 'null' && visit[elementKey] !== ''
              && elementKey !== '_id' && elementKey !== 'chief_complaints' && elementKey !== 'date'
              && elementKey !== 'addedBy' && elementKey !== 'updatedByName' && elementKey !== 'DoctorId' && elementKey !== 'patientid' && elementKey !== 'formattedDate' && elementKey !== 'newDate' && elementKey !== 'strToShowNonNullValues') {
              strToShowNonNullValues = strToShowNonNullValues + " " + elementKey + ' : ' + visit[elementKey];
            }
          }
          this.latestVisitWithValues = strToShowNonNullValues;
        }
        //this.visitsForVisitScreen = visits;

      
        // this.visitsForVisitScreen.map((monthwiseVisits) => {
        //   flattenedVisitList.push(...monthwiseVisits);
        // });

        response.map((monthwiseVisits) => {
          flattenedVisitList.push(...monthwiseVisits.data);
        });

        this.visits = response.visits && response.visits.length ? response.visits : [];
        console.log("allVisit", this.visits)
        this.visits.map((element, index) => {
          if (element._id === "synthetic_record") {
            console.log("element._id appoinment adult", element._id);
            
            console.log("log in synthetic_record add vitals");

            this.visits.splice(index, 1);
          }


        });

        this.formatVisitsArray(flattenedVisitList)

        console.log('Flattened List', flattenedVisitList)
        
          this.visitsForVisitScreen = visits;
       
      
        this.addVitals()
      }
    });
  }


  
  validateField = (field) => {
    if (field && field != '0' && field != '' && field != 'undefined' && field != 'null' && field != '-') {
      return true;
    } else {
      return false;
    }
  }

  toggleShowEmptyVisits(){
    this.toggleFormat = !this.toggleFormat;
    this.FilterVisitsEmpty();
  }

  FilterVisitsEmpty = () => {

    let visits = this.allVisits;
    let formattedVisits = [];

    if (this.toggleFormat) {
      this.allVisits = this.allVisitsBackup;
    } else {
      visits.map((visit, index) => {
        if (this.validateField(visit.weight) || this.validateField(visit.height) ||
          this.validateField(visit.cc) || this.validateField(visit.hc) || this.validateField(visit.Temp) ||
          this.validateField(visit.spo2) || this.validateField(visit.bpSys) || this.validateField(visit.bpDia) ||
          this.validateField(visit.Heartrate) || this.validateField(visit.Pulse) || this.validateField(visit.Bloodhaemoglobin) ||
          this.validateField(visit.BloodSugarRandom) ||
          this.validateField(visit.Bloodsugar_F) || this.validateField(visit.Bloodsugar_PP) ||
          this.validateField(visit.Blood_Hba1c) || this.validateField(visit.Chol_LDL) || this.validateField(visit.Chol_HDL) ||
          this.validateField(visit.TotalCholestrol) || this.validateField(visit.Triglycerides_LFT)

        ) {
       // visit.lmp=visit.lmp
          formattedVisits.push(visit)
        }
      });

      this.allVisits = formattedVisits;

    }


  }

  formatVisitsArray(visits) {

    let formattedVisitsArray: any = [];

    visits.map(visit => {


      console.log('VISITI', visit);

      let formattedvisitObject = {
        weight: this.validateField(visit.weight) ? visit.weight : '-',
        height: this.validateField(visit.height) ? visit.height : '-',
        bmi: this.validateField(visit.weight) && this.validateField(visit.height) ? (visit.weight / ((visit.height * visit.height) / 10000)).toFixed(2) : '-',
        cc: this.validateField(visit.cc) ? visit.cc : '-',
        hc: this.validateField(visit.hc) ? visit.hc : '-',
        Temp: this.validateField(visit.Temp) ? visit.Temp : '-',
        spo2: this.validateField(visit.spo2) ? visit.spo2 : '-',
        Bloodhaemoglobin: this.validateField(visit.Bloodhaemoglobin) ? visit.Bloodhaemoglobin : '-',
        bpSys: this.validateField(visit.bpSys) ? visit.bpSys : '-',
        bpDia: this.validateField(visit.bpDia) ? visit.bpDia : '-',
        Heartrate: this.validateField(visit.Heartrate) ? visit.Heartrate : '-',
        Pulse: this.validateField(visit.Pulse) ? visit.Pulse : '-',
        BloodSugarRandom: this.validateField(visit.BloodSugarRandom) ? visit.BloodSugarRandom : '-',
        Bloodsugar_F: this.validateField(visit.Bloodsugar_F) ? visit.Bloodsugar_F : '-',
        Bloodsugar_PP: this.validateField(visit.Bloodsugar_PP) ? visit.Bloodsugar_PP : '-',
        Blood_Hba1c: this.validateField(visit.Blood_Hba1c) ? visit.Blood_Hba1c : '-',
        Chol_HDL: this.validateField(visit.Chol_HDL) ? visit.Chol_HDL : '-',
        Chol_LDL: this.validateField(visit.Chol_LDL) ? visit.Chol_LDL : '-',
        TotalCholesterol: this.validateField(visit.TotalCholesterol) ? visit.TotalCholesterol : '-',
        Triglycerides_LFT: this.validateField(visit.Triglycerides_LFT) ? visit.Triglycerides_LFT : '-',
        addedBy: 'doctor',
        date: visit.date,
        time: visit.date,
        lmp:visit.lmp,
        _id:visit._id,
        ageAtVisit: this.getAge(this.birthVisit?.date ? this.birthVisit.date : this.patientResponse.DOB, visit.date),
        visitBy: visit.updatedByName ? visit.updatedByName.split(' ')[0] : '',

      };

      formattedVisitsArray.push(formattedvisitObject);
      

    })
    this.allVisits = formattedVisitsArray;
    console.log("may10",this.allVisits)
    this.allVisitsBackup =  formattedVisitsArray;
    this.FilterVisitsEmpty()
  }

  toggleVitals(type) {
    if (type == 'lipidProfile') {
      this.showLipidProfile = !this.showLipidProfile
    } else if (type == 'bloodAnalysis') {
      this.showBloodAnalysis = !this.showBloodAnalysis
    } else if (type == 'bpHeart') {
      this.showBpHeart = !this.showBpHeart;
    }

  }




  addVitals() {

    if (this.visitsForVisitScreen.length) {
      // console.log( 'ALL-VISITS',  this.visitsForVisitScreen[0])
      if (this.visitsForVisitScreen[0].data.length) {
        console.log('ALL-VISITS', this.visitsForVisitScreen[0].data[0]);

        var element = this.visitsForVisitScreen[0].data[0];
        this.isVisitEdit = false;

        var todaysDate = new Date().toISOString().slice(0, 10); if (element.date) {
          var visitDate = element.date
          var visitDateNew = new Date(visitDate).toISOString().slice(0, 10);
        } if (todaysDate == visitDateNew) {
          //EDIT VISIT
          
          this.isVisitEdit = true;
          this.selectedVisit = this.visitsForVisitScreen[0].data[0];
          this.onSelectVisit(this.selectedVisit, 0);
         
        }
        else {
          //NEW VISIT
          this.isVisitEdit = false;
          if(this.selectedVisit){
            delete this.selectedVisit["_id"];

            this.errors = {};
            var date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
            this.selectedVisitdate = this.reverseDate(date);
            if (this.isPediatric) {
              this.getMinMaxPercentile(date);
            }
          }

          this.selectedVisit = {
            "date": this.reverseDate(new Date()),
            "vitals": {
              "weight": "",
              "height": "",
              "bmi": "",
              "cc": "",
              "hc": "",
              "temperature": "",
              "spo2": ""
            },
            "bpHeart": {
              "systolic": "",
              "diastolic": "",
              "heartrate": "",
              "pulse": ""
            },
            "bloodAnalysis": {
              "haemoglobin": "",
              "random": "",
              "bloodsugarF": "",
              "bloodsugarPP": "",
              "hb1ac": ""
            },
            "lipidProfile": {
              "ldl": "",
              "hdl": "",
              "totalCholestrol": "",
              "tryglycerides": ""
            }
          }

        }

        console.log('ALL-VISITS Visit Exists......?', this.isVisitEdit)
      }
    }
  }

  opensDialog(content) {
    // this.selectedIndex = index;
    this.dialog.open(content);
  }


  hideGraph() {
    this.showGraphs = false;
  }

  addNewVitalsLink() {
    if(this.patientAge >= 3){
      this.ageFlagForHCAndCC=true
    }
    
    this.isVisitEdit = false;
    if(this.selectedVisit){
      delete this.selectedVisit["_id"];
      this.errors = {};
      var date = moment(new Date()).format("YYYY-MM-DD HH:mm:ss")
      this.selectedVisitdate = this.reverseDate(date);
      if (this.isPediatric) {
       
        this.getMinMaxPercentile(date);
      }
     // this.selectedVisit={}
      this.selectedVisit.weight = "";
      this.selectedVisit.height = "";
      this.selectedVisit.hc = ""
      this.selectedVisit.cc = "";
      this.selectedVisit.BloodSugarRandom= ""
      this.selectedVisit.Blood_Hba1c= ""
      this.selectedVisit. Bloodhaemoglobin= ""
      this.selectedVisit.Bloodsugar_F=""
      this.selectedVisit.Bloodsugar_PP= ""
      this.selectedVisit.Chol_HDL= ""
      this.selectedVisit.Chol_LDL=""
      this.selectedVisit.Heartrate= ""
      this.selectedVisit.Pulse=""
      this.selectedVisit.Temp=""
      this.selectedVisit.TotalCholesterol= ""
      this.selectedVisit.Triglycerides_LFT= ""
      this.selectedVisit.bmi=""
      this.selectedVisit.bpDia=""
      this.selectedVisit.bpSys= ""
      this.selectedVisit.spo2=""

    }
  }

  getMinMaxPercentile(date) {
    let momentVd = moment(date);
    let momentDob = moment(this.patientResponse.DOB ? this.patientResponse.DOB : '', "DD-MMM-YYYY");
    let diff = moment.duration(momentVd.diff(momentDob));
    this.visitDateDiff = diff ? diff : {};
    console.log("minPer", this.visitDateDiff)
    this.calculateMinMax(moment(date).format("YYYY-MM-DD HH:mm:ss"), diff.years());
  }
 


  onSelectVisit(visit, type) {

  this.selectedVisitIdFromGraph = visit._id;
  console.log("selectedVisitIdFromGraph",this.selectedVisitIdFromGraph);
  
  this.refreshservice.setFlag(this.selectedVisitIdFromGraph);
    this.errors = {};
    var obj = visit ? visit : '';
    this.selectedVisit = obj;
    this.visitDate=this.selectedVisit
    this.selectedVisitdate = this.reverseDate(new Date(visit.date));
    console.log("OBJECT FROM GRAPH ",obj);
    this.visitIdFromEditVitalGraph = obj._id;
    console.log("visitIdFromEditVitalGraph",this.visitIdFromEditVitalGraph);
    
    
    if (new Date(visit.date).getDate() === new Date().getDate() && new Date(visit.date).getMonth() === new Date().getMonth() && new Date(visit.date).getFullYear() === new Date().getFullYear()) {
      this.isEditableVisit = true
    }
    if (this.isPediatric) {
      this.getMinMaxPercentile(visit.date);
    }
   

    if (type == 1) {
      setTimeout(() => {
        if(this.selectedVisit.addedBy=="birth"){
          this.visitDeleteFlag=true
        }
        else{
          this.visitDeleteFlag=false
        }
        // this.selectedVisit['_id'] = this.visitIdFromEditVitalGraph;
        console.log('HAME', this.selectedVisit)
        this.opensDialog(this.editVitalsModal);
      }, 500);
    }
  }
  saveVisit() {
console.log("vitalsVisitId log from vitals ts in saveVisit ");

    this.errors = {};
    // this.refreshservice.returnflag().subscribe((ele)=>{
    //   this.getflag = ele
    // })
    // if(this.getflag=='onboardingFlagvalue'){
    //   console.log('flag value true ==================')
    // }

    if (!this.selectedVisitdate) {
      // this.selectedVisit['_id'] = this.appo;
    }
    else {
      // this.selectedVisit['_id'] = this.visitIdFromEditVitalGraph;
      console.log(" this.selectedVisit['_id']",this.selectedVisit);
      console.log("visitIdFromEditVitalGraph",this.visitIdFromEditVitalGraph);
      
      
      let recordData = {
        patientId: this.patientObj.id ? this.patientObj.id : this.patientObj._id,
        dob: this.dob,
        visit: this.selectedVisit,

      };
      console.log("editGraph  recordData",recordData);
      
      recordData.visit.date = moment(this.parseDate(this.selectedVisitdate)).format("YYYY-MM-DD HH:mm:ss")
      this.patientService.savePatientVisit(recordData, this.patientResponse?._id).subscribe(response => {
        if (response) {
          this.modalService.dismissAll();

          if (this.sendToQuickRx) {
            this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientResponse._id, vitalsValue: true } });
          }
          else if (this.goToHomePage) {

            this.router.navigate(['/doctor/homepage']);
            // setTimeout(()=>{
            //   this.refreshservice.visitRefreshed();
            // })
          }
          else {
            this.getPatientsVisitInfoFN();
            this.getPatientInfo('Visits');
          }
        }
      })
    }
  }



  reverseDate(date) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }



  calculateMinMax(vDate, ageYears) {
    console.log("this.patientObj.id ? this.paramPatientId : this.patientObj._id,", this.patientObj.id , this.paramPatientId )
    
    var postData = {
      patientId: this.patientObj.id ? this.paramPatientId : this.paramPatientId,
      visitDate: vDate
    }
    console.log("postData calculateMinMax", postData);
    
    let gender = this.patientResponse.gender === "Male" ? "Boys" : "Girls";
    this.patientService.calculateMinMax(postData, this.patientResponse._id).subscribe(response => {
      if (response) {
        var _minMaxPercentile = {};
        _minMaxPercentile["weight"] = response["weight" + gender];
        _minMaxPercentile["height"] = response["height" + gender];
        if (ageYears < 3) {
          _minMaxPercentile["hc"] = response["hc" + gender];
        } else {
          _minMaxPercentile["hc"] = { min: "", max: "" };
        }
        this.minMaxPercentile = _minMaxPercentile;

      }
    });
  }

  closeDialog() {
    this.dialog.closeAll();
  }


  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" });
  }



  parseDate(dateObject) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }


  getPatientInfo(tab) {
var patientID = this.patientDataFromLocal.id;

console.log("this.parampatientID",this.paramPatientId, "this.patientObj.id",this.patientObj.id);

    this.patientService.getPatientInfo(this.paramPatientId ? this.paramPatientId: patientID).subscribe(response => {
      if (response) {
        this.patientResponse = response;
        console.log("patientRes",this.patientResponse)
        this.patientAge=response.age.years
       
        localStorage.setItem("patientMoreData", JSON.stringify(this.patientResponse))
        
        this.isPediatric = response.patientTypes.length === 2 ? true : false;
        this.gender=response.gender
        
        this.dob = response.DOB;
        this.ageYears = response.age.years;
     
     // this.addVitalsComponent.loadPatientData(this.patientResponse._id);
    
      
      
        

        if (this.isPediatric) {
          
          this.visits = response.visits;
       //  this.selectedVisit=this.visits


          this.oldOrBirthVitalsArray = [

            {
              name: 'Weight',
              value: response.visits[0]["weight"] == 0 ? 'NA' : response.visits[0]["weight"],
              unit: 'kg',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'Height',
              value: response.visits[0]["height"] == 0 ? 'NA' : response.visits[0]["height"],
              unit: 'cm',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'HC',
              value: response.visits[0]["hc"] == 0 ? 'NA' : response.visits[0]["hc"],
              unit: 'cm',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'CC',
              value: response.visits[0]["cc"] == 0 ? 'NA' : response.visits[0]["cc"],
              unit: 'cm',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'BP (Sys)',
              value: response.visits[0]["bpSys"] == 0 ? 'NA' : response.visits[0]["bpSys"],
              unit: '',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'BP (Dia)',
              value: response.visits[0]["bpDia"] == 0 ? 'NA' : response.visits[0]["bpDia"],
              unit: '',
              date: moment(response.visits[0]["date"]).format("DD-MMM-YYYY"),
            },

          ];
          this.recentVitalsArray = [

            {
              name: 'Weight',
              value: response.visits[response.visits.length - 1]["weight"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["weight"],
              unit: 'kg',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'Height',
              value: response.visits[response.visits.length - 1]["height"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["height"],
              unit: 'cm',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'HC',
              value: response.visits[response.visits.length - 1]["hc"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["hc"],
              unit: 'cm',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'CC',
              value: response.visits[response.visits.length - 1]["cc"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["cc"],
              unit: 'cm',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'BP (Sys)',
              value: response.visits[response.visits.length - 1]["bpSys"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["bpSys"],
              unit: '',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'BP (Dia)',
              value: response.visits[response.visits.length - 1]["bpDia"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["bpDia"],
              unit: '',
              date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
            },
            {
              name: 'SPO2',
              value: response.visits[response.visits.length - 1]["spo2"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["spo2"],
              unit: '',
              date: moment(response.visits[response.visits.length - 1]["spo2"]).format("DD-MMM-YYYY"),
            },
            // {
            //   name: 'LMP',
            //   value: response.visits[response.visits.length - 1]["lmp"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["lmp"],
            //   unit: '',
            //   date: moment(response.visits[response.visits.length - 1]["lmp"]).format("DD-MMM-YYYY"),
            // },

          ];
          if(this.ageYears>=8 && response.gender=="Female"){
            this.showLMP=true
            console.log("JULY27")
            this.recentVitalsArray.push( {
             name: 'LMP',
             value: response.visits[response.visits.length - 1]["lmp"] == 0 ? 'NA' : response.visits[response.visits.length - 1]["lmp"],
             unit: '',
             date: moment(response.visits[response.visits.length - 1]["date"]).format("DD-MMM-YYYY"),
           },)
          }
        
          this.pediatricVisitDate = moment(response.visits[response.visits.length - 1]["date"]).format("DD MMM YYYY")
          if (new Date().getTime() < new Date(this.pediatricVisitDate).getTime()) {
            //this.selectedVisit = [];
            //  this.oldOrBirthVitalsArray=[];
          }
        }
       
        else {
          console.log("log in else",this.visits);
          
          //  this.tabs.push('Vaccination')
          this.visits = response.visits ? response.visits : [] ? response.visits : [];
          for (let index = 0; index < this.visits.length; index++) {
            const element = this.visits[index];
            if (element._id === "synthetic_record") {
              console.log("log in synthetic_record10");

              this.visits.splice(index, 1);
            }
          }
          if (this.visits.length) {
            if (this.visits.length > 1) {
              this.adultPreviousVisit = this.visits[this.visits.length - 2];
              this.adultPreviousVisitDate = moment(this.visits[this.visits.length - 2]["date"]).format("DD-MMM-YYYY");
              const w = Number(this.adultPreviousVisit.weight);
              const h = Number(this.adultPreviousVisit.height);
              const ldlc = Number(this.adultPreviousVisit.Chol_LDL);
              const hdlc = Number(this.adultPreviousVisit.Chol_HDL);

              let bmi:any = Number(w / Math.pow(h / 100, 2));

              let Bmi:any = ""
              if (bmi == NaN) {
                bmi = Bmi;
              }
              let r: any = ldlc / hdlc



              this.adultPreviousVisitBMI = bmi === Infinity || bmi === '' ? '' : bmi.toFixed(2);
              console.log("bmi", this.adultLatestVisitBMI)

              this.adultPreviousVisitCholRatio = r === Infinity || r === '' ? '' : r.toFixed(2);
            }
            this.adultLatestVisit = this.visits[this.visits.length - 1];
            this.adultLatestVisitDate = moment(this.visits[this.visits.length - 1]["date"]).format("DD-MMM-YYYY");
            const w = Number(this.adultLatestVisit.weight);
            const h = Number(this.adultLatestVisit.height);
            const ldlc = Number(this.adultLatestVisit.Chol_LDL);
            const hdlc = Number(this.adultLatestVisit.Chol_HDL);
            let bmi: any = Number(w / Math.pow(h / 100, 2));

            let r: any = ldlc / hdlc

            this.adultLatestVisitBMI = bmi === Infinity || bmi === '' || isNaN(bmi) ? 'NA' : bmi.toFixed(2);

            if (!Number.isNaN(this.adultLatestVisitBMI)) {
              //  this.adultLatestVisitBMI = ""
            }
            else {
              this.adultLatestVisitBMI;
            }
            this.adultLatestVisitCholRatio = r === Infinity || r === '' || isNaN(r) ? 'NA' : r.toFixed(2);

            if (!Number.isNaN(this.adultLatestVisitCholRatio)) {
              // this.adultLatestVisitCholRatio = ""
            }
            else {
              this.adultLatestVisitCholRatio;
            }
            if (new Date().getTime() < new Date(this.adultLatestVisitDate).getTime()) {
              this.adultLatestVisit
            }
          }
        }
       
        this.getPatientsVisitInfoFN();
        // this.getPatientsVaccination();
      } else {
        alert('Something went wrong')
      }
    });
  }

  onInputCustomComponent = (value, identifier) => {
  
    this.selectedVisit[identifier] = value;
   


  }

  openGraphs(graphName) {
    if (graphName == 'Weight'||graphName == 'CC' || graphName == 'Height' || graphName == 'HC' || graphName == 'BP(Sys)' || graphName == 'BP(Dia)') {
     
      // this.selectedIndex = 3;
      // this.tab = 'Visits';
   
      this.graphName = graphName;
    }

    localStorage.setItem('patientData', JSON.stringify(this.patientResponse));
    this.showGraphs = true;
  }


  onClickDeleteVisit(){
    let postData:any={
      visitId:this.selectedVisit._id,
    //  patientId:this.patientObj.id
    }
    console.log("postData", postData);
    
    this.patientService.deleteVisit(this.patientObj.id,postData).subscribe(response => {
      if(response){
        console.log("res",response)
        
      }
    })
    this.closeDialog()
  // this.getPatientInfo('Visit');
  setTimeout(()=>{
    this.getPatientsVisitInfoFN()
  },300)
  
  }

}
