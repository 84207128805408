import { Injectable } from '@angular/core';
import { CanActivate, CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Observer } from 'rxjs';

import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, SharedModule} from 'primeng/api';

export interface CanComponentDeactivate {
  canDeactivate: () => boolean | Promise<boolean> | boolean;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  allowRedirect: boolean

  constructor(private confirmationService: ConfirmationService, private router: Router, ) {
    this.allowRedirect = true
  }

  canDeactivate(component: CanComponentDeactivate,
    route: ActivatedRouteSnapshot,

    state: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ) {
    return this.allowRedirect;

    //   if(component.canDeactivate()){
    //     return this.allowRedirect 
    //   } else {
    //     return Observable.create((observer: Observer<boolean>) => {
    //       this.confirmationService.confirm({
    //           message: 'All the unsaved changes will be lost. Do you want to continue?',
    //           header: 'Confirmation',
    //           icon: 'fa fa-question-circle',
    //           rejectLabel: 'Cancel',
    //           accept: () => {
    //               observer.next(true);
    //               observer.complete();
    //           },
    //           reject: () => {
    //               observer.next(false);
    //               observer.complete();
    //           }
    //       });
    //     });




    //   /*if(confirm('All the unsaved changes will be lost. Do you want to continue?')){
    //     return true
    //   }else{
    //    return false
    //   }*/
    // }


  }
}