import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { map } from 'rxjs/operators/map'
import { Router } from '@angular/router'
import { Constants } from "../../config/constants";
import 'rxjs';
import { Observer } from 'rxjs/Observer';

interface TokenResponse {
  status: string,
  data: {
    token: string
    user_id: string
    user_type: string
  }
}
@Injectable()
export class AuthService {

  constructor(private http: HttpClient, private router: Router) { }

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  APIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/v1`

  setToken(token: string) {
    localStorage.setItem('token', token);
  }

  setAdminToken(token: string) {
    localStorage.setItem('admin_token', token);
  }

  setUserType(type: string) {
    localStorage.setItem('type', type);
  }

  getUserType() {
    return localStorage.getItem('type');
  }
  public getVersion(): Observable<any> {
    
    return this.http.get(`${this.APIURL}/appVersion`)
    //let objtoreturn:any = { webapp: "2.0" };
    //return objtoreturn;
  }

  getToken() {
    return localStorage.getItem('token');
  }

  getAdminToken() {
    return localStorage.getItem('admin_token');
  }

  deleteToken(sessionTimedOut) {
    if (this.getUser()) {
      this.http.post(`${this.APIURL}/logout`, { user_id: this.getUser(), type: 'doctor', session: sessionTimedOut }, this.noAuthHeader).subscribe((response: any) => {
        if (response.success) {
          localStorage.removeItem('token');
          localStorage.clear();
          this.router.navigate(['/'])
        }
      })
    }
  }

  deleteAdminToken(sessionTimedOut) {
    if (this.getAdminUser()) {

      this.http.post(`${this.APIURL}/logout`, { user_id: this.getAdminUser(), type: 'admin', session: sessionTimedOut }, this.noAuthHeader).subscribe((response: any) => {
        if (response.success) {
          //localStorage.removeItem('admin_token');
        }
      })

      localStorage.clear();
      this.router.navigate(['/admin/login'])

    }
  }

  getUserPayload() {
    var token = this.getToken();
    if (token) {
      var userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload);
    }
    else
      return null;
  }

  getAdminPayload() {
    var token = this.getAdminToken();
    if (token) {
      var userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload);
    }
    else
      return null;
  }

  getAdminUser() {
    var token = this.getAdminToken();
    if (token) {
      var userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload)['user_id'];
    }
    else
      return null;
  }

  getUser() {
    var token = this.getToken();
    if (token) {
      var userPayload = atob(token.split('.')[1]);
      return JSON.parse(userPayload)['user_id'];
    }
    else
      return null;
  }

  isLoggedIn() {
    var userPayload = this.getUserPayload();
    //var type = this.getUserType()
    if (userPayload)
      return userPayload.exp > Date.now() / 1000;
    else
      return false;
  }

  isAdminLoggedIn() {
    var userPayload = this.getAdminPayload(); //var type = this.getUserType()
    if (userPayload)
      return userPayload.exp > Date.now() / 1000;
    else
      return false;
  }

  public login(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/login`, req_vars, this.noAuthHeader)
  }

  public adminLogin(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/adminLogin`, req_vars, this.noAuthHeader)
  }
}
