import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { PatientService } from '../../../services/patient.service';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
import { refreshService } from '../../../services/refersh.service';
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { Subscription } from 'rxjs/Subscription';
import { MatTableDataSource } from '@angular/material/table';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, NativeDateAdapter } from '@angular/material/core';
export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};
@Component({
  selector: 'app-patient-certificate',
  templateUrl: './patient-certificate.component.html',
  styleUrls: ['./patient-certificate.component.css'],
  providers: [MessageService,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }]
})
export class PatientCertificateComponent implements OnInit {

  refbyDr=""
  dateOfAdmission: Date | null = null;
  dateOfDischarge: Date | null = null;
  wtoa=""
  wtod=""
  diagnosis=""
  co=""
  hopd=""
  pa=""
  hr=""
  labInvistigation=""
  dischargeCondition=""
  onDischargeRx=""
  advise=""
  rr=""
  bp=""
  spo2=""
  rxGiven=""
  rs=""
  cvs=""
  cns=""
  ctScan=""
  xrayFindings=""
  mri=""
  other=""
  perCertificate = ['Fit to join ', 'Advised Rest', 'Fitness Certificate', 'Vaccination Certificate','Vaccination Due','Total Due Vaccination', 'Mid day meal', 'Admission Advise']
  selectedCertiType = 'Certificate Type'
  adultCertificate: any = ['Fit to join work', 'Advised Rest', 'Fitness Certificate', 'Travel Certificate', 'Admission Advise']
  showOtherTextBox = false;
  showOtherTextBox1 = false;
  showOtherTextBox2 = false;
  showOtherTextBox3 = false;
  showOtherTextBox4 = false;
  showOtherTextBox5 = false;
  showVaccinationDue=false
  showOtherTextBox0 =true;
  showOtherAddmission = false
  showTotalDueVaccination=false
  typeOfGender:any=""
  blood_group:any=""
  selectedDate: any;
  ageInDays:any=""
  displayedColumnsForVaccDue: string[] = ['visit', 'vaccine', 'recommendedDate','date'];
  displayedColumns: string[] = ['srno','uploadedby', 'date','documenttype', 'preview',];
  moment: any = moment;
  templateText:any
  forValue:any
  duration = "";
  medicalCondtion = ""
  finalVacArr = []
  hospitalCity = "";
  hospitalName = "";
  isPediatric=false
  docName:any
  selectedVaccination:any=[]
  certisURL:any
  certiPdfURL:any
  templateName: any = "Speciality";
  selectedTemplate: any = [];
  toDatee: any;
  fromDateVacc
  address: string = "";
  resumeDateVacc:any
  resumeDate:any
  toDateVacc:any
  minDate
  toDate
  first_name
  gender
  relationwithcust
  ageYears
  ageMonths=''
  last_name
  mobileNumber
  fromToDate
  genderHe
  fromDate
  patientName
  alltestVac:any=[]
  subsVar: Subscription;
  durationnn:any
  closeResult: any;
  middle_name
  allVaccinDueList:any=[]
  vaccineList:any=[]
  @Input()patientType:any
  @Input () doctorObj:any
  @Input () patientResponse:any
 
   patientCertificate:any =[]
   vaccinationListOrg:any=[]
  allPatientCertificate:any=[]
   chief_testVacc:any
  allAdministeredVaccines:any
  documentPdfURL:any=""
  documentURL:any=""
  showdischarge=0

  @ViewChild("viewCertiModal") viewCertiModal
  @ViewChild("addCertificateModal")addCertificateModal
  
  
  @ViewChild("viewDocumentsModal") viewDocumentsModal;
  @ViewChild("viewSaveDischargeModal") viewSaveDischargeModal;
  @ViewChild("previewDischargeModal")previewDischargeModal;
  @ViewChild("dischargeModal")dischargeModal;

  
  
  constructor(public dialog: MatDialog,private modalService: NgbModal,private refreshservice: refreshService,private sanitizer: DomSanitizer,private messageService: MessageService,private patientService: PatientService) { 
    
   // this.minDate = this.reverseDate(new Date(this.dob));
    this.selectedDate = new Date();
  }
  
  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


  ngOnInit(): void {
 
     //If coming from vaccination
     var isForImmunization=localStorage.getItem("isForImmunization");
    var vaccinesAdministered: any = [];
    if(this.patientResponse && this.patientResponse._id!=null){

    
    this.patientService.getPatientsVaccination(this.patientResponse._id).subscribe(response => {
      if (response) {
        let vaccineScheduleListWithVaccinedata = response;
        this.vaccineList=response
        this.vaccinationListOrg=response

        vaccineScheduleListWithVaccinedata.map(eachVacGroup => {
          eachVacGroup.data.map((eachVac, idx) => {
            if (eachVac.administeredStatus) {
              vaccinesAdministered.push(eachVac);
            }
          })
        })

      }
    

      if (vaccinesAdministered && vaccinesAdministered.length > 0 && isForImmunization!=null) {
        
        setTimeout(() => {
          this.onDropdownSelectCerti("Vaccination Certificate");
        }, 300);

        let arr = [];
        let todaysVaccines = []

        vaccinesAdministered.map(ins => {
          // if (item.administeredStatus && moment().isSame(item.administeredDate, 'day')) {
          if (ins.administeredStatus && moment().isSame(ins.administeredDate, 'day')) {
            ins['selected'] = true;
            ins['todays'] = true;
            todaysVaccines.push(ins.name)
          } else {
            ins['selected'] = false;
          }
          arr.push(ins);
        })

        this.allAdministeredVaccines = arr;
        console.log("apr11",this.allAdministeredVaccines)
        this.chief_testVacc = todaysVaccines.length ? todaysVaccines.join(', ') : ''

        
        setTimeout(()=>{
          this.openDialog(this.addCertificateModal,"addCertificateModal")
       
        },500)
        
    
        
      
      }
    
      
    });
  }
    localStorage.removeItem("flagForCertificateVacc")
    console.log("bloodgroup",this.patientResponse)
 
    this.first_name = this.patientResponse.firstName;
    this.last_name = this.patientResponse.lastName;
    this.blood_group=this.patientResponse.blood_group
    this.middle_name = this.patientResponse.middleName;
    this.mobileNumber = this.patientResponse.parent["contactNo"].replace("91", "");
    this.patientName = this.patientResponse.firstName + " " + this.patientResponse.middleName + " " + this.patientResponse.lastName;
    this.gender = this.patientResponse.gender.toLowerCase() === 'male' ? 'M' : "F";
    if (this.gender == 'F') {
      this.genderHe = "She";
      this.typeOfGender="Miss"
    }
    else {
      this.genderHe = 'He';
      this.typeOfGender="Mr"
    }
    this.relationwithcust = this.patientResponse.relationWithCustomer
    this.ageYears = this.patientResponse.age.years +"";
    
    // if(this.ageYears=='0'){
      this.ageMonths = this.patientResponse.age.months+"";
      this.ageInDays=this.patientResponse.age.days
    // }
    this.templateName = 'default';
    console.log("docObj",this.doctorObj)
    var services = JSON.parse(this.doctorObj.services);
    this.showdischarge=services.showdischarge ? services.showdischarge:0
    console.log("sep7",services)
    if (services.template_names) {
      this.templateName = services.template_names[0];
      this.selectedTemplate = services.template_names;
    }
    else{
      this.selectedTemplate.push('default');
    }
    if(this.patientType){
      this.isPediatric=true
    }
    this.fromDateVacc = '(FromDate)'
    let today: any = new Date()
    let today1 = today.toString()
    this.resumeDate = this.reverseDate(new Date());
    this.selectedDate = this.reverseDate(new Date());
    this.toDatee = this.reverseDate(new Date());
    this.resumeDate = this.reverseDate(new Date());
    this.fromDate = this.reverseDate(today1)
    // this.fromDate.toString();
    this.toDate = this.reverseDate(today1)
    this.fromToDate = this.reverseDate(today1)
    this.dateCovert();
   
      this.getAllDoctorPrescriptions();
  
  
   
      this.getAllDocumentsOfPatient();
   
     
   
   
    
    
    
   
  }

  getSpecialVaccines(){
   // var isForImmunization=localStorage.getItem("isForImmunization");
    var vaccinesAdministered: any = [];
    if(this.patientResponse && this.patientResponse._id!=null){

    
      this.patientService.getPatientsVaccination(this.patientResponse._id).subscribe(response => {
        if (response) {
          let vaccineScheduleListWithVaccinedata = response;
          this.vaccineList=response
          this.vaccinationListOrg=response
  
          vaccineScheduleListWithVaccinedata.map(eachVacGroup => {
            eachVacGroup.data.map((eachVac, idx) => {
              if (eachVac.administeredStatus) {
                vaccinesAdministered.push(eachVac);
              }
            })
          })
  
        }
      
  
        if (vaccinesAdministered && vaccinesAdministered.length > 0 ) {
          
        
  
          let arr = [];
          let todaysVaccines = []
  
          vaccinesAdministered.map(ins => {
            // if (item.administeredStatus && moment().isSame(item.administeredDate, 'day')) {
            if (ins.administeredStatus && moment().isSame(ins.administeredDate, 'day')) {
              ins['selected'] = true;
              ins['todays'] = true;
              todaysVaccines.push(ins.name)
            } else {
              ins['selected'] = false;
            }
            arr.push(ins);
          })
  
          this.allAdministeredVaccines = arr;
          console.log("apr11",this.allAdministeredVaccines)
          this.chief_testVacc = todaysVaccines.length ? todaysVaccines.join(', ') : ''
  
          
        
          
      
          
        
        }
      
        
      });
    }

  }
  openDialog(content,index) {
 
    this.dialog.open(content);
  }
  onClickViewCert = (certificates) => {
    this.certiPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(certificates.url);
    this.certisURL = certificates.url;
    this.openDialog(this.viewCertiModal, 'viewCertiModal');
  //  this.isViewRecipt = true;
  }
  reverseDate(date) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }
  parseDate(dateObject) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }
  onDropdownSelectTemplate = (item) => {
    this.templateName = item
  }
  addVaccRemove() {
    this.allAdministeredVaccines.map(ins=>{
      if(ins.selected){
        ins.selected=false
      }
    })
    this.duration = "(Duration)";
  }
  dateCovert() {
    this.fromDateVacc = "(FromDate)";
    this.medicalCondtion = ''

    this.duration = '';
    var fromDate = moment(new Date(this.parseDate(this.selectedDate))).format("DD/MM/YYYY");
    var toDate = moment(new Date(this.parseDate(this.toDatee))).format("DD/MM/YYYY");
    var resumeDate = moment(new Date(this.parseDate(this.resumeDate))).format("DD/MM/YYYY");
    // var createdOnRaw=moment(new Date(this.parseDate(this.createdOnRaw))).format("DD/MM/YYYY HH:mm:ss");
    // this.createdOnRawDate=createdOnRaw
    this.fromDateVacc = fromDate;
    this.toDateVacc = toDate;
    this.resumeDateVacc = resumeDate;


  }
  closeDialog() {
    
    this.dialog.closeAll();
  }
  onDropdownSelectCerti = (item) => {

    this.selectedCertiType = item;
    if (item == 'Fit to join work'||item == 'Fit to join ' ) {
      this.showOtherTextBox = true;
      this.showOtherTextBox1 = false;
      this.showOtherTextBox2 = false
      this.showOtherTextBox3 = false;
      this.showOtherTextBox4 = false;
      this.showOtherTextBox5 = false
      this.showOtherTextBox0 = false
      this.showOtherAddmission = false
      this.showVaccinationDue=false
      this.showTotalDueVaccination=false
    }
    else if (item == 'Advised Rest') {
      this.duration = "";
      this.medicalCondtion = ""

      this.showOtherTextBox1 = true
      this.showOtherTextBox = false
      this.showOtherTextBox2 = false
      this.showOtherTextBox3 = false;
      this.showOtherTextBox4 = false;
      this.showOtherTextBox5 = false
      this.showOtherTextBox0 = false
      this.showOtherAddmission = false
      this.showVaccinationDue=false
      this.showTotalDueVaccination=false
    }
    else if (item == 'Fitness Certificate') {
      this.duration = "";
      this.showOtherTextBox2 = true
      this.showOtherTextBox = false
      this.showOtherTextBox1 = false;
      this.showOtherTextBox3 = false;
      this.showOtherTextBox4 = false;
      this.showOtherTextBox5 = false
      this.showOtherTextBox0 = false
      this.showOtherAddmission = false
      this.showVaccinationDue=false
      this.showTotalDueVaccination=false
    }

    else if (item == 'Mid day meal') {
      this.duration = "";
      this.medicalCondtion = ""
      this.showOtherTextBox4 = true;
      this.showOtherTextBox = false
      this.showOtherTextBox1 = false;
      this.showOtherTextBox2 = false;
      this.showOtherTextBox3 = false;
      this.showOtherTextBox5 = false
      this.showOtherTextBox0 = false
      this.showOtherAddmission = false
      this.showVaccinationDue=false
      this.showTotalDueVaccination=false
    }
    else if (item == 'Vaccination Certificate') {
      this.showOtherTextBox5 = true
      this.showOtherTextBox4 = false;
      this.showOtherTextBox = false
      this.showOtherTextBox1 = false;
      this.showOtherTextBox2 = false;
      this.showOtherTextBox3 = false;
      this.showOtherTextBox0 = false
      this.showOtherAddmission = false
      this.showVaccinationDue=false
      this.showTotalDueVaccination=false
      this.getSpecialVaccines()
      localStorage.removeItem("isForImmunization")

    }
    else if (item == 'Admission Advise') {
      this.showOtherAddmission = true;
      this.showOtherTextBox5 = false
      this.showOtherTextBox4 = false;
      this.showOtherTextBox = false
      this.showOtherTextBox1 = false;
      this.showOtherTextBox2 = false;
      this.showOtherTextBox3 = false;
      this.showOtherTextBox0 = false
      this.showVaccinationDue=false
      this.showTotalDueVaccination=false

    }
    else if (item == 'Vaccination Due') {
      this.getPendingVaccination()
      this.showOtherAddmission = false;
      this.showOtherTextBox5 = false
      this.showOtherTextBox4 = false;
      this.showOtherTextBox = false
      this.showOtherTextBox1 = false;
      this.showOtherTextBox2 = false;
      this.showOtherTextBox3 = false;
      this.showOtherTextBox0 = false
      this.showVaccinationDue=true
      this.showTotalDueVaccination=false

    }
    else if (item == 'Total Due Vaccination') {
      this.getPendingVaccination()
      this.showOtherAddmission = false;
      this.showOtherTextBox5 = false
      this.showOtherTextBox4 = false;
      this.showOtherTextBox = false
      this.showOtherTextBox1 = false;
      this.showOtherTextBox2 = false;
      this.showOtherTextBox3 = false;
      this.showOtherTextBox0 = false
      this.showVaccinationDue=false;
      this.showTotalDueVaccination=true

    }
    else {
      this.showOtherTextBox4 = false;
      this.showOtherTextBox = false
      this.showOtherTextBox1 = false;
      this.showOtherTextBox2 = false;
      this.showOtherTextBox3 = true;
      this.showOtherTextBox0 = false
      this.showOtherTextBox5 = false
      this.showOtherAddmission = false
      this.showVaccinationDue=false
      this.showTotalDueVaccination=false
    }
  }

  selectAllVaccination(){
    this.allAdministeredVaccines.map(ins=>{
      if(ins.selected || !ins.selected){
        ins.selected=true
      }
    })
  }
  selectModalTestVaccValue = (item) => {

    // this.alltestVac = this.vaccineList
    this.alltestVac = this.allAdministeredVaccines
    let data = this.alltestVac;
    let selectedDataArr = [];
    data.map((ins, index) => {
      if (ins.name == item.name) {
        if (ins.selected) {
          ins.selected = false;

          let selected = selectedDataArr;
          if (selected.includes(ins.name)) {

          }

        } else {
          ins.selected = true;
          selectedDataArr.push(ins.name);
        }
      }
    });

    this.allAdministeredVaccines = data;
  }
  onChangeDrop(event) {

    if (event === "Fit to join work ") {
      this.showOtherTextBox = true;
      this.forValue = "";
      this.showOtherTextBox1 = false;
      // this.mytest=this.htmlAsString;

      //  this.mytest=this.isill;
    }
    else if (event === 'Advised Rest') {
      this.showOtherTextBox = false;
      this.showOtherTextBox1 = true;
      this.forValue = "";
    }
    else {
      this.showOtherTextBox1 = false;
      this.showOtherTextBox = false;
    }
  }
  closeCalender(e, d) {
    if (e.target.offsetParent == null) d.close();

    else if (
      e.target.offsetParent &&
      e.target.offsetParent.nodeName != "NGB-DATEPICKER" &&
      e.target.offsetParent.className.indexOf("calender") == -1 &&
      e.target.offsetParent.className.indexOf("custom-select") == -1
    )
      d.close();

    if (d._elRef.nativeElement.parentElement.contains(e.target)) {

    } else {
      d.close()
    }
    if (e.target.offsetParent == null)
      d.close()
    else if (e.target.offsetParent && e.target.offsetParent.nodeName != "NGB-DATEPICKER" && (e.target.offsetParent.className.indexOf('calender') == -1 && e.target.offsetParent.className.indexOf('custom-select') == -1))
      d.close()
  }

  getPendingVaccination(){
    for (let i = 0; i < this.vaccineList.length; i++) {
      if (this.isJson(this.vaccineList[i].name)) {
        let scheduleNames = JSON.parse(this.vaccineList[i].name);
        if (scheduleNames && scheduleNames.length > 0) {
          scheduleNames.forEach(langpart => {
            if (langpart.lang == 'en') {
              this.vaccineList[i].name = langpart.text;
            }
          });
        }
      }
    }
    let vaccineArray = [];
    let newArray = [];
    let tempArr = [];
    let administeredVaccines = [];
    this.vaccineList=this.vaccinationListOrg

    this.vaccineList.map(ins => {
      ins.data.map((item, idx) => {
        if (!tempArr.includes(ins.name)) {
          tempArr.push(ins.name)
          item['vaccineVisit'] = ins.name;
        } else {
          item['vaccineVisit'] = "";
        }

        item['visitName'] = ins.name;
        item['index'] = idx;
        item['date']=""
        item['catchupDate'] = item.catchUpPeriod && item.catchUpPeriod != "" && item.dueDate && item.dueDate != "" ? moment(item.dueDate).add(item.catchUpPeriod, 'w') : 'NA';
        item['catchupDate'] = item.catchupDate == 'NA' ? 'NA' : item.catchupDate.format('DD-MM-YYYY');

        //overdue logic
        if (item.catchupDate && item.catchupDate != "NA") {
          var todayDate: any = moment().format('YYYY-MM-DD');
          var pastDate: any = moment(item.catchupDate, 'DD-MM-YYYY').format('YYYY-MM-DD');

          var bool = moment(todayDate).isAfter(moment(pastDate))
          if (bool && !item.administeredStatus) {
            item['isOverdue'] = true;
          }
        }

        //all administeredVaccines
        if (item.administeredStatus) {
          administeredVaccines.push(item);
        }

        if (moment(item.dueDate).isBefore(moment())) {
          item['isPending'] = true
        }
        if(!item.administeredStatus){
          vaccineArray.push(item);
        }
       
      });

    });
    this.vaccineList = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
    this.allVaccinDueList=vaccineArray
    console.log("allVacc",this.allVaccinDueList)

  }

  openCertiModal() {
    for (let i = 0; i < this.vaccineList.length; i++) {
      if (this.isJson(this.vaccineList[i].name)) {
        let scheduleNames = JSON.parse(this.vaccineList[i].name);
        if (scheduleNames && scheduleNames.length > 0) {
          scheduleNames.forEach(langpart => {
            if (langpart.lang == 'en') {
              this.vaccineList[i].name = langpart.text;
            }
          });
        }
      }
    }
    

    this.selectedCertiType = "Certificate Type";
    this.showOtherTextBox0 = true
    this.showOtherTextBox4 = false;
    this.showOtherTextBox = false
    this.showOtherTextBox1 = false;
    this.showOtherTextBox2 = false;
    this.showOtherTextBox3 = false;
    this.showVaccinationDue=false
    this.showTotalDueVaccination=false

    this.showOtherTextBox5 = false
    this.showOtherAddmission = false
    this.chief_testVacc = []
    this.finalVacArr = []
    this.hospitalCity = "";
    this.hospitalName = "";
    


  //  this.unformattedVaccineList = this.vaccineList;
   
   
  }
  printCertificateNew() {
   
    if (this.showOtherTextBox) {
      var fitToJoinWorkPostIllness: any = document.getElementById('fitToJoinWorkPostIllness').textContent;
      fitToJoinWorkPostIllness = fitToJoinWorkPostIllness
      this.templateText = fitToJoinWorkPostIllness;
    }
    else if (this.showOtherTextBox1) {
      var advisedRest: any = document.getElementById('advisedRest').textContent;
      advisedRest = advisedRest
      this.templateText = advisedRest;
    }
    else if (this.showOtherTextBox2) {
      var fitnessCertificate: any = document.getElementById('fitnessCertificate').textContent;
      fitnessCertificate = fitnessCertificate
      this.templateText = fitnessCertificate;
    }
    else if (this.showOtherTextBox3) {
      var travelCertificate: any = document.getElementById('travelCertificate').textContent;

      travelCertificate = travelCertificate;
      this.templateText = travelCertificate;
    }
    else if (this.showOtherTextBox4) {
      var midDayMeal: any = document.getElementById('midDayMeal').textContent;

      midDayMeal = midDayMeal;
      this.templateText = midDayMeal;
    }
    else if (this.showOtherTextBox5) {

      var vaccinationCertificate: any = document.getElementById('vaccinationCertificate').textContent;

      vaccinationCertificate = vaccinationCertificate;
      this.templateText = vaccinationCertificate;
    }
    else if (this.showOtherAddmission) {

      var admissionCertificate: any = document.getElementById('admissionCertificate').textContent;

      admissionCertificate = admissionCertificate
      this.templateText = admissionCertificate;
     
    }



  }
  modelChangeFn(e) {
    this.duration = e;
    this.durationnn = this.duration
  }

  closeAddReceiptModal(){
    this.showOtherTextBox = false;
  }

  getAllDoctorPrescriptions(){
    var obj = {
      "patientId": this.patientResponse.id ? this.patientResponse.id : this.patientResponse._id,
      "isForHistory": true,
    }
    var arr = [];
    var arrNotes = [];
    var arrinstr = [];
    var arrivest = [];
    this.patientService.getAppointmentsByPatientId(obj).subscribe(response => {
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      if (response.success) {
        if (response.historyArr.length) {
          for (let index = 0; index < response.historyArr.length; index++) {
            const element = response.historyArr[index];
            var obj: any = {}
            obj.createdAt = element.from;
            obj.from = element.from;
            obj.to = element.to;
            obj.id = element.id;
            obj.docName = element.Doctor.User?.first_name + " " + element.Doctor.User?.last_name;
            this.docName = obj.docName;
            obj.Visit = element.Visit && element.Visit != null ? element.Visit : {};
            obj.Medicines = element.Visit && element.Visit != null ? element.Visit.Medicines && element.Visit.Medicines.length > 0 ? element.Visit.Medicines : null : null;
            obj.prescriptionPDFURL = element.prescriptionPDFURL && element.prescriptionPDFURL != null ? element.prescriptionPDFURL : null;
            obj.Certificates = element.Certificates && element.Certificates != null ? element.Certificates : null;
          
            arr.push(obj);

          }
          this.patientCertificate = arr.reverse();
        
        }

      }
      console.log("Sep5",this.patientCertificate)
      var certificateArr:any=[]
    if(this.patientCertificate){
      for(let index = 0; index < this.patientCertificate.length; index++){
        const element = this.patientCertificate[index];
      
        if( element.Certificates!=null){
          var certificate:any=element.Certificates
          certificate=JSON.parse(certificate)
         
         certificate.map(ins=>{
           if(ins.url && ins.type=="Certificate" && ins.type!='Receipt'){
            var objDocument:any={}
            objDocument["documentDate"]=element.from
            objDocument["documentType"]="Certifcate"
            objDocument["uploadedBy"]=element.docName
            objDocument["url"]=ins.url
            certificateArr.push(objDocument)
           }
         })
        }
      }
    }
  
   this.allPatientCertificate= this.allPatientCertificate.concat(certificateArr)
    // console.log("allCert",  this.allPatientCertificate=certificateArr

    this.allPatientCertificate =  this.allPatientCertificate.reduce((unique, o) => {
      if (!unique.some(obj => obj.url == o.url)) {
        unique.push(o);
      }
      return unique;
    }, [])

    // )
    })
  }
  open(content, type) {
    this.modalService.open(content, { backdrop: false, size: 'xl', centered: false, windowClass: 'dark-modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      console.log("dismissed")
    //  this.isEditableVisit = false

    });
  }
  openNewCertificate(){
    setTimeout(() => {
      this.onDropdownSelectCerti("Certificate Type");
    }, 300);
    this.showOtherTextBox0=true
  }
  saveModalTestVaccValues() {

    let finalArr = []
    this.allAdministeredVaccines.map(ins=>{
      if(ins.selected){
        finalArr.push(ins.name)
      }
    })

    this.chief_testVacc = finalArr.join(', ');
  }

  printNewCertificate(action) {
    if (this.templateName == "Speciality") {
      // alert("hello")
      this.messageService.add({ severity: 'custom', detail: 'Please specify speciality before proceeding' });
    }
  else if(this.selectedCertiType == "Certificate Type"){
      this.messageService.add({ severity: 'custom', detail: 'Please specify Certificate Type before proceeding' });
    }
  
    else {
      if (action == 'share') {
        this.certisURL;
      }
      else {


        var data = [];
        var vacclist;
        if (this.showOtherTextBox) {
          var fitToJoinWorkPostIllness: any = document.getElementById('fitToJoinWorkPostIllness').textContent;
          fitToJoinWorkPostIllness = fitToJoinWorkPostIllness
          this.templateText = fitToJoinWorkPostIllness;
        }
        else if (this.showOtherTextBox1) {
          var advisedRest: any = document.getElementById('advisedRest').textContent;
          advisedRest = advisedRest
          this.templateText = advisedRest;
        }
        else if (this.showOtherTextBox2) {
          var fitnessCertificate: any = document.getElementById('fitnessCertificate').textContent;
          fitnessCertificate = fitnessCertificate
          this.templateText = fitnessCertificate;
        }
        else if(this.showVaccinationDue){
          // var duevaccination:any=document.getElementById('dueVaccinationContent').textContent
          // duevaccination=this.first_name+" " +this.last_name+"<br>"+duevaccination;
          // this.templateText=duevaccination
        }
        else if (this.showOtherTextBox3) {
          var travelCertificate: any = document.getElementById('travelCertificate').textContent;

          travelCertificate = travelCertificate;
          this.templateText = travelCertificate;
        }
        else if (this.showOtherTextBox4) {
          var midDayMeal: any = document.getElementById('midDayMeal').textContent;

          midDayMeal = midDayMeal;
          this.templateText = midDayMeal;
        }
        else if (this.showOtherTextBox5) {
          console.log("june1333333", this.allAdministeredVaccines)
          this.allAdministeredVaccines?.map(ins => {
            if (ins.name && ins.selected) {
              data.push(ins)
              data.join('|');
            }

          });
          var str = '<ol>'

          data.forEach(function (slide) {
            if(slide.brandvaccine==""){
              str += '<li>' + slide.name + "" +" "+slide.administeredDate + '</li>';
            }
            else{
              str += '<li>' + slide.name + "("+slide.brandvaccine+")" +" "+slide.administeredDate + '</li>';
            }
           
          });

          str += '</ol>';
          vacclist = str;



          var vaccinationCertificate: any = document.getElementById('vaccinationCertificate').textContent;

          vaccinationCertificate = vaccinationCertificate + "<br>" + vacclist;
          this.templateText = vaccinationCertificate;
        }


        else if (this.showOtherAddmission) {

          var admissionCertificate: any = document.getElementById('admissionCertificate').textContent;

          admissionCertificate = "To<br>PER/IPD<br>" + this.hospitalName + ", " + this.hospitalCity + "<br>" + admissionCertificate
          this.templateText = admissionCertificate;
          console.log("admissionTest", this.templateText)
        }


      }
   if(this.selectedCertiType=='Vaccination Due'){
     this.templateText=this.first_name+" "+this.last_name+"<br>As of date the following vaccines records are  not available, Consider all above vaccines as necessary, You are advised to the take marked vaccines on priority as per the recommended date."

     var vaccinDueList=this.selectedVaccination ? this.selectedVaccination:[]
     vaccinDueList.map(ins=>{
       if(ins.date){
         ins.date=moment((ins.date)).format('DD-MM-YYYY')
       }
     })
   }
   else if(this.selectedCertiType=='Total Due Vaccination'){
     this.templateText=this.first_name+" "+this.last_name
    var vaccinDueList=this.allVaccinDueList ? this.allVaccinDueList:[]

    vaccinDueList.map(ins=>{
      if(!ins.dueDate){
        ins.dueDate=""

      }
    })
   }
    var postData = {
      patient_id: this.patientResponse.id ? this.patientResponse.id : this.patientResponse._id,
      action: action === 'share' ? 'share' : action,
      date: moment(this.parseDate(this.selectedDate)).format('YYYY-MM-DD'),
      type: 'New Certificate',
      text: this.templateText,
      docName: this.docName,
      vaccinDueList:vaccinDueList ? vaccinDueList:'',
      certificateType: this.selectedCertiType,
      "templateName": this.templateName

    }

    console.log("TEST", JSON.stringify(postData));

    this.patientService.printNewCertificate(postData).subscribe(async response => {
      if (response) {

        console.log("res", response)
        this.closeDialog();
        this.certiPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(response.url);
        this.certisURL = response.url;
        if (action === 'preview') {
          this.openDialog(this.viewCertiModal, 'viewCertiModal');
        }
        else if (action == 'share') {
         // this.tab = "History"
         // this.selectedIndex = 4
         this.getAllDoctorPrescriptions()
          this.refreshservice.documentRefershed();
          this.messageService.add({ severity: 'custom', detail: 'Certificate saved and shared with patient successfully' });
          // const result = await ({
          //   message:
          //    'Use this link to view certificate\n' + response.data.url,

          // });
        
        }

        // this.getAllReceipt();
      }
    });

  }
  }
  onClickViewDocuments(document){
    this.documentPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(document.url);
    this.documentURL = document.url;
    this.openDialog(this.viewDocumentsModal, 'viewDocumentsModal');
  }

  onClickViewDischarge(document) {
    
       this.documentPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(document.url);
      this.documentURL = document.url;
      console.log("documentURL", this.documentURL);
    setTimeout(() => {
   this.openDialog(this.viewSaveDischargeModal, 'viewSaveDischargeModal');
    }, 200); 
  }
  



 
  getVaccData(element){
    this.selectedVaccination.push(element)
  
  }
  selectedRowIndex = -1;
  selectedRows(item){
    this.selectedRowIndex = item.id;
  
  }



  getAllDocumentsOfPatient = () => {
  //  this.allPatientCertificate=[]
    var postData = {
      patient_id: this.patientResponse._id,
    }
    this.patientService.getAllDocumentsOfPatient(postData).subscribe(response => {

      if (response) {
        console.log("disssss",response)

        for (let index = 0; index < response.documents.length; index++) {

          const element = response.documents[index];
          //  const docDate=response.documents[index].documentDate
          var dateTime = moment(response.documents[index].documentDate).format('YYYY-MM-DD');
          // console.log("docDatee", dateTime);
          let today = new Date(element.createdAt)
          let month = null
          month = today.getMonth() + 1
          if (month < 10) {
            { month = '0' + month }
          }
          element.createdAt = `${today.getDate()}/${month}/${today.getFullYear()}`

        }
      //  this.patientAllDocumnets = response.documents;
      var certificateArr:any=[]
      console.log("dischargeSummary",response.documents)
        response.documents.map(ins=>{
          if(ins.tests == 'Patient Discharge Summery'){
          //  this.onClickViewDischarge(ins.url)

          var objDocument:any={}
            objDocument["documentDate"]=ins.documentDate
            objDocument["documentType"]="Discharge Summary"
            objDocument["uploadedBy"]=ins.uploadedBy
            objDocument["url"]=ins.url
           // certificateArr.push(objDocument)
           certificateArr.push(objDocument)
          }
        })
      }
      this.allPatientCertificate=this.allPatientCertificate.concat(certificateArr)
      console.log("certSep5",this.allPatientCertificate)

      this.allPatientCertificate =  this.allPatientCertificate.reduce((unique, o) => {
        if (!unique.some(obj => obj.url == o.url)) {
          unique.push(o);
        }
        return unique;
      }, [])
  
    })
  }

  previewDischargeSummary() {
    // Store data in local storage before opening the preview
    this.storeDataInLocalStorage();

    setTimeout(() => {
        this.openDialog(this.previewDischargeModal, "previewDischargeModal");
    }, 300); 
}

handleEnterKey(event: KeyboardEvent) {
  console.log("handleEnterKey fired");
  
  // Check if the pressed key is 'Enter'
  if (event.key === 'Enter') {
      // Insert a newline character into the address variable
      this.address += "\n";
      // Prevent the default action of the Enter key (e.g., form submission)
      event.preventDefault();
  }
}
openEditDialog() {
  const patientId = this.patientResponse._id; // Ensure the patient ID exists in patientResponse
  const patientDataKey = `dischargeSummaryData_${patientId}`;
  
  // Retrieve data from local storage
  const dischargeSummaryData = JSON.parse(localStorage.getItem(patientDataKey));
  
  // Open the modal with data if it exists
  if (dischargeSummaryData) {
    // Assign the discharge summary data to component variables for two-way binding
    this.refbyDr = dischargeSummaryData.refbyDr || "";
    this.address = dischargeSummaryData.address || "";
    this.dateOfAdmission = dischargeSummaryData.dateOfAdmission ? new Date(dischargeSummaryData.dateOfAdmission) : null;
    this.dateOfDischarge = dischargeSummaryData.dateOfDischarge ? new Date(dischargeSummaryData.dateOfDischarge) : null;
    this.wtoa = dischargeSummaryData.wtoa || "";
    this.wtod = dischargeSummaryData.wtod || "";
    this.diagnosis = dischargeSummaryData.diagnosis || "";
    this.co = dischargeSummaryData.co || "";
    this.hopd = dischargeSummaryData.hopd || "";
    this.dischargeCondition = dischargeSummaryData.dischargeCondition || "";
    this.onDischargeRx = dischargeSummaryData.onDischargeRx || "";
    this.advise = dischargeSummaryData.advise || "";
    this.hr = dischargeSummaryData.hr || "";
    this.rr = dischargeSummaryData.rr || "";
    this.other = dischargeSummaryData.other || "";
    this.bp = dischargeSummaryData.bp || "";
    this.spo2 = dischargeSummaryData.spo2 || "";
    this.rs = dischargeSummaryData.rs || "";
    this.cvs = dischargeSummaryData.cvs || "";
    this.xrayFindings = dischargeSummaryData.xrayFindings || "";
    this.cns = dischargeSummaryData.cns || "";
    this.pa = dischargeSummaryData.pa || "";
    this.ctScan = dischargeSummaryData.ctScan || "";
    this.mri = dischargeSummaryData.mri || "";
    this.labInvistigation = dischargeSummaryData.labInvistigation || "";
    this.rxGiven = dischargeSummaryData.rxGiven || "";

    console.log("openEditDialog refbyDr", this.refbyDr);
    console.log("openEditDialog dateOfAdmission", this.dateOfAdmission);
    console.log("openEditDialog dateOfDischarge", this.dateOfDischarge);
    
    // Set a timeout to ensure the modal is opened after a short delay
    setTimeout(() => {
      this.openDialog(this.dischargeModal, "dischargeModal");
    }, 300); 
  }
}


createDischargeSummary() {
  var dateOfAdmission: any = "";
  var dateOfDischarge: any = "";

  if (this.dateOfAdmission != null) {
    dateOfAdmission = this.reverseDate(this.dateOfAdmission);
    dateOfAdmission = moment(this.parseDate(dateOfAdmission)).format('DD/MM/YYYY');
  }

  if (this.dateOfDischarge != null) {
    dateOfDischarge = this.reverseDate(this.dateOfDischarge);
    dateOfDischarge = moment(this.parseDate(dateOfDischarge)).format('DD/MM/YYYY');
  }

  let postData = {
    patientId: this.patientResponse._id,
    address: this.address,
  
    
    wtoa: this.wtoa,
    wtod: this.wtod,
    dateOfAdmission: dateOfAdmission,
    dateOfDischarge: dateOfDischarge,
    refbyDr: this.refbyDr,
    diagnosis: this.diagnosis,
    co: this.co,
    hopd: this.hopd,
    hr: this.hr,
    rr: this.rr,
    other: this.other,
    bp: this.bp,
    spo2: this.spo2,
    rs: this.rs,
    cvs: this.cvs,
    xrayFindings: this.xrayFindings,
    cns: this.cns,
    pa: this.pa,
    ctScan: this.ctScan,
    mri: this.mri,
    labInvistigation: this.labInvistigation,
    rxGiven: this.rxGiven,
    dischargeCondition: this.dischargeCondition,
    onDischargeRx: this.onDischargeRx,
    advise: this.advise,
    templateName: this.templateName
  };
console.log("postData createDischargeSummary",postData);

  // Retrieve patient-specific data from local storage
  const patientId = this.patientResponse._id; // Ensure the patient ID exists in patientResponse
  const patientDataKey = `dischargeSummaryData_${patientId}`;
  const dischargeSummaryData = JSON.parse(localStorage.getItem(patientDataKey));
console.log("dischargeSummaryData in print",dischargeSummaryData);

  // Check if data exists in local storage
  if (dischargeSummaryData) {
    // Make API call to save discharge summary
    this.patientService.printPatinetDischarge(postData).subscribe(response => {
      if (response) {
        console.log("printPatinetDischarge",response);
        this.onClickViewDischarge(response);
        this.getAllDocumentsOfPatient();

        // Clear local storage after saving
        localStorage.removeItem(patientDataKey);
      }
    });
  } else {
    // If data doesn't exist in local storage, show an error or handle it accordingly
    console.error("No data found in local storage.");
  }
}

storeDataInLocalStorage() {
  const patientId = this.patientResponse._id; // Ensure the patient ID exists in patientResponse
  const patientDataKey = `dischargeSummaryData_${patientId}`;
  console.log("storeDataInLocalStorage called");
  
  let postData = {
      patientId: this.patientResponse._id,
      address: this.address,
      wtoa: this.wtoa,
      wtod: this.wtod,
      dateOfAdmission: this.dateOfAdmission ? this.dateOfAdmission.toISOString() : "",
      dateOfDischarge: this.dateOfDischarge ? this.dateOfDischarge.toISOString() : "",
      refbyDr: this.refbyDr,
      diagnosis: this.diagnosis,
      co: this.co,
      hopd: this.hopd,
      hr: this.hr,
      rr: this.rr,
      other: this.other,
      bp: this.bp,
      spo2: this.spo2,
      rs: this.rs,
      cvs: this.cvs,
      xrayFindings: this.xrayFindings,
      cns: this.cns,
      pa: this.pa,
      ctScan: this.ctScan,
      mri: this.mri,
      labInvistigation: this.labInvistigation,
      rxGiven: this.rxGiven,
      dischargeCondition: this.dischargeCondition,
      onDischargeRx: this.onDischargeRx,
      advise: this.advise,
      templateName: this.templateName
  };

  localStorage.setItem(patientDataKey, JSON.stringify(postData));
}

getdischargeSummary() {
  const patientId = this.patientResponse._id; // Ensure the patient ID exists in patientResponse
  const patientDataKey = `dischargeSummaryData_${patientId}`;
  
  // Retrieve data from local storage
  const dischargeSummaryData = JSON.parse(localStorage.getItem(patientDataKey)) || {};

  // Assign retrieved data to component properties, default to blank strings if not available
  this.wtoa = dischargeSummaryData.wtoa || "";
  this.wtod = dischargeSummaryData.wtod || "";
  this.dateOfAdmission = dischargeSummaryData.dateOfAdmission ? new Date(dischargeSummaryData.dateOfAdmission) : null;
  this.dateOfDischarge = dischargeSummaryData.dateOfDischarge ? new Date(dischargeSummaryData.dateOfDischarge) : null;
  this.refbyDr = dischargeSummaryData.refbyDr || "";
  this.diagnosis = dischargeSummaryData.diagnosis || "";
  this.co = dischargeSummaryData.co || "";
  this.hopd = dischargeSummaryData.hopd || "";
  this.hr = dischargeSummaryData.hr || "";
  this.rr = dischargeSummaryData.rr || "";
  this.other = dischargeSummaryData.other || "";
  this.bp = dischargeSummaryData.bp || "";
  this.spo2 = dischargeSummaryData.spo2 || "";
  this.rs = dischargeSummaryData.rs || "";
  this.cvs = dischargeSummaryData.cvs || "";
  this.xrayFindings = dischargeSummaryData.xrayFindings || "";
  this.cns = dischargeSummaryData.cns || "";
  this.pa = dischargeSummaryData.pa || "";
  this.ctScan = dischargeSummaryData.ctScan || "";
  this.mri = dischargeSummaryData.mri || "";
  this.labInvistigation = dischargeSummaryData.labInvistigation || "";
  this.rxGiven = dischargeSummaryData.rxGiven || "";
  this.dischargeCondition = dischargeSummaryData.dischargeCondition || "";
  this.onDischargeRx = dischargeSummaryData.onDischargeRx || "";
  this.advise = dischargeSummaryData.advise || "";
  this.address = dischargeSummaryData.address || "";
}


}
