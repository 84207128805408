import { Component, OnInit, Inject } from '@angular/core';
import * as $ from 'jquery'
import { DoctorService } from '../../../services/doctor.service'
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../../services/global.service'
import { AuthService } from '../../../services/auth.service'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { filter, cloneDeep, indexOf } from 'lodash'
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-letterhead-details',
  templateUrl: './letterhead-details.component.html',
  styleUrls: ['./letterhead-details.component.scss']
})
export class LetterheadDetailsComponent implements OnInit {
  currentStage: string = '3'
  userId: any = 1
  user: any = { doctor: {} }
  specialization: any = null
  degreeArray: any = []
  councilsArray: any = []
  specializations: any = []
  institutes: any = []
  specialitiesArray: any = []
  originalSpecialitiesArray: any = []
  subSpecialitiesArray: any = []
  originalSubSpecialitiesArray: any = []
  educationArray = []
  awardArray = []
  yearsArray = []
  clinicArray = []
  primaryClinicForLetterHead = 0
  primaryClinic: any = {}
  letterheadClinics: any = []
  path = ""
  isSubmit: Boolean = false
  agreeTerms: Boolean
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private authService: AuthService, private doctorService: DoctorService, private router: Router, private globalService: GlobalService, private toastr: ToastrService) {
    this.userId = this.authService.getUser()
    let params = {
      user_id: this.userId
    }
    const urlArray = location.href.split('/')
    this.path = urlArray[urlArray.length - 2].toLowerCase()
    this.doctorService.getDetails(params).subscribe(response => {
      if (response.success) {
        this.user = response.user
        this.user.doctor = response.doctor
        let doc_params = {
          doctor_id: this.user.doctor.id
        }
        this.doctorService.getClinics(doc_params).subscribe(response => {
          if (response.success) {
            this.clinicArray = response.clinics
            this.primaryClinic = this.clinicArray.filter(function (obj, index) {
              return obj.primary
            })[0]
            if (!this.primaryClinic) {
              this.primaryClinic = this.clinicArray[0]
              this.primaryClinicForLetterHead = 0
              this.primaryClinic.include_on_letterhead = 1
            } else {
              this.primaryClinicForLetterHead = indexOf(this.clinicArray, this.primaryClinic)
            }
            this.primaryClinic.primary = 1

            this.letterheadClinics = this.clinicArray.filter(function (obj) {
              return (obj.include_on_letterhead && !obj.primary)
            })
            //this.primaryClinic = response.clinics.find
          }
          else {
            this.toastr.error(response.error)
          }
        })
      } else {
        this.toastr.error(response.error)
      }
    })
    this.globalService.getConstants().subscribe(response => {
      this.degreeArray = response.degrees
      this.councilsArray = response.state_medical_councils
      this.specializations = response.specializations
      this.specialitiesArray = response.specialities
    })


  }

  ngOnInit() {
    this.setStage(this.currentStage)
  }

  setStage(stageNo) {
    this.currentStage = stageNo
    let headerText = ""
    switch (this.currentStage) {
      case "3":
        headerText = "DETAILS OF OFFICE PAPERS"
        break;
      case "5":
        headerText = "LET'S GET STARTED"
        break;
      default:
        headerText = "CLINICIAN'S PROFILE"
        break;
    }
    let topHeading = document.getElementsByClassName('topHeading')[0]
    topHeading.innerHTML = headerText
  }

  seveTemplateDetails() {
    this.isSubmit = true
    if (this.agreeTerms == true) {
      let params = {
        user_id: this.userId,
        clinics: this.clinicArray,
      }
      this.doctorService.updateClinics(params).subscribe(response => {
        if (response.success) {
          if (this.path == "editprofile") {
            this.toastr.success("Details have been updated successfully")
            this.router.navigateByUrl('/doctor/profileView')
          } else {
            this.setStage('5')
            //this.router.navigateByUrl('/doctor/appointments')
          }
        } else {
          this.toastr.error(response.error)
        }
      })
    }
  }

  radioChange(clinic, index) {
    this.primaryClinic.primary = 0
    this.primaryClinic.include_on_letterhead = 0
    let i = indexOf(this.letterheadClinics, this.primaryClinic)
    this.letterheadClinics.splice(i, 1)
    clinic.primary = 1;
    clinic.include_on_letterhead = 1
    this.primaryClinicForLetterHead = index;
    this.primaryClinic = clinic
  }

  groupSessions(clinic) {
    let forGroupingSession = cloneDeep(clinic.ClinicSchedules)
    let groupedSessions = {}
    forGroupingSession.map((day) => {
      if (day.day != null) {
        day.day = day.day.substring(0, 3)
        let key = ""
        if (day.morning_from != null && day.morning_to != null) {
          day.morning_from = day.morning_from.split(':')[0] == '0' ? '12:00 AM' : day.morning_from
          day.morning_to = day.morning_to.split(':')[0] == '0' ? '12:00 AM' : day.morning_to
          key = key + day.morning_from + ' to ' + day.morning_to
        }
        if (day.evening_from != null && day.evening_to != null) {
          day.evening_from = day.evening_from.split(':')[0] == '0' ? '12:00 AM' : day.evening_from
          day.evening_to = day.evening_to.split(':')[0] == '0' ? '12:00 AM' : day.evening_to
          if (key != "") {
            key = key + ', '
          }
          key = key + day.evening_from + ' to ' + day.evening_to
        }
        if (groupedSessions[key] && groupedSessions[key]['days'].length > 0) {
          groupedSessions[key]['days'].push(day)
        } else {
          groupedSessions[key] = {}
          groupedSessions[key]['days'] = []
          groupedSessions[key]['days'].push(day)
        }
      }
    })

    let sessions = Object.keys(groupedSessions).map(key => ({ time: key, value: groupedSessions[key] }))
    return sessions
  }

  previewLetterhead() {

    this.primaryClinic.groupedSessions = this.groupSessions(this.primaryClinic)
    this.letterheadClinics.forEach(clinic => {
      clinic.groupedSessions = this.groupSessions(clinic)
    });

    this.setStage("4")
  }

  addToLetterhead(clinic, index) {
    if (clinic.include_on_letterhead) {
      this.letterheadClinics.push(clinic)
    } else {
      this.letterheadClinics.splice(index, 1)
    }
  }
}
