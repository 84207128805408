import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs/Observable";
import { map } from "rxjs/operators/map";
import { Router } from "@angular/router";
import { Constants } from "../../config/constants";

@Injectable()
export class GlobalService {
  constructor(private http: HttpClient, private router: Router) { 
    if (Constants.APICONFIG.HOST == 'localhost'){
      this.APIURL = 'http://localhost:3000/v1';
      this.PIVOTALAPIURL = 'http://localhost:3200/api';
  }
  }

  noAuthHeader = { headers: new HttpHeaders({ NoAuth: "True" }) };
  APIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/v1`;
  PIVOTALAPIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/api`;


  //function to get frontend constants
  public getConstants(): Observable<any> {
    return this.http.get(`${this.APIURL}/constants`, this.noAuthHeader);
  }

  //function to get dugs list
  public getDrugs(query): Observable<any> {
    return this.http.post(`${this.APIURL}/getDrugs`, query);
  }

  //function to get dugs list
  public getImage(req_vars): Observable<any> {
    return this.http.post(`${this.APIURL}/getImage`, req_vars);
  }

  //function to get  master graph data
  public getGraphMasterData(req_vars): Observable<any> {
    //return this.http.get(`${this.APIURL}/graphMasterData`,this.noAuthHeader)
    return this.http.post(`${this.APIURL}/graphMasterData`, req_vars);
  }
  public sendAashaMail(): Observable<any> {
    return this.http.get(`${this.PIVOTALAPIURL}/v2/sendAashaMail`,);
  }
  public sendDoctorMail(): Observable<any> {
    return this.http.get(`${this.PIVOTALAPIURL}/v2/sendDoctorMail`,);
  }
  // public sendSmsOfAppInvite(req_vars): Observable<any> {
  //   return this.http.post(`${this.PIVOTALAPIURL}/v2/sendSmsOfAppInvite`,req_vars);
  // }
  public getOTPs(): Observable<any> {
    return this.http.get(`${this.PIVOTALAPIURL}/v2/getOTPs`,);
  }
  public getPatientHealthAppDownloadReport(): Observable<any> {
    return this.http.get(`${this.PIVOTALAPIURL}/v2/reportHealthAppDownloads`,);
  }
  public showVaccinationReportForDoctor(req_vars): Observable<any> {
    //return this.http.get(`${this.APIURL}/graphMasterData`,this.noAuthHeader)
    return this.http.post(`${this.PIVOTALAPIURL}/v2/showVaccinationReportForDoctor`, req_vars);
  }

  public removeAccount(req_vars):Observable<any> {
    return this.http.post(`${this.PIVOTALAPIURL}/parent/deleteUser`, req_vars)
  }


}
