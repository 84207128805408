<section class="tabs-container">
	<ul class="tabs" [ngStyle]="{'transform': abc }">
		<ng-container *ngFor="let tab of tabs; let i=index">
			<li 
			
			[class.active]="selectedIndex===i" (click)="selectTab(i)" style="list-style:none;
			"
				[ngClass]="(selectedIndex === i) ?  'tab' :'tab-inctive'">{{tab}}
			</li>
		</ng-container>
	</ul>
</section>


<!-- 
<section class="tabs-container">
	<ul class="tabs1" [ngStyle]="{'transform': abc1 }">
		<ng-container *ngFor="let subtab of requisitionTab; let i=index">
			<li 
			
			[class.active]=" ReqselectedIndex===i" (click)="selectTabrequisition(i)" style="list-style:none;
			"
				[ngClass]="( ReqselectedIndex=== i) ?  'tab' :'tab-inctive'">{{subtab}}
			</li>
		</ng-container>
	</ul> 
	
</section> -->