import { Component, OnInit, Inject, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { openClose } from '../../../../animations'
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms'
import * as $ from 'jquery'
import { GlobalService } from '../../../../services/global.service'
import { AdminService } from '../../../../services/admin.service'
import { AuthService } from '../../../../services/auth.service'
import { AutoCompleteModule } from 'primeng/autocomplete';
import { debounce } from 'lodash';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../../../../services/storage.service'

@Component({
  selector: 'app-doctor-add',
  templateUrl: './doctor-add.component.html',
  styleUrls: ['./doctor-add.component.scss']
})
export class DoctorAddComponent implements OnInit {

  mask: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  registrationMask: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  yourPracticeSec = true
  confirmInfoSec = false
  infoNotVerified = false;
  thankYouSection = false;
  notOnboarded: Boolean = false;
  otherDocthankYou: Boolean = false;
  interestPage: Boolean = false
  myFields: any = { firstName: false }
  //gender: string = "Female"
  signupForm: FormGroup
  otpForm: FormGroup
  firstName: FormControl
  lastName: FormControl
  dob: FormControl
  gender: FormControl
  email: FormControl
  phoneNumber: FormControl
  degree: FormControl
  password: FormControl
  confirmPassowrd: FormControl
  registeredName: FormControl
  registeredCouncil: FormControl
  registeredYear: FormControl
  registrationNumber: FormControl
  registrationValidity: FormControl
  speciality: FormControl
  subSpeciality: FormControl
  degreeArray: any = []
  councilsArray: any = []
  specialitiesArray: any = []
  originalSpecialitiesArray: any = []
  subSpecialitiesArray: any = []
  originalSubSpecialitiesArray: any = []
  yearsArray: any = []
  date: any
  futuredate: any
  minDate: any
  otp = null
  userId: any
  acceptPrivacy: Boolean = false
  lastTimeSent: any = moment();
  userInfo: any
  isSubmit: Boolean = false
  stdCode: any

  /*@HostListener('document:click', ['$event']) clickedOutside($event)
  {
    // here you can hide your menu
    let dobInput = document.getElementById('dobInput') as HTMLInputElement
    let validUptoInput = document.getElementById('validUptoInput') as HTMLInputElement
    let dobLabel = document.getElementById('dobLabel')
    let validUptoLabel = document.getElementById('validUptoLabel')

    if($event.target.id !== "dobInput") {
      if(dobInput.value == '' && dobLabel.className.indexOf('animatedLabel') > -1) {
        dobLabel.classList.remove('animatedLabel')
      }
    } else {
      dobLabel.classList.add('animatedLabel')
    }

    if($event.target.id !== "validUptoInput") {
      if(validUptoInput.value == '' && validUptoLabel.className.indexOf('animatedLabel') > -1) {
        validUptoLabel.classList.remove('animatedLabel')
      }
    } else {
      validUptoLabel.classList.add('animatedLabel')
    }

  }*/
  constructor(private storageService: StorageService, private authService: AuthService, private formBuilder: FormBuilder, private router: Router, private globalService: GlobalService, private adminService: AdminService, private toastr: ToastrService) {
    let d = new Date()
    localStorage.removeItem('lock')
    this.userId = authService.getAdminUser()
    this.date = { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() }
    this.futuredate = { year: d.getFullYear() + 31, month: d.getMonth() + 1, day: d.getDate() }
    this.minDate = { year: 1900, month: 1, day: 1 }
    this.stdCode = "91"
    this.storageService.removeDoctor()
    this.signupForm = this.formBuilder.group({
      firstName: [null, Validators.compose([
        Validators.required,
        Validators.pattern(/^[A-z ]+$/),
        Validators.minLength(1),
        Validators.maxLength(25)])
      ],
      lastName: [null, Validators.compose([
        Validators.required,
        Validators.pattern(/^[A-z ]+$/),
        Validators.minLength(1),
        Validators.maxLength(25)])
      ],
      dob: [null, [Validators.required]],
      gender: ['female', [Validators.required]],
      email: [null, Validators.compose([
        Validators.required,
        Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/)
      ])],
      phoneNumber: [null, Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(10)])
      ],
      password: [null, Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^\S*$/)
      ])],
      confirmPassword: [null, Validators.compose([
        Validators.required,
        Validators.minLength(6),
        Validators.pattern(/^\S*$/)
      ])],
      degree: [null, [Validators.required]],
      registeredName: [null, [Validators.required]],
      registeredCouncil: [null, [Validators.required]],
      registeredYear: [null, [Validators.required]],
      registrationNumber: [null, Validators.compose([
        Validators.required,
        Validators.minLength(5),
        Validators.maxLength(6)])
      ],
      registrationValidity: [null, [Validators.required]],
      speciality: [null, [Validators.required]],
      subSpeciality: [null, [Validators.required]]
    })

    this.adminService.getConstants().subscribe(response => {
      this.degreeArray = response.degrees
      this.councilsArray = response.state_medical_councils
      this.originalSpecialitiesArray = response.specialities
      this.specialitiesArray = response.specialities
      let currentYear = (new Date).getFullYear()
      let startYear = 1960
      for (let i = 0; i <= currentYear - startYear; i++) {
        this.yearsArray.push(startYear + i)
      }
    })

  }

  createItem(item = { otp: '' }): FormGroup {
    return this.formBuilder.group(item);
  }

  ngOnInit() {
  

  }

  searchSpecialities(e) {
    this.specialitiesArray = this.filterSpecialities(e.query, this.originalSpecialitiesArray)
  }

  filterSpecialities(query, list) {
    let filtered: any[] = [];
    for (let i = 0; i < list.length; i++) {
      let speciality = list[i];
      if (speciality.speciality.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(speciality);
      }
    }
    return filtered
  }

  clearSubSpeciality(e) {
    this.signupForm.controls['subSpeciality'].setValue(null)
  }

  getSubSpecialityList() {
    this.subSpecialitiesArray = this.signupForm.controls['speciality'].value.sub_speciality
    this.originalSubSpecialitiesArray = this.signupForm.controls['speciality'].value.sub_speciality
  }

  searchSubspecialities(e) {
    this.subSpecialitiesArray = this.filterSubSpecialities(e.query, this.originalSubSpecialitiesArray)
  }

  filterSubSpecialities(query, list) {
    let filtered: any[] = [];
    for (let i = 0; i < list.length; i++) {
      let speciality = list[i];
      if (speciality.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(speciality);
      }
    }
    return filtered
  }

  submitInfoBtn() {
    this.yourPracticeSec = false;
    this.confirmInfoSec = true;
    setTimeout(function () {
      document.getElementById('tab0').focus();
    }, 200)
    this.thankYouSection = false;
  }
  goBackBtn() {
    this.yourPracticeSec = true;
    this.confirmInfoSec = false;
    this.thankYouSection = false;
  }

  reqOtp() {
    /*this.infoNotVerified = true;
    this.yourPracticeSec = false;
    this.confirmInfoSec = false;
    this.thankYouSection = false;*/
    let params = {
      phone_number: this.userInfo.phone_number,
      otp_action: 'signup',
    }
    //let currentTime = moment()
    //var diff = moment.duration(currentTime.diff(this.lastTimeSent)).as('minutes')

    //if(diff > 2){
    this.adminService.generateOTP(params).subscribe(response => {
      if (response.success) {
        this.toastr.success("OTP has been sent to your registered mobile number")
        //this.lastTimeSent = moment()
        //this.infoNotVerified = true;
        //this.yourPracticeSec = false;
        //this.confirmInfoSec = false;
        //this.thankYouSection = false
        $('#resend-otp').addClass('not-active')
        setTimeout(function () {
          $('#resend-otp').removeClass('not-active')
        }, 120000);
      } else {
        this.toastr.error(response.error)
      }
    })
  }
  backToForm() {
    this.infoNotVerified = false;
    this.yourPracticeSec = true;
    this.confirmInfoSec = false;
    this.thankYouSection = false;
  }
  confirmOTP() {
    if (!this.otp) {
      this.otp = null
      this.toastr.error("Please enter valid OTP or click on resend button to receive new OTP")
    }
    else if (this.otp.length < 6) {
      this.otp = null
      this.toastr.error("Please enter valid OTP or click on resend button to receive new OTP")
    } else {
      this.userInfo.status = 'active'
      this.userInfo.user_type = 'doctor'
      let params = {
        // user_id: this.userId,
        otp_action: 'signup',
        otp: this.otp,
        userInfo: this.userInfo
      }
      this.adminService.register(params).subscribe(response => {

        if (response.success) {
          this.toastr.success("Doctor has been onboarded successfully")
          this.router.navigateByUrl(`/admin/users/doctors/view/${response.user_id}`)

        } else {
          this.toastr.error(response.error)
        }
      })
    }
  }



  addLabelClass(e) {

    if (e.target.parentElement.children && e.target.parentElement.children.length > 3) {
      if ($(e.target.parentElement.children[2]).hasClass('animatedLabel')) {
        $(e.target.parentElement.children[2]).addClass('animatedLabel')
      } else {
        $(e.target.parentElement.children[2]).removeClass('animatedLabel')
      }
    } else {
      $(e.target.parentElement.children[1]).addClass('animatedLabel')
    }
    // $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e) {

    if (e.target.value == '') {
      if (e.target.parentElement.children && e.target.parentElement.children.length > 3) {
        if ($(e.target.parentElement.children[2]).hasClass('animatedLabel')) {
          $(e.target.parentElement.children[2]).addClass('animatedLabel')
        } else {
          $(e.target.parentElement.children[2]).removeClass('animatedLabel')
        }
      } else {
        $(e.target.parentElement.children[1]).removeClass('animatedLabel')
      }
    }
  }

  addLabelAutoComplete(e) {
    $(e.target.parentElement.parentElement.parentElement.children[1]).addClass('animatedLabel')
  }

  removeLabelAutoComplete(e) {

    if (e.target.value == "") {
      $(e.target.parentElement.parentElement.parentElement.children[1]).removeClass('animatedLabel')
    } else {
      $(e.target.parentElement.parentElement.parentElement.children[1]).addClass('animatedLabel')
    }

  }



  capitalize(string) {
    return string != null ? string.charAt(0).toUpperCase() + string.substr(1).toLowerCase() : ''
  }

  parseDate(dateObject) {
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`
  }

  submit() {
    this.isSubmit = true;
    if (this.signupForm.controls['registeredName'].value) {
      this.signupForm.controls['registeredName'].setValue(this.signupForm.controls['registeredName'].value.trim())
    }
    if (!this.signupForm.invalid && this.acceptPrivacy) {

      this.userInfo = {
        user_id: this.userId,
        first_name: this.capitalize(this.signupForm.controls['firstName'].value),
        last_name: this.capitalize(this.signupForm.controls['lastName'].value),
        dob: this.parseDate(this.signupForm.controls['dob'].value),
        gender: this.signupForm.controls['gender'].value,
        email_id: this.signupForm.controls['email'].value,
        phone_number: `${this.stdCode}${this.signupForm.controls['phoneNumber'].value}`,
        password: this.signupForm.controls['password'].value,
        confirm_password: this.signupForm.controls['confirmPassword'].value,
        created_by_admin: 1
      }
      let doctorInfo = {
        degree: this.signupForm.controls['degree'].value,
        state_medical_council: this.signupForm.controls['registeredCouncil'].value,
        year_of_registration: this.signupForm.controls['registeredYear'].value,
        registration_number: this.signupForm.controls['registrationNumber'].value,
        registration_validity: this.parseDate(this.signupForm.controls['registrationValidity'].value),
        speciality: this.signupForm.controls['speciality'].value.speciality,
        sub_speciality: this.signupForm.controls['subSpeciality'].value,
        registered_name: this.signupForm.controls['registeredName'].value
      }
      this.userInfo.doctorInfo = doctorInfo
      this.adminService.validateUser(this.userInfo).subscribe(response => {
        if (response.success) {
          this.userId = response.user_id
          this.yourPracticeSec = false
          this.confirmInfoSec = true
          setTimeout(function () {
            document.getElementById('otp').focus();
          }, 200)
          setTimeout(function () {
            $('#resend-otp').removeClass('not-active')
          }, 120000);
        } else {
          this.toastr.error(response.error)
        }
      })
    }
    //this.router.navigateByUrl(`/admin/users/doctors`)
  }

  clearForm() {
    this.signupForm.reset()
    this.isSubmit = false
    this.acceptPrivacy = false
    this.signupForm.controls['gender'].setValue('female')
  }

  onKeyUp(event, id, nextInputIndex) {
    let keyCodeValue = event.keyCode;
    let input = event.target;
    let nexInput, newID;

    if (keyCodeValue != 32 && (event.key >= 0 && event.key <= 9) || keyCodeValue == 8 || keyCodeValue == 13) {
      if (keyCodeValue === 8) {
        nexInput = nextInputIndex - 1;
        newID = id + nexInput;
      } else if (keyCodeValue === 9) {
        nexInput = nextInputIndex;
        newID = id + nexInput;
      } else {
        nexInput = nextInputIndex + 1;
        newID = id + nexInput;
      }
      if (nexInput <= 5 && nexInput >= 0) {
        document.getElementById(newID).focus();
      }
    } else {
      $('#tab' + nextInputIndex).val('');
      return false;
    }
  }

  closeCalender(e, d) {
    if (d._elRef.nativeElement.parentElement.contains(e.target)) {

    } else {
      d.close()
    }
    if (e.target.offsetParent == null)
      d.close()
    else if (e.target.offsetParent && e.target.offsetParent.nodeName != "NGB-DATEPICKER" && (e.target.offsetParent.className.indexOf('calender') == -1 && e.target.offsetParent.className.indexOf('custom-select') == -1))
      d.close()
  }

  validateNumber(e) {
    return ((e.charCode > 47 && e.charCode < 58) || e.charCode == 46) || (e.which == 8) || (e.keyCode == 9)
  }
}

