import { Routes } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth/auth-layout.component';
import { HomePageComponent } from './shared/home-page/home-page.component';
import { SigninComponent } from './authentication/signin/signin.component';
import { DashboardLayoutComponent } from './layouts/dashboard-layout/dashboard-layout.component';
import { LoginComponent } from './admin-panel/login/login.component';
import { patientComponent } from './doctor/patient/patient/patient.component';
import { ForgotPasswordComponent } from './admin-panel/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './admin-panel/reset-password/reset-password.component';
import { LabHomeComponent } from './lab/lab-home/lab-home.component';
import { LabSidebarComponent } from './layouts/lab-sidebar/lab-sidebar.component';
import { DoctorPublicProfileComponent } from './doctor-public-profile/doctor-public-profile.component';
import { MonitoringDataComponent } from './monitoring-records/monitoring-data/monitoring-data.component';
import { NewQuickRxComponent } from './new-rx/new-quick-rx/new-quick-rx.component';
import { NewRxSidebarComponent } from './layouts/new-rx-sidebar/new-rx-sidebar.component';
import { RemovePatientAccountComponent } from './remove-account/patient/remove-patient-account/remove-patient-account.component';
import { RemoveDoctorAccountComponent } from './remove-account/doctor/remove-doctor-account/remove-doctor-account.component';
//import { ChartComponent } from './chart/chart.component';
//import { GraphsComponent } from './graphs/graphs.component';


export const AppRoutes: Routes = [
  {
    path: '',
    component: SigninComponent
  },
  {
    path: '',
    component: LabHomeComponent
  },
  {
    path: '',
    component: NewQuickRxComponent
  },
  {
    path: '',
    component: MonitoringDataComponent
  },
  {
    path: 'patient/removeAccount',
    component: RemovePatientAccountComponent
  },
  {
    path: 'doctor/removeAccount',
    component: RemoveDoctorAccountComponent
  },
  {
    path: 'login',
    component: SigninComponent,
  },
  {
    path: 'admin/forgotPassword',
    component: ForgotPasswordComponent,
  },
  {
    path: 'admin/resetPassword/:token',
    component: ResetPasswordComponent,
  },
  {
    path: '',
    component: DashboardLayoutComponent,
    children: [{
      path: 'doctor',
      loadChildren: () => import('./doctor/doctor.module').then(x => x.DoctorModule)
    }]
  },
  {
    path: '',
    component: NewRxSidebarComponent,
    children: [{
      path: 'newrx',
      loadChildren: () => import('./new-rx/new-rx.module').then(x => x.NewRxModule)
    }]
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [{
      path: 'authentication',
      loadChildren: () => import('./authentication/authentication.module').then(x => x.AuthenticationModule)
    }]
  },
  
  {
    path: '**',
    redirectTo: 'error/404'
  },
  {
    path: 'admin/login',
    component: LoginComponent,
  },


  {
    path: '',
    component: LabSidebarComponent,
    children: [{
      path: 'lab',
      loadChildren: () => import('./lab/lab.module').then(x => x.LabModule)
    }],
  },

  {
    path: '',
    component: MonitoringDataComponent,
    children: [{
      path: 'monitoring',
      loadChildren: () => import('./monitoring-records/monitoring-records.module').then(x => x.MonitoringRecordsModule)
    }],
  },

  {
    path: '',
    component: AdminLayoutComponent,
    children: [{
      path: 'admin',
      loadChildren: () => import('./admin-panel/admin-panel.module').then(x => x.AdminPanelModule)
    }],
  },/*
  ,{
    path: 'charts',
    component: ChartComponent,
  },
  {
    path: 'graphs',
    component: GraphsComponent,
  }
  */
  {
    path: 'doctors/:city/:name/:id',
    component: DoctorPublicProfileComponent,
  },
];
