import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import * as $ from "jquery";
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter, pairwise, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-language-select-textbox',
  templateUrl: './language-select-textbox.component.html',
  styleUrls: ['./language-select-textbox.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LanguageSelectTextboxComponent),
      multi: true
    }
  ]
})
export class LanguageSelectTextboxComponent implements OnInit {
  value: any;

  // Implement ControlValueAccessor methods
  writeValue(value: any): void {
    this.value = value;
  }

  registerOnChange(fn: any): void { }

  registerOnTouched(fn: any): void { }

  setDisabledState?(isDisabled: boolean): void { }

  langaugeSearchData = [];
  languageText: string = "";
  languageOption: any
  lanuguageData: string[] = []
  languageName = ""
  @Input() nextFolloupPreview: string;
  @Input() langSelectedText: string;
  @Input() stylesForRow: any = {};
  @Input() patientSelectedLanguage
  @Input() placeholder: string;
  @Input() textboxvalue: string
  @Input() stylesFor: any = {}
  @Input() styles: any = {};
  @Input() styleFlagForUi: boolean
  @Input() callBack: (args) => void
  langtextboxvalue: string
  @Input() width
  @Input() styleFlag: boolean
  @Input() styleForWidth: string
  lanuageForName: any
  @Output() langSelectedTextChanged: EventEmitter<string> = new EventEmitter<string>();
  selectedLanguage: any = [{ name: "EN", value: "" }, { name: "म", lang: "mr", value: "mr-t-i0-und" }, { name: "Hi", lang: "hi", value: "hi-t-i0-und" }, { name: "Kn", lang: 'kn', value: "kn-t-i0-und" }]
  constructor() { }

  ngOnInit(): void {
    this.languageText = ""
    if (!this.languageText) {
      this.languageText = '';
    }

    this.setLanguage("en"); //init is english
    this.langtextboxvalue = this.languageText
    console.log("languageText", this.patientSelectedLanguage)

  }
  ngOnChanges(changes: SimpleChanges): void {
    // Check if languageText input property changed
    if (changes.languageText) {
      // Update local languageText when input property changes
      this.languageText = changes.languageText.currentValue;
      this.onInputChange();
      console.log("this.languageText", this.languageText);


    }
  }

  onDropdownSelectLanguage = (item) => {
    this.lanuageForName = item
    this.selectedLanguage.map(ins => {
      if (ins.name == item) {
        this.languageName = ins.value
      }
    })

  }
  closeAllLists(elmnt) {
    /*close all autocomplete lists in the document,
    except the one passed as an argument:*/
    var x = elmnt.split(' ');
    console.log("elem", x.join())
    console.log("ther", x.shift())

  }



  languageTranslatore(event, languageData) {
    //console.log(event, languageData);

    var arr = []
    var val = languageData;
    //this.langtextboxvalue=""

    if (!val) {
      this.langtextboxvalue = "";
      return false;
    }
    if (val.includes(" ")) {
      var currentarray = val.match(/\S+/g) || [];
      //console.log(currentarray);
      if (currentarray.length > 0 && val.substr(val.length - 1) != ' ') {
        val = currentarray[currentarray.length - 1];
      } else {
        val = "";
      }
    }

    if (event.keyCode == 40 || event.keyCode == 38 || event.keyCode == 13) {
      // ?????
    }

    if (event.keyCode == 32) {
      //if press spacebar try to add first value
      //console.log("pressed spacebar ");
      if (this.langaugeSearchData.length > 0) {
        event.preventDefault();
        //remove lasttyed and replace with trasnlated
        var earlierarray = this.languageText.split(" ");
        //remove first whitespace if any
        if (earlierarray[0] == ' ') {
          earlierarray = earlierarray.slice(1, earlierarray.length);
        }
        //check if last was not already a spacebar
        if (earlierarray[earlierarray.length - 1] != ' ') {
          this.languageText = earlierarray.slice(0, earlierarray.length - 2).join(" ");
          if (this.languageText == '') {
            this.languageText = this.langaugeSearchData[0] + " ";
          } else {
            this.languageText = this.languageText + " " + this.langaugeSearchData[0] + " ";
            this.langtextboxvalue = this.languageText
          }
          this.langtextboxvalue = this.languageText
          //closeAllLists()
          this.langaugeSearchData = [];
        } else {
          this.langaugeSearchData = [];
        }
      }
    }

    let langvar: any = this.languageName
    if (langvar && langvar != '' && val.trim() != '') {
      $.ajax({
        async: false, url: 'https://inputtools.google.com/request?itc=' + langvar + '&num=13&cp=0&cs=1&ie=utf-8&oe=utf-8&app=demopage&text=' + val, success: function (data) {

          if (data[1][0]) {
            arr = data[1][0][1];
            if (arr.length > 5) { arr = arr.slice(0, 5) }
            if (arr.length > 0) {
              arr.push(val);
              this.langaugeSearchData = arr;
              //console.log("lang",this.langaugeSearchData)
            }
          }
        }
      });
      this.langaugeSearchData = arr;
      //console.log("lanArr",this.langaugeSearchData)
    } else { }
  }


  searchLanguageByTyping = (text$: Observable<string>) =>
    text$.pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map(term => term === '' ? []
        : this.langaugeSearchData.filter(v => JSON.stringify(v)))
    )

  formatter = (result: any) => { return this.languageText };

  // formatMatches = (result: any) => this.languageText+ result;

  formatMatches = (result: any) => {
    //console.log(this.languageText);
    return this.languageText + ' ';
  };
  selectedItem = ({ item }) => {

    var earlierarray = this.languageText.split(" ");
    this.languageText = earlierarray.slice(0, earlierarray.length - 1).join(" ") + " " + item;
    console.log("lang select tb 1.0", this.languageText);
    this.langaugeSearchData = []
    console.log("lang select tb 2.0", this.languageText);
    this.langtextboxvalue = this.languageText
  }

  getValue() {
    // if (this.languageText) {
    //   this.langtextboxvalue = this.languageText
    // }

    // console.log("lang select tb", this.languageText, this.langtextboxvalue);
    if (this.langtextboxvalue) {
      if (this.languageText && (this.languageText.length > this.langtextboxvalue.length)) {
        
        return this.languageText;
      } else {
        return this.langtextboxvalue;
      }

    }
    if (this.languageText) {
      return this.languageText;
    }
    return "";
  }

  onInputChange() {
    console.log('Language Text Changed:', this.languageText);
    this.langSelectedTextChanged.emit(this.languageText);


    // Additional logic if needed
  }
  setTextBoxValue(additionaText: string) {
    // this.langtextboxvalue = additionaText;
    this.languageText = additionaText;
  }

  setLanguage(language: string) {
    this.patientSelectedLanguage = language;
    if (this.patientSelectedLanguage == 'mr') {
      this.lanuageForName = "म"
      this.languageName = "mr-t-i0-und"
    }
    else if (this.patientSelectedLanguage == 'hi') {
      this.lanuageForName = "Hi"
      this.languageName = "hi-t-i0-und"

    }
    else if (this.patientSelectedLanguage == 'kn') {
      this.lanuageForName = "Kn"
      this.languageName = "kn-t-i0-und"

    }
    else {
      this.lanuageForName = "EN"
    }
  }

  appendTexttoBox(additionaText: string) {

    if (this.languageText.length > 2) {

      this.languageText = this.languageText + ", " + additionaText;
    }
    else {
      this.languageText = additionaText;
    }

  }
  clearTextBox() {
    this.langtextboxvalue = "";
    this.languageText = ""
  }

}
