
import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { PatientService } from '../../../services/patient.service'
import * as moment from 'moment';
import * as _ from "lodash";

const PATIENT_DOB_FORMAT = "DD-MMM-YYYY";
const currentColor = 'green';
const currentVisitColor = 'red';


@Component({
  selector: 'app-bp-graph',
  templateUrl: './bp-graph.component.html',
  styleUrls: ['./bp-graph.component.css']
})
export class BpGraphComponent implements OnInit {

  @Input() isForPopup: false;
  chart: Chart;
  graphSeries = [];
  xAxisPlotLines = null;
  xAxisTitle = '';
  seriesX = [];
  totalRealWeeks = [];
  categories:any = [];
  birthWeek = 40;
  patient:any = { gender: 'Male' }
  isPreTerm = 0;
  height= 400;
  width = 550;
  constructor(private patientService: PatientService,) { }

  ngOnInit(): void {
    // this.init();
 
    this.patient = JSON.parse(localStorage.getItem('patientData'));

    // var printchart = this.chart.ref
    if (this.isForPopup) {
      this.width = 1000;
      this.height = window.innerHeight - 180;
    }

    const PATIENT_DOB_FORMAT = "DD-MMM-YYYY";
    const actualDate = moment(this.patient.DOB, PATIENT_DOB_FORMAT); // sept 18 2018
    const expectedDate = moment(this.patient.eDOB, PATIENT_DOB_FORMAT);// oct 15 2018
    const refDate = expectedDate.subtract(40, "weeks"); //january 8, 2018
    const diffWeeks = actualDate.diff(refDate, "days"); // 253
    const gestationalAge = {
      w: Math.round(Number(diffWeeks / 7)), // 36
      d: diffWeeks % 7 // 1
    };
    this.birthWeek = gestationalAge.w;
    var weeks = this.calculateWeek(new Date(this.patient.DOB).getTime(), new Date(this.patient.eDOB).getTime());
    if (weeks < 17 && weeks >= 13) {
      this.isPreTerm = 2;
    } else if (weeks < 13 && weeks > 2) {
      this.isPreTerm = 1;
    }
    else {
      this.isPreTerm = 0;
    }

    this.getGraphs();

  }

  calculateWeek(d1, d2) {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
  }

  getGraphs() {
    // const data = {
    //   graphType: this.patient.gender == 'Male' ? 'weightBoys' : 'weightGirls',
    //   patientId: this.patient._id ? this.patient._id : this.patient.id ,
    //   visitId: this.patient.visits[0]._id,
    //   weeksToShow: null,
    // };
    // this.patientService.getPatientsWeightGraph(this.patient._id, this.patient.visits[0]._id, data).subscribe(response => {

    //   this.graphSeries = response.graphData,
    //     this.xAxisPlotLines = response.xAxisPlotLines,
    //     this.xAxisTitle = "Gestational Age / Weeks",
    //     this.seriesX = response.seriesX
    //   this.getRealAge();

    // })


    



    let patientsDiaGraphData = [];


 var sortedVisits = _.sortBy(this.patient.visits, ["date"]);
  for (let index = 0; index < sortedVisits.length; index++) {
      const element = sortedVisits[index];
      let value = parseFloat(element['bpDia']);
      value = +value.toFixed(2);
      var age = this.getAgeinDecimal(this.patient.DOB, element.date);
     if (value > 0) { //ignoring garbage values
      let obj = {date: moment(element.date).format('DD-MMM-YYYY'),
      age: this.dateDiff(this.patient.DOB, element.date),
      value:value};
      patientsDiaGraphData.push({x: age, y: value, extraData: obj})
     }
    }



  let patientsSysGraphData = [];

for (let index = 0; index < sortedVisits.length; index++) {
  const element = sortedVisits[index];
  let value = parseFloat(element['bpSys']);
  value = +value.toFixed(2);
  var age = this.getAgeinDecimal(this.patient.DOB, element.date);
 if (value > 0) { //ignoring garbage values
  let obj = {date: moment(element.date).format('DD-MMM-YYYY'),
            age: this.dateDiff(this.patient.DOB, element.date),
            value:value};
  patientsSysGraphData.push({x:age, y:value, extraData: obj})
 }
}

// patientsDiaGraphData = patientsDiaGraphData.filter(ele => {
//     return ele.y > 0
// });

// patientsSysGraphData = patientsSysGraphData.filter(ele => {
//   return ele.y > 0
// });


var graphData = [{
  marker: {
      enabled: true,
      symbol: "circle",
      radius: 3
  },
  color: currentColor,
  name: "BPDia",
  data: patientsDiaGraphData,
},{
marker: {
    enabled: true,
    symbol: "circle",
    radius: 3
},
color: currentVisitColor,
name: "BPSys",
data: patientsSysGraphData,
}]

this.graphSeries = graphData;

var firstVisitDate = moment(this.patient.visits[0]["date"]);

var lastVisitDate = moment(this.patient.visits[this.patient.visits.length - 1]["date"]);

var result = [];

if (lastVisitDate.isBefore(firstVisitDate)) {
    throw "End date must be greated than start date."
}      

while (firstVisitDate.isBefore(lastVisitDate)) {
    result.push(firstVisitDate.format("YYYY-MM-DD"));
    firstVisitDate.add(1, 'month');
}

// this.categories = result;


this.xAxisPlotLines = [
  {
    "color": "#0000ff",
    "width": 2,
    "value": 40
  },
  {
    "color": "#0000ff",
    "width": 2,
    "value": 300
  }
];

this.xAxisTitle = "";

this.seriesX = result;
this.init();
}


   calculatePediatricAge(patient, visitDate) {
    var dt = moment();
    if (visitDate) {
        dt = moment(visitDate);
    }
    const diffInDays = moment(visitDate).diff( // oct 18 2018 - sept 18
        moment(patient.DOB, PATIENT_DOB_FORMAT),
        "days"
    );

    var diffInWeeks = (diffInDays / 7).toFixed(2);
    return Number(patient.gestationalAge.w) + Number(diffInWeeks);

}


getAgeinDecimal(bithDate, visitDate) {
  var b = moment(bithDate, PATIENT_DOB_FORMAT);
  var a = moment(visitDate);
  var diffDays = a.diff(b, 'days');
  return diffDays / 365 ;
}


 dateDiff(bithDate, visitDate) {
  var b = moment(bithDate, PATIENT_DOB_FORMAT);
  var a = moment(visitDate);
  var intervals:any = ['years', 'months', 'weeks', 'days'];
  var y; var m; var w; var d;

  for (var i = 0; i < intervals.length; i++) {
      var diff = a.diff(b, intervals[i]);
      b.add(diff, intervals[i]);
      if (i == 0) {
          y = diff != 0 ? diff + 'y ' : '';
      }
      else if (i == 1) {
          m = diff != 0 ? diff + 'm ' : '';
      }
      else if (i == 2) {
          w = diff != 0 ? diff + 'w ' : '';
      }
      else {
          d = diff != 0 ? diff + 'd ' : '';
      }
  }

  return y + m + w + d;
}

  getRealAge() {
    var realWeeksArray = [];
    var uniqueArray = [];
    var initial = 0;
    var dataArray = [];
    var allGweeksArray = [];
    var labelArray = [];
    if (this.graphSeries.length) {
      dataArray = this.graphSeries[0].data

      for (var i = 0; i < dataArray.length; i++) {
        allGweeksArray.push(dataArray[i][0]);
      }
    }

    for (var j = 0; j <= allGweeksArray[allGweeksArray.length - 1]; j++) {

      realWeeksArray.push(initial++);
    }

    for (let i = 0; i < realWeeksArray.length; i++) {
      if (this.isPreTerm != 0) {
        if (realWeeksArray[i] < (41 - this.birthWeek)) {
          var weeks: any = 0;
          weeks = this.getWeekAndDaysFromDays(realWeeksArray[i] * 7)
          labelArray.push(weeks);
        }
        else if (realWeeksArray[i] < 53) {
          var months: any = 0;
          months = this.getMonthAndDaysFromDays(realWeeksArray[i] * 7)
          labelArray.push(months);
        }
        else {
          var years: any = 0;
          years = this.getYearAndMonthFromDays(realWeeksArray[i] * 7)
          labelArray.push(years);
        }
      }
      else {
        if (realWeeksArray[i] < 5) {
          var weeks: any = 0;
          weeks = this.getWeekAndDaysFromDays(realWeeksArray[i] * 7)
          labelArray.push(weeks);

        }
        else if (realWeeksArray[i] < 53) {
          var months: any = 0;
          months = this.getMonthAndDaysFromDays(realWeeksArray[i] * 7)
          labelArray.push(months);
        }
        else {
          var years: any = 0;
          years = this.getYearAndMonthFromDays(realWeeksArray[i] * 7)
          labelArray.push(years);
        }
      }

    }

    this.totalRealWeeks = realWeeksArray,
      this.categories = labelArray
    this.init();
  }

  getYearAndMonthFromDays(numberOfDays) {
    var years = Math.floor(numberOfDays / 365);
    var months = Math.floor(numberOfDays % 365 / 30);
    var yearsDisplay = years > 0 ? years + (years == 1 ? "y" : "y") : "";
    var monthsDisplay = months > 0 ? months + (months == 1 ? "m" : "m") : "";
    return yearsDisplay + monthsDisplay;
  }
  getWeekAndDaysFromDays(numberOfDays) {
    var weeks = Math.floor(numberOfDays / 7);
    var days = numberOfDays % 7;
    var weekDisplay = weeks > 0 ? weeks + (weeks == 1 ? "w" : "w") : "";
    var daysDisplay = days > 0 ? days + (days == 1 ? "d" : "d") : "";
    return weekDisplay + daysDisplay;
  }
  getMonthAndDaysFromDays(numberOfDays) {
    var months = Math.floor(numberOfDays % 365 / 30);
    var days = Math.floor(numberOfDays % 365 % 30);


    var monthsDisplay = months > 0 ? months + (months == 1 ? "m" : "m") : "";
    var daysDisplay = days > 0 ? days + (days == 1 ? "d" : "d") : "";

    return monthsDisplay + daysDisplay;
  }


  getConf() {
    let patientInformation = "";
    try {
      if (this.patient && this.isForPopup) {
        patientInformation = `${this.patient.firstName} (${this.patient.gender.split('')[0]} - ${this.patient.age.years}y ${this.patient.age.months}m ${this.patient.age.days}d)`
      }
    } catch (exception) {
      console.log(exception)
    }
    // let graphColor = this.patient ? (this.patient.gender === 'Male' ? '#CCFFFF' : '#FFCCFF') : '#fff';
    let graphColor =  '#fff';
    let tmpXAxis = [];

    tmpXAxis.push({
      plotLines: this.xAxisPlotLines,
      gridLineWidth: 1,
      gridLineColor: 'lightgray',
      tickInterval: 1,
      minorTickInterval: 0.142,
      min: this.birthWeek,
      "opposite": true,
      title: {
        text: this.xAxisTitle,
        style: {
          fontSize: '15px',
          fontWeight: 'bold'
        }
      },
      labels: {
        style:
        {
          color: "#555555",
          fontSize: "15px",
          enabled: true
        }
      }
    });
    if (this.seriesX.length > 0) {
      tmpXAxis.push({
        "title": {
          "text": 'Age',
          "style": {
            "fontSize": '15px',
            "fontWeight": 'bold'
          }
        },
        "opposite": false,
        categories: this.categories,
      
      });
    } else {
      tmpXAxis.push({
        "title": {
          "text": ' ',
          "style": {
            "fontSize": '15px',
            "fontWeight": 'bold',
          },
          "offset": 25
        },
        "opposite": true,
        "labels": {
          "color": "#555555",
          "fontSize": "15px",
          "enabled": true,
          "step": 1
        }
      });
    }
    const conf: any = {
      chart: {
        type: "line",
        marginRight: 10,
        backgroundColor: graphColor,
        spacingLeft: 0,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        plotBorderColor: '#C0C0C0',
        plotBorderWidth: 1,
      },
      
      tooltip: {

        //[AL-GR]
        crosshairs: {
          color: 'red',
          dashStyle: 'solid'
        },
        //[AL-GR]
        formatter: function () {
            return 'Age: <b>' + this.point.extraData.age + '<br/>'  + 'Date: <b>' + this.point.extraData.date + '</b>'+ '<br/>'+ this.series.name + ': <b>' + this.point.extraData.value + '</b>';
        },
        enabled:true,
        positioner: function () {
          return { x: 80, y: 60 };
        },
        shadow: false,
        borderWidth: 0,
        backgroundColor: 'transparent'
      },
        xAxis: {
          title: {
                  "text": 'Age (In Yrs.)',
                  "style": {
                    "fontSize": '15px',
                    "fontWeight": 'bold'
                  }
                },
            min: Number(this.patient.age.years) > 18 ? Number(this.patient.age.years) - 5 : 0,
            max: this.patient.age.years + 1 ,
            gridLineWidth: 1,
      gridLineColor: 'lightgray',
      tickInterval: 1,
      minorTickInterval: 0.142,
        },
    
        series: this.graphSeries,
        credits: {
          enabled: false
        },
        title: {
          text: patientInformation + '<br/><br/>' + "Blood Pressure",
        },
        plotOptions: {

          series: {
            states: {
              hover: { enabled: true }, //[AL-GR]
              inactive: {
                opacity: 1,
              }
            },
            lineWidth: 1,
            // point: {
            //   events: {
            //     click: function () {
            //       if (this.series.name == 'Current') {
            //         window.ReactNativeWebView.postMessage(
            //           JSON.stringify({ type: 'click', x: this.x })
            //         )
            //       } else {
            //         return false;
            //       }
  
            //     }
            //   }
            // }
          }
        },
        yAxis: {
          min: 0,
          gridLineWidth: 1,
          minorTickInterval: 'auto',
          gridLineColor: '#C5EEFA',
          title: {
            text: 'Values',
            style: {
              fontSize: '15px',
              fontWeight: 'bold'
            }
          },
          labels: {
            "color": "#555555",
            "fontSize": "15px",
            "enabled": true,
            "step": 1,
            formatter: function () {
              return this.value;
            }
          }
        },
        legend: {
          enabled: true,
          margin: 0,
          itemMarginTop: 6,
          itemMarginBottom: 8,
          itemStyle: {
            fontSize: '12px'
          }
        },
        "navigation": {
          "buttonOptions": {
            "enabled": false
          }
        },
      };
    return conf;
  }

  addPoint() {
    if (this.chart) {
      this.chart.addPoint(Math.floor(Math.random() * 10));
    } else {
      alert('init chart, first!');
    }
  }

  init() {
    setTimeout(() => {


      console.log("?>?>?>?>?>?>?>??>?>?>?>?>?>?>", JSON.stringify(this.getConf()));
    let chart = new Chart(this.getConf());
    // chart.addPoint(4);
    // chart.addPoint(5);
      this.chart = chart;
    }, 400);

  }

}
