<h3 style="margin-top: 2%;margin-left: 2%;">Orders</h3>   
<div class="dateinput">
  <mat-form-field appearance="outline">
    <input matInput [matDatepicker]="picker1" [(ngModel)]="fromDate">
    <mat-label>From :</mat-label>
    <mat-datepicker-toggle matIconSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <input matInput [matDatepicker]="picker2" [(ngModel)]="toDate">
    <mat-label>To :</mat-label>
    <mat-datepicker-toggle matIconSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>


 
    <div >
      <button  class="submitButton" (click)="getAllOrders()">Submit</button>
      <button *ngIf="openOrder"  class="submitButton" (click)="closeOrderDetails()">Back to Orders</button>

    </div>
 

    <!-- <div class="usersOrder"> -->
      <div style="display: flex; flex-direction: column; margin: 10px; width: 30%;">
        <div class="userInfo" >
          <div class="searchfield" *ngIf="!openOrder">
            <mat-form-field class="example-full-width" style="margin-top: 10px;">
              <mat-label>Search Orders by User</mat-label>
              <input matInput placeholder="9876543210" [(ngModel)]="searchNumber">
            </mat-form-field>
            <button (click)="getUserByContactNumber()"
                      style="border: 0px; background-color: transparent; outline: none; background-color: #3f51b5; height: 120%; border-radius: 5px;
                      margin: 4px 0 0 20px;width: 50px; height: 50px;">
              <img src="assets/images/search_icon_white.svg" alt="" />
            </button>
          </div>
          <div style="color: red;" *ngIf="!issearchNumberLengthIs10">
            <p>Number must be of 10 digits</p>
          </div>
  
          <!-- modal for displaying User data after searching based on number -->
          <div *ngIf="userData.length && !isUserSelected" class="searchOrdersByUser">
            <div *ngFor="let user of userData">
              <mat-card  class="searchContactCard" style="padding: 5px;" (click)="userSelected(user)">
                <mat-card-content><b>Name : </b>{{user.firstName}} {{user.lastName}}<br>
                  <b>Contact No : </b>{{user.contactNo}}</mat-card-content>
                <!-- <mat-card-content><b>Contact No : </b>{{user.contactNo}}</mat-card-content> -->
              </mat-card>
            </div>
          </div>
    
          <div *ngIf="isUserSelected">
            <mat-card class="searchContactCardSelected">
              <mat-card-content><b>User Name : </b>{{selectedUser.firstName}} {{selectedUser.lastName}}</mat-card-content>
              <mat-card-content><b>Phone Number : </b>{{selectedUser.contactNo}}</mat-card-content>
            </mat-card>
          </div>
        </div>
      </div> 
    <!-- </div> -->
</div> 
  
<div *ngIf="!openOrder" style="height: 80vh; overflow-y: auto;">
<table id="productsTable" mat-table [dataSource]="totalOrders"  style="width: 75%;">

  <ng-container matColumnDef="userName">
    <th mat-header-cell *matHeaderCellDef style="text-align: left;"> User Name </th>
    <td mat-cell *matCellDef="let order" style="text-align: left;"> {{order.User.first_name}} {{order.User.last_name}} </td>
  </ng-container>

  <ng-container matColumnDef="orderId">
    <th mat-header-cell *matHeaderCellDef> Order ID </th>
    <td mat-cell *matCellDef="let order"> {{order.id}} </td>
  </ng-container>

  <ng-container matColumnDef="grandTotal">
    <th mat-header-cell *matHeaderCellDef> Grand Total </th>
    <td mat-cell *matCellDef="let order"> &#8377; {{order.grandTotal}} </td>
  </ng-container>

  <ng-container matColumnDef="noOfItems">
    <th mat-header-cell *matHeaderCellDef> No Of Items </th>
    <td mat-cell *matCellDef="let order"> {{order.OrderItems.length}}</td>
  </ng-container>

  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let order"> {{order.createdAt | date :'mediumDate'}} </td>
  </ng-container>

  

  <tr mat-header-row *matHeaderRowDef="orderTableColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: orderTableColumns;" (click)="showOrderDetails(row)"></tr>

</table>
</div>


<div style="margin: 20px;" *ngIf="openOrder">
  <div *ngIf="orderData.length==0"><p>No item in Cart</p></div>
  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    
    <div style="display: flex; flex-direction: column;  margin: 50px 10px 10px 10px; width: 40%;">
      <div class="summery">
        <mat-card>
          <mat-card-title><b>Order Summery</b></mat-card-title>
          <mat-card-content><b>Order Id : </b> # {{orderData.id}}</mat-card-content>
          <mat-card-content><b>Total Items : </b> {{orderData.OrderItems.length}}</mat-card-content>
          <mat-card-content><b>Total Amount : </b>{{orderData.grandTotal}}</mat-card-content>
        </mat-card>
      </div>
      <div class="userInfo">      
        <mat-card>
          <mat-card-content><b>User Name : </b>{{orderData.User.first_name}} {{orderData.User.last_name}}</mat-card-content>
          <mat-card-content><b>Phone Number : </b>{{orderData.User.phone_number}}</mat-card-content>
          <!-- <mat-card-content><b>Address : </b><span>{{orderData.User.address}}</span>
            <span *ngIf="!orderData.User.address">
              Address not found
            </span>
          </mat-card-content> -->
        </mat-card>
      </div>
    </div>  
    
    <div *ngIf="orderData.OrderItems.length" class="orderDetails">
      <!-- <h4 style="text-align: center;"></h4> -->
      <div *ngIf="orderData && orderData.OrderItems && orderData.OrderItems.length" class="orderDetails">
        <h4 style="text-align: center;">Ordered Items</h4>
        <!-- Loop through order items -->
        <div *ngFor="let item of orderData.OrderItems">
          <mat-card>
            <mat-card-content>
              <!-- Display product name -->
              <b>Product Name:</b>
              <!-- Check if the product variation exists -->
              <span *ngIf="getProductVariation(item.productVariationId)">
                {{ getProductVariation(item.productVariationId).MasterProduct.productName }}
              </span>
              <!-- Fallback if the product variation is not found -->
              <span *ngIf="!getProductVariation(item.productVariationId)">
                Product not found
              </span>
            </mat-card-content>
            <mat-card-content><b>Quantity : </b>{{item.selectedUnit}} item of {{item.measurement}} each</mat-card-content>
      
            <!-- Display price at order time -->
            <mat-card-content>
              <b>Total Price:</b> {{ item.priceAtOrderTime }}
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      
    </div>
    
  </div>
</div>