<!-- <div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
  <label for="" class="loader"></label>
</div> -->
<ng-sidebar-container class="app opd-main-wrapper"
  [ngClass]="{'mode-boxed': isBoxed, 'sidebar-opened': isOpened, 'theme-light': theme == 'light', 'theme-dark': theme == 'dark', 'mode-push': _mode == 'push', 'mode-dock': _mode == 'dock', 'mode-over': _mode == 'over', 'mode-slide': _mode == 'slide'}"
  style="background-color: #fff; ">
  <ng-sidebar [(opened)]="isOpened" [(mode)]="_mode" [position]="'left'" [dockedSize]="'80px'"
    [autoCollapseWidth]="'991'" [closeOnClickOutside]="isOver()" [showBackdrop]="isOver()"
    [sidebarClass]="'sidebar-panel'" #sidebar>
    <nav
      style="background-color: #fff; font-size: 14px; text-transform: uppercase; display: flex; justify-content: center; align-items: center; padding: 12px; ">
      <a [routerLink]="['/lab/labHomePage']" title="home">
        <img _ngcontent-c0="" alt="" width="65px" src="assets/images/new_logo.svg">
       
      </a>

    </nav>
    
  
    <!-- main navigation -->
    <nav
      style="background-color: #fff; font-size: 14px; text-transform: uppercase; display: flex; justify-content: center; align-items: center; padding: 12px; border-right: 1px solid #eee;"
      class="menu" 
      style="background-color: #fff; margin-right: 0px; padding-right: 0px;">
      <ul class="navigation" appAccordion>

        <li class="navigation-item" appAccordionLink *ngFor="let menuitem of menuItems.getAll()"
          group="{{menuitem.state}}">
          <a class="navigation-link" routerLinkActive="active"
            (click)="otpSection = false; removeCase();  removeData();" [routerLink]="['/', 'lab', menuitem.state]"
            *ngIf="menuitem.type === 'link'" style="color: #000;">
          
            <!-- <span>{{ menuitem.name }}</span>
            <span class="mr-auto"></span>
            <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span> -->
          </a>

          <!-- HOME & VIEW RX -->
          <a class="navigation-link" routerLinkActive="active"
            (click)="otpSection = false; removeCase(); removeData(menuitem.name=='Home' ? 0 : 1);" appAccordionToggle
            [routerLink]="['/','doctor',menuitem.state]" *ngIf="menuitem.type === 'extLink'"
            style="height: 52px !important; border-radius: 6px; width: 52px;"
            [ngStyle]="{'background-color' : selectedIndex == 0 && menuitem.name=='Home' ? '#E2ECFB' : selectedIndex == 1 && menuitem.name=='View Rx' ? '#E2ECFB' : 'transparent'}">

            <div style="flex-direction: column; display: flex; justify-content: center; width: 55px;">
                <div style="display: flex;justify-content: center;">
                  <mat-icon class="blue-icon" *ngIf="menuitem.name=='Home' && selectedIndex == 0" 
                  alt="user" title="Home">home</mat-icon>
                <mat-icon *ngIf="menuitem.name=='Home' && selectedIndex != 0" 
                   alt="user" title="user">home</mat-icon>
  
               
            
              <mat-icon *ngIf="menuitem.name=='Settings'  && selectedIndex == 1" 
              >settings</mat-icon>
              <mat-icon *ngIf="menuitem.name=='Settings' && selectedIndex != 1"
               >settings</mat-icon>
                </div>

              <!-- [ngStyle]="{'color' : selectedIndex == 0  ? 'red' : '#ccc'}" -->
              <!-- <div style="display: flex;justify-content: center; font-size: 18px;"
                [ngStyle]="{'color' : selectedIndex == 0 && menuitem.name=='Home' ? '#4686be'  : selectedIndex == 1 && menuitem.name=='View Rx' ? '#4686be' : '#8B8B99'}">
                {{menuitem.name}}</div> -->
            </div>
            <!-- <span>{{ menuitem.name }}</span> -->
            <span class="mr-auto"></span>
            <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>

          <!-- PROFILE -->

       
        

        

       
      
       

          <!-- REPORTS -->
         
         
        </li>
        <li class="navigation-item" style="margin-left: 22px;"  >
          
            <div (click)="logout()"  style="cursor: pointer; flex-direction: column; display: flex; justify-content: center; width: 55px;">
              
              <mat-icon alt="user" title="Logout">logout</mat-icon>
  
            
          </div>
        </li>

      </ul>

      <!-- SHARE APP LINK -->
    
    </nav>


    

    <!-- <nav class="menu"  style="background-color: #fff; ">

    
      <a class="navigation-link" (click)="logout()" style="height: 50px !important">
        <div style="flex-direction: row; display: flex;  align-self: center;">

          <img src="assets/images/logout.svg" class="navbar-avatar rounded-circle svg" alt="user" title="user">

          <div style="margin-left: 6px; font-size: 18px" [ngStyle]="{'color' : false ? '#4686be'  :'#8B8B99'}">
            Logout</div>
        </div>
      </a>

    </nav> -->
    <!-- /main navigation -->
  </ng-sidebar>

  <div ng-sidebar-content class="app-inner">
 
  

    <div class="main-content">

      <router-outlet ></router-outlet>

    </div>
  </div>

</ng-sidebar-container>



<!-- utility dower popup designs  -->





<!-- utility popups ends he -->










<p-confirmDialog class="pConfirmZindex" appendTo="body"></p-confirmDialog>








