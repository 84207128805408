import { Component } from '@angular/core';
//import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './services/auth.service'


@Component({
  selector: 'app-root',
  template: '<router-outlet></router-outlet>'
})
export class AppComponent {
  
  constructor( private authService: AuthService) {
    // translate.addLangs(['en', 'fr']);
    // translate.setDefaultLang('en');

    // const browserLang: string = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    if(navigator.userAgent.indexOf('Mac') > 0){
      document.getElementsByTagName('body')[0].className = document.getElementsByTagName('body')[0].className + ' mac-os';
    }
  }


}