import { Injectable, Output, EventEmitter } from '@angular/core'

@Injectable()
export class patientServiceReload {

    isReload = true;

    @Output() reload: EventEmitter<boolean> = new EventEmitter();

    reloadPatients() {
        console.log('???????????', this.isReload);
        this.reload.emit(this.isReload);
    }

}


