import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-new-quick-rx-home',
  templateUrl: './new-quick-rx-home.component.html',
  styleUrls: ['./new-quick-rx-home.component.scss']
})
export class NewQuickRxHomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
