import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { PatientService } from '../../services/patient.service';
import { NavigationServiceService } from '../../services/navigation-service.service';
import { AuthService } from '../../services/auth.service';
import { DoctorService } from '../../services/doctor.service';
import { UtilService } from '../../util/utilService';
import { FormControl } from '@angular/forms';
import { refreshService } from '../../services/refersh.service';

import {Constants} from '../../../config/constants'
@Component({
  selector: 'app-new-rx-top-bar',
  templateUrl: './new-rx-top-bar.component.html',
  styleUrls: ['./new-rx-top-bar.component.scss']
})
export class NewRxTopBarComponent implements OnInit {
  @Input() ageObj: any = {};
  @Input() fistNameObj: any;

  @Input () patientApponmentById:any={}
  @Input () patientInfo:any={}
  @Input () patientAppointments:any={}
  @Input() patientGetPrescriptions: any = {};

  @Output() ageObjFromTopRx: EventEmitter<any> = new EventEmitter<any>();
  @Output() fnameObjFromTopRx: EventEmitter<any> = new EventEmitter<any>();

  selectedIndex=1
  patientId:any=null
  patientData:any={}
  firstName:any=""
  lastName:any=""
  patientNewData:any
  mobile:any=""
  showLoading:boolean=false;
  locality:any=""
  searchby_from_date
  searchby_name_number: string = ""
  userId:any=null
  order = []
  profilePic = "";
  first_name: any = ""
  middle_name: any = ""
    mobileNumber: any = ""
  familyMedicalConditions: string = ""
  allergies: string = ""
  recurringComplaints: string = ""
  accidentalHistory: string = ""
  last_name: any = ""
  medicalConditions: string = ""
  additionalInfoForPatient: any = ""

  allPatientInfo: any

  profileImagePath = Constants.APICONFIG.patientProfileImagePath
  myControlFav = new FormControl();
  appointmentCollection = [];
  appointmentCollectionArrived = [];
  appointmentCollectionAttending = [];
  appointmentCollectionDone = [];
  cancelledAppointments:any=[]
  originalAppointments: any;
  originalCopy: any = [];
  searchPatient:any=""
  searchAppData: any = {};
  gender:any="";
  purposeofvisit:any;
  localAgeObj: any = {};
  appointmentObjPurposeVisit:any={}
  constructor( private refreshservice: refreshService,private util: UtilService,private doctorService: DoctorService,private authService: AuthService,private navService: NavigationServiceService,private router: Router,private route: ActivatedRoute,private patientService: PatientService) { 
    this.updateAgeObj();
    this.updateName();
  }
  updateAgeObj() {
    // Update ageObj
    this.ageObjFromTopRx.emit(this.ageObj);
  }
  updateName(){
    this.fnameObjFromTopRx.emit(this.fistNameObj);


  }
  ngOnChanges(changes: SimpleChanges) {
  if(changes.patientGetPrescriptions && changes.patientGetPrescriptions.currentValue){
    this.purposeofvisit =  this.patientGetPrescriptions.appointment?.comment;
    console.log(" this.purposeofvisit1;", this.purposeofvisit);
    
  }
    if (changes.patientInfo && changes.patientInfo.currentValue) {
      // Check if the fistNameObj input has changed and has a value
      console.log('Received fistNameObj in child component:', this.fistNameObj);
      console.log('Received patientInfo in child component:', this.patientInfo);

      this.firstName = this.patientInfo.firstName;
      this.lastName = this.patientInfo.lastName;
      this.gender = this.patientInfo.gender
      this.gender = this.patientInfo.gender.toLowerCase() === 'male' ? 'M' : 'F';


      // You can perform further operations with this.fistNameObj here
    }
  }

  ngOnInit(): void {
    console.log("patientApponmentById in topbar from newRx",this.patientApponmentById);
    console.log("patientInfo in topbar from newRx",this.patientInfo);
    console.log("patientAppointments in topbar from newRx",this.patientAppointments);
    console.log("patientGetPrescriptions in topbar from newRx",this.patientGetPrescriptions);
    
    this.ageObjFromTopRx.emit(this.ageObj);
    this.userId = this.authService.getUser()
    console.log("fistNameObjfistNameObj",this.fistNameObj);
    this.purposeofvisit = this.patientGetPrescriptions.appointment?.comment;
    console.log("purposeofvisit",this.purposeofvisit);

    // console.log("objjjjjjjjj",this.patientObj)
    this.route.queryParams
    .subscribe(params => {
      console.log("logSep26", params);
      this.patientId=params.patientId
  

    })
   
    this.searchby_from_date = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };
    this.refreshservice.Patient_History.subscribe(valuesent => {
      console.log("GOT REFRESH EVENT",valuesent);
      this.patientId=valuesent
      // this.getPatientInfo();
      // this.getAllDoctorPrescriptions()

    });
    setTimeout(()=>{
      this.getAppointments();
    },500)
  
  }

  getPatientInfo = () => {


    this.patientService.getPatientInfo(this.patientData.id ? this.patientData.id : this.patientId).subscribe(response => {
      if (response) {
        this.allPatientInfo = response
        console.log("jan4", this.allPatientInfo)
        var obj = {};
        var patientInfo: any = response
        this.ageObj = response.age;
        this.locality = patientInfo.locality ? patientInfo.locality : ""
        this.first_name = patientInfo.firstName ? patientInfo.firstName : ""
        this.last_name = patientInfo.lastName ? patientInfo.lastName : "";
        this.mobileNumber = patientInfo.contactNo ? patientInfo.contactNo : ""
        this.gender = patientInfo.gender.toLowerCase() === 'male' ? 'M' : "F";

        this.medicalConditions = patientInfo && patientInfo.affected_comment ? patientInfo.affected_comment : '';
        this.familyMedicalConditions = patientInfo && patientInfo.familyHistory ? patientInfo.familyHistory : '';
        this.allergies = patientInfo && patientInfo.allergies ? patientInfo.allergies : '';
        this.recurringComplaints = patientInfo && patientInfo.comment ? patientInfo.comment : '';
        this.accidentalHistory = patientInfo && patientInfo.accidentalHistory ? patientInfo.accidentalHistory : '';
        this.additionalInfoForPatient = patientInfo && patientInfo.additionalInfo && patientInfo.additionalInfo ? JSON.parse(patientInfo.additionalInfo) : {};
        obj['phone_number'] = patientInfo.contactNo
        obj['spousecontactno'] = patientInfo.spousecontactno
        if (patientInfo && patientInfo.parent.fcmtoken) {
          obj['fcmtoken'] = patientInfo.parent.fcmtoken.substring(0, 5);
        }


        // this.patientData['User']['Customer'] = obj
        console.log("fcmdate", this.patientData)


      }
    })

  }

  onClickOverview(){
    
    this.refreshservice.patientHistory(this.patientId);
    let obj = {}
    let objData = {}
    obj['id'] = this.patientData.userId;
    obj['phone_number'] = this.patientData.contactNo

    obj['spousecontactno'] = this.patientData.spousecontactno
    obj['dob'] = this.patientData.DOB
    var gender: any = ""
    if (this.patientData.gender == 'Male') {
      gender = "male"
    }
    else {
      gender = "female"
    }
    obj['gender'] = gender
    obj['first_name'] = this.patientData.firstName
    obj['last_name'] = this.patientData.lastName
    obj['middle_name'] = this.patientData.middleName
    obj['locality']=this.patientData.locality
    obj['profile_photo'] = this.patientData.profilePic ? this.patientData.profilePic : '';
    objData['User'] = { ...obj, Customer: { phone_number: this.patientData.contactNo, spousecontactno: this.patientData.spousecontactno } };
          objData['id'] = this.patientData._id;

          this.patientNewData = objData
          console.log("newPa", this.patientNewData)
          localStorage.setItem("patientData", JSON.stringify(this.patientNewData))
    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientId } })
  }
  
  
  toggleSideNav() {
    this.navService.setShowNav(true);
  }

  getAllDoctorPrescriptions= () => {
    this.appointmentObjPurposeVisit={}
    var obj = {
      "patientId": this.patientId,
      "isForHistory": true,
    }
    // this.patientService.getAppointmentsByPatientId(obj).subscribe(response => {
      if (this.patientApponmentById.success) {
        if (this.patientApponmentById.historyArr.length) {
          this.appointmentObjPurposeVisit=this.patientApponmentById.historyArr[this.patientApponmentById.historyArr.length-1]
          console.log("Feb133333333", this.appointmentObjPurposeVisit)
        }
      }

    // })

  }

  getAppointments = () => {

    //this.showLoading = true
    let reqVars = {
      doctor_id: this.userId,
      searchby_name_number: this.searchby_name_number,
      searchby_from_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
        searchby_to_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      order: this.order
    }
    // let that = this
    let d = new Date()
    this.appointmentCollection = [];
    this.appointmentCollectionArrived = [];
    this.appointmentCollectionAttending = [];
    this.appointmentCollectionDone = [];
  
    let cancelledAppointments = []
  
    //this.showLoading = true;
  
    // this.doctorService.getAppointments(reqVars).subscribe(response => {
      console.log('Response Appt: ', this.patientAppointments)
      var appointmentboj: any = {}
     // this.showLoading = false
      if (this.patientAppointments.appointments) {
        this.searchAppData = this.patientAppointments.appointments;
     
        this.originalAppointments = this.patientAppointments.appointments;
        console.log("marchTest31",this.originalAppointments)
  
        this.patientAppointments.appointments.forEach((element) => {
  
          let isReceipt=false
          console.log({ thissssss2: this })
          if(element.Patient.User.first_name=="favourite" && element.Patient.User.last_name=="creation" ){
  
          }else{
  
        
          if (element.Patient && element.Patient.User) {
            var age = element.Patient.User.dob ? this.util.calculateAge(element.Patient.User.dob) : { years: '-' }
            if (age['years'] < 1) {
  
              age['years'] = "Under 1 Year";
            }
            else {
              age;
            }
            var gender = element.Patient.User.gender != null ? element.Patient.User.gender : "-"
  
           if(element.Certificates && element.Certificates!=null){
            var certificate=JSON.parse(element.Certificates)
            certificate.map(item=>{
              if(item.type=='Receipt'){
                isReceipt=true
              }
            })
  
           }
  
            appointmentboj = {
              id: element.id,
              isReceipt,
              visitedOn: element.visit_date,
              from: element.from,
              to: element.to,
              mobile: element.Patient.User.phone_number,
              name: element.Patient.User.first_name + " " + element.Patient.User.last_name + " (" + gender.substring(0, 1).toUpperCase() + '/' + age['years'] + ")",
              clinicName: element.Clinic && element.Clinic.name ? element.Clinic.name : '-',
              deleted: element.deleted,
              comepleteData: element,
              clinicId: element.Clinic && element.Clinic.id ? element.Clinic.id : null,
              comment: element.comment,
              fullName: element.Patient.User.first_name + " " + element.Patient.User.last_name,
              status: element.status,
              userId: element.Patient.User.id,
              showStartCall: false,
              zoomStartURL: element.zoomStartURL,
              PatientId: element.PatientId,
              showCancel: false,
              showCovid: false,
              showNoShow: false,
              arrived: element.arrived,
              in: element.in,
              done: element.done,
              bookedFrom: element.bookedFrom,
              isTeleCall: element.isTeleCall
            };
            var visited = (appointmentboj.visitedOn).substr(0, 11) + "23:59:59.000Z"
            if (new Date(appointmentboj.from).getDate() === new Date().getDate() && new Date(appointmentboj.from).getMonth() === new Date().getMonth() && new Date(appointmentboj.from).getFullYear() === new Date().getFullYear()) {
              appointmentboj.showCovid = true;
            }
            if (element.isTeleCall == '1' && new Date(visited).getTime() > d.getTime() && element.Doctor.isMeetingURLAvailable) {
              appointmentboj.showStartCall = true
            }
            if (new Date(appointmentboj.from).getTime() > d.getTime() && !appointmentboj.done) {
              appointmentboj.showCancel = true;
            }
            if (!appointmentboj.done && !appointmentboj.in && !appointmentboj.arrived && appointmentboj.status == null && (new Date(appointmentboj.from).getDate() == new Date().getDate() && new Date(appointmentboj.from).getMonth() == new Date().getMonth() && new Date(appointmentboj.from).getFullYear() == new Date().getFullYear())) {
              appointmentboj.showNoShow = true;
            }
            if (new Date(appointmentboj.visitedOn).getTime() < d.getTime()) {
              appointmentboj.cancelled = true
  
            }
  
            this.appointmentCollection.push(appointmentboj)
            console.log("Test31",this.appointmentCollection)
  
            if (appointmentboj.status == 'noshow') {
              cancelledAppointments.push(appointmentboj)
            }
  
          }
          this.appointmentCollection.map(ins=>{
            if(ins.comepleteData.prescriptionPDFURL){
              ins.done="done",
              ins.arrived="done"
              ins.in="done"
              ins.showNoShow=false
            }
           })
  
          return this.appointmentCollection.sort((a, b) => {
            return <any>new Date(a.from) - <any>new Date(b.from);
          
  
          });
          }
  
        })
      
        this.cancelledAppointments = cancelledAppointments;
  
        let doneArr = [];
        let notDoneArr = [];
        let noShowArr = [];
  
        console.log("NOV3",this.appointmentCollection)
        this.appointmentCollection.map(item => {
         
          if (item.done != null) {
  
            doneArr.push(item)
            
          }
          else if (item.status != null) {
            noShowArr.push(item)
          }
          else {
            notDoneArr.push(item)
          }
        });
  
        let slots = [
          ...notDoneArr, ...doneArr, ...noShowArr
        ]
  
        this.appointmentCollection = slots;
        this.originalCopy = this.appointmentCollection;
  
        this.appointmentCollectionDone = doneArr;
  
        this.appointmentCollection.map(ins => {
          if (ins.arrived && !ins.in && !ins.done) {
            this.appointmentCollectionArrived.push(ins)
          }
  
          if (ins.arrived && ins.in && !ins.done ) {
            this.appointmentCollectionAttending.push(ins)
          }
        })
        localStorage.removeItem("covidques");
       // this.apppointmentCount();
      }
    // })
   // this.apppointmentCount();
  
  }
  onClickPatient(item){
    this.patientId=item.PatientId
    // this.getPatientInfo();
    this.navService.setShowNav(false);
    this.refreshservice.patientHistory(this.patientId);
  }
  openImmunizationRX() {
    // this.showLoading = true;
    // this.openImmunizationFlag = true;
    this.selectedIndex = 2;

    this.onClickOverview();

    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientData.id, this.refreshservice.EventName_Immunization);
      console.log('Custom  Switch Caseeeeeeeeeeeeeeee function executed.');
    }, 500);

  }
  openMilestoneRX() {
    console.log("openImmunization function called");
    this.selectedIndex = 3;
    this.onClickOverview();
    setTimeout(() => {
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientId, this.refreshservice.EventName_Milestone);
      console.log("Runnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnnn");

    }, 500);

  }
  
}
