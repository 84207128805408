import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NewQuickRxHomeComponent } from './new-quick-rx-home/new-quick-rx-home.component';
import { AuthGuard } from '../../auth/auth.guard';
import { NewQuickRxComponent } from './new-quick-rx/new-quick-rx.component';

const routes: Routes = [];



export const NewRxRoutingModule:Routes=[
{
    path: 'rxHomePage',
    // component: HomePageComponent,
    component: NewQuickRxComponent,
  //  canActivate: [AuthGuard]
  },
] ;