import { Component, Input, OnInit, } from '@angular/core';

enum alertTypes {
  Info,
  Warning,
  Danger,
  Success
}

@Component({
  selector: 'app-alertbox',
  templateUrl: './alertbox.component.html',
  styleUrls: ['./alertbox.component.css']
})


export class AlertboxComponent implements OnInit {

  @Input() title: string;
  @Input() subtitle:string;
  @Input() type: any;
  @Input() styles: any = {};
  constructor() { }

  ngOnInit(): void {
  }

}
