import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "../app/services/auth.service";
import { Router } from "@angular/router";

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(private authService : AuthService,private router : Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {
      if (!this.authService.isLoggedIn()) {
        this.router.navigate(['/login']);
        this.authService.deleteToken(1);
        return false;
      }
    return true;
  }

  
}