import { Component, OnInit, Inject } from '@angular/core';
import * as $ from 'jquery'
import { DoctorService } from '../../../services/doctor.service'
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../../services/global.service'
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { groupBy, cloneDeep, keysIn } from 'lodash'
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service'
import { Router } from '@angular/router';
import { AuthService } from '../../../services/auth.service'
import * as moment from 'moment';
@Component({
  selector: 'app-clinic-details',
  templateUrl: './clinic-details.component.html',
  styleUrls: ['./clinic-details.component.scss']
})
export class ClinicDetailsComponent implements OnInit {
  pinMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  phoneMask: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  currentStage: string = '2'
  userId: any = 1
  user: any = { doctor: {} }
  specialization: any = null
  degreeArray: any = []
  councilsArray: any = []
  specializations: any = []
  institutes: any = []
  specialitiesArray: any = []
  originalSpecialitiesArray: any = []
  subSpecialitiesArray: any = []
  originalSubSpecialitiesArray: any = []
  educationArray = []
  awardArray = []
  yearsArray = []
  clinicArray = []
  time = ''
  slotsArray = [5, 10, 15, 20, 30, 60]
  deletedClinics = []
  primaryClinicForLetterHead = 0
  primaryClinic: any = {}
  letterheadClinincs: any = {}
  days = []
  morningFrom = null //clinicSessions
  morningTo = null
  eveningFrom = null //clinicSessions
  eveningTo = null
  path = null
  s1FromMinTime: Date
  s1FromMaxTime: Date
  isEmailValid: Boolean = true
  errorTimeSlot: Boolean = false
  emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/
  elementE: any
  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private authService: AuthService, private doctorService: DoctorService, private router: Router, private globalService: GlobalService, private toastr: ToastrService) {
    this.userId = this.authService.getUser()

    let params = {
      user_id: this.userId
    }
    const urlArray = location.href.split('/')
    this.path = urlArray[urlArray.length - 2].toLowerCase()
    this.doctorService.getDetails(params).subscribe(response => {
      if (response.success) {
        this.user = response.user
        this.user.doctor = response.doctor
        let doc_params = {
          doctor_id: this.user.doctor.id
        }
        this.doctorService.getClinics(doc_params).subscribe(response => {
          if (response.success) {
            if (response.clinics.length == 0) {
              this.addClinic(0)
            } else {
              this.clinicArray = response.clinics
              this.clinicArray.map((clinic, i) => {
                clinic.pincodeErrors = null
                clinic.contactErrors = []
                clinic.consultantErrors = []
                clinic.sessionErrors = []
                clinic.session1Errors = []
                clinic.session2Errors = []
                clinic.blankSession1Errors = []
                clinic.blankSession2Errors = []
                if (clinic.ClinicConsultants.length == 0) {
                  this.addField('ClinicConsultants', i)
                }
                if (clinic.ClinicContacts.length == 0) {
                  this.addField('ClinicContacts', i)
                }
                if (clinic.ClinicFacilities.length == 0) {
                  this.addField('ClinicFacilities', i)
                }
                if (clinic.ClinicSchedules.length == 0) {
                  this.addField('ClinicSchedules', i)
                }
              })
            }

          }
          else {
            this.toastr.error(response.error)
          }
        })
      } else {
        this.toastr.error(response.error)
      }
    })
    this.globalService.getConstants().subscribe(response => {
      this.degreeArray = response.degrees
      this.councilsArray = response.state_medical_councils
      this.originalSpecialitiesArray = response.specialities
      this.specializations = response.specializations
      this.specialitiesArray = response.specialities
      this.institutes = response.institutes
      this.days = response.days
      let currentYear = (new Date).getFullYear()
      let startYear = 1900
      for (let i = 0; i <= currentYear - startYear; i++) {
        this.yearsArray.push(startYear + i)
      }
    })
    var d = new Date()
    d.setHours(0, 0, 0)
    this.morningFrom = this.formatTime(d)
    d.setHours(11, 59, 0)
    this.morningTo = this.formatTime(d)
    this.s1FromMaxTime = d
    d.setHours(12, 0, 0)
    this.eveningFrom = this.formatTime(d)
    d.setHours(23, 59, 0)
    this.eveningTo = this.formatTime(d)

    var current_date = new Date()
    current_date.setHours(0, 0, 0)
    this.s1FromMinTime = current_date
    var next_date = new Date(new Date().setDate(current_date.getDate() + 1))
    next_date.setHours(23, 59, 0)
    this.s1FromMaxTime = next_date

  }

  ngOnInit() {
    this.setStage(this.currentStage)
  }

  getCheckedOnCallForClinic(clinicIndex, onCallIndex) {
    return this.clinicArray[clinicIndex]['ClinicSchedules'][onCallIndex].on_call == "true";
  }

  formatTime(date_obj) {
    if (date_obj) {
      var hour = date_obj.getHours();
      var minute = date_obj.getMinutes();
      var amPM = (hour > 11) ? " PM" : " AM";

      if (hour > 12) {
        hour -= 12;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      return hour + ":" + minute + amPM;
    }

  }

  format(cIndex, sIndex, field) {
    this.clinicArray[cIndex].ClinicSchedules[sIndex][field] = this.formatTime(this.clinicArray[cIndex].ClinicSchedules[sIndex][field])
  }

  setValue(field, value) {
    this[field] = value
  }

  setAssociations(association, index, field, value) {
    this[association][index][field] = value
  }

  addClinic(id) {
    let clinicObj = {
      name: null,
      address_line1: null,
      address_line2: null,
      address_line3: null,
      city: null,
      pincode: null,
      primary: 0,
      include_on_letterhead: 0,
      ClinicSchedules: [],
      ClinicContacts: [],
      ClinicConsultants: [],
      ClinicFacilities: [],
      multispeciality: 0,
      contactErrors: [],
      sessionErrors: [],
      consultantErrors: [],
      scheduleErrors: [],
      session1Errors: [],
      session2Errors: [],
      blankSession1Errors: [],
      blankSession2Errors: [],
      pincodeErrors: null
    }
    if (this.clinicArray.length == 0) {
      clinicObj['primary'] = 1
      clinicObj['include_on_letterhead'] = 1
      this.primaryClinic = clinicObj
    }
    this.clinicArray.push(clinicObj)
    let index = this.clinicArray.length - 1
    this.addField('ClinicContacts', index)
    this.addField('ClinicFacilities', index)
    this.addField('ClinicConsultants', index)
    this.addField('ClinicSchedules', index)

  }

  removeClinic(index) {

    if (this.clinicArray[index].id) {
      this.deletedClinics.push(this.clinicArray[index].id)
    }
    this.clinicArray.splice(index, 1)
  }

  radioChange(clinic, index) {
    this.clinicArray[index].primary = true;
    // setTimeout(() => {
    this.primaryClinicForLetterHead = index;
    this.primaryClinic = clinic
  }


  addField(field, index) {
    let obj = {}
    switch (field) {
      case "ClinicContacts":
        obj = { phone: null, email: null }
        break;
      case "clinicServices":
        obj = { service_type: null }
        break;
      case "clinicConsultants":
        obj = { first_name: null, last_name: null, phone: null, email: null }
        break;
      case "ClinicSchedules":
        obj = {
          day: null,
          on_call: 0,
          morning_from: this.morningFrom,
          morning_to: this.morningTo,
          evening_from: this.eveningFrom,
          evening_to: this.eveningTo,
        }
        break;
      default:
        obj = {}
        break;
    }
    this.clinicArray[index][field].push(obj)
  }

  removeField(field, index, i) {
    if (field == "ClinicContacts" && this.clinicArray[index][field][i].id) {
      if (!this.clinicArray[index]['deleted_contacts']) {
        this.clinicArray[index]['deleted_contacts'] = []
      }
      this.clinicArray[index]['contactErrors'].splice(i, 1)
      this.clinicArray[index]['deleted_contacts'].push(this.clinicArray[index][field][i].id)
    }
    if (field == "ClinicSchedules" && this.clinicArray[index][field][i].id) {
      if (!this.clinicArray[index]['deleted_schedules']) {
        this.clinicArray[index]['deleted_schedules'] = []
      }

      this.clinicArray[index]['deleted_schedules'].push(this.clinicArray[index][field][i].id)
      this.clinicArray[index]['sessionErrors'].splice(i, 1)
      this.clinicArray[index]['session1Errors'].splice(i, 1)
      this.clinicArray[index]['session2Errors'].splice(i, 1)
      this.clinicArray[index]['blankSession1Errors'].splice(i, 1)
      this.clinicArray[index]['blankSession2Errors'].splice(i, 1)
    }
    if (field == "ClinicFacilities" && this.clinicArray[index][field][i].id) {
      if (!this.clinicArray[index]['deleted_facilities']) {
        this.clinicArray[index]['deleted_facilities'] = []
      }

      this.clinicArray[index]['deleted_facilities'].push(this.clinicArray[index][field][i].id)
    }
    if (field == "ClinicConsultants" && this.clinicArray[index][field][i].id) {
      if (!this.clinicArray[index]['deleted_consultants']) {
        this.clinicArray[index]['deleted_consultants'] = []
      }
      this.clinicArray[index]['consultantErrors'].splice(i, 1)
      this.clinicArray[index]['deleted_consultants'].push(this.clinicArray[index][field][i].id)
    }
    this.clinicArray[index][field].splice(i, 1)
  }


  addLabelClass(e) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

  saveStepOne() {
    this.user.doctor.awards = this.awardArray
    this.user.doctor.educations = this.educationArray
    this.setStage(2)
  }

  setStage(stageNo) {
    this.currentStage = stageNo
    let headerText = ""
    switch (this.currentStage) {
      case "3":
        headerText = "DETAILS OF OFFICE PAPERS"
        break;
      case "5":
        headerText = "LET'S GET STARTED"
        break;
      default:
        headerText = "CLINICIAN'S PROFILE"
        break;
    }
    let topHeading = document.getElementsByClassName('topHeading')[0]
    topHeading.innerHTML = headerText
  }

  validateclinic(clinic, i) {
    let error = {}
    clinic.pincodeErrors = null

    if (Number(clinic.pincode) != 0) {
      if (clinic.pincode.toString().length < 6) {
        clinic.pincodeErrors = 'error'
      }
      else {
        clinic.pincodeErrors = null
      }
    }
    if (Number(clinic.pincode) == 0) {
      clinic.pincode = null
    }

    /****************************validate clinic contacts**************************************************************/
    clinic.contactErrors = []
    clinic.ClinicContacts.map((contact) => {
      if (contact.email != "" && contact.email != null && !this.emailRegex.test(contact.email)) {
        clinic.contactErrors.push("eerror")
      }
      if (contact.phone != "" && contact.phone != null && contact.phone.length < 10) {
        clinic.contactErrors.push("perror")
      } else {
        clinic.contactErrors.push("")
      }
    })

    /****************************validate clinic consultants**************************************************************/
    clinic.consultantErrors = []
    clinic.ClinicConsultants.map((consultant) => {
      if (consultant.email != "" && consultant.email != null && !this.emailRegex.test(consultant.email)) {
        clinic.consultantErrors.push("eerror")
      }
      if (consultant.phone != "" && consultant.phone != null && consultant.phone.length < 10) {
        clinic.consultantErrors.push("perror")
      } else {
        clinic.consultantErrors.push("")
      }

    })


    /****************************validate clinic schedules**************************************************************/
    clinic.sessionErrors = []
    clinic.session1Errors = []
    clinic.session2Errors = []
    clinic.blankSession1Errors = []
    clinic.blankSession2Errors = []

    let daysSelected = []
    clinic.ClinicSchedules.map((schedule) => {
      if (daysSelected.indexOf(schedule.day) == -1) {
        if (schedule.day != null) {
          daysSelected.push(schedule.day)
          clinic.sessionErrors.push("")
        }
      } else {
        clinic.sessionErrors.push("error")
      }

      let morningFrom = moment(schedule.morning_from, "HH:mm a");
      let morningTo = moment(schedule.morning_to, "HH:mm a");
      let morningduration = moment.duration(morningTo.diff(morningFrom));
      let morninghours = morningduration.asHours();

      let eviningFrom = moment(schedule.morning_from, "HH:mm a");
      let eviningTo = moment(schedule.morning_to, "HH:mm a");
      let eviningduration = moment.duration(eviningTo.diff(eviningFrom));
      let evinghours = eviningduration.asHours();

      if (morninghours && schedule.morning_from && morninghours < 0) {
        clinic.session1Errors.push("error")
      } else {
        clinic.session1Errors.push("")
      }
      if (evinghours && schedule.evening_from && evinghours < 0) {
        clinic.session2Errors.push("error")
      } else {
        clinic.session2Errors.push("")
      }
      if (schedule.morning_from && !schedule.morning_to) {
        clinic.blankSession1Errors.push("error")
      } else if (!schedule.morning_from && schedule.morning_to) {
        clinic.blankSession1Errors.push("error")
      } else {
        clinic.blankSession1Errors.push("")
      }
      if (schedule.evening_from && !schedule.evening_to) {
        clinic.blankSession2Errors.push("error")
      } else if (!schedule.evening_from && schedule.evening_to) {
        clinic.blankSession2Errors.push("error")
      } else {
        clinic.blankSession2Errors.push("")
      }
    })

    if (clinic.contactErrors.indexOf("eerror") > -1 || clinic.pincodeErrors != null || clinic.contactErrors.indexOf("perror") > -1 || clinic.consultantErrors.indexOf("eerror") > -1 || clinic.consultantErrors.indexOf("perror") > -1 || clinic.sessionErrors.indexOf("error") > -1 || clinic.session1Errors.indexOf("error") > -1 || clinic.session2Errors.indexOf("error") > -1 || clinic.blankSession1Errors.indexOf("error") > -1 || clinic.blankSession2Errors.indexOf("error") > -1) {
      return false
    } else {
      return true
    }
  }

  saveClinics() {

    let errorClinics = []
    this.clinicArray.map((clinic, i) => {
      if (!this.validateclinic(clinic, i)) {
        errorClinics.push(clinic)
        // this.elementE = document.getElementById(i)
        // alert(document.getElementById(i))
        // this.elementE.scrollIntoView();
      }
    })
    if (errorClinics.length == 0) {
      let params = {
        user_id: this.userId,
        clinics: this.clinicArray,
        deleted_clinics: this.deletedClinics,
        opdAppointmentTime: this.time
      }

      this.doctorService.updateClinics(params).subscribe(response => {
        if (response.success) {
          if (this.path == "editprofile") {
            this.toastr.success("Details have been updated successfully")
            this.router.navigateByUrl('/doctor/profileView')
          } else {
            this.router.navigateByUrl('/doctor/profileSetup/stepThree')
          }

        } else {
          this.toastr.error(response.error)
        }
      })
    } else {
      if (!(/Edge\/\d./i.test(navigator.userAgent))) {
        document.getElementsByClassName('main-content')[0].scrollTo(0, 0)
      }
      this.toastr.error("Please fill in the required fields")
    }
  }

  previewLetterhead() {
    let forGroupingSession = cloneDeep(this.primaryClinic.clinicSessions)
    let groupedSessions = {}
    forGroupingSession.map((day) => {
      if (day.day != null) {
        day.day = day.day.substring(0, 3)
        let key = day.morning_from + ' to ' + day.morning_to + ', ' + day.evening_from + ' to ' + day.evening_to
        if (groupedSessions[key] && groupedSessions[key]['days'].length > 0) {
          groupedSessions[key]['days'].push(day)
        } else {
          groupedSessions[key] = {}
          groupedSessions[key]['days'] = []
          groupedSessions[key]['days'].push(day)
        }
      }
    })

    let sessions = Object.keys(groupedSessions).map(key => ({ time: key, value: groupedSessions[key] }))
    this.primaryClinic.groupedSessions = sessions

    this.setStage("4")
  }

  closeSession(type, cIndex, index) {
    if (type == 'morning') {
      this.clinicArray[cIndex].ClinicSchedules[index].morning_from = null
      this.clinicArray[cIndex].ClinicSchedules[index].morning_to = null
    } else {
      this.clinicArray[cIndex].ClinicSchedules[index].evening_from = null
      this.clinicArray[cIndex].ClinicSchedules[index].evening_to = null
    }
  }

  validateInput(e) {
    return (e.charCode > 64 && e.charCode < 91) || (e.charCode > 96 && e.charCode < 123) || (e.which == 8) || (e.keyCode == 9) || (e.charCode == 32)
  }

  checkDuplicateInObject(propertyName, inputArray) {
    var seenDuplicate = false,
      testObject = {};

    inputArray.map(function (item) {
      var itemPropertyName = item[propertyName];
      if (itemPropertyName in testObject) {
        testObject[itemPropertyName].duplicate = true;
        item.duplicate = true;
        seenDuplicate = true;
      }
      else {
        testObject[itemPropertyName] = item;
        delete item.duplicate;
      }
    });

    return seenDuplicate;
  }

  validateEmail(email) {
    var reg = /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (reg.test(email) == false) {
      return this.isEmailValid = false;

    }
    return this.isEmailValid = true;
  }
}
