<div style="background-color: #eee; height: 8%; width: 100%;">
    <div style=" display: flex; flex-direction: row; align-items: center; height: 100%;">
        <img src="assets/images/copious-logo.png" alt="" style="height:80%" />
        <h4>Copious Healthcare - Remove Patient's Account</h4>
    </div>
</div>

<div style="height: 92%; display: flex; justify-content: center; align-items: center;">
    <div class="container">

        <h3>Enter Registered Mobile Number</h3>
        <div style="display: flex; flex-direction: row; align-items: center; padding: 8px 8px 0px 0px">
            <h4 style="margin-bottom: 0px;border: 1px solid #333;
            padding: 9px;">+91</h4>
            <input id="phoneNumberId" name="firstname" placeholder="xxxxx-xxxxx" name="phoneNumber" type="text"
                pattern="\d*" maxlength="10"
                [(ngModel)]="phoneNumber"
                style="font-size: 18px; color: #45566e; font-weight: 600;letter-spacing: 5px; margin-left: 16px; padding: 8px; width: 55%;">
        </div>


        <div style="display: flex; flex-direction: row; align-items: center; padding: 8px 8px 8px 0px;">
            <mat-checkbox class="example-margin" [color]="'primary'"
             (change)="onCheck($event.checked)">I want to remove
                my account and its associated data.</mat-checkbox>
        </div>

        <button 
        [ngClass]="phoneNumber?.toString().length == 10 && checkValue ? 'button-enabled' : 'button-disabled'"
        (click)="requestAccountRemoval()"
        style="width: 245px; margin: 8px 0px; outline: none;">Request Account Removal</button>


        <!-- <p> -->
        <div style="margin-top: 40px;">
            <h4>Note</h4>
            <!-- <ul> -->
            <li style="font-size: 16px;">We will contact you for confirmation.</li>
            <li style="font-size: 16px;">Your account and its data will be removed in 24 hours time, after the request is made.</li>
            <li style="font-size: 16px;">You will no longer be able to retrieve your medical records once your account is removed.</li>
        </div>
        <!-- </ul> -->
        <!-- </p> -->

    </div>

</div>

<!-- *ngIf="sentOtpBtn" s -->
<!-- [(ngModel)]="phoneNumber" -->
<!-- (focus)="addLabelClass($event)" -->
<!-- (blur)="removeLabelClass($event)" -->