<div class=" col-lg-12" >
  <div class="dashboard-content">
     <div class="user-management-data">
     
      <div class="row mt-2">
        <div class="col-xl-1 col-lg-1"><a routerLink="./../labs/add" class="btn btn-primary opd-primary-btn transformNormal btn-lg add-button">Add</a></div>
        <div class="col-xl-11 col-lg-11 d-flex justify-content-end align-items-center pr-0">
          <div class="scheduleandAddNew-outer">
            <div class="scheduleandAddNew">
              <div class="appointment float-right searchIcon active">
                <div class="form-group searchField pull-left">
                  <input type="text" placeholder="Search by name, email" class="form-control" #searchInput
                    [(ngModel)]="searchString" (keyup)="searchLab()" />
                    <a (click)="closeSearch()" *ngIf="searchString"><i class="fa fa-times-circle" aria-hidden="true"></i></a>
                </div>
                <div class="searchbtn">
                  <button (click)="searchLab()"><img src="assets/images/searchIcon.svg" alt="" /></button>
                </div>
              </div>
            </div>
          </div>
        </div>
       
      </div>

      <!--<div class="card">
          <div class="card-header no-shadow inventory-list">
            <div class="appointments inventory">
              <ul>
                <li>
                  <ul class="head">
                    <li>#</li>
                    <li>Name</li>
                    <li>Email</li>
                    
                    <li></li>
                  </ul>
                </li>
                <li class="notFound" *ngIf="labsList && labsList.length == 0">No Items found</li>
                <li class="morningSession-outer inventoryBody" (scroll)="loadLabs($event)">
                  <ul class="morningSessionDetails {{activeTab == i ? 'active':''}}" *ngFor="let lab of labsList; let i = index">
                      <li>{{lab.id}}</li>
                      <li class="capitalize">
                        {{lab.name}}
                      </li>
                      <li>
                          {{lab.email_id}}
                      </li>
                      <li> 
                        <a routerLink="./../labs/edit/{{lab.id}}" title="Edit"><i class="fa fa-edit" aria-hidden="true"></i></a>
                        <a title="Delete" (click)="delete(lab)"><i class="fa fa-trash ml-2" aria-hidden="true"></i></a>
                      </li>
                  </ul>
  
                </li>
  
              </ul>
            </div>
          </div>
        </div>-->

      <!--<div class="card mb-0">
          <div class="card-header no-shadow inventory-list mt-2">
            <div class="appointments inventory pendingBilling">
              <ul>
                <li class="appointmentstableHead">
                  <ul class="head clearfix">
                    <li>#</li>
                    <li>Name</li>
                    <li>Email</li>
                    <li></li>
                  </ul>
                </li>
                <li class="morningSession-outer">
                  <ul class="morningSessionDetails contentBody clearfix clickable forHover"
                  *ngFor="let lab of labsList; let i = index">
                   
                      <li>{{lab.id}}</li>
                      <li class="capitalize">
                        {{lab.name}}
                      </li>
                      <li>
                          {{lab.email_id}}
                      </li>
                      <li> 
                        <a routerLink="./../labs/edit/{{lab.id}}" title="Edit"><i class="fa fa-edit" aria-hidden="true"></i></a>
                        <a title="Delete" (click)="delete(lab)"><i class="fa fa-trash ml-2" aria-hidden="true"></i></a>
                      </li>
                    
                   
                  </ul>
                  <ul class="morningSessionDetails contentBody clearfix" *ngIf="labsList.length == 0" >
                    <li class="noAppointment">
                      No records available
                    </li>
                  </ul>
  
                </li>
              </ul>
            </div>
          </div>
        </div>-->
     
      <!--<p  *ngIf="this.totalUsers > 0" class="summary text-right">Displaying {{offset + 1}} - {{offset + pageSize < totalUsers ? offset + pageSize : totalUsers}} of {{totalUsers}} records</p>-->
      <table  id="dtVerticalScrollExample" class="table table-striped table-fixed mt-3">
        <thead>
          <tr>
            <th class="col-1 d-flex align-items-center">#</th>
            <th  class="col-5 d-flex justify-content-start align-items-center">Name 
                <div  class="topDown">
                    <i title="Ascending order" class="menu-caret fa fa-caret-up" [ngClass]="{'active': order[0] && order[0].indexOf('name') != -1 && order[0].indexOf('asc') != -1}" (click)="sort(null,'name','asc')"></i>
                    <i title="Descending order" class="menu-caret fa fa-caret-down" [ngClass]="{'active': order[0] && order[0].indexOf('name') != -1 && order[0].indexOf('desc') != -1}" (click)="sort(null,'name','desc')"></i>
                </div>
              </th>
            <th class="col-4 d-flex align-items-center">Email Id <a class="sort-icon"></a></th>
            <th class="action d-flex col-2 align-items-center">Action</th>
          </tr>
        </thead>        
        <tbody *ngIf="!isLoading" (scroll)="loadLabs($event)">
          <tr *ngIf="labsList && labsList.length === 0"><td class="noRecords" colspan="9">No records found</td></tr>
          <tr  *ngFor="let lab of labsList; let i = index">
            <td class="col-1">{{i+1}}</td>
            <td class="capitalize col-5">{{lab.name}}</td>
            <td class="col-4">{{lab.email_id}}</td>
          
            <td class="view col-2">      
              <a routerLink="./../labs/edit/{{lab.id}}" title="Edit"><i class="fa fa-edit" aria-hidden="true"></i></a>
              <a title="Delete" (click)="delete(lab)"><i class="fa fa-trash ml-2" aria-hidden="true"></i></a>
            </td>

          </tr>
        </tbody>
        
      </table>
  </div>

</div>
</div>
<p-confirmDialog appendTo="body"></p-confirmDialog>