import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { map } from 'rxjs/operators/map'
import { Router } from '@angular/router'
import { Constants } from "../../config/constants";

@Injectable()
export class DoctorService {

  constructor(private http: HttpClient, private router: Router) { }

  APIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/v1`

  //function to get doctor details
  public getDetails(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/doctorDetails`, req_vars)
  }

  //function to add profile details
  public updateProfile(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/updateProfile`, req_vars)
  }
  //function to add profile details
  public updateClinics(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/createClinic`, req_vars)
  }

  //function to get clinincs details
  public getClinics(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/getClinics`, req_vars)
  }

  //function to get doctors all details for admin panel view
  public getDoctorDetails(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/getDoctorDetails`, req_vars)
  }

  //function to retrive all master inventory dateData
  public getMasterInventory(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/getMasterInventory`, req_vars)
  }
  //function to add doctor inventory Data
  public createInventory(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/createInventory`, req_vars)
  }
  //function to get list doctor inventory Data
  public getInventory(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/getInventoryList`, req_vars)
  }
  //function to update doctor inventory Data
  public updateInventory(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/updateInventory`, req_vars)
  }

  //function to delete doctor inventory Data
  public deleteInventory(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/deleteInventory`, req_vars)
  }

  //function to update bulk inventory quantity
  public bulkUpdate(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/bulkUpdateQuantity`, req_vars)
  }

  // function to get Doctor inventories
  public getDoctorInventories(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/getDoctorInventories`, req_vars)
  }

  // function to get Doctor inventories
  public getDoctorVaccines(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/getDoctorVaccines`, req_vars)
  }

  // function to get Doctor inventories
  public getDoctorPendingBills(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/getDoctorPendingBills`, req_vars)
  }


  // function to get Doctor inventories
  public updateDoctorVaccines(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/updateDoctorVaccines`, req_vars)
  }

  // function to get Doctor inventories
  public resetVaccinationSchedule(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/resetVaccinationSchedule`, req_vars)
  }

  // function to add New Appointment
  public addNewAppointment(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/addNewAppointment`, req_vars)
  }

  // function to add New Appointment For Queue
  public addAppointmentForQueue(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/addAppointmentForQueue`, req_vars)
  }
  //function to add getClinicSchedule
  public getClinicSchedule(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/getClinicSchedule`, req_vars)
  }

  // function to get Appointments
  public getAppointments(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/getAppointments`, req_vars)
  }

  public getCancelledAppointments(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/getCancelledAppointments`, req_vars)
  }

  public updateBill(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/updateBill`, req_vars)
  }

  public updateAppointment(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/updateAppointment`, req_vars)
  }
  public updateNoShowAppointment(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/updateNoShowAppointment`, req_vars)
  }
  public startTeleMeeting(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/startTeleMeeting`, req_vars)
  }
  public generatePaymentInvoice(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/generatePaymentInvoice`, req_vars)
  }
  public addDoctorCustomPrescriptionItem(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/addDoctorCustomPrescriptionItem`, req_vars)
  }
  public getDoctorCustomPrescriptionItems(): Observable<any> {
    return this.http.get(`${this.APIURL}/getDoctorCustomPrescriptionItems`)
  }

  public doctorPublicProfile(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/doctorPublicProfile`, req_vars)
  }

  public updateAppointmentByIdAndParams(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/updateAppointmentByIdAndParams`, req_vars);
  }
  // public getFavApptsOfDoctor (): Observable<any> {
  //   return this.http.get(`${this.APIURL}/getFavApptsOfDoctor `)
  // }

    public getVaccinStockInventory(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/getVaccineInventoryLogsForSpecificBrand`, req_vars);
  }

  public getVaccineInventory(): Observable<any> {
    return this.http.get(`${this.APIURL}/getVaccineInventory`)
  }

  public getUniqueBrandsForInventory():Observable<any> {
    return this.http.get(`${this.APIURL}/getUniqueBrandsForInventory`)
  }
  public createOrUpdateVaccineInventory(req_vars: any):Observable<any> {
    return this.http.post(`${this.APIURL}/createOrUpdateVaccineInventory`, req_vars)
  }
  public deleteVaccineFromInventory(req_vars: any):Observable<any> {
    return this.http.post(`${this.APIURL}/deleteVaccineFromInventory`, req_vars)
  }
  public getInventoryPrediction(req_vars: any):Observable<any> {
    return this.http.post(`${this.APIURL}/getInventoryPrediction`, req_vars)
  }
  
}

