<div class="pc-tab">
  <input checked="checked" id="tab1" type="radio" name="pct" />
  <input id="tab2" type="radio" name="pct" />
  <input id="tab3" type="radio" name="pct" />
  <nav>
    <ul>
      <li class="tab1" (click)="changeTab('normal')">
        <label class="forTabHead" for="tab1">normal</label>
      </li>
      <li class="tab2" (click)="changeTab('preterm')">
        <label class="forTabHead" for="tab2">preterm</label>
      </li>
      <li class="tab3" (click)="changeTab('high_risk')">
        <label class="forTabHead" for="tab3">high risk</label>
      </li>
    </ul>
  </nav>
  <section>
    <div class="tab1">
      <div class="normalModule">
        <div class="row pb-3 ">
          <div class="col-xl col-lg-7 d-flex align-items-center">
            <div class="">
              <span class="headingUpprcase18" style="text-transform: capitalize;">vaccination schedules for normal
                patient </span>
            </div>
          </div>
          <div class="col editinfo mr-2">
            <div class="form-group d-flex justify-content-end">
              <div class=" pl-3">
                <button class="btn btn-primary ml-2 opd-primary-btn transformNormal btn-md"
                  style="text-transform: capitalize;" (click)="reset()">Reset to Default</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row tablefromat ml-0 mr-2">
              <div class="col-12">
                <div class="row recordsHeader">
                  <div class="col-xl-2 col-lg-2 heading">Name of the Vaccine</div>
                  <div class="col-xl-1 col-lg-1 heading">Weeks After VacStart Date</div>
                  <div class="col-xl-1 col-lg-1 heading">Weeks After PrevDose</div>
                  <div class="col-xl-2 col-lg-2 heading">PreVaccince</div>
                  <div class="col-xl-1 col-lg-1 heading">Weeks To NextDose</div>
                  <div class="col-xl-2 col-lg-2 heading">Next Vaccince</div>
                  <div class="col-xl-2 col-lg-2 heading">Description</div>
                  <div class="col-xl-1 col-lg-1 heading"></div>
                </div>
              </div>
              <div class="col-12 recordsbody recordBodyScroll" (scroll)="loadVaccines($event)">
                <div class="row forHover" *ngFor="let vaccine of vaccines">
                  <div class="col-xl-2 col-lg-2 recordsbodyItems">{{vaccine.MasterVaccine.name}}</div>
                  <div class="col-xl-1 col-lg-1 recordsbodyItems pr-0">
                    <span *ngIf="editVaccineId != vaccine.id">{{vaccine.weeks_after_start_date}}</span>
                    <span *ngIf="editVaccineId == vaccine.id && vaccine.weeks_after_start_date != null"><input
                        type="text" (keypress)="validateNumber($event)" [(ngModel)]="vaccine.weeks_after_start_date"
                        maxlength="3" /></span>
                  </div>
                  <div class="col-xl-1 col-lg-1 recordsbodyItems">
                    {{vaccine.MasterVaccine.weeks_after_prev_dose ? vaccine.MasterVaccine.weeks_after_prev_dose : ''}}
                  </div>
                  <div class="col-xl-2 col-lg-2 recordsbodyItems">
                    {{vaccine.PrevVaccine ? vaccine.PrevVaccine.name : ''}}</div>
                  <div class="col-xl-1 col-lg-1 recordsbodyItems pr-0">
                    <span *ngIf="editVaccineId != vaccine.id">{{vaccine.weeks_to_next_dose}}</span>
                    <span *ngIf="editVaccineId == vaccine.id && vaccine.weeks_to_next_dose != null"><input type="text"
                        (keypress)="validateNumber($event)" [(ngModel)]="vaccine.weeks_to_next_dose"
                        maxlength="3" /></span>
                  </div>
                  <div class="col-xl-2 col-lg-2 recordsbodyItems">
                    {{vaccine.NextVaccine ? vaccine.NextVaccine.name : ''}}</div>
                  <div class="col-xl-2 col-lg-2 recordsbodyItems">{{vaccine.MasterVaccine.description}}</div>
                  <div class="col-xl-1 col-lg-1 recordsbodyItems icons">
                    <div class="clickable" title="Edit" *ngIf="editVaccineId != vaccine.id"
                      (click)="editVaccineId = vaccine.id">
                      <i class="fa fa-pencil-square " aria-hidden="true"></i>
                    </div>
                    <div title="Save" *ngIf="editVaccineId == vaccine.id" class="editIcon ml-2 clickable"
                      (click)="updateVaccine(vaccine)">
                      <i class="fa fa-save clr--blue" style="font-size:24px"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab2">
      <div class="preTermModule">
        <div class="row pb-3">
          <div class="col-xl col-lg-7 d-flex align-items-center">
            <div class="">
              <span class="headingUpprcase18" style="text-transform: capitalize;">vaccination schedules for pre term
                patient </span>
            </div>
          </div>
          <div class="col editinfo mr-2">
            <div class="form-group d-flex justify-content-end">
              <!--<div class="  "  *ngIf="isEdit" (click)="isSave = true; isEdit = false; show = !show;">
                        <label class="editInfo pr-0">Edit Info</label>
                        <i class="fa fa-pencil-square " aria-hidden="true"></i>
                      </div>
                        <div *ngIf="isSave" class="editIcon ml-2" (click)="updateVaccines()">
                        <label class=" pr-0">Save</label>
                        <button >
                            <i class="fa fa-save" style="font-size:25px"></i>
                          </button>
                      </div>-->
              <div class=" pl-3">
                <button class="btn btn-primary ml-2 opd-primary-btn transformNormal btn-md"
                  style="text-transform: capitalize;" (click)="reset()">Reset to Default</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row tablefromat ml-0 mr-2">

              <div class="col-12">
                <div class="row recordsHeader">
                  <div class="col-xl-2 col-lg-2 heading">Name of the Vaccine</div>
                  <div class="col-xl-1 col-lg-1 heading">Weeks After VacStart Date</div>
                  <div class="col-xl-1 col-lg-1 heading">Weeks After PrevDose</div>
                  <div class="col-xl-2 col-lg-2 heading">PreVaccince</div>
                  <div class="col-xl-1 col-lg-1 heading">Weeks To NextDose</div>
                  <div class="col-xl-2 col-lg-2 heading">Next Vaccince</div>
                  <div class="col-xl-3 col-lg-3 heading">Description</div>
                </div>
              </div>
              <div class="col-12 recordsbody recordBodyScroll" (scroll)="loadVaccines($event)">
                <div class="row forHover" *ngFor="let vaccine of vaccines">
                  <div class="col-xl-2 col-lg-2 recordsbodyItems">{{vaccine.MasterVaccine.name}}</div>
                  <div class="col-xl-1 col-lg-1 recordsbodyItems pr-0">
                    <span *ngIf="editVaccineId != vaccine.id">{{vaccine.weeks_after_start_date}}</span>
                    <span *ngIf="editVaccineId == vaccine.id && vaccine.weeks_after_start_date != null"><input
                        type="text" (keypress)="validateNumber($event)" [(ngModel)]="vaccine.weeks_after_start_date"
                        maxlength="3" /></span>
                  </div>
                  <div class="col-xl-1 col-lg-1 recordsbodyItems">
                    {{vaccine.MasterVaccine.weeks_after_prev_dose ? vaccine.MasterVaccine.weeks_after_prev_dose : ''}}
                  </div>
                  <div class="col-xl-2 col-lg-2 recordsbodyItems">
                    {{vaccine.PrevVaccine ? vaccine.PrevVaccine.name : ''}}</div>
                  <div class="col-xl-1 col-lg-1 recordsbodyItems pr-0">
                    <span *ngIf="editVaccineId != vaccine.id">{{vaccine.weeks_to_next_dose}}</span>
                    <span *ngIf="editVaccineId == vaccine.id && vaccine.weeks_to_next_dose != null"><input type="text"
                        (keypress)="validateNumber($event)" [(ngModel)]="vaccine.weeks_to_next_dose"
                        maxlength="3" /></span>
                  </div>
                  <div class="col-xl-2 col-lg-2 recordsbodyItems">
                    {{vaccine.NextVaccine ? vaccine.NextVaccine.name : ''}}</div>
                  <div class="col-xl-2 col-lg-2 recordsbodyItems">{{vaccine.MasterVaccine.description}}</div>
                  <div class="col-xl-1 col-lg-1 recordsbodyItems icons">
                    <div class="clickable" title="Edit" *ngIf="editVaccineId != vaccine.id"
                      (click)="editVaccineId = vaccine.id">

                      <i class="fa fa-pencil-square " aria-hidden="true"></i>
                    </div>
                    <div title="Save" *ngIf="editVaccineId == vaccine.id" class="editIcon ml-2 clcikable"
                      (click)="updateVaccine(vaccine)">


                      <i class="fa fa-save clr--blue" style="font-size:24px"></i>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="tab3">
      <div class="highRiskModule">
        <div class="row pb-3">
          <div class="col-xl col-lg-7 d-flex align-items-center">
            <div class="">
              <span class="headingUpprcase18" style="text-transform: capitalize;">vaccination schedules for High risk
                patient </span>
            </div>
          </div>
          <div class="col editinfo mr-2">
            <div class="form-group d-flex justify-content-end">
              <!--<div class="  "  *ngIf="isEdit" (click)="isSave = true; isEdit = false; show = !show;">
                        <label class="editInfo pr-0">Edit Info</label>
                        <i class="fa fa-pencil-square " aria-hidden="true"></i>
                      </div>
                        <div *ngIf="isSave" class="editIcon ml-2" (click)="updateVaccines()">
                        <label class=" pr-0">Save</label>
                        <button >
                            <i class="fa fa-save" style="font-size:25px"></i>
                          </button>
                      </div>-->
              <div class=" pl-3">
                <button class="btn btn-primary ml-2 opd-primary-btn transformNormal btn-md"
                  style="text-transform: capitalize;" (click)="reset()">Reset to Default</button>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="row tablefromat ml-0 mr-2">

              <div class="col-12">
                <div class="row recordsHeader">
                  <div class="col-xl-2 col-lg-2 heading">Name of the Vaccine</div>
                  <div class="col-xl-1 col-lg-1 heading">Weeks After VacStart Date</div>
                  <div class="col-xl-1 col-lg-1 heading">Weeks After PrevDose</div>
                  <div class="col-xl-2 col-lg-2 heading">PreVaccince</div>
                  <div class="col-xl-1 col-lg-1 heading">Weeks To NextDose</div>
                  <div class="col-xl-2 col-lg-2 heading">Next Vaccince</div>
                  <div class="col-xl-3 col-lg-3 heading">Description</div>
                </div>
              </div>
              <div class="col-12 recordsbody recordBodyScroll" (scroll)="loadVaccines($event)">
                <div class="row forHover" *ngFor="let vaccine of vaccines">
                  <div class="col-xl-2 col-lg-2 recordsbodyItems">{{vaccine.MasterVaccine.name}}</div>
                  <div class="col-xl-1 col-lg-1 recordsbodyItems pr-0">
                    <span *ngIf="editVaccineId != vaccine.id">{{vaccine.weeks_after_start_date}}</span>
                    <span *ngIf="editVaccineId == vaccine.id && vaccine.weeks_after_start_date != null"><input
                        type="text" (keypress)="validateNumber($event)" [(ngModel)]="vaccine.weeks_after_start_date"
                        maxlength="3" /></span>
                  </div>
                  <div class="col-xl-1 col-lg-1 recordsbodyItems">
                    {{vaccine.MasterVaccine.weeks_after_prev_dose ? vaccine.MasterVaccine.weeks_after_prev_dose : ''}}
                  </div>
                  <div class="col-xl-2 col-lg-2 recordsbodyItems">
                    {{vaccine.PrevVaccine ? vaccine.PrevVaccine.name : ''}}</div>
                  <div class="col-xl-1 col-lg-1 recordsbodyItems pr-0">
                    <span *ngIf="editVaccineId != vaccine.id">{{vaccine.weeks_to_next_dose}}</span>
                    <span *ngIf="editVaccineId == vaccine.id && vaccine.weeks_to_next_dose != null"><input type="text"
                        (keypress)="validateNumber($event)" [(ngModel)]="vaccine.weeks_to_next_dose"
                        maxlength="3" /></span>
                  </div>
                  <div class="col-xl-2 col-lg-2 recordsbodyItems">
                    {{vaccine.NextVaccine ? vaccine.NextVaccine.name : ''}}</div>
                  <div class="col-xl-2 col-lg-2 recordsbodyItems">{{vaccine.MasterVaccine.description}}</div>
                  <div class="col-xl-1 col-lg-1 recordsbodyItems icons">
                    <div class="clickable" title="Edit" *ngIf="editVaccineId != vaccine.id"
                      (click)="editVaccineId = vaccine.id">

                      <i class="fa fa-pencil-square " aria-hidden="true"></i>
                    </div>
                    <div *ngIf="editVaccineId == vaccine.id" title="Save" class="editIcon ml-2 clickable"
                      (click)="updateVaccine(vaccine)">


                      <i class="fa fa-save clr--blue" style="font-size:24px"></i>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


<p-confirmDialog appendTo="body"></p-confirmDialog>