import { ChangeDetectorRef, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { PatientService } from '../../services/patient.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MarketPlaceService } from '../../services/market-place.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { ProductsComponent } from '../products/products.component';

@Component({
  selector: 'app-market-place-par',
  templateUrl: './market-place-par.component.html',
  styleUrls: ['./market-place-par.component.scss']
})
export class MarketPlaceParComponent implements OnInit {
  selectedTab: string = 'allProducts';
  @Output() addToCartClicked = new EventEmitter<any>();
  cartData: any[] = [];
  searchNumber: any = '';
  issearchNumberLengthIs10 = 1;
  isUserSelected = 0;
  grandTotal = 0;
  orderPlacedFlagForClearCart: boolean = false;
  grandTotalPrice: number;
  productVariationId: any;
  @ViewChild(ProductsComponent) toRunProductVariations!: ProductsComponent; // Ref
  selectedUser = {};

  userData: any[] = [];
  paymentType: String;
  paymentTypes = [
    { value: 'cash', viewValue: 'Cash' },
    { value: 'upi', viewValue: 'UPI' },
    { value: 'creditCard', viewValue: 'Credit Card' },
    { value: 'debitCard', viewValue: 'Debit Card' },
  ];
  placedOrder;
  isCartData = 0;
  quantity: number = 1;

  productDetails: any;
  masterProductsVariations: any = [];




  constructor(private changeDetectorRef: ChangeDetectorRef, private patientService: PatientService, private snackBAr: MatSnackBar, private marketPlaceService: MarketPlaceService, private router: Router, private route: ActivatedRoute,) {
    this.route.queryParams.subscribe(params => {
      this.productVariationId = params['productVariationId'];
    });
    // this.getProductVariationwithId();
    this.getCartItemsFromLocalStorage()

  }
  // clearCart() {
  //   this.cartData = [];

  // }

  ngOnInit(): void {
    console.log("cartData", this.cartData);
    console.log("selectedUser", this.selectedUser);
    // this.selectedTab = 'allProducts';
    this.getAllMasterProductVariations()
  }

  ngAfterViewInit() {
    if (!this.toRunProductVariations) {
      throw new Error("Product component not found"); // Ensure child component is found
    }
  }

  onItemAdded(item: any) {
    // Update cartData with the new item
    this.cartData.push(item);
  }
  refreshCartData() {
    // Retrieve updated cartData from localStorage
    const updatedCartData = JSON.parse(localStorage.getItem('cartItems') || '[]');

    // Update the cartData variable with the retrieved data
    this.cartData = updatedCartData;
  }


  getAllMasterProductVariations() {
    this.marketPlaceService.getAllMasterProductVariations()
      .subscribe((response) => {
        console.log("respo getAllMasterProductVariations in parent  ", response);

        this.masterProductsVariations = new MatTableDataSource(response);
      })
  }
  onTabClick(tab: string) {
    this.selectedTab = tab;
    // Call a function to fetch data based on the selected tab
    this.fetchDataForTab(tab);
    console.log("tabbbbb", tab);

  }

  // Function to fetch data based on the selected tab
  fetchDataForTab(tab: string) {
    // Implement logic to fetch data based on the selected tab
    // For example:
    if (tab === 'allProducts') {
      // this.fetchAllProductsData();
      console.log("allProducts Tab clicked ");

    } else if (tab === 'orders') {
      // this.fetchOrdersData();
      console.log("orders Tab clicked ");

    } else if (tab === 'inventory') {
      // this.fetchInventoryData();
      console.log("inventory Tab clicked ");

    }
  }


  getProductVariationwithId() {
    let req_vars = { productVariationId: this.productVariationId };
    this.marketPlaceService.getProductVariationwithId(req_vars).
      subscribe(response => {
        this.productDetails = response[0];
        console.log("Product Details", this.productDetails)
      })
  }
  addProductToLocalStorge() {
    let item = {
      productVariationId: this.productDetails.id,
      productName: this.productDetails.MasterProduct.productName,
      measurement: this.productDetails.measurement + ' ' + this.productDetails.unitOfMeasurement,
      priceAtOrderTime: this.productDetails.price,
      selectedUnit: this.quantity,
      total: this.productDetails.price * this.quantity
    }

    if (localStorage.getItem('cartItems') == null) {
      this.cartData.push(item)
      localStorage.setItem('cartItems', JSON.stringify(this.cartData))
    } else {
      let productId = this.cartData.findIndex(el => el.productVariationId === this.productDetails.id);
      if (productId === -1) {
        this.cartData.push(item)
        localStorage.setItem('cartItems', JSON.stringify(this.cartData))
      } else {
        this.cartData[productId] = item;
        localStorage.setItem('cartItems', JSON.stringify(this.cartData))
      }
    }

    // Emit an event to notify the parent component
    this.addToCartClicked.emit();

    this.getCartItemsFromLocalStorage();
  }


  getCartItemsFromLocalStorage() {
    if (localStorage.getItem('cartItems') !== null) {
      this.cartData = JSON.parse(localStorage.getItem('cartItems'));
      this.isCartData = 1;
      console.log("cart data", this.cartData)
    } else {
      this.isCartData = 0;
    }
  }

  placeOrder() {
    // Check if user is selected
    if (!this.isUserSelected) {
      this.snackBAr.open('Please Search and Select User', 'X', {
        duration: 3000
      });
      return;
    }

    // Check if cart is empty
    if (this.cartData.length === 0) {
      this.snackBAr.open('Add Items in the Cart', 'X', {
        duration: 3000
      });
      return;
    }

    // Check if payment type is selected
    if (!this.paymentType) {
      this.snackBAr.open('Please select Payment Type', 'X', {
        duration: 3000
      });
      return;
    }

    // Prepare order object
    let orderObject = {
      orderItems: this.cartData,
      paymentType: this.paymentType,
      paymentStatus: 1,
      grandTotal: this.grandTotal,
      userId: this.selectedUser["userId"]
    };

    // Save order
    this.marketPlaceService.saveOrderByUserid(orderObject)
      .subscribe(response => {

        if (response) {
          this.orderPlacedFlagForClearCart = true;
          this.grandTotalPrice = response.grandTotal
          // Handle order placement response
          console.log("Order Details", response);
          this.placedOrder = response;

          // Show order placed message
          this.snackBAr.open('Order Placed', 'X', {
            duration: 3000
          });
          if (this.orderPlacedFlagForClearCart) {
            this.cartData = [];
            this.selectedUser = null;
            this.isUserSelected = 0;
            this.userData = [];
            this.searchNumber = '';
            this.paymentType = null;
            this.changeDetectorRef.detectChanges();
            // Remove cartItems from localStorage
            localStorage.removeItem('cartItems');
            this.toRunProductVariations.getAllMasterProductVariations(); // Call the child function
          }
          // Clear cart items from local storage after order is successfully placed
          if (!this.isUserSelected) {
            // localStorage.removeItem('cartItems');
            console.log("clearrrrrrrrrrrrrrr")

          }
        }

      });
  }



  removeCartItem(index: number) {
    this.cartData.splice(index, 1); // Remove the item at the specified index
    localStorage.setItem('cartItems', JSON.stringify(this.cartData)); // Update localStorage
  }
  grandTotalSum: number = 0;
  calculateGrandTotal(): number {
    this.grandTotalSum = 0;
    for (let item of this.cartData) {
      this.grandTotalSum += item.total; // Calculate individual total and add to grand total sum
    }
    return this.grandTotalSum;
  }


  selectPaymentType(type: any) {

    // this.paymentType = type; 
    console.log("selected payment type is =", type);

  }
  viewOrder(orderId: any) {
    let req_vars = { orderId: orderId };
    this.marketPlaceService.getOrderWithId(req_vars).
      subscribe(response => {
        console.log("Order Details of order with orderId", orderId)
        console.log(response);
        this.placedOrder = response;
      })
  }
  userSelected(user) {


    this.isUserSelected = 1;
    this.selectedUser = user;
    console.log("userSelected", user);
    console.log("userSelected.firstName", user.firstName);


    // firstName
    // lastName
    // address
    // locality

  }
  getUserByContactNumber() {
    // https://app.copious.care/api/doctor/getAllPatients/9665767383

    if (this.searchNumber.length != 10) {
      this.issearchNumberLengthIs10 = 0;
      return;
    } else {
      this.issearchNumberLengthIs10 = 1;
      this.patientService.getPatientsByFullNumberSearch(this.searchNumber)
        .subscribe(response => {
          // this.formatPatientRecords(response);
          this.userData = response;
          if (this.userData.length > 0) {
            this.isUserSelected = 0;
          }
        });
    }
  }

  activatAllOrderTab() {
    this.selectedTab = 'orders';
  }

  activateInventoryTab() {
    this.selectedTab = 'inventory'; // Update selected tab
  }

}
