import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NavigationServiceService {

  private showNav$: BehaviorSubject<any> = new BehaviorSubject<any>(false);

  constructor() { }

  ngOnInit() {
  }

  getShowNav(){
    return this.showNav$.asObservable();
  }

  setShowNav(showHide: any) {
    this.showNav$.next(showHide);
  }

  toggleNavState() {
    this.showNav$.next(!this.showNav$.value);
  }

  isNavOpen() {
    return this.showNav$.value;
  }

}
