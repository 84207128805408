<div class="title" style="display: flex; flex-direction: row; margin: 5% 0% 1% 5%;">
  <h3> Products Inventory</h3>
  <!-- <button id="addProductButton" (click)="addProduct()">Add Product</button> -->
  <!-- <button id="addProductButton" (click)="openModal(mytemplate)">Add Product</button> -->

  <!-- <button id="addProductButton" (click)="viewListedProducts()">View Listed Products</button> -->
</div>
<div style="height: 80vh; overflow-y: auto;">
  <table id="inventoryTable" mat-table [dataSource]="masterProductsVariations">

    <ng-container matColumnDef="productName">
      <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Product Name </th>
      <td mat-cell *matCellDef="let product" style="text-align: left;"> {{product.MasterProduct.productName}} </td>
    </ng-container>

    <ng-container matColumnDef="price">
      <th mat-header-cell *matHeaderCellDef> Price </th>
      <td mat-cell *matCellDef="let product"> &#8377; {{product.price}} </td>
    </ng-container>

    <ng-container matColumnDef="company">
      <th mat-header-cell *matHeaderCellDef> Company </th>
      <td mat-cell *matCellDef="let product"> {{product.MasterProduct.supplierId}} </td>
    </ng-container>

    <ng-container matColumnDef="measurement">
      <th mat-header-cell *matHeaderCellDef> Measurement </th>
      <td mat-cell *matCellDef="let product"> {{product.measurement}} {{product.unitOfMeasurement}}</td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef> Quantity </th>
      <td mat-cell *matCellDef="let product"> {{product.quantity}} </td>
    </ng-container>

    <ng-container matColumnDef="update">
      <th mat-header-cell *matHeaderCellDef style="text-align: center"> Update </th>
      <td mat-cell *matCellDef="let product" style="width: 20% !important; text-align: center;">
        <mat-icon style="height: 20px;cursor: pointer; color: #6887C7;"
          (click)="openUpdateInventoryModal(updateInventoryModal,product); editInventory(product)">edit</mat-icon>
      </td>
    </ng-container>

    <!-- <ng-container matColumnDef="delete">
      <th mat-header-cell *matHeaderCellDef style="text-align: start"> Delete </th>
      <td mat-cell *matCellDef="let product" style="width: 20% !important; text-align: center;">
          <div style="flex-direction: row;display:flex; width: 15%;  justify-content: center; align-items: center; ">
              <img (click)="opendeleteProductVariationModal(deleteProductModal,product)" style="height: 20px;cursor: pointer;" src="assets/images/delete_red.svg" />
          </div>
      </td>
  </ng-container>  -->

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  </table>
</div>


<ng-template #mytemplate>
  <div style="height: 600px; width: 750px;">
    <button id="closeModal" (click)="closeModal()">X</button>
    <h1 style="margin: 20px 0px 10px 25px;">Add Product and Variations</h1>
    <form (ngSubmit)="onSubmit()" [formGroup]="masterProductFormGroup">
      <!-- <form  [formGroup]="masterProductFormGroup"> -->
      <div style="display: flex; width: 100%;flex-wrap: wrap;">
        <mat-form-field appearance="outline">
          <mat-label>Product Name</mat-label>
          <input matInput formControlName="productName">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Product Description</mat-label>
          <input matInput formControlName="productDescription">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Unit Of Measurement</mat-label>
          <mat-select formControlName="unitOfMeasurement">
            <mat-option value="litre">litre</mat-option>
            <mat-option value="kg">kilogram</mat-option>
            <mat-option value="pieces">Piece</mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Price Per Unit</mat-label>
          <input matInput formControlName="pricePerUnit">
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>category</mat-label>
          <input matInput formControlName="category">
        </mat-form-field>
        <button (click)="addMasterProductVariations()">Add Item</button><br>
        <button id="submitbutton" type="submit" class="btn btn-success">Submit</button>
      </div>
    </form>

  </div>
</ng-template>

<ng-template #updateInventoryModal>
  <div style="width: 350px;height: 350px;">
    <button id="closeModal" (click)="closeModal()">X</button>
    <h1 style="margin: 20px 0px 10px 25px;">{{selectedProduct.MasterProduct.productName}}</h1>
    <h5 style="margin: 20px 0px 10px 25px;">Variant : {{selectedProduct.measurement}}
      {{selectedProduct.unitOfMeasurement}}</h5>
    <h5 style="margin: 20px 0px 10px 25px;">Quantity : {{selectedProduct.quantity}}</h5>


    <div style="width: 50%;">
      <div style="display: flex;flex-direction: row;justify-content: left;margin: 25px;">
        <div>
          <button id="subtraction" (click)="decrement();">
            <mat-icon class="removeIcon">remove</mat-icon>
          </button>
        </div>
        <!-- <div class="quantityUi" style="width: 60px;"> -->
        <div>
          <input style="width: 60px; margin: 10px;" type="number" min="0" class="form-control" #searchInput [(ngModel)]="quantity" />
        </div>
        <!-- </div> -->
        <div>
          <button id="addProductInventory" (click)="increment();">
            <mat-icon class="removeIcon">add</mat-icon>
          </button>
        </div>
      </div>




      <div class="addtocardbutton">
        <button id="addTocard" (click)="updateProductVariationQuantity()">Update</button>
      </div>
    </div>

  </div>
</ng-template>

<ng-template #deleteProductModal>
  <div style="width: 350px;height: 350px;">
    <button id="closeModal" (click)="closeModal()">X</button>
    <h3 style="margin: 20px 0px 10px 25px;">Please confirm to delete this Product variation</h3>
    <mat-card>
      <h4 style="margin: 20px 0px 10px 25px;">Product : {{selectedProduct.MasterProduct.productName}}</h4>
      <h5 style="margin: 20px 0px 10px 25px;">Variant : {{selectedProduct.measurement}}
        {{selectedProduct.unitOfMeasurement}}</h5>
      <h5 style="margin: 20px 0px 10px 25px;">Quantity : {{selectedProduct.quantity}}</h5>
      <div class="addtocardbutton">
        <button id="addTocard" (click)="deleteProductVariation()">Delete</button>
      </div>
    </mat-card>
  </div>
</ng-template>