import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { DoctorService } from '../../services/doctor.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminService } from '../../services/admin.service';
import { MatStepper } from '@angular/material/stepper';
import { DateAdapter, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE, NativeDateAdapter } from '@angular/material/core'
import * as moment from 'moment'
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery'
import { MatSelect } from '@angular/material/select';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatRadioChange } from '@angular/material/radio';
import { treatmentComponent } from '../../doctor/patient/treatment/treatment.component';
import { PatientService } from '../../services/patient.service';
import { MessageService } from 'primeng/api';

export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};


@Component({
  selector: 'app-doctor-profile-management',
  templateUrl: './doctor-profile-management.component.html',
  styleUrls: ['./doctor-profile-management.component.scss'],
  providers: [MessageService,
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
})
export class DoctorProfileManagementComponent implements OnInit {
  selectedLanguage:any=""
  microcluster:any=""
  genderArray:any=[{name:"female"},{name:"male"}]
  languageArray: any = [{ name: 'English', language: 'en' }, { name: 'Marathi', language: 'mr' }, { name: 'Hindi', language: 'hi' }, { name: 'Kannada', language: 'kn' }];
  doctorTypeArray:any=[{name:'Doctor',checked: true},{name:'Asha',checked: false},{name:'Lab',checked: false}]
  patientId=null;
  patientDoB:any=""
  patientVaccinationDate:any=""
  jsonFlag:boolean=true
  isAsha:any=""
  doctorSelectedLang=""
  displayedColumns: string[] = ['templateName', 'action'];
  appintmentType = [
    { name: "Appointment", id: "1", checked:false  },
    { name: "Queue", id: "2",checked:false  }
   
  ];
  prescriptionShowSettingTypes= [
    { name: "showPedStats", value: "Y", checked:true },
    { name: "showChiefComp", value: "Y",checked:true },
    { name: "showDiagnosis", value: "Y",checked:true },
    { name: "showAdultStats", value: "Y",checked:true }
   
  ];
  prescriptionSettings:any=[]
  editprescriptionSettings:any={
  
  }
  isLinear = true;
  @ViewChild('stepper') private stepper: MatStepper;
  @ViewChild('select') select: MatSelect;
  @ViewChild('DocRxSettings')DocRxSettings;
  pinMask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  phoneMask: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/
  userId:any=null
  user:any
  primaryClinic: any = {}
  morningFrom = null //clinicSessions
  morningTo = null
  eveningFrom = null //clinicSessions
  eveningTo = null
  s1FromMaxTime=null
  s1FromMinTime=null
  deletedClinics = []
  days:any=[]
  firstName=""
  lastName=""
  typeDoctor
  mobileNumber:any=""
  registrationNumber=""
  emailId="";
  time = ''
  docServices:any=""
  adminServicesForLocation:any=""
  doctorId=null
  isEnabled:any=1
  isEnabledChecked=false
  slotsArray = [5, 10, 15, 20, 30, 60]
  gender:any=null
  clinicArray:any=[]
  isAppointment:boolean=false
  isAppointmentValue:any=""

  constructor(private messageService: MessageService,private patientService: PatientService,public dialog: MatDialog,private route: ActivatedRoute, private router: Router,private authService: AuthService,private doctorService: DoctorService,private adminService: AdminService) { 
    var d = new Date()
    d.setHours(0, 0, 0)
    this.morningFrom = this.formatTime(d)
    d.setHours(11, 59, 0)
    this.morningTo = this.formatTime(d)
    this.s1FromMaxTime = d
    d.setHours(12, 0, 0)
    this.eveningFrom = this.formatTime(d)
    d.setHours(23, 59, 0)
    this.eveningTo = this.formatTime(d)
    var current_date = new Date()
    current_date.setHours(0, 0, 0)
    this.s1FromMinTime = current_date
    var next_date = new Date(new Date().setDate(current_date.getDate() + 1))
    next_date.setHours(23, 59, 0)
    this.s1FromMaxTime = next_date

  
    }
   

  ngOnInit(): void {
    this.isAsha="d"
  
   
    this.getConstants();
    this.route.queryParams
      .subscribe(params => {
        console.log("log2", params);
        this.userId=(params.userID)? params.userID:null
        setTimeout(()=>{
        
          this.getDoctor()
          
        },500)
       setTimeout(()=>{
        this.getAllDoctorRxTemplate();
       },500)
      
      })

     this.editprescriptionSettings={
        id:null,
        logo:"",
        foneLeft:"",
        templateName:"",
        v3fontsize:"",
        v3fontsizelarge:"",
        v3footerheight:"",
        v3headerheight:"",
        v3height:"",
        v3width:"",
        backgroundImage:"",
        v3marginleft:0
      
      }
  }
  isJson(str) {
    try {
      JSON.parse(str);
      this.jsonFlag=true
      console.log( ": JSON is okay");
    }
    catch (e) {
      this.jsonFlag=false
      console.log(": JSON is malformed: " + e.message);
    }
   
  }

 onDropdownSelectTemplate(value) {
 
  this.gender = value
  console.log("gender",this.gender)

 }
 isDoctorTypeSelect(e,element){
  console.log("element",e, element)
  this.doctorTypeArray.map(ins=>{
    if(element.name=='Asha'){
      this.isAsha='y'
      ins.checked=true
    }
    if(element.name=='Doctor'){
      this.isAsha='d'
      ins.checked=true
    }
   if(ins.name=="Lab"){
      ins.checked=false
    }
   
  })
  console.log(this.doctorTypeArray)
 
 }

  getConstants(){
    this.adminService.getConstants().subscribe(response => {
      if(response){
        this.days = response.days
      }
     
    })
  }

  openDialog(content) {
  
    this.dialog.open(content);
  }

  closeDialog() {
    this.dialog.closeAll()

  }
  parseDate(dateObject) {
    if (dateObject?.year) {
      return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
    } else {
      return `${dateObject}`
    }
  }

  resetVaccination(){
 
    var postData={
      dob: moment(this.parseDate(this.patientDoB)).format("YYYY-MM-DD"),
      vaccinationStartDate:moment(this.parseDate(this.patientVaccinationDate)).format("YYYY-MM-DD"),
      patientid:this.patientId,
      docUserId: this.userId
    }
   this.patientService.patientresetVaccinationSchedule(postData).subscribe(response => {
    if(response){
      console.log(response)
      this.closeDialog();
    }
   })     
  

  }
getDoctor(){
  if(this.userId!=null){
  let params:any = {
    userId:Number(this.userId)
  }
  console.log("param",params)


 
  this.doctorService.getDoctorDetails(params).subscribe(response => {
    if (response.success) {
      console.log("respos",response)
      let demo:any=response.doctor.isAppointmentEnabled
      this.doctorId=response.doctor.id
      this.userId=response.doctor.UserId
     this.firstName=response.doctor.User.first_name
     this.lastName=response.doctor.User.last_name
     this.emailId=response.doctor.User.email_id
     if(response.doctor.User.phone_number.includes("91")){
      this.mobileNumber=response.doctor.User.phone_number.replace('91','');
     }
     //this.mobileNumber=response.doctor.User.phone_number
     this.registrationNumber=response.doctor.registration_number
     var docLang=response.doctor.selectedLanguage
    
     this.microcluster=response.doctor.microcluster
     this.gender=response.doctor.User.gender

  
     let lang = "";
     switch (docLang) {
      case 'en':
        lang = "English"
        break;

      case 'hi':
        lang = "Hindi"
        break;

      case 'mr':
        lang = "Marathi"
        break;

      case 'kn':
        lang = "Kannada"
        break;

      default:
        break;
    }
    this.doctorSelectedLang = lang ;
    this.doctorTypeArray.map(ins=>{
   if(demo==0){
    if(ins.name=="Asha"){
      ins.checked=true
      this.isAsha='y'
    }
   }
     })
    console.log("llll",this.doctorSelectedLang)
      this.genderArray.map(ins=>{
        if(ins.name==this.gender){
          this.gender=ins.name
        }
      })
     
     var isEnabled=response.doctor.isEnabled
     if(isEnabled==1){
     this.isEnabledChecked=true
     }
     else if(isEnabled==0){
      this.isEnabledChecked=false
     }
   
     var isAppointment=response.doctor.isAppointmentEnabled
     this.isAppointmentValue=isAppointment
     
      this.appintmentType.map(ins=>{
        if(isAppointment=="1" && ins.name=='Appointment'){
      
          ins.checked=true
        }
        else if(isAppointment=="2" && ins.name=='Queue'){
          ins.checked=true
        }
      })
     

     console.log("doctorId",response)
    }
    if (response.doctor.services && response.doctor.services !== null) {
      var services=response.doctor.services ? response.doctor.services:"";
      this.docServices=services ? services :""
    }
    if (response.doctor.adminservices && response.doctor.adminservices !== null) {
      var services=response.doctor.adminservices ? response.doctor.adminservices:"";
      this.adminServicesForLocation=services ? services :""
    }
    })
    // setTimeout(()=>{
    //   this.getClinic();
    // },500)
  }
   


}
isEnabledisAppointment(event: MatRadioChange,data){
 
    this.isAppointmentValue=data.id
}
getClinic(){
  console.log("doctorId",this.doctorId)
  let doc_params = {
    doctor_id:Number(this.doctorId)
  }
  this.adminService.getClinics(doc_params).subscribe(response => {
    if (response.success) {
      if (response.clinics.length == 0) {
        this.addClinic(0)
      } else {
        this.clinicArray = response.clinics
        this.clinicArray.map((clinic, i) => {
          clinic.pincodeErrors = null
          clinic.contactErrors = []
          clinic.consultantErrors = []
          clinic.sessionErrors = []
          clinic.session1Errors = []
          clinic.session2Errors = []
          clinic.blankSession1Errors = []
          clinic.blankSession2Errors = []
          if (clinic.ClinicConsultants.length == 0) {
            this.addField('ClinicConsultants', i)
          }
          if (clinic.ClinicContacts.length == 0) {
            this.addField('ClinicContacts', i)
          }
          if (clinic.ClinicFacilities.length == 0) {
            this.addField('ClinicFacilities', i)
          }
          if (clinic.ClinicSchedules.length == 0) {
            this.addField('ClinicSchedules', i)
          }
        })
      }

    }
    else {
      //this.toastr.error(response.error)
    }
  })
}

  addClinic(id) {
    let clinicObj = {
      name: null,
      address_line1: null,
      address_line2: null,
      address_line3: null,
      city: null,
      pincode: null,
      primary: 0,
      include_on_letterhead: 0,
      ClinicSchedules: [],
      ClinicContacts: [],
      ClinicConsultants: [],
      ClinicFacilities: [],
      multispeciality: 0,
      contactErrors: [],
      sessionErrors: [],
      consultantErrors: [],
      scheduleErrors: [],
      session1Errors: [],
      session2Errors: [],
      blankSession1Errors: [],
      blankSession2Errors: [],
      pincodeErrors: null
    }
    if (this.clinicArray.length == 0) {
      clinicObj['primary'] = 1
      clinicObj['include_on_letterhead'] = 1
      this.primaryClinic = clinicObj
    }
    this.clinicArray.push(clinicObj)
    let index = this.clinicArray.length - 1
    this.addField('ClinicContacts', index)
    this.addField('ClinicFacilities', index)
    this.addField('ClinicConsultants', index)
    this.addField('ClinicSchedules', index)

  }


  addField(field, index) {
    let obj = {}
    switch (field) {
      case "ClinicContacts":
        obj = { phone: null, email: null }
        break;
      case "clinicServices":
        obj = { service_type: null }
        break;
      case "clinicConsultants":
        obj = { first_name: null, last_name: null, phone: null, email: null }
        break;
      case "ClinicSchedules":
        obj = {
          day: null,
          on_call: 0,
          morning_from: this.morningFrom,
          morning_to: this.morningTo,
          evening_from: this.eveningFrom,
          evening_to: this.eveningTo,
        }
        break;
      default:
        obj = {}
        break;
    }
    this.clinicArray[index][field].push(obj)
    console.log("clicicArray",this.clinicArray)
  }
  format(cIndex, sIndex, field) {
    this.clinicArray[cIndex].ClinicSchedules[sIndex][field] = this.formatTime(this.clinicArray[cIndex].ClinicSchedules[sIndex][field])
  }
  formatTime(date_obj) {
    if (date_obj) {
      var hour = date_obj.getHours();
      var minute = date_obj.getMinutes();
      var amPM = (hour > 11) ? " PM" : " AM";

      if (hour > 12) {
        hour -= 12;
      }
      if (minute < 10) {
        minute = "0" + minute;
      }
      return hour + ":" + minute + amPM;
    }

  }
  isEnabledDoctor(e){
    console.log("event",e)
    if(e==true){
      this.isEnabled=1
    }
    else if(e==false){
      this.isEnabled=0
    }
  }

  onSignUp(){
  //  this.doctorId=Number(this.doctorId)
     console.log(this.doctorTypeArray)
     this.doctorTypeArray.map(ins=>{
      if(ins.name=="Asha" && ins.checked==true){
        this.isAsha='y'
      }
     })
    if(this.mobileNumber=="" || this.firstName=="" ||this.lastName=="" || this.gender==""||this.registrationNumber==""){
    }
    else{

    let postData = {
      _id:this.doctorId ? this.doctorId:null,
      firstName: this.firstName,
      lastName: this.lastName,
      selectedLanguage:this.selectedLanguage,
      microcluster:this.microcluster,
      email: this.emailId,
      contactNo: '+91' + ' ' + this.mobileNumber,
      contactNo2:"",
      gender:this.gender,
      isEnabled:this.isEnabled ? this.isEnabled:0,
      isAsha:this.isAsha,
      doctorId: this.registrationNumber,
     
    };
    this.adminService.doctorRegister(postData).subscribe(response => {
      if(response){
        console.log("res",response)
        this.doctorId=(response._id)
        this.userId = response.UserId ? response.UserId:this.userId;

        this.getClinic();
      }
  //  setTimeout(()=>{
  //   this.getClinic();
  //  },500)
   if(this.isAsha=="d"){
    this.getAllDoctorRxTemplate();
    this.stepper.next()
   }
   else{
    this.messageService.add({ severity: 'custom', detail: 'Asha onboard successfully' });
    this.firstName=""
    this.lastName="";
    this.emailId="";
    this.mobileNumber="";
    this.gender="";
    this.registrationNumber="";
    this.isAsha=""
    this.doctorId=null
    this.microcluster=""
    this.doctorSelectedLang="Language"

   }
 
    
    })
  
  }
    
  }



  removeClinic(index) {

    if (this.clinicArray[index].id) {
      this.deletedClinics.push(this.clinicArray[index].id)
    }
    this.clinicArray.splice(index, 1)
  }

  saveClinics() {
    console.log("saveClinics fired");
    console.log("clinicArray length:", this.clinicArray.length);

    let errorClinics = [];
    this.clinicArray.map((clinic, i) => {
      if (!this.validateclinic(clinic, i)) {
        errorClinics.push(clinic);
        console.log("Error Clinic Found:", clinic); // Added for debugging
      }
    });
  
    if (errorClinics.length == 0) {
      let params = {
        user_id: this.userId,
        clinics: this.clinicArray,
        deleted_clinics: [],
        opdAppointmentTime: this.time
      };
  
      this.doctorService.updateClinics(params).subscribe(response => {
        if (response) {
          console.log("updateClinics", response);
          console.log("updateClinics params", params);
        } else {
          // this.toastr.error(response.error)
        }
      });
    } else {
      console.log("Error Clinics:", errorClinics); // Added for debugging
      if (!(/Edge\/\d./i.test(navigator.userAgent))) {
        document.getElementsByClassName('main-content')[0].scrollTo(0, 0);
      }
      // this.toastr.error("Please fill in the required fields")
    }
    this.stepper.next();
  }
  
  addLabelClass(e) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

  validateclinic(clinic, i) {
    console.log("Validating clinic:", clinic);
    let error = {}
    clinic.pincodeErrors = null

    if (Number(clinic.pincode) != 0) {
      if (clinic.pincode.toString().length < 6) {
        clinic.pincodeErrors = 'error'
      }
      else {
        clinic.pincodeErrors = null
      }
    }
    if (Number(clinic.pincode) == 0) {
      clinic.pincode = null
    }

    /****************************validate clinic contacts**************************************************************/
    clinic.contactErrors = []
    clinic.ClinicContacts.map((contact) => {
      if (contact.email != "" && contact.email != null && !this.emailRegex.test(contact.email)) {
        clinic.contactErrors.push("eerror")
      }
      if (contact.phone != "" && contact.phone != null && contact.phone.length < 10) {
        clinic.contactErrors.push("perror")
      } else {
        clinic.contactErrors.push("")
      }
    })

    /****************************validate clinic consultants**************************************************************/
    clinic.consultantErrors = []
    clinic.ClinicConsultants.map((consultant) => {
      if (consultant.email != "" && consultant.email != null && !this.emailRegex.test(consultant.email)) {
        clinic.consultantErrors.push("eerror")
      }
      if (consultant.phone != "" && consultant.phone != null && consultant.phone.length < 10) {
        clinic.consultantErrors.push("perror")
      } else {
        clinic.consultantErrors.push("")
      }

    })


    /****************************validate clinic schedules**************************************************************/
    clinic.sessionErrors = []
    clinic.session1Errors = []
    clinic.session2Errors = []
    clinic.blankSession1Errors = []
    clinic.blankSession2Errors = []

    let daysSelected = []
    clinic.ClinicSchedules.map((schedule) => {
      if (daysSelected.indexOf(schedule.day) == -1) {
        if (schedule.day != null) {
          daysSelected.push(schedule.day)
          clinic.sessionErrors.push("")
        }
      } else {
        clinic.sessionErrors.push("error")
      }

      let morningFrom = moment(schedule.morning_from, "HH:mm a");
      let morningTo = moment(schedule.morning_to, "HH:mm a");
      let morningduration = moment.duration(morningTo.diff(morningFrom));
      let morninghours = morningduration.asHours();

      let eviningFrom = moment(schedule.morning_from, "HH:mm a");
      let eviningTo = moment(schedule.morning_to, "HH:mm a");
      let eviningduration = moment.duration(eviningTo.diff(eviningFrom));
      let evinghours = eviningduration.asHours();

      if (morninghours && schedule.morning_from && morninghours < 0) {
        clinic.session1Errors.push("error")
      } else {
        clinic.session1Errors.push("")
      }
      if (evinghours && schedule.evening_from && evinghours < 0) {
        clinic.session2Errors.push("error")
      } else {
        clinic.session2Errors.push("")
      }
      if (schedule.morning_from && !schedule.morning_to) {
        clinic.blankSession1Errors.push("error")
      } else if (!schedule.morning_from && schedule.morning_to) {
        clinic.blankSession1Errors.push("error")
      } else {
        clinic.blankSession1Errors.push("")
      }
      if (schedule.evening_from && !schedule.evening_to) {
        clinic.blankSession2Errors.push("error")
      } else if (!schedule.evening_from && schedule.evening_to) {
        clinic.blankSession2Errors.push("error")
      } else {
        clinic.blankSession2Errors.push("")
      }
    })

    if (clinic.contactErrors.indexOf("eerror") > -1 || clinic.pincodeErrors != null || clinic.contactErrors.indexOf("perror") > -1 || clinic.consultantErrors.indexOf("eerror") > -1 || clinic.consultantErrors.indexOf("perror") > -1 || clinic.sessionErrors.indexOf("error") > -1 || clinic.session1Errors.indexOf("error") > -1 || clinic.session2Errors.indexOf("error") > -1 || clinic.blankSession1Errors.indexOf("error") > -1 || clinic.blankSession2Errors.indexOf("error") > -1) {
      return false
    } else {
      return true
    }
  }

  saveDoctorServices(){
   
  if(!this.jsonFlag){

  }
    else{

    
    let postData={
      id:this.doctorId,
      services:(this.docServices),
      adminservices:this.adminServicesForLocation,
      isAppointmentEnabled:this.isAppointmentValue
    }
    console.log(postData)
    this.adminService.saveServicesByDoctorId(postData).subscribe(response => {
      if(response){
        console.log("Savesevices",response)
      }   
      this.stepper.next();
    })
   
  } 
 }


  getAllDoctorRxTemplate(){
   console.log("userId",this.userId)
   if(this.userId!=null){

   
    let postData={
      doctorUserId:Number(this.userId)
    }
    
    this.adminService.getAllDoctorRxTemplate(postData).subscribe(response => {

      if(response){
     console.log("rxTemp",response)
    
     this.prescriptionSettings=response.res

      }
    })
  }
  }

  getPrescriptionValue(element){
    if(element){
      this.editprescriptionSettings=element
      this.openDialog(this.DocRxSettings)
    }
   

    console.log("pervalue",element)
  }
  isdisableSettingsTypes(e,data){
   
      this.prescriptionShowSettingTypes.map(ins=>{
        if(e.checked==false && ins.name==data.name){
          ins.checked=false;
          ins.value='N'
        
        }
        else{
          ins.checked=true;
          ins.value='Y'
        }
      })
    

  }
  saveRxTemplateByDocUserId(){
   
    var showPedStats=""
    var showChiefComp=""
    var showDiagnosis=""
    var showAdultStats=""
    this.prescriptionShowSettingTypes.map(ins=>{
      if(ins.name=="showPedStats"){
        showPedStats=ins.value
      }
      if(ins.name=="showChiefComp"){
        showChiefComp=ins.value
      }
      if(ins.name=="showDiagnosis"){
        showDiagnosis=ins.value
      }
      if(ins.name=="showAdultStats"){
        showAdultStats=ins.value
      }
    })
    if(this.userId!=null){

  
    let postData={
      id:this.editprescriptionSettings.id,
      doctorUserId:this.userId,
      rxHeaderUrl:this.editprescriptionSettings.logo,
      rxfooterUrl:this.editprescriptionSettings.foneLeft,
      v3height:this.editprescriptionSettings.v3height,
      v3width:this.editprescriptionSettings.v3width,
      v3headerheight:this.editprescriptionSettings.v3headerheight,
      v3footerheight:this.editprescriptionSettings.v3footerheight,
      v3fontsize:this.editprescriptionSettings.v3fontsize,
      v3fontsizelarge: this.editprescriptionSettings.v3fontsizelarge,
      templateName: this.editprescriptionSettings.templateName,
      backgroundImage:this.editprescriptionSettings.backgroundImage,
      showPedStats:showPedStats,
      showChiefComp:showChiefComp,
      showDiagnosis:showDiagnosis,
      showAdultStats:showAdultStats,
      v3marginleft:this.editprescriptionSettings.v3marginleft


    }

    this.adminService.saveRxTemplateByDocUserId(postData).subscribe(response => {

      if(response){
        console.log("perResponse",response);
        this.getAllDoctorRxTemplate();
      this.closeDialog();
       
      }
    
     
     // 
      })
    }
  }
  createNewPerscription(){
    console.log("userId",this.userId)
    var showPedStats=""
    var showChiefComp=""
    var showDiagnosis=""
    var showAdultStats=""
    this.prescriptionShowSettingTypes.map(ins=>{
      if(ins.name=="showPedStats"){
        showPedStats=ins.value
      }
      if(ins.name=="showChiefComp"){
        showChiefComp=ins.value
      }
      if(ins.name=="showDiagnosis"){
        showDiagnosis=ins.value
      }
      if(ins.name=="showAdultStats"){
        showAdultStats=ins.value
      }
    })
   this.editprescriptionSettings.id=null,
   
    this.editprescriptionSettings.logo=""
   this.editprescriptionSettings.foneLeft=""
  this.editprescriptionSettings.v3height=""
   this.editprescriptionSettings.v3width=""
   this.editprescriptionSettings.v3headerheight=""
  this.editprescriptionSettings.v3footerheight=""
   this.editprescriptionSettings.v3fontsize=""
     this.editprescriptionSettings.v3fontsizelarge=""
    this.editprescriptionSettings.templateName=""
    this.editprescriptionSettings.backgroundImage=""
    this.editprescriptionSettings.v3marginleft=0

  }
  validateNumber(e) {
    return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.which == 13) || (e.keyCode == 9) || (e.keyCode == 190) || (e.keyCode == 110) || (e.keyCode == 46)
  }

  loginDoctor(){
    this.router.navigate([`/login`], { queryParams: { mobileNumber: this.mobileNumber } });
    // this.router.navigate(['/login']);
  }

  removeField(field, index, i) {
    if (field == "ClinicContacts" && this.clinicArray[index][field][i].id) {
      if (!this.clinicArray[index]['deleted_contacts']) {
        this.clinicArray[index]['deleted_contacts'] = []
      }
      this.clinicArray[index]['contactErrors'].splice(i, 1)
      this.clinicArray[index]['deleted_contacts'].push(this.clinicArray[index][field][i].id)
    }
    if (field == "ClinicSchedules" && this.clinicArray[index][field][i].id) {
      if (!this.clinicArray[index]['deleted_schedules']) {
        this.clinicArray[index]['deleted_schedules'] = []
      }

      this.clinicArray[index]['deleted_schedules'].push(this.clinicArray[index][field][i].id)
      this.clinicArray[index]['sessionErrors'].splice(i, 1)
      this.clinicArray[index]['session1Errors'].splice(i, 1)
      this.clinicArray[index]['session2Errors'].splice(i, 1)
      this.clinicArray[index]['blankSession1Errors'].splice(i, 1)
      this.clinicArray[index]['blankSession2Errors'].splice(i, 1)
    }
    if (field == "ClinicFacilities" && this.clinicArray[index][field][i].id) {
      if (!this.clinicArray[index]['deleted_facilities']) {
        this.clinicArray[index]['deleted_facilities'] = []
      }

      this.clinicArray[index]['deleted_facilities'].push(this.clinicArray[index][field][i].id)
    }
    if (field == "ClinicConsultants" && this.clinicArray[index][field][i].id) {
      if (!this.clinicArray[index]['deleted_consultants']) {
        this.clinicArray[index]['deleted_consultants'] = []
      }
      this.clinicArray[index]['consultantErrors'].splice(i, 1)
      this.clinicArray[index]['deleted_consultants'].push(this.clinicArray[index][field][i].id)
    }
    this.clinicArray[index][field].splice(i, 1)
  }



  onDropdownSelectLanguage(key, value) {
   
    let lang = "";

    switch (value) {
      case 'English':
        lang = "en"
        break;

      case 'Hindi':
        lang = "hi"
        break;

      case 'Marathi':
        lang = "mr"
        break;

      case 'Kannada':
        lang = "kn"
        break;

      default:
        break;
    }

    this.selectedLanguage = lang ;
    console.log("lang", this.selectedLanguage)

  }
}
