import { state } from '@angular/animations';
import { Injectable } from '@angular/core';

export interface BadgeItem {
  type: string;
  value: string;
}

export interface ChildrenItems {
  state: string;
  name: string;
  type?: string;
}

export interface Menu {
  state: string;
  name: string;
  type: string;
  icon: string;
  badge?: BadgeItem[];
  children?: ChildrenItems[];
}

const MENUITEMS = [
  // {
  //   state: 'dashboard',
  //   name: 'Dashboard',
  //   type: 'extLink',
  //   icon: 'basic-lock-open',
  //   path: 'dashoboard'
  // }, {
  //   state: 'patients',
  //   name: 'Patient',
  //   type: 'sub',
  //   icon: 'basic-lock-open',
  //   for: 'patient',
  //   children: [
  //     {
  //       state: 'patientBasicInfo',
  //       name: 'Basic Info'
  //     },{
  //       state: 'pastHistory',
  //       name: 'Past History'
  //     },/*{
  //       state: 'pastHistoryNew',
  //       name: 'Past History New'
  //     },*/{
  //       state: 'vitals',
  //       name: 'Vitals'
  //     }, {
  //       state: 'complaints',
  //       name: 'Complaints'
  //     }, {
  //       state: 'investigations',
  //       name: 'investigations'
  //     }, {
  //       state: 'treatment',
  //       name: 'Treatment'
  //     }, {
  //       state: 'growthnutrition',
  //       name: 'GROWTH & NUTRITION'
  //     },{
  //       state:'nextAppointment',
  //       name: 'Next Appointment'
  //     },/*{
  //       state:'growthNutrition',
  //       name: 'growthNutrition'
  //     }*/
  //   ]
  // },
  {
    state: 'homepage',
    name: 'Home',
    type: 'extLink',
    icon: 'basic-lock-open',
    path: 'homepage'
  },
  // {
  //   state: 'MonitoringDataHomePage',
  //   name: 'MonitoringDataHomePage',
  //   type: 'extLink',
  //   icon: 'basic-lock-open',
  //   path: 'MonitoringDataHomePage'
  // },

  // {
  //   state: 'appointments',
  //   name: 'Appointments',
  //   type: 'extLink',
  //   icon: 'basic-lock-open',
  //   path: 'appointments'
  // },
  {
    state: 'viewPrescriptions',
    name: 'View Rx',
    type: 'extLink',
    icon: 'basic-lock-open',
    path: 'viewPrescriptions'
  },
  {
    state: 'profileView',
    name: 'Profile',
    type: 'profile',
    icon: 'basic-lock-open',
    path: 'profileview'
  },
  {
    state: 'reports',
    name: 'Reports',
    type: 'sub',
    for: 'doctor',
    icon: 'basic-lock-open',
    children: [
      {
        state: 'vaccinationreport',
        name: 'Vaccination'
      },
      {
        state: 'vaccineProjections',
        name: 'Vaccine Projections'
      },
      {
        state: 'appdownloads',
        name: 'App Downloads'
      },
      {
        state: 'doctorreport',
        name: 'Analysis Report'
      },
    ]
  },
  {
    state: 'favouriteManagement',
    name: 'Favourite',
    type: 'Favourite',
    icon: 'basic-lock-open',
    path: 'favouriteManagement'
  },
  {
    state: 'vaccineInventory',
    name: 'Inventory',
    type: 'Inventory',
    icon: 'basic-lock-open',
    path: 'vaccineInventory'
  },
  
  // {
  //   state: 'homepagee',
  //   name: 'ShareAppLink',
  //   type: 'extLink',
  //   icon: 'basic-lock-open',
  //   path: 'homepagee'
  // },
  // {
  //   state: 'settings',
  //   name: 'Settings',
  //   type: 'sub',
  //   for: 'doctor',
  //   icon: 'basic-lock-open',
  //   children: [
  //     {
  //       state: 'changeEmail',
  //       name: 'Change Email'
  //     },
  //     {
  //       state: 'changePassword',
  //       name: 'Change Password'
  //     },
  //     {
  //       state: 'changePhone',
  //       name: 'Change Phone No'
  //     },
  //     {
  //       state: 'vaccinations',
  //       name: 'Vaccinations'
  //     }
  //   ]
  // },
];

const LABMENU=[
  {
    state:'labHomePage',
    name:'Home',
   
    type: 'extLink',
    icon: 'basic-lock-open',
    path: 'labHomePage'
  },
  {
    state:'setting',
    name:'Settings',
   
    type: 'extLink',
    icon: 'basic-lock-open',
    path: 'setting',
  }
]

const NEWRXMENU=[
  {
    state:'rxHomePage',
    name:'Home',
   
    type: 'extLink',
    icon: 'basic-lock-open',
    path: 'rxHomePage'
  },
  {
    state:'setting',
    name:'Settings',
   
    type: 'extLink',
    icon: 'basic-lock-open',
    path: 'setting',
  }
]
const ADMINMENUITEM = [
  {
    state: 'otps',
    name: 'OTP',
    type: 'extLink',
    icon: 'basic-lock-open',
    path: 'otps'
  },
  {
    state: 'reports',
    name: 'Reports',
    type: 'extLink',
    icon: 'basic-lock-open',
    path: 'reports'
  },
  {
    state: 'docdash',
    name: 'Dashboard',
    type: 'extLink',
    icon: 'basic-lock-open',
    path: 'docdash'
  },
  {
    state: 'docOnboarding',
    name: 'Onboarding',
    type: 'extLink',
    icon: 'basic-lock-open',
    path: 'docOnboarding'
  },
  {
    state: 'managementReports',
    name: 'Mgmt Reports',
    type: 'extLink',
    icon: 'basic-lock-open',
    path: 'managementReports'
  },
  {
    state: 'marketPlaceData',
    name: 'Marketplace',
    type: 'marketextLink',
    icon: 'basic-lock-open',
    path: 'marketPlaceData'
  }
  // {
  //   state: 'users',
  //   name: 'Users',
  //   type: 'sub',
  //   icon: 'basic-lock-open',
  //   children: [
  //     {
  //       state: 'users',
  //       substate: 'doctors',
  //       name: 'Doctors management'
  //     },
  //     {
  //       state: 'users',
  //       substate: 'customers',
  //       name: 'Customer management'
  //     },
  //     {
  //       state: 'users',
  //       substate: 'patients',
  //       name: 'Patient management'
  //     }
  //   ]
  // },
  // {
  //   state: 'activities',
  //   name: 'Activity Log',
  //   type: 'extLink',
  //   icon: 'basic-lock-open',
  //   path: 'labs'
  // },
  // {
  //   state: 'settings',
  //   name: 'Site settings',
  //   type: 'sub',
  //   icon: 'basic-lock-open',
  //   children: [
  //     {
  //       state: 'settings',
  //       substate: 'labs',
  //       name: 'Lab management'
  //     }
  //   ]
  // },

];

@Injectable()
export class MenuItems {
  getAll(): Menu[] {
    return MENUITEMS;
  }
}
export class LabMenuItems {
  getAll(): Menu[] {
    return LABMENU;
  }
}
export class RxMenuItems {
  getAll(): Menu[] {
    return NEWRXMENU;
  }
}

export class AdminMenuItems {
  getAll(): Menu[] {
    return ADMINMENUITEM;
  }
}
