<section>
  <div class="container">
    <div class="row">
      <div class="admin-login">
        <div class="form-section student-signup">
          <header class="header-wrapper mb-4">
            <div class="logo-section">
              <h4>Copious</h4>
            </div>
          </header>
          <div class="form-heading align-center">
            <h2 class="fn14 cn2 widthAuto underlinecn3">Forgot Password</h2>
          </div>
          <form (submit)="forgotPassword()">
            <!--<div class="form-group">
              <div class="form-field-holder">
                <input type="email" class="form-control" #email="ngModel" name="email_id" placeholder="Enter email" [pattern]="emailPattern" [(ngModel)]="credentials.email_id" (keyup.enter)="login()">
                <i class="la la-envelope cn3"></i>
                <p class="errors " *ngIf="email.errors?.pattern">Please enter valid email id</p>
              </div>
            </div>-->
            <div class="form-group animateLabel">
                <input type="email" #email="ngModel" id="emailInput" class="form-control animateInput mb-2" [(ngModel)]="credentials.email_id" [pattern]="emailPattern" name="email" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                <label [ngClass]="{'animatedLabel':credentials.email_id}">Enter Email</label>
                <p class="errors " *ngIf="email.errors?.pattern">Please enter valid email id</p>
                <a [routerLink]="['/admin/login']" class="forgot-password ">Go back</a>
            </div>
            <!--<div class="form-group">
              <div class="form-field-holder">
                <input (keydown.space)="$event.preventDefault()" type="password" class="form-control .admin-passwordField" name="password" placeholder="Password" [(ngModel)]="credentials.password" (keyup.enter)="login()">
                <a ><i class="la la-eye cn3"></i></a>
                
              </div>
            </div>-->
       
            <div class="form-group">
              <div class="form-field-holder">
                <button type="submit" class="btn-primary opd-primary-btn btn-md">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
