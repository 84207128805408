import { Component,forwardRef, OnInit,Input,EventEmitter, resolveForwardRef, ElementRef } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from '@angular/forms'
import { FormGroup, FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-custom-textinput',
  templateUrl: './custom-textinput.component.html',
  styleUrls: ['./custom-textinput.component.css'],
  // providers: [
  //   {
  //     provide: NG_VALUE_ACCESSOR,
  //     useExisting: CustomTextinputComponent,
  //     multi: true
  //   }
  // ]
 
})
export class CustomTextinputComponent implements OnInit{
  @Input() parentForm:FormGroup
  @Input () fieldName:string
  @Input() public label: string;
  @Input() placeholder: string;
  @Input() field:string
  @Input() identifier:any;
  @Input() isNumeric: boolean; 
  @Input() maxLength: number;
  @Input() disabled: boolean=false;
  @Input() callBack: (args: any, args2: any) => void;
    
      searchString = "";
      message : string;
  constructor(private formBuilder: FormBuilder) {
    //console.log("Inside Constructor "+this.identifier+" "+this.placeholder)
  }
 
 

  ngOnInit(): void {
   
    this.searchString=this.field ;
   
    console.log(this.identifier+" "+this.field)
    // this.inputText(this.searchString)
    // this.onTextChange(this.value);
 
  }
  onTextChange(value)
  {
    console.log("valuee",value)
    this.searchString = value;
    if(this.searchString == '')
    {
      this.message="Textbox is empty !!!";
    }
    
  }
  numberOnly(event:any): boolean {  
    if(!this.isNumeric) return true;  
    const charCode = (event.which) ? event.which : event.keyCode;  
    if (charCode > 31 && (charCode < 48 || charCode > 57) && charCode !=46 ) {  
      return false;  
    }  
    return true;  
  }  
  inputText(label) {
    
    this.callBack(this.searchString, this.identifier);
   // this.searchString=""
  }
}


