<!-- Appointment Progress Card -->
<div class="cardContainer">

    <!-- Serial Number -->
    <div class="serialNumber">{{i+1}}</div>

    <!-- Name & Time -->
    <div (click)="onPressPatientInfo()" class="personalInfoContainer">
        <span class="nameSpan">{{appointmentData.name}}</span>
        <span class="appointmentDateTimeSpan">{{appointmentData.from | date: 'shortTime'}} - {{appointmentData.to |
            date: 'shortTime'}}</span>
        <span class="mobileSpan">+{{appointmentData.mobile}}</span>
    </div>

    <!-- Progress Card : NORMAL APPOINTMENT -->

    <div *ngIf="appointmentData.isTeleCall != '1'" (click)="onPressAppointmentStatus()" class="appointmentStatusCard">


        <!-- if Queue and Absent -->
        <span *ngIf="appointmentData.status == 'noshow'"
            style="position: absolute; z-index: 2; background-color: rgba(255,255,255,0.8); height: 100%; width: 100%; align-self: center; text-align: center; color: #721C23; font-size: 22px; border-radius: 30px; padding-top: 25px;">Absent</span>


        <!-- if Appointment and Cancelled -->
        <span *ngIf="appointmentData.deleted"
            style="position: absolute; z-index: 2; background-color: rgba(255,255,255,0.8); height: 100%; width: 100%; align-self: center; text-align: center; color: #721C23; font-size: 22px; border-radius: 30px; padding-top: 25px;">Cancelled</span>

        <!-- Line Indicator -->
        <div
            style="height: 1px; width: 70%; position: absolute; border: 1px dashed #CBCBCB; margin-top: -24px; z-index: 0">
        </div>

        <!-- More Options -->
        <!-- <div style="position: absolute; top: 0; right: 0; ">
    <img style="height: 30px; width: 30px;" src="assets/images/appointment_card_options.svg"
      alt="" />
  </div> -->

        <!-- Booked -->
        <!-- <div class="progressComponent">
            <img class="progressIconStyle" src="assets/images/appointment_card_done.svg" />
            <div class="progressTextStyle">Booked</div>
        </div> -->

        <!-- Arrived -->
        <div class="progressComponent">
            <img *ngIf="!appointmentData.arrived" class="progressIconStyle"
                src="assets/images/appointment_card_blank.svg" />
            <img *ngIf="appointmentData.arrived" class="progressIconStyle"
                src="assets/images/appointment_card_done.svg" />
            <div class="progressTextStyle">Arrived</div>
        </div>

        <!-- Attending -->
        <div class="progressComponent">
            <img *ngIf="appointmentData.arrived && appointmentData.in || appointmentData.done" class="progressIconStyle"
                src="assets/images/appointment_card_done.svg" />
            <img *ngIf="!appointmentData.in" class="progressIconStyle" src="assets/images/appointment_card_blank.svg" />
            <div class="progressTextStyle">Attending</div>
        </div>

        <!-- Done -->
        <div class="progressComponent">
            <img *ngIf="!appointmentData.done" class="progressIconStyle"
                src="assets/images/appointment_card_blank.svg" />
            <img *ngIf="appointmentData.done" class="progressIconStyle" src="assets/images/appointment_card_done.svg" />
            <div class="progressTextStyle">Done</div>
        </div>
    </div>


    <!-- Progress Card : TELE APPOINTMENT -->
    <div *ngIf="appointmentData.isTeleCall === '1'" (click)="onPressAppointmentStatus()" class="appointmentStatusCard">
        <!-- Line Indicator -->
        <div
            style="height: 1px; width: 50%; position: absolute; border: 1px dashed #CBCBCB; margin-top: -24px; z-index: 0">
        </div>

        <!-- Booked -->
        <div class="progressComponent">
            <img class="progressIconStyle" src="assets/images/appointment_card_done.svg" />
            <div class="progressTextStyle">Booked</div>
        </div>

        <!-- Start Call -->
        <div class="progressComponent">
            <img *ngIf="!appointmentData.done" class="progressIconStyle" src="assets/images/home_videocall_icon.svg" />
            <img *ngIf="appointmentData.done" class="progressIconStyle" src="assets/images/appointment_card_done.svg" />
            <div class="progressTextStyle">Start Call</div>
        </div>
    </div>

    <div style="height: 100%; width: 150px; display: flex; justify-content: space-around;">
        <img mat-button [matMenuTriggerFor]="menu" style="height: 40px; width: 40px; cursor: pointer;"
            src="assets/images/appointment_card_options.svg" alt="" />

        <!-- Cancel Apopointment Icon -->
        <img ngbTooltip="Cancel" *ngIf="appointmentData.showCancel && isAppointmentEnabled =='1'"
            (click)="onPressCancelAppointment()" style="height: 40px; width: 40px; cursor: pointer;"
            src="assets/images/cancel_appointment_icon.svg" alt="" />
        <img *ngIf="!appointmentData.showCancel && isAppointmentEnabled =='1' "
            style="height: 40px; width: 40px; cursor: pointer;" src="assets/images/cancel_appointment_icon_off.svg"
            alt="" />

        <img ngbTooltip="Mark Absent" *ngIf="appointmentData.showNoShow && isAppointmentEnabled =='2'"
            (click)="onPressCancelAppointment()" style="height: 40px; width: 40px; cursor: pointer;"
            src="assets/images/cancel_appointment_icon.svg" alt="" />

        <img *ngIf="!appointmentData.showNoShow && isAppointmentEnabled =='2'"
            style="height: 40px; width: 40px; cursor: pointer;" src="assets/images/cancel_appointment_icon_off.svg"
            alt="" />

    </div>

    <!-- <li *ngIf="isAppointmentEnabled == '1'" style="font-weight:bold;text-align: left !important;">Cancel
    </li>
    <li *ngIf="isAppointmentEnabled == '2'" style="font-weight:bold;text-align: left !important;">Absent
    </li> -->


</div>

<mat-menu #menu="matMenu" xPosition="before" [overlapTrigger]="false" class="matPanel">
    <button *ngIf="!appointmentData.isReceipt"  (click)="onPressBillReceipt()" mat-menu-item>Create Receipt</button>
    <button *ngIf="appointmentData.isReceipt" (click)="onPressBillReceipt()" mat-menu-item> <span style="font-size: 16px; font-weight: bold;">Receipt</span> <span style=" color:#1f8447;
        border: 1px solid #b3f1ca; border-radius: 6px; background-color: #c9f5d9;padding: 0px 8px; font-size: 16px;margin-left: 21px;
    margin-right: -17px; ">View</span> </button>
    <!-- <button mat-menu-item>Generate Bill</button> -->
</mat-menu>