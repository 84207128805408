<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
  <label for="" class="loader"></label>
</div>  
<div class="row">
  <div style="width: 100%; background-color: #F3F6FA;">
    <app-patient (messageEvent)="receiveMessage($event)"  ></app-patient>
  </div>

</div>
<p-confirmDialog appendTo="body"></p-confirmDialog>
<ng-template #scheduleandAddNewAppointment let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title" style="text-transform: capitalize;" *ngIf="!editMode">Add New Appointment
      </h6>
      <h6 class="modal-title" style="text-transform: capitalize;" *ngIf="editMode">Appointment Details
      </h6>
      <button type="button" class="close" aria-label="Close" (click)="c()">
        <span aria-hidden="true" style="font-size: 34px;">
          ×
          <!-- <img src="assets/images/close-btn.png" alt=""> -->
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">
        <div class="row">
          <div class="col-12">
            <div class="form-group onFocus" #mobileNumberEl>
              <label [ngClass]="{'animatedLabel':user.mobile_number}" class="newInputStyleLabel">Mobile Number <span
                  class="required">*</span></label>
              <input [(ngModel)]="user.mobile_number" type="text" class="form-control  newInputBox" [readonly]="true"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;">
              <!-- <p-autoComplete *ngIf="!editMode" class="autocomplete animateInput capitalize" [(ngModel)]="number"
                maxlength="12" (onFocus)="addLabelAutoComplete($event)" (onBlur)="removeLabelAutoComplete($event)"
                (keyup)="removeLabelAutoComplete($event)" field="label" [suggestions]="users"
                (completeMethod)="getUsers(number)" [appendTo]="mobileNumberEl" (onSelect)="selectUser(number)">
              </p-autoComplete> -->

              <p class="errors" *ngIf="errors.mobile_number">{{errors.mobile_number}}</p>
            </div>
          </div>
        </div>
        <!-- <div class="row">
          <div class="col-12">
            <div class="form-group animateLabel onFocus" #firstNameEl>
              <input (blur)="trimInput(user,'first_name')" *ngIf="editMode" [(ngModel)]="user.first_name" type="text"
                class="form-control animateInput capitalize" (focus)="addLabelClass($event)"
                (blur)="removeLabelClass($event)" [readonly]="editMode" maxlength="25">
              <p-autoComplete *ngIf="!editMode" class="autocomplete animateInput capitalize" [(ngModel)]="firstName"
                (onFocus)="addLabelAutoComplete($event)" (onBlur)="removeLabelAutoComplete($event)"
                (keyup)="removeLabelAutoComplete($event)" field="first_name" [suggestions]="users"
                [appendTo]="firstNameEl" (completeMethod)="searchUsers($event,'first_name')" maxlength="25"
                (onSelect)="selectUser(firstName)">
              </p-autoComplete>
              <label [ngClass]="{'animatedLabel':firstName['first_name'] || editMode}">First Name of Patient <span
                  class="required">*</span></label>
              <p class="errors" *ngIf="errors.first_name">{{errors.first_name}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group animateLabel onFocus" #lastNameEl>
              <input (blur)="trimInput(user,'last_name')" *ngIf="editMode" [(ngModel)]="user.last_name" type="text"
                class="form-control animateInput capitalize" (focus)="addLabelClass($event)"
                (blur)="removeLabelClass($event)" [readonly]="editMode" maxlength="25">


              <p-autoComplete *ngIf="!editMode" class="autocomplete animateInput capitalize" [(ngModel)]="lastName"
                (onFocus)="addLabelAutoComplete($event)" (onBlur)="removeLabelAutoComplete($event)"
                (keyup)="removeLabelAutoComplete($event)" field="last_name" [suggestions]="users"
                [appendTo]="lastNameEl" maxlength="25" (completeMethod)="searchUsers($event,'last_name')">
              </p-autoComplete>
              <label [ngClass]="{'animatedLabel':lastName['last_name'] || editMode}">Last Name of Patient</label>
              <p class="errors" *ngIf="errors.last_name">{{errors.last_name}}</p>
            </div>
          </div>
        </div> -->
        <div class="row GforHeight">
          <div class="col-6 calender">
            <div class="form-group  calendarIconPopup onFocus">
              <label class="newInputStyleLabel">Date of Birth </label>
              <input placeholder="DD/MM/YYYY" type="text" [placement]="'top-left'" [minDate]="minDate" [maxDate]="today"
                [ngClass]="{'error-field':error}" id="dobInput" class="form-control animateInput" [(ngModel)]="user.dob"
                ngbDatepicker #d="ngbDatepicker" readonly="true" class="newInputBox"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;">
              <img class="calendarbg top25" src="assets/images/calendarIcon.svg" alt=""
                style="margin-right: 4px; margin-top: 2px;" />
              <p class="errors" *ngIf="errors.dob">{{errors.dob}}</p>
            </div>
          </div>
          <div class="col-6 onFocus">
            <label class="ifnoLabel twoColRadioLabel newInputStyleLabel">Gender </label>
            <div class="form-group twoColRadio clearfix d-flex">

              <!-- <label class="custom-control mr-3 custom-radio">
                <input class="custom-control-input" type="radio" [(ngModel)]="user.gender" disabled name="gender"
                  value="male">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Male</span>
              </label> -->
              <label class="container-radio custom-control mr-3" style="margin: 6px 15px 5px 4px;
              color: #868e95; padding-top: 2px; font-size: 16px;">Male
                <input type="radio" [(ngModel)]="user.gender" disabled name="gender" value="male">
                <span class="checkmark-radio"></span>
              </label>

              <!-- <label class="custom-control custom-radio mr-0">
                <input class="custom-control-input" type="radio" [(ngModel)]="user.gender" name="gender" value="female"
                  disabled>
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Female</span>
              </label> -->

              <label class="container-radio custom-control mr-3" style="margin: 6px 15px 5px 4px;
              color: #868e95; padding-top: 2px; font-size: 16px;">Female
                <input type="radio" [(ngModel)]="user.gender" disabled name="gender" value="female">
                <span class="checkmark-radio"></span>
              </label>

              <p class="errors clearfix" *ngIf="errors.gender">{{errors.gender}}</p>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-6 calender">
            <div class="form-group  calendarIconPopup onFocus">
              <label class="newInputStyleLabel">Appointment Date <span class="required">*</span></label>
              <input placeholder="DD/MM/YYYY" type="text" [minDate]="today" [ngClass]="{'error-field':error}"
                id="appointmentDateInput" class="form-control animateInput" [(ngModel)]="user.appointment_date"
                ngbDatepicker #dd="ngbDatepicker" (focus)="dd.open()" (document:click)="closeCalender($event,dd)"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;" readonly>
              <!-- <input *ngIf="editMode" placeholder="DD/MM/YYYY" type="text" [placement]="'top-left'" [minDate]="today"
                [ngClass]="{'error-field':error}" id="appointmentDateInput" class="form-control animateInput"
                [(ngModel)]="user.appointment_date" ngbDatepicker readonly> -->
              <img (click)="dd.toggle()" class="calendarbg top25" src="assets/images/calendarIcon.svg" alt=""
                style="margin-right: 4px; margin-top: 2px;" />
              <p class="errors" *ngIf="errors.appointment_date">{{errors.appointment_date}}</p>
            </div>
          </div>
          <div class="col-6 onFocus">
            <label class="ifnoLabel twoColRadioLabel newInputStyleLabel">Is Video Appointment<span
                class="required">*</span></label>
            <div class="form-group twoColRadio clearfix d-flex">

              <!-- <label class="custom-control mr-3 custom-radio">
                <input class="custom-control-input" type="radio" [(ngModel)]="isTeleAppointMent" [disabled]="editMode"
                  name="tele" value="1">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Yes</span>
              </label> -->

              <label class="container-radio custom-control mr-3" style="margin: 6px 15px 5px 4px;
              color: #333; padding-top: 2px; font-size: 16px">Yes
                <input type="radio" [(ngModel)]="isTeleAppointMent" [disabled]="editMode" name="tele" value="1">
                <span class="checkmark-radio"></span>
              </label>

              <!-- <label class="custom-control custom-radio mr-0">
                <input class="custom-control-input" type="radio" [(ngModel)]="isTeleAppointMent" [disabled]="editMode"
                  name="tele" value="0">
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">No</span>
              </label> -->

              <label class="container-radio custom-control mr-3" style="margin: 6px 15px 5px 4px;
              color: #333; padding-top: 2px; font-size: 16px;">No
                <input type="radio" [(ngModel)]="isTeleAppointMent" [disabled]="editMode" name="tele" value="0">
                <span class="checkmark-radio"></span>
              </label>

              <p class="errors clearfix" *ngIf="errors.isTeleAppointMent">{{errors.isTeleAppointMent}}</p>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="col-6">

            <div class="form-group  calendarIconPopup onFocus">
              <label class="newInputStyleLabel">From<span class="required">*</span></label>
              <!--<input [ngxTimepicker]="picker">
              <ngx-material-timepicker #picker></ngx-material-timepicker>-->
              <p-calendar [(ngModel)]="user.appointment_from" (ngModelChange)="changeTime()" hourFormat="12"
                dateFormat="hh:ii AA" timeOnly="true" readonlyInput="true" class="newInputBox"></p-calendar>
              <!-- <input *ngIf="editMode" type="text" [ngClass]="{'error-field':error}" class="form-control animateInput"
                [(ngModel)]="user.appointment_from" readonly> -->
              <!-- <i class="icon icon-basic-clock top25"></i> -->

              <p class="errors" *ngIf="errors.appointment_from">{{errors.appointment_from}}</p>
            </div>
          </div>
          <div class="col-6">
            <div class="form-group  calendarIconPopup onFocus">
              <label class="newInputStyleLabel">To<span class="required">*</span></label>
              <p-calendar [(ngModel)]="user.appointment_to" hourFormat="12" dateFormat="hh:ii AA" timeOnly="true"
                readonlyInput="true"></p-calendar>
              <!-- <input *ngIf="editMode" type="text" [ngClass]="{'error-field':error}" class="form-control animateInput"
                [(ngModel)]="user.appointment_to" readonly> -->
              <!-- <i class="icon icon-basic-clock top25"></i> -->
              <p class="errors" *ngIf="errors.appointment_to">{{errors.appointment_to}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group selectDropDown bloodGroup onFocus"
              style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;">
              <label class="newInputStyleLabel">Name of Clinic <span class="required">*</span></label>
              <select [(ngModel)]="user.clinic_id" class="infoLabel ng-pristine ng-valid ng-touched"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; height: calc(2.25rem + 2px);">
                <option value="" disabled selected>Select Clinic</option>
                <option *ngFor="let clinic of clinics" value="{{clinic.id}}">{{clinic.name}}</option>
              </select>
              <p class="errors" *ngIf="errors.clinic_id">{{errors.clinic_id}}</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group onFocus">
              <label class="newInputStyleLabel" for="comments">Comments</label>
              <input class="form-control newInputBox" name="comments" placeholder="Enter Comments" maxlength="500"
                [(ngModel)]="user.comment" onfocus="if(placeholder=='Enter Comments'){placeholder=''}"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                onblur="if(placeholder==''){placeholder='Enter Comments'}" [readonly]="editMode">
            </div>
          </div>
        </div>
        <div class="row" style="display:flex; justify-content:flex-end">
          <div class="col-12 mt-3 mb-3" style="display:flex; justify-content:flex-end">
            <button *ngIf="!editMode" class="newBtnStyle" style="text-transform: capitalize; padding:6px 10px"
              type="button" (click)="saveAppointment(c)">Save</button>
            <button *ngIf="editMode" class="newBtnStyle" style="text-transform: capitalize;" type="button"
              (click)="delete(c)">Cancel</button>
            <!-- <button *ngIf="editMode" class="btn-secondary opd-primary-btn btn-md pull-right"
              style="text-transform: capitalize;" type="button" (click)="c()">Close</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Questionnaire let-c="close" let-d="dismiss">
  <div class="popUps" style="width: 600px !important;">
    <div class="modal-header">
      <h6 class="modal-title " style="text-transform: capitalize;">COVID-19 Screening Questionnaire</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true" style="font-size: 34px;">
          ×
          <!-- <img src="assets/images/close-btn.png" alt=""> -->
        </span>
      </button>
    </div>
    <div class="modal-body" style="background-color: #fff; width: 600px !important;">
      <div class="model-content" style=" padding: 0 10px;">
        <div class="selectionTable" style="height: 400px; align-self: center; overflow-y:scroll;">
          <form name="form1" id="form1" style="margin-left: 5px;">
            <table>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">How old is the Patient?</b></td>
              </tr>
              <tr>
                <!-- <td>
                  <input type="radio" name="age" value="Under 18" checked>
                  <label class="optionText">&nbsp; Under
                    18</label></td> -->

                <label class="container-radio">Under 18
                  <input type="radio" name="age" value="Under 18" checked>
                  <span class="checkmark-radio"></span>
                </label>

              </tr>
              <tr>
                <!-- <td><input type="radio" name="age" value="Between 18 and 65"><label class="optionText">&nbsp; Between 18
                    and 65</label>
                </td> -->
                <label class="container-radio">Between 18 and 65
                  <input type="radio" name="age" value="Between 18 and 65">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="age" value="Above 65"><label class="optionText">&nbsp; Above 65</label>
                </td> -->
                <label class="container-radio">Above 65
                  <input type="radio" name="age" value="Above 65">
                  <span class="checkmark-radio"></span>
                </label>

              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">Symptoms shown by Patient (Multiple selection)</b>
                </td>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="symptoms2" (click)="symptomitemselected()"
                    value="Fevers chills or sweating"><label class="optionText">&nbsp; Fevers chills or sweating</label>
                </td> -->

                <label class="container">Fevers chills or sweating
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms2"
                    value="Fevers chills or sweating">
                  <span class="checkmark"></span>
                </label>

              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="symptoms3" (click)="symptomitemselected()"
                    value="Difficulty breathing [not severe]"><label class="optionText">&nbsp; Difficulty breathing [not
                    severe]</label>
                </td> -->
                <label class="container">Difficulty in breathing [not severe]
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms3"
                    value="Difficulty in breathing [not severe]">
                  <span class="checkmark"></span>
                </label>

              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="symptoms4" (click)="symptomitemselected()"
                    value="New or heavy cough"><label class="optionText">&nbsp; New or heavy cough</label></td> -->
                <label class="container">New or heavy cough
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms4" value="New or heavy cough">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="symptoms5" (click)="symptomitemselected()" value="Sore throat"><label
                    class="optionText">&nbsp; Sore throat</label></td> -->
                <label class="container">Sore throat
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms5" value="Sore throat">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="symptoms6" (click)="symptomitemselected()" value="Body ache"><label
                    class="optionText">&nbsp; Body ache</label></td> -->
                <label class="container">Body ache
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms6" value="Body ache">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="symptoms7" (click)="symptomitemselected()"
                    value="Vomitting or Diarrhea" class="optionText"><label class="optionText">&nbsp; Vomitting or
                    Diarrhea</label></td> -->
                <label class="container">Vomitting or Diarrhea
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms7" value="Vomitting or Diarrhea">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="symptoms8" (click)="symptomitemselected()"
                    value="Feels like Influenza"><label class="optionText">&nbsp; Feels like Influenza</label></td> -->
                <label class="container">Feels like Influenza
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms8" value="Feels like Influenza">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="symptoms9" (click)="symptomitemselected()" value="Runny nose"><label
                    class="optionText">&nbsp; Runny nose</label></td> -->
                <label class="container">Runny nose
                  <input type="checkbox" (click)="symptomitemselected()" name="symptoms9" value="Runny nose">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="symptoms1" value="None" (click)="symptomnoneselected()" checked><label
                    class="optionText">&nbsp; None</label></td> -->
                <label class="container">None
                  <input type="checkbox" (click)="symptomnoneselected()" name="symptoms1" value="None" checked>
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">Medical Conditions (Multiple selection)</b></td>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="conditions1" (click)="conditionitemselected()"
                    value="Asthama or Lung disease"><label class="optionText">&nbsp; Asthama or Lung
                    disease</label></td> -->
                <label class="container">Asthama or Lung disease
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions1"
                    value="Asthama or Lung disease">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="conditions2" (click)="conditionitemselected()" value="Pregnancy"><label
                    class="optionText">&nbsp; Pregnancy</label></td> -->
                <label class="container">Pregnancy
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions2" value="Pregnancy">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="conditions3" (click)="conditionitemselected()"
                    value="Diabetes with complications"><label class="optionText">&nbsp; Diabetes with
                    complications</label></td> -->

                <label class="container">Diabetes with complications
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions3"
                    value="Diabetes with complications">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="conditions4" (click)="conditionitemselected()"
                    value="Diseases that make it Hard to cough"><label class="optionText">&nbsp; Diseases that
                    make it Hard to cough</label></td> -->
                <label class="container">Diseases that make it Hard to cough
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions4"
                    value="Diseases that make it Hard to cough">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="conditions5" (click)="conditionitemselected()"
                    value="Kidney failure or Dialysis"><label class="optionText">&nbsp; Kidney failure or
                    Dialysis</label></td> -->
                <label class="container">Kidney failure or Dialysis
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions5"
                    value="Kidney failure or Dialysis">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="conditions6" (click)="conditionitemselected()"
                    value="Liver Cirrhosis"><label class="optionText">&nbsp; Liver Cirrhosis </label></td> -->
                <label class="container">Liver Cirrhosis
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions6" value="Liver Cirrhosis">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="conditions7" (click)="conditionitemselected()"
                    value="Weak immune system"><label class="optionText">&nbsp; Weak immune system</label></td> -->
                <label class="container">Weak immune system
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions7"
                    value="Weak immune system">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="conditions8" (click)="conditionitemselected()"
                    value="Heart disease"><label class="optionText">&nbsp; Heart disease</label></td> -->
                <label class="container">Heart disease
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions8" value="Heart disease">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="conditions9" (click)="conditionitemselected()" value="Obesity"><label
                    class="optionText">&nbsp; Obesity</label></td> -->
                <label class="container">Obesity
                  <input type="checkbox" (click)="conditionitemselected()" name="conditions9" value="Obesity">
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="checkbox" name="conditions10" value="None" (click)="conditionnoneselected()"
                    checked><label class="optionText">&nbsp; None</label></td> -->
                <label class="container">None
                  <input type="checkbox" name="conditions10" value="None" (click)="conditionnoneselected()" checked>
                  <span class="checkmark"></span>
                </label>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">In the last 14 days, has patient travelled outside
                    India?</b></td>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="international" value="(Yes, travelled outside India)"><label
                    class="optionText">&nbsp; Yes,
                    travelled out of India</label></td> -->
                <label class="container-radio">Yes, travelled out of India
                  <input type="radio" name="international" value="(Yes, travelled outside India)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="international" value="No" checked><label class="optionText">&nbsp;
                    No</label>
                </td> -->
                <label class="container-radio">No
                  <input type="radio" name="international" value="No" checked>
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">In the last 14 days, has patient travelled to any
                    area infected with
                    COVID-19?</b></td>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="area" value="(Lives in infected area)"><label class="optionText">&nbsp;
                    Lives in
                    area
                    infected by
                    COVID-19</label></td> -->
                <label class="container-radio">Lives in area infected by COVID-19
                  <input type="radio" name="area" value="(Lives in infected area)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="area" value="(Travelled to infected area)"><label
                    class="optionText">&nbsp; Travelled to
                    area
                    infected by COVID-19</label></td> -->
                <label class="container-radio">Travelled to area infected by COVID-19
                  <input type="radio" name="area" value="(Travelled to infected area)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="area" value="No" checked><label class="optionText">&nbsp; No</label></td> -->
                <label class="container-radio">No
                  <input type="radio" name="area" value="No" checked>
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">In the last 14 days, Patient's exposure to
                    COVID-19?</b></td>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="exposure" value="(Lives with someone who has COVID-19)"><label
                    class="optionText">&nbsp;
                    Lives with
                    someone who has COVID-19</label></td> -->
                <label class="container-radio">Lives with someone who has COVID-19
                  <input type="radio" name="exposure" value="(Lives with someone who has COVID-19)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="exposure" value="(Met someone who has COVID-19)"><label
                    class="optionText">&nbsp; Met
                    someone who
                    has COVID-19</label></td> -->
                <label class="container-radio">Met someone who has COVID-19
                  <input type="radio" name="exposure" value="(Met someone who has COVID-19)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="exposure" value="None" checked><label class="optionText">&nbsp;
                    None</label></td> -->
                <label class="container-radio">None
                  <input type="radio" name="exposure" value="None" checked>
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">Patient works in a Hospital/Nursing Home/Old-age
                    home? (as Nurse,
                    Staff or Doctor)</b></td>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="works" value="(Lives in Hospital/Nursing Home)"><label
                    class="optionText">&nbsp; Lives in
                    Hospital/Nursing Home/Old age home</label></td> -->
                <label class="container-radio">Lives in Hospital/Nursing Home/Old age home
                  <input type="radio" name="works" value="(Lives in Hospital/Nursing Home)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="works" value="(Works at Hospital/Nursing home)"><label
                    class="optionText">&nbsp; Works at
                    Hospital/Nursing home/Old age home</label></td> -->
                <label class="container-radio">Works at Hospital/Nursing home/Old age home
                  <input type="radio" name="works" value="(Works at Hospital/Nursing home)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="works" value="No" checked><label class="optionText">&nbsp; None</label> </td> -->
                <label class="container-radio">None
                  <input type="radio" name="works" value="No" checked>
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <td>&nbsp;</td>
              </tr>
              <tr>
                <td style="padding-bottom: 7px;"><b class="titleText">Doctor's conclusion</b></td>
              </tr>
              <tr>
                <!-- <td noWrap><input type="radio" name="conclusion" checked
                    value="As per information provided by Patient, NO test is needed as of Prescription Date mentioned above"><label
                    class="optionText">&nbsp;
                    No,
                    patient doesn't need to go to Hospital for COVID-19 testing</label></td> -->
                <label class="container-radio">No, patient doesn't need to go to Hospital for COVID-19 testing
                  <input type="radio" name="conclusion"
                    value="As per information provided by Patient, NO test is needed as of Prescription Date mentioned above"
                    checked>
                  <span class="checkmark-radio"></span>
                </label>
              </tr>
              <tr>
                <!-- <td><input type="radio" name="conclusion"
                    value="(As per information provided by Patient, Patient <br> needs to be tested for COVID-19 by any authorized Hospital)"><label><b
                      class="optionText">&nbsp;
                      Yes,
                      Patient needs to go to Hospital for COVID-19 testing</b></label></td> -->
                <label class="container-radio"><b class="optionText">Yes, Patient needs to go to Hospital for COVID-19
                    testing</b>
                  <input type="radio" name="conclusion"
                    value="(As per information provided by Patient, Patient needs to be tested for COVID-19 by any authorized Hospital)">
                  <span class="checkmark-radio"></span>
                </label>
              </tr>

              <tr>
                <td>&nbsp;</td>
              </tr>
            </table>
          </form>
        </div>
        <div class="row d-flex pt-3" style="display: flex; justify-content: flex-end;">
          <button class="newBtnStyle" (click)="submitform()">Save & Move
            to Prescription</button>

        </div>
      </div>
    </div>
  </div>
</ng-template>


<ng-template #Billgenerate let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title " style="text-transform: capitalize;">Create Invoice</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true" style="font-size: 34px;">
          ×
          <!-- <img src="assets/images/close-btn.png" alt=""> -->
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">
        <div class="selectionTable" style="width: 90%; align-self: center; overflow-x:hidden;">

          <div class="row">
            <div class="col-12">
              <label for="" style="font-size: 18px;color: #454A51;" class="newInputStyleLabel">Patient
                Name : {{appointmentDataForPresc.comepleteData.Patient.User.first_name}}
                {{ appointmentDataForPresc.comepleteData.Patient.User.last_name}}</label>
              <div class="form-group  onFocus">
                <label [ngClass]="{'animatedLabel':firstName['first_name'] || editMode}" class="newInputStyleLabel">Bill
                  Amount (Rs.) <span class="required">*</span></label>
                <input [(ngModel)]="billAmount" type="text" class="form-control newInputBox"
                  (focus)="addLabelClass($event)" [textMask]="{mask: mask,guide:false}" placeholder="Enter bill amount"
                  style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                  (blur)="removeLabelClass($event)">

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="form-group  onFocus">
                <label class="newInputStyleLabel"
                  [ngClass]="{'animatedLabel':lastName['last_name'] || editMode}">Patient Email (Optional)</label>
                <input [(ngModel)]="patientEmail" type="text" class="form-control newInputBox"
                  placeholder="Enter patient email"
                  style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                  (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">

              </div>
            </div>
          </div>
        </div>
        <div class="row d-flex pt-3" style="display: flex; justify-content: flex-end;">
          <button class="newBtnStyle" (click)="submitBillGenerateForm(c)">Save & Send to Patient
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #todaysBday let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase" style="text-transform: capitalize;">Patient Info</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="model-content ">
        <div *ngIf="cases.length > 0" style="height: 400px; align-self: center; overflow-y:scroll;">
          <div class="row">
            <div class="row">
              <div class="col-12">
                <div class="form-group selectDropDown bloodGroup onFocus" style="margin-left: 10px;">
                  <label class="">Choose Visit<span class="required">*</span></label>
                  <select (change)="onChange($event.target.value)" class="infoLabel ng-pristine ng-valid ng-touched">
                    <option *ngFor="let caseVisit of cases; let i = index" [value]="i">
                      {{caseVisit.Visits[0] && caseVisit.Visits[0].createdAt | date: 'dd/MM/yyyy'}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="certificates topBottomBorder birthdaylist">
              <ul class="pl-0" *ngFor="let path of pathArray; let i = index">
                <li class="clearfix">
                  <div class="row" style="margin: 15px;">
                    <div class="form-group checkbox withLabel" style="width: 60%; margin-left: 3px;">
                      <label style="color: #333;padding:3px">
                        {{path.name}}
                      </label>
                    </div>
                    <div class="row d-flex" style="width: 36%;margin: 3px;">
                      <a style="font-size: 14px;height: 30px;color: #FFF;background-color: #FF8E14;padding: 5px; border-radius: 4px; margin-left: 10px;margin-right: 10px;text-transform: capitalize;"
                        (click)="goToLink(path,d)">Go</a>
                    </div>
                  </div>
                </li>
                <!--- <li class="clearfix">
                  <div class="form-group checkbox withLabel">
                    <label>
                      <input class="form-control filled-in" type="checkbox">
                      <span>Ganpati Venkatevshvar Ramanujan</span>
                    </label>
                  </div>
                </li>
                <li class="clearfix">
                  <div class="form-group checkbox withLabel">
                    <label>
                      <input  class="form-control filled-in" type="checkbox">
                      <span>Consult for Continuation</span>
                    </label>
                  </div>
                </li>
                <li class="clearfix">
                  <div class="form-group checkbox withLabel">
                    <label>
                      <input  class="form-control filled-in" type="checkbox">
                      <span>Vatsala Ganpati Ramanujan</span>
                    </label>
                  </div>
                </li>-->
              </ul>
            </div>
          </div>

        </div>
        <div *ngIf="!cases || cases.length == 0">
          <div class="row">
            <div class="form-group checkbox withLabel mb-2 selectAll">
              <label>
                <!--<input class="form-control filled-in" type="checkbox">-->
                <span>No patient selected</span>
              </label>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showPrescription_modal let-modal>


  <h4>Create Prescription</h4>
  <div style="color: grey; width: 50vh; line-height: 16px;">You are almost done! Before sharing Rx with your patient
    please specify
    the following fields.</div>
  <hr>


  <div style="display: flex;flex-direction: column;">
  

   


    <mat-radio-group aria-label="Select an option" style="margin: 0px 0px;" *ngFor="let item of createPescription;let i=index"  (click)="selectPrescriptionValue(i)">
      <div  [ngClass]="(item.selected) ?  'highlightedBoxPer' :'UnhighlightedBoxPer'" >
    
        <mat-radio-button value="true" [checked]="item.selected" >&nbsp;{{item.name}}</mat-radio-button><br><br>
      </div>

    <!-- <div style=" margin-bottom: 12px;
    padding-bottom: 0px;
    padding-top: 12px;
    padding-left: 12px;">
      <mat-radio-button value="2">&nbsp;Custom Prescription</mat-radio-button>
    </div> -->
   
    </mat-radio-group>
  
  <mat-form-field id="fourth" style="width: 100%; margin: 0px 6px;" *ngIf="customPerscription" >
                    <mat-label>Select Custom Prescription</mat-label>
                    <mat-select [value]="dose" (selectionChange)="onDropdownSelectFavMed(favArray,$event.value)">
                      <mat-option *ngFor="let item of favArray"  [value]="item">{{item.favApptName}}</mat-option>
                    </mat-select>
                  </mat-form-field>



  </div>

  <!-- <div style="color: red; padding-top: 16px;">Fields marked and highlighted are compulsory*</div> -->

  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
    <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 8px;
                color: #444;
                outline: none;">Cancel</button>

    <button (click)="openPrescriptions();closeDialog()" style="background-color:#3f51b5;
      padding: 8px 16px;
      border-radius: 6px;
      color: #fff;
      font-weight: bold;
      border: 0px;
      margin: 8px 0px;
    outline: none;">Select</button>

  </div>
</ng-template>

<p-toast position="top-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class=" p-flex p-flex-column"
      style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;top:67px ;">
      <div>
        <!-- <img (click)="goBackpage()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
                  src="assets/images/exclamation-circle-blue.svg" alt="" /> -->
      </div>
      <div class=" p-text-center">
        <div style="font-size: 18px;
                  font-weight: bold;
                  padding: 12px 0px 0px 12px;
                  
                  color: #034A70;">{{message.summary}}</div>
        <div style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
          {{message.detail}}</div>
      </div>
    </div>
  </ng-template>
</p-toast>