<!-- TABLE FOR PRODUCT's DISPLAY START HERE -->
<div style="display: flex; flex-direction: row;">
  <h3>Listed Products for Sell</h3>
  <button id="inventoryManagementButton" (click)="onClickActivateInventoryTab()">Inventory Management</button>
  <!-- <button id="orderButton" (click)="onClickOrders()">Orders</button> -->
</div>
<div style="height: 80vh; overflow-y: auto;">

<table id="products" mat-table [dataSource]="masterProductsVariations" style="width: 75%;">

  <!-- <ng-container matColumnDef="productId">
    <th mat-header-cell *matHeaderCellDef> Product Var Id </th>
    <td mat-cell *matCellDef="let product"> {{product.id}} </td>
  </ng-container> -->

  <ng-container matColumnDef="productName"> 
    <th mat-header-cell *matHeaderCellDef style="text-align: left;"> Product Name </th>
    <td mat-cell *matCellDef="let product" style="text-align: left;"> {{product.MasterProduct.productName}} </td>
  </ng-container>

  <ng-container matColumnDef="price">
    <th mat-header-cell *matHeaderCellDef> Price </th>
    <td mat-cell *matCellDef="let product"> &#8377;{{product.price}} </td>
  </ng-container>

  <ng-container matColumnDef="company">
    <th mat-header-cell *matHeaderCellDef> Company </th>
    <td mat-cell *matCellDef="let product"> {{product.MasterProduct.supplierId}} </td>
  </ng-container>

  <ng-container matColumnDef="measurement">
    <th mat-header-cell *matHeaderCellDef> Measurement </th>
    <td mat-cell *matCellDef="let product"> {{product.measurement}} {{product.unitOfMeasurement}}</td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef> Action </th>
    <!-- showProduct(product);  -->
    <td mat-cell *matCellDef="let product"> 
      <button 
        type="button" 
        (click)="openModal(product, mytemplate)" 
        [ngClass]="{'disabled-button': product.quantity <= 0}" 
        style="background-color: #6887C7;" 
        [disabled]="product.quantity <= 0">  
        <span style="color: #fff; font-size: 16px; font-weight: bold;">Add Product</span>
      </button> 
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

</table>
</div>

<ng-template #mytemplate style="width: 90%;">
  <mat-card class="product-details-card">
    <div style="display: flex;  justify-content: space-between; flex-direction: row;">
      <div style="width: 30%;">
        <h3 >Product Details</h3>
      </div>
      <div>
        <button mat-icon-button style="padding: 0;" (click)="closeDialog()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
    </div>

    <mat-card-content>
      <b>Product Var Id:</b> {{ selectedProduct.id }}
    </mat-card-content>
    <mat-card-content>
      <b>Product Name:</b> {{ selectedProduct.MasterProduct.productName }}
    </mat-card-content>
    <mat-card-content>
      <b>Product Description:</b> {{ selectedProduct.MasterProduct.productDescription }}
    </mat-card-content>
    <mat-card-content>
      <b>Price:</b> Rs. {{ selectedProduct.price }}
    </mat-card-content>
    <mat-card-content>
      <b>Measurement:</b> {{ selectedProduct.measurement }} {{ selectedProduct.unitOfMeasurement }}
    </mat-card-content>

    <!-- Quantity UI and Add to Cart button -->
    <div class="quantity-container">
      <!-- Quantity UI buttons -->
      <div style="width: 60%; display: flex; flex-direction: row; justify-content: space-evenly;">
        <div style=" display: flex; flex-direction: row; justify-content: center; ">
          <button id="subtraction" (click)="decrement();" style="background-color: #6887C7;">
            <mat-icon class="removeIcon">remove</mat-icon>
          </button>
        </div>

        <div style=" display: flex; flex-direction: row; justify-content: center;  align-items: center;">

          <input type="number" min="1" class="form-control" [(ngModel)]="quantity" style="width: 55px;" />
        </div>

        <div style=" display: flex; flex-direction: row; justify-content: center;  align-items: center;">

          <button id="addition" (click)="increment();"  style="background-color: #6887C7;">
            <mat-icon class="addIcon">add</mat-icon>
          </button>
        </div>
      </div>

      <!-- Add to Cart button -->
      <div class="add-to-cart-button" style="width: 40%; display: flex; justify-content: center;
      ">
        <button style="background-color: #6887C7;" (click)="addProductToLocalStorge(product)">Add to Cart</button>
      </div>
    </div>
  </mat-card>
</ng-template>