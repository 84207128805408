<div class="container-fluid basic-info-wrapper" style="margin: 12px;">
  <div class="row">

    <!--Your Practice section(start here) -->
    <div class="col-12 pl-1" *ngIf="yourPracticeSec">
      <h3 class="inner-header">let us know about your practice</h3>
      <form [formGroup]="signupForm">
        <div class="row">
          <div class="col-xl-6 col-lg-7">
            <div class="row">
              <div class="col-6">
                <div class="form-group animateLabel">
                  <label [ngClass]="{'animatedLabel':signupForm.controls['firstName'].value}"
                    class="newInputStyleLabel">Your First Name <span class="required">*</span></label>
                  <input type="text" class="form-control animateInput capitalize newInputBox"
                    formControlName="firstName" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)"
                    maxlength="25" onkeypress="return (event.charCode > 64 &&
  event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9)"
                    placeholder="Enter first name">

                  <p class="errors" *ngIf="signupForm.controls['firstName'].invalid && isSubmit">Please enter your first
                    name</p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group animateLabel">
                  <label [ngClass]="{'animatedLabel':signupForm.controls['lastName'].value}"
                    class="newInputStyleLabel">Last Name <span class="required">*</span></label>
                  <input type="text" class="form-control animateInput capitalize newInputBox" formControlName="lastName"
                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)" maxlength="25" onkeypress="return (event.charCode > 64 &&
  event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9)"
                    placeholder="Enter last name">

                  <p class="errors" *ngIf="signupForm.controls['lastName'].invalid && isSubmit">Please enter your last
                    name</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-5 ">
            <div class="row">
              <div class="col-6 col-md-8">
                <div class="row">
                  <div class="col-xl-8 col-lg-10">
                    <div class="form-group calendarIcon calender onFocus" style="margin-top: 20px !important;">
                      <label class="infoLabel newInputStyleLabel" id="dobLabel">Date of Birth <span
                          class="required">*</span></label>
                      <!--(document:click)="closeCalender($event, d)"-->
                      <input type="text" placeholder="DD/MM/YYYY" id="dobInput" class="form-control animateInput"
                        [maxDate]="date" [minDate]="minDate" formControlName="dob" ngbDatepicker #d="ngbDatepicker"
                        style="border-radius: 4px !important;" (focus)="d.open();"
                        (document:click)="closeCalender($event, d)" readonly>

                      <img _ngcontent-c3="" (click)="d.toggle()" class="calendarbg" alt=""
                        src="assets/images/calendarIcon.svg">
                      <p class="errors" *ngIf="signupForm.controls['dob'].invalid && isSubmit">Please select your date
                        of birth</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 ">
            <div class="form-group mb-3 onFocus">
              <label class="newInputStyleLabel">Gender <span class="required">*</span></label>
              <div class="form-check-inline">
                <label
                  class="container-radio {{signupForm.controls['gender'].value === 'male' ? 'active-gender' : '' }}"
                  style="margin: 6px 15px 5px 4px;
                  color: #333; padding-top: 2px; font-size: 16px">Male
                  <input class="custom-control-input ng-untouched ng-pristine ng-valid" name="gender"
                    formControlName="gender" value="male" type="radio" ng-reflect-name="primary_clinic_temp"
                    ng-reflect-value="0" ng-reflect-model="1">
                  <span class="checkmark-radio"></span>
                  <!-- <span class="custom-control-description">Male</span> -->
                </label>
              </div>
              <div class="form-check-inline">
                <label
                  class="container-radio {{signupForm.controls['gender'].value === 'female' ? 'active-gender' : '' }}"
                  style="margin: 6px 15px 5px 4px;
                  color: #333; padding-top: 2px; font-size: 16px">Female
                  <input class="custom-control-input ng-untouched ng-pristine ng-valid" name="gender"
                    formControlName="gender" value="female" type="radio" ng-reflect-name="primary_clinic_temp"
                    ng-reflect-value="0" ng-reflect-model="1">
                  <span class="checkmark-radio"></span>
                  <!-- <span class="custom-control-description">Female</span> -->
                </label>
              </div>
              <div class="form-check-inline">
                <label
                  class="container-radio {{signupForm.controls['gender'].value === 'do_not_disclose' ? 'active-gender' : '' }}"
                  style="margin: 6px 15px 5px 4px;
                  color: #333; padding-top: 2px; font-size: 16px">Non-speccified
                  <input class="custom-control-input ng-untouched ng-pristine ng-valid" name="gender"
                    formControlName="gender" value="do_not_disclose" type="radio" ng-reflect-name="primary_clinic_temp"
                    ng-reflect-value="0" ng-reflect-model="1">
                  <span class="checkmark-radio"></span>
                  <!-- <span class="custom-control-description">Non-specified</span> -->
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-7">
            <div class="row">
              <div class="col-6">
                <div class="form-group animateLabel">
                  <label [ngClass]="{'animatedLabel':signupForm.controls['email'].value}"
                    class="newInputStyleLabel">Your Office Email <span class="required">*</span></label>
                  <input type="text" class="form-control newInputBox" formControlName="email"
                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">

                  <p class="errors" *ngIf="signupForm.controls['email'].invalid && isSubmit">Please enter valid email id
                  </p>
                </div>
              </div>
              <div class="col-6" style="margin-top: 18px;">
                <!-- <div class="form-group animateLabel">
                  <input type="text" [textMask]="{mask: mask,guide:false}"  class="form-control animateInput" formControlName="phoneNumber" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)"  maxlength="12">
                  <label [ngClass]="{'animatedLabel':signupForm.controls['phoneNumber'].value}">Your Mobile Number</label>
                  <p class="errors" *ngIf="signupForm.controls['phoneNumber'].invalid && !signupForm.controls['phoneNumber'].value && isSubmit">Please enter valid phone number</p>
                  <p class="errors" *ngIf="signupForm.controls['phoneNumber'].invalid && signupForm.controls['phoneNumber'].value && signupForm.controls['phoneNumber'].value.length < 10 && isSubmit">Please enter valid phone number</p>
                  <p class="errors" *ngIf="signupForm.controls['phoneNumber'].invalid && signupForm.controls['phoneNumber'].value && signupForm.controls['phoneNumber'].value.length == 10 && isSubmit">Please enter country code</p>
                </div> -->
                <div class="form-group mobNumber clearfix">
                  <label class="newInputStyleLabel"
                    [ngClass]="{'animatedLabel':signupForm.controls['phoneNumber'].value}">Your Mobile Number
                    <span class="required">*</span></label>

                  <div class="stdCode" style="padding-top: 2px !important;">
                    <span class="stdCodeInputSign">+</span>
                    <input class="stdCodeInput form-control newInputBox" type="text" maxlength="2"
                      (keypress)="validateNumber($event)" [(ngModel)]="stdCode" name="stdCode"
                      [ngModelOptions]="{standalone: true}">

                  </div>
                  <div class="animateLabel" style="padding-top: 2px !important;">

                    <input type="text" [textMask]="{mask: mask,guide:false}" class="form-control newInputBox"
                      formControlName="phoneNumber" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)"
                      maxlength="10">

                    <p class="errors"
                      *ngIf="signupForm.controls['phoneNumber'].invalid && !signupForm.controls['phoneNumber'].value && isSubmit">
                      Please enter valid phone number</p>
                    <p class="errors"
                      *ngIf="signupForm.controls['phoneNumber'].invalid && signupForm.controls['phoneNumber'].value && signupForm.controls['phoneNumber'].value.length < 10 && isSubmit">
                      Please enter valid phone number</p>
                    <p class="errors" *ngIf="!stdCode && isSubmit && signupForm.controls['phoneNumber'].valid">Please
                      enter valid country code.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-6 col-lg-5">
            <div class="row" style="margin-top: 17px;">
              <div class="col-xl-6 col-lg-8">
                <div class="form-group selectDropDown onFocus">
                  <label class="newInputStyleLabel">Degree <span class="required">*</span></label>
                  <select [ngClass]="{'infoLabel': !signupForm.controls['degree'].value}"
                    class="form-control newInputBox" formControlName="degree" style="padding: 8px;">
                    <option disabled value="null">Select</option>
                    <option *ngFor="let degree of degreeArray; let i = index" value="{{i}}">{{degree}}</option>
                  </select>
                  <p class="errors" *ngIf="signupForm.controls['degree'].value == null && isSubmit">Please select your
                    degree</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-2 pb-1">
          <div class="col-xl-6 col-lg-7">
            <div class="row">
              <div class="col-6">
                <div class="form-group animateLabel">
                  <label [ngClass]="{'animatedLabel':signupForm.controls['password'].value}"
                    class="newInputStyleLabel">Enter Password <span class="required">*</span></label>
                  <input (keydown.space)="$event.preventDefault()" type="password" class="form-control  newInputBox"
                    formControlName="password" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">

                  <p class="errors" *ngIf="signupForm.controls['password'].invalid && isSubmit">Password must be minimum
                    of 6 characters</p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group animateLabel">
                  <label [ngClass]="{'animatedLabel':signupForm.controls['confirmPassword'].value}"
                    class="newInputStyleLabel">Confirm Password
                    <span class="required">*</span></label>
                  <input (keydown.space)="$event.preventDefault()" type="password" class="form-control newInputBox"
                    formControlName="confirmPassword" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">

                  <p class="errors"
                    *ngIf="( signupForm.controls['confirmPassword'].invalid || signupForm.controls['password'].value !== signupForm.controls['confirmPassword'].value) && isSubmit">
                    Password does not match</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-7 col-xl-6">
            <div class="row">
              <div class="col-12">
                <div class="form-group mb-0">
                  <label class="newInputStyleLabel">Your Name as Registered with:</label>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-xl-6 col-lg-6">
                <div class="form-group animateLabel">
                  <label [ngClass]="{'animatedLabel':signupForm.controls['registeredName'].value}"
                    class="newInputStyleLabel">Registered Name <span class="required">*</span></label>
                  <input type="text" formControlName="registeredName" class="form-control newInputBox capitalize"
                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)"
                    onkeypress="return (event.charCode > 64 &&
                  event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.charCode == 32) || (event.which == 8) || (event.keyCode  == 9)" maxlength="50">

                  <p class="errors" *ngIf="signupForm.controls['registeredName'].invalid && isSubmit">Please enter your
                    registered name</p>
                </div>
              </div>
              <div class="col-6" style="margin-top: 17px;">
                <div class="form-group selectDropDown onFocus">
                  <label class="newInputStyleLabel">State Medical Council <span class="required">*</span></label>
                  <select [ngClass]="{'infoLabel': !signupForm.controls['registeredCouncil'].value}"
                    class="form-control newInputBox" formControlName="registeredCouncil" style="padding: 8px;">
                    <option disabled value="null">Select</option>
                    <option *ngFor="let council of councilsArray; let i = index" value="{{i}}">{{council}}</option>
                  </select>
                  <p class="errors" *ngIf="signupForm.controls['registeredCouncil'].value == null && isSubmit">Please
                    select your state medical council</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-5 col-xl-6" style="margin-top: 17px;">
            <div _ngcontent-c5="" class="row">
              <div _ngcontent-c5="" class="col-lg-4 col-md-3">
                <div _ngcontent-c5="" class="form-group selectDropDown yearDropDown onFocus">
                  <label _ngcontent-c5="" class="ng-tns-c5-0" class="newInputStyleLabel">Year <span
                      class="required">*</span></label>
                  <select [ngClass]="{'infoLabel': !signupForm.controls['registeredYear'].value}"
                    class="form-control newInputBox" formControlName="registeredYear" style="padding: 8px;">
                    <option disabled value="null">Select</option>
                    <option *ngFor="let year of yearsArray; let i = index" value="{{year}}">{{year}}</option>
                  </select>
                  <p class="errors" *ngIf="signupForm.controls['registeredYear'].value == null && isSubmit">Please
                    select your state medical council</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-7">
            <div class="row">
              <div class="col-6">
                <div class="form-group animateLabel">
                  <label [ngClass]="{'animatedLabel':signupForm.controls['registrationNumber'].value}"
                    class="newInputStyleLabel">Registration
                    Number <span class="required">*</span></label>
                  <input type="text" [textMask]="{mask: registrationMask,guide:false}" class="form-control newInputBox"
                    formControlName="registrationNumber" (focus)="addLabelClass($event)"
                    (blur)="removeLabelClass($event)">

                  <p class="errors errorswithDubleLine"
                    *ngIf="signupForm.controls['registrationNumber'].invalid && isSubmit">Registration number must be
                    minimum of 5 digits and maximum of 6 digits</p>
                </div>
              </div>
              <div class="col-5">
                <div class="form-group calendarIcon calender onFocus" style="margin-top: 20px !important;">
                  <label id="validUptoLabel" class="infoLabel newInputStyleLabel">Valid Upto <span
                      class="required">*</span></label>
                  <input placeholder="DD/MM/YYYY" id="validUptoInput" type="text" class="form-control"
                    formControlName="registrationValidity" ngbDatepicker #d1="ngbDatepicker" (focus)="d1.open();"
                    (document:click)="closeCalender($event, d1); $event.stopPropagation();" [maxDate]="futuredate"
                    style="border-radius: 4px !important;" readonly>
                  <!-- <ngb-datepicker [displayMonths]="displayMonths" [navigation]="navigation"></ngb-datepicker> -->
                  <img (click)="d1.toggle()" _ngcontent-c3="" class="calendarbg" alt=""
                    src="assets/images/calendarIcon.svg">
                  <p class="errors" *ngIf="signupForm.controls['registrationValidity'].value == null && isSubmit">Please
                    select expiry date</p>
                </div>
              </div>

            </div>
          </div>
          <div class="col-6">
            <div class="row">

            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-xl-6 col-lg-7">
            <div class="row">
              <div class="col-6">
                <div class="form-group animateLabel onFocus">
                  <label [ngClass]="{'animatedLabel':signupForm.controls['speciality'].value}"
                    class="newInputStyleLabel">Your Speciality <span class="required">*</span></label>
                  <!--<input type="text" class="form-control animateInput" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">-->
                  <p-autoComplete class="autocomplete newInputBox" formControlName="speciality"
                    (onFocus)="addLabelAutoComplete($event)" (onBlur)="removeLabelAutoComplete($event)"
                    (keyup)="removeLabelAutoComplete($event)" field="speciality" [suggestions]="specialitiesArray"
                    (completeMethod)="searchSpecialities($event)" (onClear)="clearSubSpeciality(); "
                    (onSelect)="getSubSpecialityList(); clearSubSpeciality(); " forceSelection=true>
                  </p-autoComplete>

                  <p class="errors" *ngIf="signupForm.controls['speciality'].invalid && isSubmit">Please select your
                    speciality</p>
                </div>
              </div>
              <div class="col-6">
                <div class="form-group animateLabel onFocus">
                  <!--<input type="text" class="form-control animateInput" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">-->
                  <label [ngClass]="{'animatedLabel':signupForm.controls['subSpeciality'].value}"
                    class="newInputStyleLabel">Your Sub-Speciality
                    <span class="required">*</span></label>
                  <p-autoComplete class="autocomplete animateInput" formControlName="subSpeciality"
                    (onFocus)="addLabelAutoComplete($event)" (onBlur)="removeLabelAutoComplete($event)"
                    (keyup)="removeLabelAutoComplete($event)" [suggestions]="subSpecialitiesArray"
                    (completeMethod)="searchSubspecialities($event)" forceSelection=true>
                  </p-autoComplete>

                  <p class="errors" *ngIf="signupForm.controls['subSpeciality'].invalid && isSubmit">Please select your
                    sub-speciality</p>
                </div>
              </div>
              <div class="col-12 ">
                <div class="form-group mb-3 onFocus">
                  <label class="newInputStyleLabel">Your Development Milestone Reference Type <span
                      class="required">*</span></label>
                  <div class="form-check-inline">
                    <label class="container-radio" style="margin: 6px 15px 5px 4px;
                    color: #333; padding-top: 2px; font-size: 16px">International
                      <input class="custom-control-input ng-untouched ng-pristine ng-valid"
                        formControlName="milestone_type" type="radio" [value]="2" name="milestone_type">
                      <span class="checkmark-radio"></span>
                      <!-- <span class="custom-control-description">International</span> -->
                    </label>
                  </div>
                  <div class="form-check-inline">
                    <label class="container-radio" style="margin: 6px 15px 5px 4px;
                    color: #333; padding-top: 2px; font-size: 16px">CDC Kerala
                      <input class="custom-control-input ng-untouched ng-pristine ng-valid"
                        formControlName="milestone_type" type="radio" [value]="1" name="milestone_type">
                      <span class="checkmark-radio"></span>
                      <!-- <span class="custom-control-description">CDC Kerala</span> -->
                    </label>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group checkbox withLabel">
              <label>
                <input class="filled-in mr-1" type="checkbox" [checked]="acceptPrivacy"
                  (change)="acceptPrivacy = !acceptPrivacy">
                <span>By proceeding you agree with OPD Clinic’s Terms of Use and acknowledge that you have read the
                  privacy policy.</span>
              </label>

              <p class="errors" *ngIf="!acceptPrivacy && isSubmit">Please accept Terms of Use</p>
            </div>
            <!-- <span><input type="checkbox"/></span><p class="terms-line">By proceeding you agree with OPD Clinic’s Terms of Use and acknowledge that you have read the privacy policy.</p> -->
          </div>
        </div>
        <div class="row">
          <div class="col-12 footer-part">

            <button type="button" class="newBtnStyle" (click)="submit();">Submit</button>
            <button type="button" class="newBtnStyle" (click)="clearForm()"
              style="text-transform: none; background-color: #999 !important; border: 1px solid #999 !important">Clear
              Form</button>
            <!--<a [routerLink]="" class="confirmOTP">Confirm OTP <span><i _ngcontent-c19="" class="fa fa-angle-right"></i></span></a>-->
          </div>
        </div>
      </form>
    </div>

    <!--confirm info section(start here) -->
    <div class="col-12 pl-1" *ngIf="confirmInfoSec">
      <h3 class="inner-header">Confirm your information</h3>
      <form>

        <div class="col-12">
          <div class="optHolder">
            <div class="form-group mb-4 onFocus">
              <label>Enter OTP</label>
              <div class="row">

                <div class="col-12">
                  <input type="text" class="form-control otpInputField" maxlength="6" [(ngModel)]="otp" name="otp"
                    id="otp" (keypress)="validateNumber($event)">
                  <!--placeholder="* * * * * *"
                  onfocus="if(placeholder=='* * * * * *'){placeholder=''}"
                  onblur="if(placeholder==''){placeholder='* * * * * *'}"-->
                </div>

              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <p class="terms-line"><a class="req-otp not-active" id="resend-otp" (click)="reqOtp()">Resend OTP?</a> if
                you don't receive it in 2 mins.
                <!-- <p class="terms-line">Please wait for couple of minutes to receive the OTP. If you do not receive OTP,
              <a class="req-otp" (click)="reqOtp()">Request OTP</a>
            </p> -->
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-3">
              <button type="submit" class="btn-primary opd-primary-btn btn-md" (click)="confirmOTP()">Submit</button>
              <button type="button" class="btn-primary opd-secondary-btn btn-md ml-2" (click)="goBackBtn();">Go
                back</button>
            </div>
          </div>
        </div>
      </form>
    </div>
    <!--Basic info thank you section(start here) -->
    <div class="col-12" *ngIf="thankYouSection">
      <div class="thankYouSection">
        <div class="successOuer">
          <div class="success">
            <span></span>
          </div>
        </div>
        <h2 class="inner-header">Confirmation</h2>
        <p>Medical Council confirmed the registration No. {{signupForm.controls['registrationNumber'].value}}<br /> of
          {{signupForm.controls['registrationValidity'].value.year}} in the name of <b>Dr.
            {{this.capitalize(this.signupForm.controls['firstName'].value)}}
            {{this.capitalize(this.signupForm.controls['lastName'].value)}}.</b></p>
        <p>Your registration number with Copious Health-Care is <b>CHC-023423,</b> in
          {{this.signupForm.controls['speciality'].value.speciality}}.</p>
        <p>Kids are waiting for you to take care of them </p>
        <p><b>COPIOUS HEALTH-CARE</b></p>
      </div>
      <div class="row">
        <div class="col-12 mt-3">
          <a class="btn-primary opd-primary-btn btn-md" routerLink="/doctor/appointments">View Dashboard</a>
          <a class="btn-primary opd-secondary-btn btn-md ml-2" routerLink="/doctor/profileSetup/stepOne">Complete
            Profile</a>
        </div>
      </div>
    </div>

    <!--info not verified section(start here) -->
    <div class="col-12" *ngIf="infoNotVerified">
      <h3 class="headingUpprcase18">information is not verified</h3>
      <form>
        <div class="row">
          <div class="col-12">
            <p class="refile-info">
              Refill the information if the details are still not matching.
              <br> On confirmation we will get back to you.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <button type="button" class="btn-primary opd-primary-btn btn-md" (click)="backToForm();">Back to
              form</button>
            <a routerLink="/" type="button" class="btn-primary opd-secondary-btn btn-md ml-2">Cancel</a>
          </div>
        </div>
      </form>
    </div>

    <!--if doctor select speciality other than pediatrics OR doctor not onboarded screen(start here) -->
    <div class="col-12 bl_text" *ngIf="notOnboarded">
      <h3 class="headingUpprcase18">Thank you for taking 'care'</h3>
      <form>
        <div class="row">
          <div class="col-12">
            <p class="refile-info">
              At precent we are developing on Medicine Care.<br>
              Very soon we will inform you about release date.<br>
              In case you are interested in development / or <br>
              as a domain expert, please accept this invitation.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <button type="button" class="btn-primary opd-primary-btn btn-md"
              (click)="interestPage = true; notOnboarded = false; otherDocthankYou = false;">YES</button>
            <a type="button" class="btn-primary opd-secondary-btn btn-md ml-2"
              (click)="otherDocthankYou = true; notOnboarded = false;">NO</a>
          </div>
          <div class="col-12 mt-3">
            <p class="refile-info">
              We care for your advice and domain expertise !!
            </p>
          </div>
        </div>
      </form>
    </div>

    <!-- thank you page other than pediatric doctor page(start here)-->
    <div class="col-12" *ngIf="otherDocthankYou">
      <form>
        <div class="row">
          <div class="col-12">
            <p class="refile-info">
              Thank you, Dr. {{this.capitalize(this.signupForm.controls['firstName'].value)}}
              {{this.capitalize(this.signupForm.controls['lastName'].value)}}, for joining us!!<br>
              We will get back to you sooner!!
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <button type="button" class="btn-primary opd-primary-btn btn-md" routerLink="/">CLOSE</button>
          </div>
        </div>
      </form>
    </div>

    <!-- Interest page(start here)-->
    <div class="col-12" *ngIf="interestPage">
      <form>
        <div class="row">
          <div class="col-12">
            <p class="refile-info">
              Thank you, Our technical team will 'care' to get in touch with you.

            <p class="refile-info">
              Thank you once again for your interest in development of software.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <button routerLink="/" type="button" class="btn-primary opd-primary-btn btn-md">CLOSE</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>