import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable, Injector } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Router } from "@angular/router";

import { AuthService } from "../app/services/auth.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private router: Router, private injector: Injector) { }

    intercept(req: HttpRequest<any>, next: HttpHandler) {
        const authService = this.injector.get(AuthService);
        let clonedreq = null
        if (authService.getAdminToken()) {
            clonedreq = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + authService.getAdminToken())
            });

        }
        else if (authService.getToken()) {
            clonedreq = req.clone({
                headers: req.headers.set("Authorization", "Bearer " + authService.getToken())
            });
        }
        else {
            return next.handle(req.clone());
        }


        return next.handle(clonedreq).pipe(
            tap(
                event => { },
                err => {
                    if (err.error.auth == false) {
                        authService.deleteToken(1)
                        localStorage.removeItem('lock')
                        this.router.navigate(['/login']);

                    }
                })
        );
        // }
    }
}
