import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PatientService } from '../../services/patient.service';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, map, filter, pairwise, startWith } from 'rxjs/operators';
import { AuthService } from '../../services/auth.service';
import { MatSelect } from '@angular/material/select';
import { LanguageSelectTextboxComponent } from '../../components/language-select-textbox/language-select-textbox.component';
import { DoctorService } from '../../services/doctor.service';
let drugTypeSelection = ["Tablet","Syrup", "Liquid","Suspension","Drop","Capsule","Cream","Gel", "Jelly", "Lotion", "Oil", "Ointment","Powder" , "Sachet", "Granules", "Inhaler", "Spray", "Respule" , "Injection", "Vial", "Soap","Suppository"  ];
let frequencyWithVal = [{ name: "Morning", value: '' }, { name: "Afternoon", value: '' }, { name: "Evening", value: '' }, { name: "Night", value: '' }, { name: "Once a week", value: '' }, { name: "Once a month", value: '' }, { name: "Anytime", value: '' }, { name: "If Required", value: '' }];
let whenToTake = ["-", "Before food", "After food", "With food", "With Milk", "With fruit juice", "At bed time", "Early morning", "Empty stomach", "If required","As needed"];
let frequency = ["-", "Morning", "Afternoon", "Evening", "Night", "Once a week", "Once a month", "Anytime", "If Required"];
let drugUnitTypes = ["Unit", "Fingertip", "Drop", "ml", "Puff", "Sachet", "Scoop","Bottle","Strip","Spoon", "Device"]
let duration = ['-', '1 Day(s)', '2 Day(s)', '3 Day(s)', '4 Day(s)', '5 Day(s)', '6 Day(s)', '7 Day(s)', '8 Day(s)', '9 Day(s)', '10 Day(s)', '11 Day(s)', '12 Day(s)', '13 Day(s)', '14 Day(s)', '15 Day(s)', '1 Week(s)', '2 Week(s)', '3 Week(s)', '1 Month', '2 Months', '3 Months', 'Until Finished',"Until cured"];
let printingFieldsArray = ["showVitals", "showComplaints", "showOnExamination", "showDiagnosis", "showPrescription", "showGeneralIntructions", "showDiet", "showNextFollowup"]
@Component({
  selector: 'app-favourite-management',
  templateUrl: './favourite-management.component.html',
  styleUrls: ['./favourite-management.component.scss'],
  providers: [MessageService]
})

export class FavouriteManagementComponent implements OnInit {
  @ViewChild("lngMedInstr") lngMedInstr: LanguageSelectTextboxComponent;
  @ViewChild('select') select: MatSelect;
  @ViewChild('add_new_drug_modal')add_new_drug_modal
  @ViewChild('incomplete_data_modal') incomplete_data_modal
  showPlaceholder:boolean=false
  editTextBox:boolean=false
  editMode:boolean=true
  ageGroup=["0 to 1","1 to 2"];
  ageGroupName:any=""
  showAddButton:boolean=false
  showAddMedicineBox:boolean=false
  showOnlyFavourite:boolean=false
  showDeleteButton:boolean=false
  drugNameAddNewDrugs: string = '';
  drugTypeAddNewDrugs: string = '';
  drugCompanyAddNewDrugs: string = '';
  genericDrugNameAddNewDrugs: string = '';
  instructionsForNewMed: any = '';
  allMedicineInstructions:any=[]
  allMedicineInstructionsOg:any=[]
  drugTypes: Observable<string[]>;
  patientSelectedLanguage: any = ""
  drugTypesOg: any = [];
  exitsAppointmentId=null
  medicineLoader=false
  selectedFavName:any=""
  Tounit = "Unit"
  toFrequency = "Frequency";
  Duration = "Duration";
  Whentotake = "When to take";
  drugType: any = 'Drug Type';
  drugMolecularFlag:any=""
  searchFavouriteMed:any=""
  frequencyWithVal = frequencyWithVal;
  frequency=frequency
  drugUnitTypes = drugUnitTypes;
  duration = duration;
  whenToTake = whenToTake;
  drugTypeSelection = drugTypeSelection;  
  searchedMeds:any=[]
  medicineName: any = {};
  doctorObj:any={}
  selectedInstructions:any=""
  UnitField: any = new FormControl('', [Validators.required]);
  FrequencyField: any = new FormControl('', [Validators.required]);
  FrequencyFieldNewMed: any = new FormControl('', [Validators.required]);
  DurationFieldNewMed: any = new FormControl('', [Validators.required]);
  WhentotakeFieldNewMed: any = new FormControl('', [Validators.required]);
  

  FrequencyFieldEdit: any = new FormControl('', [Validators.required]);
  DurationField: any = new FormControl('', [Validators.required]);
  WhentotakeField: any = new FormControl('', [Validators.required]);
  DrugUnitField: any = new FormControl('', [Validators.required])
  drugTypeField: any = new FormControl('', [Validators.required]);
  searchTextField: any = new FormControl('', [Validators.required]);
  selectedFavMed:any=[]
  allMedicines:any=[]
  patientApptId:any=null
  isAddMedicine = true; //commenting here
  searchText = "Start typing a drug name"
  unselectedDrugName=false
  doseCount:any= [{ unit: "", drugType: "", frequency: "", duration: "", whenToTake: "" }];
  
  favArray:any=[]
  favArrayOrg:any=[]
  selectedPreviousFavMed:any=[]
  favMedicineArray:any[]
  currentFavMedObj:any={}
  myControlFav = new FormControl();
  myControl = new FormControl();
  favArrayNames:any=""
  printingFields = printingFieldsArray;
  drugTypeUnit:any=""
  selectedDrugType:any = ""
  selectedDrugUnit = ""
  selectedDuration=""
  templateName = 'default';
  frequencyCollapsibleVariable = 4;
  selectedUnit
  searchedMedicineObj: any = {};
  drugUnit
  units
  selectedWhenToTake
  selectedFrequency:any=""
  unitForNewMed: any = '';
  frequencyForNewMed: any = '';
  frequencyForEditMedicine: any = [];
  durationForNewMed: any = '';
  whenToTakeForNewMed: any = '';
  userID=null
  appintmentId=null
  offset: number = 0
  nextOffset = 0
  pageSize: number
  patientId=null
  patientObj:any={}
  recentlyAuthorizedPatients:any=[]
  selectedIndex: any = null;
  constructor(private messageService: MessageService,private doctorService: DoctorService,public dialog: MatDialog,private patientService: PatientService, private authService: AuthService,) {
    this.userID = this.authService.getUser();
   }

  ngOnInit(): void {
    this.showPlaceholder=true
    this.getPatients();
    this.getDoctorDetails();
    this.getFavApptsOfDoctor();
    setTimeout(() => {
      this.getAllRequiredConstants();
    }, 500)
  
  }
  changeFavName(){
    this.editMode=true
    this.editTextBox=true
  }
  getDoctorDetails(){
    let params = {
      user_id: this.userID
    }
    this.doctorService.getDetails(params).subscribe(response => {
      this.doctorObj = response.doctor

      if (response.success) {
        if (response.doctor.services && response.doctor.services !== null) {
          var services = JSON.parse(response.doctor.services);
          if (services.template_names) {
            this.templateName=services.template_names[0]
          }
        }
      }
    })

  }
  getPatients(){
    let params = {
      user_id: this.userID,
      offset: this.offset,
      limit: this.pageSize
    }
    this.patientService.getAuthorizedPatients(params).subscribe(response => {
      // this.showLoading = false
      if (response.success) {
        if (response.next_offset != 0) {
          console.log(response.patients)
          this.recentlyAuthorizedPatients = response.patients;
          this.recentlyAuthorizedPatients.map(ins=>{
            if(ins.Patient && ins.Patient.User!=null){
              if(ins.Patient.User.first_name=="favourite" && ins.Patient.User.last_name=="creation"){
                this.patientId=ins.PatientId
                this.patientObj=ins
              }
            }
            
          })
        }
      }
    })
    console.log(this.patientId)
  }
  onDropdownSelectAge(element){
    this.selectedFavName=this.selectedFavName.replace(/ *\([^)]*\) */g, "");
    this.selectedFavName=this.selectedFavName+" "+"("+element+")";

  }
  previewPrescriptionPDF(){
    this.editMode=false
    if(this.selectedFavName ==""){
      
      console.log("Please Enter Favourite Name ");
      
      this.messageService.add({ severity: 'custom', detail: 'Please Enter Favourite Name'});

    }else{

   
    let obj: any = {};
    var patient: any = {};
     var exitsAppointmentId=null
     if(this.exitsAppointmentId){
      exitsAppointmentId=this.exitsAppointmentId
     }
     else if(this.patientApptId){
      exitsAppointmentId=null
     }
   var  age=  {years: 0, months: 1, days: 19}

    patient.first_name = this.patientObj.Patient.User.first_name;
    patient.last_name =  this.patientObj.Patient.User.last_name;
    patient.gender =  this.patientObj.Patient.User.gender;
    patient.phone_number =  this.patientObj.Patient.User.phone_number;

    patient.age = age;
    patient.weight = 'N/A';
    patient.height = 'N/A';



    obj = {
      "visit_id": null,
      "clinic_id": null,
      "read_only": false,
      "date": null,
      "appointmentId": null,
      "exitsAppointmentId":exitsAppointmentId,
      // "visit_id": this.appointmentData.VisitId,
      // "date": this.appointmentData.visit_date,
      "action": "share",
      "patientId": this.patientId,
      "patient": patient,

      "medicines": this.selectedFavMed, //pending
      "general_instruction": "",
      "covidQues": "",
      "suggested_investigation": '',
      "chief_complaints": "",
      "next_vaccination": "",
      "expirationDate": '',
      "on_examination": "",
      "complaint_diagnosis": "",
      "savePrescriptionParams": {},
      //  "printingFields":this.printingFields,
      'newVersion': true,
      "medicinePreview": '',//pending
      "language": 'en',
      "nutrition_diet_advice":"",
      "templateName": this.templateName,
      "favApptName": this.selectedFavName,
      "newVersionValue": 'a',
    }
    this.selectedFavMed.map(ins => {
      if (ins.dosage2) {

        ins.dosage2 = ins.dosage2.split(",").join(" - ");
      }

    })


    let savePrescriptionParams = {
      visit: {
        complaint_diagnosis: obj.complaint_diagnosis,
        general_instruction: obj.general_instruction,
        chief_complaints: obj.chief_complaints,
        next_vaccination: obj.next_vaccination,
        complaint_examination: obj.on_examination,
        suggested_investigation: obj.suggested_investigation,

        covidQues: null,
        expirationDate: obj.expirationDate,
        nutrition_diet_advice: obj.nutrition_diet_advice,
      },
      patient_id: obj.patientId,
      // visit_id: obj.visit_id,
      medicine_list: this.selectedFavMed,
    }
    obj.savePrescriptionParams = savePrescriptionParams;

    obj.printingFields = this.printingFields;



    this.patientService.printPrescriptionVersion2(obj).subscribe(response => {
      if (response) {
        

        console.log('PRESCRIPTION PDF Version 1', response)
        let url = response.url;
        //window.open(url);
       // this.showLoading = false;
       if(this.patientApptId){
        this.onClickDeleteFavMed();
       }
       else{
        this.getFavApptsOfDoctor();
       }
      // this.patientApptId=null
    
   
       this.messageService.add({ severity: 'custom', detail: 'Favourite Medicines saved  successfully' });
     //  this.editMode=true
      // this.showOnlyFavourite=true
      this.selectedFavMed=this.selectedFavMed
      this.showDeleteButton=true
   

        sessionStorage.setItem("currentPreviewPrescriptionObject", JSON.stringify(obj));
      }
    })
  }
  // 
  }


  private _filterFavourites(value: any): string[] {
    console.log('Value', value)

    let filterValue = ''

    if (typeof value === 'string' || value instanceof String) {
      filterValue = value.toLowerCase();
    } else {
      filterValue = value.value.toLowerCase();
    }


    return this.favArray.filter(option => option.value.toLowerCase().includes(filterValue) || option.selectedDiagnosis.toLowerCase().includes(filterValue));
  }
  addMedicine(){
    this.showAddMedicineBox=true
  }

  getFavApptsOfDoctor = () => {
    // this.showRequisitionLoading = true;
    this.patientService.getFavApptsOfDoctor().subscribe(response => {
      if (response) {
        // this.currentFavMedObj=response;
        this.favArray = [];
       // this.selectedFavMed = [];
        this.favMedicineArray = response

        //let myid=this.currentFavMedObj.id;
        this.currentFavMedObj = response.length ? response[0] : {};
        console.log("favappt1", this.currentFavMedObj);
        console.log("favappt", response)
        localStorage.setItem("doctorsFavTemplate", this.currentFavMedObj);

        // this.showRequisitionLoading = false;
        for (let ins = 0; ins < response.length; ins++) {

          const element = response[ins];
          var obj: any = {}
        

          obj.Medicines = element.Visit && element.Visit != null ? element.Visit.Medicines && element.Visit.Medicines.length > 0 ? element.Visit.Medicines : null : null;
          if (element.Visit && element.Visit != null) {
            var med: any = {};
            med.id = element.id;
            med.PatientId=element.Visit.patientId
            med.value = element.favApptName;
            med.selected=false
            med.medicine = element.Visit.Medicines;
            med.selectedComplaints = element.Visit.chief_complaints ? element.Visit.chief_complaints : '';
            med.selectedDiagnosis = element.Visit.complaint_diagnosis ? element.Visit.complaint_diagnosis : '';
       
            this.favArray.push(med);
          }


          console.log('FAV-ARRAY', this.favArray)



        }
    
     
  

        this.favArrayNames = this.favArray.map(ins => { return ins.value });
        console.log("favMarch1", this.favArrayNames)




        console.log("favArrar", this.favArrayNames);
      

        console.log("sele", this.selectedFavMed)
        this.favArrayOrg=this.favArray;

        this.favArray.map(ins=>{

          if(ins.id==this.appintmentId){
          ins.selected=true
       
          }
        

        })
        if(this.patientApptId){
          this.favArray.map(ins=>{
            if(ins.value==this.selectedFavName){
              ins.selected=true
              this.selectedFavMed.push(ins)
            }
          })
          console.log("selectedFavName",this.selectedFavName);
          
         // var favArry=this.favArray[0];
         
        }
        this.patientApptId=null;
         
        console.log(this.selectedFavMed)



        // this.selectedFavMed.map(ins => {

        //   console.log("logggg", ins.value)
        //   if (ins.Drug) {
        //     ins.dosage2 = ins.duration2 ? ins.dosage2.replace('--', `- ${ins.duration2} -`) : ins.dosage2;
        //     ins.medicineName = ins.Drug.product_name;
        //   }
        // })


      }
    
    })
  
  }
  handleClick(item,index){
   this.appintmentId=null;
    this.showPlaceholder=false
    this.editTextBox=false
    this.showAddButton=true
    this.exitsAppointmentId=null
    this.showAddMedicineBox=false
    this.showDeleteButton=true
    this.editMode=true
    this.showOnlyFavourite=true
    if(item.PatientId==this.patientId){
      this.showOnlyFavourite=true
      this.exitsAppointmentId=item.id
      this.appintmentId=item.id
    
    
    }
    else{
      this.patientApptId=item.id
      this.appintmentId=item.id
    }
  

    this.favArray.map(ins=>{
      if(ins.selected){
        ins.selected=false
      }
    })
    this.selectedFavMed = item.medicine;
    this.selectedFavName=item.value
    this.currentFavMedObj=item
    console.log(item)
    if(this.favArray[index].selected==true){

    }
    else{
      this.favArray[index].selected=true
    }
   
  }

  closeAddTolistPop(){
    this.showAddMedicineBox=false
  }

  dropdownSelect(key, value) {
    this.drugType = value

    console.log('key', value);
    switch (key) {

      case 'unitNewMed':
        this.unitForNewMed = value;
        break;

      case 'frequencyNewMed':
        this.frequencyForNewMed = value;
        break;

      case 'durationNewMed':
        this.durationForNewMed = value
        break;

      case 'whenToTakeNewMed':
        this.whenToTakeForNewMed = value;
        break;

      case 'unit':
        this.Tounit = "";
        if (this.doseCount.length > 1) {

          this.doseCount[this.doseCount.length - 1].unit = value

        } else {
          this.selectedUnit = value

        }
        break;

      case 'drugType':


        switch (value) {
          case "Tablet":
            this.units = [0.5, 1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5, 5.5, 6, 6.5, 7, 7.5, 8, 8.5, 9, 9.5, 10, 10.5, 11, 11.5, 12, 12.5, 13, 13.5, 14, 14.5, 15];
            this.drugTypeUnit = "Unit";
            this.selectedDrugType = value
            this.selectedDrugUnit = "Unit"
            this.DrugUnitField.value = "Unit"
            break;

          case "Syrup":
          case "Liquid":
          // case "Lotion":
          case "Suspension":
            this.units = ["1 ml", "1.5 ml", "2 ml", "2.5 ml", "3 ml", "3.5 ml", "4 ml", "4.5 ml", "5 ml", "5.5 ml", "6.5 ml", "7 ml", "7.5 ml", "8 ml", "8.5 ml", "9 ml", "9.5 ml", "10 ml"]
            this.drugTypeUnit = "ml";
            this.selectedDrugType = value
            this.selectedDrugUnit = "ml";
            this.DrugUnitField.value = "ml"
            break;

          case "Drop":
            this.units = ["1", "2", "3", "4", "5", "10", "15", "20"]
            this.drugTypeUnit = "Drop";
            this.selectedDrugUnit = "Drop"
            this.DrugUnitField.value = "Drop"
            this.selectedDrugType = "Drop"


            break;

          case "Cream":
          case "Gel":
          case "Ointment":
          case "Oil":
          case "Jelly":
          case "Lotion":
          case "Soap":
          case "Suppository":

            this.units = ['Fingertip']
            this.drugUnit = "Fingertip";
            this.selectedDrugType = value

            if (this.doseCount.length > 1) {
              this.doseCount[this.doseCount.length - 1].unit = "Fingertip";
              this.selectedDrugUnit = "Fingertip"
            } else {
              this.selectedDrugUnit = "Fingertip"
              this.doseCount[0].unit = "Fingertip"
            }

            this.DrugUnitField.value = "Fingertip"

            break;

          case "Inhaler":
            this.units = ['1 Puff', '2 Puff', '3 Puff', '4 Puff', '5 Puff', '6 Puff', "7 Puff", "8 Puff", "9 Puff", "10 Puff"]
            this.drugTypeUnit = "Puff";
            this.selectedDrugType = value

            if (this.doseCount.length > 1) {
              this.doseCount[this.doseCount.length - 1].unit = "Puff"

            } else {
              // this.selectedDrugUnit = "Puff"
              this.doseCount[0].unit = "Puff"
            }

            this.DrugUnitField.value = "Puff"
            break;

          case "Powder":
          case "Granules":
            this.units = ['1 Sachet', '2 Sachet', '3 Sachet', '4 Sachet', '5 Sachet', '6 Sachet', "10 Sachet"];
            this.drugTypeUnit = "Sachet";
            this.selectedDrugType = value

            this.selectedDrugUnit = "Sachet"
            this.DrugUnitField.value = "Sachet"
            break;

          case "Vial":
            this.units = ['2.5', '5', '10', '20', '30', '40', '50']
            this.drugTypeUnit = "Unit";
            this.selectedDrugUnit = "Unit"
            this.DrugUnitField.value = "Unit"
            break;

          case "Capsule":
          case "Sachet":
          case "Spray":
          case "Injection":
            this.selectedDrugType = value
            this.units = ['1', '2', '3', '4', '5', '6', '7', '8'];
            this.drugTypeUnit = "Unit";
            this.selectedDrugUnit = "Unit";
            this.DrugUnitField.value = "Unit"
            break;

          default:
            this.units = ['1', '2', '3', '4', '5', '6', '7', '8'];
            this.drugTypeUnit = "Unit";
            this.selectedDrugUnit = "Unit";
            this.DrugUnitField.value = "Unit"
            break;
        }


        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].drugType = value

        } else {
          this.selectedDrugType = value
        }
        break;

      case 'frequency':
        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].frequency = value
        } else {
          this.selectedFrequency = value;
          this.doseCount[0].frequency = value
        }
        break;
      case 'duration':
        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].duration = value
          this.selectedDuration = this.selectedWhenToTake;
        } else {
          this.doseCount[0].duration = value
          this.selectedDuration = value
        }
        break;

      case 'whenToTake':
        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].whenToTake = value
        } else {
          this.selectedWhenToTake = value;
          this.doseCount[0].whenToTake = value
        }
        break;

      case 'drugType':
        if (this.doseCount.length > 1) {
          this.doseCount[this.doseCount.length - 1].drugType = value
        } else {
          this.selectedDrugType = value;
        }

      case 'drugUnit':
        this.selectedUnit = value;
      
        this.drugTypeUnit = value;
        this.DrugUnitField.value = value
        break;

      default:
        break;
    }
  }
  onChangeDrugName = () => {
    this.unselectedDrugName = false;
  }

  openDialog(content, index) {

    this.selectedIndex = index;
    this.dialog.open(content);
  }
  closeDialog() {
    this.dialog.closeAll()
  }



  setSelectMedicine = ({ item }) => {

    console.log('ITEM', item);

    //

    let arr = item.advisedFrequency ? item.advisedFrequency.split(', ') : [];


    this.frequencyWithVal.map(eachFrequency => {

      arr.map(innerFrequency => {
        if (eachFrequency.name.includes(innerFrequency)) {
          eachFrequency.value = item.advisedDosage
        }
      })

    })

    this.searchedMedicineObj = item;
    this.selectedInstructions = item.advisedInstructions ? item.advisedInstructions : ""
    setTimeout(() => {
     // this.lngMedInstr.appendTexttoBox(this.selectedInstructions)
    }, 200)

    console.log("ins", this.selectedInstructions)
    console.log("medData", this.searchedMedicineObj)


    var drugType = this.handleDrugType(item.product_type);

    if (drugType && drugType != "") {
      // this.drugTypeField.setErrors(null)
    }
    // this.selectedDrugType = drugType

    let index = this.doseCount.length - 1;

    if (this.drugTypeSelection.includes(drugType)) {
      this.doseCount[index].drugType = drugType;
      this.selectedDrugType = drugType;
      this.dropdownSelect('drugType', this.selectedDrugType);
      this.drugTypeField.setErrors(null);
    } else {
      this.doseCount[index].drugType = "";
      this.selectedDrugType = ""
    }
  
    if (item.advisedDosage && parseInt(item.advisedDosage)) {
      this.doseCount[index].unit = item.advisedDosage
      this.selectedUnit = item.advisedDosage;
      // this.drugUnit = item.advisedDosage;
      let name = document.getElementById('drugUnit_0');
      this.drugUnit = item.advisedDosage;

      // name = item.advisedDosage

      this.UnitField.setErrors(null);
    } else {
      // this.doseCount[index].unit = ""
      // this.selectedUnit = "";
      this.UnitField.setErrors(null);
    }

    // FREQUENCY
    if (item.advisedFrequency && item.advisedFrequency != "" && item.advisedFrequency.toLowerCase() != "null") {

      let formattedFrequency = item.advisedFrequency.split(', ').map(ins =>
        ins.charAt(0).toUpperCase() + ins.slice(1)
      );

      this.doseCount[index].frequency = formattedFrequency;
      this.selectedFrequency = formattedFrequency;
      this.FrequencyField.setErrors(null);
    } else {
      this.doseCount[index].frequency = "";
      this.selectedFrequency = "";
    }


    //DURATION
    if (item.advisedDuration && item.advisedDuration != "" && item.advisedDuration.toLowerCase() != "null") {

      this.DurationField.setErrors(null)
      if (item.advisedDurationUnit) {
        this.doseCount[index].duration = item.advisedDuration + ' ' + item.advisedDurationUnit
        this.selectedDuration = item.advisedDuration + ' ' + item.advisedDurationUnit
      } else {
        if (item.advisedDuration == "1") {
          this.doseCount[index].duration = item.advisedDuration + ' ' + 'Day(s)';
          this.selectedDuration = item.advisedDuration + ' ' + 'Day(s)';
        } else {
          this.doseCount[index].duration = item.advisedDuration + ' ' + 'Day(s)';
          this.selectedDuration = item.advisedDuration + ' ' + 'Day(s)';
        }
      }
    } else {
      this.doseCount[index].duration = "";
      this.selectedDuration = "";
    }

    //WHEN TO TAKE
   
    if (item.advisedWhenToTake && item.advisedWhenToTake != "" && item.advisedWhenToTake.toLowerCase() != "null" && whenToTake.includes(item.advisedWhenToTake.charAt(0).toUpperCase() + item.advisedWhenToTake.slice(1))) {

      // element.drugType.charAt(0).toUpperCase() + element.drugType.slice(1) : " ";
      this.doseCount[index].whenToTake = item.advisedWhenToTake.charAt(0).toUpperCase() + item.advisedWhenToTake.slice(1)
      this.selectedWhenToTake = item.advisedWhenToTake.charAt(0).toUpperCase() + item.advisedWhenToTake.slice(1)
      this.WhentotakeField.setErrors(null);

    } else {
      this.doseCount[index].whenToTake = ""
      this.selectedWhenToTake = ""
    }

    var duration = "";
    this.doseCount.map(ins => {
      if (ins.duration == '') {
        duration = ins
      }
    });
    setTimeout(() => {
   
      this.selectedDrugUnit = this.drugTypeUnit;
      this.DrugUnitField.setErrors(null)
    }, 200);

  }
  handleDrugType(productType) {
    var drugType = "";
    if (productType.toLowerCase().includes('cap')) {
      drugType = "Capsule"
    }
    else if (productType.toLowerCase().includes('tab')) {
      drugType = "Tablet"
    }
    else if (productType.toLowerCase().includes('stri')) {
      drugType = "Strip"
    }
    else if (productType.toLowerCase().includes('syru')) {
      drugType = "Syrup"
    }
    else if (productType.toLowerCase().includes('drop')) {
      drugType = "Drop"
    } else if (productType.toLowerCase().includes('inhale')) {
      drugType = "Inhaler"
    } else if (productType.toLowerCase().includes('liqui')) {
      drugType = "Liquid"
    } else if (productType.toLowerCase().includes('powd')) {
      drugType = "Powder"
    } else if (productType.toLowerCase().includes('sach')) {
      drugType = "Sachet"
    } else if (productType.toLowerCase().includes('spray')) {
      drugType = "Spray"
    } else if (productType.toLowerCase().includes('susp')) {
      drugType = "Suspension"
    } else if (productType.toLowerCase().includes('vial')) {
      drugType = "Vial"
    }
    return drugType || productType
  }



  getSearchedMeds = (searchString) => {
    var searchDrugOnDoctorId: any = []
    if (searchString.length >= 3) {
      this.medicineLoader = true;
      var obj = { "query": searchString };
      this.patientService.getDrugs(obj).subscribe(response => {
        console.log('Response', response);
        this.medicineLoader = false;
        this.searchedMeds = response.drugs.reduce((unique, o) => {
          if (!unique.some(obj => obj.product_name.replaceAll(' ', '').toLowerCase() === o.product_name.replaceAll(' ', '').toLowerCase() && obj.product_type === o.product_type)) {
            unique.push(o);
          }
          return unique;
        }, []);
        this.searchedMeds.map(ins => {
          if (ins.doctorId == this.doctorObj.id || ins.doctorId == null) {
            console.log("ins.doctorId ",ins.doctorId );
            console.log("this.doctorObj.id ",this.doctorObj.id );
            console.log("ins.doctorId ",ins.doctorId);

            
            
           var abc = searchDrugOnDoctorId.push(ins)
            console.log("searchDrugOnDoctorId",abc);
            
          }
        })
        this.searchedMeds = searchDrugOnDoctorId

        console.log("Filtered searchedMeds:", this.searchedMeds);
        console.log(this.doctorObj)
        // this.searchedMeds = this.searchedMeds.splice(0, 5);

      }
      )
    } else {
      this.searchedMeds = [];
      this.medicineLoader = false;
    }
  }
  formatter = (result: any) => result.product_name;
  formatMatches = (result: any) => result.product_name ? result.product_name : '';

  searchDrugsByTyping = (text$: Observable<string>) =>
  text$.pipe(
    debounceTime(200),
    distinctUntilChanged(),
    map(term => term === '' ? []
      : this.searchedMeds?.filter(v => JSON.stringify(v)))
  )


  addNewMedicine($event) {

    if (!this.isAddMedicine) {

      setTimeout(() => {
        this.UnitField.setErrors(null);
        this.FrequencyField.setErrors(null)
        this.WhentotakeField.setErrors(null)
        this.drugTypeField.setErrors(null)
        this.DurationField.setErrors(null)
      }, 500);

     // this.clearAddMedicine()
    }
    // 
    this.isAddMedicine = true;

  }
  toggleFrequencyCollapse() {
    if (this.frequencyCollapsibleVariable == 4) {
      this.frequencyCollapsibleVariable = 9;
    } else {
      this.frequencyCollapsibleVariable = 4;
    }
  }


  addToList() {

  

    var dignosisId: any = [];
    // this.allDignosis.map(ins => {
    //   if (ins.selected) {
    //     dignosisId.push(ins.id)
    //   }

    // });

    let dosage = '';
    let frequencyArr = [];
    let frequency = ''
    this.frequencyWithVal.map(eachFrequency => {
      if (eachFrequency.value && eachFrequency.value != '0' && eachFrequency.value != 'NULL') {

        let frequency = '';
        let dose: any = ''
        if (this.drugTypeUnit == 'ml') {
          dose = (eachFrequency.value + "")

        }
        else {
          dose = this.convertDoseUnitToUnicode(eachFrequency.value + "")
          
        }

        let doseValue=""
        if(this.drugUnit == "Fingertip"){
         this.drugTypeUnit=this.drugUnit
        }

        doseValue =  this.drugTypeUnit == 'ml' ? dose + ' ml' : dose;
        let unit = this.drugTypeUnit == 'Unit' || this.drugTypeUnit == 'ml' ? this.selectedDrugType : this.drugTypeUnit;
        frequency = frequency + `${doseValue} - ${unit} - ${eachFrequency.name}`
        frequencyArr.push(frequency);
      }
    });

    let name = "";
    if (this.medicineName.product_name && this.medicineName.product_name != "" && this.selectedDrugType != "") {

      let unitType = '';
      let newUnitFinal = ""
      if (this.drugTypeUnit == 'ml' ||
        this.drugTypeUnit == 'Puff'
        || this.drugTypeUnit == 'Sachet') {
        unitType = this.drugTypeUnit
      }
      if (this.drugTypeUnit == 'ml') {
        newUnitFinal = this.selectedUnit
      }
      else {
        newUnitFinal = this.convertDoseUnitToUnicode(this.selectedUnit);
      }

      if (this.drugMolecularFlag == 1) {
        console.log("drugFlag", this.drugMolecularFlag)

        name = this.medicineName.product_name && this.medicineName.product_name;
        console.log("name", name)
        if (this.selectedFrequency.length) {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
        }
        else {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
        }
      }
      else {
        name = this.medicineName.product_name && this.medicineName.product_name;
        if (this.selectedFrequency.length) {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency.join(', ')} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`

        }
        else {
          dosage = `${newUnitFinal} ${unitType} - ${this.selectedDrugType} - ${this.selectedFrequency} - ${this.selectedDuration}, ${this.selectedWhenToTake ? this.selectedWhenToTake : ""}`
        }
      }


      // Drug molecular flag.
      // if (this.drugMolecularFlag == 1) {
      //   name = this.medicineName.product_name && this.medicineName.generic_name ? this.medicineName.product_name + ' (' + this.medicineName.generic_name + ')' : this.medicineName.product_name;

      // }
      // else {
      //   name = this.medicineName.product_name && this.medicineName.product_name;
      // }
      // END


      let Drug = {
        id: this.searchedMedicineObj.id,
        product_name: this.medicineName.product_name,
        generic_name: this.medicineName.generic_name,

      }
     // console.log("Drug Obj", Drug, this.lngMedInstr.getValue());
      let obj = {
        // id: this.searchedMedicineObj.id,... drud id isin "Drug" obj below
        name: this.medicineName.product_name,
        generic_name: this.medicineName.generic_name,

        dosage: frequencyArr.join(', '),
        //dosage2: frequencyArr.join(', '),
        dosage2: frequencyArr.join(', '),
        duration2: this.selectedDuration,
        whenToTake: this.selectedWhenToTake ? this.selectedWhenToTake : "",
        drugType: this.selectedDrugType,
        duration: this.selectedDuration,
        unitType: unitType,
        dosageValue: newUnitFinal,
        frequency: this.selectedFrequency && this.selectedFrequency.length ? this.selectedFrequency.join() : '',
        Drug,
        //instructions: this.selectedInstructions,
       instructions: this.lngMedInstr.getValue(),
        selected: true
      }
      this.allMedicines.push(obj);
      console.log("newMed", Drug, "all", this.allMedicines)
      this.selectedFavMed.push(obj)
     

      //save this drug and doage to doctor's popular used
      var postData: any = {
        drugObj: obj,
        commaSeparatedDiagnosisIds: "" // dignosisId.join() // not saving medicines against diagnosis
      }
      this.patientService.saveMedicineOfDoctorperDiagnosis(postData).subscribe(response => {
        if (response) {
          console.log("saved 1 drug to doc popular for diag", response);
        //  this.getMedicinesAsPerDiagnosis();

          this.frequencyWithVal.map(eachFrequency => {
            if (eachFrequency.value && eachFrequency.value != '0') {
              eachFrequency.value = "";
            }
          });
        }
      })

      this.clearAddMedicine();
    
      
    } else {
      if (!this.medicineName.product_name && this.medicineName.length > 2) {
       // this.proceedAddMedicine();
        this.dialog.open(this.add_new_drug_modal);
      } else {
        this.showAddMedicineBox=true
        this.dialog.open(this.incomplete_data_modal);
      }

      this.UnitField.markAsTouched();
      this.DurationField.markAsTouched();
      this.drugTypeField.markAsTouched();
      this.searchTextField.markAsTouched();
      this.DrugUnitField.markAsTouched();

    }

    // setTimeout(() => {
    //   document.getElementById("typeahead-format").focus();
    // }, 500);
   
    this.showOnlyFavourite=true
  }
  convertDoseUnitToUnicode(dose) {
    const dosesVariant = {
      "5": "\u00BD",
      "50": "\u00BD",
      "25": "\u00BC",
      "75": "\u00BE"
    }

    const doseUnit = ("" + dose).split('.');
    const newUnit = doseUnit[0];
    const decimalUnit = doseUnit[1];
    if (dosesVariant[decimalUnit]) {
      return (Number(newUnit) > 0 ? newUnit : "") + dosesVariant[decimalUnit]
    } else {
      return dose;
    }
  }

  clearAddMedicine() {
    // this.isAddMedicine = false;
    this.editMode=true
    this.showAddMedicineBox=false
    this.selectedUnit = '';
    this.selectedDrugType = '';
    this.selectedFrequency = [];
    this.selectedDuration = '';
    this.selectedWhenToTake = '';
    this.medicineName = "";
    this.doseCount = [{ unit: "", drugType: "", frequency: "", duration: "", whenToTake: "" }];
    this.searchedMedicineObj = {}
    this.selectedInstructions = "";
    this.drugUnit = null;

    this.UnitField.value = "";
    this.FrequencyField.value = "";
    this.WhentotakeField.value = "";
    this.drugTypeField.value = ""
    this.DurationField.value = "";
    this.DrugUnitField.value = "";

    this.drugTypeUnit = "";

    

    this.allMedicineInstructions = this.allMedicineInstructions.map(ins => { return { name: ins.name, selected: false } });
    this.allMedicineInstructionsOg = this.allMedicineInstructions;

     this.lngMedInstr.clearTextBox();
  }
  createFavMed(){
  //  this.showOnlyFavourite=true
    this.exitsAppointmentId=null
    this.editMode=false
    this.selectedFavMed=[]
    this.selectedFavName=""
    this.showAddButton=true
   this.showPlaceholder=false
   this.showOnlyFavourite=false
   this.editTextBox=true
   this.showDeleteButton=false
  }
  deleteAction(){
   
    this.selectedFavMed.splice(this.selectedIndex, 1);
    this.dialog.closeAll()
  }

  onClickDeleteFavMed() {
    var  appointmentId=null
    if(this.appintmentId){
      appointmentId=this.appintmentId
    }
    else{
      appointmentId=this.currentFavMedObj.id
    }
 
   let postData = {
      appointmentId: appointmentId
    }
    this.patientService.removeFavApptOfDoctor(postData).subscribe(response => {
  
      this.getFavApptsOfDoctor();
     
    //  this.selectedFavMed=[]
     // this.selectedFavName=""
      this.editMode=false
      this.editTextBox=false;
      this.showOnlyFavourite=false
      this.showPlaceholder=true
       // this.createFavMed();
      
    
  
    });

   
  
    this.dialog.closeAll()


  }
  saveModalValues(type: string) {
    let closeDialogFlag = true;
    switch (type) {

      case 'medicine_instructions':
        var instructions = this.lngMedInstr.getValue();
        this.allMedicineInstructionsOg.map(ins => {

          if (ins.selected && !this.selectedInstructions.includes(ins.name)) {
            //this.selectedInstructions = this.selectedInstructions.length ? this.selectedInstructions + ', ' + ins.name : ins.name

            if (instructions != undefined || instructions != "") {
              this.lngMedInstr.setTextBoxValue(instructions.length ? instructions + ', ' + ins.name : ins.name);
            }
            else {
              this.lngMedInstr.setTextBoxValue(ins.name);
            }

          }

        });
        break;

    case 'drugTypesAddNewDrug':

        if (this.drugNameAddNewDrugs != "" && this.drugTypeAddNewDrugs != "") {
          this.addNewDrugs();
        } else {
          closeDialogFlag = false;
          alert('Please specify drug name and type.')
        }

        break;
  }
}

addNewDrugs = () => {
 

  const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9];
  let otp = "";
  for (let i = 0; i < 4; i++) {
    //changed to 6 from 4
    otp += numbers[Math.floor(Math.random() * 8) + 1];
  }

  var obj = {
    "product_name": this.drugNameAddNewDrugs,
    "product_company": this.drugCompanyAddNewDrugs,
    "product_type": this.drugTypeAddNewDrugs,
    "product_composition": this.genericDrugNameAddNewDrugs,
    "product_id": `${this.drugNameAddNewDrugs.substring(0, 3).trim() + otp}`,

    "advisedDosage": this.unitForNewMed,
    "advisedFrequency": this.frequencyForNewMed.length ? this.frequencyForNewMed.join(', ') : '',
    "advisedDuration": this.durationForNewMed.length ? this.durationForNewMed.split(' ')[0] : '',
    "advisedDurationUnit": this.durationForNewMed.length ? this.durationForNewMed.split(' ')[1] : '',
    "advisedWhenToTake": this.whenToTakeForNewMed,
    "advisedInstructions": this.instructionsForNewMed
  }


  console.log('Object', obj)

  this.patientService.addNewDrug(obj).subscribe(result => {
    if (result.Drug) {

      // To open type ahead after adding drug
      // Dispatch event on input element that NgbTypeahead is bound to
      // this.typeAhead.nativeElement.dispatchEvent(new Event('input'));
      // Ensure input has focus so the user can start typing
      // this.typeAhead.nativeElement.focus();

      console.log('Saved New Drug');
      this.searchedMeds = result.Drug;
      console.log("newDrug", this.searchedMeds);
      this.medicineName = result.Drug;
      this.setSelectMedicine({ item: this.searchedMeds });
      this.addToList();
      this.drugCompanyAddNewDrugs = '';
      this.genericDrugNameAddNewDrugs = '';
      this.drugTypeAddNewDrugs = '';
      this.drugNameAddNewDrugs = '';
    } else {
      alert('Something went wrong');
    }
  });
}
private _filter(value: string): string[] {
  const filterValue = value.toLowerCase();
  return this.drugTypesOg.filter(option => option.toLowerCase().includes(filterValue));
}


getAllRequiredConstants(){
  this.patientService.getAllRequiredConstants(this.patientSelectedLanguage).subscribe(response => {
    if (response) {
   
      this.allMedicineInstructions = response.data.medicineInstructions.map(ins => { return { name: ins.name, selected: false } });
      this.allMedicineInstructionsOg = response.data.medicineInstructions.map(ins => { return { name: ins.name, selected: false } });
     

  
      this.drugTypes = response.data.drugTypes.map(ins => { return ins.name });
      this.drugTypesOg = response.data.drugTypes.map(ins => { return ins.name });

      //here
      this.drugTypes = this.myControl.valueChanges.pipe(
        startWith(''),
        map(value => this._filter(value))
      );

      console.log("general", this.drugTypes)
    }
  })
}

selectModalValue(index, type) {

  if (type == 'drugTypes') {

    if (this.drugTypes[index].selected) {
      this.drugTypes[index].selected = false;
      this.drugTypesOg[index].selected = false;
    } else {
      this.drugTypes[index].selected = true;
      this.drugTypes.map((ins, ind) => {
        if (index != ind) {
          this.drugTypes[ind].selected = false;
        }
      })
    }

  } else {

    if (this.allMedicineInstructions[index].selected) {
      this.allMedicineInstructions[index].selected = false;
      this.allMedicineInstructionsOg[index].selected = false;

      this.selectedInstructions = this.selectedInstructions.replace(this.allMedicineInstructions[index].name + ',', '');
      this.selectedInstructions = this.selectedInstructions.replace(this.allMedicineInstructions[index].name, '');
    } else {
      this.allMedicineInstructions[index].selected = true;
    }

    this.allMedicineInstructions = this.allMedicineInstructionsOg
    this.allMedicineInstructions = this.allMedicineInstructions.reduce((unique, o) => {
      if (!unique.some(obj => obj.name.toLowerCase() == o.name.toLowerCase())) {
        unique.push(o);
      }
      return unique;
    }, []);

  }

}


onSearchChangeFav(searchValue: string): void {
  let newTestArr = JSON.parse(JSON.stringify(this.favArrayOrg));
  if (searchValue == '') {
    this.favArray = newTestArr;
  } else {
    var testArr = [];
    for (let index = 0; index < newTestArr.length; index++) {
      var element = newTestArr[index];
      if (element.value.toLowerCase().includes(searchValue.toLowerCase())) {
        testArr.push(element);
      }
      var newArr = []
      for (let j = 0; j < element.length; j++) {
        const test = element[j];
        if (test.value.toLowerCase().includes(searchValue.toLowerCase())) {
          newArr.push(test);
        }
      }
      if (newArr.length) {
        element.data = newArr
        testArr.push(element);
      }
    }
    testArr = testArr.filter(function (item, pos, self) {
      return self.indexOf(item) == pos;
    });

    this.favArray = testArr;
  }

}




}
