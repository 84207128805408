import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NewRxRoutingModule } from './new-rx-routing.module';
import { NewQuickRxComponent } from './new-quick-rx/new-quick-rx.component';
import { NewQuickRxHomeComponent } from './new-quick-rx-home/new-quick-rx-home.component';
import { RouterModule } from '@angular/router';
import { LabRoutingModule } from '../lab/lab-routing.module';
import { HomeDashboardComponent } from '../doctor/home-dashboard/home-dashboard.component';
import { TopInfoNavbarComponent } from '../doctor/patient/top-info-navbar/top-info-navbar.component';
import { DoctorModule } from '../doctor/doctor.module';
import { PatientListComponent } from './patient-list/patient-list.component';
import { SidenavDirectionComponent } from './sidenav-direction/sidenav-direction.component';
import {MatIconModule} from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatError, MatFormFieldModule, } from '@angular/material/form-field';
import { MatSelectModule, MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatButton, MatButtonModule } from '@angular/material/button';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatMenuModule} from '@angular/material/menu';
import {MatNativeDateModule} from '@angular/material/core';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule, MatStepper, MatStep } from '@angular/material/stepper';
import {MatChipsModule} from '@angular/material/chips';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import {MatSidenavModule} from '@angular/material/sidenav';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { PatientPreviousDetailsComponent } from './patient-previous-details/patient-previous-details.component';
import { NewRxTopBarComponent } from './new-rx-top-bar/new-rx-top-bar.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgbTypeahead, NgbTypeaheadModule } from '@ng-bootstrap/ng-bootstrap';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

@NgModule({
  declarations: [
    NewQuickRxComponent,
    NewQuickRxHomeComponent,
    PatientListComponent,
    PatientPreviousDetailsComponent,
    NewRxTopBarComponent,
   // SidenavDirectionComponent,
 
   // HomeDashboardComponent
  ],
  imports: [
    MatTableModule,
    MatTooltipModule,
    NgbDropdownModule,
    MatSidenavModule,
    Ng2SearchPipeModule,
    MatRadioModule,
    MatCheckboxModule,
    MatStepperModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatDatepickerModule,
    MatInputModule,
    MatButtonModule,
    MatMenuModule,
    MatExpansionModule,
    CommonModule,
    MatIconModule,
    DoctorModule,
    MatAutocompleteModule,
    FormsModule,
    ReactiveFormsModule,
    MatSelectModule, 
    MatChipsModule,
    MatProgressSpinnerModule,
  
    RouterModule.forChild(NewRxRoutingModule),
  
    NgbTypeaheadModule,
    DragDropModule
  ],
  providers:[
    {
      provide: MatSelect,
      useValue: {}
    },
    {
      provide: MatStepper,
      useValue: {}
    },
    {
      provide: MatStep,
      useValue: {}
    },
    {
      provide: MatSelectTrigger,
      useValue: {}
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
  ]
 
})
export class NewRxModule { }
