import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AdminService } from '../../services/admin.service'
import { ToastrService } from 'ngx-toastr';
import * as $ from 'jquery'

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  credentials: any
  emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/
  userId = null
  errors: any = {}
  constructor(private adminService: AdminService, private route: ActivatedRoute, private router: Router, private toastr: ToastrService) {

    this.route.params.subscribe(params => {
      if (params.token) {
        this.userId = atob(params['token']).split('#')[0]
        let date = atob(params['token']).split('#')[1]
        const req_vars = { id: this.userId }
        this.adminService.checkResetLink(req_vars).subscribe(response => {



          if (new Date(response.user.reset_password_expiry) < new Date() || new Date(response.user.reset_password_sent) > new Date(date)) {
            this.toastr.error("Link has been expired")
            this.router.navigate(["/admin/login"])
          }
        }, (err) => {
          console.error(err)
        })
      } else {
        this.router.navigate(["/admin/login"])
      }
    })


  }

  ngOnInit() {
    this.credentials = { user_type: "Admin" }
  }

  submit() {
    this.errors = {}
    this.credentials.password = this.credentials.password ? this.credentials.password.trim() : null
    if (!this.credentials.password) {
      this.errors.password = "Password must be minimum of 6 characters"
    }
    else if (this.credentials.password.length < 6) {
      this.errors.password = "Password must be minimum of 6 characters"
    }
    if (this.credentials.confirm_password != this.credentials.password) {
      this.errors.confirm_password = "Password does not match"
    }

    if (Object.keys(this.errors).length == 0) {
      let params = {
        password: this.credentials.password,
        user_id: this.userId
      }
      this.adminService.changePassword(params).subscribe(respose => {
        if (respose.success) {
          this.toastr.success("Password updated successfully")
          this.router.navigate(["/admin/login"])
        } else {
          this.toastr.error(respose.error)
        }
      })
    }

  }

  addLabelClass(e) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

}
