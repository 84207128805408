<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
  <label for="" class="loader"></label>
</div>


<div class=" col-lg-12">
  <div class="dashboard-content">
    <div class="user-management-data">

      <div class="row appointmentHead" style="margin-left: 0;margin-top: 15px;margin-bottom: 15px;">
        <!-- <div class="scheduleandAddNew-outer">
          <div class="scheduleandAddNew">
            <div class="appointment float-right searchIcon active"
              style="margin-right: 28px; border-radius: 4px; border-color: #cccc;">
              <div class="form-group searchField pull-left" style="display: flex;">
                <input type="text" placeholder="Search Name or Phone" class="form-control" #searchInput
                  [(ngModel)]="searchString" (keyup)="searchAppointmentByNameNumber()"
                  style="font-size: 15px; border-radius: 4px !important; border-right: 0px solid #000 !important; padding: 8px !important;" />
                <a type="button" (click)="closeSearch()" style="padding-left: 6px;
                padding-right: 6px;
                font-size: 34px; top: -4px;">×</a>
              </div>
              <div class="searchbtn">
                <button (click)="searchAppointmentByNameNumber()"><img src="assets/images/searchIcon.svg"
                    alt="" /></button>
              </div>
            </div>
          </div>
        </div> -->
        <div class="col-xl-12 col-lg-12">
          <div class="row d-flex ">
            <div style="margin-right: 20px;">
              <div class="todaysAppointments pl-2"
                style="margin-top: 8px !important; font-size: 16px !important; font-weight: bold;">Search by</div>
            </div>
            <div class="scheduleandAddNew-outer">
              <div class="form-group animateLabel calendarIcon mb-0 pt-0 calender">
                <div class="appointment float-right searchIcon active"
                  style="width:150px; border-radius: 4px !important; border-color: #cccc;">
                  <input [(ngModel)]="fromDate" ngbDatepicker #dfrom="ngbDatepicker" (focus)="dfrom.open()" type="text"
                    class="form-control" placeholder="DD/MM/YYYY" name="" (document:click)="closeCalender($event,dfrom)"
                    style="font-size: 15px; padding: 8px !important; border-right: 0px solid #ccc !important" readonly>
                  <img (click)="dfrom.toggle()" style="padding-right: 10px;" class="calendarbg"
                    src="assets/images/calendarIcon.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="fs-18 d-flex" style="margin-right: 20px;">
              <div class="todaysAppointments"
                style="margin-top: 8px !important; font-size: 16px !important; font-weight: bold;">To
              </div>
            </div>
            <div class="scheduleandAddNew-outer">
              <div class="form-group animateLabel calendarIcon mb-0 pt-0 calender">
                <div class="appointment float-right searchIcon active"
                  style="width:150px; border-radius: 4px !important; border-color: #cccc;">
                  <input [(ngModel)]="toDate" ngbDatepicker #dto="ngbDatepicker" (focus)="dto.open()" type="text"
                    class="form-control" placeholder="DD/MM/YYYY" name="" (document:click)="closeCalender($event,dto)"
                    style="font-size: 15px; padding: 8px !important; border-right: 0px solid #ccc !important" readonly>
                  <img (click)="dto.toggle()" style="padding-right: 10px;" class="calendarbg"
                    src="assets/images/calendarIcon.svg" alt="" />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <div class="searchbtn">
                <button (click)="getVaccReports()" style="padding-left: 6px !important;
                    padding-right: 6px !important;
                    border-radius: 4px !important;
                    color: #4587c0 !important;
                    border-color: #4587c0 !important;
                    font-weight: bold;
                    font-size: 16px !important; width: 96px;" class="searchbtn1">Search &nbsp;<span>&#8680;</span>

                  <!-- <img class="forImgWidth" alt=""src="assets/images/searchIcon.svg"> -->

                </button>
              </div>
              <div class="searchbtn ml-15" style="margin-left: 8px !important;">
                <!-- <button (click)="closeSearch()" class="searchbtn1"><i _ngcontent-c5="" class="fa fa-times-circle"
                    aria-hidden="true"></i>
                </button> -->
                <button style="padding-left: 6px !important;
                    padding-right: 6px !important;
                    border-radius: 4px !important;
                    color: #4587c0 !important;
                    border-color: #4587c0 !important;
                    font-weight: bold;
                    font-size: 16px !important; width: 90px;" (click)="setDefaultDates()" class="searchbtn1">Clear
                  &nbsp;
                  <span style="font-size: 22px;">×</span>
                  <!-- <i
                     style="color: #4587c0;"
                    _ngcontent-c5="" aria-hidden="true" class="fa fa-times-circle"></i> -->
                </button>

              </div>
            </div>
            <div class="searchbtn" style="margin-left: 25px;">
              <button (click)="downloadPDF()" style="padding-left: 6px !important;
                  padding-right: 6px !important;
                  border-radius: 4px !important;
                  color: #4587c0 !important;
                  border-color: #4587c0 !important;
                  font-weight: bold;
                  font-size: 16px !important; width: 150px;" class="searchbtn1">Download PDF &nbsp;<span></span>

                <!-- <img class="forImgWidth" alt=""src="assets/images/searchIcon.svg"> -->

              </button>
            </div>
            <div class="searchbtn" style="margin-left: 15px;">
              <button (click)="openPDF()" style="padding-left: 6px !important;
                  padding-right: 6px !important;
                  border-radius: 4px !important;
                  color: #4587c0 !important;
                  border-color: #4587c0 !important;
                  font-weight: bold;
                  font-size: 16px !important; width: 150px;" class="searchbtn1">Open PDF &nbsp;<span></span>

                <!-- <img class="forImgWidth" alt=""src="assets/images/searchIcon.svg"> -->

              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="htmlData" #htmlData>

        <table id="vacreport" class="table  table-fixed" style="border: 1px solid #e9eaea;">
          <thead>
            <tr class="col-12 pl-0 pr-0">
              <th class="col-2 d-flex justify-content-start align-items-center"
                style="background: #f1f1f1;padding: 8px;color: #333;font-weight: bold;">Due Date
              </th>
              <th class="col-2 d-flex justify-content-start align-items-center"
                style="background: #f1f1f1;padding: 8px;color: #333;font-weight: bold;">Taken Date
              </th>
              <th class="col-2 d-flex justify-content-start align-items-center"
                style="background: #f1f1f1;padding: 8px;color: #333;font-weight: bold;">Child Name

              </th>
              <th class="col-2 d-flex justify-content-start align-items-center"
                style="background: #f1f1f1;padding: 8px;color: #333;font-weight: bold;">
                Vaccine Name
              </th>

              <th class="col-2 d-flex justify-content-start align-items-center"
                style="background: #f1f1f1;padding: 8px;color: #333;font-weight: bold;">
                Child Age

              </th>
              <th class="col-2 d-flex justify-content-start align-items-center"
                style="background: #f1f1f1;padding: 8px;color: #333;font-weight: bold;">Phone Number

              </th>



            </tr>
          </thead>
          <tbody>
            <tr *ngIf="vaccList.length === 0" class="notFound">
              <td class="noRecords" colspan="3">No records found</td>
            </tr>
            <tr class="col-12 pl-0 pr-0" *ngFor="let activity of vaccList; let i = index">
              <td class="col-2">{{activity.DueDate}}</td>
              <td class="col-2">{{activity.TakenDate}}</td>
              <td class="col-2 capitalize">{{activity.ChildName}}</td>

              <td class="col-2">{{activity.VaccineName}}</td>
              <td class="col-2">{{activity.ChildAge}}</td>

              <td class="col-2">{{activity.PhoneNumber}}</td>

            </tr>
          </tbody>

        </table>
      </div>

    </div>

  </div>
</div>
<p-confirmDialog appendTo="body"></p-confirmDialog>