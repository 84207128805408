export class Constants {
  public static get APICONFIG(): any {
    let config = {
      PROTOCOL: "https",
      HOST: "app.copious.care",
      PORT:443,
      doctorProfileImagePath:
        "https://copious-opd-prod.s3.ap-south-1.amazonaws.com/images/profile_images/Doctor/",
      patientProfileImagePath:
        "https://copious-opd-prod.s3.ap-south-1.amazonaws.com/images/profile_images/Patient/",
      vaccineImagePath:
        "https://copious-opd-prod.s3.ap-south-1.amazonaws.com/images/vaccines/",
      reportsPath:
        "https://copious-opd-prod.s3.ap-south-1.amazonaws.com/reports",
      milestoneImagePath:
        "https://copious-opd-prod.s3.ap-south-1.amazonaws.com/images/milestones/",
      dischargeNotesPath:
        "https://copious-opd-prod.s3.ap-south-1.amazonaws.com/images/discharge_notes/"
    };
    return config;
  }
}
  