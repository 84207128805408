import { Component, OnInit } from '@angular/core';
import * as $ from 'jquery'
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms'
import { AuthService } from '../../../services/auth.service'
import { UserService } from '../../../services/user.service'
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
@Component({
  selector: 'app-change-phone',
  templateUrl: './change-phone.component.html',
  styleUrls: ['./change-phone.component.scss']
})
export class ChangePhoneComponent implements OnInit {

  phoneNumber: FormControl
  password: FormControl
  userId: null
  contactForm: FormGroup
  otpSection = false
  otp = null
  mask: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  phone: null
  submitted = false

  constructor(private formBuilder: FormBuilder, private router: Router, private authService: AuthService, private userService: UserService, private toastr: ToastrService) {
    this.userId = this.authService.getUser()
    this.contactForm = this.formBuilder.group({
      phoneNumber: [null, Validators.compose([
        Validators.required,
        Validators.minLength(11),
        Validators.maxLength(12)])
      ],
      password: [null, Validators.compose([
        Validators.required,
        Validators.minLength(6)]),
      ],
    })
  }

  ngOnInit() {
  }

  goBack() {
    this.router.navigateByUrl('/doctor/homepage')
  }

  addLabelClass(e) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }

  removeLabelClass(e) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

  checkPhonePassword() {
    this.submitted = true
    if (this.contactForm.valid) {
      let params = {
        user_id: this.userId,
        phone_number: this.contactForm.controls['phoneNumber'].value,
        password: this.contactForm.controls['password'].value
      }
      this.userService.checkPhonePassword(params).subscribe(response => {
        if (response.success) {
          this.submitted = false
          this.phone = response.phone_number
          this.otpSection = true
          this.otp = null
          setTimeout(function () {
            document.getElementById('otp').focus();
          }, 200)
          setTimeout(function () {
            $('#resend-otp').removeClass('not-active')
          }, 120000);
        } else {
          this.toastr.error(response.error)
        }
      })
    }
  }

  validateNumber(e) {
    return (e.charCode > 47 && e.charCode < 58) || (e.which == 8) || (e.keyCode == 9) || (e.which == 13)
  }

  changePhoneNumber() {
    if (!this.otp) {
      this.otp = null
      this.toastr.error("Please enter valid OTP or click on resend button to receive new OTP")
    }
    else if (this.otp.length < 6) {
      this.otp = null
      this.toastr.error("Please enter valid OTP or click on resend button to receive new OTP")
    } else {
      let params = {
        user_id: this.userId,
        phone_number: this.contactForm.controls['phoneNumber'].value,
        password: this.contactForm.controls['password'].value,
        otp_action: 'change_phone',
        otp: this.otp
      }
      this.userService.updateUser(params).subscribe(response => {
        if (response.success) {
          this.otp = null
          this.otpSection = false
          this.contactForm.reset()
          this.toastr.success("Phone number updated successfully")
        } else {
          this.toastr.error(response.error)
        }
      })
    }
  }

  onKeyUp(event, id, nextInputIndex) {
    let keyCodeValue = event.keyCode;
    let input = event.target;
    let nexInput, newID;

    if (keyCodeValue != 32 && (event.key >= 0 && event.key <= 9) || keyCodeValue == 8 || keyCodeValue == 13) {
      if (keyCodeValue === 8) {
        nexInput = nextInputIndex - 1;
        newID = id + nexInput;
      } else if (keyCodeValue === 9) {
        nexInput = nextInputIndex;
        newID = id + nexInput;
      } else {
        nexInput = nextInputIndex + 1;
        newID = id + nexInput;
      }
      if (nexInput <= 5 && nexInput >= 0) {
        document.getElementById(newID).focus();
      }
    } else {
      $('#tab' + nextInputIndex).val('');
      return false;
    }
  }


  reqOtp() {
    let params = {
      phone_number: this.phone,
      otp_action: 'change_phone',
      user_id: this.userId
    }

    this.userService.generateOTP(params).subscribe(response => {
      if (response.success) {
        $('#resend-otp').addClass('not-active')
        setTimeout(function () {
          $('#resend-otp').removeClass('not-active')
        }, 12000);
        this.toastr.success("OTP has been sent to your registered mobile number")
      } else {
        this.toastr.error(response.error)
      }
    })
  }

}
