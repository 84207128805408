import { MatTableDataSource } from '@angular/material/table';
import { MarketPlaceService } from './../../services/market-place.service';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MessageService } from 'primeng/api';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss'],

})
export class ProductsComponent implements OnInit {
  @Input() cartData: any[];

  @Output() itemAdded: EventEmitter<any> = new EventEmitter<any>();

  @Output() activateInventoryTab: EventEmitter<void> = new EventEmitter<void>();
  @Output() activatAllOrderTab: EventEmitter<void> = new EventEmitter<void>();

  @Output() productAddedToCart = new EventEmitter<void>();
  selectedProduct: any;
  quantity: number = 1;
  isButtonDisabled = false;
  availableStock: number;
  productDetails: any;
  grandTotal = 0;

  cartData1: any[] = [];


  constructor(
    private marketPlaceService: MarketPlaceService,
    private router: Router,
    private dialog: MatDialog,
    private snackBar: MatSnackBar
  ) {


  }

  masterProductsVariations: any = [];
  displayedColumns: string[] = ['productName', 'price', 'company', 'measurement', 'action'];
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.cartData1 && !changes.cartData1.firstChange) {
      this.getAllMasterProductVariations()
    }
  }
  ngOnInit(): void {
    this.getAllMasterProductVariations()

  }
  openModal(product: any, temp: any) {
    console.log("productproductproduct", product);
    this.availableStock = product.quantity
    console.log("availableStock", this.availableStock);

    this.selectedProduct = product;
    // let productVariationId = product.id
    // this.router.navigate(['/marketplace/productDetails'], { queryParams : { productVariationId : productVariationId }})
    this.dialog.open(temp)
  }


  increment() {
    this.quantity = this.quantity + 1
    console.log("this.quantity ", this.quantity);

  }

  decrement() {
    if (this.quantity == 0) {
      this.quantity = 0
    }
    else {
      this.quantity = this.quantity - 1
    }
  }
  // addProductToLocalStorge() {
  //   if (this.selectedProduct) {
  //     let item = {
  //       productName: this.selectedProduct.MasterProduct.productName,
  //       measurement: this.selectedProduct.measurement + ' ' + this.selectedProduct.unitOfMeasurement,
  //       priceAtOrderTime: this.selectedProduct.price,
  //       selectedUnit: this.quantity,
  //       total: this.selectedProduct.price * this.quantity
  //     };

  //     if (localStorage.getItem('cartItems') === null) {
  //       this.cartData1.push(item);
  //       localStorage.setItem('cartItems', JSON.stringify(this.cartData1));
  //     } else {
  //       let productId = this.cartData1.findIndex(el => el.productVariationId === this.selectedProduct.id);

  //       if (productId === -1) {
  //         this.cartData1.push(item);
  //       } else {
  //         this.cartData1[productId] = item;
  //       }
  //       localStorage.setItem('cartItems', JSON.stringify(this.cartData1));
  //     }
  //     this.getCartItemsFromLocalStorage();

  //   } else {
  //     console.error("No product selected to add to cart.");
  //   }
  // }
  closeDialog() {
    this.dialog.closeAll()
    this.quantity = 1;

  }
  addProductToLocalStorge(dt: any) {
    console.log("dydydydyd", dt);

    if (this.availableStock >= this.quantity) {
      if (this.selectedProduct) {
    
        let item = {
          productVariationId: this.selectedProduct.id, // Assuming product id is needed
          productName: this.selectedProduct.MasterProduct.productName,
          measurement: this.selectedProduct.measurement + ' ' + this.selectedProduct.unitOfMeasurement,
          priceAtOrderTime: this.selectedProduct.price,
          selectedUnit: this.quantity,
          total: this.selectedProduct.price * this.quantity

        };

        let cartData1 = JSON.parse(localStorage.getItem('cartItems') || '[]'); // Retrieve existing cart items
        this.quantity = 1;

        // Check if the product is already in the cart
        let productId = cartData1.findIndex(el => el.productVariationId === this.selectedProduct.id);
        if (productId === -1) {
          // If not, add the new product to the cart
          cartData1.push(item);
        } else {
          // If it is, update the existing product's information
          cartData1[productId] = item;
        }

        // Save updated cart data to localStorage
        localStorage.setItem('cartItems', JSON.stringify(cartData1));

        // Update cart display or perform any necessary actions
        this.getCartItemsFromLocalStorage();
        this.closeDialog()

      } else {
        console.error("No product selected to add to cart.");
      }
      this.productAddedToCart.emit();
    } else {
      console.log("else is running ");
      // this.messageService.add({ severity: 'custom', detail: 'Please Select Gender' });
      this.snackBar.open('Cannot add item to cart. Available Stock is ' + this.availableStock, 'Close', {
        duration: 3000 // Duration in milliseconds
      });
      this.quantity = this.availableStock;
      
    }
  }



  getCartItemsFromLocalStorage() {
    if (localStorage.getItem('cartItems') !== null) {
      this.cartData1 = JSON.parse(localStorage.getItem('cartItems'));
      console.log("cart data", this.cartData1)
      for (let i = 0; i < this.cartData1.length; i++) {
        this.grandTotal = this.grandTotal + this.cartData1[i].total;
      }
    }
  }
  onClickActivateInventoryTab() {
    this.activateInventoryTab.emit();
  }

  onClickOrders() {
    console.log("onClickOrders");

    this.activateInventoryTab.emit();

    // this.router.navigate(['/marketplace/orders'])
  }

  getAllMasterProductVariations() {
    console.log(" getAllMasterProductVariations called");
    
    this.marketPlaceService.getAllMasterProductVariations()
      .subscribe((response) => {
        this.masterProductsVariations = new MatTableDataSource(response);
      })
  }

  showProduct(product) {
    console.log(product);
    let productVariationId = product.id
    this.router.navigate(['/marketplace/productDetails'], { queryParams: { productVariationId: productVariationId } })
  }




}
