
<div class=" col-lg-12" >
  <div class="dashboard-content">
     <div class="user-management-data">
      <div class="row ">
        <div class="col-xl-1 col-lg-1"><a routerLink="./../doctors/add" class="btn btn-primary opd-primary-btn transformNormal btn-lg add-button">Add</a></div>
        <div class="col-xl-11 col-lg-11 d-flex justify-content-end align-items-center pr-0">
          <div class="scheduleandAddNew-outer">
            <div class="scheduleandAddNew">
              <div class="appointment float-right searchIcon active">
                <div class="form-group searchField pull-left">
                  <input type="text" placeholder="Search by name, speciality, reg.number" class="form-control" #searchInput
                    [(ngModel)]="searchString" (keyup)="searchDoctors()" />
                    <a (click)="closeSearch()" *ngIf="searchString"><i class="fa fa-times-circle" aria-hidden="true"></i></a>
                </div>
                <div class="searchbtn">
                  <button (click)="searchDoctors()"><img src="assets/images/searchIcon.svg" alt="" /></button>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

      <table  id="dtVerticalScrollExample" class="table  table-fixed mt-3">
        <thead>
          <tr class="col-12 pl-0 pr-0">
            <th  class="col-1 d-flex justify-content-start align-items-center">#</th>
            <th  class="col-2 d-flex justify-content-start align-items-center">Name
                <div  class="topDown">
                    <i title="Ascending order" class="menu-caret fa fa-caret-up" [ngClass]="{'active': order[0] && order[0].indexOf('full_name') != -1 && order[0].indexOf('asc') != -1}" (click)="sort('User','full_name','asc')"></i>
                    <i title="Descending order" class="menu-caret fa fa-caret-down" [ngClass]="{'active': order[0] && order[0].indexOf('full_name') != -1 && order[0].indexOf('desc') != -1}" (click)="sort('User','full_name','desc')"></i>
                </div>
            </th>
            <th  class="col-1 d-flex justify-content-start align-items-center">Degree <a class="sort-icon"></a></th>
            <th  class="col-2 d-flex justify-content-start align-items-center">Speciality/Subspeciality<a class="sort-icon"></a></th>
            <th  class="col-1 d-flex justify-content-start align-items-center">Reg No
                <div  class="topDown">
                    <i title="Ascending order" class="menu-caret fa fa-caret-up" [ngClass]="{'active': order[0] && order[0].indexOf('registration_number') != -1 && order[0].indexOf('asc') != -1}" (click)="sort(null,'registration_number','asc')"></i>
                    <i title="Descending order" class="menu-caret fa fa-caret-down" [ngClass]="{'active': order[0] && order[0].indexOf('registration_number') != -1 && order[0].indexOf('desc') != -1}" (click)="sort(null,'registration_number','desc')"></i>
                </div>
            </th>
            <th  class="col-1 d-flex justify-content-start align-items-center">Gender<a class="sort-icon"></a></th>
            <th  class="col-2 d-flex justify-content-start align-items-center">State medical council</th>
            <th  class="col-1 d-flex justify-content-start align-items-center">Experience
                <div  class="topDown">
                    <i title="Ascending order" class="menu-caret fa fa-caret-up" [ngClass]="{'active': order[0] && order[0].indexOf('experience') != -1 && order[0].indexOf('asc') != -1}" (click)="sort(null,'experience','asc')"></i>
                    <i title="Descending order" class="menu-caret fa fa-caret-down" [ngClass]="{'active': order[0] && order[0].indexOf('experience') != -1 && order[0].indexOf('desc') != -1}" (click)="sort(null,'experience','desc')"></i>
                </div>
            </th>
            <!-- <th>Bio/Summary<a class="sort-icon"></a></th> -->
  					<th class="col-1 d-flex justify-content-start align-items-center">Action</th>
          </tr>
        </thead>
        <tbody *ngIf="!isLoading"  (scroll)="loadDoctors($event)">
          <!-- <tr *ngIf="userList.length === 0"><td class="notFound" colspan="9">No records found</td></tr> -->
          <tr class="notFound" *ngIf="userList.length === 0">No records found</tr>
          <tr class="col-12 pl-0 pr-0" *ngFor="let user of userList; let i = index">
            <td  class="col-1">{{ i + 1}}</td>
            <td  class="col-2">{{user.User.first_name}} {{user.User.last_name}} <i class="fa fa-circle" *ngIf="user.User.status == 'active'"></i></td>
            <td  class="col-1">{{degreeArray[user.degree]}}</td>
            <td  class="col-2">{{user.speciality}}/ {{user.sub_speciality}}</td>
            <td  class="col-1">{{user.registration_number}}</td>
            <td  class="col-1">{{user.User.gender | titlecase}}</td>
            <td class="col-2">{{councilsArray[user.state_medical_council]}}</td>
            <td class="col-1 "><span *ngIf="user.experience">{{user.experience}} Years</span></td>
            <!--<td class="text-center col-1">
              <span *ngIf="user.User.status == 'active'" class="text-info"><i class="fa fa-check"></i></span>
              <span *ngIf="user.User.status !== 'active'" class="text-info"><i class="fa fa-ban"></i></span>
            </td>-->
            <!-- <td>{{user.bio}}</td> -->
            <!-- <td>
              <span></span>
              <span><button class="btn btn-success">Verify</button></span>
            </td> -->
            <!-- <td>
              <span class="text-info"><i class="fa fa-check"></i></span>
              <span class="text-info"><i class="fa fa-ban"></i></span>
            </td> -->
            <td class=" col-1 ">
              <!-- <a routerLink="./../userUpdate/{{ user._id}}" title="Update"><i class="fa fa-edit" aria-hidden="true"></i></a> -->
              <a routerLink="./../doctors/view/{{ user.User.id}}" title="View"><i class="fa fa-eye mr-3" aria-hidden="true"></i></a>
              <a *ngIf="user.User.status == 'active'" title="Deactivate" (click) ="deactivate(user.User.id,'inactive')"><i class="fa fa-ban"></i></a>
              <a *ngIf="user.User.status != 'active'" title="Activate" (click) ="updateRecord(user.User.id,'active')"><i class="fa fa-check-circle"></i></a>

            </td>

          </tr>
        </tbody>

      </table>
      <div class="col-12 d-flex justify-content-end align-items-center pr-0 font12">Displaying {{totalRecords}} records</div>
  </div>

</div>
</div>
<p-confirmDialog appendTo="body"></p-confirmDialog>
