import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-cutom-card',
  templateUrl: './cutom-card.component.html',
  styleUrls: ['./cutom-card.component.css']
})
export class CutomCardComponent implements OnInit {
  @Input() callBack: (arg) => void;
  @Input() public item: string;
  @Input() public selected: boolean;
  @Input() public styleflag: boolean;
  @Input() public specialVisitType: number
  @Input() passValue: any = [];
  // passSelectedValue=[];
  constructor() { }

  ngOnInit(): void {
    //console.log("may8",this.item,this.specialVisitType)

  }
  // onClickButton(event) {
  //   console.log("event",event)
  //   this.onClick(event);
  // }
  inputText(label) {

    this.callBack(this.item);
    console.log("item", this.item)
    //this.searchString=""
  }

  onPress(item) {
    if (!this.selected) {
      console.log('Pressed', item);
      // this.callBack(item);
    }
  }
}
