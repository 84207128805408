<div class="container-fluid home-wrapper">
  <div class="row">
    <div class="col-12">
      
      <header class="row header-wrapper" style="height: 150px;">
        <div id="divlogo" style="display: flex; justify-content: space-between; width: 100%;height: 150px;">
          <div class="col-4 col-lg-3 col-xl-3 logo-section d-flex justify-content-center align-items-center"
            style="display:flex; justify-content:center; align-items: center;">
            <img class="logo" src="./../../../assets/images/logo1.svg">
            <h4>Copious</h4>
          </div>

          <div id="headertitle" class="col-6 col-lg-6 col-xl-6 header-content-section"
            style=" display: flex; justify-content: flex-end; padding-right:16px">
            <h6 style="font-size: 24px;">OPD Management made easy for Doctors</h6>
          </div>
        </div>
      </header>

   
      <header id="div-home-header-desktop" class="row sub-header" style="height: 100px;">
        <div class="col-12" style="display: flex; align-items: center; justify-content: space-between;">

          

          <div style=" height: 90px; padding:16px">
            <h6 style="color: #fff; font-size: 18px; text-align: start;">&#10004; Appointment Scheduling</h6>
            <h6 style="color: #fff; font-size: 18px; text-align: start;">&#10004; Easy Online Billing</h6>
          </div>

          <div style=" height: 90px; padding:16px">
            <h6 style="color: #fff; font-size: 18px; text-align: start;">&#10004; Tele-Medicine with Video Calls</h6>
            <h6 style="color: #fff; font-size: 18px; text-align: start;">&#10004; Web (Browser) Application</h6>
          </div>

          <div style=" height: 90px; padding:16px">
            <h6 style="color: #fff; font-size: 18px; text-align: start;">&#10004; Electronic Prescription</h6>
            <h6 style="color: #fff; font-size: 18px; text-align: start;">&#10004; Mobile Application</h6>
          </div>

          <div>
            <button class="btn-primary opd-secondary-btn btn-md btn-login" style="margin-top: 22px;"
              (click)="opdSignIn();">
              Doctor Login
            </button>
          </div>
        </div>
      </header>

      <!-- Content section (start here) for Mobile View -->
      <section class="row content-wrapper">
        <div id="div-home-header-mobile"
          style="font-size: 15px; margin-bottom: -130px; align-self: center; width: 100%;">
          <p style="padding: 16px; font-size: 22px;">OPD Management made easy for Doctors</p>
          <p style="font-size: 14px;">&#10004;Appointment Scheduling &nbsp; &#10004;Tele-Medicine with Video calls
            <br>&#10004;Electronic Prescription &nbsp; &#10004;Easy Online Billing
            <br>&#10004;Web(browser) application &nbsp; &#10004;Mobile App
          </p>
          <br>
         
        </div>
      
      
   
        <div class="col-12 dashboardWrapper" style=" display: flex; flex-direction: column; height: 80vh;"
        *ngIf="cliniciansFamilySection">
        <div id="divcard" class="row"
          style=" width: 60%; display: block; justify-content: center; align-self: center; padding:10px; border-radius: 6px; box-shadow: 2px 2px 6px 6px #eee;">
          <div class="col-12" style="display: flex; flex-direction: column;">
            <h2 id="mobile-header-lng" style="font-size: 26px; margin-top: 10px;  color:#585858; font-weight: bold;">
              Are you a Doctor or
              Clinician? Patients, want to refer your Doctor?<h2 id="mobile-header-lng"
                style="margin-top: 3px; font-size: 24px; color: #ccc; font-weight: bold;">आपण डॉक्टर आहत का? तुमच्या
                डॉक्टरांची ओळख करून द्यायची आहे?</h2>
            </h2>
          

            <div #divDoctorInfo *ngIf="doctorForm" style="margin-top: 0px; margin-bottom: 30px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              ">


              <div id="formdoctordivinputs" class="form-group animateLabel"
                style="display: flex;flex-direction: row; " *ngIf="mobNo">

                <div style="display: flex; flex-direction: column;">
                  <label class="newInputStyleLabel">Doctor's Name<span
                      style="color: red; font-size: 18px;">*</span></label>
                  <input style="width: 260px; border-radius: 4px;" #docNameInput type="text"
                    placeholder="Enter doctor's name" class="form-control  newInputBox"
                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)" [(ngModel)]="docName">

                </div>
                &nbsp;&nbsp;&nbsp;

                <div style="display: flex; flex-direction: column;">
                  <label id="labeldocnumber" class="newInputStyleLabel" style="margin-left:0px !important;">Doctor's
                    Contact Number<span style="color: red; font-size: 18px;">*</span></label>
                  <input #phoneNumber style="width: 260px;border-radius: 4px;" type="text"
                    placeholder="Enter doctor's contact" class="form-control  animateInput"
                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)" [(ngModel)]="docphoneNumber">

                </div>

              </div>

              <span id="spanfootertext"
                style="width: 530px; margin-top: -20px; margin-bottom: -8px; font-size: 16px; color: #585858;  text-align: start; align-self: center;">In
                case you are referring your doctor</span>

              <div id="formuserdivinputs" class="form-group animateLabel"
                style="margin-bottom: 16px;display: flex; flex-direction: row;">

                <div style="display: flex; flex-direction: column; ">
                  <label class="newInputStyleLabel">Your Name</label>
                  <input style="width: 260px; border-radius: 4px;" #docNameInput type="text"
                    placeholder="Enter your name" class="form-control  animateInput" (focus)="addLabelClass($event)"
                    (blur)="removeLabelClass($event)" [(ngModel)]="userName">

                </div>

                &nbsp;&nbsp;&nbsp;

                <div style="display: flex; flex-direction: column; ">
                  <label id="labelyournumber" class="newInputStyleLabel" style="margin-left: 0px !important;">Your
                    Number</label>
                  <input #phoneNumber style="width: 260px;border-radius: 4px;" type="text"
                    placeholder="Enter your number" class=" form-control  animateInput"
                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)" [(ngModel)]="userPhoneNumber">


                </div>

              </div>

              <button type="button" class="opd-primary-btn btn-md w125 login-btn upprcase newBtnStyle"
                (click)="sendDocDetaillsBtn($event)">Submit</button>
            </div>

            <div #divSuccessfulReach style="border: 1px solid #3CDC96;
            width: 400px;
            font-size: 18px;
            font-weight: 500;
            padding: 16px;
            border-radius: 8px;
            background-color: #E7FEF4;
            align-self: center;
            display:none;
            ">Thank you for reaching out, We will revert to you as soon as possible!</div>


           

          </div>

        </div>

        <div style="margin-top: 16px; bottom: 0; align-self: center;">
          <div>
            <span id="spanfootertext" style="margin-bottom: 0px; font-size: 20px;">In case of any queries you may
              reach us via Phone, SMS
              or Whatsapp at<span id="spanfootertext"
                style="color: #1D5AA9; font-size: 20px;">9372226222</span></span>
          </div>

          <span id="spanfootertext" style="font-size: 20px;">For Patients and Parents we also have specialized mobile
            apps </span>
        </div>

        <div style="display: block; flex-direction: row; justify-content: center;">
          <a style="margin-top: 0px;" href="https://apps.apple.com/us/app/health-by-copious/id1475776393?ls=1"
            target="_blank" class="mr-1"><img style="height:30px;" src="assets/images/Download_on_App_Store.png"
              alt="" /></a>
          <a style="margin-top: 0px;" href="https://play.google.com/store/apps/details?id=com.calculator_parent_apps"
            target="_blank" class="ml-1"><img style="height:30px;" src="assets/images/download_on_google_play.png"
              alt="" /></a>
        </div>

      </div>  

        <!-- Login (start here) -->
      

        <!-- Pawword (start here) -->
        <div class="col-12 password-wrapper passwordSection" *ngIf="passwordSec">
          <h1 class="heading-text">enter password to login in your account</h1>
          <h6 class="email-id mb-4 pb-1"></h6>
          <div class="login-wrapper">
            <form>
              <div class="form-group animateLabel">
                <input (keydown.space)="$event.preventDefault()" type="password" class="form-control animateInput"
                  (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                <label>Enter Password</label>
                <a routerLink="authentication/forgot" class="forgot-password">Forgot password?</a>
              </div>
              <button type="submit" class=" opd-primary-btn btn-md w125 login-btn upprcase newBtnStyle"
                (click)="passwordLoginBtn()">Next</button>
            </form>
          </div>
        </div>

        <!-- OPT (start here) -->
        <div class="col-12 otp-wrapper password-wrapper optSec" *ngIf="optSec">
          <h1 class="heading-text">enter OTP to login in your account</h1>
          <h6 class="email-id"></h6>
          <p class="otp-sent">We have sent an OTP to your registered number XXX-XXX-X413</p>
          <div class="login-wrapper">
            <form>
              <div class="form-group onFocus">
                <label>Enter OTP</label>

                <div class="row">
                  <div class="col-2 pr-0">
                    <input (keydown.space)="$event.preventDefault()" type="password" maxlength="1"
                      class="form-control otp-textbox" placeholder="*" onfocus="if(placeholder=='*'){placeholder=''}"
                      onblur="if(placeholder==''){placeholder='*'}">
                  </div>
                  <div class="col-2 pr-0">
                    <input (keydown.space)="$event.preventDefault()" type="password" maxlength="1"
                      class="form-control otp-textbox" placeholder="*" onfocus="if(placeholder=='*'){placeholder=''}"
                      onblur="if(placeholder==''){placeholder='*'}">
                  </div>
                  <div class="col-2 pr-0">
                    <input (keydown.space)="$event.preventDefault()" type="password" maxlength="1"
                      class="form-control otp-textbox" placeholder="*" onfocus="if(placeholder=='*'){placeholder=''}"
                      onblur="if(placeholder==''){placeholder='*'}">
                  </div>
                  <div class="col-2 pr-0">
                    <input (keydown.space)="$event.preventDefault()" type="password" maxlength="1"
                      class="form-control otp-textbox" placeholder="*" onfocus="if(placeholder=='*'){placeholder=''}"
                      onblur="if(placeholder==''){placeholder='*'}">
                  </div>
                  <div class="col-2 pr-0">
                    <input (keydown.space)="$event.preventDefault()" type="password" maxlength="1"
                      class="form-control otp-textbox" placeholder="*" onfocus="if(placeholder=='*'){placeholder=''}"
                      onblur="if(placeholder==''){placeholder='*'}">
                  </div>
                  <div class="col-2 pr-0">
                    <input (keydown.space)="$event.preventDefault()" type="password" maxlength="1"
                      class="form-control otp-textbox" placeholder="*" onfocus="if(placeholder=='*'){placeholder=''}"
                      onblur="if(placeholder==''){placeholder='*'}">
                  </div>
                </div>

                <a class="forgot-password">Resend OTP?</a> <span>if you don't receive it in 2 mins.</span>
              </div>
              <button type="button" class="opd-primary-btn btn-md w125 login-btn upprcase newBtnStyle"
                routerLink="/doctor/homepage">Login</button>
            </form>
          </div>
        </div>
      </section>
     <div>
     
    </div>
    </div>
    
  </div>
</div>

