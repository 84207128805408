<div  class="searchBox"  [style.width.%]="width" [style]="stylesFor" 
style="width: 500px; display: flex;flex-direction: row; padding: 6px">

<!-- <input type="text" [ngStyle]="styles"  [placeholder]="placeholder" [style]="styles" [value]="langtextboxvalue"  [resultTemplate]="searchDrugResultFormatterTemplate" [(ngModel)]="languageText" [ngbTypeahead]="searchLanguageByTyping"
[inputFormatter]="formatMatches" [resultFormatter]="formatter"  (keyup)="languageTranslatore($event,languageText)" (selectItem)="selectedItem($event)"
style="padding: 3px; border: none; outline: none; width:400px;background-color: #fff;" > -->
<input type="text" [ngStyle]="styles" name="languageText" ngDefaultControl [placeholder]="placeholder" [style]="styles" [value]="langtextboxvalue" [resultTemplate]="searchDrugResultFormatterTemplate" [(ngModel)]="languageText" [ngbTypeahead]="searchLanguageByTyping" [inputFormatter]="formatMatches" [resultFormatter]="formatter" (keyup)="languageTranslatore($event,languageText); onInputChange();" (selectItem)="selectedItem($event)" style="padding: 4px; margin-left: 16px; border: none; outline: none; width:400px;background-color: #fff; border-radius: 16px;" (input)="onInputChange();languageTranslatore($event,languageText);">

<div 
  style="font-size: 16px; z-index: 1; border-top-right-radius: 8px; border-bottom-right-radius: 8px; width: 17%; height: 100%; display: flex; justify-content: flex-end; align-items: center; color: #1F3042; font-weight: 500; cursor: pointer; ">

  <div  style="font-size: 10px; margin-top: 0px;">
 
   <mat-form-field class="dropDownUi" appearance="none">
    
     <mat-select  [value]="lanuageForName"
       required (selectionChange)="onDropdownSelectLanguage($event.value)">
       <mat-option style="text-align: center;" *ngFor="let item of selectedLanguage" [value]="item.name">{{item.name}}</mat-option>
     </mat-select>
   
   </mat-form-field>
 </div>
</div>


</div>

   <ng-template #searchDrugResultFormatterTemplate let-r="result" let-t="term">
     <ngb-highlight style="font-weight: bold;" [result]="r" [term]="t"></ngb-highlight>
    
   </ng-template>