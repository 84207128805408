import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DoctorService } from '../services/doctor.service';
import { DeviceDetectorService } from 'ngx-device-detector';

import { DomSanitizer, } from '@angular/platform-browser';
import { Constants } from '../../config/constants';
@Component({
  selector: 'app-doctor-public-profile',
  templateUrl: './doctor-public-profile.component.html',
  styleUrls: ['./doctor-public-profile.component.scss']
})
export class DoctorPublicProfileComponent implements OnInit {
  doctorName:string=""
  
  email_id:any=""
  userId
  mobile:boolean = false;
  mobileNumber:any=""
  degree:any=""
  experience:any=""
  speciality:any=""
  clinicAdress:any=[]
  profileImagePath = Constants.APICONFIG.doctorProfileImagePath;
  doctorLocationLink:any=""
  profileImage = null;


  constructor(private sanitizer: DomSanitizer, private doctorService:DoctorService,route: ActivatedRoute, private deviceService:DeviceDetectorService) { 
    route.params.subscribe((params) => {
      this.userId = params["id"];
    });
  }

  ngOnInit(): void {

    if (this.deviceService.isMobile()) { // 768px portrait
      this.mobile = true;
      console.log("jan9",this.mobile)
    }

    this.getDoctorProfile()
  }

   getDoctorProfile(){
    var params = {
      "_id": this.userId
    }
    this.doctorService.doctorPublicProfile(params).subscribe(response => {
      if(response){
        this.doctorName=response.doctor.User.first_name +" "+ response.doctor.User.last_name
        this.email_id=response.doctor.User.email_id
        this.mobileNumber=response.doctor.User.phone_number
        this.degree=response.doctor.degree
        this.experience=response.doctor.experience
        this.speciality=response.doctor.speciality
        this.profileImage = response.doctor.User.profile_photo
        ? `${this.profileImagePath}${response.doctor.User.profile_photo}`
        :
        response.doctor.User.gender.toLowerCase() == 'male' ?
          "assets/images/doctor_placeholder_male.svg"
          :
          "assets/images/doctor_placeholder_female.svg";
        this.clinicAdress=response.clinics[0]
        var services=JSON.parse(response.doctor.adminservices)
      // this.doctorLocationLink=services
       this.doctorLocationLink= this.sanitizer.bypassSecurityTrustHtml(services.profile_location_link);
     
      }

    })
   }
  

}
