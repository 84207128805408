import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-doctor-reports-component',
  templateUrl: './doctor-reports-component.component.html',
  styleUrls: ['./doctor-reports-component.component.css']
})
export class DoctorReportsComponentComponent implements OnInit {
  
  constructor() { }

  ngOnInit(): void {
    
  }


}
