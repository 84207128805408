import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { PatientService } from '../../../services/patient.service';
import * as _ from "lodash"
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialog } from '@angular/material/dialog';
import { refreshService } from '../../../services/refersh.service'
import { P } from '@angular/cdk/keycodes';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-patient-history',
  templateUrl: './patient-history.component.html',
  styleUrls: ['./patient-history.component.css']
})
export class PatientHistoryComponent implements OnInit {
  @Input() patientPrescription: any;
  @Input() patientAllDocumnets: any;
  @Input() patientId: any;
  @ViewChild("viewDocumentsModal") viewDocumentsModal;
  reportsAll:any=[]
  covidAllReports:any=[]
  allDocuments: any = []
  allRequisition:any=[];
  allPrescription: any = [];
  allPatientCertificate: any = [];
  patientCertificate:any
  allReports: any = []
  covidReports: any = [];
  documentPdfURL: any = ""
  documentURL: any = ""
  pramPatientId:any;
  drString: string = "";
  searchPatientHistory: string
  moment: any = moment;
  allDischarge:any=[]
  displayedColumns: string[] = ['sr no', 'uploaded by', 'date', 'document type', 'preview',];

  constructor(public refreshservice: refreshService, private patientService: PatientService, private sanitizer: DomSanitizer, public dialog: MatDialog, private route:ActivatedRoute) {

  }

  ngOnInit(): void {
   
    this.route.queryParams.subscribe(params => {
      this.pramPatientId=params.patientId ? params.patientId:null
    })
  

   console.log("all",this.patientAllDocumnets)
   this.getAllDocumentsOfPatient();
   this.refreshAllDocuments();
   setTimeout(()=>{
   
    this.getAllDoctorPrescriptions();
   },500)
 
  }

  getAllDocumentsOfPatient = (optionalParam = 1) => {
    var postData = {
      patient_id: this.pramPatientId,
    }
    console.log("getAllDocumentsOfPatient postData", postData);
    
    this.patientService.getAllDocumentsOfPatient(postData).subscribe(response => {

      if (response) {

        for (let index = 0; index < response.documents.length; index++) {

          const element = response.documents[index];
          //  const docDate=response.documents[index].documentDate
          var dateTime = moment(response.documents[index].documentDate).format('YYYY-MM-DD');
          // console.log("docDatee", dateTime);
          let today = new Date(element.createdAt)
          let month = null
          month = today.getMonth() + 1
          if (month < 10) {
            { month = '0' + month }
          }
          element.createdAt = `${today.getDate()}/${month}/${today.getFullYear()}`

        }
        this.patientAllDocumnets = response.documents;
      }
    })
  }

  getAllDoctorPrescriptions = () => {


    var obj = {
      "patientId": this.patientId? this.patientId:this.pramPatientId,
      "isForHistory": true,
    }
    console.log("getAllDoctorPrescriptions obj", obj);
    
    var arr = [];
    var arrNotes = [];
    var arrinstr = [];
    var arrivest = [];

    this.patientService.getAppointmentsByPatientId(obj).subscribe(response => {
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
      if (response.success) {
        if (response.historyArr.length) {
          for (let index = 0; index < response.historyArr.length; index++) {
            const element = response.historyArr[index];
            var obj: any = {}
            obj.createdAt = element.from;
            obj.from = element.from;
            obj.to = element.to;
            obj.id = element.id;
            obj.docName = element.Doctor.User?.first_name + " " + element.Doctor.User?.last_name;
            //  this.docName = obj.docName;
            obj.Visit = element.Visit && element.Visit != null ? element.Visit : {};
            obj.Medicines = element.Visit && element.Visit != null ? element.Visit.Medicines && element.Visit.Medicines.length > 0 ? element.Visit.Medicines : null : null;
            obj.prescriptionPDFURL = element.prescriptionPDFURL && element.prescriptionPDFURL != null ? element.prescriptionPDFURL : null;
            obj.Certificates = element.Certificates && element.Certificates != null ? element.Certificates : null;
            if (element.Visit && element.Visit != null) {
              //additionalInfo.newBornScreening ? additionalInfo.newBornScreening : null;
              obj.chief_complaints = element.Visit.chief_complaints && element.Visit.chief_complaints != null ? element.Visit.chief_complaints : ''
              if (element.Visit.prescription_instruction && element.Visit.prescription_instruction != null) {
                var note: any = {};
                note.date = `${days[new Date(element.from).getDay()]}, ${moment(element.from).format('DD MMM YYYY')}`
                note.note = element.Visit.prescription_instruction;
                note.docName = element.Doctor.User.first_name + " " + element.Doctor.User.last_name;
                note.visitId = element.VisitId;
                arrNotes.push(note);
              }

              if (element.Visit.general_instruction && element.Visit.general_instruction != null) {
                var instruction: any = {};
                instruction.value = `${days[new Date(element.from).getDay()]}, ${moment(element.from).format('DD MMM YYYY')}`
                instruction.instruction = element.Visit.general_instruction;
                arrinstr.push(instruction);
              }

              if (element.Visit.suggested_investigation && element.Visit.suggested_investigation != null) {
                var investigation: any = {};
                investigation.value = `${days[new Date(element.from).getDay()]}, ${moment(element.from).format('DD MMM YYYY')}`
                investigation.investigation = element.Visit.suggested_investigation;
                arrivest.push(investigation);
              }
            }
            arr.push(obj);

          }
          this.patientCertificate = arr.reverse();
          this.refreshAllDocuments();
          // arrNotes = _.uniqBy(arrNotes, 'visitId');

          // this.notesArr = arrNotes.reverse();
        }

      }
    });
  }

  refreshAllDocuments() {
    var arr = [];
    var certificateArr: any = []
    for (let index = 0; index < this.patientPrescription.length; index++) {
      const element = this.patientPrescription[index];
      var objDocument: any = {}
      if (element.prescriptionPDFURL != null) {
        objDocument["documentDate"] = element.from
        objDocument["documentType"] = "Prescription"
        objDocument["uploadedBy"] = element.docName
        objDocument["url"] = element.prescriptionPDFURL
        arr.push(objDocument);
      }

    }
    this.allPrescription = arr;

    if (this.patientCertificate) {
      for (let index = 0; index < this.patientCertificate.length; index++) {
        const element = this.patientCertificate[index];

        if (element.Certificates != null) {
          var certificate: any = element.Certificates
          certificate = JSON.parse(certificate)

          console.log("certificateRefer", certificate)

          certificate.map(ins => {

            if (ins.url && ins.type != 'Receipt') {
              var objDocument: any = {}
              objDocument["documentDate"] = element.from
              objDocument["documentType"] = ins.type
              objDocument["uploadedBy"] = element.docName
              objDocument["url"] = ins.url
              certificateArr.push(objDocument)
            }
          })
        }
      }
    }
    console.log("allCert", certificateArr)
    this.allPatientCertificate = certificateArr
  
    this.allDocuments = certificateArr.concat(arr,this.patientAllDocumnets)
    // this.allDocuments=this.patientAllDocumnets


    // arr=certificateArr.concat(arr)
    // this.allDocuments= arr.concat(this.patientAllDocumnets)
    console.log("all", this.allDocuments)
    this.allDocuments.map(ins => {
      if (ins.documentType == "Covid Vaccine Certificate") {
        this.drString = ""
        //this.covidReports.push(ins);
        this.covidAllReports.push(ins)
      }

      if (ins.documentType=="Lab Report") {
        // this.drString = ""
        // if (ins.uploadedBy == "email") {
        //   ins['uploadedBy'] = "Lab email Id";
        // }
        // else {
        //   ins['uploadedBy'] = ins.notes;
        // }
        this.allReports.push(ins)
      }
      if(ins.documentType=="Requisition"){
        this.allRequisition.push(ins)
      }
      console.log("may4Req",this.allRequisition)

      // if(ins.documentType=='Prescription'){
      //   this.drString=""
      // this.allPrescription.push(ins)
      // }
      if (ins.tests == 'Patient Discharge Summery') {
        ins.documentType="Discharge Summary"
        this.allDischarge.push(ins)

      }
     })

    this.allDocuments.map(ins => {
      return this.allDocuments.sort((a, b) => {
        return <any>new Date(b.documentDate) - <any>new Date(a.documentDate);
      });
    })
    console.log("allDoc", this.allDocuments)
    this.allPrescription = new MatTableDataSource(this.allPrescription)
    //this.allReports=new MatTableDataSource(this.allReports)
    this.allDocuments = new MatTableDataSource(this.allDocuments)


  }
  tabClick(event){
    console.log("Nov16",event)

    if(event.index==2){
      this.allDocuments.filteredData.map(ins => {
        if (ins.documentType=="Lab Report") {
          this.allReports.push(ins)
        }
       
      })
    }
    if(event.index==5){
      this.allDocuments.filteredData.map(ins => {
       
        if(ins.documentType=="Requisition"){
          this.allRequisition.push(ins)
        }
      })
    }
  }
  openDialog(content, index) {
    this.dialog.open(content);
  }
  closeDialog() {
    this.dialog.closeAll()
  }
  onClickViewDocuments(document) {
    let url = document.url?document.url:document.photoURL;
    // document.url for  and document.photoURL for pivotal 
    this.documentPdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);
    this.documentURL = document.url;
    this.openDialog(this.viewDocumentsModal, 'viewDocumentsModal');
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches

    // Repeatitive work
    this.allDocuments.filter = filterValue;
    this.allPrescription.filter = filterValue;
    this.allReports.filter = filterValue;
    this.covidReports.filter = filterValue
  }

  onSearchPatientHistory = (event: Event) => {
    this.applyFilter(this.searchPatientHistory);
  }
  reverseDate(date) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }
  parseDate(dateObject) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }
 

}
