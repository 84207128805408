<div class=" col-lg-7 grid-view"  >
  <h3> Add New Lab</h3>
  <ul class="breadcrumb breadcrumb-page admin-breadcrumb-page">
      <div class="breadcrumb-label text-light-gray">You are here: </div>
          <div class="breadcrumbs admin-breadcrumbs">
              <a routerLink="/admin/settings/labs">Lab Management</a> » <span>Add</span>
          </div><!-- breadcrumbs -->
  </ul>

  <div class="col-lg-12" >
  
    <form method="post">
        <div class="form-group animateLabel">
            <input type="text" class="form-control animateInput capitalize" name="name" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)" [(ngModel)]="lab.name" (keyup.enter)="addLab()" maxlength="50" required>
            <label [ngClass]="{'animatedLabel': lab.name}">Name <span class="required">*</span></label>
            <p class="errors" *ngIf="errors.name">{{errors.name}}</p>
        </div>
    
        <div class="form-group animateLabel">
            <input type="text" class="form-control animateInput" name="email" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)" [(ngModel)]="lab.email_id"  required>
            <label [ngClass]="{'animatedLabel': lab.email_id}">Email<span class="required">*</span></label>
            <p class="errors" *ngIf="errors.email">{{errors.email}}</p>
          </div>
     
    
      <div class="form-group button-group">
        <button type="submit" class="btn-primary opd-primary-btn btn-md" (click)="addLab()">Save</button>
        <button type="button" class="btn-primary opd-secondary-btn btn-md ml-2" (click)="goBack()">Cancel</button>
      </div>
    </form>
  </div>
</div>