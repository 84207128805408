<div style="margin-top: 15px;display: flex;justify-content: center; align-content: center; height: 100%;">
    <mat-horizontal-stepper [@.disabled]="true" linear #stepper>
       <mat-step>
           <ng-template matStepLabel>Profile</ng-template>
           <div style="display: flex; justify-content: center;align-items: center; flex-direction: column;">
          <div style="border: 1px solid #acacac;height: 100%;border-radius: 6px; width: 50vw;
          padding: 15px 21px; display: flex; flex-direction: column; justify-content: center; ">
             <div style="display:flex;flex-direction: row; justify-content: space-between;">
               <div style="font-size: 18px; font-weight: 600;">Profile</div>
               <div style="margin-top: 0px;">
                <button (click)="onSignUp();" mat-raised-button style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;font-weight: bold;">Save & Next </button>
            </div>
           </div>
           <div>
            <mat-checkbox (change)="isEnabledDoctor($event.checked)" [checked]="true" >Enable Doctor</mat-checkbox>
        </div>
           <div style="display:flex;flex-direction: row; justify-content: space-between; ">
               <div style="font-size: 16px; ">Type</div>
            </div>
            <div style="display:flex;flex-direction: row; justify-content: space-between; ">

              <mat-radio-group aria-labelledby="example-checkbox-group-label" class="example-section">
                <mat-radio-button class="example-margin" *ngFor="let typeDoc of doctorTypeArray" [value]="typeDoc" [checked]="typeDoc.checked"
                  (change)="isDoctorTypeSelect($event,typeDoc)">
                  {{typeDoc.name}}</mat-radio-button>
              </mat-radio-group>
            <!-- <mat-radio-group  aria-label="Select an option" >
             
               <mat-radio-button value="1" [checked]="true" >Doctor</mat-radio-button>
               <mat-radio-button value="2">Asha</mat-radio-button>
               <mat-radio-button value="3">Lab</mat-radio-button>
             </mat-radio-group> -->
             </div>
   
               <div style="display:flex;flex-direction: row; justify-content: space-between;  ">
                   <mat-form-field appearance="outline" style="width: 48%;">
                     <mat-label>Doctor's First Name</mat-label>
                     <input matInput placeholder="Enter Doctor's First Name" [(ngModel)]="firstName" maxlength="25" >
                   </mat-form-field>
                   <mat-form-field appearance="outline" style="width: 48%;">
                     <mat-label> Doctor's Last Name</mat-label>
                     <input matInput placeholder="Enter Doctor's Last Name" [(ngModel)]="lastName" maxlength="25">
                   </mat-form-field>
               </div>
             
               <div style="display:flex;flex-direction: row; justify-content: space-between;  ">
                   <mat-form-field appearance="outline" style="width: 48%;">
                     <mat-label>Mobile Number</mat-label>
                     <input matInput placeholder="Enter Mobile Number" (keypress)="validateNumber($event)" [(ngModel)]="mobileNumber" maxlength="10" >
                   </mat-form-field>
                   <mat-form-field appearance="outline" style="width: 48%;">
                     <mat-label> Registration Number</mat-label>
                     <input matInput placeholder="Enter Registration Number" [(ngModel)]="registrationNumber"  maxlength="25">
                   </mat-form-field>
               </div>
   
               <div style="display:flex;flex-direction: row; justify-content: space-between; ">

                <mat-form-field style="width: 47%;" appearance="outline">
                  <mat-label style="font-size: 15px;">Gender</mat-label>
                  <mat-select [value]="gender" (selectionChange)="onDropdownSelectTemplate($event.value)">
                    <mat-option *ngFor="let item of genderArray"  [value]="item.name">
                      {{item.name}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
                   <!-- <mat-form-field appearance="outline" style="width: 48%;">
                     <mat-label>Gender</mat-label>
                     <input matInput placeholder="Enter Gender" [(ngModel)]="gender" maxlength="6" >
                   </mat-form-field>-->
                   <mat-form-field appearance="outline" style="width: 48%;">
                     <mat-label> Email Id</mat-label>
                     <input matInput placeholder="Enter Email Id" [(ngModel)]="emailId"  maxlength="25">
                   </mat-form-field> 
               </div>
            
               <div style="display:flex;flex-direction: row; justify-content: space-between;">

                <mat-form-field style="width: 47%;" appearance="outline">
                  <mat-label style="font-size: 15px;">Language</mat-label>
                  <mat-select style="font-size: 16px;" [value]="doctorSelectedLang"
                  (selectionChange)="onDropdownSelectLanguage('language',$event.value)">
                  <mat-option style="font-size: 16px;" *ngFor="let language of languageArray" [value]="language.name">
                    {{language.name}}
                  </mat-option>
                </mat-select>
                </mat-form-field>

                <mat-form-field appearance="outline" style="width: 48%;">
                  <mat-label> Microcluster</mat-label>
                  <input matInput placeholder="Enter Microcluster" [(ngModel)]="microcluster"  maxlength="25">
                </mat-form-field>
               </div>
           </div>
          
   
         
         
       </div>
      
           </mat-step>
           
           <mat-step>
               <ng-template matStepLabel>Clinic Setup</ng-template>
   
               <div style="display: flex; justify-content: center;align-items: center; flex-direction: column;">
                 
                    <div style="overflow:hidden; border: 1px solid #acacac;height: 100%;border-radius: 6px; width: 60vw;
                   padding: 15px 21px;  margin-top: 15px; ">
                       
                       <div style="display:flex;flex-direction: row; justify-content: space-between;">
                        <div style="font-size: 18px; font-weight: 600;">Clinic Setup</div>
                        <div style="margin-top: 0px;">
                          <button (click)="saveClinics()" mat-raised-button style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;font-weight: bold;">Save & Next </button>
                      </div>
                    </div>
                       <div class="salutationSpecialization stage2" *ngFor="let clinic of clinicArray; let cIndex = index" id="{{cIndex}}">
                           <div class="clinic">
                             <div class="row">
                               <div class="col-12">
                                 <label class="ifnoLabel ifnoDetail">Clinic {{cIndex+1}}</label>
                               </div>
                               <div class="col-12">
                                 <div class="row fieldset">
                                   <div class="col-6">
                                     <div class="row">
                                       <div class="col-6">
                                         <div class="form-group">
                                           <div class="animateLabel">
                                             <input type="text" placeholder=" Clinic Name" class="form-control animateInput capitalize" name="name"
                                               [(ngModel)]="clinicArray[cIndex].name" (focus)="addLabelClass($event)"
                                               (blur)="removeLabelClass($event)" maxlength="100">
                                             <label [ngClass]="{'animatedLabel': clinicArray[cIndex].name != null}"></label>
                                           </div>
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                               <div class="col-12">
                                 <div class="row fieldset">
                                   <div class="col-6">
                                     <div class="row">
                                       <div class="col-6">
                                         <div class="form-group">
                                           <div class="animateLabel">
                                             <input type="text" placeholder="Address Line1" class="form-control animateInput capitalize" name="address"
                                               [(ngModel)]="clinicArray[cIndex].address_line1" (focus)="addLabelClass($event)"
                                               (blur)="removeLabelClass($event)" maxlength="50">
                                             <label [ngClass]="{'animatedLabel':clinicArray[cIndex].address_line1 != null}"></label>
                                           </div>
                                         </div>
                                       </div>
                                       <div class="col-6">
                                         <div class="form-group">
                                           <div class="animateLabel">
                                             <input type="text" placeholder="Address Line2" class="form-control animateInput capitalize" name="address"
                                               [(ngModel)]="clinicArray[cIndex].address_line2" (focus)="addLabelClass($event)"
                                               (blur)="removeLabelClass($event)" maxlength="50">
                                             <label [ngClass]="{'animatedLabel':clinicArray[cIndex].address_line2 != null}">
                                               </label>
                                           </div>
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                   <div class="col-6">
                                     <div class="row">
                                       <div class="col-6">
                                         <div class="form-group">
                                           <div class="animateLabel">
                                             <input type="text" placeholder="Area /Landmark" class="form-control animateInput capitalize" name="address"
                                               [(ngModel)]="clinicArray[cIndex].address_line3" (focus)="addLabelClass($event)"
                                               (blur)="removeLabelClass($event)" maxlength="50">
                                             <label [ngClass]="{'animatedLabel':clinicArray[cIndex].address_line3 != null}"></label>
                                           </div>
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                               <div class="col-12">
                                 <div class="row fieldset">
                                   <div class="col-6">
                                     <div class="row">
                                       <div class="col-6">
                                         <div class="form-group">
                                           <div class="animateLabel">
                                             <input type="text" placeholder="City" class="form-control animateInput capitalize" name="city"
                                               [(ngModel)]="clinicArray[cIndex].city" (focus)="addLabelClass($event)"
                                               (blur)="removeLabelClass($event)" maxlength="20"
                                               onkeypress="return (event.charCode > 64 &&
                                                   event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9)">
                                             <label [ngClass]="{'animatedLabel':clinicArray[cIndex].city != null}"></label>
                                           </div>
                                         </div>
                                       </div>
                                       <div class="col-6">
                                         <div class="form-group">
                                           <div class="animateLabel">
                                             <input [textMask]="{mask: pinMask,guide:false}" placeholder="Pincode" type="text" class="form-control animateInput"
                                               name="pincode" [(ngModel)]="clinicArray[cIndex].pincode" (focus)="addLabelClass($event)"
                                               (blur)="removeLabelClass($event)" maxlength="6">
                                             <label [ngClass]="{'animatedLabel':clinicArray[cIndex].pincode != null}"></label>
                                             <p class="errors" *ngIf="clinicArray[cIndex].pincodeErrors == 'error'">Please enter valid pin
                                               code</p>
                                           </div>
                     
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                               </div>
                             </div>
                             <div class="row"  >
                               <div class="col-12"><br />
                                 <label class="ifnoLabel ifnoDetail" style="font-size:16px;font-weight: bold; ">Let us set Clinic schedule</label>
                               </div>
                               <div class="col-12" style="height: 70%; overflow: scroll;" >
                                 <div class="row">
                                   <div class="col-3"></div>
                                   <div class="col-9">
                                     <div class="row" style="width: 60vw;">
                                       <div class="col-1">
                                         <p class="ifnoDetail center mar0">On Call</p>
                                       </div>
                                       <div class="col-xl-4 col-lg-5">
                                         <div class="sessionOne">
                                           <p class="ifnoDetail mar0">Session one</p>
                                         </div>
                                       </div>
                                       <div class="col-xl-4 col-lg-5">
                                         <div class="sessionOne">
                                           <p class="ifnoDetail mar0">Session two</p>
                                         </div>
                                       </div>
                                     </div>
                                   </div>
                                 </div>
                                 <div class="row fieldset clinicSessionOuter"
                                   *ngFor="let session of clinicArray[cIndex].ClinicSchedules; let i = index">
                                   <div class="col-3">
                     
                                     <div class="form-group selectDropDown">
                                       <select [ngClass]="{'ifnoLabel': !clinicArray[cIndex].ClinicSchedules[i].day}" class="form-control"
                                         [(ngModel)]="clinicArray[cIndex].ClinicSchedules[i].day" name="day{{i}}">
                                         <option disabled value="null">Select</option>
                                         <option *ngFor="let day of days" value="{{day}}">{{day}}</option>
                                       </select>
                                     </div>
                                     <p class="errors" *ngIf="clinicArray[cIndex].sessionErrors[i] == 'error'">Please select another day.</p>
                                   </div>
                                   <div class="col-9">
                                     <div class="row" style="width:60vw;">
                                       <div class="col-1" style="display:flex;justify-content: center; margin-top: 10px; ">
                                         <div class="form-group checkbox">
                                           <label>
                                             <input class="filled-in" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day == null"
                                               type="checkbox" [disabled]="true">
                                             <input class="form-control" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null"
                                               [(ngModel)]="clinicArray[cIndex].ClinicSchedules[i].on_call" type="checkbox"
                                               class="filled-in">
                                             <span></span>
                                           </label>
                                         </div>
                                       </div>
                                       <div  style="display:flex; justify-content: space-around; width: 30%;">
                                         <div class="clearfix clinicSession" style="display: flex; justify-content: space-around;">
                                           <div class="width40Percent">
                                             <div class="form-group">
                                               <input class="form-control" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day == null"
                                                 [disabled]="true" />
                                               <p-calendar *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null"
                                                 [(ngModel)]="clinicArray[cIndex].ClinicSchedules[i].morning_from"
                                                 (ngModelChange)="format(cIndex,i,'morning_from')" hourFormat="12" dateFormat="hh:ii "
                                                 timeOnly="true" readonlyInput="true"></p-calendar>
                                             </div>
                                           </div>
                                           <div class="width10Percent to" style="margin-top:5px ;">
                                             <p class="ifnoLabel center"  style="font-size:16px; font-weight: bold;">To</p>
                                           </div>
                                           <div class="width40Percent">
                                             <div class="form-group">
                                               <input class="form-control" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day == null"
                                                 [disabled]="true" />
                                               <p-calendar *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null"
                                                 [(ngModel)]="clinicArray[cIndex].ClinicSchedules[i].morning_to"
                                                 (ngModelChange)="format(cIndex,i,'morning_to')" hourFormat="12" dateFormat="hh:ii AA"
                                                 timeOnly="true" readonlyInput="true"></p-calendar>
                                             </div>
                                           </div>
                                           <div class="width10Percent to close-button" style="margin-top: 5px;" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null
                                                 && clinicArray[cIndex].ClinicSchedules[i].evening_from != null
                                                 && clinicArray[cIndex].ClinicSchedules[i].evening_to != null
                                                 && clinicArray[cIndex].ClinicSchedules[i].morning_from != null
                                                 && clinicArray[cIndex].ClinicSchedules[i].morning_to != null">
                                             <a (click)="closeSession('morning', cIndex, i)"><i class="fa fa-times-circle-o "></i></a>
                                           </div>
                     
                                         </div>
                                         <p class="errors" *ngIf="clinicArray[cIndex].session1Errors[i] == 'error'">Invalid timings for
                                           session one</p>
                                         <p class="errors" *ngIf="clinicArray[cIndex].blankSession1Errors[i] == 'error'">Please select
                                           timings</p>
                                       </div>
                                       <div class="col-xl-4 col-lg-5">
                                         <div class="clearfix clinicSession" style="display: flex; justify-content: space-around;">
                                           <div class="width40Percent">
                                             <div class="form-group">
                                               <input class="form-control" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day == null"
                                                 [disabled]="true" />
                                               <p-calendar *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null"
                                                 [(ngModel)]="clinicArray[cIndex].ClinicSchedules[i].evening_from"
                                                 (ngModelChange)="format(cIndex,i,'evening_from')" hourFormat="12" dateFormat="hh:ii AA"
                                                 timeOnly="true" readonlyInput="true"></p-calendar>
                                             </div>
                                           </div>
                                           <div class="width10Percent to" style="margin-top:5px ;">
                                             <p class="ifnoLabel center" style="font-size:16px; font-weight: bold;">To</p>
                                           </div>
                                           <div class="width40Percent">
                                             <div class="form-group">
                                               <input class="form-control" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day == null"
                                                 [disabled]="true" />
                                               <p-calendar *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null"
                                                 [(ngModel)]="clinicArray[cIndex].ClinicSchedules[i].evening_to"
                                                 (ngModelChange)="format(cIndex,i,'evening_to')" hourFormat="12" dateFormat="hh:ii AA"
                                                 timeOnly="true" readonlyInput="true"></p-calendar>
                                             </div>
                                           </div>
                                           <div class="width10Percent to close-button" style="margin-top:5px ;" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null
                                                 && clinicArray[cIndex].ClinicSchedules[i].morning_from != null
                                                 && clinicArray[cIndex].ClinicSchedules[i].morning_to != null
                                                 && clinicArray[cIndex].ClinicSchedules[i].evening_from != null
                                                 && clinicArray[cIndex].ClinicSchedules[i].evening_to != null">
                                             <a (click)="closeSession('evening', cIndex, i)"><i class="fa fa-times-circle-o "></i></a>
                                           </div>
                                         </div>
                                         <p class="errors" *ngIf="clinicArray[cIndex].session2Errors[i] == 'error'">Invalid timings for
                                           session two</p>
                                         <p class="errors" *ngIf="clinicArray[cIndex].blankSession2Errors[i] == 'error'">Please select
                                           timings</p>
                                       </div>
                     
                                     </div>
                                   </div>
                     
                                   <div class="col-12 addMore" >
                                     <div class="addMore d-flex" >
                                       <a (click)="removeField('ClinicSchedules',cIndex,i)" *ngIf="i != 0" class="mr15"><i _ngcontent-c12=""style="color:red
                                        ;"
                                           class="icon icon-arrows-circle-minus" ></i> Delete</a>
                                       <a (click)="addField('ClinicSchedules',cIndex)"
                                         *ngIf="i == clinicArray[cIndex].ClinicSchedules.length - 1  && clinicArray[cIndex].ClinicSchedules.length < 7"><i
                                           _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i> Add more</a>
                                     </div>
                                   </div>
                     
                                   <!--<div class="col-12 addMore">
                       
                                           <a (click)="addField('ClinicSchedules',cIndex)" *ngIf="i == 0 && clinicArray[cIndex].ClinicSchedules.length < 7"><i _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i> Add more</a>
                                           <a (click)="removeField('ClinicSchedules',cIndex,i)" *ngIf="i != 0 && clinicArray[cIndex].ClinicSchedules.length > 1"><i _ngcontent-c12="" class="icon icon-arrows-circle-minus"></i> Delete</a>
                                         </div>-->
                                 </div>
                               </div>
                             </div>
                             <div class="col-12"><br />
                               <label class="ifnoLabel ifnoDetail">Select slot time (in Minutes)</label>
                     
                               <div class="form-group selectDropDown width10Percent">
                                 <select class="form-control" [(ngModel)]="time" name="timeslot">
                                   <option disabled value="Select">Select</option>
                                   <option *ngFor="let time of slotsArray; let i = index" value="{{time}}">{{time}}
                                   </option>
                                 </select>
                                 <!-- <select class="form-control">
                                   <option disabled value="10">10</option>
                                   <option *ngFor="let time of slotsArray" value="{{time}}">{{time}}</option>
                                 </select> -->
                               </div>
                             </div>
                           
                           </div>
                     
                           <div class="col-3 col-sm-4">
                             <div class="addMore pt-3">
                               <a (click)="removeClinic(cIndex)" *ngIf="cIndex != 0" class="mr15"><i _ngcontent-c12=""
                                   class="icon icon-arrows-circle-minus"></i> Delete clinic</a>
                               <a (click)="addClinic(cIndex)" *ngIf="clinicArray.length < 3  && cIndex == clinicArray.length -1"><i
                                   _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i> Add more clinic</a>
                             </div>
                           </div>
                     
                           <!--<div class="addMore pt-3">
                               <a *ngIf="cIndex == 0 && clinicArray.length < 3" (click)="addClinic(cIndex)"><i _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i>Add more clinic</a>
                               <a *ngIf="cIndex != 0" (click)="removeClinic(cIndex)"><i _ngcontent-c12="" class="icon icon-arrows-circle-minus"></i> Delete</a>
                             </div>-->
                         </div>
   
                   
                   </div>
            
                   
                  
                </div>
               
               </mat-step>
               <mat-step>
                   <ng-template matStepLabel>Services</ng-template>
                   <div style="display: flex; justify-content: center;align-items: center; flex-direction: column;">
                       <div style="border: 1px solid #acacac;height: 100%;border-radius: 6px; width: 50vw;
                       padding: 15px 21px; display: flex; flex-direction: column; justify-content: center; ">
                        <div style="display:flex;flex-direction: row; justify-content: space-between;">
                           <div style="font-size: 18px; font-weight: 600;">All Services</div>

                           <div style="margin-top: 0px;">
                            <button (click)="saveDoctorServices()" mat-raised-button style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;font-weight: bold;">Save & Next </button>
                        </div>
                           </div>
   
                        
                    <mat-form-field class="example-full-width" appearance="outline" style="margin-top: 10px;">
                         <mat-label>Services</mat-label>
                          <textarea [(ngModel)]="docServices" (change)="isJson(docServices)"  matInput placeholder="" rows="5" cols="40">
                           
                          </textarea>
          
                                       </mat-form-field>
                                       <mat-form-field class="example-full-width" appearance="outline" style="margin-top: 10px;">
                                        <mat-label>AdminServices</mat-label>
                                         <textarea [(ngModel)]="adminServicesForLocation"  matInput placeholder="" rows="3" cols="20">
                                          
                                         </textarea>
                         
                                                      </mat-form-field>
                                      
                           <div style="display:flex;flex-direction: row; justify-content: space-between; ">
                             
                           </div>
                       </div>
                    
                       <div style="border: 1px solid #acacac;height: 100%;border-radius: 6px; width: 50vw;
                       padding: 15px 21px; display: flex; flex-direction: column; justify-content: center; margin-top: 15px; ">
                       <div style="display:flex;flex-direction: column; justify-content: space-between;">
                           <div style="font-size: 18px; font-weight: 600;">Appointment Configuration</div>
   
                           <div style="font-size: 16px;">Appointment Type</div>
                           </div>
   
                           <div  style="display:flex;flex-direction: row; justify-content: space-between; margin-top: 10px; ">
                            
                            
                             <mat-radio-group aria-labelledby="example-radio-group-label" class="example-radio-group">
                               <mat-radio-button class="example-radio-button" *ngFor="let section of appintmentType" [value]="section" [checked]="section.checked"
                                 (change)="isEnabledisAppointment($event,section)">
                                 {{section.name}}</mat-radio-button>
                             </mat-radio-group>
                                </div>
   
                              
                       </div>
                     
                       </div>
                   </mat-step>
                   <mat-step>
                       <ng-template matStepLabel>Prescription Settings</ng-template>
   
                       <div style="display: flex; justify-content: center;align-items: center; flex-direction: column;">
                           <div style="border: 1px solid #acacac;height: 100%;border-radius: 6px; width: 50vw;
                           padding: 15px 21px; display: flex; flex-direction: column; justify-content: center;  ">
    
                                   <div style="display:flex;flex-direction: row; justify-content: space-between;">
                                     <div style="font-size: 18px; font-weight: 600;">Prescription Settings</div><br>

                                  
                                     <div style="display:flex; flex-direction: row;">
                                    
                                         <button (click)="openDialog(DocRxSettings);createNewPerscription();" mat-raised-button style="background-color: #fff; color: #5D86CC; height: 40px;margin-left: 9px;margin-top: 0px;font-size: 16px;font-weight: bold;">+Add New</button>
                                         <div style="margin-top: 0px;">
                            
                                          <button (click)="loginDoctor()" mat-raised-button style="background-color: #5D86CC; color:#fff ; height: 40px;margin-left: 9px;margin-top: 0px;font-size: 16px;font-weight: bold;">Login Doctor</button>
                                      </div>
                                     </div>
                                 </div>
                              
                                <div style="height: 50vh; width: 48vw; overflow: scroll;">
                                  <div style="height: 50vh; width: 48vw;overflow: scroll;">
                                      <ng-container [ngTemplateOutlet]="renderImmunization" [ngTemplateOutletContext]="{value:prescriptionSettings}">
                                      </ng-container>
                                  </div>
                              </div>

                              <ng-template #renderImmunization let-value="value">
                                <table mat-table [dataSource]="value" style="width: 48vw; margin-top: 8px;">
                                  <ng-container>
                                      <!-- VISIT -->
                                      <ng-container matColumnDef="templateName">
                                          <th mat-header-cell *matHeaderCellDef> Template Name </th>
                                          <td mat-cell *matCellDef="let element"
                                              style="text-align: start !important; width:88%; font-weight: bold;"
                                             >
                                             {{element.templateName}}
                                             
                                          </td>
                                      </ng-container>
                      
                                      <!-- VACCINE -->
                                      <ng-container matColumnDef="action">
                                          <th mat-header-cell *matHeaderCellDef > Action </th>
                                          <td mat-cell *matCellDef="let element"
                                              style="text-align: end !important; width: 34.5% !important;">
                                              <div class="actionBtn" style="width: 113% !important;"
                                              (click)="getPrescriptionValue(element)"> View
                                          </div>  
                      
                                          </td>
                                      </ng-container>
                      
                                      <!-- RECOMMENDED DATE -->
                                    
                      
                                      <!-- CATCHUP DATE -->
                                     
              
                                   
                                      <!-- STATUS -->
                                   
                      
                                      <!-- RECEIVED DATE -->
                             
                      
                                      <!-- BRAND -->
                                     
                      
                                      <!-- ACTION   -->
                                     
                      
                                  </ng-container>
                      
                                  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;"></tr>
                                  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                              </table>
                                </ng-template>
                            
                           </div>
                          
                           </div>
                       </mat-step>
                     
    </mat-horizontal-stepper>
    <div style="height:50px ; margin-top: 11px;">
      <!-- <button (click)="openDialog(resetVaccinationModal);" > save</button> -->

      <button (click)="openDialog(resetVaccinationModal);" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <mat-icon [ngClass]="'blue-icon'">
      settings
      </mat-icon>

      <span style="margin-left: 4px;">Settings</span>
  </button>
    </div>
 
   </div>
   
   <ng-template #DocRxSettings let-modal>
     <div class="modal-header" style="border-bottom: 0px; width: 50vw;">
       <h4 class="modal-title" id="modal-basic-title">Prescription Settings</h4>
     </div>
   <div style="height:100vh ;">
     <div style="display:flex;flex-direction: column; ">

      <mat-form-field appearance="outline" style="width: 100%;">
        <mat-label> Template Name </mat-label>
        <input matInput placeholder="Enter Template Name" [(ngModel)]="editprescriptionSettings.templateName" >
      </mat-form-field>
       <mat-form-field appearance="outline" style="width: 100%;">
         <mat-label>Prescription header s3 url</mat-label>
         <textarea [(ngModel)]="editprescriptionSettings.logo" matInput placeholder="" rows="2" cols="20"></textarea>
         <!-- <input matInput placeholder="Enter Prescription header s3 url" [(ngModel)]="editprescriptionSettings.logo" > -->
       </mat-form-field>
       <mat-form-field appearance="outline" style="width: 100%;">
         <mat-label> Prescription footer s3 url</mat-label>
         <textarea [(ngModel)]="editprescriptionSettings.foneLeft" matInput placeholder="" rows="2" cols="20"></textarea>
       </mat-form-field>
       <mat-form-field appearance="outline" style="width: 100%;">
           <mat-label> Prescription Background image s3 url </mat-label>
           <input matInput [(ngModel)]="editprescriptionSettings.backgroundImage" placeholder="Enter Prescription  Background image s3 url" >
         </mat-form-field>
        
   </div>
   
   
   <div style="display:flex;flex-direction: row; justify-content:space-evenly;">
   <mat-form-field appearance="outline" style="width: 28%;">
     <mat-label>V3 Height in Inches</mat-label>
     <input matInput [(ngModel)]="editprescriptionSettings.v3height" placeholder="Enter V3 Height in Inches" maxlength="25" >
   </mat-form-field>
   <mat-form-field appearance="outline" style="width: 28%;">
     <mat-label> V3 Width in Inches</mat-label>
     <input matInput [(ngModel)]="editprescriptionSettings.v3width" placeholder="Enter V3 Width in Inches" maxlength="25">
   </mat-form-field>
   <mat-form-field appearance="outline" style="width: 28%;">
       <mat-label> V3 Header Height in Cm</mat-label>
       <input matInput [(ngModel)]="editprescriptionSettings.v3headerheight" placeholder="Enter V3 Header Height in Cm " maxlength="25">
     </mat-form-field>
   </div>
   <div style="display:flex;flex-direction: row; justify-content: space-evenly;  ">
   <mat-form-field appearance="outline" style="width: 20%;">
     <mat-label>V3 Footer Height in Cm </mat-label>
     <input matInput [(ngModel)]="editprescriptionSettings.v3footerheight" placeholder="Enter V3 Footer Height in Cm" maxlength="25" >
   </mat-form-field>
   <mat-form-field appearance="outline" style="width: 20%;">
     <mat-label> V3 Font size</mat-label>
     <input matInput [(ngModel)]="editprescriptionSettings.v3fontsize" placeholder="Enter V3 Font size" maxlength="25">
   </mat-form-field>
   <mat-form-field appearance="outline" style="width: 20%;">
       <mat-label>V3 Font size large</mat-label>
       <input [(ngModel)]="editprescriptionSettings.v3fontsizelarge" matInput placeholder="Enter V3 Font size large " maxlength="25">
     </mat-form-field>

     <mat-form-field appearance="outline" style="width: 20%;">
      <mat-label>V3 MarginLeft</mat-label>
      <input [(ngModel)]="editprescriptionSettings.v3marginleft" matInput placeholder="Enter V3 MarginLeft " maxlength="25">
    </mat-form-field>
   </div>
   <!-- 
   <div style="margin-top: 10px;">
     <button mat-raised-button style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;font-weight: bold;">Save & Next </button>
   </div> -->

   <div  style="display:flex;flex-direction: row; justify-content: space-between; margin-top: 10px; ">
                            
                            
    <mat-checkbox-group aria-labelledby="example-checkbox-group-label" class="example-section">
      <mat-checkbox class="example-margin" *ngFor="let section of prescriptionShowSettingTypes" [value]="section" [checked]="section.checked"
        (change)="isdisableSettingsTypes($event,section)">
        {{section.name}}</mat-checkbox>
    </mat-checkbox-group>
       </div>
       
   <div style="display: flex;justify-content: flex-end;">
     <button (click)="closeDialog()" class="btnUiForCancel">Cancel</button>
     <button (click)="saveRxTemplateByDocUserId();closeDialog();"  class="btnUiForCopyMed">Save</button>
   </div>
  </div>
     </ng-template>

     <ng-template #resetVaccinationModal let-modal>
      <h4> Reset Vaccination </h4>
      <div style="display: flex;flex-direction: row;">
        <mat-form-field appearance="outline" style="margin-top: 18px;" >
          <mat-label style="font-size: 16px;">Patient Id </mat-label>
          <input matInput  [(ngModel)]="patientId" placeholder="Enter Patient Id">
      
       
        </mat-form-field>
      
        <mat-form-field appearance="outline" style="margin-top: 18px; margin-left: 10px;" >
          <mat-label style="font-size: 16px;">DOB </mat-label>
          <input matInput [matDatepicker]="picker" [(ngModel)]="patientDoB">
      
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>
        
        <mat-form-field  appearance="outline"  style="margin-left: 17px; margin-top: 18px;">
          <mat-label style="font-size: 16px;">Vaccination start date</mat-label>
          <input [(ngModel)]="patientVaccinationDate" matInput [matDatepicker]="picker1">
         
          <mat-datepicker-toggle matSuffix  [for]="picker1"></mat-datepicker-toggle>
          <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>

      </div>
     <div style="display: flex; flex-direction: row; justify-content: end;">
      <button (click)="closeDialog();"  mat-raised-button style="background-color: #eee; color: #444;; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;font-weight: bold;">Cancel</button>
      <button (click)="resetVaccination();"  mat-raised-button style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;font-weight: bold;">Reset Vaccination</button>
     </div>
     </ng-template>

     <p-toast position="top-right" [baseZIndex]="5000">
      <ng-template let-message pTemplate="message">
        <div class=" p-flex p-flex-column"
          style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;">
          <div>
            <img (click)="goBack()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
              src="assets/images/exclamation-circle-blue.svg" alt="" />
          </div>
          <div class=" p-text-center">
            <div style="font-size: 18px;
                      font-weight: bold;
                      padding: 12px 0px 0px 12px;
                      
                      color: #034A70;">{{message.summary}}</div>
            <div style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
              {{message.detail}}</div>
          </div>
        </div>
      </ng-template>
    </p-toast>