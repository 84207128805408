import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SidebarModule } from 'ng-sidebar';
import { AuthService } from './../services/auth.service'
// import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AutoCompleteModule } from 'primeng/autocomplete';

import { CalendarModule } from 'primeng/calendar';
import { ImageCropperModule } from "ngx-img-cropper";
import { DialogModule } from 'primeng/dialog';
import { profileRoutes } from './doctor.routing';
import { BasicInformationComponent } from './profile/basic-information/basic-information.component';
import { patientComponent } from './patient/patient/patient.component';
import { treatmentComponent } from './patient/treatment/treatment.component';
import { prescriptionsComponent } from './patient/prescriptions/prescriptions.component';
import { AppointmentsComponent } from './appointments/appointments.component';
import { ViewPrescriptionsComponent } from './viewPrescriptions/viewPrescriptions.component';
import { HomePageComponent } from './homepage/homepage.component';

import { CliniciansProfileComponent } from './profile/clinicians-profile/clinicians-profile.component';
import { ChangeEmailComponent } from './settings/change-email/change-email.component';
import { VaccinationReportComponent } from './reports/vaccinationreport/vaccinationreport.component';
import { AppDownloadsComponent } from './reports/appdownloads/appdownloads.component';

import { VaccinationsComponent } from './settings/vaccinations/vaccinations.component';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/user.service';
import { ClinicDetailsComponent } from './profile/clinic-details/clinic-details.component';
import { CliniciansProfileViewComponent } from './profile/clinicians-profile-view/clinicians-profile-view.component';
import { LetterheadDetailsComponent } from './profile/letterhead-details/letterhead-details.component';
import { ChangePasswordComponent } from './settings/change-password/change-password.component';
import { ChangePhoneComponent } from './settings/change-phone/change-phone.component';

import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { ThankYouComponent } from './thank-you/thank-you.component';
// New imports for graph functionality
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as exporting from 'highcharts/modules/exporting.src'

import stock from 'highcharts/modules/stock.src';

import { WebcamModule } from 'ngx-webcam';
import { CanDeactivateGuard } from '../can-deactivate.guard';
import { TwoDigitDecimaNumberDirective } from '../modules/two-digit-decima-number.directive';
import { ProfileSummary } from './patient/profile-summary/profile-summary.component';
import { PatientOnboard } from './patient/patient-onboard/patient-onboard.component';

import { ScrollableTabsComponent } from './scrollable-tabs/scrollable-tabs.component';
import { PrescriptionpreviewComponent } from './patient/prescriptionpreview/prescriptionpreview.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';

import { DndDirective } from './patient/profile-summary/Profile-dirctive';
import { ProgressComponent } from './progress/progress-compoent';
import { CustomtextinputComponent } from './customtextinput/customtextinput.component';
import { CustomTextinputComponent } from './custom-textinput/custom-textinput.component';

import { PdfViewerModule } from 'ng2-pdf-viewer';
import { DoctorReportsComponentComponent } from './reports/doctor-reports-component/doctor-reports-component.component';
import { DocRptDashComponent } from '../admin-panel/doc-rpt-dash/doc-rpt-dash.component';
import { refreshService } from '../services/refersh.service';
import { AdminPanelModule } from '../admin-panel/admin-panel.module';
import { QuickPrescriptionComponentComponent } from './patient/quickprescription/quick.prescription.component/quick.prescription.component.component';
import { CutomCardComponent } from './custom-card/cutom-card/cutom-card.component';
import { PrescriptionLivePreviewComponent } from './patient/prescription-live-preview/prescription-live-preview.component';
import { MatStepperModule, MatStepper, MatStep } from '@angular/material/stepper';
import { MatSelectModule, MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatDialogModule, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatAutocomplete, MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButton, MatButtonModule } from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';
import { EditPrescriptionComponent } from './patient/edit-prescription/edit-prescription.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatError, MatFormFieldModule, } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {MatIconModule} from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TopInfoNavbarComponent } from './patient/top-info-navbar/top-info-navbar.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';

import {MatExpansionModule} from '@angular/material/expansion';
import {MatMenuModule} from '@angular/material/menu';
import {MatChipsModule} from '@angular/material/chips';
import {MatBadgeModule} from '@angular/material/badge';
// import { MatCarouselModule } from '@ngmodule/material-carousel';
import {DragDropModule} from '@angular/cdk/drag-drop';
import { GraphContainerComponent } from './patient/graph-container/graph-container.component';
import { WeightGraphComponent } from './patient/weight-graph/weight-graph.component';
import { HeightGraphComponent } from './patient/height-graph/height-graph.component';
import { HcGraphComponent } from './patient/hc-graph/hc-graph.component';
import { TargetHeightGraphComponent } from './patient/target-height-graph/target-height-graph.component';
import { HomeDashboardComponent } from './home-dashboard/home-dashboard.component';
// import { DocRptDashComponent } from '../admin-panel/doc-rpt-dash/doc-rpt-dash.component';
import {NgxPrintModule} from 'ngx-print';
import { BmiGraphComponent } from './patient/bmi-graph/bmi-graph.component';
import { BpGraphComponent } from './patient/bp-graph/bp-graph.component';
import {MatTabsModule} from '@angular/material/tabs';
import {MatDividerModule} from '@angular/material/divider';

import { AppointmentProgressCardComponent } from '../components/appointment-progress-card/appointment-progress-card.component';
import { EmptyStatePlaceholderComponent } from './../components/empty-state-placeholder/empty-state-placeholder.component';
import { MilestoneComponent } from './patient/milestone/milestone.component';
import {LanguageSelectTextboxComponent } from '../components/language-select-textbox/language-select-textbox.component'
import { PatientHistoryComponent } from './patient/patient-history/patient-history.component';
import { AlertboxComponent } from './../components/alertbox/alertbox.component';
import { ImmunizationComponent } from './patient/immunization/immunization.component';
import { NewOnboardPatientComponent } from './patient/new-onboard-patient/new-onboard-patient.component';
import { PatientReceiptComponent } from './patient/patient-receipt/patient-receipt.component';
import { PatientCertificateComponent } from './patient/patient-certificate/patient-certificate.component';
import { PatientReferralComponent } from './patient/patient-referral/patient-referral.component';
import { PatientRequisitionComponent } from './patient/patient-requisition/patient-requisition.component';
import { VitalsComponent } from './patient/vitals/vitals.component';
import { AddVitalsComponent } from './patient/add-vitals/add-vitals.component';
import { WeightHeightGraphComponent } from './patient/weight-height-graph/weight-height-graph.component';
import { FavouriteManagementComponent } from './favourite-management/favourite-management.component';
import {MatListModule} from '@angular/material/list';
import { NewProfileComponent } from './patient/new-profile/new-profile.component';
import { VaccineInventoryComponent } from './vaccine-inventory/vaccine-inventory.component';
import { VaccineProjectionsComponent } from './vaccine-projections/vaccine-projections.component';
import { NewRxComponent } from './new-rx/new-rx.component';
import { NewRxModule } from '../new-rx/new-rx.module';


export function highchartsModules() {
  // apply Highcharts Modules to this array
  return [stock, more];
}


const dialogMock = {
  close: () => { }
};

@NgModule({
  imports: [
    Ng2SearchPipeModule,
    NgxPrintModule,
    CommonModule,
    RouterModule.forChild(profileRoutes),
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    NgbModule,
    PdfViewerModule,
    MessageModule,
    MessagesModule,
    ToastModule,
    AutoCompleteModule,
    CalendarModule,
    ConfirmDialogModule,
    SharedModule,
    MatIconModule,
    ImageCropperModule,
    DialogModule,
    ChartModule,
    WebcamModule,
    AdminPanelModule,
    MatStepperModule,
    MatSelectModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatButtonModule,
    MatTableModule,
    FormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    ReactiveFormsModule,
    MatFormFieldModule,

    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatTooltipModule,
    MatProgressSpinnerModule,
    MatExpansionModule,
    MatMenuModule,
    MatChipsModule,
    MatBadgeModule,
    MatTabsModule,
    DragDropModule,
    MatTabsModule,
    MatDividerModule,
    MatListModule,
    NgCircleProgressModule.forRoot({}),
    
    // MatCarouselModule.forRoot()
  ],
  providers: [
    GlobalService,
    UserService,
    ConfirmationService,
    CanDeactivateGuard,
    refreshService,
    {
      provide: MatStepper,
      useValue: {}
    },
    {
      provide: MatStep,
      useValue: {}
    },
    {
      provide: MatSelect,
      useValue: {}
    },
    {
      provide: MatSelectTrigger,
      useValue: {}
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: MatDialogRef,
      useValue: dialogMock

    },
    {
      provide: MatAutocomplete,
      useValue: {}
    },

    { provide: MAT_DIALOG_DATA, useValue: {} },


    //NgxMaterialTimepickerModule,
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, exporting] } // add as factory to your providers
  ],
  declarations: [
    BasicInformationComponent,
    CliniciansProfileComponent,
    ChangeEmailComponent,
    VaccinationReportComponent,
    AppDownloadsComponent,
    ClinicDetailsComponent,
    LetterheadDetailsComponent,
    VaccinationsComponent,
    CliniciansProfileViewComponent,
    ChangePasswordComponent,
    ChangePhoneComponent,
    patientComponent,
    ThankYouComponent,
    treatmentComponent,
    prescriptionsComponent,
    AppointmentsComponent,
    ViewPrescriptionsComponent,
    HomePageComponent,
    TwoDigitDecimaNumberDirective,
    ProfileSummary,
    ScrollableTabsComponent,
    PatientOnboard,
    PrescriptionpreviewComponent,
   
    ProgressComponent,
    CustomtextinputComponent,
    CustomTextinputComponent,
    DoctorReportsComponentComponent,

    QuickPrescriptionComponentComponent,
    // DocRptDashComponent,
    CutomCardComponent,
    PrescriptionLivePreviewComponent,
    EditPrescriptionComponent,
    TopInfoNavbarComponent,
    GraphContainerComponent,
    WeightGraphComponent,
    HeightGraphComponent,
    HcGraphComponent,
    TargetHeightGraphComponent,
    BmiGraphComponent,
    BpGraphComponent,
    HomeDashboardComponent,

    AppointmentProgressCardComponent,
    EmptyStatePlaceholderComponent,
    MilestoneComponent,
    LanguageSelectTextboxComponent,
    PatientHistoryComponent,
    AlertboxComponent,
    ImmunizationComponent,
    NewOnboardPatientComponent,
    PatientReceiptComponent,
    PatientCertificateComponent,
    PatientReferralComponent,
    PatientRequisitionComponent,
    VitalsComponent,
    AddVitalsComponent,
    WeightHeightGraphComponent,
    FavouriteManagementComponent,
    NewProfileComponent,
    VaccineInventoryComponent,
    VaccineProjectionsComponent,
    NewRxComponent,
   


    // DocRptDashComponent
  ],
  exports: [HomeDashboardComponent,TopInfoNavbarComponent,LanguageSelectTextboxComponent,AlertboxComponent],
})
export class DoctorModule {

  /*constructor(private authService: AuthService) {
    //Redirect to login screen, if user logout from one browser tab.
    setInterval(() => {
      if (!this.authService.isLoggedIn()) {
        this.authService.deleteToken();
        window.location.href = '/login'
      }
    }, 10000); // 10 seconds
  }*/
}

