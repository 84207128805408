import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../../services/user.service'
import { AdminService } from '../../../../services/admin.service'
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../../../services/global.service'
import { UtilService } from '../../../../util/utilService'
import { debounce } from 'lodash';
@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent implements OnInit {
  userList: any = []
  search : any
  totalUsers: number = 0
  maleCount: number = 0
  femaleCount: number = 0
  offset: number
  limit: number
  nextOffset = null
  totalPages: number[]
  currentPage: number
  isLoading: Boolean = false
  degreeArray: any=[]
  councilsArray: any=[]
  totalRecords = null
  searchString = null
  searchByGender=null
  fromDate = null
  toDate = null
  order = []
  rangeType = null
  firstTime = true;
 
  constructor(private userService: UserService, private util: UtilService,  private adminService: AdminService, private router: Router, private toastr: ToastrService, private globalService: GlobalService) {
    this.search = {}
    this.totalUsers = this.offset = 0
    this.limit = 20
    this.currentPage = 1
    this.totalPages = [1]
  }

  ngOnInit() {

      this.getUsers();
      this.getConstants();

  }

  loadDoctors(e){
    let browserZoomLevel = window.devicePixelRatio;
    let scrollHeight = e.target.scrollHeight;
    let scrollHeightZoom = scrollHeight*browserZoomLevel;

    if (e.target.offsetHeight + e.target.scrollTop === scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop > scrollHeightZoom || e.target.offsetHeight + e.target.scrollTop==scrollHeight) {
      this.offset = this.offset + this.limit
      if(this.nextOffset !== 0){
        this.getUsers()
      }
    }
  }

  searchPatients = debounce( ()=> {
    if(this.searchString){
      this.search.search_string = this.searchString
    }else{
      delete this.search['search_string']
    }
    if(this.searchByGender){
      this.search.search_by_gender = this.searchByGender
    }
    this.search.from_date = this.fromDate ? this.util.parseDate(this.fromDate) : null
    this.search.to_date = this.toDate ? this.util.parseDate(this.toDate) : null
   
    this.userList = []
    this.offset = null
    this.nextOffset = null
    this.getUsers()
  }, 300)

  sort = debounce((model,param, order) => {
    if(model)
    this.order = [[model,param,order]]
    else
      this.order = [[param,order]]

      this.userList = []
      this.offset = null
      this.nextOffset = null
      this.getUsers()
  }, 300)


  clearFilters(){
    this.search = {}
    this.searchString = null
    this.searchByGender = null
    this.fromDate = null
    this.toDate = null
    this.searchPatients()
  }


  closeCalender(e,d){

    if(d._elRef.nativeElement.parentElement.contains(e.target)) {

    } else {
      d.close()
    }
    if(e.target.offsetParent == null)
      d.close()
    else if(e.target.offsetParent && e.target.offsetParent.nodeName != "NGB-DATEPICKER" && (e.target.offsetParent.className.indexOf('calender') == -1 && e.target.offsetParent.className.indexOf('custom-select') == -1))
      d.close()
  }

  getUsers = (query = {}, search = false) => {
    this.isLoading = false
    query['user_type'] = "patient"
    let req_vars = {  query: query,
                      search: this.search,
                      offset: this.offset,
                      limit: this.limit,
                      order: this.order
                  }
    this.adminService.patientList(req_vars).subscribe(response => {
      if(response.success){
        this.totalRecords = response.total
        this.maleCount = response.maleCount
        this.femaleCount = response.femaleCount
        if(response.next_offset != 0){
          this.userList = this.userList.concat(response.users)
        }
        this.nextOffset = response.next_offset

      }
    }, (err) => {
      console.error(err)
    })
  }

  getAge(dob){
    dob = new Date(dob)
    let age = this.util.calculateAge(dob)
    let ageStr= null
    ageStr = age['years'] ? `${age['years']}Y` : null
    ageStr = ageStr ? age['months'] ? `${ageStr}, ${age['months']}M` : `${ageStr}` :  age['months'] ?  `${age['months']}M` : 0
    return ageStr
  }

  getConstants = () => {
    this.adminService.getConstants().subscribe(response =>{
      this.degreeArray = response.degrees
      this.councilsArray = response.state_medical_councils
    })
  }

  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  



}
