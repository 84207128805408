import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'scrollable-tabs',
  templateUrl: './scrollable-tabs.component.html',
  styleUrls: ['./scrollable-tabs.component.css']
})
export class ScrollableTabsComponent implements OnInit {
  @Input() tabs;
  @Input() requisitionTab;
  selectedIndex = 0;
  // ReqselectedIndex=0
  
  abc: string;
  abc1: string;
  leftTabIdx = 0;
  atStart = true;
  atEnd = false
  @Output() emitSelectedTab = new EventEmitter()
  @Output() emitSelectTabrequisition = new EventEmitter()
  constructor() { }

  ngOnInit() {
    this.emitSelectedTab.emit(this.tabs[0])
    this.abc = `translateX(0px)`
  // this.emitSelectTabrequisition.emit(this.requisitionTab[0]);
  //   this.abc1=`translateX(0px)`
  }

  selectTab(index) {
    this.selectedIndex = index
    this.emitSelectedTab.emit(this.tabs[index])
    //this.scrollTab(index-this.leftTabIdx-1)
  }
  // selectTabrequisition(index){
  //   this.ReqselectedIndex=index;
  //   this.emitSelectTabrequisition.emit(this.requisitionTab[index]);

  // }
  scrollTab(x) {
    if (this.atStart && x < 0 || this.atEnd && x > 0) {
      return
    }
    this.leftTabIdx = this.leftTabIdx + x
    this.abc = `translateX(${(this.leftTabIdx) * -140}px)`
    this.atStart = this.leftTabIdx === 0
    this.atEnd = this.leftTabIdx === this.tabs.length -1
  }

}