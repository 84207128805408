<div class="container-fluid basic-info-wrapper" *ngIf="!otpSection">
  <div class="row">

    <!--Your Practice section(start here) -->
    <div class="col-12">
      <label class="headingUpprcase18 mb-3" style="text-transform: capitalize;">Change Password</label>
      <form [formGroup]="passwordForm">
        <div class="row">
          <div class="col-3">
            <div class="form-group animateLabel">
              <input (keydown.space)="$event.preventDefault()" type="password" class="form-control animateInput"
                formControlName="password" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
              <label [ngClass]="{'animatedLabel':passwordForm.controls['password'].value}">Current Password <span
                  class="required">*</span></label>
              <p class="errors" *ngIf="passwordForm.controls['password'].invalid && submitted">Password must be minimum
                of 6 characters</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="form-group animateLabel">
              <input (keydown.space)="$event.preventDefault()" type="password" class="form-control animateInput"
                formControlName="newPassword" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
              <label [ngClass]="{'animatedLabel':passwordForm.controls['newPassword'].value}">New Password <span
                  class="required">*</span></label>
              <p class="errors" *ngIf="passwordForm.controls['newPassword'].invalid && submitted">Password must be
                minimum of 6 characters</p>
              <!-- <p class="errors" *ngIf="passwordForm.controls['confirmPassword'].touched && ( passwordForm.controls['newPassword'].value !== passwordForm.controls['confirmPassword'].value)">Password does not match</p>-->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-3">
            <div class="form-group animateLabel">
              <input (keydown.space)="$event.preventDefault()" type="password" class="form-control animateInput"
                formControlName="confirmPassword" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
              <label [ngClass]="{'animatedLabel':passwordForm.controls['confirmPassword'].value}">Re-enter Password
                <span class="required">*</span></label>

              <p class="errors"
                *ngIf="(passwordForm.controls['newPassword'].value !== passwordForm.controls['confirmPassword'].value) && submitted">
                Password does not match</p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 mt-3">
            <button type="button" class="btn-primary opd-primary-btn settingBtn btn-md"
              style="text-transform: capitalize;" (click)="checkPassword()">Update Password</button>
            <!--<a type="button" routerLink="/doctor/appointments" class="btn-primary opd-secondary-btn btn-md ml-2 upprcase" >Cancel</a>-->
            <button type="button" class="btn-primary opd-secondary-btn settingBtn btn-md ml-3"
              style="text-transform: capitalize;" (click)="goBack()">Cancel</button>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
<div class="col-12" *ngIf="otpSection">
  <h3 class="inner-header">Confirm your information</h3>
  <form>

    <div class="row">
      <div class="optHolder">
        <div class="col-12 form-group mb-4 onFocus">
          <label>Enter OTP</label>
          <div class="row">
            <div class="col-12">
              <input type="text" class="form-control otpInputField" maxlength="6" [(ngModel)]="otp" name="otp" id="otp"
                (keypress)="validateNumber($event)">
              <!--placeholder="* * * * * *"
                  onfocus="if(placeholder=='* * * * * *'){placeholder=''}"
                  onblur="if(placeholder==''){placeholder='* * * * * *'}"-->
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <p class="terms-line">
          <a class="req-otp not-active" id="resend-otp" (click)="reqOtp()">Resend OTP?</a> if you don't receive it in 2
          mins.
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col-12 mt-3">
        <button type="submit" class="btn-primary opd-primary-btn btn-md" (click)="changePassword()">Submit</button>
        <button type="button" class="btn-primary opd-secondary-btn btn-md ml-2" (click)="otpSection = false">Go
          back</button>
      </div>
    </div>
  </form>
</div>