<ng-sidebar-container class="app opd-main-wrapper" [ngClass]="{
  'mode-boxed': isBoxed,
  'sidebar-opened': isOpened,
  'theme-light': theme == 'light',
  'theme-dark': theme == 'dark',
  'mode-push': _mode == 'push',
  'mode-dock': _mode == 'dock',
  'mode-over': _mode == 'over',
  'mode-slide': _mode == 'slide',
  'no-footer': options?.removeFooter,
  'map-header': options?.mapHeader
}">
<ng-sidebar [(opened)]="isOpened" [(mode)]="_mode" [position]="'left'" [dockedSize]="'80px'"
  [autoCollapseWidth]="'991'" [closeOnClickOutside]="isOver()" [showBackdrop]="isOver()"
  [sidebarClass]="'sidebar-panel'" #sidebar>
  <nav class="navbar custom-navbar main-brand">
    <a class="navbar-brand copious-logo" [routerLink]="['/admin/login']" title="home" style="font-size: 13px;">
      <img _ngcontent-c0="" alt="" class="logo" src="assets/images/logo1.svg" />
      copious

      <!-- <view-all-cases-popup myNum="5" [master]="master" ></view-all-cases-popup> -->
    </a>
    <ul class="navbar-nav" style="display: none;">
      <li class="nav-item">
        <a href="javascript:;" class="nav-link" (click)="toogleSidebar()">
          <i class="hamburger-icon v2" *ngIf="_mode === 'over' && !isOver()">
            <span></span>
          </i>
        </a>
      </li>
    </ul>
  </nav>
  <div class="profileSection">
    <ul>
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <!-- <a href="javascript:;" class="nav-link clearfix" ngbDropdownToggle>
          <figure><img src="assets/images/profile-icon.png" class="navbar-avatar rounded-circle" alt="user" title="user"></figure> <span>Dr. Rashmi</span>
        </a>
        <div class="changeProPic">
          <div class="proPic"></div>
          <a>Changes profile picture</a>
        </div> -->
        <!-- <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item">
            <i class="icon icon-basic-settings mr-3"></i>
            <span>Settings</span>
          </a>
          <a class="dropdown-item">
            <i class="icon icon-basic-postcard mr-3"></i>
            <span>Profile</span>
          </a>
          <a class="dropdown-item">
            <i class="icon icon-basic-message-multiple mr-3"></i>
            <span>Notifications</span>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item">
            <i class="icon icon-arrows-switch-vertical mr-3"></i>
            <span>Signout</span>
          </a>
        </div> -->
      </li>
    </ul>
  </div>
  <!-- main navigation -->
  <nav class="menu mt-3">
    <ul class="navigation" appAccordion>
      <li class="navigation-item" appAccordionLink *ngFor="let menuitem of menuItems.getAll()"
        group="{{ menuitem.state }}">
        <a class="navigation-link" routerLinkActive="active" appAccordionToggle [routerLink]="['/', menuitem.state]"
          *ngIf="menuitem.type === 'link'">
          <span>{{ menuitem.name   }}</span>
          <span class="mr-auto"></span>
          <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>

        <a class="navigation-link" routerLinkActive="active" appAccordionToggle
          [routerLink]="['/', 'admin', menuitem.state]" *ngIf="menuitem.type === 'extLink'">
          <span>{{ menuitem.name   }}</span>
          <span class="mr-auto"></span>
          <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
        </a>
        <a class="navigation-link" routerLinkActive="active" appAccordionToggle
          [routerLink]="['/', 'admin', menuitem.state]" target="_blank" *ngIf="menuitem.type === 'extTabLink'">
          <span>{{ menuitem.name   }}</span>
          <span class="mr-auto"></span>
          <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          <i class="menu-caret fa fa-caret-right"></i>
        </a>
       
        <a class="navigation-link " appAccordionToggle href="javascript:;"
          [ngClass]="{ active: currentUrl.indexOf(menuitem.state) != -1 }" *ngIf="menuitem.type === 'sub'">
          <span>{{ menuitem.name   }}</span>
          <span class="mr-auto"></span>
          <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          <i class="menu-caret fa fa-caret-right"></i>
        </a>
        <a class="navigation-link {{
            mainMenu == menuitem.state ? 'active1' : ''
          }}" appAccordionToggle href="javascript:;" [routerLink]="['/', 'doctor', 'investigations', 'viewRecords']"
          *ngIf="
            menuitem.type === 'sub' && menuitem.state === 'investigations'
          ">
          <span>{{ menuitem.name   }}</span>
          <span class="mr-auto"></span>
          <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          <i class="menu-caret fa fa-caret-right"></i>
        </a>
        <ul class="navigation-submenu" *ngIf="menuitem.type === 'sub'">
          <li class="navigation-item" *ngFor="let childitem of menuitem.children" routerLinkActive="open">
            <a [routerLink]="[
                '/',
                'admin',
                childitem.state,
                childitem.substate
              ]" class="navigation-link relative" [ngClass]="{
                active: currentUrl.indexOf(childitem.substate) != -1
              }" routerLinkActive="active">{{ childitem.name   }}</a>
          </li>
        </ul>
      </li>
    
      <li class="navigation-item">
        <a (click)="gToMarktPlace();" class="navigation-link" routerLinkActive="active" 
         target="_blank" >
        <span>Market Place</span>
        <span class="mr-auto"></span>
        
      
      </a>
      </li>
    </ul>
  </nav>
  <!--<nav class="menu botttomMenu">
    <ul class="navigation clearfix">

      <li>
        <a (click)="logout()">
          <i class="fa fa-sign-out"></i>
          <span>Logout</span>
        </a>
      </li>
    </ul>
  </nav>-->
  <!-- /main navigation -->
</ng-sidebar>

<div ng-sidebar-content class="app-inner">
  <nav class="navbar custom-navbar bg-faded main-header">
    <ul class="navbar-nav">
      <li class="nav-item">
        <span class="topHeading">{{ topHeading }}</span>
      </li>
    </ul>
    <span class="navbar-heading hidden-xs-down">{{ options?.heading }}</span>
    <span class="mr-auto"></span>
    <ul class="addNewSearchSection clearfix">
      <!-- <li class="addNew li">
        <a class="btn btn-primary opd-primary-btn transformNormal btn-lg"><span>+</span> Add new patient</a>
      </li>
      <li class="searchIcon li">
        <a (click)="open(searchResult, 'search')"><img src="assets/images/searchIcon.svg" alt="" /></a>
      </li>
      <li class="todayBirthday li">
        <a>{{todaysDate | date:'MMMM d, yyyy'}}<span>Today's birthdays</span></a>
      </li> -->
      <li class="addNew li">
        <a (click)="logout()" class="btn btn-primary opd-primary-btn transformNormal btn-lg">Logout</a>
      </li>
    </ul>
    <!-- <ul class="navbar-nav hidden">
      <li class="nav-item" ngbDropdown placement="bottom-right">
        <a href="javascript:;" class="nav-link" routerLinkActive="active" ngbDropdownToggle>
          <img src="assets/images/avatar.jpg" class="navbar-avatar rounded-circle" alt="user" title="user">
        </a>
        <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
          <a class="dropdown-item" routerLinkActive="active" href="javascript:;">
            <i class="icon icon-basic-settings mr-3"></i>
            <span>Settings</span>
          </a>
          <a class="dropdown-item" routerLinkActive="active" href="javascript:;">
            <i class="icon icon-basic-postcard mr-3"></i>
            <span>Profile</span>
          </a>
          <a class="dropdown-item"  routerLinkActive="active" href="javascript:;">
            <i class="icon icon-basic-message-multiple mr-3"></i>
            <span>Notifications</span>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="javascript:;" routerLinkActive="active">
            <i class="icon icon-arrows-switch-vertical mr-3"></i>
            <span>Signout</span>
          </a>
        </div>
      </li>
    </ul> -->
  </nav>

  <div class="main-content">
    <router-outlet></router-outlet>
  </div>
</div>
</ng-sidebar-container>

<ng-template #search let-c="close" let-d="dismiss">
<form class="search__form" action="">
  <input class="search-input" name="search" type="search" placeholder="Search..." autocomplete="off" autocorrect="off"
    autocapitalize="off" spellcheck="false" autofocus="true" />
  <p class="text-muted">
    <small>
      <strong>Hit enter to search or ESC to close</strong>
    </small>
  </p>
</form>
<div class="search-suggestions">
  <h6 class="text-uppercase">
    <strong>Suggestions?</strong>
  </h6>
  <p class="text-primary">
    #medical #analytics #fitness #transport #ui #dashboard #admin #bootstrap
    #angular #typescript
  </p>
</div>
<button type="button" class="search-close" aria-label="Close search form" (click)="d('Cross click')">
  <i class="fi flaticon-close"></i>
</button>
</ng-template>
<!-- <div class="configuration hidden-sm-down" [ngClass]="{'active': showSettings}">
  <div class="configuration-cog halfCircle" (click)="showSettings = !showSettings">
    <span><i class="fa fa-angle-left" aria-hidden="true"></i></span>
  </div>
  <div class="card">

    <div class="card-body">
      <div class="cardDetails">
        <ul>
          <li><a><span>Drugs</span></a></li>
          <li><a><span>Condition</span></a></li>
          <li><a><span>Drugs Interaction</span></a></li>
          <li><a><span>Calculator</span></a></li>
          <li><a><span>Drugs</span></a></li>
        </ul>
      </div>
    </div>
  </div>
</div> -->

<ng-template #searchResult let-c="close" let-d="dismiss">
<div class="popUps searchResult">
  <div class="modal-header">
    <div class="form-group m-0">
      <input type="text" class="form-control" />
      <button><img src="assets/images/searchIcon.svg" alt="" /></button>
    </div>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true"><img src="assets/images/close-btn.png" alt="" /></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content">
      <ul class="searchList">
        <li class="searchListContent">
          Nitish Salgaonkar (DOB: 02/02/2011)
          <a class="viewDetails">View Details</a>
        </li>
        <li class="searchListContent">
          Mini Salgaonkar (DOB: 15/14/1982)
          <a class="viewDetails">View Details</a>
        </li>
        <li class="searchListContent">
          Nitish Salgaonkar (DOB: 02/02/2011)
          <a class="viewDetails">View Details</a>
        </li>
        <li class="searchListContent">
          Mini Salgaonkar (DOB: 15/14/1982)
          <a class="viewDetails">View Details</a>
        </li>
      </ul>
      <div class="searchSection">
        <ul class="addNewSearchSection p-0">
          <li class="addNew li float-none text-left">
            <a class="btn btn-primary opd-primary-btn transformNormal btn-lg"><span>+</span> Add new patient</a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
</ng-template>

<ng-template #addNewPatient let-c="close" let-d="dismiss">
<div class="popUps">
  <div class="modal-header">
    <h6 class="modal-title text-uppercase">Add new patient</h6>
    <button type="button" class="close" aria-label="Close" (click)="d()">
      <span aria-hidden="true"><img src="assets/images/close-btn.png" alt="" /></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="model-content">
      <div class="row">
        <div class="col-12">
          <div class="form-group animateLabel">
            <input type="text" class="form-control animateInput" (focus)="addLabelClass($event)"
              (blur)="removeLabelClass($event)" />
            <label>Mobile Number</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group animateLabel">
            <input type="text" class="form-control animateInput" (focus)="addLabelClass($event)"
              (blur)="removeLabelClass($event)" />
            <label>First Name</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group animateLabel">
            <input type="text" class="form-control animateInput" (focus)="addLabelClass($event)"
              (blur)="removeLabelClass($event)" />
            <label>Last Name</label>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-5">
          <div class="form-group animateLabel calendarIcon">
            <input type="text" class="form-control animateInput" (focus)="addLabelClass($event)"
              (blur)="removeLabelClass($event)" />
            <label>Date of Birth</label>
            <img class="calendarbg" src="assets/images/calendarIcon.svg" alt="" />
          </div>
        </div>
        <div class="col-7 age">
          <div class="row">
            <div class="col-12">
              <label class="ifnoLabel pb-2">
                Age
              </label>
            </div>
            <div class="col-6">
              <div class="form-group">
                <input type="text" class="form-control animateInput" placeholder="00" />
                <label class="pt-1">Years</label>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <input type="text" class="form-control animateInput" placeholder="00" />
                <label class="pt-1">Months</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group mb-2">
            <label>Gender</label>
            <div class="form-check-inline">
              <label class="custom-control custom-radio {{
                  gender === 'Male' ? 'active-gender' : ''
                }}">
                <input class="custom-control-input" type="radio" name="gender" [(ngModel)]="gender" value="Male" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Male</span>
              </label>
            </div>
            <div class="form-check-inline">
              <label class="custom-control custom-radio {{
                  gender === 'Female' ? 'active-gender' : ''
                }}">
                <input class="custom-control-input" type="radio" name="gender" [(ngModel)]="gender" value="Female" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Female</span>
              </label>
            </div>
            <div class="form-check-inline">
              <label class="custom-control custom-radio {{
                  gender === 'notSpecified' ? 'active-gender' : ''
                }}">
                <input class="custom-control-input" type="radio" name="gender" [(ngModel)]="gender"
                  value="notSpecified" />
                <span class="custom-control-indicator"></span>
                <span class="custom-control-description">Not Specified</span>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group selectDropDown bloodGroup">
        <label>Blood Group</label>
        <div class="custom-droupdown dropdown btn-group" ngbDropdown>
          <button type="button" class="btn dropdown-toggle cust-toggle" ngbDropdownToggle>
            <span class="selected-name">B RH+</span>
            <span class="caret"></span>
          </button>
          <div ngbDropdownMenu class="dropdown-menu" role="menu">
            <a class="dropdown-item">O</a>
            <a class="dropdown-item">O+</a>
            <a class="dropdown-item">AB</a>
            <a class="dropdown-item">AB+</a>
            <a class="dropdown-item">B</a>
            <a class="dropdown-item">B+</a>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mt-3 mb-3">
          <button class="btn-primary opd-primary-btn btn-md transformNormal" type="button">
            Request OTP
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
</ng-template>