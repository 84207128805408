<div style="margin: 12px;">
    <div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="isLoading">
        <label for="" class="loader"></label>
    </div>

    <div style="display: flex; flex-direction: row; width: 80%; justify-content: space-between; margin-bottom: 20px;">
        <div class=" headingUpprcase18 newInputStyleLabel" style="text-transform: capitalize;margin-right: 7px">Patient
            wise Health App downloads
            report</div>

        <input type="text" placeholder="Search Name or Phone" class="form-control" #searchInput
            [(ngModel)]="searchString" (keyup)="searchAppointmentByNameNumber()"
            style="font-size: 15px; border-radius: 4px !important; padding: 8px !important; width: 300px;" />
    </div>
    <table class="table table-striped" style="width: 80%; border: 1px solid #eee;">
        <thead>
            <tr style="background-color: #f1f1f1; text-align: center;">
                <th scope="col">Patient Name</th>
                <th scope="col">Contact Number</th>
                <th scope="col">Download Status</th>
            </tr>
        </thead>
        <tbody>
            <tr style="background-color:#fff; text-align: center;" *ngFor="let patient of patients; index as i">
                <!-- <td *ngIf="patient.gender=='Female'">{{ patient.mothersName }} {{patient.lastName}}</td> -->
                <!-- *ngIf="patient.gender=='Male' || patient.gender==''" -->
                <td  >{{ patient.childsName }} {{patient.lastName}}
                </td>
                <td>{{ patient.contactNo }}</td>
                <td *ngIf="patient.fcmtoken">Yes</td>
                <td style="color: red;" *ngIf="!patient.fcmtoken">No</td>
            </tr>
        </tbody>
    </table>
</div>