import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from "../app/services/auth.service";
import { PatientService } from "../app/services/patient.service";
import { Router } from "@angular/router";

@Injectable()
export class PatientAuthGuard implements CanActivate {

  constructor(private authService: AuthService, private patientService: PatientService, private router: Router, private route: ActivatedRoute) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    //  let currentUrl = state.url.split('/')
    //  console.log(currentUrl)
    //  let patientId = localStorage.getItem('patientId')
    //  let userId = this.authService.getUser()
    //   //console.log(this.checkIfAuthorized(userId, patientId))

    //  //console.log(atob(patientId));
    //   let params = {
    //       user_id: userId,
    //       patient_id: patientId
    //     }
    //     this.patientService.checkIfAuthorized(params).subscribe(response => {

    //         if(!response.authorized){
    //           localStorage.setItem('url', state.url)
    //           localStorage.setItem('phone',response.phone_number)
    //           this.router.navigateByUrl(`doctor/patients/authorize/${patientId}`);
    //           return false
    //         }

    //     })
    return true
  }


}