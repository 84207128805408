<div style="display: flex; height: 100vh; width: 100%; flex-direction: column; flex: 1;">
    <div
        style="width: 100%; height: 60px; background-color: #fff;padding: 16px; display: flex; justify-content: space-between; align-items: center; margin-top: 16px;">

        <div style="display: flex; flex-direction: column; justify-content: center; ">
            <div class="heading" style="margin-top:0px">Certificate</div>
            <div class="subheading" style="width: 100%;">This feature is used to create various Certificate like
                Fitness, Adviced Rest,
                Vaccination Certificate etc.</div>
        </div>

        <div style="display: flex;flex-direction: row;">
            <button *ngIf="showdischarge==1" (click)="openDialog(dischargeModal,'dischargeModal');getdischargeSummary();"
                class="new_btn">Discharge Summary</button>

            <button (click)="openDialog(addCertificateModal,'addCertificateModal');openCertiModal();" class="new_btn"
                style="margin-left: 10px;">Create Certificate</button>

        </div>
    </div>
    <br>

    <div style="height: 88vh; width: 100%; overflow: scroll; margin: 4px 0px 0px 23px;">
        <div style="height:88vh; width: 100%; overflow: scroll">
            <ng-container [ngTemplateOutlet]="renderImmunization"
                [ngTemplateOutletContext]="{value:allPatientCertificate}">
            </ng-container>
        </div>
    </div>


    <ng-template #renderImmunization let-value="value">
        <table mat-table [dataSource]="value" style="width: 100%;">
            <ng-container>

                <!-- AGE -->
                <ng-container matColumnDef="srno" style="width:10%">
                    <th mat-header-cell *matHeaderCellDef> Sr No</th>
                    <td mat-cell *matCellDef="let element let j = index"
                        style="text-align: start !important; width:10%">
                        {{j+1}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="date" style="width:10%">
                    <th mat-header-cell *matHeaderCellDef class="theadUi"> Date </th>
                    <td mat-cell *matCellDef="let element" style="text-align: start !important; width:10%">
                        {{moment(element.documentDate).format('DD/MM/YYYY')}}
                    </td>
                </ng-container>
                <ng-container matColumnDef="documenttype">
                    <th mat-header-cell *matHeaderCellDef class="theadUi"> Document Types </th>
                    <td mat-cell *matCellDef="let element" style="text-align: start !important; width:10%">
                        {{element.documentType}}
                    </td>
                </ng-container>
                <!-- ACTION -->
                <ng-container matColumnDef="preview">
                    <th mat-header-cell *matHeaderCellDef class="theadUi">View </th>
                    <td mat-cell *matCellDef="let element" style="text-align: start !important;width: 10% !important">

                        <div class="actionBtn" (click)="onClickViewDocuments(element);"> View
                        </div>

                    </td>
                </ng-container>

                <!-- STATUS -->
                <ng-container matColumnDef="uploadedby" style="width:10%">
                    <th class="theadUi" mat-header-cell *matHeaderCellDef> Uploaded By </th>
                    <td mat-cell *matCellDef="let element"
                        style="text-align: start  !important; width: 10% !important;">
                        <span> {{element.uploadedBy}} </span>

                    </td>

                </ng-container>

            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true" style="background-color: #eee;"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
    </ng-template>
    <!-- <div style="display: flex; height: 100vh; width: 100%; flex-direction: column; flex: 1;">
    <div class="heading">Certificate</div>
    <div class="subheading">This feature is used to create various Certificate like Fitness, Adviced Rest,
        Vaccination Certificate etc.<br>

        <span style="font-size: 18px !important;color: #333;">Once your Certificate is issued, you will see it
            in the "Documents ==> All Documents ==> Certificates" Tab</span>

    </div>
    <div
        style="display: flex; background-color: #fff; height: 100%; width: 100vw; flex: 1; justify-content: center; align-items: center; flex-direction: column;">

        <img  style="height: 36%; width: 36%; margin-top:-100px;"
            src="assets/images/certificate_icon.svg" alt="" />

        <button (click)="openDialog(addCertificateModal,'addCertificateModal');" style="background-color: #5D86CC;
        padding: 8px 16px;
        border-radius: 6px;
        color: #fff;
        font-size: 18px;
        font-weight: bold;
        border: 0px;
        margin-top: 30px;
      outline: none;">Tap to create Certificate</button>

    </div>
</div> -->
</div>
<ng-template #addCertificateModal let-modal style="position: relative; top:115px">
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Create New Certificate</h4>
        <div style="display:flex; flex-direction: row;">

            <div ngbDropdown class="d-inline-block">
                <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;  border-bottom: 1px solid #ccc;
            padding-right: 0px;
            color: #333;
            background-color: #fff;
            margin-right: 4px;
            " ngbDropdownToggle>{{templateName}} </button>

                <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="top:-11px">
                    <button (click)="onDropdownSelectTemplate(item)" style="margin: 0px 16px 0px 0px !important"
                        *ngFor="let item of selectedTemplate; let i = index" ngbDropdownItem>{{item}}</button>
                </div>
            </div>

            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();closeAddReceiptModal()">
                <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body " style="width: 55vw; height: 65vh; display: flex; flex: 1; flex-direction: column;">
        <div style="width:100%;padding-left: 5px; margin-top: 4px;margin-bottom: 4px ">
            <div class="row">
                <div class="form-group selectDropDown bloodGroup onFocus"
                    style="width: 96%; margin-left: 10px;margin-top: 5px;margin-bottom: 5px;">
                    <label class="newInputStyleLabel" style="font-size: 14px;">Select Certificate
                    </label>
                    <div *ngIf="isPediatric">
                        <div ngbDropdown class="d-inline-block">
                            <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;  border-bottom: 1px solid #ccc;
                        padding-right: 0px;
                        color: #333;
                        background-color: #fff;
                        margin-right: 4px;
                        " ngbDropdownToggle>{{selectedCertiType}} </button>

                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="top:-11px">
                                <button (click)="onDropdownSelectCerti(item)"
                                    style="margin: 0px 16px 0px 0px !important"
                                    *ngFor="let item of perCertificate; let i = index" ngbDropdownItem>{{item}}</button>
                            </div>
                        </div>


                    </div>
                    <div *ngIf="!isPediatric">
                        <div ngbDropdown class="d-inline-block">
                            <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;  border-bottom: 1px solid #ccc;
                        padding-right: 0px;
                        color: #333;
                        background-color: #fff;
                        margin-right: 4px;
                        " ngbDropdownToggle>{{selectedCertiType}} </button>

                            <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="top:-11px">
                                <button (click)="onDropdownSelectCerti(item)"
                                    style="margin: 0px 16px 0px 0px !important"
                                    *ngFor="let item of adultCertificate; let i = index"
                                    ngbDropdownItem>{{item}}</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div *ngIf="showOtherTextBox" style="display: flex; flex-direction: row;">
            <div style="margin-left: 3px;width: 551px; margin-top: 5px;">
                <label class="fieldsetlable">Form </label>

                <input placeholder="DD/MM/YYYY" type="text" [maxDate]="date" [minDate]="{year:1920,month:1,day:1}"
                    id="dobInput" class="form-control " [(ngModel)]="selectedDate" ngbDatepicker #d="ngbDatepicker"
                    readonly (click)="d.toggle()" (ngModelChange)="dateCovert()"
                    style="border-radius: 4px !important;width: 180px;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;">

                <img (click)="d.toggle()" class="calendarbg" style="top: 30px; right: 8px; width: 25px; padding-left: 5px;margin-bottom: 5px;
            margin-top: -51px;
            margin-left: 140px;" src="assets/images/calendarIcon.svg" alt="" />
            </div>
            <div class="animateLabel calendarIcon mb-0 pt-0 calender"
                style="margin-left: 0px;width: 551px; margin-top: 5px;">
                <label class="fieldsetlable">To </label>
                <div>

                    <input [(ngModel)]="toDatee" [maxDate]="date" [minDate]="{year:1920,month:1,day:1}" ngbDatepicker
                        #dfrom="ngbDatepicker" (focus)="dfrom.open()" type="text" class="form-control"
                        placeholder="DD/MM/YYYY" name="" (ngModelChange)="dateCovert()"
                        (document:click)="closeCalender($event,dfrom)"
                        style="border-radius: 4px !important;width: 180px;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;"
                        readonly>

                    <img (click)="dfrom.toggle()" class="calendarbg" style="top: 57px; right: 45px; width: 25px; padding-left: 5px;margin-bottom: 5px;
        margin-top: -51px;
        margin-left: 140px;" src="assets/images/calendarIcon.svg" alt="" />
                </div>
            </div>

            <div style="margin-left: -8px;width: 551px; margin-top: 5px;">
                <label class="fieldsetlable">Diagnosis </label>

                <input placeholder="Diagnosis" type="text" class="form-control" (ngModelChange)="modelChangeFn($event)"
                    [ngModel]="duration"
                    style="border-radius: 4px !important;width: 180px;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;">


            </div>
            <div class="animateLabel calendarIcon mb-0 pt-0 calender"
                style="margin-left: 0px;width: 551px; margin-top: 5px;">
                <label class="fieldsetlable">Resume Date </label>
                <div>

                    <input [(ngModel)]="resumeDate" ngbDatepicker #dto="ngbDatepicker" (focus)="dto.open()" type="text"
                        class="form-control" placeholder="DD/MM/YYYY" name="" (ngModelChange)="dateCovert()"
                        (document:click)="closeCalender($event,dto)"
                        style="border-radius: 4px !important;width: 180px;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;"
                        readonly>

                    <img (click)="dto.toggle()" class="calendarbg" style="top: 57px; right: 45px; width: 25px; padding-left: 5px;margin-bottom: 5px;
margin-top: -51px;
margin-left: 140px;" src="assets/images/calendarIcon.svg" alt="" />
                </div>
            </div>
        </div>

        <div *ngIf="showOtherTextBox5">

            <div style="display: flex;flex-direction: row;">
                <!-- <label class="fieldsetlable">Select Vaccines administered</label> -->

                <input placeholder="Select Vaccines administered" [(ngModel)]="chief_testVacc" class="form-control"
                    (ngModelChange)="dateCovert()"
                    style="border-radius: 4px !important;width: 70%;  color: #333 !important; font-size: 1rem !important;margin-left: 4px;">
                <div class="actionBtn"
                    style="width: 22%; padding-top: 6px; margin-left: 15px; background-color: #5D86CC; color: #fff;"
                    (click)="open(addVaccModal);"> Select vaccinations
                </div>
            </div>




        </div>
        <div *ngIf="showOtherTextBox4" style="display: flex; flex-direction: row;">

            <div style="margin-left: -8px;width: 551px; margin-top: 5px;">
                <label class="fieldsetlable">Duration </label>

                <input placeholder="Duration" type="text" class="form-control" [(ngModel)]="duration"
                    style="border-radius: 4px !important;width: 367px;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;">


            </div>

            <div style="margin-left: -8px;width: 551px; margin-top: 5px;">
                <label class="fieldsetlable">Medical Condition</label>

                <input placeholder="Medical Condition" type="text" class="form-control" [(ngModel)]="medicalCondtion"
                    style="border-radius: 4px !important;width:367px;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;">


            </div>
        </div>
        <div *ngIf="showOtherAddmission" style="display: flex; flex-direction: row;">

            <div style="margin-left: -8px;width: 551px; margin-top: 5px;">
                <label class="fieldsetlable">Hospital Name</label>

                <input placeholder="Hospital Name" type="text" class="form-control" [(ngModel)]="hospitalName"
                    style="border-radius: 4px !important;width: 367px;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;">


            </div>

            <div style="margin-left: 0px;width: 551px; margin-top: 5px;">
                <label class="fieldsetlable">Hospital City</label>

                <input placeholder="Hospital City" type="text" class="form-control" [(ngModel)]="hospitalCity"
                    style="border-radius: 4px !important;width:367px;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;">


            </div>
        </div>
        <div *ngIf="showOtherTextBox1" style="display: flex; flex-direction: row;">
            <div style="margin-left: 3px;width: 551px; margin-top: 5px;">
                <label class="fieldsetlable">Form </label>

                <input placeholder="DD/MM/YYYY" type="text" [maxDate]="date" [minDate]="minDate" id="dobInput1"
                    class="form-control " [(ngModel)]="selectedDate" ngbDatepicker #d="ngbDatepicker" readonly
                    (click)="d.toggle()" (ngModelChange)="dateCovert()"
                    style="border-radius: 4px !important;width: 230px;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;">

                <img (click)="d.toggle()" class="calendarbg" style="top: 30px; right: 8px; width: 25px; padding-left: 5px;margin-bottom: 5px;
        margin-top: -51px;
        margin-left: 198px;" src="assets/images/calendarIcon.svg" alt="" />
            </div>

            <div style="margin-left: -8px;width: 551px; margin-top: 5px;">
                <label class="fieldsetlable">Duration </label>

                <input placeholder="Duration" type="text" class="form-control" [(ngModel)]="duration"
                    style="border-radius: 4px !important;width: 230px;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;">


            </div>

            <div style="margin-left: -8px;width: 551px; margin-top: 5px;">
                <label class="fieldsetlable">Medical Condition</label>

                <input placeholder="Medical Condition" type="text" class="form-control" [(ngModel)]="medicalCondtion"
                    style="border-radius: 4px !important;width: 230px;  color: #8C8B8C !important; font-size: 1rem !important;margin-left: 4px;">


            </div>
        </div>


        <div *ngIf="!showVaccinationDue && !showTotalDueVaccination"
            style="display:flex; flex-direction: row; justify-content: space-between; flex: 1; margin-right: 15px;">
            <div class="newInputStyleLabel" style="font-size: 14px;margin-left: 5px;">Message Preview</div>

            <div style="color: #8B8B8B;">(Editable)</div>
        </div>

        <div *ngIf="showOtherTextBox0" style="padding-top: 20px; width: 735px;" contentEditable="true" type="text"
            cols="40" rows="10" class="text-area-style" data-id="2"></div>
        <div id="fitToJoinWorkPostIllness" *ngIf="showOtherTextBox" style="padding-top: 20px;" contentEditable="true"
            type="text" cols="40" rows="10" class="text-area-style" data-id="2">
            <div *ngIf="isPediatric" style="  margin-left: 5px;">
                <!-- This is to certify
                that
                {{first_name}} {{last_name}} ({{gender}}), {{relationwithcust}} of was
                unwell from {{fromDateVacc}}
                To.{{toDateVacc}} {{genderHe}} was hospitalised for {{duration}} days.{{genderHe}} is now recovered
                and
                can attend school in full capacity from {{resumeDateVacc}} -->

                This is to certify that {{first_name}} {{last_name}} ({{gender}} &nbsp; <span *ngIf="ageYears > 0">{{ ageYears }} year<span *ngIf="ageYears > 1">s</span></span>
                <span *ngIf="ageMonths > 0">
                  <span *ngIf="ageYears > 0">&nbsp; </span >
<span *ngIf="ageMonths > 0">
    {{ ageMonths }} 
    <span *ngIf="ageMonths == 1">month</span>

    <span *ngIf="ageMonths > 1">months</span>
</span>
                </span>
                <span *ngIf="ageInDays > 0">
                  <span *ngIf="ageYears > 0 || ageMonths > 0">&nbsp;</span>{{ ageInDays }} day<span *ngIf="ageInDays > 1">s</span>
                </span>), was
                under my treatment from {{fromDateVacc}} to {{toDateVacc}} for {{duration}} {{genderHe}}
                was advised complete rest and treatment. {{genderHe}} is now recovered and medically
                fit to resume school w.e.f {{resumeDateVacc}}

            </div>
            <br>
            <div *ngIf="!isPediatric" style="  margin-left: 5px;">
                <!-- This is to certify
                that {{first_name}} {{last_name}} ({{gender}}) was unwell from
                {{fromDateVacc}}
                To.{{toDateVacc}} {{genderHe}} was hospitalised for {{duration}} days.{{genderHe}} is now recovered
                and
                can attend duties in full capacity from {{resumeDateVacc}} -->

                This is to certify that {{first_name}} {{last_name}}( {{gender}} &nbsp;  <span *ngIf="ageYears > 0">{{ ageYears }} year<span *ngIf="ageYears > 1">s</span></span>
                <span *ngIf="ageMonths > 0">
                  <span *ngIf="ageYears > 0">&nbsp; </span >
<span *ngIf="ageMonths > 0">
    {{ ageMonths }} 
    <span *ngIf="ageMonths == 1">month</span>

    <span *ngIf="ageMonths > 1">months</span>
</span>
                </span>
                <span *ngIf="ageInDays > 0">
                  <span *ngIf="ageYears > 0 || ageMonths > 0">&nbsp;</span>{{ ageInDays }} day<span *ngIf="ageInDays > 1">s</span>
                </span>
               ), was
                under my treatment from {{fromDateVacc}} to {{toDateVacc}} for {{duration}} {{genderHe}}
                was advised complete rest and treatment .{{genderHe}} is now recovered and medically
                fit to resume duties w.e.f {{resumeDateVacc}}

            </div>
        </div>
        <div id="advisedRest" *ngIf="showOtherTextBox1" style="padding-top: 20px;" contentEditable="true" type="text"
            cols="40" rows="10" class="text-area-style" data-id="2">
            <div *ngIf="isPediatric" style="  margin-left: 5px;">This is to certify that {{first_name}} {{last_name}} ({{gender}} &nbsp;  <span *ngIf="ageYears > 0">{{ ageYears }} year<span *ngIf="ageYears > 1">s</span></span>
                <span *ngIf="ageMonths > 0">
                  <span *ngIf="ageYears > 0">&nbsp; </span >
<span *ngIf="ageMonths > 0">
    {{ ageMonths }} 
    <span *ngIf="ageMonths == 1">month</span>

    <span *ngIf="ageMonths > 1">months</span>
</span>
                </span>
                <span *ngIf="ageInDays > 0">
                  <span *ngIf="ageYears > 0 || ageMonths > 0">&nbsp;</span>{{ ageInDays }} day<span *ngIf="ageInDays > 1">s</span>
                </span>
               ),  
                <!-- {{relationwithcust}} of -->
                 was
                unwell from {{fromDateVacc}}
                {{genderHe}} had {{medicalCondtion}} {{genderHe}} is advised rest for {{duration}} days.{{genderHe}}
                can
                join school as symptoms subside. </div>
            <br>
            <div *ngIf="!isPediatric" style="  margin-left: 5px;">This is to certify
                that {{first_name}} {{last_name}} ({{gender}} &nbsp;  <span *ngIf="ageYears > 0">{{ ageYears }} year<span *ngIf="ageYears > 1">s</span></span>
                <span *ngIf="ageMonths > 0">
                  <span *ngIf="ageYears > 0">&nbsp; </span >
<span *ngIf="ageMonths > 0">
    {{ ageMonths }} 
    <span *ngIf="ageMonths == 1">month</span>

    <span *ngIf="ageMonths > 1">months</span>
</span>
                </span>
                <span *ngIf="ageInDays > 0">
                  <span *ngIf="ageYears > 0 || ageMonths > 0">&nbsp;</span>{{ ageInDays }} day<span *ngIf="ageInDays > 1">s</span>
                </span>
               ) was unwell from
                {{fromDateVacc}}
                {{genderHe}} had {{medicalCondtion}} {{genderHe}} is advised rest for {{duration}} days.{{genderHe}}
                can
                join work as symptoms subside. </div>
        </div>
        <div id="fitnessCertificate" *ngIf="showOtherTextBox2" style="padding-top: 20px;" contentEditable="true"
            type="text" cols="40" rows="10" class="text-area-style" data-id="2">
            <div *ngIf="isPediatric" style="  margin-left: 5px;"> This is to certify
                that {{first_name}} {{last_name}} ({{gender}} &nbsp;  <span *ngIf="ageYears > 0">{{ ageYears }} year<span *ngIf="ageYears > 1">s</span></span>
                <span *ngIf="ageMonths > 0">
                  <span *ngIf="ageYears > 0">&nbsp; </span >
<span *ngIf="ageMonths > 0">
    {{ ageMonths }} 
    <span *ngIf="ageMonths == 1">month</span>

    <span *ngIf="ageMonths > 1">months</span>
</span>
                </span>
                <span *ngIf="ageInDays > 0">
                  <span *ngIf="ageYears > 0 || ageMonths > 0">&nbsp;</span>{{ ageInDays }} day<span *ngIf="ageInDays > 1">s</span>
                </span>
               )
                is
                physically fit and mentally well and alert child. {{genderHe}} has no known allergy. {{genderHe}} has
                taken age
                appropriate vaccination. Blood group {{blood_group}} for your records.

            </div>
            <div *ngIf="!isPediatric" style="  margin-left: 5px;"> This is to
                certify
                that {{first_name}} {{last_name}} ({{gender}} &nbsp;  <span *ngIf="ageYears > 0">{{ ageYears }} year<span *ngIf="ageYears > 1">s</span></span>
                <span *ngIf="ageMonths > 0">
                  <span *ngIf="ageYears > 0">&nbsp; </span >
<span *ngIf="ageMonths > 0">
    {{ ageMonths }} 
    <span *ngIf="ageMonths == 1">month</span>

    <span *ngIf="ageMonths > 1">months</span>
</span>
                </span>
                <span *ngIf="ageInDays > 0">
                  <span *ngIf="ageYears > 0 || ageMonths > 0">&nbsp;</span>{{ ageInDays }} day<span *ngIf="ageInDays > 1">s</span>
                </span>
               ) is physically fit and
                mentally well and alert person. {{genderHe}} has no known allergy. {{genderHe}} has vaccination
                certificate
                (known epidemics).Blood group is {{blood_group}} for your records.
            </div>

        </div>
        <div id="travelCertificate" *ngIf="showOtherTextBox3" style="padding-top: 20px;" contentEditable="true"
            type="text" cols="40" rows="10" class="text-area-style" data-id="2">
            <div id="isPed" style="  margin-left: 5px;">This is to certify that
                {{first_name}} {{last_name}} ({{gender}} &nbsp;  <span *ngIf="ageYears > 0">{{ ageYears }} year<span *ngIf="ageYears > 1">s</span></span>
                <span *ngIf="ageMonths > 0">
                  <span *ngIf="ageYears > 0">&nbsp; </span >
<span *ngIf="ageMonths > 0">
    {{ ageMonths }} 
    <span *ngIf="ageMonths == 1">month</span>

    <span *ngIf="ageMonths > 1">months</span>
</span>
                </span>
                <span *ngIf="ageInDays > 0">
                  <span *ngIf="ageYears > 0 || ageMonths > 0">&nbsp;</span>{{ ageInDays }} day<span *ngIf="ageInDays > 1">s</span>
                </span>
               ), has vaccination
                certificates(known epidemics) and physically and mentally fit to travel by all available mode of
                transportation. </div> <br>

        </div>

        <div id="midDayMeal" *ngIf="showOtherTextBox4" style="padding-top: 20px;" contentEditable="true" type="text"
            cols="40" rows="10" class="text-area-style" data-id="2">
            <div style="  margin-left: 5px;">This is to certify that {{first_name}}
                {{last_name}} ({{gender}} &nbsp;  <span *ngIf="ageYears > 0">{{ ageYears }} year<span *ngIf="ageYears > 1">s</span></span>
                <span *ngIf="ageMonths > 0">
                  <span *ngIf="ageYears > 0">&nbsp; </span >
<span *ngIf="ageMonths > 0">
    {{ ageMonths }} 
    <span *ngIf="ageMonths == 1">month</span>

    <span *ngIf="ageMonths > 1">months</span>
</span>
                </span>
                <span *ngIf="ageInDays > 0">
                  <span *ngIf="ageYears > 0 || ageMonths > 0">&nbsp;</span>{{ ageInDays }} day<span *ngIf="ageInDays > 1">s</span>
                </span>
               ),
                <!-- {{relationwithcust}} of -->
                had {{medicalCondtion}} {{genderHe}} advised to take home food for next {{duration}} days. </div>
            <br>


        </div>
        <div id="vaccinationCertificate" *ngIf="showOtherTextBox5" style="padding-top: 20px;" contentEditable="true"
            type="text" cols="40" rows="10" class="text-area-style" data-id="2">


            <div style="  margin-left: 5px;"> This is to certify that {{first_name}}
                {{last_name}} ({{gender}} &nbsp;  <span *ngIf="ageYears > 0">{{ ageYears }} year<span *ngIf="ageYears > 1">s</span></span>
                <span *ngIf="ageMonths > 0">
                  <span *ngIf="ageYears > 0">&nbsp; </span >
<span *ngIf="ageMonths > 0">
    {{ ageMonths }} 
    <span *ngIf="ageMonths == 1">month</span>

    <span *ngIf="ageMonths > 1">months</span>
</span>
                </span>
                <span *ngIf="ageInDays > 0">
                  <span *ngIf="ageYears > 0 || ageMonths > 0">&nbsp;</span>{{ ageInDays }} day<span *ngIf="ageInDays > 1">s</span>
                </span>
               )
               <!-- {{relationwithcust}}  -->
                , has taken age appropriate
                vaccinations, details
                are below.<br><br></div><br>
            <br>
        </div>
        <div id="admissionCertificate" *ngIf="showOtherAddmission" style="padding-top: 20px;" contentEditable="true"
            type="text" cols="40" rows="10" class="text-area-style" data-id="2">

            <div *ngIf="isPediatric" style="  margin-left: 5px;"> Please admit
                {{typeOfGender}} {{first_name}} {{last_name}} 

                Under my care in G.W/ Semi Private /NICU/ PICU
                Please inform me upon admission.<br></div><br>

            <div *ngIf="!isPediatric" style="  margin-left: 5px;"> Please admit
                {{typeOfGender}} {{first_name}} {{last_name}} ({{gender}} &nbsp;  <span *ngIf="ageYears > 0">{{ ageYears }} year<span *ngIf="ageYears > 1">s</span></span>
                <span *ngIf="ageMonths > 0">
                  <span *ngIf="ageYears > 0">&nbsp; </span >
<span *ngIf="ageMonths > 0">
    {{ ageMonths }} 
    <span *ngIf="ageMonths == 1">month</span>

    <span *ngIf="ageMonths > 1">months</span>
</span>
                </span>
                <span *ngIf="ageInDays > 0">
                  <span *ngIf="ageYears > 0 || ageMonths > 0">&nbsp;</span>{{ ageInDays }} day<span *ngIf="ageInDays > 1">s</span>
                </span>
               ) Under my care in G.W/ Semi
                Private /NICU/ PICU
                Please inform me upon admission.</div><br>

            <br>







        </div>

        <div *ngIf="showVaccinationDue">

            <div style="height: 45vh; width: 55vw; overflow: scroll;">

                <div style="height:45vh; width: 55vw; overflow: scroll">
                    <ng-container [ngTemplateOutlet]="renderImmunization"
                        [ngTemplateOutletContext]="{value:allVaccinDueList}">
                    </ng-container>
                </div>
            </div>

            <ng-template #renderImmunization let-value="value">
                <table mat-table [dataSource]="value" style="width: 55vw; margin-top: 8px;">
                    <ng-container>
                        <!-- VISIT -->
                        <ng-container matColumnDef="visit">
                            <th mat-header-cell *matHeaderCellDef style="text-align:start !important"> Visit </th>
                            <td mat-cell *matCellDef="let element"
                                style="text-align: start !important; width:20%; font-weight: bold;">
                                <mat-checkbox class="example-margin" (change)="getVaccData(element)"></mat-checkbox>
                                {{element.visitName}}

                            </td>
                        </ng-container>

                        <!-- VACCINE -->
                        <ng-container matColumnDef="vaccine">
                            <th mat-header-cell *matHeaderCellDef style="text-align:start !important"> Vaccine Name
                            </th>
                            <td mat-cell *matCellDef="let element"
                                style="text-align: start !important; width: 15% !important;">
                                {{element.name}}

                            </td>
                        </ng-container>

                        <!-- RECOMMENDED DATE -->
                        <ng-container matColumnDef="recommendedDate">
                            <th mat-header-cell *matHeaderCellDef style="text-align: center"> Recommended Date </th>
                            <td mat-cell *matCellDef="let element" style="width: 15% !important; text-align: start;">
                                {{element.dueDate | date:"dd-MM-YYYY"}}

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef style="text-align: center"> Date </th>
                            <td mat-cell *matCellDef="let element" style="width: 10% !important; text-align: start;">
                                <mat-form-field style="width: 130px;">
                                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker"
                                        (dateChange)="onDateChangeForVacc($event)" [(ngModel)]="element.date"
                                        placeholder="Choose a date" required>
                                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>

                                </mat-form-field>
                            </td>
                        </ng-container>


                        <!-- CATCHUP DATE -->


                        <!-- STATUS -->


                        <!-- RECEIVED DATE -->


                        <!-- BRAND -->


                        <!-- ACTION   -->


                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsForVaccDue; sticky:true"
                        style="background-color: #eee;"></tr>
                    <tr mat-row [ngClass]="{'highlight': selectedRowIndex == row.id}"
                        *matRowDef="let row; columns: displayedColumnsForVaccDue;" (click)="selectedRows(row)"></tr>
                </table>
            </ng-template>

            <!-- <div  style="padding-top: 0px; width: 735px; height: 100px !important" contentEditable="true" type="text"
        cols="40" rows="10" class="text-area-style" data-id="2">
        <div style="text-align: start;color: #000;">{{first_name}} {{last_name}} ({{gender}})<br>
        
        </div>
        <div id="dueVaccinationContent">As of date the following vaccines records are  not available, Consider all above vaccines<br>
         as necessary, You are advised to the take marked vaccines on priority as per the recommended date.
        </div>
    </div> -->
        </div>



        <div *ngIf="showTotalDueVaccination">
            <div style="height: 45vh; width: 55vw; overflow: scroll;">
                <div style="height:45vh; width: 55vw; overflow: scroll">
                    <ng-container [ngTemplateOutlet]="renderImmunization"
                        [ngTemplateOutletContext]="{value:allVaccinDueList}">
                    </ng-container>
                </div>
            </div>
            <ng-template #renderImmunization let-value="value">
                <table mat-table [dataSource]="value" style="width: 55vw; margin-top: 8px;">
                    <ng-container>
                        <!-- VISIT -->
                        <ng-container matColumnDef="visit">
                            <th mat-header-cell *matHeaderCellDef style="text-align:start !important"> Visit </th>
                            <td mat-cell *matCellDef="let element"
                                style="text-align: start !important; width:25%; font-weight: bold;">
                                {{element.visitName}}

                            </td>
                        </ng-container>

                        <!-- VACCINE -->
                        <ng-container matColumnDef="vaccine">
                            <th mat-header-cell *matHeaderCellDef style="text-align:start !important"> Vaccine Name
                            </th>
                            <td mat-cell *matCellDef="let element"
                                style="text-align: start !important; width: 48% !important;">
                                {{element.name}}

                            </td>
                        </ng-container>

                        <!-- RECOMMENDED DATE -->
                        <ng-container matColumnDef="recommendedDate">
                            <th mat-header-cell *matHeaderCellDef style="text-align: center"> Recommended Date </th>
                            <td mat-cell *matCellDef="let element" style="width: 20% !important; text-align: center;">
                                {{element.dueDate | date:"dd-MM-YYYY"}}

                            </td>
                        </ng-container>
                        <ng-container matColumnDef="date">
                            <th mat-header-cell *matHeaderCellDef style="text-align: center"> </th>
                            <td mat-cell *matCellDef="let element" style="width: 10% !important; text-align: start;">

                            </td>
                        </ng-container>


                        <!-- CATCHUP DATE -->


                        <!-- STATUS -->


                        <!-- RECEIVED DATE -->


                        <!-- BRAND -->


                        <!-- ACTION   -->


                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="displayedColumnsForVaccDue; sticky:true"
                        style="background-color: #eee;"></tr>
                    <tr mat-row [ngClass]="{'highlight': selectedRowIndex == row.id}"
                        *matRowDef="let row; columns: displayedColumnsForVaccDue;" (click)="selectedRows(row)"></tr>
                </table>
            </ng-template>


        </div>
        <div style="display: flex; flex: 1;">
            <button type="submit" class=" opd-primary-btn btn-md w125 newBtnStyle"
                style="margin-left: 84%;margin-top: 0%;text-transform: capitalize;"
                (click)="printNewCertificate('preview','share')">Preview</button>
        </div>
    </div>


</ng-template>

<ng-template #viewCertiModal let-modal>
    <div>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Certificate</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">
                <span aria-hidden="true" style="font-size: 40px;color: #000;">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="display: flex;  height: 70vh; margin-top: 0px; width: 70vw;">
                <embed [attr.src]="certiPdfURL" type="application/pdf" width="100%" height="100%">
            </div>

            <br>

            <div style="display:flex; flex-direction: row; justify-content: flex-end; flex: 1; margin-bottom: 10px;">
                <!-- <div (click)="editCert();" class="quickrx" > Edit</div> -->

                <div (click)="printNewCertificate('share');closeDialog();" class="quickrx">Save & share
                </div>
            </div>



        </div>

    </div>
</ng-template>
<ng-template #addVaccModal let-modal>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">Select Vaccines</h4>

        <div style="display:flex; flex-direction: row;  margin-right: 30px;">



            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="modal.dismiss('Cross click');">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
    </div>
    <div class="modal-body">
        <mat-chip-list aria-label="Fish selection">

            <mat-chip color="primary" (click)="selectAllVaccination()">Select All</mat-chip>

        </mat-chip-list>
        <form>

            <div class="form-group">


                <div style="height: 60vh; overflow: scroll;">
                    <div
                        style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw;   ">

                        <div *ngFor="let item of allAdministeredVaccines let i = index"
                            class="searchable-component-style" style="display: flex;
                        flex-direction: row;
                        border: 1px solid #eee;
                        border-radius: 6px;
                        padding: 6px;
                        background-color: #F3F6FA;
                        align-items: center;
                        margin: 8px;
                        height: 40px;
                        cursor: pointer;" (click)="selectModalTestVaccValue(item)">
                            <img *ngIf="item.selected != true" style="margin: 0px 8px 0px 0px;height:16px;width:16px;"
                                src="assets/images/tick_mark_grey.svg" class="tick-icons" />

                            <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px;height:16px;width:16px;"
                                src="assets/images/tick_mark_color.svg" class="tick-icons" />
                            <div>{{item.name}} ({{item.brandvaccine}}) :{{item.administeredDate}}</div>
                        </div>
                    </div>
                </div>


            </div>
        </form>
    </div>
    <div class="modal-footer">



        <div (click)="saveModalTestVaccValues();modal.dismiss()" class="btn-type-filled" style="margin: 0px 8px 0px 8px;
              cursor: pointer;
              padding: 8px 16px 8px 16px;
              border-radius: 6px;
              background-color: #4687BF;
              color: #FFF;
              border-width: 0px;
              text-align: center;
              font-weight: bold;
              height: 40px">Proceed</div>


    </div>
</ng-template>

<ng-template #viewDocumentsModal let-modal>
    <div style="width: 80vw;">
        <div class="modal-header" style="border-bottom: 0px; padding: 16px 16px 16px 0px; width: 76vw;">
            <h4 class="modal-title" id="modal-basic-title">View Document</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">
                <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="display: flex;  height: 80vh;">
                <embed [attr.src]="documentPdfURL" type="application/pdf" width="100%" height="100%">
            </div>
        </div>
    </div>
</ng-template>
<ng-template #viewSaveDischargeModal let-modal>
    <div style="width: 80vw;">
        <div class="modal-header" style="border-bottom: 0px; padding: 16px 16px 16px 0px; width: 76vw;">
            <h4 class="modal-title" id="modal-basic-title">View Discharge Summary</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">
                <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="display: flex;  height: 80vh;">
                <embed [attr.src]="documentPdfURL" type="application/pdf" width="100%" height="100%">
            </div>
        </div>
    </div>
</ng-template>
<ng-template #previewDischargeModal let-modal >
    <div style="width: 55vw;">
        <div class="modal-header" style="border-bottom: 0px; padding: 16px 16px 16px 0px;  ">
            <h4 class="modal-title" id="modal-basic-title">Preview</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">

                <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
            </button>
        </div>
        <div style="display: flex;align-items: center;justify-content: center; ">
            <div style=" display:flex ;  background-color: #f1efef; height: 80vh;  width: 100%;
            overflow-y: scroll;">

                <div class="modal-body" style="padding: 0px 0px 0px 12px; ">

                    <div style="display: flex;flex-direction: row; justify-content: center; align-items: center;">
                        <h3 style="margin: 16PX;"> Discharge Summary</h3>

                    </div>
                    <!-- first row  -->
                    <div style="display: flex;flex-direction: row; " class="previewDataRow">
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">Care Of By Doctor:-</mat-label>
                            <span class="previewLabelData"> &nbsp;{{ refbyDr }}</span>
                        </div>
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">Address:</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ address }}</pre>
                          </div>
                        
                    </div>
                    <!-- second row  -->
                    <div style="display: flex;flex-direction: row; " class="previewDataRow">
                        <!-- Date of Admission -->
                        <div style="width: 48%; ">
                            <mat-label class="previewLabel">Date of Admission-</mat-label>
                            <span class="previewLabelData"> &nbsp;{{ dateOfAdmission | date: 'dd/MM/yyyy' }}</span>
                        </div>

                        <!-- Date of Discharge -->
                        <div style="width: 48%; ">
                            <mat-label class="previewLabel">Date of Discharge-</mat-label>
                            <span class="previewLabelData"> &nbsp;{{ dateOfDischarge | date: 'dd/MM/yyyy' }}</span>
                        </div>
                    </div>

                    <div style="display: flex;flex-direction: row; " class="previewDataRow">
                        <!-- Third Pair of Fields -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">Wt.OA-</mat-label>
                            <span class="previewLabelData"> &nbsp;{{ wtoa }}</span>
                        </div>

                        <div style="width: 48%; ">
                            <mat-label class="previewLabel">Wt.OD-</mat-label>
                            <span class="previewLabelData"> &nbsp;{{ wtod }}</span>
                        </div>
                    </div>

                    <div style="display: flex; flex-direction: row; " class="previewDataRow">
                        <!-- Fourth Pair of Fields -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">Diagnosis:</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ diagnosis }}</pre>
                          </div>
                      

                        <div style="width: 48%; ">
                            <mat-label class="previewLabel">C/O-</mat-label>
                            <span class="previewLabelData"> &nbsp;{{ co }}</span>
                        </div>
                    </div>




                    <div style="display: flex; flex-direction: row;" class="previewDataRow">
                        <!-- Fifth Pair of Fields -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">HOPD:</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ hopd }}</pre>
                          </div>
                   
                          <div style="width: 48%;">
                            <mat-label class="previewLabel">Condition on Discharge-</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ dischargeCondition }}</pre>
                          </div>

                    
                    </div>

                    <div style="display: flex; flex-direction: row;" class="previewDataRow">
                        <!-- Sixth Pair of Fields -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">On Discharge Rx-</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ onDischargeRx }}</pre>
                          </div>
                   

                          <div style="width: 48%;">
                            <mat-label class="previewLabel">Advise -</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ advise }}</pre>
                          </div>
                      
                    </div>


                    <div style="display: flex;flex-direction: row; margin-top: 10px; ">
                        <h3  > On Examination: </h3>



                    </div>
                    <div style="display: flex; flex-direction: row;" class="previewDataRow">
                        <!-- First Pair of Fields -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">HR-</mat-label>
                            <span class="previewLabelData"> &nbsp;{{ hr }}</span>
                        </div>

                        <div style="width: 48%;">
                            <mat-label class="previewLabel">RR-</mat-label>
                            <span class="previewLabelData"> &nbsp;{{ rr }}</span>
                        </div>
                    </div>
                    <div style="display: flex; flex-direction: row;" class="previewDataRow"> 
                        <!-- Second Pair of Fields -->
                        <div style="width: 48%; margin-top: 10px;">
                            <mat-label class="previewLabel">BP-</mat-label>
                            <span class="previewLabelData"> &nbsp;{{ bp }}</span>
                        </div>

                        <div style="width: 48%;  margin-top: 10px;">
                            <mat-label class="previewLabel">SPO2.-</mat-label>
                            <span class="previewLabelData"> &nbsp;{{ spo2 }}</span>
                        </div>
                    </div>






                    <div style="display: flex;flex-direction: row; margin-top: 10px; ">
                        <h3  >Systemic Examination: </h3>
                    </div>
                    <div style="display: flex; flex-direction: row;" class="previewDataRow">
                        <!-- First Pair of Fields -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">RS -</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ rs }}</pre>
                          </div>

                    
                          <div style="width: 48%;">
                            <mat-label class="previewLabel">CVS -</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ cvs }}</pre>
                          </div>
                 
                    </div>

                    <div style="display: flex; flex-direction: row;" class="previewDataRow">

                        <!-- Second Pair of Fields -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">CNS -</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ cns }}</pre>
                          </div>

                          <div style="width: 48%;">
                            <mat-label class="previewLabel">PA -</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ pa }}</pre>
                          </div>
                    </div>


                    <div style="display: flex; flex-direction: row;" class="previewDataRow">

                        <!-- X-ray Findings -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">X-ray Findings -</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ xrayFindings }}</pre>
                          </div>

                     

                        <!-- CT SCAN -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">CT SCAN- </mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ ctScan }}</pre>
                          </div>

                   
                    </div>


                    <div style="display: flex; flex-direction: row;" class="previewDataRow">

                        <!-- MRI -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">MRI -</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ mri }}</pre>
                          </div>


                        <!-- Other -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">Other -</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ other }}</pre>
                          </div>

                  
                    </div>
                    <div style="display: flex;flex-direction: row; " class="previewDataRow">

                        <!-- Lab Investigation -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">Lab Investigation-</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ labInvistigation }}</pre>
                          </div>

                   
                        <!-- Rx Given -->
                        <div style="width: 48%;">
                            <mat-label class="previewLabel">Rx Given -</mat-label>
                            <pre class="previewLabelData" style="white-space: pre-line;     color: #353333;
                            font-size: 18px;
                            font-weight: bold;     font-family: 'Mukta';">&nbsp;{{ rxGiven }}</pre>
                          </div>
                    </div>


                </div>
            </div>

        </div>
        <div style="text-align: right; margin-top: 10px;">
            <button (click)="openEditDialog(); closeDialog(); " style="background-color: #eee;
                    padding: 8px 16px;
                    border-radius: 6px;
                    color: #fff;
                    font-weight: bold;
                    border: 0px;
                    margin: 4px;
                    color: #444;
                    font-size: 16px;
                    outline: none;">Edit</button>

            <button class="btn-confirm" (click)="createDischargeSummary();closeDialog();  ">Confirm and Print</button>
        </div>


    </div>
</ng-template>

<ng-template #dischargeModal let-modal>
    <div style="width: 80vw; height: 80vh;">
        <div class="modal-header" style="border-bottom: 0px; padding: 0px 16px 16px 0px; ">
            <h4 class="modal-title" id="modal-basic-title" style="margin-left: 17px;">Discharge Summary</h4>
            <div style="display:flex; flex-direction: row;">
                <div ngbDropdown class="d-inline-block">
                    <button class="btn btn-outline-primary" id="dropdownBasic1" style="border: 0px;  border-bottom: 1px solid #ccc;
            padding-right: 0px;
            color: #333;
            background-color: #fff;
            margin-right: 4px;
            " ngbDropdownToggle>{{templateName}} </button>

                    <div ngbDropdownMenu aria-labelledby="dropdownBasic1" style="top:-11px">
                        <button (click)="onDropdownSelectTemplate(item)" style="margin: 0px 16px 0px 0px !important"
                            *ngFor="let item of selectedTemplate; let i = index" ngbDropdownItem>{{item}}</button>
                    </div>
                </div>
                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog(); storeDataInLocalStorage();">
                    <span aria-hidden="true" style="font-size: 40px; color: #000;">&times;</span>
                </button>
            </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: center; ">
            <div style=" display:flex ; height: 65vh;  width: 100%;
            overflow-y: scroll;">
        <div class="modal-body" style="padding: 0px 0px 0px 12px; width: 100%;">
            <div style="display: flex;flex-direction: row; align-items: self-end;">
                <mat-form-field style="width: 48%;">
                    <mat-label>Care Of By Doctor:</mat-label>
                    <input matInput placeholder="Care Of By Doctor:" [(ngModel)]="refbyDr">
                  </mat-form-field>
                  
                  <mat-form-field style="width: 48%;">
                    <mat-label>Address:</mat-label>
                    <textarea matInput placeholder="Address:" [(ngModel)]="address" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>




                <mat-form-field style="width: 48%; margin-left: 14px;">
                    <mat-label>Date of Admission</mat-label>
                    <input matInput [matDatepicker]="picker" [readonly]="true" [(ngModel)]="dateOfAdmission">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>

                </mat-form-field>

                <mat-form-field style="width: 48%; margin-left: 14px;">
                    <mat-label>Date of Discharge</mat-label>
                    <input matInput [matDatepicker]="picker1" [readonly]="true" [(ngModel)]="dateOfDischarge">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>

                </mat-form-field>

            </div>

            <div style="display: flex;flex-direction: row; align-items: self-end;">
                <mat-form-field style="width: 48%;">
                    <mat-label>Wt.OA</mat-label>
                    <input matInput placeholder="Wt.OA" [(ngModel)]="wtoa">

                </mat-form-field>

                <mat-form-field style="width: 48%; margin-left: 14px;">
                    <mat-label>Wt.OD</mat-label>
                    <input matInput placeholder="Wt.OD" [(ngModel)]=wtod>

                </mat-form-field>

                <mat-form-field style="width: 48%;">
                    <mat-label>Diagnosis:</mat-label>
                    <textarea matInput placeholder="Diagnosis:" [(ngModel)]="diagnosis" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>

                <!-- <mat-form-field style="width: 48%; margin-left: 14px;">
                    <mat-label>Diagnosis</mat-label>
                    <input matInput placeholder="Diagnosis" [(ngModel)]=diagnosis>

                </mat-form-field> -->

                <mat-form-field style="width: 48%; margin-left: 14px;">
                    <mat-label>C/O</mat-label>
                    <input matInput placeholder="C/O" [(ngModel)]="co">

                </mat-form-field>

            </div>


            <div style="display: flex;flex-direction: row; align-items: self-end;">
                <mat-form-field style="width: 48%;">
                    <mat-label>HOPD</mat-label>
                    <textarea matInput placeholder="HOPD:" [(ngModel)]="hopd" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>

                <!-- <mat-form-field style="width: 48%;">
                    <mat-label>HOPD</mat-label>
                    <input matInput placeholder="HOPD" [(ngModel)]="hopd">

                </mat-form-field> -->

                <mat-form-field style="width: 48%;">
                    <mat-label>Condition on Discharge</mat-label>
                    <textarea matInput placeholder="Condition on Discharge:" [(ngModel)]="dischargeCondition" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>

                  <mat-form-field style="width: 48%;">
                    <mat-label>On Discharge Rx </mat-label>
                    <textarea matInput placeholder="On Discharge Rx :" [(ngModel)]="onDischargeRx" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>

              
                  <mat-form-field style="width: 48%;">
                    <mat-label>Advise </mat-label>
                    <textarea matInput placeholder="Advise:" [(ngModel)]="advise" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>


            </div>



            <h4 style="font-size: 18px;"> On Examination: </h4>
            <div style="display: flex;flex-direction: row;  align-items: self-end;">

                <mat-form-field style="width: 48%; ">
                    <mat-label>HR</mat-label>
                    <input matInput placeholder="HR" [(ngModel)]="hr">

                </mat-form-field>
                <mat-form-field style="width: 48%; margin-left: 14px;">
                    <mat-label>RR</mat-label>
                    <input matInput placeholder="RR" [(ngModel)]=rr>

                </mat-form-field>

                <mat-form-field style="width: 48%; margin-left: 14px;">
                    <mat-label>BP</mat-label>
                    <input matInput placeholder="BP" [(ngModel)]="bp">

                </mat-form-field>

                <mat-form-field style="width: 48%; margin-left: 14px;">
                    <mat-label>SPO2.</mat-label>
                    <input matInput placeholder="SPO2." [(ngModel)]="spo2">

                </mat-form-field>
            </div>


            <h4 style="font-size: 18px;">Systemic Ex: </h4>
            <div style="display: flex;flex-direction: row; align-items: self-end;">

                <mat-form-field style="width: 48%;">
                    <mat-label>RS: </mat-label>
                    <textarea matInput placeholder="RS." [(ngModel)]="rs" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>
             
                  <mat-form-field style="width: 48%;">
                    <mat-label>CVS: </mat-label>
                    <textarea matInput placeholder="CVS." [(ngModel)]="cvs" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>

           
                  <mat-form-field style="width: 48%;">
                    <mat-label>CNS: </mat-label>
                    <textarea matInput placeholder="CNS." [(ngModel)]="cns" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>

                  <mat-form-field style="width: 48%;">
                    <mat-label>PA: </mat-label>
                    <textarea matInput placeholder="PA." [(ngModel)]="pa" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>
            </div>

            <div style="display: flex;flex-direction: row;  align-items: self-end;">
                <mat-form-field style="width: 48%;">
                    <mat-label>X-ray Findings: </mat-label>
                    <textarea matInput placeholder="X-ray Findings." [(ngModel)]="xrayFindings" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>


                  <mat-form-field style="width: 48%;">
                    <mat-label>CT SCAN </mat-label>
                    <textarea matInput placeholder="CT SCAN." [(ngModel)]="ctScan" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>
            
                  <mat-form-field style="width: 48%;">
                    <mat-label>MRI</mat-label>
                    <textarea matInput placeholder="MRI." [(ngModel)]="mri" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>

                  <mat-form-field style="width: 48%;">
                    <mat-label>Other</mat-label>
                    <textarea matInput placeholder="Other." [(ngModel)]="other" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>


          
            </div>

            <div style="display: flex;flex-direction: row;  align-items: self-end; justify-content: space-between;">
                <mat-form-field style="width: 48%;">
                    <mat-label>Lab Invistigation</mat-label>
                    <textarea matInput placeholder="Lab Invistigation." [(ngModel)]="labInvistigation" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>

                  <mat-form-field style="width: 48%;">
                    <mat-label>Rx Given</mat-label>
                    <textarea matInput placeholder="Rx Given." [(ngModel)]="rxGiven" rows="1" class="textarea-field"></textarea>
                  </mat-form-field>
              
           
            </div>


            <!-- <div style="display: flex;flex-direction: row;justify-content:end;">

                <button (click)=" previewDischargeSummary(); closeDialog(); " class="new_btn"
                    style="margin: 16px 0;">Preview</button>



            </div> -->
        </div>
            </div>
        </div>
        <div style="display: flex;flex-direction: row;justify-content:end;">

            <button (click)=" previewDischargeSummary(); closeDialog(); " class="new_btn"
                style="margin-top: 20px;">Preview</button>



        </div>
    </div>
</ng-template>

<p-toast position="top-right" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class=" p-flex p-flex-column"
            style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;">
            <div>
                <img (click)="goBack()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
                    src="assets/images/exclamation-circle-blue.svg" alt="" />
            </div>
            <div class=" p-text-center">
                <div style="font-size: 18px;
                font-weight: bold;
                padding: 12px 0px 0px 12px;
                
                color: #034A70;">{{message.summary}}</div>
                <div
                    style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
                    {{message.detail}}</div>
            </div>
        </div>
    </ng-template>
</p-toast>