 <!--if doctor select speciality other than pediatrics OR doctor not onboarded screen(start here) -->
<div class="container-fluid basic-info-wrapper">
  <div class="row">
    <div class="col-12 bl_text" *ngIf="notOnboarded">
        <h3 class="headingUpprcase18">Thank you for taking 'care'</h3>
        <form>
          <div class="row">
            <div class="col-12">
              <p class="refile-info">
                At precent we are developing on Medicine Care.<br>
                Very soon we will inform you about release date.<br>
                In case you are interested in development / or <br>
                as a domain expert, please accept this invitation.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <button type="button" class="btn-primary opd-primary-btn btn-md" (click)="interestPage = true; notOnboarded = false; otherDocthankYou = false;">YES</button>
              <a type="button" class="btn-primary opd-secondary-btn btn-md ml-2" (click)="otherDocthankYou = true; notOnboarded = false;" >NO</a>
            </div>
            <div class="col-12 mt-3">
              <p class="refile-info bottomLine">
                We care for your advice and domain expertise !!
              </p>
            </div>
          </div>
        </form>
      </div>

      <!-- thank you page other than pediatric doctor page(start here)-->
      <div class="col-12" *ngIf="otherDocthankYou">
        <form>
          <div class="row">
            <div class="col-12">
              <p class="staticText refile-info">
                Thank you, Dr. {{doctor.first_name}} {{doctor.last_name}}, for joining us!!<br>
                We will get back to you sooner!!
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-3">
              <button type="button" class="btn-primary opd-primary-btn btn-md" routerLink="/">CLOSE</button>
            </div>
          </div>
        </form>
      </div>

      <!-- Interest page(start here)-->
      <div class="col-12 refile-info" *ngIf="interestPage">
        <form>
          <div class="row">
            <div class="col-12">
              <p class="staticText refile-info">
                Thank you, Our technical team will 'care' to get in touch with you.

              <p class="staticText refile-info">
                Thank you once again for your interest in development of software.
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-12 mt-3">
              <button routerLink="/" type="button" class="btn-primary opd-primary-btn btn-md" >CLOSE</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
