<section>
  <div>
    <div class="row">
      <div class="admin-login">
        <div class="form-section student-signup">
          <header class="header-wrapper mb-4">
            <div class="logo-section">
              <h4>Copious</h4>
            </div>
          </header>
          <div class="form-heading align-center">
            <h2 class="fn14 cn2 widthAuto underlinecn3">Login</h2>
          </div>
          <form (submit)="login()">
            
            <div class="form-group animateLabel">
              <input type="email" #email="ngModel" id="emailInput" class="form-control animateInput"
                [(ngModel)]="credentials.email_id" [pattern]="emailPattern" name="email" (focus)="addLabelClass($event)"
                (blur)="removeLabelClass($event)">
              <label [ngClass]="{'animatedLabel':credentials.email_id}">Enter Email</label>
              <p class="errors " *ngIf="email.errors?.pattern">Please enter valid email id</p>
            </div>
          
            <div class="form-group animateLabel">
              <input (keydown.space)="$event.preventDefault()" type="password" [(ngModel)]="credentials.password"
                id="passwordInput" name="password" class="form-control animateInput mb-2"
                (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
              <label [ngClass]="{'animatedLabel':credentials.password}">Enter Password</label>
              <!-- <a [routerLink]="['/admin/forgotPassword']" class="forgot-password ">Forgot password?</a> -->
            </div>
            <div class="form-group">
              <div class="form-field-holder">
                <button type="submit" class="btn-primary opd-primary-btn btn-md">Login</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>