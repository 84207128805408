<div *ngIf="currentStage == '2'" style="margin: 12px;">
  <div class="clinician-detailed-wrapper-outer">
    <div class="row">
      <div class="col-12">
        <label class="ifnoLabel upprcase ifnoDetail">Let's know about your clinic</label>
      </div>
    </div>
    <div class="salutationSpecialization stage2" *ngFor="let clinic of clinicArray; let cIndex = index" id="{{cIndex}}">
      <div class="clinic">
        <div class="row">
          <div class="col-12">
            <label class="ifnoLabel ifnoDetail">Clinic {{cIndex+1}}</label>
          </div>
          <div class="col-12">
            <div class="row fieldset">
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="animateLabel">
                        <input type="text" class="form-control animateInput capitalize" name="name"
                          [(ngModel)]="clinicArray[cIndex].name" (focus)="addLabelClass($event)"
                          (blur)="removeLabelClass($event)" maxlength="100">
                        <label [ngClass]="{'animatedLabel': clinicArray[cIndex].name != null}">Clinic Name</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row fieldset">
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="animateLabel">
                        <input type="text" class="form-control animateInput capitalize" name="address"
                          [(ngModel)]="clinicArray[cIndex].address_line1" (focus)="addLabelClass($event)"
                          (blur)="removeLabelClass($event)" maxlength="50">
                        <label [ngClass]="{'animatedLabel':clinicArray[cIndex].address_line1 != null}">Address Line
                          1</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <div class="animateLabel">
                        <input type="text" class="form-control animateInput capitalize" name="address"
                          [(ngModel)]="clinicArray[cIndex].address_line2" (focus)="addLabelClass($event)"
                          (blur)="removeLabelClass($event)" maxlength="50">
                        <label [ngClass]="{'animatedLabel':clinicArray[cIndex].address_line2 != null}">Address Line
                          2</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="animateLabel">
                        <input type="text" class="form-control animateInput capitalize" name="address"
                          [(ngModel)]="clinicArray[cIndex].address_line3" (focus)="addLabelClass($event)"
                          (blur)="removeLabelClass($event)" maxlength="50">
                        <label [ngClass]="{'animatedLabel':clinicArray[cIndex].address_line3 != null}">Area /
                          Landmark</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="row fieldset">
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="animateLabel">
                        <input type="text" class="form-control animateInput capitalize" name="city"
                          [(ngModel)]="clinicArray[cIndex].city" (focus)="addLabelClass($event)"
                          (blur)="removeLabelClass($event)" maxlength="20"
                          onkeypress="return (event.charCode > 64 &&
                            event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9)">
                        <label [ngClass]="{'animatedLabel':clinicArray[cIndex].city != null}">City</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <div class="animateLabel">
                        <input [textMask]="{mask: pinMask,guide:false}" type="text" class="form-control animateInput"
                          name="pincode" [(ngModel)]="clinicArray[cIndex].pincode" (focus)="addLabelClass($event)"
                          (blur)="removeLabelClass($event)" maxlength="6">
                        <label [ngClass]="{'animatedLabel':clinicArray[cIndex].pincode != null}">Pincode</label>
                        <p class="errors" *ngIf="clinicArray[cIndex].pincodeErrors == 'error'">Please enter valid pin
                          code</p>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12"><br />
            <label class="ifnoLabel ifnoDetail">Let us set Clinic schedule</label>
          </div>
          <div class="col-12">
            <div class="row">
              <div class="col-3"></div>
              <div class="col-9">
                <div class="row">
                  <div class="col-1">
                    <p class="ifnoDetail center mar0">On Call</p>
                  </div>
                  <div class="col-xl-4 col-lg-5">
                    <div class="sessionOne">
                      <p class="ifnoDetail mar0">Session one</p>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-5">
                    <div class="sessionOne">
                      <p class="ifnoDetail mar0">Session two</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row fieldset clinicSessionOuter"
              *ngFor="let session of clinicArray[cIndex].ClinicSchedules; let i = index">
              <div class="col-3">

                <div class="form-group selectDropDown">
                  <select [ngClass]="{'ifnoLabel': !clinicArray[cIndex].ClinicSchedules[i].day}" class="form-control"
                    [(ngModel)]="clinicArray[cIndex].ClinicSchedules[i].day" name="day{{i}}">
                    <option disabled value="null">Select</option>
                    <option *ngFor="let day of days" value="{{day}}">{{day}}</option>
                  </select>
                </div>
                <p class="errors" *ngIf="clinicArray[cIndex].sessionErrors[i] == 'error'">Please select another day.</p>
              </div>
              <div class="col-9">
                <div class="row">
                  <div class="col-1">
                    <div class="form-group checkbox">
                      <label>
                        <input class="filled-in" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day == null"
                          type="checkbox" [disabled]="true">
                        <input class="form-control" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null"
                          [(ngModel)]="clinicArray[cIndex].ClinicSchedules[i].on_call" type="checkbox"
                          class="filled-in">
                        <span></span>
                      </label>
                    </div>
                  </div>
                  <div class="col-xl-4 col-lg-5">
                    <div class="clearfix clinicSession">
                      <div class="width40Percent">
                        <div class="form-group">
                          <input class="form-control" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day == null"
                            [disabled]="true" />
                          <p-calendar *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null"
                            [(ngModel)]="clinicArray[cIndex].ClinicSchedules[i].morning_from"
                            (ngModelChange)="format(cIndex,i,'morning_from')" hourFormat="12" dateFormat="hh:ii "
                            timeOnly="true" readonlyInput="true"></p-calendar>
                        </div>
                      </div>
                      <div class="width10Percent to">
                        <p class="ifnoLabel center">To</p>
                      </div>
                      <div class="width40Percent">
                        <div class="form-group">
                          <input class="form-control" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day == null"
                            [disabled]="true" />
                          <p-calendar *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null"
                            [(ngModel)]="clinicArray[cIndex].ClinicSchedules[i].morning_to"
                            (ngModelChange)="format(cIndex,i,'morning_to')" hourFormat="12" dateFormat="hh:ii AA"
                            timeOnly="true" readonlyInput="true"></p-calendar>
                        </div>
                      </div>
                      <div class="width10Percent to close-button" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null
                          && clinicArray[cIndex].ClinicSchedules[i].evening_from != null
                          && clinicArray[cIndex].ClinicSchedules[i].evening_to != null
                          && clinicArray[cIndex].ClinicSchedules[i].morning_from != null
                          && clinicArray[cIndex].ClinicSchedules[i].morning_to != null">
                        <a (click)="closeSession('morning', cIndex, i)"><i class="fa fa-times-circle-o "></i></a>
                      </div>

                    </div>
                    <p class="errors" *ngIf="clinicArray[cIndex].session1Errors[i] == 'error'">Invalid timings for
                      session one</p>
                    <p class="errors" *ngIf="clinicArray[cIndex].blankSession1Errors[i] == 'error'">Please select
                      timings</p>
                  </div>
                  <div class="col-xl-4 col-lg-5">
                    <div class="clearfix clinicSession">
                      <div class="width40Percent">
                        <div class="form-group">
                          <input class="form-control" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day == null"
                            [disabled]="true" />
                          <p-calendar *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null"
                            [(ngModel)]="clinicArray[cIndex].ClinicSchedules[i].evening_from"
                            (ngModelChange)="format(cIndex,i,'evening_from')" hourFormat="12" dateFormat="hh:ii AA"
                            timeOnly="true" readonlyInput="true"></p-calendar>
                        </div>
                      </div>
                      <div class="width10Percent to">
                        <p class="ifnoLabel center">To</p>
                      </div>
                      <div class="width40Percent">
                        <div class="form-group">
                          <input class="form-control" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day == null"
                            [disabled]="true" />
                          <p-calendar *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null"
                            [(ngModel)]="clinicArray[cIndex].ClinicSchedules[i].evening_to"
                            (ngModelChange)="format(cIndex,i,'evening_to')" hourFormat="12" dateFormat="hh:ii AA"
                            timeOnly="true" readonlyInput="true"></p-calendar>
                        </div>
                      </div>
                      <div class="width10Percent to close-button" *ngIf="clinicArray[cIndex].ClinicSchedules[i].day != null
                          && clinicArray[cIndex].ClinicSchedules[i].morning_from != null
                          && clinicArray[cIndex].ClinicSchedules[i].morning_to != null
                          && clinicArray[cIndex].ClinicSchedules[i].evening_from != null
                          && clinicArray[cIndex].ClinicSchedules[i].evening_to != null">
                        <a (click)="closeSession('evening', cIndex, i)"><i class="fa fa-times-circle-o "></i></a>
                      </div>
                    </div>
                    <p class="errors" *ngIf="clinicArray[cIndex].session2Errors[i] == 'error'">Invalid timings for
                      session two</p>
                    <p class="errors" *ngIf="clinicArray[cIndex].blankSession2Errors[i] == 'error'">Please select
                      timings</p>
                  </div>

                </div>
              </div>

              <div class="col-12 addMore">
                <div class="addMore d-flex">
                  <a (click)="removeField('ClinicSchedules',cIndex,i)" *ngIf="i != 0" class="mr15"><i _ngcontent-c12=""
                      class="icon icon-arrows-circle-minus"></i> Delete</a>
                  <a (click)="addField('ClinicSchedules',cIndex)"
                    *ngIf="i == clinicArray[cIndex].ClinicSchedules.length - 1  && clinicArray[cIndex].ClinicSchedules.length < 7"><i
                      _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i> Add more</a>
                </div>
              </div>

              <!--<div class="col-12 addMore">

                    <a (click)="addField('ClinicSchedules',cIndex)" *ngIf="i == 0 && clinicArray[cIndex].ClinicSchedules.length < 7"><i _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i> Add more</a>
                    <a (click)="removeField('ClinicSchedules',cIndex,i)" *ngIf="i != 0 && clinicArray[cIndex].ClinicSchedules.length > 1"><i _ngcontent-c12="" class="icon icon-arrows-circle-minus"></i> Delete</a>
                  </div>-->
            </div>
          </div>
        </div>

        <div class="col-12"><br />
          <label class="ifnoLabel ifnoDetail">Select slot time (in Minutes)</label>

          <div class="form-group selectDropDown width10Percent">
            <select class="form-control" [(ngModel)]="time" name="timeslot">
              <option disabled value="Select">Select</option>
              <option *ngFor="let time of slotsArray; let i = index" value="{{time}}">{{time}}
              </option>
            </select>
            <!-- <select class="form-control">
              <option disabled value="10">10</option>
              <option *ngFor="let time of slotsArray" value="{{time}}">{{time}}</option>
            </select> -->
          </div>
        </div>

        <div class="row">
          <div class="col-12"><br />
            <label class="ifnoDetail">Clinic Contact Details</label>
          </div>
          <div class="col-12" *ngFor="let contact of clinicArray[cIndex].ClinicContacts; let i = index">
            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="animateLabel">
                        <input type="text" [textMask]="{mask: phoneMask,guide:false}" name="phone"
                          class="form-control animateInput" [(ngModel)]="clinicArray[cIndex].ClinicContacts[i].phone"
                          (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                        <label [ngClass]="{'animatedLabel':clinicArray[cIndex].ClinicContacts[i].phone != null}">Phone
                          No.</label>
                        <p class="errors" *ngIf="clinicArray[cIndex].contactErrors[i] == 'perror'">Please enter valid
                          phone number.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <div class="animateLabel">
                        <input type="text" name="email" class="form-control animateInput "
                          [(ngModel)]="clinicArray[cIndex].ClinicContacts[i].email" (focus)="addLabelClass($event)"
                          (blur)="removeLabelClass($event);">
                        <label [ngClass]="{'animatedLabel':clinicArray[cIndex].ClinicContacts[i].email != null}">Email
                          ID</label>
                        <p class="errors" *ngIf="clinicArray[cIndex].contactErrors[i] == 'eerror'">Please enter valid
                          email id.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="addMore paddtop32 mr-1" *ngIf="i != 0">
                    <a (click)="removeField('ClinicContacts',cIndex,i)"><i _ngcontent-c12=""
                        class="icon icon-arrows-circle-minus"></i> Delete</a>
                  </div>
                  <div class="addMore paddtop32 ml-15" *ngIf="clinicArray[cIndex].ClinicContacts.length < 3">
                    <a (click)="addField('ClinicContacts',cIndex)"
                      *ngIf="i == clinicArray[cIndex].ClinicContacts.length -1"><i _ngcontent-c12=""
                        class="icon icon-arrows-circle-plus"></i> Add more</a>
                  </div>

                  <!--<div class="col-2  col-sm-3" *ngIf="i != 0">
                      <div class="addMore paddtop32">
                          <a (click)="removeField('ClinicContacts',cIndex,i)"><i _ngcontent-c12="" class="icon icon-arrows-circle-minus"></i> Delete</a>
                      </div>
                  </div>
                  <div class="col-3" *ngIf="clinicArray[cIndex].ClinicContacts.length < 3">
                      <div class="addMore paddtop32">
                          <a (click)="addField('ClinicContacts',cIndex)" *ngIf="i == clinicArray[cIndex].ClinicContacts.length -1"><i _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i> Add more</a>
                      </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12"><br />
            <label class="ifnoDetail">Add In-house Services / Facilities</label>
          </div>
          <div class="col-12" *ngFor="let service of clinicArray[cIndex].ClinicFacilities; let i = index">
            <div class="row">
              <div class="col-xl-3 col-lg-4">
                <div class="form-group">
                  <div class="animateLabel">
                    <input type="text" class="form-control animateInput capitalize" name="services"
                      [(ngModel)]="clinicArray[cIndex].ClinicFacilities[i].service_type" (focus)="addLabelClass($event)"
                      (blur)="removeLabelClass($event)" maxlength="50">
                    <label
                      [ngClass]="{'animatedLabel':clinicArray[cIndex].ClinicFacilities[i].service_type != null}">Type of
                      Services / Facilities</label>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="addMore paddtop32 d-flex">
                  <a (click)="removeField('ClinicFacilities',cIndex,i)" *ngIf="i != 0" class="mr-3"><i _ngcontent-c12=""
                      class="icon icon-arrows-circle-minus"></i> Delete</a>
                  <a (click)="addField('ClinicFacilities',cIndex)"
                    *ngIf="clinicArray[cIndex].ClinicFacilities.length < 3  && i ==  clinicArray[cIndex].ClinicFacilities.length -1"><i
                      _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i> Add more</a>
                </div>

              </div>
            </div>

          </div>
          <div class="row">
            <div class="col-12 pl-3">
              <div class="form-group checkbox withLabel">
                <label>
                  <input name="multispeciality" [(ngModel)]="clinicArray[cIndex].multispeciality" type="checkbox"
                    class="filled-in">
                  <span>Is your clinic multispeciality?</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12"><br />
            <label class="ifnoDetail">Add Consultant</label>
          </div>
          <div class="col-12" *ngFor="let consultant of clinicArray[cIndex].ClinicConsultants; let i = index">

            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="animateLabel">
                        <input type="text" class="form-control animateInput capitalize" name="first_name" maxlength="25"
                          [(ngModel)]="clinicArray[cIndex].ClinicConsultants[i].first_name"
                          (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)"
                          (keypress)="validateInput($event)">
                        <label
                          [ngClass]="{'animatedLabel':clinicArray[cIndex].ClinicConsultants[i].first_name != null}">First
                          Name</label>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <div class="animateLabel">
                        <input type="text" class="form-control animateInput capitalize" name="last_name" maxlength="25"
                          [(ngModel)]="clinicArray[cIndex].ClinicConsultants[i].last_name"
                          (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)"
                          (keypress)="validateInput($event)">
                        <label
                          [ngClass]="{'animatedLabel':clinicArray[cIndex].ClinicConsultants[i].last_name != null}">Last
                          Name</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="row">
              <div class="col-6">
                <div class="row">
                  <div class="col-6">
                    <div class="form-group">
                      <div class="animateLabel">
                        <input type="text" class="form-control animateInput" [textMask]="{mask: phoneMask,guide:false}"
                          name="phone" [(ngModel)]="clinicArray[cIndex].ClinicConsultants[i].phone"
                          (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                        <label
                          [ngClass]="{'animatedLabel':clinicArray[cIndex].ClinicConsultants[i].phone != null}">Phone</label>
                        <p class="errors" *ngIf="clinicArray[cIndex].consultantErrors[i] == 'perror'">Please enter valid
                          phone number.</p>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-group">
                      <div class="animateLabel">
                        <input type="text" class="form-control animateInput" name="email"
                          [(ngModel)]="clinicArray[cIndex].ClinicConsultants[i].email" (focus)="addLabelClass($event)"
                          (blur)="removeLabelClass($event); validateEmail(clinicArray[cIndex].ClinicConsultants[i].email)">
                        <label
                          [ngClass]="{'animatedLabel':clinicArray[cIndex].ClinicConsultants[i].email != null}">Email</label>
                        <p class="errors" *ngIf="clinicArray[cIndex].consultantErrors[i] == 'eerror'">Please enter valid
                          email id.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-6">
                <div class="row">
                  <div class="addMore paddtop32 ml-15 mr-15" *ngIf="i != 0">
                    <a (click)="removeField('ClinicConsultants',cIndex,i)"><i _ngcontent-c12=""
                        class="icon icon-arrows-circle-minus"></i> Delete</a>
                  </div>

                  <div class="addMore paddtop32 ml-15" *ngIf="clinicArray[cIndex].ClinicConsultants.length < 3">
                    <a (click)="addField('ClinicConsultants',cIndex)"
                      *ngIf="i == clinicArray[cIndex].ClinicConsultants.length -1"><i _ngcontent-c12=""
                        class="icon icon-arrows-circle-plus"></i> Add more consultant</a>
                  </div>

                  <!--<div class="col-6">
                        <div class="addMore paddtop32">
                          

                          <a *ngIf="i == 0 && clinicArray[cIndex].ClinicConsultants.length < 3" (click)="addField('ClinicConsultants',cIndex)"><i _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i> Add more consultant</a>
                          <a *ngIf="i != 0" (click)="removeField('ClinicConsultants',cIndex,i)"><i _ngcontent-c12="" class="icon icon-arrows-circle-minus"></i> Delete</a>
                        </div>
                      </div>-->
                  <!--<div class="col-2 col-sm-3" *ngIf="i != 0">
                          <div class="addMore paddtop32">
                              <a (click)="removeField('ClinicConsultants',cIndex,i)"><i _ngcontent-c12="" class="icon icon-arrows-circle-minus"></i> Delete</a>
                          </div>
                      </div>-->
                  <!--<div class="col-4 col-sm-5" *ngIf="clinicArray[cIndex].ClinicConsultants.length < 3">
                          <div class="addMore paddtop32">
                              <a (click)="addField('ClinicConsultants',cIndex)" *ngIf="i == clinicArray[cIndex].ClinicConsultants.length -1"><i _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i> Add more consultant</a>
                          </div>
                      </div>-->
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>

      <div class="col-3 col-sm-4">
        <div class="addMore pt-3">
          <a (click)="removeClinic(cIndex)" *ngIf="cIndex != 0" class="mr15"><i _ngcontent-c12=""
              class="icon icon-arrows-circle-minus"></i> Delete clinic</a>
          <a (click)="addClinic(cIndex)" *ngIf="clinicArray.length < 3  && cIndex == clinicArray.length -1"><i
              _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i> Add more clinic</a>
        </div>
      </div>

      <!--<div class="addMore pt-3">
        <a *ngIf="cIndex == 0 && clinicArray.length < 3" (click)="addClinic(cIndex)"><i _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i>Add more clinic</a>
        <a *ngIf="cIndex != 0" (click)="removeClinic(cIndex)"><i _ngcontent-c12="" class="icon icon-arrows-circle-minus"></i> Delete</a>
      </div>-->
    </div>
    <div class="center buttons">
      <button class="btn btn-primary opd-primary-btn transformNormal btn-lg upprcase" (click)="saveClinics()">Save &
        Next</button>
      <button class="btn btn-secondary opd-primary-btn transformNormal btn-lg upprcase" *ngIf="path == 'editprofile'"
        routerLink="/doctor/profileView">Cancel</button>
      <button class="btn btn-secondary opd-primary-btn transformNormal btn-lg upprcase" *ngIf="path !== 'editprofile'"
        routerLink="/doctor/profileSetup/stepThree">Skip</button>
    </div>
  </div>
</div>