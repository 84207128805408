import { Component, ElementRef, ViewChild, OnInit, OnDestroy, EventEmitter, Output, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { openClose } from '../../animations'
import * as $ from 'jquery'
import { cloneDeep } from 'lodash'
import { UserService } from '../../services/user.service';
import { DoctorService } from '../../services/doctor.service';
import { PatientService } from "../../services/patient.service";
import { refreshService } from '../../services/refersh.service'

import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';
import { UtilService } from '../../util/utilService';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, MessageService, SharedModule } from 'primeng/api';
import { first } from 'rxjs/operators';
import { debounce } from 'lodash';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'app-appointments',
  templateUrl: './appointments.component.html',
  styleUrls: ['./appointments.component.scss'],
  animations: [openClose]

})

export class AppointmentsComponent implements OnInit, OnDestroy {

  @ViewChild("searchIcon") searchIcon: ElementRef
  @ViewChild("searchInput") searchInput: ElementRef;
  @ViewChild('appmtPopUp') appmtPopUp: ElementRef;
  @ViewChild("todaysBday") todaysBday;
  @ViewChild('addVitalsModal') addVitalsModal
  @Output() appointmentData = new EventEmitter<string>();
  @Input() searchAppointmentsKeywords = '';
  @Input () docUserId

  searchString: string = "";

  modalRef: any = null
  status: boolean = false;
  user: any = {}
  errors: any = {}
  refreshComponent = false
  userId: any
  clinics: any = []
  searchby_name_number: string = ""
  searchby_from_date: any = ""
  searchby_to_date: any = ""
  editMode = false
  mask: any[] = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/]
  selectedAppointment = null
  appointmentCollection: any = [];
  originalCopy: any = [];
  originalAppointments: any;
  searchAppointmentDate: any = {};
  closeResult: string;
  users = []
  usersArray = []
  arrivedPatients: any = [];
  doneAppointments: any = [];
  attendingAppointments: any = [];
  number = {}
  firstName = {}
  lastName = {}
  minDate = { year: 1900, month: 1, day: 1 }
  today = null
  showLoading = false
  order = []
  isUserSelectedFromList = false
  isTeleAppointMent = '1'
  patientData: any
  appointmentDataForPresc: any
  billAmount: string = ""
  patientEmail: string = ""
  pathArray: any;
  cases: any;
  searchAppData: any = {};
  totalAppointment;
  arrivedCount = 0;
  doneCount = 0;
  inCount = 0;
  isAppointmentEnabled = '0';
  isBillEnabled: any
  searchStringData: string = "";
  interval: any;


  //new variables
  appointmentCollectionArrived: any = [];
  appointmentCollectionAttending: any = [];
  appointmentCollectionDone: any = [];
  morningSlots = [];
  eveningSlots = [];
  morningAppointmentCount: any;
  eveningAppointmentCount: any;

  opdAppointmentTime: number = 10;

  cancelledAppointments: any = [];
  rxUi:any=""



  constructor(private modalService: NgbModal,
    private userService: UserService,
    private doctorService: DoctorService,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private util: UtilService,
    private toastr: ToastrService,
    private confirmationService: ConfirmationService,
    private patientService: PatientService,
    private messageService: MessageService,
    private refreshservice: refreshService,
    public dialog: MatDialog

  ) {
    this.pathArray = [
      {
        id: 1,
        name: "Basic Information",
      },
      {
        id: 2,
        name: "Past History",
      },
      {
        id: 3,
        name: "Vitals",
      },
      {
        id: 4,
        name: "Complaints",
      },
      {
        id: 5,
        name: "Treatment",
      },
      {
        id: 6,
        name: "Investigations",
      },
      {
        id: 7,
        name: "Growth & Milestone",
      },
      {
        id: 8,
        name: "Next Appointment",
      }
    ]
  }

  ngOnInit() {
    if(this.docUserId){
      this.userId = this.docUserId
    }
    else{
      this.userId = this.authService.getUser()
    }
   
    this.today = this.util.reverseDate(new Date());
    this.searchby_from_date = { year: new Date().getFullYear(), month: new Date().getMonth() + 1, day: new Date().getDate() };

    this.getDoctorDetails()
    this.getAppointments();
    this.getCancelledAppointments();
   
    this.rxUi= (localStorage.getItem('RxUi'))


    this.refreshservice.AppointmentRefreshEvent.subscribe(valuesent => {
      console.log("GOT REFRESH EVENT");
      this.getAppointments();
      this.getCancelledAppointments();


    });

    this.refreshservice.VisitRefreshEvent.subscribe(valuesent => {
      console.log("GOT REFRESH EVENT");
      // this.getAppointments();

    });

    this.interval = setInterval(() => {
      this.pollFromServer();
    }, 1000 * 60);

  }



  pollFromServer() {
    let reqVars = {
      doctor_id: this.userId,
      searchby_name_number: this.searchby_name_number,
      searchby_from_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      searchby_to_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      order: this.order
    }

    this.doctorService.getAppointments(reqVars).subscribe(response => {
      this.showLoading = false
      if (response.appointments) {

        if (this.originalAppointments) {
          if (JSON.stringify(this.originalAppointments) != JSON.stringify(response.appointments)) {
            this.messageService.clear();
            this.messageService.add({ severity: 'info', summary: 'New data found', detail: '', sticky: true, });
          }
        }
      }
    })
  }

  refreshAppointment = () => {
    this.refreshservice.appointrefreshed();
    this.refreshservice.patientRefreshed();
    this.messageService.clear();
  }



  ngOnDestroy() {
    if (this.modalRef) {
      this.modalRef.close()
    }
    if (this.interval) {
      clearInterval(this.interval);
    }
  }

  initPatientView() {
    var req_param = { patient_id: localStorage.getItem('patientId'), doctor_id: this.userId };
    this.patientService.getCasesAndVisits(req_param).subscribe(response => {
      if (response.success == true) {
        this.cases = response.patientCase;
        this.open(this.todaysBday, 'todaysBdayPopups');
      }
    });
  }
  onclicktime() {

  }
  onChange(index) {
    var caseObj = this.cases[index]
    localStorage.setItem("viewCaseId", caseObj.id);
    localStorage.setItem("viewVisitId", caseObj.Visits[0].id);

    localStorage.removeItem("newCaseId");
    localStorage.removeItem("newVisitId");
  }


  goToLink(path, d) {
    console.log(path);

    if (path.id === 1) {
      this.router.navigate([`/doctor/patients/patientBasicInfo/view`])
    } else if (path.id === 2) {
      this.router.navigate([`/doctor/patients/pastHistory/view`])
    } else if (path.id === 3) {
      this.router.navigate([`/doctor/patients/vitals/view`]);
    } else if (path.id === 4) {
      this.router.navigate([`/doctor/patients/complaints/view`])
    } else if (path.id === 5) {
      this.router.navigate([`/doctor/patients/treatment/view`])
    } else if (path.id === 6) {
      this.router.navigate([`/doctor/patients/investigations/view`])
    } else if (path.id === 7) {
      this.router.navigate([`/doctor/patients/growthnutrition/view`])
    } else if (path.id === 8) {
      this.router.navigate([`/doctor/patients/nextAppointment/view`])
    }

    // if (d) {
    //   d("close modal");
    // }
  }

  initAppointment() {
    var from = new Date();
    from.setMinutes(from.getMinutes() + 30);
    var to = new Date();
    to.setMinutes(to.getMinutes() + 40);
    this.user = {}
    this.number = {}
    this.editMode = false
    this.appmtPopUp.nativeElement.click();
    var retrievedPatientObject = localStorage.getItem('patientData');
    this.patientData = JSON.parse(retrievedPatientObject);
    this.user.mobile_number = `+${this.patientData.User.phone_number.substring(0, 2)} ${this.patientData.User.phone_number.substring(2)} ${this.patientData.User.first_name} ${this.patientData.User.last_name}`
    this.user.dob = this.patientData.User.dob ? this.util.reverseDate(this.patientData.User.dob) : null
    this.user.gender = this.patientData.User.gender
    this.user.patientId = this.patientData.id
    this.user.appointment_from = from;
    this.user.appointment_to = to;
    this.user.appointment_date = "";
  }


  addLabelClass(e) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }
  clickEvent() {
    this.status = !this.status;
    if (this.status) {
      this.searchInput.nativeElement.focus();
    }
  }

  getUsers(number) {
    if (number.length > 3) {
      this.errors['mobile_number'] = ""
      this.userService.searchUsers(number).subscribe(response => {
        if (response.length) {
          response.forEach(user => {
            user.label = `+${user.contactNo.substring(0, 2)} ${user.contactNo.substring(2)} ${user.firstName} ${user.lastName}`
          });
          this.users = response;
          this.usersArray = this.users
        } else {
          this.users = []
          this.usersArray = this.users
        }
      })
    }
    else {
      this.errors['mobile_number'] = "Please enter atleast 4 digits"
    }
  }

  searchUsers(e, type) {
    this.users = this.filterUsers(e.query, this.usersArray, type)
  }

  filterUsers(query, list, type) {
    let filtered: any[] = [];
    for (let i = 0; i < list.length; i++) {
      let user = list[i];
      if (user[type].toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(user);
      }
    }
    return filtered
  }

  openQues(appointmentData) {
    this.billAmount = ""
    this.patientEmail = ""
    this.appointmentDataForPresc = appointmentData;
  }

  submitform() {

    var answers = "";
    answers += "[COVID] Age of patient: " + document.forms["form1"].age.value;
    answers += " || Symptoms: ";

    if (document.forms["form1"].symptoms1.checked) {
      answers += " " + document.forms["form1"].symptoms1.value;
    }
    if (document.forms["form1"].symptoms2.checked) {
      answers += ", " + document.forms["form1"].symptoms2.value;
    }
    if (document.forms["form1"].symptoms3.checked) {
      answers += ", " + document.forms["form1"].symptoms3.value;
    }
    if (document.forms["form1"].symptoms4.checked) {
      answers += ", " + document.forms["form1"].symptoms4.value;
    }
    if (document.forms["form1"].symptoms5.checked) {
      answers += ", " + document.forms["form1"].symptoms5.value;
    }
    if (document.forms["form1"].symptoms6.checked) {
      answers += ", " + document.forms["form1"].symptoms6.value;
    }
    if (document.forms["form1"].symptoms7.checked) {
      answers += ", " + document.forms["form1"].symptoms7.value;
    }
    if (document.forms["form1"].symptoms8.checked) {
      answers += ", " + document.forms["form1"].symptoms8.value;
    }
    if (document.forms["form1"].symptoms9.checked) {
      answers += ", " + document.forms["form1"].symptoms9.value;
    }
    answers += " || Conditions: ";

    if (document.forms["form1"].conditions1.checked) {
      answers += " " + document.forms["form1"].conditions1.value;
    }
    if (document.forms["form1"].conditions2.checked) {
      answers += ", " + document.forms["form1"].conditions2.value;
    }
    if (document.forms["form1"].conditions3.checked) {
      answers += ", " + document.forms["form1"].conditions3.value;
    }
    if (document.forms["form1"].conditions4.checked) {
      answers += ", " + document.forms["form1"].conditions4.value;
    }
    if (document.forms["form1"].conditions5.checked) {
      answers += ", " + document.forms["form1"].conditions5.value;
    }
    if (document.forms["form1"].conditions6.checked) {
      answers += ", " + document.forms["form1"].conditions6.value;
    }
    if (document.forms["form1"].conditions7.checked) {
      answers += ", " + document.forms["form1"].conditions7.value;
    }
    if (document.forms["form1"].conditions8.checked) {
      answers += ", " + document.forms["form1"].conditions8.value;
    }
    if (document.forms["form1"].conditions9.checked) {
      answers += ", " + document.forms["form1"].conditions9.value;
    }
    if (document.forms["form1"].conditions10.checked) {
      answers += ", " + document.forms["form1"].conditions10.value;
    }

    answers += " || International Travel: " + document.forms["form1"].international.value;
    answers += " || Intected area visited: " + document.forms["form1"].area.value;
    answers += " || Exposure to COVID-19: " + document.forms["form1"].exposure.value;
    answers += " || Worked in hospital: " + document.forms["form1"].works.value;
    answers += " || Conclusion for COVID-19: " + document.forms["form1"].conclusion.value;
    answers += "[COVID]"

    localStorage.setItem("covidques", answers);
    this.onClickPrescription(this.appointmentDataForPresc, 'covid');
  }
  symptomnoneselected() {

    document.forms["form1"].symptoms2.checked = false;
    document.forms["form1"].symptoms3.checked = false;
    document.forms["form1"].symptoms4.checked = false;
    document.forms["form1"].symptoms5.checked = false;
    document.forms["form1"].symptoms6.checked = false;
    document.forms["form1"].symptoms7.checked = false;
    document.forms["form1"].symptoms8.checked = false;
    document.forms["form1"].symptoms9.checked = false;
  }
  conditionnoneselected() {

    document.forms["form1"].conditions1.checked = false;
    document.forms["form1"].conditions2.checked = false;
    document.forms["form1"].conditions3.checked = false;
    document.forms["form1"].conditions4.checked = false;
    document.forms["form1"].conditions5.checked = false;
    document.forms["form1"].conditions6.checked = false;
    document.forms["form1"].conditions7.checked = false;
    document.forms["form1"].conditions8.checked = false;
    document.forms["form1"].conditions9.checked = false;

  }
  conditionitemselected() {
    document.forms["form1"].conditions10.checked = false;
  }
  symptomitemselected() {
    document.forms["form1"].symptoms1.checked = false;
  }


  selectUser(user) {
    this.firstName = user
    this.lastName = user
    this.user.mobile_number = user.contactNo
    this.user.dob = user.DOB ? this.util.reverseDate(user.DOB) : null
    this.user.gender = user.gender
    this.user.patientId = user._id
    this.isUserSelectedFromList = true
  }

  changeTime() {
    let minutes = this.user['appointment_from'].getMinutes() + 10
    let toTime = new Date(this.user['appointment_from'])
    toTime = new Date(toTime.setMinutes(minutes))
    this.user['appointment_to'] = toTime
  }

  onClickPrescription(appointmentData, isFrom) {
    localStorage.setItem('appointmentData', JSON.stringify(appointmentData.comepleteData));
    if (isFrom != 'covid') {
      localStorage.setItem("covidques", '');
    }
    this.router.navigate(['/doctor/patients/treatment/view'])
  }

  onClickPatientName(isFrom, data) {
    if(this.rxUi=='NewRxUI'){
      localStorage.setItem('patientData', JSON.stringify(data.comepleteData.Patient));
      //this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: data.PatientId } });
      this.router.navigate([`/newrx/rxHomePage`], { queryParams: { patientId:  data.PatientId } });
    }
    else{
      localStorage.setItem('patientData', JSON.stringify(data.comepleteData.Patient));
      this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: data.PatientId } });
     // this.router.navigate([`/newrx/rxHomePage`], { queryParams: { patientId:  data.PatientId } });
    }
   
  }

  onClickStartCall(appointmentData) {
    if (appointmentData.comepleteData.Doctor.personalMeetingURL) {
      window.open(appointmentData.comepleteData.Doctor.personalMeetingURL);
    }

  }

  onClickPrescriptionAfterDay(appointmentData) {
    if (appointmentData.comepleteData.prescriptionPDFURL && appointmentData.comepleteData.prescriptionPDFURL != null) {
      window.open(appointmentData.comepleteData.prescriptionPDFURL);
    } else if (appointmentData.comepleteData.prescriptionPhotoURL && appointmentData.comepleteData.prescriptionPhotoURL != null) {
      window.open(appointmentData.comepleteData.prescriptionPhotoURL);
    }
    else {
      this.toastr.warning("No prescription was created for this appointment")
    }
  }

  searchPatient() {
    console.log("app", this.appointmentCollection)
    if (this.searchString.length > 0) {
      var arr = [];
      for (let index = 0; index < this.appointmentCollection.length; index++) {
        const element = this.appointmentCollection[index];
        // if (element.comepleteData.Patient && element.comepleteData.Patient.UserId != null && element.comepleteData.Patient.User && element.comepleteData.Patient.User.Customer ) {
        if (element.comepleteData.Patient.User.first_name.toLowerCase().includes(this.searchString.toLowerCase()) || element.comepleteData.Patient.User.last_name.toLowerCase().includes(this.searchString.toLowerCase()) || element.comepleteData.Patient.User.phone_number.includes(this.searchString)) {

          arr.push(element)
        }
        // }

      }
      this.appointmentCollection = arr;
    }
    else if (this.searchString.length < 1) {
      this.appointmentCollection = this.originalCopy;
    }

  }


  searchAppointmentByNameNumber() {
    if (this.searchString.length > 0) {
      var arr = [];
      for (let index = 0; index < this.originalCopy.length; index++) {
        const element = this.originalCopy[index].comepleteData;
        var appointmentObj
        let d = new Date()
        if (element.Patient.UserId != null && element.Patient.User) {
          if (this.originalCopy[index].fullName.toLowerCase().includes(this.searchString.toLowerCase()) || element.Patient.User.first_name.toLowerCase().includes(this.searchString.toLowerCase()) || element.Patient.User.last_name.toLowerCase().includes(this.searchString.toLowerCase()) || element.Patient.User.phone_number.includes(this.searchString)) {
            var age:any = element.Patient.User.dob ? this.util.calculateAge(element.Patient.User.dob) : { years: '-' }
            if (age < 1) {
              age = "Under 1 Year"
            }
            else {
              age;
            }
            var gender = element.Patient.User.gender != null ? element.Patient.User.gender : "-"

            appointmentObj = {
              id: element.id,
              visitedOn: element.visit_date,
              from: element.from,
              to: element.to,
              mobile: element.Patient.User.phone_number,
              name: element.Patient.User.first_name + " " + element.Patient.User.last_name + " (" + gender + '/' + age['years'] + ")",
              clinicName: element.Clinic && element.Clinic.name ? element.Clinic.name : '-',
              deleted: element.deleted,
              comepleteData: element,
              fullName: element.Patient.User.first_name + " " + element.Patient.User.last_name,
              clinicId: element.Clinic && element.Clinic.id ? element.Clinic.id : null,
              comment: element.comment,
              status: element.status,
              userId: element.Patient.User.id,
              showStartCall: false,
              zoomStartURL: element.zoomStartURL,
              PatientId: element.PatientId,
              showCancel: false,
              showCovid: false,
            };
            var visited = (appointmentObj.visitedOn).substr(0, 11) + "23:59:59.000Z"
            if (new Date(visited).getTime() > d.getTime()) {
              appointmentObj.showCovid = true;
            }
            if (element.isTeleCall == '1' && new Date(visited).getTime() > d.getTime() && element.Doctor.isMeetingURLAvailable) {
              appointmentObj.showStartCall = true
            }
            if (new Date(appointmentObj.from).getTime() > d.getTime() && !appointmentObj.done) {
              appointmentObj.showCancel = true;
            }
            if (new Date(appointmentObj.visitedOn).getTime() < d.getTime()) {
              appointmentObj.cancelled = true

            }
            arr.push(appointmentObj)
          }
        }

      }
      this.appointmentCollection = arr;
      // this.appointmentCollection[0]

    } else {
      this.appointmentCollection = this.originalCopy;
    }
    console.log("original", this.originalCopy)
  }

  searchAppointmentByDates() {
    var arr = [];
    if (this.searchby_from_date != '' && this.searchby_from_date != null && (this.searchby_to_date == '' || this.searchby_to_date == null)) {
      this.searchby_to_date = this.searchby_from_date;
    }
    var from = this.util.parseDate(this.searchby_from_date);
    var to = this.util.parseDate(this.searchby_to_date);
    from.setHours(0, 0, 0);
    to.setHours(23, 59, 59);
    for (let index = 0; index < this.originalCopy.length; index++) {
      const element = this.originalCopy[index].comepleteData;
      var appointmentObj
      let d = new Date()
      if (element.Patient.UserId != null && element.Patient.User) {
        if (new Date(element.visit_date).getTime() > new Date(from).getTime() && new Date(element.visit_date).getTime() < new Date(to).getTime()) {
          var age = element.Patient.User.dob ? this.util.calculateAge(element.Patient.User.dob) : { years: '-' }
          var age1 = age

          var gender = element.Patient.User.gender != null ? element.Patient.User.gender : "-"

          appointmentObj = {
            id: element.id,
            visitedOn: element.visit_date,
            from: element.from,
            to: element.to,
            mobile: element.Patient.User.phone_number,
            name: element.Patient.User.first_name + " " + element.Patient.User.last_name + " (" + gender + '/' + age['years'] + ")",
            clinicName: element.Clinic && element.Clinic.name ? element.Clinic.name : '-',
            deleted: element.deleted,
            comepleteData: element,
            clinicId: element.Clinic && element.Clinic.id ? element.Clinic.id : null,
            comment: element.comment,
            fullName: element.Patient.User.first_name + " " + element.Patient.User.last_name,
            status: element.status,
            userId: element.Patient.User.id,
            showStartCall: false,
            zoomStartURL: element.zoomStartURL,
            PatientId: element.PatientId,
            showCancel: false,
            showCovid: false,
            showNoShow: false,
            arrived: element.arrived,
            in: element.in,
            done: element.done,
            bookedFrom: element.bookedFrom,
            isTeleCall: element.isTeleCall,
          };
          var visited = (appointmentObj.visitedOn).substr(0, 11) + "23:59:59.000Z"
          if (new Date(visited).getTime() > d.getTime()) {
            appointmentObj.showCovid = true;
          }
          if (element.isTeleCall == '1' && new Date(visited).getTime() > d.getTime() && element.Doctor.isMeetingURLAvailable) {
            appointmentObj.showStartCall = true
          }
          if (new Date(appointmentObj.from).getTime() > d.getTime() && !appointmentObj.done) {
            appointmentObj.showCancel = true;
          }
          if (!appointmentObj.done && !appointmentObj.in && !appointmentObj.arrived && appointmentObj.status == null && (new Date(appointmentObj.from).getDate() == new Date().getDate() && new Date(appointmentObj.from).getMonth() == new Date().getMonth() && new Date(appointmentObj.from).getFullYear() == new Date().getFullYear())) {
            appointmentObj.showNoShow = true;
          }
          if (new Date(appointmentObj.visitedOn).getTime() < d.getTime()) {
            appointmentObj.cancelled = true

          }
          arr.push(appointmentObj)
        }
      }

    }
    let doneArr = [];
    let notDoneArr = [];
    let noShowArr = [];
    arr.map(item => {
      if (item.done != null) {
        doneArr.push(item)
      }
      else if (item.status != null) {
        noShowArr.push(item)
      }
      else {
        notDoneArr.push(item)
      }
    });

    let slots = [
      ...notDoneArr, ...doneArr, ...noShowArr
    ]

    this.appointmentCollection = slots;
  }


  onClick(event) {
    if (!this.searchIcon.nativeElement.contains(event.target)) {
      this.status = false
    }
  }
  closeSearch() {
    this.searchby_from_date = null;
    this.searchby_to_date = null;
    this.searchString = '';
    this.searchStringData = '';
    this.searchAppData = this.originalCopy;

  }
  closeSearchApp() {
    this.searchString = '';

    this.appointmentCollection = this.originalCopy;

  }
  open(content, customClass: any = '') {
    this.user = {}
    this.errors = {}
    this.editMode = false
    this.modalRef = this.modalService.open(content, { backdrop: false, size: 'xl' })
    this.modalRef.result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${reason}`;
    });

  }



  saveAppointment(c) {
    if (!this.editMode) {
    }
    this.errors = {};
    let date;
    let from
    let to
    let h
    let m
    if (this.user['appointment_date']) {

      date = this.util.parseDate(this.user['appointment_date'])
      from = this.user['appointment_from'] ? this.user['appointment_from'] : null
      to = this.user['appointment_to'] ? this.user['appointment_to'] : null
      h = from ? from.getHours() : null
      m = from ? from.getMinutes() : null
      if (from) {
        date.setHours(h)
        date.setMinutes(m)
      }

    }
    console.log("date", date)
    /*if (!this.user['dob']) {
      this.errors['dob'] = "Please select patient date of birth"
    }else if (!this.user['gender']) {
      this.errors['gender'] = "Please select gender"
    }*/ if (!this.user['appointment_date']) {
      this.errors['appointment_date'] = "Please select appointment date"
    } else if (!this.user['appointment_from']) {
      this.errors['appointment_from'] = "Please select appointment time"
    } else if (date < new Date()) {
      this.errors['appointment_from'] = "Invalid time"
    } else if (!this.user['appointment_to']) {
      this.errors['appointment_to'] = "Please select appointment time"
    } else if (this.user['appointment_to'] <= this.user['appointment_from']) {
      this.errors['appointment_to'] = "Invalid time"
    }
  
    else if (!['isTeleAppointMent']) {
      this.errors['isTeleAppointMent'] = "Please select if its a TeleAppointment"
    } else {
 
      let reqVars = {
        appointment_id: this.user.appointment_id,
        doctor_id: this.userId,
        from: from,
        to: to,
        appointment_date: date,
        clinic_id: this.user['clinic_id'],
        comment: this.user['comment'],
        patientId: this.patientData.id,
        isTeleCall: this.isTeleAppointMent,
      }
      this.showLoading = true
      c('close modal')

      this.doctorService.addNewAppointment(reqVars).subscribe(response => {
        this.showLoading = false
        localStorage.removeItem('patientData')
        this.user = {}
        this.firstName = {}
        this.lastName = {}
        this.number = {}
        this.editMode = false
        // this.sort(null, 'id', 'desc')
        this.getAppointments()
        this.toastr.success(response.successMessage)
        this.editMode = false
      })
    }
  }

  submitBillGenerateForm(c) {
    if (this.billAmount == "") {
      this.toastr.error('Please enter valid bill amount')
    } else {
      let reqVars = {}
      reqVars =
      {
        patientId: this.appointmentDataForPresc.comepleteData.PatientId,
        appointmentId: this.appointmentDataForPresc.comepleteData.id,
        patientemail: this.patientEmail,
        amount: this.billAmount
      }
      this.showLoading = true
      this.doctorService.generatePaymentInvoice(reqVars).subscribe(response => {
        this.showLoading = false
        c('close modal')
        if (response.success) {
          this.getAppointments()
          this.toastr.success('Generated and sent bill to patient successfully')
        } else {
          this.toastr.error('Something went wrong,please try again')
        }

      })
    }
  }


  updateStatus(event, appointmentObj) {
    event.stopPropagation();
    this.confirmationService.confirm({
      message: 'Are you sure you want to update the status?',
      header: 'Confirmation',
      icon: 'fa fa-question-circle',
      accept: () => {
        appointmentObj.status = 1
        appointmentObj.user_id = this.userId
        this.doctorService.updateAppointment(appointmentObj).subscribe((response) => { })
      },
      reject: () => {
        //this.msgs = [{severity:'info', summary:'Rejected', detail:'You have rejected'}];
      }
    });

  }

  addLabelAutoComplete(e) {
    $(e.target.parentElement.parentElement.parentElement.children[1]).addClass('animatedLabel')
  }

  removeLabelAutoComplete(e) {

    if (e.target.value == "") {
      $(e.target.parentElement.parentElement.parentElement.children[1]).removeClass('animatedLabel')
    } else {
      $(e.target.parentElement.parentElement.parentElement.children[1]).addClass('animatedLabel')
    }

  }

  selectAppointment(appointmentData) {
    this.selectedAppointment = appointmentData
    this.editMode = true
    this.user.dob = appointmentData.comepleteData.Patient.User.dob ? this.util.reverseDate(appointmentData.comepleteData.Patient.User.dob) : null
    if (this.user.dob < 1) {
      this.user.dob = "Under 1 Year"
    }
    else {
      this.user.dob;
    }
    this.user.gender = appointmentData.comepleteData.Patient.User.gender
    this.user.appointment_date = this.util.reverseDate(appointmentData.comepleteData.visit_date)
    this.user.appointment_from = new Date(appointmentData.comepleteData.from)
    this.user.appointment_to = new Date(appointmentData.comepleteData.to)
    this.user.appointment_id = appointmentData.id
    this.user.mobile_number = appointmentData.comepleteData.Patient.User.phone_number
    this.user.first_name = appointmentData.comepleteData.Patient.User.first_name
    this.user.last_name = appointmentData.comepleteData.Patient.User.last_name
    this.user.clinic_id = appointmentData.clinicId
    this.user.comment = appointmentData.comment
    this.user.userId = appointmentData.userId
  }

  goToVital() {
    console.log("appp", this.selectedAppointment)
    // var obj = { id: this.selectedAppointment.PatientId }
    let obj: any = {}
    let visit: any = {
      BloodSugarRandom: null,
      Blood_Hba1c: null,
      Bloodhaemoglobin: null,
      Bloodsugar_F: null,
      Bloodsugar_PP: null,
      Chol_HDL: null,
      Chol_LDL: null,
      DoctorId: this.selectedAppointment.comepleteData.Visit.DoctorId,
      Heartrate: null,
      Pulse: this.selectedAppointment.comepleteData.Visit.Pulse,
      Temp: this.selectedAppointment.comepleteData.Visit.Temp,
      TotalCholesterol: null,
      Triglycerides_LFT: null,
      addedBy: this.selectedAppointment.comepleteData.Visit.addedby,
      bpDia: this.selectedAppointment.comepleteData.Visit.bp_dia,
      bpSys: this.selectedAppointment.comepleteData.Visit.bp_sys,
      cc: this.selectedAppointment.comepleteData.Visit.chest_circumference,
      chief_complaints: null,
      date: this.selectedAppointment.comepleteData.Visit.createdAt,
      hc: this.selectedAppointment.comepleteData.Visit.head_circumference,
      height: this.selectedAppointment.comepleteData.Visit.height,
      patientid: this.selectedAppointment.comepleteData.Visit.patientId,
      spo2: this.selectedAppointment.comepleteData.Visit.spo2,
      updatedByName: "",
      weight: this.selectedAppointment.comepleteData.Visit.weight,
      _id: this.selectedAppointment.comepleteData.Visit.id,
    }
    obj['_id'] = this.selectedAppointment.comepleteData.PatientId
    obj['firstName'] = this.selectedAppointment.comepleteData.Patient.User.first_name
    obj['lastName'] = this.selectedAppointment.comepleteData.Patient.User.last_name
    obj['patientTypes'] = this.selectedAppointment.comepleteData.Patient.patientTypes
    obj['gender'] = this.selectedAppointment.comepleteData.Patient.User.gender
    obj['DOB'] = this.selectedAppointment.comepleteData.Patient.User.dob
    obj['gestationalAge'] = ''
    obj['discharge_note'] = ''
    obj['visits'] = [visit]
    console.log("obej", obj)
    //localStorage.setItem("patientData",obj)
    localStorage.setItem('patientDataForVitals', JSON.stringify(obj));

    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.selectedAppointment.PatientId, visit: true, action: 'edit', isAppointment: true } });

    // setTimeout(() => {
    //   this.refreshservice.OnPatTopNav_MenuSelected(obj.id, this.refreshservice.EventName_Visits);
    // }, 500)
  }
  openBillReceipt(data) {
    //  localStorage.setItem("patientData", JSON.stringify(data))
    let obj = {}
    let objData: any = {}

    objData['User'] = data.comepleteData.Patient.User
    objData['id'] = data.PatientId;
    console.log("patdata", objData)
    localStorage.setItem("patientData", JSON.stringify(objData))
    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: objData.id, billFlag: true } });
  }
  onClickAppointmentStatus(appointment) {
    this.selectedAppointment = appointment;
    console.log("appOn", appointment)

    if (appointment.isTeleCall != '1' && new Date().getDay() === new Date(appointment.comepleteData.from).getDay() && new Date().getMonth() === new Date(appointment.comepleteData.from).getMonth() && new Date().getFullYear() === new Date(appointment.comepleteData.from).getFullYear() && appointment.comepleteData.status == null) {
      const postData: any = {
        doctor_id: this.userId,
        appointment_date: appointment.comepleteData.visit_date,
        from: appointment.comepleteData.from,
        to: appointment.comepleteData.to,
        clinic_id: appointment.comepleteData.ClinicId,
        isTeleCall: appointment.comepleteData.isTeleCall,
        appointment_id: appointment.comepleteData.id
      };
      if (!appointment.arrived) {

        postData.arrived = moment().format('YYYY-MM-DD HH:mm:ss');
      } else if (appointment.arrived && !appointment.in && !appointment.done) {
        postData.arrived = appointment.arrived;
        postData.in = moment().format('YYYY-MM-DD HH:mm:ss');
      }
      else if (appointment.arrived && appointment.in && !appointment.done) {
        postData.arrived = appointment.arrived;
        postData.in = appointment.in;
        postData.done = moment().format('YYYY-MM-DD HH:mm:ss');
      }
      else if (appointment.arrived && appointment.in && appointment.done) {
        postData.arrived = appointment.arrived;
        postData.in = appointment.in;
        postData.done = appointment.done;
      }
      if (appointment.arrived && appointment.in && appointment.done) {
      } else {
        this.doctorService.addNewAppointment(postData).subscribe(response => {
          if (!appointment.arrived && appointment.comepleteData.Visit.weight == '') {
            let objData: any = {}

            objData['User'] = appointment.comepleteData.Patient.User
            objData['id'] = appointment.PatientId;
            console.log("patdata", objData)
            localStorage.setItem("patientData", JSON.stringify(objData))

            // localStorage.setItem("visitForAppontment",appointment.comepleteData.visit)
            this.openDialog(this.addVitalsModal);
          }
          if (!appointment.in && appointment.comepleteData.Visit.weight == '') {
            let objData: any = {}

            objData['User'] = appointment.comepleteData.Patient.User
            objData['id'] = appointment.PatientId;
            console.log("patdata", objData)
            localStorage.setItem("patientData", JSON.stringify(objData))
            this.openDialog(this.addVitalsModal);
          }

          this.getAppointments()
        });
      }

    } else {
      if (appointment.isTeleCall == '1') {
        this.onClickStartCall(appointment)
      }
    }
  }
  pageRefersh() {
    this.refreshservice.patientRefreshed();
  }

  searchInAppointments(params) {
    this.searchString = params;
    this.searchPatient();
  }

  getAllClinicsTiming = () => {

    this.morningAppointmentCount = 0
    this.eveningAppointmentCount = 0
    // this.priorityAppointmentCount = 0
    // this.priorityTimeSlots = []

    const postData = {
      date: moment(this.parseDate(this.searchby_from_date)).format("YYYY-MM-DD"),
    };
    this.doctorService.getClinicSchedule(postData).subscribe(response => {

      console.log('Clinic Schedule', response)

      var morningStartArr = [];
      var morningEndArr = [];
      var eveningStartArr: any = [];
      var eveningEndArr: any = [];
      if (response.success) {
        for (let index = 0; index < response.clinicSchedule.length; index++) {
          const element = response.clinicSchedule[index];
          var morning_from = moment(element.ClinicSchedules[0].morning_from, "hh:mm a").valueOf();
          morningStartArr.push(morning_from);
          var morning_to = moment(element.ClinicSchedules[0].morning_to, "hh:mm a").valueOf();
          morningEndArr.push(morning_to);
          var evening_from = moment(element.ClinicSchedules[0].evening_from, "hh:mm a").valueOf();
          eveningStartArr.push(evening_from);
          var evening_to = moment(element.ClinicSchedules[0].evening_to, "hh:mm a").valueOf();
          eveningEndArr.push(evening_to);
        }
        const morningStarthr: any = Math.min(...(morningStartArr));
        const morningEndhr: any = Math.max(...(morningEndArr));
        const eveningStarthr: any = Math.min(...(eveningStartArr));
        const eveningEndhr: any = Math.max(...(eveningEndArr));

        this.fillEmptyMorningSlots(morningStarthr, morningEndhr);
        this.fillEmptyEveningSlots(eveningStarthr, eveningEndhr);

      }

    })

  }



  fillEmptyMorningSlots = (morningStarthr, morningEndhr) => {
    var offset = this.opdAppointmentTime;
    var arr: any = [];

    var morningStarthrToShow = '';
    var morningEndhrToShow = '';

    for (let index = new Date(morningStarthr).getHours(); index <= new Date(morningEndhr).getHours(); index++) {
      var startmin = 0;
      var endmin: any = offset;

      for (let j = 0; j < 60 / offset; j++) {

        var startTime: any = moment(this.parseDate(this.searchby_from_date)).format("YYYY-MM-DD");
        startTime = moment(startTime).set({ hour: index, minute: startmin, second: 0, millisecond: 0 });
        if (index === morningStarthr && startmin === 0) {

          morningStarthrToShow = moment(startTime).local().format("hh:mm a")

        }
        startmin = startmin + offset;
        var endTime: any = moment(new Date(this.parseDate(this.searchby_from_date))).format("YYYY-MM-DD");
        endTime = moment(startTime).set({ hour: index, minute: endmin, second: 0, millisecond: 0 });
        if (j === 60 / offset) {
          endTime = moment(startTime).set({ hour: index + 1, minute: 0, second: 0, millisecond: 0 });
        }
        endmin = endmin + offset;
        var obj = { start: moment(startTime).local().format("hh:mm a"), end: moment(endTime).local().format("hh:mm a"), title: null, summary: null, showPlusButton: false }
        if (moment(startTime).isAfter(moment())) {
          obj.showPlusButton = true;

          var date2 = morningStarthr;
          date2 = new Date(date2).setDate(new Date(startTime).getDate());
          var date3: any = morningEndhr;
          date3 = new Date(date3).setDate(new Date(endTime).getDate());
          if (new Date(startTime).getTime() >= date2 && new Date(endTime).getTime() <= date3) {
            arr.push(obj);
          }
        }
      }
      if (index === morningEndhr) {
        morningEndhrToShow = moment(endTime).local().format("hh:mm a");
      }
    }
    this.morningSlots = arr;
  }

  fillEmptyEveningSlots = (eveningStarthr, eveningEndhr) => {
    var offset = this.opdAppointmentTime;
    var arr = [];
    for (let index = new Date(eveningStarthr).getHours(); index <= new Date(eveningEndhr).getHours(); index++) {
      var startmin = 0;
      var endmin: any = offset;

      for (let j = 0; j < 60 / offset; j++) {
        var startTime: any = moment(new Date(this.parseDate(this.searchby_from_date))).format("YYYY-MM-DD");
        startTime = moment(startTime).set({ hour: index, minute: startmin, second: 0, millisecond: 0 });
        startmin = startmin + offset;
        var endTime: any = moment(new Date(this.parseDate(this.searchby_from_date))).format("YYYY-MM-DD");
        endTime = moment(startTime).set({ hour: index, minute: endmin, second: 0, millisecond: 0 });
        if (j === 60 / offset) {
          endTime = moment(startTime).set({ hour: index + 1, minute: 0, second: 0, millisecond: 0 });
        }
        endmin = endmin + offset;
        var obj = { start: moment(startTime).local().format("hh:mm a"), end: moment(endTime).local().format("hh:mm a"), title: null, summary: null, showPlusButton: false }
        if (moment(startTime).isAfter(moment())) {
          obj.showPlusButton = true;

          var date2: any = eveningStarthr;
          date2 = new Date(date2).setDate(new Date(startTime).getDate());
          var date3: any = eveningEndhr;
          date3 = new Date(date3).setDate(new Date(endTime).getDate());
          if (new Date(startTime).getTime() >= date2 && new Date(endTime).getTime() <= date3) {
            arr.push(obj);
          }
        }
      }
    }
    this.eveningSlots = arr;
  }


  getCancelledAppointments = () => {
    let reqVars = {
      doctor_id: this.userId,
      searchby_name_number: this.searchby_name_number,
      searchby_from_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      searchby_to_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      order: this.order
    }
    this.showLoading = true;
    this.doctorService.getCancelledAppointments(reqVars).subscribe(response => {
      console.log('Response getCancelledAppointments: ', response)
      this.showLoading = false;
      let cancelledAppointments = [];
      let appointmentboj: any = {}
      let d = new Date()

      if (response.appointments) {
        //  cancelledAppointments = response.appointments;
        // this.cancelledAppointments = cancelledAppointments;

        response.appointments.forEach((element) => {
          if (element.Patient && element.Patient.User) {
            var age = element.Patient.User.dob ? this.util.calculateAge(element.Patient.User.dob) : { years: '-' }
            if (age['years'] < 1) {

              age['years'] = "Under 1 Year";
            }
            else {
              age;
            }
            var gender = element.Patient.User.gender != null ? element.Patient.User.gender : "-"

            appointmentboj = {
              id: element.id,
              visitedOn: element.visit_date,
              from: element.from,
              to: element.to,
              mobile: element.Patient.User.phone_number,
              name: element.Patient.User.first_name + " " + element.Patient.User.last_name + " (" + gender.substring(0, 1).toUpperCase() + '/' + age['years'] + ")",
              clinicName: element.Clinic && element.Clinic.name ? element.Clinic.name : '-',
              deleted: true,
              comepleteData: element,
              clinicId: element.Clinic && element.Clinic.id ? element.Clinic.id : null,
              comment: element.comment,
              fullName: element.Patient.User.first_name + " " + element.Patient.User.last_name,
              status: element.status,
              userId: element.Patient.User.id,
              showStartCall: false,
              zoomStartURL: element.zoomStartURL,
              PatientId: element.PatientId,
              showCancel: false,
              showCovid: false,
              showNoShow: false,
              arrived: element.arrived,
              in: element.in,
              done: element.done,
              bookedFrom: element.bookedFrom,
              isTeleCall: element.isTeleCall
            };
            var visited = (appointmentboj.visitedOn).substr(0, 11) + "23:59:59.000Z"
            if (new Date(appointmentboj.from).getDate() === new Date().getDate() && new Date(appointmentboj.from).getMonth() === new Date().getMonth() && new Date(appointmentboj.from).getFullYear() === new Date().getFullYear()) {
              appointmentboj.showCovid = true;
            }
            if (element.isTeleCall == '1' && new Date(visited).getTime() > d.getTime() && element.Doctor.isMeetingURLAvailable) {
              appointmentboj.showStartCall = true
            }
            if (new Date(appointmentboj.from).getTime() > d.getTime() && !appointmentboj.done) {
              appointmentboj.showCancel = true;
            }
            if (!appointmentboj.done && !appointmentboj.in && !appointmentboj.arrived && appointmentboj.status == null && (new Date(appointmentboj.from).getDate() == new Date().getDate() && new Date(appointmentboj.from).getMonth() == new Date().getMonth() && new Date(appointmentboj.from).getFullYear() == new Date().getFullYear())) {
              appointmentboj.showNoShow = true;
            }
            if (new Date(appointmentboj.visitedOn).getTime() < d.getTime()) {
              appointmentboj.cancelled = true

            }

            cancelledAppointments.push(appointmentboj)

          }

          return cancelledAppointments.sort((a, b) => {
            return <any>new Date(a.from) - <any>new Date(b.from);

          });

        });

        this.cancelledAppointments = cancelledAppointments;


      }
    })
    // this.apppointmentCount();
  }


  getAppointments = () => {

    //this.showLoading = true
    let reqVars = {
      doctor_id: this.userId,
      searchby_name_number: this.searchby_name_number,
      searchby_from_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      searchby_to_date: this.searchby_from_date ? this.util.parseDate(this.searchby_from_date) : "",
      order: this.order
    }
    // let that = this
    let d = new Date()
    this.appointmentCollection = [];
    this.appointmentCollectionArrived = [];
    this.appointmentCollectionAttending = [];
    this.appointmentCollectionDone = [];

    let cancelledAppointments = []

    this.showLoading = true;

    this.doctorService.getAppointments(reqVars).subscribe(response => {
      console.log('Response Appt: ', response)
      var appointmentboj: any = {}
      this.showLoading = false
      if (response.appointments) {
        this.searchAppData = response.appointments;
     
        this.originalAppointments = response.appointments;
        console.log("marchTest31",this.originalAppointments)

        response.appointments.forEach((element) => {

          let isReceipt=false
          console.log({ thissssss2: this })
          if(element.Patient.User.first_name=="favourite" && element.Patient.User.last_name=="creation" ){

          }else{

        
          if (element.Patient && element.Patient.User) {
            var age = element.Patient.User.dob ? this.util.calculateAge(element.Patient.User.dob) : { years: '-' }
            if (age['years'] < 1) {

              age['years'] = "Under 1 Year";
            }
            else {
              age;
            }
            var gender = element.Patient.User.gender != null ? element.Patient.User.gender : "-"

           if(element.Certificates && element.Certificates!=null){
            var certificate=JSON.parse(element.Certificates)
            certificate.map(item=>{
              if(item.type=='Receipt'){
                isReceipt=true
              }
            })

           }

            appointmentboj = {
              id: element.id,
              isReceipt,
              visitedOn: element.visit_date,
              from: element.from,
              to: element.to,
              mobile: element.Patient.User.phone_number,
              name: element.Patient.User.first_name + " " + element.Patient.User.last_name + " (" + gender.substring(0, 1).toUpperCase() + '/' + age['years'] + ")",
              clinicName: element.Clinic && element.Clinic.name ? element.Clinic.name : '-',
              deleted: element.deleted,
              comepleteData: element,
              clinicId: element.Clinic && element.Clinic.id ? element.Clinic.id : null,
              comment: element.comment,
              fullName: element.Patient.User.first_name + " " + element.Patient.User.last_name,
              status: element.status,
              userId: element.Patient.User.id,
              showStartCall: false,
              zoomStartURL: element.zoomStartURL,
              PatientId: element.PatientId,
              showCancel: false,
              showCovid: false,
              showNoShow: false,
              arrived: element.arrived,
              in: element.in,
              done: element.done,
              bookedFrom: element.bookedFrom,
              isTeleCall: element.isTeleCall
            };
            var visited = (appointmentboj.visitedOn).substr(0, 11) + "23:59:59.000Z"
            if (new Date(appointmentboj.from).getDate() === new Date().getDate() && new Date(appointmentboj.from).getMonth() === new Date().getMonth() && new Date(appointmentboj.from).getFullYear() === new Date().getFullYear()) {
              appointmentboj.showCovid = true;
            }
            if (element.isTeleCall == '1' && new Date(visited).getTime() > d.getTime() && element.Doctor.isMeetingURLAvailable) {
              appointmentboj.showStartCall = true
            }
            if (new Date(appointmentboj.from).getTime() > d.getTime() && !appointmentboj.done) {
              appointmentboj.showCancel = true;
            }
            if (!appointmentboj.done && !appointmentboj.in && !appointmentboj.arrived && appointmentboj.status == null && (new Date(appointmentboj.from).getDate() == new Date().getDate() && new Date(appointmentboj.from).getMonth() == new Date().getMonth() && new Date(appointmentboj.from).getFullYear() == new Date().getFullYear())) {
              appointmentboj.showNoShow = true;
            }
            if (new Date(appointmentboj.visitedOn).getTime() < d.getTime()) {
              appointmentboj.cancelled = true

            }

            this.appointmentCollection.push(appointmentboj)
            console.log("Test31",this.appointmentCollection)

            if (appointmentboj.status == 'noshow') {
              cancelledAppointments.push(appointmentboj)
            }

          }
          this.appointmentCollection.map(ins=>{
            if(ins.comepleteData.prescriptionPDFURL){
              ins.done="done",
              ins.arrived="done"
              ins.in="done"
              ins.showNoShow=false
            }
           })

          return this.appointmentCollection.sort((a, b) => {
            return <any>new Date(a.from) - <any>new Date(b.from);
          

          });
          }

        })
      
        this.cancelledAppointments = cancelledAppointments;

        let doneArr = [];
        let notDoneArr = [];
        let noShowArr = [];

        console.log("NOV3",this.appointmentCollection)
        this.appointmentCollection.map(item => {
         
          if (item.done != null) {

            doneArr.push(item)
            
          }
          else if (item.status != null) {
            noShowArr.push(item)
          }
          else {
            notDoneArr.push(item)
          }
        });

        let slots = [
          ...notDoneArr, ...doneArr, ...noShowArr
        ]

        this.appointmentCollection = slots;
        this.originalCopy = this.appointmentCollection;

        this.appointmentCollectionDone = doneArr;

        this.appointmentCollection.map(ins => {
          if (ins.arrived && !ins.in && !ins.done) {
            this.appointmentCollectionArrived.push(ins)
          }

          if (ins.arrived && ins.in && !ins.done ) {
            this.appointmentCollectionAttending.push(ins)
          }
        })
        localStorage.removeItem("covidques");
        this.apppointmentCount();
      }
    })
    this.apppointmentCount();

  }



  closeCalendar(e, d) {
    if (this.user['appointment_date']) {
      this.errors['appointment_date'] = "";
    }
    if (this.searchby_from_date != '' && this.searchby_from_date != null && (this.searchby_to_date == '' || this.searchby_to_date == null)) {
      this.searchby_to_date = this.searchby_from_date;
    }
    if (d._elRef.nativeElement.parentElement.contains(e.target)) {

    } else {
      d.close()
    }
    if (e.target.offsetParent == null)
      d.close()
    else if (e.target.offsetParent && e.target.offsetParent.nodeName != "NGB-DATEPICKER" && (e.target.offsetParent.className.indexOf('calender') == -1 && e.target.offsetParent.className.indexOf('custom-select') == -1))
      d.close()
  }


  delete(c) {
    this.selectedAppointment.comepleteData['deleted'] = 1
    this.doctorService.updateAppointment(this.selectedAppointment.comepleteData).subscribe((response) => {
      this.editMode = false
      // c('close modal')
      this.getAppointments();
      this.getCancelledAppointments();
      this.toastr.success("Appointment has been cancelled successfully")
    })

  }

  markNoShow() {
    this.doctorService.updateNoShowAppointment(this.selectedAppointment.comepleteData).subscribe((response) => {
      this.editMode = false
      // c('close modal')
      this.getAppointments()
      this.toastr.success("Changed status to No Show successfully")
    })

  }

  getDoctorDetails() {
    let req_vars = { userId: this.userId }
    this.doctorService.getDoctorDetails(req_vars).subscribe(response => {
      var services = JSON.parse(response.doctor.services);
      console.log("docdata", response)
      this.opdAppointmentTime = response.doctor && response.doctor.opdAppointmentTime ? response.doctor.opdAppointmentTime : 10;
      this.clinics = response.clinics
      this.user.clinic_id = this.clinics.length ? this.clinics[0].id : '';
      this.isAppointmentEnabled = response.doctor && response.doctor.isAppointmentEnabled ? response.doctor.isAppointmentEnabled : '0';
      this.isBillEnabled = services && services.enableBillingModule ? services.enableBillingModule : '0';

      this.getAllClinicsTiming()

    })

  }
  parseDate(dateObject) {
    return `${dateObject.year}-${dateObject.month}-${dateObject.day}`
  }

  editAppointment() {
    console.log("hello..")
  }

  trimInput(user, name) {
    user[name] = user[name].trim()
  }
  apppointmentCount() {
    this.arrivedCount = 0;
    this.doneCount = 0;
    this.inCount = 0;
    this.arrivedPatients = [];
    this.attendingAppointments = [];
    this.doneAppointments = [];
    if (this.appointmentCollection.length == 0) {
      this.totalAppointment = 0;
      this.arrivedCount = 0;
      this.doneCount = 0;
      this.inCount = 0;
      this.arrivedPatients = [];
      this.attendingAppointments = [];
      this.doneAppointments = [];
    } else {
      this.totalAppointment = this.appointmentCollection.length;
      console.log(this.totalAppointment);
    }
    for (let i = 0; i < this.appointmentCollection.length; i++) {
      if (this.appointmentCollection[i].arrived != null) {
        this.arrivedCount++;
        this.arrivedPatients.push(this.appointmentCollection[i]);
        if (this.appointmentCollection[i].in != null) {
          this.arrivedCount--;
          for (let i = 0; i < this.arrivedPatients.length; i++) {
            if (this.arrivedPatients[i].in != null) {
              this.arrivedPatients.splice(i);
            }
          }
          // this.arrivedPatients.splice(this.appointmentCollection[i]);
          this.inCount++;
          this.attendingAppointments.push(this.appointmentCollection[i]);
        }
        if (this.appointmentCollection[i].done != null) {
          this.inCount--;
          for (let i:any = 0; i < this.attendingAppointments.length; i++) {
            if (this.attendingAppointments[i].done != null) {
              this.attendingAppointments.splice(i);
            }
          }
          this.doneCount++;
          this.doneAppointments.push(this.appointmentCollection[i]);
        }
      }
    }
    console.log("arrived", this.arrivedCount);
    console.log("done", this.doneCount);
    console.log("Attending", this.inCount);
    console.log("Collections", this.appointmentCollection);
    console.log("Done", this.doneAppointments);
    console.log("Arrived", this.arrivedPatients);
    console.log("Attending", this.attendingAppointments);
  }
  openDialog(content) {
    this.dialog.open(content);
  }
  closeDialog() {
    this.dialog.closeAll()
  }


  onClickPatientInfo = (appointmentData) => {
    if(this.rxUi=='NewRxUI'){
      localStorage.setItem('patientData', JSON.stringify(appointmentData.comepleteData.Patient));
     
       this.router.navigate([`/newrx/rxHomePage`], { queryParams: { patientId:  appointmentData.PatientId } });
    }
    else{
      localStorage.setItem('patientData', JSON.stringify(appointmentData.comepleteData.Patient));
      this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: appointmentData.PatientId } });
       
    }
   
  }

}


