<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
  <label for="" class="loader"></label>
</div>
<div class="patientBsicInfoWrapper pastHistoryWrapper pr-4">
  <table width="100%">
    <tr>
      <td width="16%"></td>
      <td width="16%"></td>
      <td width="16%"></td>
      <td width="16%"></td>
      <td width="16%"></td>
      <td width="16%"></td>
    </tr>
    <tr>
      <td colspan=6 style="padding:10px 3px 3px 4px; vertical-align:middle; border-radius: 3px;">
        <label style="font-size: 18px;color: #333;"> <span
            style="font-size: 20px;color: #333;text-transform: capitalize;font-weight: bold;"><span
              style="font-size: 15px;color: #333;">
            </span>{{appointmentData.Patient.User.first_name}}
            {{appointmentData.Patient.User.last_name}}</span> <span style="font-size: 13px;color: #333;"> -
            {{date |  date: 'dd/MM/yyyy'}}</span>

        </label>
        
          <button class="newBtnStyle"
            (click)="print('preview')">
            Preview </button>
          <button class="newBtnStyle"
            
            (click)="print('share')"> Share
            to Patient </button>
          <button class="newBtnStyle"
            
            (click)="savePrescription()">Save</button>
          <button class="newBtnStyle"
           
            (click)="closePrescription()">Close</button>
          <!-- <a (click)="open(prescriptions, 'PrescriptionsPopups')">+ Add Prescription</a> -->
        
      </td>
    </tr>
    <tr>
      <td colspan="3" width="50%" style="padding:3px 3px 3px 3px;">
        <div class="form-group onFocus">
          <div class="row">
            <label for="chief_complaints"
              style="font-size: 18px;color: #333;margin-left: 7px;margin-right: 7px;margin-top: 3px;" class="newInputStyleLabel">Chief Complaints</label>
            <a style="margin-bottom: 3px;" ngbTooltip="Add Complaint"
              (click)="open(Complaints, 'ComplaintsPopoup')"><img height="22" src="assets/images/plus_orange.png"
                style="vertical-align: text-bottom;margin-bottom: 2px;" alt=""></a>
          </div>
          <textarea class="form-control animateInput newInputBox" 
          
            [(ngModel)]="chief_complaints" name="chief_complaints" placeholder="Enter chief complaints" maxlength="700"
            onfocus="if(placeholder=='Enter chief complaints'){placeholder=''}"
            onblur="if(placeholder==''){placeholder='Enter chief complaints'}"></textarea>
        </div>

      </td>
      <td colspan="3" width="50%" style="padding:3px 3px 3px 3px;">
        <div class="form-group onFocus">
          <div class="row">
            <label for="diagnosis"
              style="font-size: 18px;color: #333;margin-left: 7px;margin-right: 7px;margin-top: 3px;" class="newInputStyleLabel">Working Diagnosis</label>
            <a style="margin-bottom: 3px;" ngbTooltip="Add Diagnosis" (click)="open(Diagnosis, 'DiagnosisPopoup')"><img
                style="vertical-align: text-bottom;margin-bottom: 2px;" height="22" src="assets/images/plus_orange.png"
                alt=""></a>
          </div>
          <textarea class="form-control animateInput newInputBox" name="diagnosis" style="font-size: 17px;font-weight: 300;"
            placeholder="Enter about dignosis with this Prescription" maxlength="700" [(ngModel)]="complaint_diagnosis"
            onfocus="if(placeholder=='Enter about dignosis with this Prescription'){placeholder=''}"
            onblur="if(placeholder==''){placeholder='Enter about dignosis with this Prescription'}"></textarea>
        </div>
      </td>
    </tr>
    <!-- <tr>
      <td colspan="6" style="border-top: 0px solid lightgray;">&nbsp;</td>
    </tr> -->
    <tr>
      <td colspan="4" style="padding:3px 12px 3px 3px;">
        <div>
          <p class="errors" *ngIf="medicineError">{{medicineError}}</p>
          <div class="row">
            <div class="col-6">
              <div>
                <label style="font-size: 18px;color: #333;margin-bottom: 1px;" class="newInputStyleLabel">Search Medicine
                  <span class="required">*</span></label>
                <p-autoComplete class="autocomplete animateInput" placeholder="Enter drug name" [(ngModel)]="drug"
                  (ngModelChange)="modified = true" (onBlur)="removeLabelAutoComplete($event)"
                  (onFocus)="addLabelAutoComplete($event)" (keyup)="removeLabelAutoComplete($event)"
                  [suggestions]="drugs" field="product_name" (onSelect)="setMedicine(drug)"
                  (completeMethod)="searchMedicines($event)" emptyMessage="No results found" forceSelection=true
                  autocomplete=false>
                </p-autoComplete>
              </div>
            </div>

            <div class="col-6 onFocus">
              <label class="ifnoLabel twoColRadioLabel" style="font-size: 18px;color: #333;" class="newInputStyleLabel">Type of Medicine </label>
              <div class="form-group twoColRadio clearfix d-flex">

                <!-- <label class="custom-control mr-3 custom-radio" style="padding-left: 1px !important;">
                  <input style="height: 20px;width: 20px;" type="radio" [(ngModel)]="medicine.dosage" value="Tablet">
                  <span style="font-size: 17px;">Tablet</span>
                </label> -->
                <label class="container-radio custom-control mr-3"
                  style="margin: 6px 15px 5px 4px;  padding-top: 2px; font-size: 16px">Tablet
                  <input type="radio" [(ngModel)]="medicine.dosage" value="Tablet">
                  <span class="checkmark-radio"></span>
                </label>

                <!-- <label class="custom-control custom-radio mr-0" style="padding-left: 1px !important;">
                  <input style="height: 20px;width: 20px;" type="radio" [(ngModel)]="medicine.dosage" value="Syrup">
                  <span style="font-size: 17px;">Syrup</span>
                </label> -->
                <label class="container-radio custom-control mr-3"
                  style="margin: 6px 15px 5px 4px;  padding-top: 2px; font-size: 16px">Syrup
                  <input type="radio" [(ngModel)]="medicine.dosage" value="Syrup">
                  <span class="checkmark-radio"></span>
                </label>

                <!-- <label class="custom-control custom-radio mr-0" style="padding-left: 1px !important;">
                  <input style="height: 20px;width: 20px;" type="radio" [(ngModel)]="medicine.dosage" value="Drop">
                  <span style="font-size: 17px;">Drop/Ointment</span>
                </label> -->
                <label class="container-radio custom-control mr-3"
                  style="margin: 6px 15px 5px 4px;  padding-top: 2px; font-size: 16px">Drop/Ointment
                  <input type="radio" [(ngModel)]="medicine.dosage" value="Drop">
                  <span class="checkmark-radio"></span>
                </label>
              </div>

            </div>
          </div>
          <div class="row pl-2">
            <div class="col-lg-12 col-xl-12">
              <div>
                <div class="row bgColorTreatment">
                  <!-- first row of prescription -->
                  <div class="col-2 bord text-left pl-3 border-left border-top border-bottom border-right boldTitle">
                    <label style="font-size: 14px;" for="">Time of day<span class="required">*</span></label>
                  </div>
                  <div class="col bord  border-top border-bottom border-right boldTitle"
                    [ngClass]="{'coloredBlock': medicine.morning}">
                    <a class="">
                      <img class="icons" src="assets/images/morning_icon.svg" alt="">
                      Morning
                    </a>
                  </div>
                  <div class="col bord  border-top border-bottom border-right boldTitle"
                    [ngClass]="{'coloredBlock': medicine.noon}">
                    <a class="">
                      <img class="icons" src="assets/images/noon_icon.svg" alt="">
                      Noon
                    </a>
                  </div>
                  <div class="col bord  border-top border-bottom border-right boldTitle"
                    [ngClass]="{'coloredBlock': medicine.afternoon}">
                    <a class="">
                      <img class="icons" src="assets/images/afternoon_icon.svg" alt="">
                      A'noon
                    </a>
                  </div>
                  <div class="col bord  border-top border-bottom border-right boldTitle"
                    [ngClass]="{'coloredBlock': medicine.evening}">
                    <a class="">
                      <img class="icons" src="assets/images/evening_icon.svg" alt="">
                      Evening
                    </a>
                  </div>
                  <div class="col bord  border-top border-bottom border-right boldTitle"
                    [ngClass]="{'coloredBlock': medicine.night}">
                    <a class="">
                      <img class="icons" src="assets/images/night.svg" alt="">
                      Night
                    </a>
                  </div>
                  <div class="col bord  border-top border-bottom border-right boldTitle"
                    [ngClass]="{'coloredBlock': medicine.midnight}">
                    <a class="">
                      <img class="icons" src="assets/images/midnight_Icon.svg" alt="">
                      Midnight
                    </a>
                  </div>
                </div>
                <div class="row">
                  <!-- second row of prescription -->
                  <div class="col-2 bord text-left pl-3 border-left border-bottom border-right">
                    <label for="">Dosage <span class="required">*</span></label>
                  </div>

                  <div class="col bord p-0 border-right border-bottom">
                    <!-- dorpdown 1 -->
                    <div ngbDropdown class="d-inline-block dropdownCarrier">
                      <button class="btn btn-navigation NonColoredBlock" [ngClass]="{'coloredBlock': medicine.morning}"
                        id="dropdownBasic1" ngbDropdownToggle>
                        <span>{{medicine.morning ? medicine.morning : 'Select'}}</span>
                        <i _ngcontent-c0="" class="menu-caret fa fa-caret-down"> </i>
                      </button>
                      <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="uldropdown mt-0 pt-0 pb-0">
                        <li>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.morning=0.5">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/half-circle.png" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="">1/2</label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.morning=1">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-2">1</label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.morning=1.5">
                              <!--<div class="d-flex justify-content-between bg-secondary  highlight">

                                    <div class="">

                                    </div>
                                    <label class="pr-1">1
                                        <sup class="">1/2</sup>
                                    </label>
                                  </div>-->
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables ml-2"
                                    src="assets/images/half-circle.png" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-0">1<sup class="">1/2</sup></label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.morning=2">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables ml-2"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-2">2</label>
                              </div>
                            </a>
                          </div>

                        </li>
                      </ul>

                    </div>
                  </div>
                  <div class="col bord  p-0 border-right border-bottom">
                    <div ngbDropdown class="d-inline-block dropdownCarrier">
                      <!-- dorpdown 1 -->
                      <button class="btn btn-navigation NonColoredBlock" [ngClass]="{'coloredBlock': medicine.noon}"
                        id="dropdownBasic1" ngbDropdownToggle>
                        <span>{{medicine.noon ? medicine.noon : 'Select'}}</span>
                        <i _ngcontent-c0="" class="menu-caret fa fa-caret-down"> </i>
                      </button>
                      <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="uldropdown mt-0 pt-0 pb-0">
                        <li>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.noon=0.5">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/half-circle.png" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="">1/2</label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.noon=1">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-3">1</label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.noon=1.5">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables ml-2"
                                    src="assets/images/half-circle.png" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-1">1<sup class="">1/2</sup></label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.noon=2">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables ml-2"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-3">2</label>
                              </div>
                            </a>
                          </div>

                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col bord  p-0 border-right border-bottom">
                    <div ngbDropdown class="d-inline-block dropdownCarrier">
                      <!-- dorpdown 1 -->
                      <button class="btn btn-navigation NonColoredBlock"
                        [ngClass]="{'coloredBlock': medicine.afternoon}" id="dropdownBasic1" ngbDropdownToggle>
                        <span>{{medicine.afternoon ? medicine.afternoon : 'Select'}}</span>
                        <i _ngcontent-c0="" class="menu-caret fa fa-caret-down"> </i>
                      </button>
                      <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="uldropdown mt-0 pt-0 pb-0">
                        <li>
                          <div ngbDropdownItem>

                            <a class="" (click)="medicine.afternoon=0.5">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/half-circle.png" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="">1/2</label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.afternoon=1">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-2">1</label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.afternoon=1.5">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables ml-2"
                                    src="assets/images/half-circle.png" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-0">1<sup class="">1/2</sup></label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.afternoon=2">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables ml-2"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-2">2</label>
                              </div>
                            </a>
                          </div>

                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col bord  p-0 border-right border-bottom">
                    <div ngbDropdown class="d-inline-block dropdownCarrier">
                      <!-- dorpdown 1 -->
                      <button class="btn btn-navigation NonColoredBlock" [ngClass]="{'coloredBlock': medicine.evening}"
                        id="dropdownBasic1" ngbDropdownToggle>
                        <span>{{medicine.evening ? medicine.evening : 'Select'}}</span>
                        <i _ngcontent-c0="" class="menu-caret fa fa-caret-down"> </i>
                      </button>
                      <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="uldropdown mt-0 pt-0 pb-0">
                        <li>
                          <div ngbDropdownItem>

                            <a class="" (click)="medicine.evening=0.5">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/half-circle.png" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="">1/2</label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.evening=1">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-2">1</label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.evening=1.5">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables ml-2"
                                    src="assets/images/half-circle.png" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-0">1<sup class="">1/2</sup></label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.evening=2">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables ml-2"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-2">2</label>
                              </div>
                            </a>
                          </div>

                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col bord  p-0 border-right border-bottom">
                    <div ngbDropdown class="d-inline-block dropdownCarrier">
                      <!-- dorpdown 1 -->
                      <button class="btn btn-navigation NonColoredBlock" [ngClass]="{'coloredBlock': medicine.night}"
                        id="dropdownBasic1" ngbDropdownToggle>
                        <span>{{medicine.night ? medicine.night : 'Select'}}</span>
                        <i _ngcontent-c0="" class="menu-caret fa fa-caret-down"> </i>
                      </button>
                      <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="uldropdown mt-0 pt-0 pb-0">
                        <li>
                          <div ngbDropdownItem>

                            <a class="" (click)="medicine.night=0.5">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/half-circle.png" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="">1/2</label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.night=1">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-2">1</label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.night=1.5">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables ml-2"
                                    src="assets/images/half-circle.png" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-0">1<sup class="">1/2</sup></label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.night=2">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables ml-2"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-2">2</label>
                              </div>
                            </a>
                          </div>

                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="col bord  p-0 border-right border-bottom">
                    <div ngbDropdown class="d-inline-block dropdownCarrier">
                      <!-- dorpdown 1 -->
                      <button class="btn btn-navigation NonColoredBlock" [ngClass]="{'coloredBlock': medicine.midnight}"
                        id="dropdownBasic1" ngbDropdownToggle>
                        <span>{{medicine.midnight ? medicine.midnight : 'Select'}}</span>
                        <i _ngcontent-c0="" class="menu-caret fa fa-caret-down"> </i>
                      </button>
                      <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="uldropdown mt-0 pt-0 pb-0">
                        <li>
                          <div ngbDropdownItem>

                            <a class="" (click)="medicine.midnight=0.5">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/half-circle.png" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="">1/2</label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.midnight=1">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-2">1</label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.midnight=1.5">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables ml-2"
                                    src="assets/images/half-circle.png" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-0">1<sup class="">1/2</sup></label>
                              </div>
                            </a>
                          </div>
                          <div ngbDropdownItem>
                            <a class="" (click)="medicine.midnight=2">
                              <div class="d-flex justify-content-between bg-secondary  highlight">
                                <div class="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Tablet'" class="iconsTables ml-2"
                                    src="assets/images/full-cirle.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Drop'" class="iconsTables drop"
                                    src="assets/images/drop.svg" alt="">
                                  <img *ngIf="medicine.dosage === 'Syrup'" class="iconsTables spoon"
                                    src="assets/images/spoon.png" alt="">
                                </div>
                                <label class="pr-2">2</label>
                              </div>
                            </a>
                          </div>

                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- third row -->
                  <div class="col-7 bord text-left pl-3 border-left border-bottom border-right">
                    <label for="">Specific Instruction when to take medicine <span class="required">*</span></label>
                  </div>
                  <div class="col bord p-0  border-right border-bottom">
                    <!-- dorpdown 1 -->
                    <div ngbDropdown class="d-inline-block dropdownCarrier">
                      <button class="btn btn-navigation NonColoredBlock"
                        [ngClass]="{'coloredBlock': medicine.medicine_guide}" id="dropdownBasic1" ngbDropdownToggle>
                        {{medicine.medicine_guide ? medicine.medicine_guide : 'Select from ..'}}
                        <i _ngcontent-c0="" class="menu-caret fa fa-caret-down"> </i>
                      </button>
                      <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="uldropdownAfterMeal mt-0 pt-0 pb-0">
                        <li>
                          <div ngbDropdownItem>
                            <div>
                              <a class="" *ngFor="let obj of medicineInstructions; let i = index"
                                (click)="medicine.medicine_guide = obj">
                                <div class="d-flex justify-content-between bg-secondary highlight">
                                  <label class="">{{obj}}</label>
                                </div>
                              </a>
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <!-- fourth row -->
                  <div class="col-2 bord text-left pl-3  border-bottom border-left">
                    <label for="">Duration <span class="required">*</span></label>
                  </div>
                  <div class="col-8 bord p-0  border-right border-bottom border-left">
                    <div class="row">
                      <div class="col-2 bord p-0 form-group mb-0 border-right border-left"
                        [ngClass]="{'coloredBlock': medicine.days}">
                        <input type="text" placeholder="Enter days" name="day" [(ngModel)]="medicine.days"
                          (keypress)="validateNumber($event)" maxlength="3"
                          onfocus="if(placeholder=='Enter days'){placeholder=''}"
                          onblur="if(placeholder==''){placeholder='Enter days'}"
                          style="background-color:#f0fdff;height: 100%;padding-bottom: 10px;">
                      </div>
                      <div class="col-2 bord border-right " [ngClass]="{'coloredBlock': medicine.days}">
                        <label for="">Days</label>
                      </div>
                      <div class="col-3 bord p-0 form-group mb-0 border-right"
                        [ngClass]="{'coloredBlock': medicine.weeks}">
                        <input type="text" placeholder="Enter weeks" name="week" [(ngModel)]="medicine.weeks"
                          (keypress)="validateNumber($event)" maxlength="3"
                          onfocus="if(placeholder=='Enter weeks'){placeholder=''}"
                          onblur="if(placeholder==''){placeholder='Enter weeks'}"
                          style="background-color:#f0fdff;height: 100%;padding-bottom: 10px;">
                      </div>
                      <div class="col-2 bord  border-right" [ngClass]="{'coloredBlock': medicine.weeks}">
                        <label for="">Weeks</label>
                      </div>
                      <div class="col-3 bord p-0 form-group mb-0 border-right"
                        [ngClass]="{'coloredBlock': medicine.months}">
                        <input type="text" placeholder="Enter months" name="month" [(ngModel)]="medicine.months"
                          (keypress)="validateNumber($event)" maxlength="3"
                          onfocus="if(placeholder=='Enter months'){placeholder=''}"
                          onblur="if(placeholder==''){placeholder='Enter months'}"
                          style="background-color:#f0fdff;height: 100%;padding-bottom: 10px;">
                      </div>
                    </div>
                  </div>
                  <div class="col-2 bord coloredBlock  border-right border-bottom"
                    [ngClass]="{'coloredBlock': medicine.months}">
                    <label for="">Months</label>
                  </div>
                </div>
                <!--<div class="row">
                    <div class="col-4 bord text-left pl-3 border-left border-right border-bottom">
                      <label for="">Special Instructions</label>
                    </div>
                    <div class="col-8 bord p-0  border-right border-bottom">
                      <div ngbDropdown class="d-inline-block dropdownCarrier">

                      </div>
                    </div>
                  </div>-->
                <div class="row">
                  <div class="col-10 bord text-left pl-3 forborder border-bottom border-left">
                    <label class="ng-tns-c3-0" for="">Special Instructions <span _ngcontent-c3="" class="nativLang">(
                        Input enabled for Native language ) Language of Instruction : <b class="ng-tns-c3-0">
                          {{languages[patient.language]}}</b></span></label>
                  </div>
                  <div
                    class="col-2 bord p-0 forborderButton d-flex justify-content-center align-items-center border-bottom border-right">
                    <button class=" newBtnStyle"
                      (click)="open(SelectInst, 'SelectInstPopoup')">Select</button>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12 bord forborderInst text-left pl-3  border-bottom border-left border-right">
                    <!-- <label  class="speInst" for="">Must Consult for continuation</label>-->
                    <input class="inputOfPrescription form-control" type="text" style="font-size: 15px;"
                      placeholder="Enter instructions" name="week" [(ngModel)]="medicine.instructions"
                      (ngModelChange)="modified = true" onfocus="if(placeholder=='Enter instructions'){placeholder=''}"
                      onblur="if(placeholder==''){placeholder='Enter instructions'}">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="text-align: right;margin-right: -22px; margin-top: 5px;">
            <div class="col-12">
              <button (click)="saveMedicine()" *ngIf="editIndex == null"
                style="margin-top: 8px;"
                class="newBtnStyle">Add to Prescription&#8680;</button>
              <button (click)="updateMedicine()" *ngIf="editIndex != null"
              style="margin-top: 8px;"
                class="newBtnStyle">Add to Prescription&#8680;</button>
            </div>
          </div>

        </div>
      </td>
      <td colspan="2" style="margin-left: 10px;padding:3px 3px 3px 3px;vertical-align: top; ">
        <div class="col-12  mb-2" style="border-radius: 3px;border:1px solid lightgray; padding:12px 8px 12px 8px">
          <label style="font-size: 18px;color: #333;" class="newInputStyleLabel">
            Current Prescription
          </label> <span
            ngbTooltip="Your current prescription is shown here. Each medicine you add is shown in the list below. You can remove a medicine using the bin icon on the side. You must press 'Save' to save to the database"
            style="margin-left:10px"><img src="assets/images/info.png" height="17" alt=""
              style="vertical-align: text-bottom;"></span><br>
          <div class="col-12 mb-0 pl-0" *ngIf="medicineList.length == 0">
            <p style="font-weight: 11px !important; color: #777777;">No medicines added</p>
            <p class=" errors clearfix" *ngIf="prescriptionErrors['medicines']">{{prescriptionErrors['medicines']}}
            </p>
          </div>
          <div class="d-flex mb-0" *ngFor="let med of medicineList; let i = index" style="margin-top: 2px;">
            <div class="col-10">
              <span class="filleData" style="font-size: 18px; color: #777777; font-weight: bold;">{{i+1}}.</span>
              <span class="filleData " style="font-size: 18px; color: #777777; font-weight: bold;">
                {{medicineList[medicineList.length - i-1].Drug.product_name}}</span>
              <br>
              <span class="filleData " style="font-size: 14px; color: #ccc; font-weight: 800; font-style: italic; margin-left: 16px;">
                {{medicineList[medicineList.length - i-1].dosage}} (
                {{medicineList[medicineList.length - i-1].frequency}} times ,
                {{medicineList[medicineList.length - i-1].days ? medicineList[medicineList.length - i-1].days+' days' : ''}}
                {{medicineList[medicineList.length - i-1].weeks ? medicineList[medicineList.length - i-1].weeks+' weeks' : ''}}
                {{medicineList[medicineList.length - i-1].months ? medicineList[medicineList.length - i-1].months+' months' : ''}}
                ,
                {{medicineList[medicineList.length - i-1].medicine_guide}})
              </span>
            </div>
            <div class="col-1 icons d-flex" style="font-size: 18px;">

              <a title="Delete" (click)="deleteMedicine(medicineList.length -i-1,d, prescriptions)">
                <i class="fa fa-trash"></i>
              </a>
              <a class="pt-2" title="Edit" *ngIf="!readOnly"
                (click)="editMedicine(medicineList[medicineList.length - i-1], medicineList.length-i-1)">
                <i class="fa fa-edit"></i>
              </a>
            </div>
          </div>

        </div>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="padding:3px 3px 3px 3px;vertical-align: top;">
        <div>
          <label for="suggested_investigation" style="font-size: 18px;color: #333;margin-bottom: 0px;" class="newInputStyleLabel">Suggested Investigation</label>
          <a ngbTooltip="Add Investigation" (click)="open(Investigation, 'InvestigationPopoup')"><img height="22"
              style="vertical-align: text-bottom;margin-bottom: 2px;" src="assets/images/plus_orange.png" alt=""></a>
          <textarea [(ngModel)]="suggested_investigation" placeholder="Enter suggested investigations"
            class="form-control animateInput newInputBox" style="font-size: 15px;height: 200px;resize: none;">
          </textarea>
        </div>
      </td>
      <td colspan="2" style="padding:3px 6px 3px 6px; vertical-align: top;">
        <div>
          <label for="general_instruction" style="font-size: 18px;color: #333;margin-bottom: 0px;" class="newInputStyleLabel">General Instructions</label>
          <textarea class="form-control animateInput newInputBox" name="general_instruction"
            style="font-size: 15px;font-weight: 300; height: 200px !important;resize: none;"
            placeholder="Enter the General Instructions with this Prescription" maxlength="700"
            [(ngModel)]="general_instruction"
            onfocus="if(placeholder=='Enter the General Instructions with this Prescription'){placeholder=''}"
            onblur="if(placeholder==''){placeholder='Enter the General Instructions with this Prescription'}"> </textarea>
        </div>
      </td>
    </tr>
    <tr>
      <td colspan="2" style="padding:3px 3px 3px 3px; vertical-align: top;">
        <label for="vaccination" style="font-size: 18px;color: #333;margin-bottom: 0px;" class="newInputStyleLabel">Next followup</label>
        <img (click)="d.toggle()" class="calendarbg top25" style="margin-bottom: 5px; height: 26px;"
          src="assets/images/calendarIcon.svg" alt="" />
        <input placeholder="yyyy-mm-dd" name="dp" type="button" [(ngModel)]="dateChosen" ngbDatepicker
          #d="ngbDatepicker" style="width: 1px;border-color: #FFF;color: #FFF; background-color: Transparent;
          background-repeat:no-repeat;
          border: none;
          cursor:pointer;
          overflow: hidden;
          outline:none;" [minDate]="today" (document:click)="closeCalender($event,d)">

        <br>
        <textarea class="form-control animateInput newInputBox" name="vaccination" placeholder="Enter Next Followup"
          [(ngModel)]="next_vaccination" style="font-size: 15px; width: 100%;resize: none;"
          onfocus="if(placeholder=='Enter Next Followup'){placeholder=''}"
          onblur="if(placeholder==''){placeholder='Enter Next Followup'}"> </textarea>

      </td>
      <td colspan="2" style="padding:3px 3px 3px 3px; vertical-align: top;">
        <label for="vaccination" style="font-size: 18px;color: #333;margin-bottom: 0px;" class="newInputStyleLabel">Precription expiration date</label>

        <img (click)="dd.toggle()" class="calendarbg top25" style="margin-bottom: 5px; height:26px"
          src="assets/images/calendarIcon.svg" alt="" />
        <input placeholder="yyyy-mm-dd" name="ddp" type="button" [(ngModel)]="expirationDate" ngbDatepicker
          #dd="ngbDatepicker" style="width: 1px;border-color: #FFF;color: #FFF; background-color: Transparent;
          background-repeat:no-repeat;
          border: none;
          cursor:pointer;
          overflow: hidden;
          outline:none;" [minDate]="today" (document:click)="closeCalenderForExp($event,dd)">
        <br>
        <textarea class="form-control animateInput newInputBox" name="expirationDt"
          placeholder="Enter expiration date of prescription" [(ngModel)]="expirationDateStr"
          style="font-size: 15px; width: 100%;resize: none;"
          onfocus="if(placeholder=='Enter expiration date of prescription'){placeholder=''}"
          onblur="if(placeholder==''){placeholder='Enter expiration date of prescription'}"> </textarea>

      </td>
    </tr>
  </table>


  <ng-template #PreList let-c="close" let-d="dismiss">
    <div class="popUps">
      <div class="modal-header">
        <h6 class="modal-title text-uppercase">Prescription List</h6>
        <button type="button" class="close" aria-label="Close" (click)="d()">
          <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="model-content vaccinationBody">
          <div class="row mt-3">
            <div class="col mt-0 pt-0 prescriptionScroller">
              <div class="prescriptionLinks pt-0 pr-1 text-center" *ngFor="let prescription of viewPrescriptionList"
                (click)="selectedPrescription = prescription">
                <span><a
                    class="prescriptionDate {{prescription.id == selectedPrescription.id? 'active' : ''}}">Prescription
                    for {{prescription.createdAt | date: 'dd/MM/yyyy'}}</a></span>
              </div>
            </div>
            <div class="col-8">

              <div class="prescriptionData pl-3">
                <h5 class="headingUpprcase16 mb-4">Prescriptions</h5>
                <div class="prescriptionsRow mb-2 clearfix"
                  *ngFor="let med of getSortedMedicines(selectedPrescription.Medicines,'id'); let i = index;">
                  <!--<div class="pull-left">
                        {{med.Drug.name}}-{{med.Drug.generic_name}}
                        <p>{{med.dosage}} {{med.Drug.unit}} for {{med.duration}} {{med.duration_unit}}, Total Qty:{{med.quantity}}</p>
                    </div>-->
                  <span class="filleData">{{i+1}} .</span>
                  <span class="filleData pl-3"> {{med.Drug.product_name}}</span>
                  <!--<span class="filleData pl-3">
                      {{med.dosage}} |
                      {{med.frequency}} times |
                      {{med.days ? med.days+' days' : ''}}
                      {{med.weeks ? med.weeks+' weeks' : ''}}
                      {{med.months ? med.months+' months' : ''}} |
                      {{med.medicine_guide}}
                      {{med.instructions ? ' | ' + med.instructions : ''}}
                    </span> -->
                </div>
                <div class="filleData">General Instructions: <p class="font18 text-justify">
                    {{selectedPrescription.general_instructions ? selectedPrescription.general_instructions : 'N/A'}}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <ng-template #SelectInst let-c="close" let-d="dismiss">
    <div class="popUps">
      <div class="modal-header">
        <h6 class="modal-title">Select Instructions</h6>
        <button type="button" class="close" aria-label="Close" (click)="d()">
          <!-- <span aria-hidden="true">
            <img src="assets/images/close-btn.png" alt="">
          </span> -->
          <span aria-hidden="true" style="font-size: 34px;">
            ×
            <!-- <img src="assets/images/close-btn.png" alt=""> -->
          </span>
        </button>
      </div>
      <div class="modal-body ">
        <div class="model-content ">
          <div class="row">
            <div class="form-group checkbox withLabel mb-2 selectAll">
              <label>
                <input class="form-control filled-in" type="checkbox"
                  [checked]="selectedInstructions && specialInstructions[0] && specialInstructions[0][selectedLanguage] ? selectedInstructions.length == specialInstructions[0][selectedLanguage].length : false"
                  (change)="selectAllLanguages($event)">
                <span>Select All</span>
              </label>
            </div>


            <div class="form-group selectDropDown mb-0 langDrop" style = "padding-left: 45%;">
              <span style="font-size:16px;"><span style="font-family: 'Mukta', sans-serif;">
                  <div id="google_translate_element"></div>
                </span></span>

              <select style="width:100px; padding-left: 8px; padding-right: 8px;" [(ngModel)]="selectedLanguage"
              class="newInputBox"
                (ngModelChange)="clearSelectedInstructions()">
                <option value="English">English</option>
                <option value="Hindi">Hindi</option>
                <option value="Marathi">Marathi</option>
              </select>
            </div>

            <div class="certificates topBottomBorder">
              <ul>
                <li class="clearfix" *ngFor="let obj of specialInstructions[0][selectedLanguage]">
                  <div class="form-group checkbox withLabel">
                    <label>
                      <input class="form-control filled-in" type="checkbox"
                        [checked]="selectedInstructions && selectedInstructions.indexOf(obj) != -1"
                        (change)="addSpecialInstruction($event, obj)">
                      <span>{{obj}}</span>
                    </label>
                  </div>
                </li>

              </ul>
            </div>
          </div>
          <div class="row d-flex pt-3" style="justify-content: flex-end;">
            <button class="newBtnStyle"
              (click)="saveSpecialInstructions(); d()">Add Instructions</button>

          </div>
        </div>
      </div>
    </div>
  </ng-template>

  <div class="center mt-5 buttons">
    <!--<button class="btn btn-primary mr-2 opd-primary-btn transformNormal btn-md upprcase">Save & Next</button>
      <button class="btn btn-secondary ml-2 opd-primary-btn transformNormal btn-md upprcase">clear form</button>-->
    <button class="newBtnStyle"
      (click)="savePrescription()">Save</button>
    <button class="newBtnStyle"
      (click)="closePrescription()">Close</button>
    <!-- <button *ngIf="readOnly" class="btn btn-secondary ml-2 opd-primary-btn transformNormal btn-md upprcase"
      (click)="exitVisit()">Close this visit</button> -->
    <!-- <a class="pageNavigationLeft" (click)="goToPrevTab()"><span><i
          class="fa fa-angle-left"></i></span><span>Investigation</span></a>
    <a class="pageNavigationRight" (click)="goToNextTab()"><span>Growth & Nutrition</span> <span><i
          class="fa fa-angle-right"></i></span></a> -->
  </div>
</div>

<p-confirmDialog appendTo="body"></p-confirmDialog>

<ng-template #Complaints let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title " style="text-transform: capitalize;">Complaints</h6>
      <button type="button" class="close" aria-label="Close" (click)="closeComplaints(c)">
        <span aria-hidden="true" style="font-size: 34px;">
          ×
          <!-- <img src="assets/images/close-btn.png" alt=""> -->
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">
        <div class="form-group form-inline" style="margin-bottom: 10px;">
          <!-- Search: -->
          <div style="width: 70%;"><input placeholder="Search Complaints"
              style="height: 40px; font-size: 14px;margin-right: 10px;border-radius: 6px;" type="text"
              (keyup)="searchComplaints()" [(ngModel)]="searchComplaint" /></div>
          <div style="width: 20%;margin-left: 10px"> <a ngbTooltip="Insert new Complaint"
              (click)="open(AddNewComplaint, 'AddNewComplaintPopoup')">
              <img style="margin-left: 10px" height="25" src="assets/images/plus_orange.png" alt="">
            </a></div>
        </div>
        <div *ngIf="chief_complaintsOri.length>0" style="border: 1px solid #ccc;border-radius: 2px; padding: 8px;">
          <span *ngFor="let comp of chief_complaintsOri; index as i"><label (click)="addComplaints(comp,false,i)"
              style="font-size: 13px;color: #71a2ab; background-color: #f0fdff;border: 1px solid #71a2ab; padding: 1px;padding-left: 5px; padding-right: 5px;text-align: center; border-radius: 16px; margin:6px 0px 6px 0px; ">
              {{comp}}
              <span style="font-size: 13px;color: #71a2ab; font-weight: bold;">×</span></label>
          </span></div>
        <div class="selectionTable" style="height: 400px; align-self: center; overflow-y:scroll;">

          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Complaints</th>
                <th scope="col" style="padding-left: 3px;">Add</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let complaint of complaintsArry; index as i">
                <th scope="row">{{ i + 1 }}</th>
                <td>
                  <ngb-highlight [result]="complaint"></ngb-highlight>
                </td>
                <td>
                  <!-- <input type="checkbox" id="{{ 'com' + i }}"
                    (change)="addComplaints(complaint,$event.target.checked,i)"> -->
                  <label class="container">
                    <input type="checkbox" id="{{ 'com' + i }}"
                      (change)="addComplaints(complaint,$event.target.checked,i)" style="border: 1px solid #ccc;">
                    <span class="checkmark" style="border: 1px solid #ccc; "></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row d-flex justify-content-around align-items-center pt-3">
          <button class="btn btn-primary mr-2 opd-primary-btn transformNormal btn-md" (click)="SaveComplaints(c)">Add
            Complaints
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Diagnosis let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title " style="text-transform: capitalize;">Add Diagnosis</h6>
      <button type="button" class="close" aria-label="Close" (click)="closeDiagnosis(c)">
        <span aria-hidden="true" style="font-size: 34px;">
          ×
          <!-- <img src="assets/images/close-btn.png" alt=""> -->
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">
        <div class="form-group form-inline" style="margin-bottom: 10px;">
          <!-- Search: -->
          <div style="width: 66%;"><input placeholder="Search Diagnosis"
              style="height: 40px; font-size: 14px;margin-right: 10px;border-radius: 6px; border-color: #ccc !important;"
              type="text" (keyup)="searchDiagnosis()" [(ngModel)]="searchDiagno" /></div>
          <div style="width: 20%;margin-left: 10px"> <a ngbTooltip="Insert new Diagnosis"
              (click)="open(AddNewDiagnosis, 'AddNewDiagnosisPopoup')"><img style="margin-left: 10px" height="25"
                src="assets/images/plus_orange.png" alt=""></a></div>
        </div>
        <div *ngIf="complaint_diagnosisOri.length>0" style="border: 1px solid #ccc;border-radius: 2px; padding: 8px;">
          <span *ngFor="let dia of complaint_diagnosisOri; index as i"><label (click)="addDiagnosis(dia,false,i)"
              style="font-size: 13px;color: #71a2ab;background-color: #f0fdff;border: 1px solid #71a2ab; padding: 1px;padding-left: 5px; padding-right: 5px;text-align: center; border-radius: 16px; margin:6px 0px 6px 0px; ">
              {{dia}}
              <span style="font-size: 13px;color: #71a2ab; font-weight: bold;">×</span></label>
          </span></div>

        <div class="selectionTable" style="height: 400px; align-self: center; overflow-y:scroll;">



          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Diagnosis</th>
                <th scope="col" style="padding-left: 3px;">Add</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let diagnosis of diagosisArry; index as i">
                <th scope="row">{{ i + 1 }}</th>
                <td>
                  <ngb-highlight [result]="diagnosis"></ngb-highlight>
                </td>
                <td>
                  <!-- <input type="checkbox" id="{{ 'dia' + i }}" (change)="addDiagnosis(diagnosis,$event.target.checked)"> -->
                  <label class="container">
                    <input type="checkbox" id="{{ 'dia' + i }}" (change)="addDiagnosis(diagnosis,$event.target.checked)"
                      style="border: 1px solid #ccc;">
                    <span class="checkmark" style="border: 1px solid #ccc; "></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row d-flex justify-content-around align-items-center pt-3">
          <button class="btn btn-primary mr-2 opd-primary-btn transformNormal btn-md" (click)="SaveDiagnosis(c)">Add
            Diagnosis
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #Investigation let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title " style="text-transform: capitalize;">Add Investigation</h6>
      <button type="button" class="close" aria-label="Close" (click)="closeInvestigations(c)">
        <span aria-hidden="true" style="font-size: 34px;">
          ×
          <!-- <img src="assets/images/close-btn.png" alt=""> -->
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">
        <div class="form-group form-inline" style="margin-bottom: 10px;">
          <!-- Search: -->
          <div style="width: 66%;"><input placeholder="Search Investigations"
              style="height: 40px; font-size: 14px;margin-right: 10px;border-radius: 6px;" type="text"
              (keyup)="searchInvestigations()" [(ngModel)]="searchInvestigation" /></div>
          <div style="width: 20%;margin-left: 10px"> <a ngbTooltip="Insert new Investigation:"
              (click)="open(AddNewInvestigation, 'AddNewInvestigationPopoup')"><img style="margin-left: 10px"
                height="25" src="assets/images/plus_orange.png" alt=""></a></div>
        </div>
        <div *ngIf="suggested_investigationOri.length>0"
          style="border: 1px solid #ccc; border-radius: 2px;  padding: 8px;">
          <span *ngFor="let inv of suggested_investigationOri; index as i"><label
              (click)="addInvestigations(inv,false,i)"
              style="font-size: 13px;color: #71a2ab;background-color: #f0fdff;border: 1px solid #71a2ab; padding: 1px;padding-left: 5px; padding-right: 5px;text-align: center; border-radius: 16px; margin:6px 0px 6px 0px; ">
              {{inv}}
              <span style="font-size: 13px;color: #71a2ab; font-weight: bold;">×</span></label>
          </span></div>
        <div class="selectionTable" style="height: 400px; align-self: center; overflow-y:scroll;">

          <table class="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Investigation</th>
                <th scope="col" style="padding-left: 3px;">Add</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let investigation of investigationArry; index as i">
                <th scope="row">{{ i + 1 }}</th>
                <td>
                  <ngb-highlight [result]="investigation"></ngb-highlight>
                </td>
                <td>
                  <!-- <input type="checkbox" id="{{ 'inv' + i }}"
                    (change)="addInvestigations(investigation,$event.target.checked)"> -->
                  <label class="container">
                    <input type="checkbox" id="{{ 'inv' + i }}"
                      (change)="addInvestigations(investigation,$event.target.checked)" style="border: 1px solid #ccc;">
                    <span class="checkmark" style="border: 1px solid #ccc; "></span>
                  </label>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="row d-flex justify-content-around align-items-center pt-3">
          <button class="btn btn-primary mr-2 opd-primary-btn transformNormal btn-md" (click)="SaveInvestigation(c)">Add
            Investigations
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #AddNewComplaint let-w="close" let-e="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title " style="text-transform: capitalize;">add new complaint</h6>
      <button type="button" class="close" aria-label="Close" (click)="e()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">

        <div class="selectionTable" style="align-self: center; overflow-y:scroll;">
          <div class="form-group form-inline" style="margin-bottom: 10px;">
            Insert New Complaint:
            <div><input class="form-control ml-2" type="text" [(ngModel)]="newComplaint" /></div>
          </div>
        </div>
        <div class="row d-flex justify-content-around align-items-center pt-3">
          <button class="btn btn-primary mr-2 opd-primary-btn transformNormal btn-md" (click)="AddMore('com',w)">Save
            Complaint
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #AddNewDiagnosis let-w="close" let-e="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title " style="text-transform: capitalize;">add new diagnosis</h6>
      <button type="button" class="close" aria-label="Close" (click)="e()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">

        <div class="selectionTable" style="align-self: center; overflow-y:scroll;">
          <div class="form-group form-inline" style="margin-bottom: 10px;">
            Insert New Diagnosis:
            <div><input class="form-control ml-2" type="text" [(ngModel)]="newDiagnosis" /></div>
          </div>
        </div>
        <div class="row d-flex justify-content-around align-items-center pt-3">
          <button class="btn btn-primary mr-2 opd-primary-btn transformNormal btn-md" (click)="AddMore('dia',w)">Save
            Diagnosis
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #AddNewInvestigation let-w="close" let-e="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title " style="text-transform: capitalize;">add new Investigation </h6>
      <button type="button" class="close" aria-label="Close" (click)="e()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">

        <div class="selectionTable" style="align-self: center; overflow-y:scroll;">
          <div class="form-group form-inline" style="margin-bottom: 10px;">
            Insert New Investigation:
            <div><input class="form-control ml-2" type="text" [(ngModel)]="newInvestigation" /></div>
          </div>
        </div>
        <div class="row d-flex justify-content-around align-items-center pt-3">
          <button class="btn btn-primary mr-2 opd-primary-btn transformNormal btn-md" (click)="AddMore('inve',w)">Save
            Investigation
          </button>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #footerTemplate>
  <hr class="my-0">
  <button class="btn btn-secondary btn-sm m-2 float-right" (click)="d.close()">Close</button>
</ng-template>