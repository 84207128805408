import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { PatientService } from '../../../services/patient.service'
import * as moment from 'moment';

@Component({
  selector: 'app-target-height-graph',
  templateUrl: './target-height-graph.component.html',
  styleUrls: ['./target-height-graph.component.css']
})
export class TargetHeightGraphComponent implements OnInit {

  @Input() isForPopup: false;
  chart: Chart;
  graphSeries = [];
  xAxisPlotLines = null;
  xAxisTitle = '';
  seriesX = [];
  totalRealWeeks = [];
  categories = null;
  birthWeek = 40;
  patient: any = { gender: 'Male', fathersHeight: 170, mothersHeight: 175 }
  isPreTerm = 0;
  height = 400;
  width = 550;
  constructor(private patientService: PatientService,) { }

  ngOnInit(): void {
    // this.init();

    if (this.isForPopup) {
      this.width = 1000;
      this.height = window.innerHeight - 180;
    }

    this.patient = JSON.parse(localStorage.getItem('patientData'));

    const PATIENT_DOB_FORMAT = "DD-MMM-YYYY";
    const actualDate = moment(this.patient.DOB, PATIENT_DOB_FORMAT); // sept 18 2018
    const expectedDate = moment(this.patient.eDOB, PATIENT_DOB_FORMAT);// oct 15 2018
    const refDate = expectedDate.subtract(40, "weeks"); //january 8, 2018
    const diffWeeks = actualDate.diff(refDate, "days"); // 253
    const gestationalAge = {
      w: Math.round(Number(diffWeeks / 7)), // 36
      d: diffWeeks % 7 // 1
    };
    this.birthWeek = gestationalAge.w;
    var weeks = this.calculateWeek(new Date(this.patient.DOB).getTime(), new Date(this.patient.eDOB).getTime());
    if (weeks < 17 && weeks >= 13) {
      this.isPreTerm = 2;
    } else if (weeks < 13 && weeks > 2) {
      this.isPreTerm = 1;
    }
    else {
      this.isPreTerm = 0;
    }

    this.getGraphs();

  }

  calculateWeek(d1, d2) {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
  }

  getGraphs() {

    var postData = {
      fathersHeight: this.patient.fathersHeight,
      mothersHeight: this.patient.mothersHeight,
      gender: this.patient.gender
    }
    this.patientService.getPatientsTargetHeightGraph(this.patient._id, this.patient.visits[0]._id, postData).subscribe(response => {

      // httpCall("post", `patient/${this.state.patient._id}/visits/${this.state.visit._id}/graph`, data)
      //   .then(result => {
      this.graphSeries = response.graphData,
        this.xAxisPlotLines = response.xAxisPlotLines,
        this.xAxisTitle = "Gestational Age / Weeks",
        this.seriesX = response.seriesX
      this.getRealAge();

    })
  }


  getRealAge() {
    var realWeeksArray = [];
    var uniqueArray = [];
    var initial = 0;
    var dataArray = [];
    var allGweeksArray = [];
    var labelArray = [];
    if (this.graphSeries.length) {
      dataArray = this.graphSeries[0].data

      for (var i = 0; i < dataArray.length; i++) {
        allGweeksArray.push(dataArray[i][0]);
      }
    }

    for (var j = 0; j <= allGweeksArray[allGweeksArray.length - 1]; j++) {

      realWeeksArray.push(initial++);
    }

    for (let i = 0; i < realWeeksArray.length; i++) {
      if (this.isPreTerm != 0) {
        if (realWeeksArray[i] < (41 - this.birthWeek)) {
          var weeks: any = 0;
          weeks = this.getWeekAndDaysFromDays(realWeeksArray[i] * 7)
          labelArray.push(weeks);
        }
        else if (realWeeksArray[i] < 53) {
          var months: any = 0;
          months = this.getMonthAndDaysFromDays(realWeeksArray[i] * 7)
          labelArray.push(months);
        }
        else {
          var years: any = 0;
          years = this.getYearAndMonthFromDays(realWeeksArray[i] * 7)
          labelArray.push(years);
        }
      }
      else {
        if (realWeeksArray[i] < 5) {
          var weeks: any = 0;
          weeks = this.getWeekAndDaysFromDays(realWeeksArray[i] * 7)
          labelArray.push(weeks);

        }
        else if (realWeeksArray[i] < 53) {
          var months: any = 0;
          months = this.getMonthAndDaysFromDays(realWeeksArray[i] * 7)
          labelArray.push(months);
        }
        else {
          var years: any = 0;
          years = this.getYearAndMonthFromDays(realWeeksArray[i] * 7)
          labelArray.push(years);
        }
      }

    }

    this.totalRealWeeks = realWeeksArray,
      this.categories = labelArray
    this.init();
  }

  getYearAndMonthFromDays(numberOfDays) {
    var years = Math.floor(numberOfDays / 365);
    var months = Math.floor(numberOfDays % 365 / 30);
    var yearsDisplay = years > 0 ? years + (years == 1 ? "y" : "y") : "";
    var monthsDisplay = months > 0 ? months + (months == 1 ? "m" : "m") : "";
    return yearsDisplay + monthsDisplay;
  }
  getWeekAndDaysFromDays(numberOfDays) {
    var weeks = Math.floor(numberOfDays / 7);
    var days = numberOfDays % 7;
    var weekDisplay = weeks > 0 ? weeks + (weeks == 1 ? "w" : "w") : "";
    var daysDisplay = days > 0 ? days + (days == 1 ? "d" : "d") : "";
    return weekDisplay + daysDisplay;
  }
  getMonthAndDaysFromDays(numberOfDays) {
    var months = Math.floor(numberOfDays % 365 / 30);
    var days = Math.floor(numberOfDays % 365 % 30);


    var monthsDisplay = months > 0 ? months + (months == 1 ? "m" : "m") : "";
    var daysDisplay = days > 0 ? days + (days == 1 ? "d" : "d") : "";

    return monthsDisplay + daysDisplay;
  }


  getConf() {
    // let graphColor = this.patient ? (this.patient.gender === 'Male' ? '#CCFFFF' : '#FFCCFF') : '#fff';
    let graphColor = '#fff';
    let tmpXAxis = [];
    tmpXAxis.push({
      gridLineWidth: 1,
      gridLineColor: '#C5EEFA',
      allowDecimals: false,
      tickInterval: 2,
      title: {
        text: 'Age in Years',
        style: {
          fontSize: '15px',
          fontWeight: 'bold'
        }
      },
      labels: {
        formatter: function () {
          // return Math.round((this.value * 7 / 365) * 10) / 10;
          var val = Number(((this.value * 7 / 365) * 10) / 10);
          val = parseInt(val.toString());
          return val;
        },
        style:
        {
          color: "#555555",
          fontSize: "15px",
          "enabled": true
        }
      },
    });
    if (this.seriesX.length > 0) {
      tmpXAxis.push({
        "title": {
          "text": 'Age in Months',
          "style": {
            "fontSize": '15px',
            "fontWeight": 'bold'
          }
        },
        "opposite": true,
        "labels": {
          "color": "#555555",
          "fontSize": "15px",
          "enabled": true,
          "step": 1
        },
        "min": Math.round((this.seriesX[0] * 7 / 30) * 10) / 10,
        "max": Math.round((this.seriesX[this.seriesX.length - 1] * 7 / 30) * 10) / 10
      });
    } else {
      tmpXAxis.push({
        "title": {
          "text": ' ',
          "style": {
            "fontSize": '15px',
            "fontWeight": 'bold',
          },
          "offset": 25
        },
        "opposite": true,
        "labels": {
          "color": "#555555",
          "fontSize": "15px",
          "enabled": true,
          "step": 1
        }
      });
    }
    const conf = {
      chart: {
        type: "line",
        marginRight: 10,
        backgroundColor: graphColor,
        spacingLeft: 0,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        plotBorderColor: '#C0C0C0',
        plotBorderWidth: 1
      },
      tooltip: {
        crosshairs: {
          color: 'red',
          dashStyle: 'solid',
        },
        enabled:true,
        formatter: function () {
          return 'Week: <b>' + this.x + '<br/>' + '</b>Percentile: <b>' + this.series.name + '<br/>' + '</b>Value: <b>' + this.y + '</b>';
        }
        ,
        positioner: function () {
          return { x: 80, y: 60 };
        },
        shadow: false,
        borderWidth: 0,
        backgroundColor: graphColor
      },
      credits: {
        enabled: false
      },
      title: {
        text: ''
      },
      xAxis: tmpXAxis || [],
      plotOptions: {
        series: {
          lineWidth: 1,
          states:{hover: {enabled:true}} //[AL-GR]
        },
        line: {
          marker: {
            enabled: false
          }
        }
      },
      yAxis: {
        min: 0,
        gridLineWidth: 1,
        minorTickInterval: 'auto',
        gridLineColor: '#C5EEFA',
        title: {
          text: "Height (cms.)",
          style: {
            fontSize: '15px',
            fontWeight: 'bold'
          }
        },
        labels: {
          "color": "#555555",
          "fontSize": "15px",
          "enabled": true,
          "step": 1,
          formatter: function () {
            return this.value;
          }
        }
      },
      legend: {
        enabled: true,
        margin: 0,
        itemMarginTop: 6,
        itemMarginBottom: 8,
        itemStyle: {
          fontSize: '12px'
        }
      },
      "navigation": {
        "buttonOptions": {
          "enabled": false
        }
      },
      series: this.graphSeries
    }
    return conf;
  }

  addPoint() {
    if (this.chart) {
      this.chart.addPoint(Math.floor(Math.random() * 10));
    } else {
      alert('init chart, first!');
    }
  }


  init() {
    setTimeout(() => {
      let chart = new Chart(this.getConf());
      // chart.addPoint(4);
      // chart.addPoint(5);  
      this.chart = chart;
    }, 600);

  }


}
