<div
    style=" display: flex; flex-direction: row; height: 70px; width: 98%; align-items: center; justify-content: space-between;padding: 10px 0px 0px 0px;border-bottom: 1px solid #eee;">

    <div style="display: flex; flex-direction: row; margin-left: 36px;">
        <!-- <img  (click)="toggleSideNav()"  style="margin: 3px 8px 0px 30px;cursor: pointer;"
        src="assets/images/menuIcon.svg" /> -->
        <div>   
        <figure *ngIf="gender=='F'" >
            <img height="50px" width="50px" style="text-align: center;
            margin-top: 13px;"  *ngIf="profilePic" src="{{profilePic}}" alt="" />

            <img height="50px" width="50px" *ngIf="!profilePic" style="text-align: center;
  margin-top: 13px;
  ;" src="assets/images/appFemalIcon.svg"
  alt="" />


</figure>
<figure *ngIf="gender=='M'" >
    <img height="50px" width="50px" style="text-align: center;
    margin-top: 13px;"  *ngIf="profilePic" src="{{profilePic}}" alt="" />
    <img height="50px" width="50px" *ngIf="!profilePic" style="text-align: center;
margin-top: 13px;
;" src="assets/images/appMaleIcon.svg"
alt="" />


</figure>
</div> 
<div 
style="padding-left: 8px; font-size: 18px; font-weight: bold; color: #333; align-self: center; line-height: 1.3;">

{{firstName}}

{{lastName}} ({{gender}} / {{this.ageObj.years}}y {{this.ageObj.months}}m {{this.ageObj.days}}d) <br>


</div>
<div *ngIf="purposeofvisit" style="margin: 24px 0px 0px 36px;font-size: 18px;" >Purpose of Visit : <span style="font-weight: bold;">{{purposeofvisit}}</span></div>


        </div>
           <!-- nav buttons div  -->
           <!-- <div *ngIf="!(ageObj?.years > 6 || (ageObj?.years === 6 && ageObj?.months >= 0 && ageObj?.days >= 0))"
            style="margin-left: 6px; display: flex;flex-direction: row;  height: 50px;">
            <button class="btn btn-secondary nav-btns" (click)="openImmunizationRX()">

                <mat-icon [ngClass]="'blue-icon'">
                    vaccines
                </mat-icon>

                <span style="margin-left: 4px;">Immunization</span>
            </button>
            <button class="btn btn-secondary nav-btns">

                <mat-icon [ngClass]="'blue-icon'">
                    vaccines
                </mat-icon>

                <span style="margin-left: 4px;">Growth</span>
            </button>
            <button class="btn btn-secondary nav-btns" (click)="openMilestoneRX()">

                <mat-icon [ngClass]="'blue-icon'">
                    vaccines
                </mat-icon>

                <span style="margin-left: 4px;">Milestone</span>
            </button>
            <button class="btn btn-secondary nav-btns">

                <mat-icon [ngClass]="'blue-icon'">
                    vaccines
                </mat-icon>

                <span style="margin-left: 4px;">Special Vaccines</span>
            </button>
        </div> -->
   
        <div style="display: flex;flex-direction: row;">
            <div style="cursor: pointer;" class="linktop">
             
                <img  style="height: 24px; width: 24px; fill: #000; align-self: center;"
                    src="assets/images/new_prescription_topbar.svg" alt="" />
                <div style="font-size: 16px; color: #807E7E;"
                    [ngStyle]="{'color': (selectedIndex == 1) ? '#4687BF' : '#807E7E'}">Quick Rx
                </div>
                <div style="height: 6px; width: 100%; background-color: #4687BF; border-top-right-radius: 6px; border-top-left-radius: 6px;  visibility: hidden;"
                    [ngStyle]="{'visibility': (selectedIndex == 1) ? 'visible' : 'hidden'}">
                </div>
            </div>
            <div (click)="onClickOverview();" style="cursor: pointer;" class="linktop">
             
                <img  style="height: 24px; width: 24px; fill: #000; align-self: center;"
                    src="assets/images/new_overview_topbar_off.svg" alt="" />
                <div style="font-size: 16px; color: #807E7E;"
                    [ngStyle]="{'color': (selectedIndex == 0) ? '#4687BF' : '#807E7E'}">Overview
                </div>
                <div style="height: 6px; width: 100%; background-color: #4687BF; border-top-right-radius: 6px; border-top-left-radius: 6px;  visibility: hidden;"
                    [ngStyle]="{'visibility': (selectedIndex == 0) ? 'visible' : 'hidden'}">
                </div>
            </div>
           
        </div>
    </div>


 <app-patient-list
 [sidenavTemplateRef]="navContent"
 [direction]="'left'" 
 [navWidth]="370" 
 [duration]="0.5"></app-patient-list> 
 <ng-template #navContent>
    <div style="font-size: 20px;color: #000000">Todays Patients List</div>
    <input  #searchFavourites placeholder="Search Patient" type="text" aria-label="Number" matInput
    [(ngModel)]="searchPatient" class="searchTab" [formControl]="myControlFav" >
    <div *ngFor="let item of appointmentCollection | filter:searchPatient; let i=index" (click)="onClickPatient(item)" style="display: flex;flex-direction: row; width: 100%; cursor: pointer;" >
      <div style="display: flex;flex-direction: row; width: 86%;">
      <div *ngIf="item.comepleteData.Patient.User.gender=='female'" style="margin: 7px 0px 12px 0px;">
        <img 
        src="assets/images/appFemalIcon.svg" class="largerCheckbox"  /></div>
        <div *ngIf="item.comepleteData.Patient.User.gender=='male'" style="margin: 7px 0px 12px 0px;">
          <img 
          src="assets/images/appMaleIcon.svg" class="largerCheckbox"  /></div>
             <div style="display: flex;flex-direction: column;">
            <div style="color: #000000; font-size: 18px;padding: 10px 0px 0px 9px;font-weight: 600;">{{item.fullName}}
  
           </div>
           <div style="color: #7C7C7C;font-size: 16px;margin: -4px 0px 3px 10px;">{{item.mobile}}</div>
          </div>
          </div>
          <div style="display: flex;flex-direction: column; width: 22%;">
            <div *ngIf="item.arrived && !item.in &&!item.done">
            <div  class="cradUid">Arrived</div>
             <div  style="color: #1C59FF;font-size: 18px;"> {{item.arrived | date:"hh:mm a"}} </div>
            </div>
            <div *ngIf="item.in && !item.done">
              <div  class="cradUid">Attending</div>
               <div  style="color: #1C59FF;font-size: 18px;"> {{item.in | date:"hh:mm a"}} </div>
              </div>
            <!-- <div *ngIf="item.done ">
              <div  class="doneUi">Done</div>
               <div  style="color: #1C59FF;font-size: 18px;"> {{item.done | date:"hh:mm a"}} </div>
              </div> -->
          </div>
     
    </div>
    
  
    </ng-template>