import { Injectable } from "@angular/core";
import * as _ from "lodash";

const a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen ']
const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']

const regex = /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/

const getLT20 = (n) => a[Number(n)]
const getGT20 = (n) => b[n[0]] + ' ' + a[n[1]]

@Injectable()
export class UtilService {
  parseDate(dateObject: any) {
    let date = new Date();
    date.setFullYear(dateObject.year);
    date.setMonth(dateObject.month - 1);
    date.setDate(dateObject.day);
    // 0

    return date;
  }

  reverseDate(date) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }

  calculateGestationalAge(edd, today) {
    let gestationalAge = {};

    edd = new Date(edd);
    edd.setUTCHours(0, 0, 0, 0);

    today = new Date(today);
    today.setUTCHours(0, 0, 0, 0);

    gestationalAge["weeks"] = 0;
    gestationalAge["days"] = 0;
    /*dob = new Date(dob)
    edd = new Date(edd)
    let timeDiff = Math.abs(Number(new Date(edd).getTime()) - Number(new Date(dob).getTime()))
    let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
    let gestationalAgeInDays = 284 - diffDays
    gestationalAge['weeks'] = Math.trunc(gestationalAgeInDays / 7)
    gestationalAge['days'] = gestationalAgeInDays % 7
    return gestationalAge*/

    let timeDiff = Number(today.getTime()) - Number(edd.getTime());
    let diffDays = Math.round(timeDiff / (1000 * 60 * 60 * 24));
    //let diffDays = Math.trunc(timeDiff / (1000 * 3600 * 24));

    let gestationalAgeInDays = 284 + diffDays;

    gestationalAge["weeks"] = Math.floor(gestationalAgeInDays / 7);
    gestationalAge["days"] = gestationalAgeInDays % 7;
    return gestationalAge;
  }

  calculateAge(birthDate) {
    let age = {};
    var startDate = new Date(new Date().toISOString().substr(0, 10));
    if (!birthDate) {
      birthDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
    }
    var endDate = new Date(birthDate);
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }

    age["years"] = yearDiff;
    age["months"] = monthDiff;
    age["weeks"] = Math.floor(dayDiff / 7);
    age["days"] = dayDiff;
    return age;
  }

  calculateAgeForVisit(birthDate, visitDate) {
    let age = {};
    var startDate = new Date(visitDate);
    if (!birthDate) {
      birthDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
    }
    var endDate = new Date(birthDate);
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }

    age["years"] = yearDiff;
    age["months"] = monthDiff;
    age["weeks"] = Math.floor(dayDiff / 7);
    age["days"] = dayDiff;
    return age;
  }


  numberToWord(input) {
    const num = Number(input)
    if (isNaN(num)) return ''
    if (num === 0) return 'zero'

    const numStr = num.toString()
    if (numStr.length > 9) {
      throw new Error('overflow') // Does not support converting more than 9 digits yet
    }

    const [, n1, n2, n3, n4, n5]: any = ('000000000' + numStr).substr(-9).match(regex) // left pad zeros

    let str: any = ''
    str += n1 != 0 ? (getLT20(n1) || getGT20(n1)) + 'crore ' : ''
    str += n2 != 0 ? (getLT20(n2) || getGT20(n2)) + 'lakh ' : ''
    str += n3 != 0 ? (getLT20(n3) || getGT20(n3)) + 'thousand ' : ''
    str += n4 != 0 ? getLT20(n4) + 'hundred ' : ''
    str += n5 != 0 && str != '' ? 'and ' : ''
    str += n5 != 0 ? (getLT20(n5) || getGT20(n5)) : ''

    return str.trim()
  }

  concealText(value, type) {
    //type : 1 is for name eg.) A**** M****
    // type : 2 is for mobile eg.) ******5889
    let data = '';
    let condition = false;
    if (value && value.length > 1) {
      for (var i = 0; i < value.length; i++) {
        condition = type == 2 ? i > value.length - 5 : i == 0;
        if (condition) {
          data = data + value.charAt(i);
        } else {
          data = data + '*';
        }
      }
    }
    return data;
  }

}
