import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { LabmoduleService } from '../../services/labmodule.service';

@Component({
  selector: 'app-lab-infobar',
  templateUrl: './lab-infobar.component.html',
  styleUrls: ['./lab-infobar.component.css']
})
export class LabInfobarComponent implements OnInit {
  userId
  labDetails:any={}
  labName:string=""
  labAddress:any=""
  constructor(private LabService: LabmoduleService, private authService: AuthService,) { }

  ngOnInit(): void {
    this.userId = this.authService.getUser()
    this.getLabDetailsbyDocId()
  }

  getLabDetailsbyDocId() {
    let labdetals = JSON.parse(localStorage.getItem("LAB_DETAILS"));
    this.labName=labdetals.name
    this.labAddress=labdetals.address
    
  }
}
