import { Component, OnInit, Inject, ViewChild, ElementRef, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import * as $ from 'jquery'
import { UserService } from '../../services/user.service'
import { AuthService } from '../../services/auth.service'
import { openClose } from '../../animations';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { debounce } from 'lodash';
import * as moment from 'moment';
// import { MatCarousel, MatCarouselComponent } from '@ngmodule/material-carousel';
import { StorageService } from '../../services/storage.service'
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ConfirmationService, SharedModule } from 'primeng/api';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { MatSelect } from '@angular/material/select';
import { FormsModule } from '@angular/forms'
import { LabmoduleService } from '../../services/labmodule.service';
import { DeviceDetectorService } from 'ngx-device-detector';

// import {img} from '../../../assets'
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss'],
  providers: [NgbCarouselConfig]
})

export class SigninComponent implements OnInit {
  @ViewChild("activationError") activationError: ElementRef;
  images = [1055, 194, 368].map((n) => `https://picsum.photos/id/${n}/900/500`);
  loginSec = true;
  testArray = ["-", "-", "-", "-"]
  passwordSec = false;
  OTPsec = false;
  emailId: any = null
  phoneNumber: any = null
  password: any = null
  user: any = null
  otpArray: any = new Array(6)
  otp = null
  lastTimeSent: any = moment();
  timeDiff: any
  optFirst: any = ""
  time: any = 45;
  reSendTime : any =45;
  reSendTimeFlag = 0;
  OTPnotvalid : any = false;
  timeloder: any
  display;
  interval;
  loginBtnLoading = 0;
  sentOtpBtn =1;
  verifyOtp = 1;
  verifyBtnLoading =0;
  loginValidateEmpty;
  loginValidateBlank;
  loginValidateLength;
  userExist;
  optSecond: any = "";
  optThird: any = ""
  optFourth: any = ""
  optFifth: any = ""
  optSixth: any = ""
  otpTime: any
  myIndex: any = 0;
  hideResend:any = 0;
  deviceInfo:any;
  sendOtp:any=""
  slides = [
    { 'image': 'assets/images/carousel_1.svg', heading: 'Easy to Use', description: 'Complete OPD management made easy to use on web & mobile' },
    { 'image': 'assets/images/carousel_2.svg', heading: 'Appointment', description: 'Track appoinments, manage queue and cater patients with few clicks' },
    { 'image': 'assets/images/carousel_3.svg', heading: 'Documents', description: 'Swiftly generate Prescriptions, Requisitions, Receipts & much more ' },
    { 'image': 'assets/images/carousel_4.svg', heading: 'Keeping tracks', description: 'A deep and complete analysis report of your clinic' }

  ];
  mobile:boolean=false;
  notOnboarded = true
  emailPattern: any = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))+$/
  showLoading = false
  constructor(private route: ActivatedRoute,config: NgbCarouselConfig, private LabService:LabmoduleService,  private modalService: NgbModal, private storageService: StorageService, private confirmationService: ConfirmationService, private authService: AuthService, private userService: UserService, private router: Router, private toastr: ToastrService, private deviceDetector: DeviceDetectorService) {
    localStorage.removeItem('lock')

    if (this.authService.isLoggedIn()) {
      var labdoc = localStorage.getItem("LAB_DOCTOR");
      var linkageDoc=localStorage.getItem("LinkedDoctor_DETAILS")
    
      if(labdoc){
        var labdocobj =JSON.parse(labdoc);
       
        if(labdocobj.role == "l"){
          this.router.navigate(['/lab/labHomePage']);
        }
       
      } else {
        this.router.navigate(['/doctor/homepage'])
      }

      if(linkageDoc){
        var linkdocType=JSON.parse(linkageDoc)
        if(linkdocType.doctor.role=="m"|| linkdocType.doctor.role=="s" || linkdocType.doctor.role=="z"){
          this.router.navigate(['/monitoring/MonitoringDataHomePage']);
        }
         else {
          this.router.navigate(['/doctor/homepage'])
        }
      }
    }
    //  config.interval = 10000;

  }

  ngOnInit() {
    this.route.queryParams
    .subscribe(params => {
      console.log("log2", params);
      this.sendOtp=params.otp
      console.log("parm",this.sendOtp)
      this.phoneNumber=Number(params.mobileNumber) ? params.mobileNumber:null
    })

    this.deviceInfo = this.deviceDetector.getDeviceInfo();
    if(this.deviceInfo.deviceType=='mobile'){
      this.mobile=true;
    }
   
    console.log('Device Detector: ', this.deviceInfo)
    localStorage.removeItem('lock')
    
  }
  timeout = setInterval(() => {
    
   // this.time=0;
    if (this.time > 0) {
      this.time -= 1;
    } else {
      clearInterval(0);
    }
  }, 1000);
  
  getVersion() {
    this.authService.getVersion().subscribe(response => {
      console.log(response, localStorage.getItem("version"));
      let currentwebapp = "";
      if (localStorage.getItem("version")) {
        currentwebapp = localStorage.getItem("version");
      }
      if (response.webapp != currentwebapp) {
        console.log("Need to be refreshed");
        localStorage.setItem("version", response.webapp);
        window.location.reload();
      }
    });
  }

  ngAfterViewInit() {
    document.getElementById('phoneNumberId').focus();
  }
  //  carousel() {
  //   var i:any;
  //   var im: HTMLImageElement = document.getElementById("myId") as HTMLImageElement;
  //  let handle: HTMLElement = document.getElementsByClassName('handle')[0] as HTMLElement;
  //   var x:any = document.getElementsByClassName("mySlides");
  //   for (i = 0; i < x.length; i++) {
  //     x[i].im.style.display="none";  
  //   }
  //   this.myIndex++;
  //   if (this.myIndex > x.length) {
  //     this.myIndex = 1
  //   }    
  //   x[this.myIndex-1].im.style.display="block";  
  //   setTimeout(this.carousel, 2000); // Change image every 2 seconds
  // }
  opdSignIn() {
    this.loginSec = true;
    this.passwordSec = false;
    this.OTPsec = false;
  }
  backLoginPage() {
    this.loginSec = true;
    this.reSendTimeFlag=1
    this.OTPsec = false;
    this.optFirst=""
    this.optSecond=""
    this.optThird=""
    this.optFourth=""
    this.optFifth=""
    this.optSixth=""
  }
  tabChange(val) {

    let ele = document.querySelectorAll('input');
    if (ele[val - 1].value != '') {
      ele[val].focus()
    } else if (ele[val - 1].value == '') {
      ele[val - 2].focus()
    }

    this.otp = this.optFirst + this.optSecond + this.optThird + this.optFourth + this.optFifth + this.optSixth

    console.log('OTP', this.otp)


  }
  // startTimer() {
  //   this.interval = setInterval(() => {
  //     if (this.time === 0) {
  //       this.time++;
  //     } else {
  //       this.time++;
  //     }
  //     this.display=this.transform( this.time)
  //   }, 1000);
  // }
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return minutes + ':' + (value - minutes * 60);
  }
  labLogin(){
   //this.authService.setUserType('lab')
   this.router.navigate(['/lab/labHomePage'])
    //this.router.navigate(['/doctor/homepage'])

  }

  checkPhoneNumber() {
    if (this.phoneNumber == null || this.phoneNumber == "" || this.phoneNumber.length < 10) {
      this.toastr.error("Please enter a valid mobile number")
    } else {
     let params:any={}
      if(this.sendOtp=='no'){
         params = {
        phone_number: `91${this.phoneNumber}`,
        sendOtp:this.sendOtp
      }
    }
      else{
       params = {
        phone_number: `91${this.phoneNumber}`
      
       }
      }

     
      this.userService.checkPhoneNumberandSendOTP(params).subscribe(response => {
        if (response.success) {
          this.user = response.user
          //this.phoneNumber = response.user.phone_number;
          this.loginSec = false;
          this.passwordSec = false;
          this.otp = null
          this.OTPsec = true;
          this.userExist=0;
          if(this.reSendTimeFlag==1){
            this.resendOTP();
          }
          setTimeout(function () {
            document.getElementById('otp').focus();
          }, 200)
          setTimeout(function () {
            $('#resend-otp').removeClass('not-active')
          }, 45000);
        } else {
          this.toastr.error(response.error);
          this.userExist=1;
        }
      })
    }
  }

  checkPassword() {
    if (this.password == null || this.password == "") {
      this.toastr.error("Please enter correct password")
    } else {
      let params = {
        password: this.password,
        user_id: this.user.id,
        send_otp: true
      }
      this.userService.checkPassword(params).subscribe(response => {
        if (response.success) {

          //this.user = response
          this.loginSec = false;
          this.passwordSec = false;
          this.otp = null
          this.OTPsec = true;
          setTimeout(function () {
            document.getElementById('otp').focus();
          }, 200)
          setTimeout(function () {
            $('#resend-otp').removeClass('not-active')
          }, 45000);
        } else {
          this.password = null
          setTimeout(function () {
            document.getElementById('passwordInput').focus();
          }, 200)
          this.toastr.error(response.error)
        }
      })
    }
  }
  validateNumber(e) {
    // var data=this.optFirst+this.optSecond+this.optThird+this.optFourth+this.optFifth+this.optSixth
    // console.log("otp", data)



    return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.which == 13) || (e.keyCode == 9)

  }
  confirmOTP() {

    //loginAPI
    this.verifyOtp =0;
    this.verifyBtnLoading = 1;
    this.otp = this.otp ? this.otp : null
    if (!this.otp) {
      this.otp = null
      this.toastr.error("Please enter valid OTP or click on resend button to receive new OTP")
    }
    else if (this.otp && this.otp.length < 6) {
      this.otp = null
      this.toastr.error("Please enter valid OTP or click on resend button to receive new OTP")
    } else {
      let params = {
        user_id: this.user.id,
        otp_action: 'login',
        otp: this.otp,
        info:this.deviceInfo.userAgent
      }
      this.showLoading = true

      this.authService.login(params).subscribe(response => {
        if (response.success) {
          localStorage.setItem('loggedin', '1');
          this.authService.setToken(response.token);
          this.authService.setUserType('doctor');
          console.log("Apr5",response)
          if(response.doctor && response.doctor.role && response.doctor.role == 'l'){
            localStorage.setItem("LAB_DOCTOR", JSON.stringify(response.doctor));
            this.LabService.getLabByDoctorId({ doctorid: response.doctor.doctorid }).subscribe(response => {
              console.log("get Lab detaisl By Doctor1", response);
              localStorage.setItem("LAB_DETAILS",JSON.stringify (response.lab));
              this.router.navigate(['/lab/labHomePage']);
            })
           
            
          } 
          else if(response.doctor && response.doctor.role && response.doctor.role == 'm' || response.doctor.role == 's'  || response.doctor.role == 'z'){
            localStorage.setItem("LinkedDoctor_DETAILS",JSON.stringify (response));
            this.router.navigate(['/monitoring/MonitoringDataHomePage']);
              
          }
          else if(response.doctor && response.doctor.role && response.doctor.role == 'y'){
            this.verifyBtnLoading = 0;
            this.verifyOtp = 1;
            this.backLoginPage()
            this.authService.deleteToken(0);
          }
          else {
            this.router.navigate(['/doctor/homepage'])
          }
          
        } else {
          this.showLoading = false;

          this.toastr.error(response.error)
          this.OTPnotvalid = true;
          this.verifyBtnLoading = 0;
          this.verifyOtp =1;
        }
      })
    }
  }
  addLabelClass(e) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

  resendOTP() {
   this.time=45
  // this.reSendTimeFlag=0
 
    let currentTime = moment()
    //var diff = moment.duration(currentTime.diff(this.lastTimeSent)).as('minutes')
    this.reSendTime=45;
    this.reSendTimeFlag=1;
    this.timeout = setInterval(() => {
     
      if (this.reSendTime > 0) {
        this.reSendTime -= 1;
      } else {
        clearInterval(100);
      }
    }, 1000);
    let params = {
      user_id: this.user.id,
      otp_action: 'login',
      phone_number: this.user.phone_number
    }
    //if(diff > 2){
    this.userService.generateOTP(params).subscribe(response => {
      if (response.success) {
        // this.lastTimeSent = moment()
        this.toastr.success("OTP has been sent to your registered mobile number")
        $('#resend-otp').addClass('not-active')
        setTimeout(function () {
          $('#resend-otp').removeClass('not-active')
        }, 120000);
      } else {
        this.toastr.error(response.error)
      }
    })

  }

  /*onKeyUp = debounce((event, id, nextInputIndex) => {
    let keyCodeValue = event.keyCode;
    let input = event.target;
    let nexInput, newID;
    if(keyCodeValue === 8 ){
      nexInput = nextInputIndex - 1;
      newID = id + nexInput;

    }  else if(keyCodeValue === 9){
      nexInput = nextInputIndex;
      newID = id + nexInput;


    }else {
      nexInput = nextInputIndex + 1;
      newID = id + nexInput;
    }
    console.log(newID)
    if(nexInput <= 5 && nexInput >= -1){
      document.getElementById(newID).focus();
    }
  }, 100)*/

  onKeyUp(event, id, nextInputIndex) {
    let keyCodeValue = event.keyCode;
    let input = event.target;
    let nexInput, newID;

    if (keyCodeValue != 32 && (event.key >= 0 && event.key <= 9) || keyCodeValue == 8 || keyCodeValue == 13) {
      if (keyCodeValue === 8) {
        nexInput = nextInputIndex - 1;
        newID = id + nexInput;
      } else if (keyCodeValue === 9) {
        nexInput = nextInputIndex;
        newID = id + nexInput;
      } else {
        nexInput = nextInputIndex + 1;
        newID = id + nexInput;
      }
      if (nexInput <= 5 && nexInput >= 0) {
        document.getElementById(newID).focus();
      }
    } else {
      $('#tab' + nextInputIndex).val('');
      return false;
    }
  }

  showEmail() {
    this.loginSec = true;
    this.passwordSec = false;
    this.OTPsec = false;
    setTimeout(function () {
      document.getElementById('phoneNumberId').focus();
    }, 200)
  }

  open(content) {

    this.user = {}

    this.modalService.open(content).result.then((result) => {
      //this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  loginValidate(phoneNumber){
    this.otp=null;
    console.log("phone",phoneNumber);
    if (phoneNumber == null) {
      this.loginValidateEmpty=1;
      this.sentOtpBtn =1;
    }else if(phoneNumber == "" ){
      this.loginValidateBlank=1;
    }else if(phoneNumber.length < 10){
      this.loginValidateLength =1;
      this.sentOtpBtn =1;
    }else{
      this.loginBtnLoading = 1;
      this.sentOtpBtn = 0;
      setTimeout(() => {
        document.getElementById('firstOtp').focus()
      }, 500);
    }
    if(!this.OTPsec){
      this.loginBtnLoading = 0;
      this.sentOtpBtn =1;
    }
  }
}
