import { Component, Input, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientService } from '../../services/patient.service';
import * as moment from 'moment';
import { MatMenuTrigger } from '@angular/material/menu';
import { refreshService } from '../../services/refersh.service';
import { MatDialog } from '@angular/material/dialog';

let selectFields = [{ name: "Vitals", selected: true, value: "showVitals" }, { name: "OnExamination", selected: true, value: "showOnExamination" }, { name: "Complaints", selected: true, value: "showComplaints" }, { name: "Diagnosis", selected: true, value: "showDiagnosis" }, { name: "Prescription", selected: true, value: "showPrescription" }, { name: "General Intructions", selected: true, value: "showGeneralIntructions" }, { name: "Dietary Advice", selected: true, value: "showDiet" }, { name: "Next Followup", selected: true, value: "showNextFollowup" }];
@Component({
  selector: 'app-patient-previous-details',
  templateUrl: './patient-previous-details.component.html',
  styleUrls: ['./patient-previous-details.component.scss']
})
export class PatientPreviousDetailsComponent implements OnInit {
  @ViewChild(MatMenuTrigger) menuForPatientInfo: MatMenuTrigger
  medicalConditionArray: any = []
  selectFieldsArray: any = [{ name: "All Fields", value: "" }];
  @Input() styles
  @Input() public patientData: any;
  @Input() patientInfo: any = {};
  @Input() patientAllRequiredConstant: any = {};
  @Input() patientApponmentById: any = {};
  @Input() pdfurlObjectForPrintingInChild: any = {};


  patientId
  allergies: any = ""
  selectedVisit: any = {}

  recurringComplaints = ""
  medicalCondition: any = ""
  accidentalHistory: any = ""
  accidentalHistoryValue: any = ""
  allergiesValue: any = ""
  medicalConditionsValue: any = ""
  recurringComplaintsValue: any = ""
  previousMedsArr: any = []
  prescriptionsUrl: any = []
  chief_complaints: any = ""
  lastRxDate: any = null
  previousMed: any = []
  weight: any = ""
  height: any = ""
  bp: any = "";
  showLoading1: boolean = false
  temp: any = ""
  medicalConditions: string = ""
  complaint_diagnosis: any = ""
  isPediatric: boolean = false;
  lastVisit: any;
  constructor(public dialog: MatDialog, private refreshservice: refreshService, private route: ActivatedRoute, private patientService: PatientService) { }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.patientInfo && changes.patientInfo.currentValue) {
      // this.getPatientInfo()
      this.showLoading1 = true

      console.log(" patientInfo in onchanges patient previous details", this.patientInfo);

      // Check if the patientInfo input has changed and has a value
      this.allergies = this.patientInfo.allergies
      this.medicalConditions = this.patientInfo.affected_comment;
      this.accidentalHistory = this.patientInfo.accidentalHistory
      this.recurringComplaints = this.patientInfo.comment

      var visits = this.patientInfo.visits && this.patientInfo.visits.length ? this.patientInfo.visits : [];

      visits.map((element, index) => {


        if (new Date(element.date).getDate() == new Date().getDate() && new Date(element.date).getMonth() == new Date().getMonth() && new Date(element.date).getFullYear() == new Date().getFullYear()) {
          console.log("VisitJan15", element)
          this.selectedVisit = element;
          console.log("this.selectedVisit", this.selectedVisit);
        }
      })
      console.log("88888888888888888888", this.selectedVisit);

      if (this.patientInfo.visits && this.patientInfo.visits.length > 0) {
        const visits = this.patientInfo.visits;
        const lastVisit = visits[visits.length - 1];
    
        // Check if the last visit is a synthetic record
        if (lastVisit._id === 'synthetic_record' && visits.length > 1) {
            this.lastVisit = visits[visits.length - 2];
        } else {
            this.lastVisit = lastVisit;
        }
    } else {
        // Handle case where there are no visits
        this.lastVisit = null;
    }
    
    console.log("lastVisit", this.lastVisit);
    
    if (this.lastVisit) {
        this.weight = this.lastVisit.weight ? this.lastVisit.weight : '';
        console.log("this.weight", this.weight);
    
        this.height = this.lastVisit.height ? this.lastVisit.height : '';
        this.temp = this.lastVisit.Temp ? this.lastVisit.Temp : '';
    
        const bpDia = Number(this.lastVisit.bpDia ? this.lastVisit.bpDia : 0);
        const bpSys = Number(this.lastVisit.bpSys ? this.lastVisit.bpSys : 0);
        this.bp = `${bpDia}/${bpSys}`;
    } else {
        // Provide default values when lastVisit is null
        this.weight = '';
        this.height = '';
        this.temp = '';
        this.bp = '0/0';
    }
      
      console.log('Received patientInfo in third-level child component:', this.patientInfo);
      this.showLoading1 = false;
      
      // You can perform further operations with this.patientInfo here
      

      // You can perform further operations with this.patientInfo here
    }


    if (changes.patientApponmentById && changes.patientApponmentById.currentValue) {
      console.log('Received patientApponmentById in third-level child component:', this.patientApponmentById);
      // this.showLoading1 = true

      if (this.patientApponmentById.historyArr?.length) {
        this.showLoading1 = false

        var medArr = [];
        var arr: any = [];
        this.prescriptionsUrl = [];
        for (let index = 0; index < this.patientApponmentById.historyArr.length; index++) {
          const element = this.patientApponmentById.historyArr[index];

          var obj: any = {}
          obj.createdAt = element.from;
          obj.from = element.from;
          obj.to = element.to;
          obj.id = element.id;
          obj.favApptName = element.favApptName;
          obj.docName = element.Doctor.User?.first_name + " " + element.Doctor.User?.last_name;
          obj.Visit = element.Visit && element.Visit != null ? element.Visit : null;
          obj.Medicines = element.Visit && element.Visit != null ? element.Visit.Medicines && element.Visit.Medicines.length > 0 ? element.Visit.Medicines : null : null;
          obj.prescriptionPDFURL = element.prescriptionPDFURL && element.prescriptionPDFURL != null ? element.prescriptionPDFURL : null;
          obj.Certificates = element.Certificates && element.Certificates != null ? element.Certificates : null;
          if (element.Visit && element.Visit != null && element.Visit.Medicines && element.Visit.Medicines.length > 0) {
            var med: any = {};
            // if (new Date(element.from).getDate() == new Date().getDate() && new Date(element.from).getMonth() == new Date().getMonth() && new Date(element.from).getFullYear() == new Date().getFullYear()) {

            // }
            // else {

            med.medicine = element.Visit.Medicines;
            med.complaints = element.Visit.chief_complaints;
            med.diagnosis = element.Visit.complaint_diagnosis;
            if (element.prescriptionPDFURL != null) {
              med.pdfUrl = element.prescriptionPDFURL
              console.log(" med.pdfUrl", med.pdfUrl);

            }

            medArr.push(obj);
            // }
          }
          arr.push(obj);
          console.log("pdf", arr)

        }


        if (medArr && medArr.length > 0) {
          this.previousMedsArr = medArr.reverse();
          this.chief_complaints = this.previousMedsArr[0].Visit.chief_complaints;
          this.complaint_diagnosis = this.previousMedsArr[0].Visit.complaint_diagnosis;

          this.prescriptionsUrl.push(this.previousMedsArr[0].prescriptionPDFURL); // Push the latest URL to the array
          console.log("prescriptionsUrl", this.prescriptionsUrl[0]);
          // this.prescriptionsUrl= this.prescriptionsUrl[0]

          this.lastRxDate = this.previousMedsArr[0].Visit.createdAt;
          this.previousMed = this.previousMedsArr[0].Medicines;

          console.log("ooooooo", this.prescriptionsUrl);
        }

      }
      this.showLoading1 = false


    }




  }

  ngOnInit(): void {


    this.getAllRequiredConstants();
    this.route.queryParams
      .subscribe(params => {
        console.log("logSep27", params);
        this.patientId = params.patientId
        // this.getPatientInfo();
        // this.getAllDoctorPrescriptions()
      }
      )
    this.refreshservice.Patient_History.subscribe(valuesent => {
      console.log("GOT REFRESH EVENT Feb2111", valuesent);
      this.patientId = valuesent
      // this.getPatientInfo();
      // this.getAllDoctorPrescriptions()

      this.showLoading1  = false;



    });

  }
  closeDialog() {

    this.dialog.closeAll();
  }
  selectmedicalConditions(item) {
    if (this.medicalConditionsValue) {
      this.medicalConditionsValue = this.medicalConditionsValue + "," + " " + item.name
    }
    else {
      this.medicalConditionsValue = item.name
    }

  }
  // this function is responsible for getting medical conditions static data 
  getAllRequiredConstants = () => {
    let newArray = [];
    console.log("responseresponseresponse", this.patientAllRequiredConstant);

    if (this.patientAllRequiredConstant) {
      this.medicalConditionArray = this.patientAllRequiredConstant.data.medicalConditions.map(ins => { return { name: ins.name, selected: false } });
      console.log("medicalConditionArray", this.medicalConditionArray);
      this.showLoading1  = false;

    }
    // })


  }



  getPatientInfoOnSaveTheData = () => {
    console.log("called getPatientInfo");

    // this.showLoading1 = true
    this.patientService.getPatientInfo(this.patientId).subscribe(response => {
      if (response) {
        this.showLoading1 = false
        console.log("response Patients>>>>>1111111111111111111111111", response);
        this.allergies = response.allergies
        this.medicalConditions = response.affected_comment;
        console.log("this.medicalConditions", this.medicalConditions);

        this.accidentalHistory = response.accidentalHistory
        this.recurringComplaints = response.comment

        this.allergiesValue = response.allergies
        this.medicalConditionsValue = response.affected_comment
        this.accidentalHistoryValue = response.accidentalHistory
        this.recurringComplaintsValue = response.comment



        var additionalInfo = response.additionalInfo ? JSON.parse(response.additionalInfo) : {}
        console.log("adddFeb12", additionalInfo)

        var specialVaccination = additionalInfo ? additionalInfo.specialVaccination : []


      }
    })
  }



  openRxUrl(url: string): void {
    console.log("urrrrrrrrrrrl", url);

    window.open(url[0]);
  }
  openDialog(content, index) {


    this.dialog.open(content);
  }



  saveMoreInfo() {
    this.getPatientInfoOnSaveTheData();

    console.log("this.patientInfo._id", this.patientInfo._id);
    var postData: any = {};
    postData.allergies = this.allergiesValue;
    postData.affected_comment = this.medicalConditionsValue;
    postData.comment = this.recurringComplaintsValue;
    postData.accidentalHistory = this.accidentalHistoryValue;
    console.log("postData updatePatientMoreInfo", postData);

    // postData.familyHistory = this.familyMedicalConditions;
    this.patientService.updatePatientMoreInfo(postData, this.patientInfo._id).subscribe(response => {
      console.log("his.patientInfothisthis.patientInfo._id", this.patientInfo._id);
      console.log("Feb199999999", response)

      if (response) {
        this.getPatientInfoOnSaveTheData();
        console.log("Feb199999999", response)

      }



      this.closeDialog();
    }
    )
  }
}

