
<div class=" col-lg-12" >
  <div class="dashboard-content">
     <div class="user-management-data">
    
    <div class="row mt-2">
        
        <div class="col-xl-4 col-lg-4 d-flex  align-items-top pr-0">
          <div class="scheduleandAddNew-outer">
            <div class="scheduleandAddNew">
              <div class="appointment float-right searchIcon active">
                <div class="form-group searchField pull-left">
                  <input type="text" placeholder="Search by user, activity" class="form-control" #searchInput
                    [(ngModel)]="searchString" (keyup)="searchActivities()" />
                    <!--<a (click)="closeSearch()" *ngIf="searchString"><i class="fa fa-times-circle" aria-hidden="true"></i></a>-->
                </div>
                <div class="searchbtn">
                  <button (click)="searchActivities()"><img src="assets/images/searchIcon.svg" alt="" /></button>
                </div>
              </div>
            </div>
          </div>
        </div>
       
        <div class="col-xl-2 col-lg-2 d-flex  align-items-top pr-0">
            <div class="form-group selectDropDown bloodGroup onFocus ">
  
              <select [(ngModel)]="searchByType"  [ngClass]="{'ifnoLabel': !searchByType}" (ngModelChange)="searchActivities()">
                  <option disabled value="null">Filter by type</option>
                  <option value="all">All</option>
                  <option value="doctor">Doctor</option>
                  <option value="admin">Admin</option>
              </select>
            </div>
            <!--<div class="scheduleandAddNew-outer">
              <div class="scheduleandAddNew">
                <div class="appointment float-right searchIcon active">
                  <div class="form-group searchField pull-left">
                    <input type="text" placeholder="Search..." class="form-control" #searchInput
                      [(ngModel)]="searchString" (keyup)="searchLab()" />
                      <a (click)="closeSearch()" *ngIf="searchString"><i class="fa fa-times-circle" aria-hidden="true"></i></a>
                  </div>
                  <div class="searchbtn">
                    <button (click)="searchLab()"><img src="assets/images/searchIcon.svg" alt="" /></button>
                  </div>
                </div>
              </div>
            </div>-->
          </div>

        <div class="col-xl-4 col-lg-4 d-flex justify-content-end align-items-top">
          <div class="row d-flex justify-content-end">
              <div class="col-xl-1 col-lg-1 col-lg fs-18 d-flex justify-content-center">
                  <div class="todaysAppointments pl-2">From</div>
                </div>
            <div class="col-xl-5 col-lg-5 pl-0 d-flex justify-content-end pt-1">
              <div class="form-group animateLabel calendarIcon mb-0 pt-0 calender">
                <input [(ngModel)]="fromDate" [maxDate]="toDate" ngbDatepicker #dfrom="ngbDatepicker" (focus)="dfrom.open()"
                  type="text" (ngModelChange)="searchActivities()" class="form-control" placeholder="DD/MM/YYYY" name=""
                  (document:click)="closeCalender($event,dfrom)" readonly>
                <img (click)="dfrom.toggle()" class="calendarbg" src="assets/images/calendarIcon.svg" alt="" />
              </div>
            </div>
            <div class="col-xl-1 col-lg-1 col-lg fs-18 d-flex justify-content-center">
              <div class="todaysAppointments pl-2">To</div>
            </div>
            <div class="col-xl-5 col-lg-5 pl-0 d-flex justify-content-end pt-1">
              <div class="form-group animateLabel calendarIcon mb-0 pt-0 calender" >
                <input [(ngModel)]="toDate" ngbDatepicker #dto="ngbDatepicker" (focus)="dto.open()"
                  type="text" class="form-control" [minDate]="fromDate" (ngModelChange)="searchActivities()" placeholder="DD/MM/YYYY" name=""
                (document:click)="closeCalender($event,dto)" readonly>
                <img (click)="dto.toggle()" class="calendarbg" src="assets/images/calendarIcon.svg" alt="" />
              </div>


            </div>
            <!--<div class="col-xl-1 col-lg-1 pr-0 d-flex justify-content-end">
              <div class="">
                  <button type="button" class="btn-primary opd-primary-btn btn-sm" (click)="addLab()">search</button>
                <!--<button (click)="searchAppointment()" class="searchbtn1"><img class="" alt="" src="assets/images/searchIcon.svg"></button>
              </div>
            </div>-->
          </div>
        </div>

        <div  class="col-xl-2 col-lg-2 d-flex justify-content-end align-items-top">
            <button type="button" class="btn-primary opd-secondary-btn btn-sm" (click)="clearFilters()">clear filter</button>
        </div>
    </div>
     
      <table  id="dtVerticalScrollExample" class="table  table-fixed">
        <thead>
          <tr class="col-12 pl-0 pr-0">
            <th  class="col-2 d-flex justify-content-start align-items-center">Date
              <div  class="topDown">
                  <i title="Ascending order" class="menu-caret fa fa-caret-up" [ngClass]="{'active': order[0] && order[0].indexOf('createdAt') != -1 && order[0].indexOf('asc') != -1}" (click)="sort(null,'createdAt','asc')"></i>
                  <i title="Descending order" class="menu-caret fa fa-caret-down"  [ngClass]="{'active': order[0] && order[0].indexOf('createdAt') != -1 && order[0].indexOf('desc') != -1}" (click)="sort(null,'createdAt','desc')"></i>
              </div>
            </th>
            <th  class="col-2 d-flex justify-content-start align-items-center">
              User
              <div  class="topDown">
                  <i title="Ascending order" class="menu-caret fa fa-caret-up"  [ngClass]="{'active': order[0] && order[0].indexOf('full_name') != -1 && order[0].indexOf('asc') != -1}" (click)="sort('User','full_name','asc')"></i>
                  <i title="Descending order" class="menu-caret fa fa-caret-down"  [ngClass]="{'active': order[0] && order[0].indexOf('full_name') != -1 && order[0].indexOf('desc') != -1}" (click)="sort('User', 'full_name','desc')"></i>
              </div>
            </th>
            <th  class="col-1 d-flex justify-content-start align-items-center">
              Type
              
            </th>
            <th  class="col-7 d-flex justify-content-start align-items-center">Activity
               
            </th>
           
           
          
          </tr>
        </thead>        
        <tbody *ngIf="!isLoading"  (scroll)="loadActivities($event)">
          <tr *ngIf="activityList.length === 0" class="notFound"><td class="noRecords" colspan="3">No records found</td></tr>
          <tr class="col-12 pl-0 pr-0" *ngFor="let activity of activityList; let i = index">
            <td  class="col-2">{{activity.createdAt |  date: 'dd/MM/yyyy hh:mm a'}}</td>
            
            <td  class="col-2">{{activity.User.full_name}}</td>
            <td  class="col-1 capitalize">{{activity.user_type}}</td>
            <td  class="col-7">{{activity.activity}}</td>
            
          </tr>
        </tbody>
        
      </table>
      <div class="col-12 d-flex justify-content-end align-items-center pr-0 font12">Displaying {{totalRecords}} records</div>
  </div>

</div>
</div>
<p-confirmDialog appendTo="body"></p-confirmDialog>
