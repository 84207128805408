
<div style="background-color: #fff;
height: 100%;
width: 100%;
z-index: 100;" class="d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
    <label for="" class="loader"></label>
</div>


<div [ngClass]="hideTextBox ? 'mainDiv1' :'maindiv'" >



  <div  class="secondManinDiv">
    <div class="headingText">
    <div class="subHeadingText1">Hello {{linkedDocName}}  </div>
    <div *ngIf="roleOfDoctor=='m'" class="subHeadingText">  (Supervisor :{{supervisorName}})</div>
   
  
    <div class="logoutUi">
     
    <div *ngIf="mobileDevice" (click)="hideShowTextBox()" style=" font-size: 20px; cursor: pointer;
    color: #5D86CC;">Hide/Show </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;

  <button (click)="openDialogModal(logoutPage)" mat-icon-button class="example-icon" aria-label="Example icon-button with menu icon">
    <mat-icon [ngClass]="'blue-icon'">exit_to_app</mat-icon>
  </button>
</div>
</div>


    <div  [style.visibility]="hideTextBox? 'hidden': 'visible'"  class="alldropdown">
      <mat-form-field *ngIf="roleOfDoctor=='z' " appearance="fill" class="textBoxUi">
        <mat-label style="font-size: 18px;">Select Supervisor</mat-label>
        <mat-select #sm_dropdown style="font-size: 16px;" [value]="supervisorArray"  multiple (closed)="onCloseSupervisorMethod(sm_dropdown)">
          <div class="row no-gutters pb-1" style=" display: flex;
          justify-content: end;
          margin: 0px 11px 5px 0px;">
  
              <!-- <button type="button" class="btn btn-primary" (click)="getAllsupervisor()">Save</button> -->
  
            </div>
          <mat-option #allSelected (click)="toggleAllSupervisor()" [value]="0">All</mat-option>
          <mat-option style="font-size: 16px;" *ngFor="let supervisorName of supervisorList"
            [value]="supervisorName.doctor_id" (click)="selectSupervisorList(supervisorName.doctor_id)">
            {{supervisorName.fullName}}
          </mat-option>
        </mat-select>
      </mat-form-field>


      <mat-form-field *ngIf="roleOfDoctor=='s' || roleOfDoctor=='z'" appearance="fill" class="textBoxUi">
        <mat-label style="font-size: 18px;">Select Arogyamitra </mat-label>
        <mat-select #am_dropdown style="font-size: 16px;" [value]="value"
          multiple (closed)="onCloseMethod(am_dropdown)">
          <div class="row no-gutters pb-1" style=" display: flex;
          justify-content: end;
          margin: 0px 11px 5px 0px;">
  
              <!-- <button type="button" class="btn btn-primary" (click)="getAllAsha()">Save</button> -->
  
            </div>
          <mat-option #allSelected (click)="toggleAllSelection()" [value]="0">All</mat-option>
         
          <mat-option *ngFor="let arogyamitraName of arogyamitraList"
            [value]="arogyamitraName.linked_doc_id" (click)="valueChange(arogyamitraName.linked_doc_id)">
            {{arogyamitraName.secondparty}}
          </mat-option>
        
        </mat-select>
      </mat-form-field>
      <!-- <mat-form-field *ngIf="roleOfDoctor=='m'" appearance="fill" class="textBoxUi" >
        <mat-label style="font-size: 18px;">Supervisor Name</mat-label>
        <input matInput [(ngModel)]="supervisorName" [readonly]="true">
      </mat-form-field> -->
      <mat-form-field *ngIf="roleOfDoctor=='s' || roleOfDoctor=='m' || roleOfDoctor=='z'" appearance="fill" class="textBoxUi">
        <mat-label style="font-size: 18px;">Select Microcluster </mat-label>
        <mat-select #microcluster_dropdown style="font-size: 16px;" [value]="microclusterArray"
          (selectionChange)="onDropdownSelectMicrocluster($event.value)" (closed)="onCloseMicroclusterMethod(microcluster_dropdown)" multiple>
          <div class="row no-gutters pb-1" style=" display: flex;
          justify-content: end;
          margin: 0px 11px 5px 0px;">
  
              <!-- <button type="button" class="btn btn-primary" (click)="getAllmicrocluster()">Save</button> -->
  
            </div>
          <mat-option  *ngFor="let cityname of microclusterArray" [value]="cityname.microcluster">
        {{cityname.microcluster}} 
          </mat-option>
       

        </mat-select>
      </mat-form-field>
      <mat-form-field *ngIf="roleOfDoctor=='m' || roleOfDoctor=='z'|| roleOfDoctor=='s'" appearance="fill" class="textBoxUi">
        <mat-label style="font-size: 18px;">Arogya Sevak/Sevika </mat-label>
        <mat-select #asha_dropdown style="font-size: 16px;" [value]="ashaArray"
        (closed)="onCloseAshaMethod(asha_dropdown)"  multiple>
         <div class="row no-gutters pb-1" style=" display: flex;
         justify-content: end;
         margin: 0px 11px 5px 0px;">
        
 
             <!-- <button type="button" class="btn btn-primary" (click)="getAllAshaId()">Save</button> -->
 
           </div>
           <input placeholder="Search Asha Name" matInput type="text" (keyup)="search($event.target.value)" class="example-input">

          <mat-option #allAshaSelected (click)="allAshaSelection()" [value]="0">All</mat-option>

          <mat-option  *ngFor="let ashaName of ashaList" [value]="ashaName.linked_doc_id"
          (click)="ashaValueChange(ashaName.linked_doc_id,$event)">
            {{ashaName.secondparty}}
          </mat-option>
       

        </mat-select>
      </mat-form-field>


      <mat-form-field appearance="outline" class="textBoxUi">
        <mat-label style="font-size: 18px;">From  </mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="startDate"  (dateChange)="onDateChange($event)" >

        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>

      <mat-form-field appearance="outline" class="textBoxUi">
        <mat-label style="font-size: 18px;">To  </mat-label>
        <input matInput [matDatepicker]="picker1" [(ngModel)]="endDate"  [min]="startDate" (dateChange)="onEndDateChange($event)">

        <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
        <mat-datepicker #picker1></mat-datepicker>
      </mat-form-field>

      <button *ngIf="disableSearchbutton"  class="btnUiForCopyMed" (click)="getAllMonitoringRecordsCount()">Search</button>
      <button *ngIf="!disableSearchbutton"  class="btnUiForCopyMed" >Search</button>

    </div>

  </div>
</div>

<!-- <div (click)="hideCrad()" style="display: flex; flex-direction: row; justify-content: end; cursor: pointer; margin-right: 15px;">
  <mat-icon>arrow_forward</mat-icon>
</div> -->

<div style=" width: 100%; display: flex; flex-direction: column; justify-content: center;align-items: center;">
<div class="row cardUi">
  <div *ngFor="let item of allMonitoringCount; let i = index"
    style="display: flex; flex-direction: row; margin: 14px 3px 0px 0px;">
    <div (click)="openDialog(item)" class="smallcard" >
      <div  class="labelValueSize">
        {{item.value}}</div>
      <div class="labelTextSize">
        {{item.name}}</div>
    </div>
  </div>
</div>

<div class="row cardUi">
  <div *ngFor="let item of allVaccinesCount; let i = index"
    style="display: flex; flex-direction: row; margin: 14px 3px 0px 0px;">
    <div (click)="openDialog(item)" class="smallcard" >
      <div class="labelValueSize" >
        {{item.value}}</div>
      <div  class="labelTextSize">
        {{item.name}}</div>
    </div>
  </div>
</div>

<div  class="row cardUi">
  <div *ngFor="let item of allReportandRequsitionCount; let i = index"
    style="display: flex; flex-direction: row; margin: 14px 3px 0px 0px;">
    <div (click)="openDialog(item)" class="smallcard" >
      <div  class="labelValueSize">
        {{item.value}}</div>
      <div  class="labelTextSize">
        {{item.name}}</div>
    </div>
  </div>
</div>
</div>

<ng-template #medicalConditionsView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Medical Conditions</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name, mobile or medical condition" class="form-control"
        #searchInput [(ngModel)]="searchMedicalConditions" (keyup)="onSearchFilter($event, 'searchMedicalConditions')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allMedicalConditions.xlsx', 'allMedicalConditionData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>


  <div style="height: 90vh; overflow-y: scroll">

    <table mat-table [dataSource]="medicalConditions" id="allMedicalConditionData" style="width:100%; ">
      <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.fullName}}</td>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Phone Number </th>
        <td mat-cell *matCellDef="let element"> {{element.phoneNumber}}</td>
      </ng-container>

      <ng-container matColumnDef="medicalConditions">
        <th mat-header-cell *matHeaderCellDef> Medical Conditions </th>
        <td mat-cell *matCellDef="let element"> {{element.medicalConditions}} </td>
      </ng-container>
      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef> Onboarded By </th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsMc; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsMc;"></tr>
    </table>

  </div>

  <!-- <div style="display: flex; flex-direction: row; justify-content: end;">
    <button (click)="closeDialog();" mat-raised-button
      style="background-color: #eee; color: #444;; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;font-weight: bold;">Cancel</button>
    <button (click)="resetVaccination();" mat-raised-button
      style="background-color: #5D86CC; color: #fff; height: 40px;margin-left: 9px;margin-top: 3px;font-size: 16px;font-weight: bold;">Okay</button>
  </div> -->
</ng-template>

<ng-template #totalPatientsView let-modal>
  <div class="modal-header"  class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Total Patients</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name, or mobile" class="form-control"
        #searchInput [(ngModel)]="searchTotalPatients" (keyup)="onSearchFilter($event, 'searchTotalPatients')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('totalPatients.xlsx', 'totalPatientsData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="totalPatients" id="totalPatientsData" style="width:100%; ">

      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
      </ng-container>
      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef> Onboarded By </th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsTp; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsTp;"></tr>
    </table>

  </div>
</ng-template>

<ng-template #healthInstalledView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Total Health App Installed</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchHealthInstalled" (keyup)="onSearchFilter($event, 'searchHealthInstalled')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allHealthInstalled.xlsx', 'allHealthInstalledData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="healthInstalled" id="allHealthInstalledData" style="width:100%; ">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.patName}}  </td>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.phone_number}} </td>
      </ng-container>
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef> Customer Created </th>
        <td mat-cell *matCellDef="let element"> {{element.createdAt}} </td>
      </ng-container>
      <ng-container matColumnDef="deviceInfo">
        <th mat-header-cell *matHeaderCellDef>Device Info </th>
        <td mat-cell *matCellDef="let element"> {{element.deviceInfo}} </td>
      </ng-container>
   
    
      <ng-container matColumnDef="appversion">
        <th mat-header-cell *matHeaderCellDef> App Version </th>
        <td mat-cell *matCellDef="let element"> {{element.appversion}} </td>
      </ng-container>

      <ng-container matColumnDef="dateupdated">
        <th mat-header-cell *matHeaderCellDef>Health App cr date </th>
        <td mat-cell *matCellDef="let element"> {{element.dateupdated}} </td>
      </ng-container>

      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef> Onboarded By </th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumnsHi; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsHi;"></tr>
    </table>

  </div>
</ng-template>

<ng-template #healthNotInstalledView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Total Health NOT Installed</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchHealthNotInstalled" (keyup)="onSearchFilter($event, 'searchHealthNotInstalled')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allHealthNotInstalled.xlsx', 'allHealthNotInstalledData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="healthNotInstalled" id="allHealthNotInstalledData" style="width:100%; ">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
      </ng-container>
      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef> Onboarded By </th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumnsHni; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsHni;"></tr>
    </table>

  </div>
</ng-template>



<ng-template #totalTakenVaccineView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Total Taken Vaccine</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchTakenVaccine" (keyup)="onSearchFilter($event, 'searchTakenVaccine')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allTakenVaccine.xlsx', 'allTakenVaccineData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="totalTakenVaccine" id="allTakenVaccineData" style="width:100%; ">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
      </ng-container>
      <ng-container matColumnDef="takenDate">
        <th mat-header-cell *matHeaderCellDef> TakenDate</th>
        <td mat-cell *matCellDef="let element"> {{element.TakenDate}} </td>
      </ng-container>
      <ng-container matColumnDef="vaccineName">
        <th mat-header-cell *matHeaderCellDef> VaccineName</th>
        <td mat-cell *matCellDef="let element"> {{element.VaccineName}} </td>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef> DueDate</th>
        <td mat-cell *matCellDef="let element"> {{element.DueDate}} </td>
      </ng-container>
      <ng-container matColumnDef="childAge">
        <th mat-header-cell *matHeaderCellDef> ChildAge</th>
        <td mat-cell *matCellDef="let element"> {{element.ChildAge}} </td>
      </ng-container>

      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef> Onboarded By</th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsTv; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsTv;"></tr>
    </table>

  </div>
</ng-template>



<!-- 
<ng-template #totalPendingVaccineView let-modal>
  <div class="modal-header" style="border-bottom: 0px; display: flex; align-items: center;">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Total Pending Vaccine</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchPendingVaccine" (keyup)="onSearchFilter($event, 'searchPendingVaccine')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allPendingVaccine.xlsx', 'allPendingVaccineData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="totalPendingVaccine" id="allTakenVaccineData" style="width:100%; ">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.fullName}} </td>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
      </ng-container>
      <ng-container matColumnDef="takenDate">
        <th mat-header-cell *matHeaderCellDef> TakenDate</th>
        <td mat-cell *matCellDef="let element"> {{element.TakenDate}} </td>
      </ng-container>
      <ng-container matColumnDef="vaccineName">
        <th mat-header-cell *matHeaderCellDef> VaccineName</th>
        <td mat-cell *matCellDef="let element"> {{element.VaccineName}} </td>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef> DueDate</th>
        <td mat-cell *matCellDef="let element"> {{element.DueDate}} </td>
      </ng-container>
      <ng-container matColumnDef="childAge">
        <th mat-header-cell *matHeaderCellDef> ChildAge</th>
        <td mat-cell *matCellDef="let element"> {{element.ChildAge}} </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsPv; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsPv;"></tr>
    </table>

  </div>
</ng-template> -->

<!------ ---------Due Vaccine---------->


<ng-template #totalPendingVaccineForSevenDaysView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Total Due Vaccine(due for 7 days)</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchPendingVaccineForSevenDays" (keyup)="onSearchFilter($event, 'searchPendingVaccineForSevenDays')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allPendingVaccineForSevenDays.xlsx', 'allPendingVaccineForSevenDaysData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="totalPendingVaccineForSevenDays" id="allPendingVaccineForSevenDaysData" style="width:100%; ">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.ChildName}} </td>
      </ng-container>
      <ng-container matColumnDef="childAge">
        <th mat-header-cell *matHeaderCellDef> ChildAge</th>
        <td mat-cell *matCellDef="let element"> {{element.ChildAge}} </td>
      </ng-container>
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.PhoneNumber}} </td>
      </ng-container>
     
      <ng-container matColumnDef="vaccineName">
        <th mat-header-cell *matHeaderCellDef> VaccineName</th>
        <td mat-cell *matCellDef="let element"> {{element.VaccineName}} </td>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef> DueDate</th>
        <td mat-cell *matCellDef="let element"> {{element.SevenDaysDueDate}} </td>
      </ng-container>

      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef> Onboarded By</th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>
     
      <tr mat-header-row *matHeaderRowDef="displayedColumnsPvsd; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsPvsd;"></tr>
    </table>

  </div>
</ng-template>

<!-------- ForthcomingVaccines   -->
<ng-template #totalForthcomingVaccinesView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Total Forthcoming Vaccines</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchForthcomingVaccines" (keyup)="onSearchFilter($event, 'searchForthcomingVaccines')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allForthcomingVaccines.xlsx', 'allForthcomingVaccinesData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="totalForthcomingVaccines" id="allForthcomingVaccinesData" style="width:100%; ">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.ChildName}} </td>
      </ng-container>
      <ng-container matColumnDef="childAge">
        <th mat-header-cell *matHeaderCellDef> ChildAge</th>
        <td mat-cell *matCellDef="let element"> {{element.ChildAge}} </td>
      </ng-container>
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.PhoneNumber}} </td>
      </ng-container>
     
      <ng-container matColumnDef="vaccineName">
        <th mat-header-cell *matHeaderCellDef> VaccineName</th>
        <td mat-cell *matCellDef="let element"> {{element.VaccineName}} </td>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef> DueDate</th>
        <td mat-cell *matCellDef="let element"> {{element.SevenDaysDueDate}} </td>
      </ng-container>

      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef> Onboarded By</th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>
     
      <tr mat-header-row *matHeaderRowDef="displayedColumnsFv; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsFv;"></tr>
    </table>

  </div>
</ng-template>
<!-------------PendingVaccines-->
<ng-template #totalPendingVaccinesView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%;">Total Pending Vaccines(Last 3 Months)</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchPendingVaccines" (keyup)="onSearchFilter($event, 'searchPendingVaccines')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allPendingVaccines.xlsx', 'allPendingVaccinesData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="totalPendingVaccines" id="allPendingVaccinesData" style="width:100%; height: 100vh; overflow: scroll;">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.ChildName}} </td>
      </ng-container>
      <ng-container matColumnDef="childAge">
        <th mat-header-cell *matHeaderCellDef> ChildAge</th>
        <td mat-cell *matCellDef="let element"> {{element.ChildAge}} </td>
      </ng-container>
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.PhoneNumber}} </td>
      </ng-container>
     
      <ng-container matColumnDef="vaccineName">
        <th mat-header-cell *matHeaderCellDef> VaccineName</th>
        <td mat-cell *matCellDef="let element"> {{element.VaccineName}} </td>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef> DueDate</th>
        <td mat-cell *matCellDef="let element"> {{element.SevenDaysDueDate}} </td>
      </ng-container>

      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef> Onboarded By</th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>
     
      <tr mat-header-row *matHeaderRowDef="displayedColumnsPvs; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsPvs;"></tr>
    </table>

  </div>
</ng-template>

<!-------- OverDueVaccines ------->

<ng-template #totalOverDueVaccinesView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%;">Total OverDue Vaccines</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchOverDueVaccines" (keyup)="onSearchFilter($event, 'searchOverDueVaccines')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allOverDueVaccines.xlsx', 'allOverDueVaccinesData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="totalOverDueVaccines" id="allOverDueVaccinesData" style="width:100%; height: 100vh; overflow: scroll;">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.ChildName}} </td>
      </ng-container>
      <ng-container matColumnDef="childAge">
        <th mat-header-cell *matHeaderCellDef> ChildAge</th>
        <td mat-cell *matCellDef="let element"> {{element.ChildAge}} </td>
      </ng-container>
      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.PhoneNumber}} </td>
      </ng-container>
     
      <ng-container matColumnDef="vaccineName">
        <th mat-header-cell *matHeaderCellDef> VaccineName</th>
        <td mat-cell *matCellDef="let element"> {{element.VaccineName}} </td>
      </ng-container>
      <ng-container matColumnDef="dueDate">
        <th mat-header-cell *matHeaderCellDef> DueDate</th>
        <td mat-cell *matCellDef="let element"> {{element.SevenDaysDueDate}} </td>
      </ng-container>
      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef> Onboarded By</th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>
     
      <tr mat-header-row *matHeaderRowDef="displayedColumnsOv; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsOv;"></tr>
    </table>

  </div>
</ng-template>


    <!--  LabRequisitions-->
<ng-template #totalLabRequisitionsView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Total Lab Requisitions</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchLabRequisitions" (keyup)="onSearchFilter($event, 'searchLabRequisitions')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allLabRequisitions.xlsx', 'allLabRequisitionsData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="totalLabRequisitions" id="allTakenVaccineData" style="width:100%; ">
      <ng-container matColumnDef="patientName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.patName}} </td>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.phone_number}} </td>
      </ng-container>
      <ng-container matColumnDef="requisitionCreated">
        <th mat-header-cell *matHeaderCellDef> Requisition Created</th>
        <td mat-cell *matCellDef="let element"> {{element.createdAt}} </td>
      </ng-container>
      <ng-container matColumnDef="tetsName">
        <th mat-header-cell *matHeaderCellDef> Tets Name</th>
        <td mat-cell *matCellDef="let element"> {{element.tests}} </td>
      </ng-container>
      <ng-container matColumnDef="sharedLabName">
        <th mat-header-cell *matHeaderCellDef> Shared Lab Name</th>
        <td mat-cell *matCellDef="let element"> {{element.sharedtolab}} </td>
      </ng-container>

      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef> Onboarded By</th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>
    
      <tr mat-header-row *matHeaderRowDef="displayedColumnsLr; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsLr;"></tr>
    </table>

  </div>
</ng-template>



<!--   totalAbhaCreated -->
<ng-template #totalAbhaCreatedView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Total Abha Created</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchAbhaCreated" (keyup)="onSearchFilter($event, 'searchAbhaCreated')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allAbhaCreated.xlsx', 'allAbhaCreatedData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="totalAbhaCreated" id="allAbhaCreatedData" style="width:100%; ">
      <ng-container matColumnDef="patientName">
        <th mat-header-cell *matHeaderCellDef> Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.patName}} </td>
      </ng-container>

      <ng-container matColumnDef="mobile">
        <th mat-header-cell *matHeaderCellDef> Mobile </th>
        <td mat-cell *matCellDef="let element"> {{element.phone_number}} </td>
      </ng-container>
      <ng-container matColumnDef="healthIdNumber">
        <th mat-header-cell *matHeaderCellDef> HealthId Number</th>
        <td mat-cell *matCellDef="let element"> {{element.healthIdNumber}} </td>
      </ng-container>
      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef> Onboarded By </th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>
    
    
      <tr mat-header-row *matHeaderRowDef="displayedColumnsAc; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsAc;"></tr>
    </table>

  </div>
</ng-template>


<!--- totalCustomers-->


<ng-template #totalCustomersView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Total Customers</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchCustomers" (keyup)="onSearchFilter($event, 'searchCustomers')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allCustomers.xlsx', 'allCustomersData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="totalCustomers" id="allCustomersData" style="width:100%; ">
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>Phone Number </th>
        <td mat-cell *matCellDef="let element"> {{element.phoneNumber}} </td>
      </ng-container>

      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef>Onboarded By</th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>
     
    
    
      <tr mat-header-row *matHeaderRowDef="displayedColumnsTc; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsTc;"></tr>
    </table>

  </div>
</ng-template>


<!-- -----  TotalLabReport ----->
<ng-template #totalLabReportsView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Total Lab Reports</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchLabReports" (keyup)="onSearchFilter($event, 'searchLabReports')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allLabReports.xlsx', 'allLabReportsData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="totalLabReports" id="allLabReportsData" style="width:100%; ">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.patName}} </td>
      </ng-container>
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>Phone Number </th>
        <td mat-cell *matCellDef="let element"> {{element.phone_number}} </td>
      </ng-container>

      <ng-container matColumnDef="uploadedBy">
        <th mat-header-cell *matHeaderCellDef>uploadedBy </th>
        <td mat-cell *matCellDef="let element"> {{element.uploadedBy}} </td>
      </ng-container>

      <ng-container matColumnDef="documentDate">
        <th mat-header-cell *matHeaderCellDef>documentDate </th>
        <td mat-cell *matCellDef="let element"> {{element.documentDate}} </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>createdAt </th>
        <td mat-cell *matCellDef="let element"> {{element.createdAt}} </td>
      </ng-container>
      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef>Onboarded By </th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>

      <ng-container matColumnDef="preview">
        <th mat-header-cell *matHeaderCellDef class="theadUi">View </th>
        <td mat-cell *matCellDef="let element" style="text-align: start !important;width: 10% !important">
    
            <div class="actionBtn" 
            (click)="onClickViewDocuments(element);"> View
        </div>  
           
        </td>
    </ng-container>

    
     
    
    
      <tr mat-header-row *matHeaderRowDef="displayedColumnsLR; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsLR;"></tr>
    </table>

  </div>
</ng-template>

<ng-template #totalPrescriptionView let-modal>
  <div class="modal-header" class="modalheader">
    <h4 class="modal-title" id="modal-basic-title" style="width:100%">Total Prescription</h4>

    <div style="width: 90%; height: 100%;">
      <input type="text" placeholder="Search by patient name or mobile" class="form-control"
        #searchInput [(ngModel)]="searchPrescription" (keyup)="onSearchFilter($event, 'searchPrescription')"
        style="height: 100% !important; font-size: 16px; border: 1px solid grey !important; background-color: #fff !important; border-radius: 8px !important;" />
    </div>

    <button (click)="DownloadExcel('allPrescription.xlsx', 'allPrescriptionData')" class="btn btn-secondary"
      style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

      <span style="margin-left: 4px;">Download Excel</span>
    </button>

    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div style="height: 700px; overflow-y: scroll">
    <table mat-table [dataSource]="totalLabReports" id="allPrescriptionData" style="width:100%; ">
      <ng-container matColumnDef="fullName">
        <th mat-header-cell *matHeaderCellDef>Patient Name </th>
        <td mat-cell *matCellDef="let element"> {{element.patName}} </td>
      </ng-container>
      <ng-container matColumnDef="phoneNumber">
        <th mat-header-cell *matHeaderCellDef>Phone Number </th>
        <td mat-cell *matCellDef="let element"> {{element.phone_number}} </td>
      </ng-container>

      <ng-container matColumnDef="uploadedBy">
        <th mat-header-cell *matHeaderCellDef>uploadedBy </th>
        <td mat-cell *matCellDef="let element"> {{element.uploadedBy}} </td>
      </ng-container>

      <ng-container matColumnDef="documentDate">
        <th mat-header-cell *matHeaderCellDef>documentDate </th>
        <td mat-cell *matCellDef="let element"> {{element.documentDate}} </td>
      </ng-container>

      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>createdAt </th>
        <td mat-cell *matCellDef="let element"> {{element.createdAt}} </td>
      </ng-container>
      <ng-container matColumnDef="docName">
        <th mat-header-cell *matHeaderCellDef>Onboarded By </th>
        <td mat-cell *matCellDef="let element"> {{element.docName}} </td>
      </ng-container>

      <ng-container matColumnDef="preview">
        <th mat-header-cell *matHeaderCellDef class="theadUi">View </th>
        <td mat-cell *matCellDef="let element" style="text-align: start !important;width: 10% !important">
    
            <div class="actionBtn" 
            (click)="onClickViewDocuments(element);"> View
        </div>  
           
        </td>
    </ng-container>

    
     
    
    
      <tr mat-header-row *matHeaderRowDef="displayedColumnsRx; sticky:true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsRx;"></tr>
    </table>

  </div>
</ng-template>


<ng-template #logoutPage>
  <h4>Logout</h4>
  <div style="color: #555; font-size: 16px;">Are you sure you want to Logout?</div>
  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
      <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 4px;
                color: #444;
                outline: none;">Cancel</button>

      <button (click)="logoutMonitoringRecord();closeDialog();" style="background-color: #5D86CC;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            border: 0px;
            margin: 4px;
          outline: none;">Logout</button>
  </div>
</ng-template>


<p-toast position="top-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class=" p-flex p-flex-column"
      style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;">
      <div>
        <img (click)="goBack()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
          src="assets/images/exclamation-circle-blue.svg" alt="" />
      </div>
      <div class=" p-text-center">
        <div style="font-size: 18px;
                  font-weight: bold;
                  padding: 12px 0px 0px 12px;
                  
                  color: #034A70;">{{message.summary}}</div>
        <div style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
          {{message.detail}}</div>
      </div>
    </div>
  </ng-template>
</p-toast>