<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading1">
    <label for="" class="loader"></label>
</div>
<div style="">

    <div style=" display: flex;flex-direction: column; margin-left: 8px;">

        <div [style]="styles"
            style="background-color: #F0F2F5;  display: flex;flex-direction: row; padding: 5px 5px 5px 8px;">
            <div style="color: #333; font-size: 18px; width: 100%;">Medical Conditions</div>
            <div (click)="openDialog(moreInfoModal,'moreInfoModal')"
                style="color: #2052D1; font-size: 18px; cursor: pointer;">Edit</div>
        </div>
        <div [style]="styles" style="background-color: #FFF;  padding: 0px 5px 0px 8px ">
            <div style="color: #333; font-size: 18px; ">Allergy</div>
            <div style="color: #FF1C1C; font-size: 16px;">{{allergies ? allergies:'NA'}}</div>
        </div>
        <div [style]="styles" style="background-color: #FFF;  padding:0px 5px 0px 8px ">
            <div style="color: #333; font-size: 18px; ">Medical Condition</div>
            <div style="color: #FF1C1C; font-size: 16px;">{{medicalConditions ? medicalConditions:'NA'}}</div>
        </div>
        <div [style]="styles" style="background-color: #FFF;  padding:0px 5px 0px 8px ">
            <div style="color: #333; font-size: 18px; ">Recurring Complaints</div>
            <div style="color: #FF1C1C; font-size: 16px;">{{recurringComplaints ? recurringComplaints:'NA'}}</div>
        </div>
        <div [style]="styles" style="background-color: #FFF;  padding:0px 5px 0px 8px ">
            <div style="color: #333; font-size: 18px; ">AccidentalHistory</div>
            <div style="color: #FF1C1C; font-size: 16px;">{{accidentalHistory ? accidentalHistory:'NA'}}</div>
        </div>

    </div>
</div>


<div
    style="  display: flex;flex-direction: column; justify-content: space-between;  margin: 0px 0px 0px 14px;background-color: #E2ECFB">

    <div style="display: flex;flex-direction: column;background-color: #E2ECFB ">

        <div *ngIf="lastRxDate" [style]="styles"
            style="background-color: #E2ECFB;  display: flex; justify-content: space-between; padding: 5px 5px 5px 8px;">
            <div style="color: #333; font-size: 18px; ">Previous Visit</div>
            <div style="color: #1C59FF; font-size: 16px; background-color: #E2ECFB;padding: 2px 6px 0px 6px;">
                {{lastRxDate| date: 'dd-MMM-YYYY'}}
            </div>

        </div>




    </div>


    <div style=" display: flex;flex-direction: column; ">



        <div style="display: flex;flex-direction: row; cursor: pointer; align-items: center;">
            <div *ngIf="prescriptionsUrl.length" style="margin: 0px 0px 7px 7px;cursor: pointer; ">
                <img src="../../../assets/images/file-pdf-solid.svg"
                    style="width:20px ;filter: invert(52%) sepia(34%) saturate(7471%) hue-rotate(220deg);"
                    alt="reysfhdrdrh">

            </div>

            <div *ngIf="prescriptionsUrl.length" (click)="openRxUrl(prescriptionsUrl)"
                style="cursor: pointer; margin-left: 8px; font-size: 16px; color: #1C59FF; text-decoration: underline; ">
                View last Prescription</div>
        </div>
        <!-- <div [style]="styles" style="background-color: #FFF;  padding: 0px 5px 8px 5px;">
                <div style="color: #333; font-size: 16px; ">Allergy</div>
                <div  style="color: #FF1C1C; font-size: 14px;">Dust</div>
               </div> -->


    </div>




</div>
<div style="  margin: 2px 0px 0px 14px;">

    <div style=" display: flex;flex-direction: column;">
        <div [style]="styles"
            style="background-color: #F0F2F5;  display: flex;flex-direction: row;justify-content: space-between; padding: 5px 5px 5px 8px;">
            <div style="color: #333; font-size: 18px; "> Complaints</div>
            <div style="color: #2052D1; font-size: 18px;"></div>
        </div>
        <div [style]="styles" style="background-color: #FFF; padding: 0px 5px 0px 8px">

            <div style="color: #1C59FF; font-size: 16px;">{{chief_complaints ? chief_complaints:'NA'}}</div>
        </div>


    </div>
</div>

<!-- 
<div
    style="  display: flex;flex-direction: row; justify-content: space-between;  margin: 7px 0px 0px 14px; width: 100%;"> -->

<div *ngIf="weight!='0' || height!='0' || bp!='0/0' || temp!='null'" style=" display: flex;flex-direction: column; ">
    <div [style]="styles"
        style="background-color: #F0F2F5;  display: flex;flex-direction: row;  padding: 5px 5px 5px 8px;  margin-left: 14px;">
        <div style="color: #333; font-size: 18px; ">Previous Vitals</div>
        <div style="color: #2052D1; font-size: 18px;"></div>
    </div>

</div>

<div *ngIf="weight!='0' || height!='0' || bp!='0/0' || temp!='null'"
    style="  display: flex;flex-direction: row; justify-content: space-between;  margin: 7px 0px 0px 14px;">


    <div style="margin-left: 8px;">
        <div *ngIf="weight!=''" style="color: #333333; font-size: 16px; ">Weight</div>
        <div *ngIf="height!=''" style="color: #333333; font-size: 16px; ">Height</div>

        <div *ngIf="bp!='0/0'" style="color: #333333; font-size: 16px; ">BP</div>
        <div *ngIf="temp!=''" style="color: #333333; font-size: 16px; ">Temp</div>




    </div>


    <div>
        <div *ngIf="weight!=''" style="color: #1C59FF; font-size: 14px;">{{weight}}</div>
        <div *ngIf="height!=''" style="color: #1C59FF; font-size: 14px;">{{height}}</div>



        <div *ngIf="bp!='0/0'" style="color: #FF1C1C; font-size: 14px;">{{bp}}</div>

        <div *ngIf="temp!=''" style="color: #1C59FF; font-size: 14px;">{{temp}}</div>
    </div>


</div>




<div style="margin: 7px 0px 0px 14px;">

    <div style=" display: flex;flex-direction: column;">
        <div [style]="styles"
            style="background-color: #F0F2F5;  display: flex;flex-direction: row;justify-content: space-between; padding: 5px 5px 5px 8px;">
            <div style="color: #333; font-size: 18px; "> Diagnosis</div>
            <div style="color: #2052D1; font-size: 18px;"></div>
        </div>
        <div [style]="styles" style="background-color: #FFF;  padding: 0px 5px 0px 8px">

            <div style="color: #7D7D7D; font-size: 16px;">{{complaint_diagnosis ? complaint_diagnosis:'NA'}}</div>
        </div>


    </div>
</div>


<div style="  flex-direction: row; justify-content: space-between;  margin: 7px 0px 0px 14px;">

    <div style=" display: flex;flex-direction: column;">
        <div [style]="styles"
            style="background-color: #F0F2F5;  display: flex;flex-direction: row;justify-content: space-between; padding: 5px 5px 5px 8px; width: 100%;">
            <div style="color: #333; font-size: 18px; ">Previous Visit Medicines</div>
            <div style="color: #2052D1; font-size: 18px;"></div>
        </div>
        <div>
            <div *ngFor="let item of previousMed" [style]="styles"
                style="background-color: #FFF;padding: 0px 5px 0px 8px;  ">

                <div style="color: #404040; font-size: 18px;">{{item.Drug.product_name}}
                </div>
                <div style="color: #7D7D7D; font-size: 16px;">{{item.dosage2}} {{item.duration2}}
                </div>

            </div>
        </div>


    </div>
</div>

<ng-template #moreInfoModal let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">More Information</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body" style="width:50vw;">

        <div style="display: flex;flex-direction: column; padding-top: 10px;">

            <mat-form-field appearance="outline">
                <mat-label>Medical Conditions</mat-label>
                <input matInput [(ngModel)]="medicalConditionsValue" placeholder="Enter Medical Conditions">

            </mat-form-field>
            <mat-chip-list aria-label="Fish selection" [multiple]="true" style="margin:10px 6px;">
                <mat-chip class="price-filter" style="font-size: 16px; background-color: #E8F7F5;" #ref="matChip"
                    *ngFor="let item of medicalConditionArray;let i=index" (click)='selectmedicalConditions(item);'
                    [value]="item">
                    {{item.name}}
                </mat-chip>

            </mat-chip-list>


            <mat-form-field appearance="outline">
                <mat-label>Allergies</mat-label>
                <input matInput [(ngModel)]="allergiesValue" placeholder="Enter Allergies">

            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Recurring Complaints</mat-label>
                <input matInput [(ngModel)]="recurringComplaintsValue" placeholder="Enter Recurring Complaints">

            </mat-form-field>

            <mat-form-field appearance="outline">
                <mat-label>Accidental / Procedural History</mat-label>
                <input matInput [(ngModel)]="accidentalHistoryValue"
                    placeholder="Enter Accidental / Procedural History">

            </mat-form-field>
        </div>

        <div style="display: flex;width: 100%;align-items: flex-end;justify-content: flex-end;">
            <button type="submit" class="new_btn"
                style="  display: flex;text-transform: capitalize;align-items: center;justify-content: center;"
                (click)="saveMoreInfo()">Save</button>
        </div>
    </div>


</ng-template>