import { Injectable } from '@angular/core'

@Injectable()
export class StorageService {

  constructor() {}

  setStage(stage: string) {
    localStorage.setItem('stage', stage);
  }

  setData(data: any) {
    localStorage.setItem('data', data);
  }

  getStage() {
    return localStorage.getItem('stage');
  }

  getData() {
    return JSON.parse(localStorage.getItem('data'));
  }

  removeStage() {
    return localStorage.removeItem('stage');
  }

  removeData() {
    return localStorage.removeItem('data');
  }

  getDoctor() {
    return JSON.parse(localStorage.getItem('doctor'));
  }

  removeDoctor() {
    return localStorage.removeItem('doctor');
  }

  setDoctor(data: any) {
    localStorage.setItem('doctor', data);
  }

}

