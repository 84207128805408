<div
    style="background-color: #fff; height: fit-content; width: 98%; display: flex; flex-direction: column; margin: 0px 16px;">


    <div
        style="display: flex; flex-direction:row; align-items: center; padding: 16px 0px;">

        <!-- <div style="font-size: 20px;
    color: #333;
    align-self: center;
    font-weight: bold;
    ">Management Reports</div> -->


        <div
            style="height: 80%; width: 36%; border-radius: 6px; border: 0.4px solid #005CAF; display: flex; flex-direction: row; ">
            
            <div style="width: 90%; height: 100%;">
                <input type="text" placeholder="Search by doctor name, sales person name, city or area"
                    class="form-control" #searchInput [(ngModel)]="searchReports" (keyup)="onSearchReports($event)"
                    style="height: 100% !important; font-size: 16px; border: 1px solid transparent !important; background-color: #fff !important; border-radius: 8px !important;" />
            </div>
            

            <div
                style="width: 12%; height: 100%; display: flex; justify-content: center; align-items: center; margin-top: 4px;">
                <button style="border: 0px; background-color: transparent; outline: none;"><img
                        src="assets/images/search_icon_blue.svg" alt="" /></button>
            </div>
        </div>

        <div style="display: flex; flex-direction:row; justify-content: center; margin-top: 6px; ">
            <mat-checkbox color="primary" #liveDoctor (click)="toggleLiveDoctor(!liveDoctor.checked)" style="padding: 0px 16px; font-size: 18px;">Show <span style="font-size: 18px; font-weight: bold;">ONLY *Up and  running*</span> users in the period</mat-checkbox>
        </div>


    </div>

    <div style="display: flex; flex-direction:'row'; align-items: center; ">

        <div style="display: flex; flex-direction:row; justify-content: center; ">
            <mat-radio-group aria-label="Select an option" [(ngModel)]="doctorType"
                style="display: flex; flex-direction:row; align-items: center;">
                <mat-radio-button style="padding: 0px 16px; font-size: 18px;" value="d" [checked]="true">Doctor
                </mat-radio-button>
                <mat-radio-button style="padding: 0px 16px;font-size: 18px" value="l">Lab</mat-radio-button>
                <mat-radio-button style="padding: 0px 16px;font-size: 18px" value="y">Asha</mat-radio-button>
            </mat-radio-group>
        </div>



        <mat-form-field appearance="outline">
            <mat-label style="font-size: 18px">From Date </mat-label>
            <input matInput [matDatepicker]="picker" [max]="today" [(ngModel)]="fromDateForAllReports">

            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
        </mat-form-field>

        <mat-form-field appearance="outline" style="margin-left: 8px">
            <mat-label style="font-size: 18px">To Date</mat-label>
            <input [(ngModel)]="toDateForForAllReports" [max]="today" matInput [matDatepicker]="picker1">

            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
        </mat-form-field>


     

        <div
            style="display: flex; flex-direction: row; width: 100%; justify-content: flex-end; height: 60px; margin-bottom: 12px;">
            <button (click)="applyChanges()" class="btn btn-secondary"
                style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
                <mat-icon [ngClass]="'blue-icon'">
                    search
                </mat-icon>
                <span style="margin-left: 4px;">Search Filter</span>
            </button>
        </div>
        <div
        style="display: flex; flex-direction: row;  justify-content: flex-end; height: 60px; margin-bottom: 12px;">
        <button (click)="DownloadExcel('allReports.xlsx')" class="btn btn-secondary"
            style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
           
            <span style="margin-left: 4px;">Download Excel</span>
        </button>
    </div>
    </div>

</div>

<div *ngIf="loading" style="display: flex; height:100%; width:100%; justify-content: center; align-items: center;">
    <mat-spinner [diameter]="50"></mat-spinner>
</div>

<div *ngIf="!loading">

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="allReportData">

        <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

        <!-- Sr No Column -->
        <ng-container matColumnDef="srno">
            <th mat-header-cell *matHeaderCellDef> Sr No. </th>
            <td mat-cell *matCellDef="let element"> {{element.srno}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> Sr No. 2 </td> -->
        </ng-container>

        <!-- Doctor Name Column -->
        <ng-container matColumnDef="doctorName">
            <th mat-header-cell *matHeaderCellDef> Doctor Name </th>
            <td mat-cell *matCellDef="let element"> {{element.doctorName}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> Doctor Name 2 </td> -->
        </ng-container>

        <!-- Total Count Column -->
        <ng-container matColumnDef="totalCount">
            <th mat-header-cell *matHeaderCellDef> Total Count </th>
            <td mat-cell *matCellDef="let element"> {{element.totalCount}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> Total Count 2 </td> -->
        </ng-container>

        <!-- Old Patient Column -->
        <ng-container matColumnDef="oldPatients">
            <th mat-header-cell *matHeaderCellDef> Old Patients </th>
            <td mat-cell *matCellDef="let element"> {{element.oldPatients}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> Old Patients 2 </td> -->
        </ng-container>

        <!-- Patient Enrolled Column -->
        <ng-container matColumnDef="patientsEnrolled">
            <th mat-header-cell *matHeaderCellDef> Patients Enrolled </th>
            <td mat-cell *matCellDef="let element"> {{element.patientEnrolled}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> Patients Enrolled 2 </td> -->
        </ng-container>

        <!-- Health App Column -->
        <ng-container matColumnDef="healthApps">
            <th mat-header-cell *matHeaderCellDef> Health App </th>
            <td mat-cell *matCellDef="let element"> {{element.healthApps}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> Health App 2 </td> -->
        </ng-container>

        <!-- Prescription Column -->
        <ng-container matColumnDef="prescriptions">
            <th mat-header-cell *matHeaderCellDef> Prescriptions</th>
            <td mat-cell *matCellDef="let element"> {{element.prescriptions}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> Prescriptions 2 </td> -->
        </ng-container>

        <!-- Vaccines Column -->
        <ng-container matColumnDef="vaccines">
            <th mat-header-cell *matHeaderCellDef> Vaccines </th>
            <td mat-cell *matCellDef="let element"> {{element.vaccines}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> Vaccines 2 </td> -->
        </ng-container>

        <!-- Lab Requisitions Column -->
        <ng-container matColumnDef="labRequisitions">
            <th mat-header-cell *matHeaderCellDef> Lab Requisitions </th>
            <td mat-cell *matCellDef="let element"> {{element.labRequisitions}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> Lab Requisitions 2 </td> -->
        </ng-container>

        <!-- Appointments Column -->
        <ng-container matColumnDef="appointments">
            <th mat-header-cell *matHeaderCellDef> Appointments </th>
            <td mat-cell *matCellDef="let element"> {{element.appointments}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> Appointments 2 </td> -->
        </ng-container>

        <!-- City Column -->
        <ng-container matColumnDef="city">
            <th mat-header-cell *matHeaderCellDef> City </th>
            <td mat-cell *matCellDef="let element"> {{element.city}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> City 2 </td> -->
        </ng-container>

        <!-- Area Column -->
        <ng-container matColumnDef="area">
            <th mat-header-cell *matHeaderCellDef> Area </th>
            <td mat-cell *matCellDef="let element"> {{element.area}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> Area 2 </td> -->
        </ng-container>

        <!-- Salesperson Column -->
        <ng-container matColumnDef="salesperson">
            <th mat-header-cell *matHeaderCellDef> Sales Person </th>
            <td mat-cell *matCellDef="let element"> {{element.salesperson}} </td>
            <!-- <td mat-footer-cell *matFooterCellDef> Sales Person 2 </td> -->
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        <!-- <tr mat-footer-row *matFooterRowDef="displayedColumnsFooter"></tr> -->
    </table>

</div>