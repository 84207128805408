import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../services/storage.service'
import { Router } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.scss']
})
export class ThankYouComponent implements OnInit {
  notOnboarded = true
  otherDocthankYou : Boolean = false;
  interestPage: Boolean = false
  doctor = null

  constructor(private storageService: StorageService, private router: Router) { 
    this.notOnboarded = true
    this.doctor = this.storageService.getDoctor()

    if(!this.doctor){
      this.router.navigate(['/'])
    }
  }

  ngOnInit() {
  }

}
