
<div style="display: flex;flex-direction: row; width: 100%; ">
    <div style="width: 20%; display: flex;flex-direction: row; font-weight: 600; margin:20px 20px 0px 22px;font-size: 20px;">Vaccine Required</div>

   <div style="display: flex;flex-direction: row; justify-content: end; margin:20px 0px 0px 15px; width: 100%;">
    <mat-form-field style="width: 26%; margin-right: 22px;">
        <mat-label style="font-size: 15px;">Time Period</mat-label>
        <mat-select style="font-size: 16px;" [value]="monthSelected"
          (selectionChange)="onDropdownSelectMonth($event.value)">
          <mat-option style="font-size: 16px;" *ngFor="let month of monthArray" [value]="month.name">
            {{month.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>



</div> 
<div style="margin:20px 20px 0px 0px" (click)="openPDF()">
    <button 
     class="btn btn-secondary"
    style="margin-top: 10px; display: flex; justify-content: center; align-items: center;">
    <!-- <mat-icon [ngClass]="'blue-icon'">
       view
    </mat-icon> -->
    <span style="margin-left: 4px;">View PDF</span>
    </button>
    </div>

</div>


<div  style=" width: 100%; ">
    <div style=" width: 100%; height: 100vh;overflow-y: scroll; ">
        <ng-container [ngTemplateOutlet]="renderImmunization" [ngTemplateOutletContext]="{value:futureReportList}">
        </ng-container>
    </div>
</div>


<ng-template  #renderImmunization let-value="value">
    <table mat-table [dataSource]="value" style="width: 97%;  overflow-y: scroll; margin: 8px 0px 0px 23px;"  id="allReportData">
        <ng-container>
            <!-- VISIT -->

            <ng-container matColumnDef="vaccName">
                <th mat-header-cell *matHeaderCellDef style="text-align:start !important;font-size: 18px; font-weight: 600;"> Vaccine </th>
                <td mat-cell *matCellDef="let element"
                    style="text-align: start !important; width: 10% !important; font-size: 16px;"
                    [ngClass]="(element.color=='red') ?  'lessQuantity' :'quantityList'"  >
                    {{element.name}}

                </td>
            </ng-container>
            <ng-container matColumnDef="totalVaccinesForDuration">
                <th mat-header-cell *matHeaderCellDef style="text-align:center !important ;font-size: 18px;font-weight: 600;"> Required </th>
                <td mat-cell *matCellDef="let element"
                    style="text-align: center !important; width:20%;font-size: 16px; "
                    [ngClass]="(element.color=='red') ?  'lessQuantity' :'quantityList'"   >
                {{element.totalVaccinesForDuration}}
                   
                </td>
            </ng-container>
            <ng-container matColumnDef="brandName">
                <th mat-header-cell *matHeaderCellDef style="width: 40%; text-align:start !important ;font-size: 18px;font-weight: 600;"> Brand Name (In Stock) </th>
                <td mat-cell *matCellDef="let element"
                    style="text-align: start !important; width:20%; margin: 2px 2px 2px 2px;"  [ngClass]="(element.color=='red') ?  'lessQuantity' :'quantityList'" >
                     <span style=" padding: 2px 2px 2px 2px;font-size: 16px;" *ngFor="let funct of element.brandsForVaccines">
                        {{funct.brandName}}  ({{funct.quantity}}) ,</span>
                   
                </td>
            </ng-container>
            <!-- <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef style="text-align:start !important ;font-size: 18px;font-weight: 600;"> In Stock </th>
                <td mat-cell *matCellDef="let element"
                    style="text-align: start !important; width:20%;">
                     <div style="margin: 2px 2px 2px 2px;font-size: 16px;" *ngFor="let funct of element.brandsForVaccines">{{funct.quantity}}</div>
                   
                </td>
            </ng-container> -->

        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumnsForVaccDue; sticky:true" style="background-color: #eee;"></tr>
        <tr mat-row [ngClass]="{'highlight': selectedRowIndex == row.id}"  *matRowDef="let row; columns: displayedColumnsForVaccDue;"  ></tr>
    </table>
</ng-template>



<p-confirmDialog appendTo="body"></p-confirmDialog>

