import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarModule } from 'ng-sidebar';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { adminRoutes } from './admin-panel.routing';
import { GlobalService } from '../services/global.service';
import { UserService } from '../services/user.service';
import { RouterModule } from '@angular/router';
import { DoctorListComponent } from './users/doctor/doctor-list/doctor-list.component';
import { DoctorViewComponent } from './users/doctor/doctor-view/doctor-view.component';
import { LabEditComponent } from './site-settings/labs/lab-edit/lab-edit.component';
import { LabListComponent } from './site-settings/labs/lab-list/lab-list.component';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmationService, SharedModule} from 'primeng/api';
import { PatientListComponent } from './users/patient/patient-list/patient-list.component';
import { CustomerListComponent } from './users/customer/customer-list/customer-list.component';
import { DoctorClinicsComponent } from './users/doctor/doctor-clinics/doctor-clinics.component';
import { DoctorLetterheadComponent } from './users/doctor/doctor-letterhead/doctor-letterhead.component';
import { DoctorAddComponent } from './users/doctor/doctor-add/doctor-add.component';
import { DoctorEditProfileComponent } from './users/doctor/doctor-edit-profile/doctor-edit-profile.component';
import { ActivityLogComponent } from './activity-log/activity-log.component';
import { ReportsComponent } from './reports/reports.component';
import { OtpsComponent } from './otps/otps.component';
import { DocRptDashComponent } from './doc-rpt-dash/doc-rpt-dash.component';
import { refreshService } from '../services/refersh.service';
import { DoctorProfileManagementComponent } from './doctor-profile-management/doctor-profile-management.component';
import { MatStepperModule, MatStepper, MatStep } from '@angular/material/stepper';
import { MatSelectModule, MatSelect, MatSelectTrigger } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatButton, MatButtonModule } from '@angular/material/button';
import {MatTableModule} from '@angular/material/table';

import { MatRadioModule, MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { ManagementReportsComponent } from './management-reports/management-reports.component';
import {MatIconModule} from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NgbModule, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgbDateCustomParserFormatter } from '../filters/dateformate';
import { MatNativeDateModule } from '@angular/material/core';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { MessageModule } from 'primeng/message';
import { MessagesModule } from 'primeng/messages';
import { ToastModule } from 'primeng/toast';
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [DoctorListComponent, DoctorViewComponent, LabEditComponent, LabListComponent, PatientListComponent, CustomerListComponent, DoctorClinicsComponent, DoctorLetterheadComponent, DoctorAddComponent, DoctorEditProfileComponent, ActivityLogComponent, ReportsComponent, OtpsComponent, DocRptDashComponent, DoctorProfileManagementComponent, ManagementReportsComponent,],
  imports: [
    MessageModule,
    MessagesModule,
    ToastModule,
    CommonModule,
    RouterModule.forChild(adminRoutes),
    FormsModule,
    ReactiveFormsModule,
    SidebarModule,
    NgbModule,
    AutoCompleteModule,
    ConfirmDialogModule,
    CalendarModule,
    MatStepperModule,
    MatSelectModule,
    MatInputModule,
    MatCheckboxModule,
    MatRadioModule,
    MatButtonModule,
    MatTableModule,
    MatIconModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
    MatProgressSpinnerModule,
    SharedModule,
    MatSnackBarModule

   
    
  ],
  exports:[DocRptDashComponent],
  providers: [
    GlobalService,
    UserService,
    refreshService,
    ConfirmationService,
    
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'primary' },
    },
    {
      provide: NgbDateParserFormatter,
      useClass: NgbDateCustomParserFormatter
    },
  ]
})
export class AdminPanelModule { }
