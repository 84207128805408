<!-- <div style="border: 1px #000000 solid;height: 35px;margin-left: 20px; margin-top: 20px; margin-right: 20px;">
    <a *ngIf="!show4Graphs"style="margin-left: 20px;align-items: center;justify-content: center;" (click)="toggle4Graphs()">
        <span style="font-size: 21px;color: #000000;font-weight: bold;">
            Open Pediatric Graphs
            <i style="color: #4687BF;margin-left: 10px;font-size: 25px;border: 0.5px #000000 solid; padding-left: 3px; padding-right: 3px; margin-top: 3px;"
                class="fa fa-angle-down fa-4"></i></span></a>

                <a *ngIf="show4Graphs"  style="margin-left: 20px;align-items: center;justify-content: center;" (click)="toggle4Graphs()">
                    <span style="font-size: 21px;color: #000000;font-weight: bold;">
                        Close Pediatric Graphs
                        <i style="color: #4687BF;margin-left: 10px;font-size: 25px; border: 0.5px #000000 solid; padding-left: 3px; padding-right: 3px; margin-top: 3px;"
                            class="fa fa-angle-up fa-4"></i></span></a>      
                
</div> -->

<!-- <div style=" display: flex; flex-direction:'row';">
    <div (click)="toggle4Graphs(1)" style="font-weight: bold;
    font-size: 16px;
    padding: 12px;
    cursor: pointer;
    background-color: #e2ecfb;
    border-radius: 6px;
    color: #4686be;"
        [ngStyle]="{'background-color': show4Graphs  ? '#e2ecfb' : '#ffffff', color:show4Graphs  ? '#4686be' : 'grey'}">
        Pediatric Graph</div>

    <div (click)="toggle4Graphs(0)" style=" font-weight: bold; font-size: 16px; padding: 12px; cursor: pointer;
        background-color: #fff; border-radius: 6px; color: grey;"
        [ngStyle]="{'background-color': !show4Graphs  ? '#e2ecfb' : '#ffffff', color:!show4Graphs  ? '#4686be' : 'grey'}">
        Adult Graph</div>
</div> -->

<div  style="padding-bottom: 100px !important;">

    <div style="display: flex; justify-content: space-evenly; margin-top: 16px; margin-right: 16px; ">

        <!-- COMPARE -->
        <div *ngIf="hasWeightData" style="width: 45%; justify-self:center;">
            <div class="graph-container"
                [ngStyle]="{'background-color': patient.gender == 'Male' ? '#c4f6f58f' : '#ffccff59'}">
                <div class="graph-title-area">
                    <div style="display: flex; width: 100%;">
                        <div class="graph-title">Weight (Kg.)</div>

                        <div *ngIf="isPreTerm" class="form-group selectDropDown bloodGroup onFocus"
                            style="width: 140px; margin-left: 20px;">
                            <select class="newInputBox" (change)="onChange($event.target.value,'Weight')"
                                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px; width: 140px; background-color: #fff;">
                                <option *ngFor="let selectedWeightWeeks of graphArr; let i = index"
                                    value="{{selectedWeightWeeks}}">
                                    {{selectedWeightWeeks}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="selectedWeightWeeks == 'PreTerm'" (click)="onChange32('Weight')" style="width: 130px;
                            margin-left: 16px;
                            display: flex;
                            background-color: #fff;
                            height: 37px;
                            border-radius: 4px;
                            align-items: center;
                            padding: 4px;
                            cursor: pointer;
                            ">
                            <img *ngIf="!weightPretermChecked" style="margin: 3px 8px 0px 0px"
                                src="assets/images/tick_mark_grey.svg" class="tick-icon" />

                            <img *ngIf="weightPretermChecked" style="margin: 3px 8px 0px 0px"
                                src="assets/images/tick_mark_color.svg" class="tick-icon" />

                            <div style="font-weight: 800;">Upto 32 Weeks</div>
                        </div>
                    </div>
                    <div>
                        <!-- <a style="font-size: 16px; color:#5D86CC; font-weight: bold; cursor: pointer !important; width: fit-content;"
                            (click)="setShowWeightGraph();"><u>View full screen</u></a> -->
                        <mat-icon (click)="setShowWeightGraph();" [ngClass]="'mat-blue-icon'">
                            fullscreen_exit
                        </mat-icon>
                    </div>
                </div>
                <app-weight-graph #appWeighGraph></app-weight-graph>
            </div>
        </div>

        <div *ngIf="hasHeightData" style="width: 45%; justify-self:center;">

            <div class="graph-container"
                [ngStyle]="{'background-color': patient.gender == 'Male' ? '#c4f6f58f' : '#ffccff59'}">
                <div class="graph-title-area">
                    <div style="display: flex; width: 100%;">
                        <div class="graph-title">Height (Cms.)</div>

                        <div *ngIf="isPreTerm" class="form-group selectDropDown bloodGroup onFocus"
                            style="width: 140px; margin-left: 20px;">
                            <select class="newInputBox" (change)="onChange($event.target.value,'Height')"
                                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px; width: 140px; background-color: #fff;">
                                <option *ngFor="let selectedHeightWeeks of graphArr; let i = index"
                                    value="{{selectedHeightWeeks}}">
                                    {{selectedHeightWeeks}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="selectedHeightWeeks == 'PreTerm'" (click)="onChange32('Height')" style="width: 130px;
                            margin-left: 16px;
                            display: flex;
                            background-color: #fff;
                            height: 37px;
                            border-radius: 4px;
                            align-items: center;
                            padding: 4px;
                            cursor: pointer;
                            ">
                            <img *ngIf="!heightPretermChecked" style="margin: 3px 8px 0px 0px"
                                src="assets/images/tick_mark_grey.svg" class="tick-icon" />

                            <img *ngIf="heightPretermChecked" style="margin: 3px 8px 0px 0px"
                                src="assets/images/tick_mark_color.svg" class="tick-icon" />

                            <div style="font-weight: 800;">Upto 32 Weeks</div>
                        </div>
                    </div>
                    <div>
                        <!-- <a style="font-size: 16px; color:#5D86CC; font-weight: bold; cursor: pointer !important; width: fit-content;"
                            (click)="setShowHeightGraph();"><u>View full screen</u></a> -->
                            <mat-icon (click)="setShowHeightGraph();" [ngClass]="'mat-blue-icon'">
                                fullscreen_exit
                            </mat-icon>
                    </div>
                </div>
                <app-height-graph #appHeighGraph></app-height-graph>
            </div>
        </div>

    </div>

    <div style="display: flex; justify-content: space-evenly; margin-top: 70px; margin-right: 16px; ">
        <div *ngIf="hasHcData" style="width: 45%; justify-self:center;">
            <div class="graph-container"
                [ngStyle]="{'background-color': patient.gender == 'Male' ? '#c4f6f58f' : '#ffccff59'}">
                <div class="graph-title-area">
                    <div style="display: flex; width: 100%;">
                        <div class="graph-title">Head Circumference (Cms.)</div>

                        <div *ngIf="isPreTerm" class="form-group selectDropDown bloodGroup onFocus"
                            style="width: 140px; margin-left: 20px;">
                            <select class="newInputBox" (change)="onChange($event.target.value,'HC')"
                                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px; width: 140px; background-color: #fff;">
                                <option *ngFor="let selectedHCWeeks of graphArr; let i = index"
                                    value="{{selectedHCWeeks}}">
                                    {{selectedHCWeeks}}
                                </option>
                            </select>
                        </div>
                        <div *ngIf="selectedHCWeeks == 'PreTerm'" (click)="onChange32('HC')" style="width: 130px;
                            margin-left: 16px;
                            display: flex;
                            background-color: #fff;
                            height: 37px;
                            border-radius: 4px;
                            align-items: center;
                            padding: 4px;
                            cursor: pointer;
                            ">
                            <img *ngIf="!hcPretermChecked" style="margin: 3px 8px 0px 0px"
                                src="assets/images/tick_mark_grey.svg" class="tick-icon" />

                            <img *ngIf="hcPretermChecked" style="margin: 3px 8px 0px 0px"
                                src="assets/images/tick_mark_color.svg" class="tick-icon" />

                            <div style="font-weight: 800;">Upto 32 Weeks</div>
                        </div>
                    </div>
                    <div >
                        <!-- <a style="font-size: 16px; color:#5D86CC; font-weight: bold; cursor: pointer !important; width: fit-content;"
                            (click)="setShowHcGraph();"><u>View full screen</u></a> -->
                            <mat-icon (click)="setShowHcGraph();" [ngClass]="'mat-blue-icon'">
                                fullscreen_exit
                            </mat-icon>
                    </div>
                </div>
                <app-hc-graph #appHCGraph></app-hc-graph>
            </div>

        </div>

        <div *ngIf="hasHeightData" style="width: 45%; justify-self:center;">
            <div class="graph-container"
                [ngStyle]="{'background-color': patient.gender == 'Male' ? '#c4f6f58f' : '#ffccff59'}">
                <div class="graph-title-area">
                    <div class="graph-title">Target Height</div>
                    <!-- <a style="font-size: 16px; color:#5D86CC; font-weight: bold; cursor: pointer !important;"
                        (click)="setShowTargetHeightGraph();"><u>View full screen</u></a> -->
                        <mat-icon (click)="setShowTargetHeightGraph();" [ngClass]="'mat-blue-icon'">
                            fullscreen_exit
                        </mat-icon>
                </div>
                <app-target-height-graph></app-target-height-graph>
            </div>
        </div>

    </div>
</div>

<!-- Weight Height Graph -->
<!-- <div style="width: 45%; justify-self:center;">
    <div class="graph-container"
        [ngStyle]="{'background-color': patient.gender == 'Male' ? '#c4f6f58f' : '#ffccff59'}">
        <div class="graph-title-area">
            <div class="graph-title">Weight-Height</div>
                <mat-icon (click)="setShowTargetHeightGraph();" [ngClass]="'mat-blue-icon'">
                    fullscreen_exit
                </mat-icon>
        </div>
        <app-weight-height-graph></app-weight-height-graph>
    </div>
</div> -->

<div style="padding-bottom: 100px !important;">

    <div style="display: flex; justify-content: space-evenly; margin-right: 16px; margin-top: 16px; ">
        <div *ngIf="showBMI" style="width: 45%; justify-self:center;">
            <div class="graph-container"
                [ngStyle]="{'background-color': patient.gender == 'Male' ? '#c4f6f58f' : '#ffccff59'}">
                <div class="graph-title-area">
                    <div class="graph-title">BMI (Kg/m)</div>
                    <!-- <a style="font-size: 16px; color:#5D86CC; font-weight: bold; cursor: pointer !important;"
                        (click)="setShowBMIGraph();"><u>View full screen</u></a> -->
                        <mat-icon (click)="setShowBMIGraph();" [ngClass]="'mat-blue-icon'">
                            fullscreen_exit
                        </mat-icon>
                </div>
                <app-bmi-graph></app-bmi-graph>
            </div>
        </div>

        <div *ngIf="hasBpData" style="width: 45%; justify-self:center;">
            <div class="graph-container"
                [ngStyle]="{'background-color': patient.gender == 'Male' ? '#c4f6f58f' : '#ffccff59'}">
                <div class="graph-title-area">
                    <div class="graph-title">Blood Pressure</div>
                    <!-- <a style="font-size: 16px; color:#5D86CC; font-weight: bold; cursor: pointer !important;"
                        (click)="setShowBPGraph();"><u>View full screen</u></a> -->
                        <mat-icon (click)="setShowBPGraph();" [ngClass]="'mat-blue-icon'">
                            fullscreen_exit
                        </mat-icon>
                </div>
                <app-bp-graph></app-bp-graph>
            </div>
        </div>
    </div>
</div>

<ng-template #weightGraph>
    <div class="modal-header" style="border-bottom: 0px;">

        <div class="modal-container">

            <div class="modal-firstpart">
                <h4 class="modal-title" id="modal-basic-title">Weight Graph</h4>


                <div *ngIf="isPreTerm" class="form-group selectDropDown bloodGroup onFocus"
                    style="width: 20%;margin-left: 20px;">
                    <select class="newInputBox" (change)="onChange($event.target.value,'Weight')"
                        style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;">
                        <option *ngFor="let selectedWeightWeeks of graphArr; let i = index"
                            value="{{selectedWeightWeeks}}">
                            {{selectedWeightWeeks}}
                        </option>
                    </select>
                </div>
                <div *ngIf="selectedWeightWeeks == 'PreTerm'" (click)="onChange32('Weight')"
                    class="searchable-component-style"
                    style="width: 120px;margin-left: 17px;margin-top: 12px;display: flex;">
                    <img *ngIf="!weightPretermChecked" style="margin: 3px 8px 0px 0px"
                        src="assets/images/tick_mark_grey.svg" class="tick-icon" />

                    <img *ngIf="weightPretermChecked" style="margin: 3px 8px 0px 0px"
                        src="assets/images/tick_mark_color.svg" class="tick-icon" />

                    <div style="font-weight: 800;">Upto 32 Weeks</div>
                </div>

            </div>

            <div style="display: flex;">
                <button printSectionId="print-section" ngxPrint class="btn btn-secondary"
                    style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
                    <mat-icon [ngClass]="'blue-icon'">
                        print
                    </mat-icon>
                    <span style="margin-left: 4px;">Print</span>
                </button>

                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
                </button>
            </div>
        </div>
    </div>
    <div id="print-section" [ngStyle]="{'width': width+'px', 'height': height+'px'}">
        <app-weight-graph [isForPopup]='true' #appWeighGraph1></app-weight-graph>
    </div>
</ng-template>

<ng-template #heightGraph>
    <div class="modal-header" style="border-bottom: 0px;">

        <div class="modal-container">

            <div class="modal-firstpart">

                <h4 class="modal-title" id="modal-basic-title">Height Graph</h4>
                <div *ngIf="isPreTerm" class="form-group selectDropDown bloodGroup onFocus"
                    style="width: 20%;margin-left: 20px;">
                    <select class="newInputBox" (change)="onChange($event.target.value,'Height')"
                        style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;">
                        <option *ngFor="let selectedHeightWeeks of graphArr; let i = index"
                            value="{{selectedHeightWeeks}}">
                            {{selectedHeightWeeks}}
                        </option>
                    </select>
                </div>

                <div *ngIf="selectedHeightWeeks == 'PreTerm'" (click)="onChange32('Height')"
                    class="searchable-component-style"
                    style="width: 120px;margin-left: 17px;margin-top: 12px; display: flex;">
                    <img *ngIf="!heightPretermChecked" style="margin: 3px 8px 0px 0px"
                        src="assets/images/tick_mark_grey.svg" class="tick-icon" />

                    <img *ngIf="heightPretermChecked" style="margin: 3px 8px 0px 0px"
                        src="assets/images/tick_mark_color.svg" class="tick-icon" />

                    <div style="font-weight: 800;">Upto 32 Weeks</div>
                </div>

            </div>

            <div style="display: flex;">
                <button printSectionId="print-section" ngxPrint class="btn btn-secondary"
                    style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
                    <mat-icon [ngClass]="'blue-icon'">
                        print
                    </mat-icon>
                    <span style="margin-left: 4px;">Print</span>
                </button>

                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
                </button>
            </div>
        </div>

    </div>

    <div id="print-section" [ngStyle]="{'width': width+'px', 'height': height+'px'}">
        <app-height-graph [isForPopup]='true' #appHeighGraph1></app-height-graph>
    </div>
</ng-template>


<ng-template #hcGraph>
    <div class="modal-header" style="border-bottom: 0px;">


        <div class="modal-container">

            <div class="modal-firstpart">

                <h4 class="modal-title" id="modal-basic-title">Head Circumference Graph</h4>
                <div *ngIf="isPreTerm" class="form-group selectDropDown bloodGroup onFocus"
                    style="width: 20%;margin-left: 20px;">
                    <select class="newInputBox" (change)="onChange($event.target.value,'HC')"
                        style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;">
                        <option *ngFor="let selectedHCWeeks of graphArr; let i = index" value="{{selectedHCWeeks}}">
                            {{selectedHCWeeks}}
                        </option>
                    </select>
                </div>
                <div *ngIf="selectedHCWeeks == 'PreTerm'" (click)="onChange32('HC')" class="searchable-component-style"
                    style="width: 120px;margin-left: 17px;margin-top: 12px;display: flex;">
                    <img *ngIf="!hcPretermChecked" style="margin: 3px 8px 0px 0px"
                        src="assets/images/tick_mark_grey.svg" class="tick-icon" />

                    <img *ngIf="hcPretermChecked" style="margin: 3px 8px 0px 0px"
                        src="assets/images/tick_mark_color.svg" class="tick-icon" />

                    <div style="font-weight: 800;">Upto 32 Weeks</div>
                </div>

            </div>
            <div style="display: flex;">
                <button printSectionId="print-section" ngxPrint class="btn btn-secondary"
                    style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
                    <mat-icon [ngClass]="'blue-icon'">
                        print
                    </mat-icon>
                    <span style="margin-left: 4px;">Print</span>
                </button>

                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
                </button>
            </div>
        </div>
    </div>
    <div id="print-section" [ngStyle]="{'width': width+'px', 'height': height+'px'}">
        <app-hc-graph [isForPopup]='true' #appHCGraph1></app-hc-graph>
    </div>
</ng-template>


<ng-template #targetHeightGraph>
    <div class="modal-header" style="border-bottom: 0px;">
        <div class="modal-container">

            <div class="modal-firstpart">
                <h4 class="modal-title" id="modal-basic-title">Target Height Graph</h4>
            </div>
            <!-- <button class="new_btn" style="margin-left: 20px;" printSectionId="print-section" ngxPrint>Print</button> -->
            <div style="display: flex;">
                <button printSectionId="print-section" ngxPrint class="btn btn-secondary"
                    style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
                    <mat-icon [ngClass]="'blue-icon'">
                        print
                    </mat-icon>
                    <span style="margin-left: 4px;">Print</span>
                </button>

                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
                </button>
            </div>
        </div>

    </div>
    <div id="print-section" [ngStyle]="{'width': width+'px', 'height': height+'px'}">
        <app-target-height-graph [isForPopup]='true'></app-target-height-graph>
    </div>
</ng-template>


<ng-template #BMIGraph>
    <div class="modal-header" style="border-bottom: 0px;">

        <h4 class="modal-title" id="modal-basic-title">BMI Graph</h4>
        <!-- <button class="new_btn" style="margin-left: 20px;" printSectionId="print-section" ngxPrint>Print</button> -->

        <div style="display: flex;">
            <button printSectionId="print-section" ngxPrint class="btn btn-secondary"
                style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
                <mat-icon [ngClass]="'blue-icon'">
                    print
                </mat-icon>
                <span style="margin-left: 4px;">Print</span>
            </button>

            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">
                <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
            </button>
        </div>
    </div>
    <div id="print-section" [ngStyle]="{'width': width+'px', 'height': height+'px'}">
        <app-bmi-graph [isForPopup]='true'></app-bmi-graph>
    </div>
</ng-template>

<ng-template #BPGraph>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">BP Graph</h4>
        <!-- <button class="new_btn" style="margin-left: 20px;" printSectionId="print-section" ngxPrint>Print</button> -->
        <div style="display: flex;">

            <button printSectionId="print-section" ngxPrint class="btn btn-secondary"
                style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">
                <mat-icon [ngClass]="'blue-icon'">
                    print
                </mat-icon>
                <span style="margin-left: 4px;">Print</span>
            </button>

            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">
                <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
            </button>

        </div>
    </div>
    <div id="print-section" [ngStyle]="{'width': width+'px', 'height': height+'px'}">
        <app-bp-graph [isForPopup]='true'></app-bp-graph>
    </div>
</ng-template>

<!-- <ng-template #heightGraph>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">Height Graph</h4>
        <button class="new_btn" style="margin-left: 20px;" printSectionId="print-section" ngxPrint>Print</button>
        <div *ngIf="isPreTerm" class="form-group selectDropDown bloodGroup onFocus"
            style="width: 20%;margin-left: 20px;">
            <select class="newInputBox" (change)="onChange($event.target.value,'Height')"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;">
                <option *ngFor="let selectedHeightWeeks of graphArr; let i = index" value="{{selectedHeightWeeks}}">
                    {{selectedHeightWeeks}}
                </option>
            </select>
        </div>

        <div *ngIf="selectedHeightWeeks == 'PreTerm'" (click)="onChange32('Height')" class="searchable-component-style"
            style="width: 120px;margin-left: 17px;margin-top: 12px; display: flex;">
            <img *ngIf="!heightPretermChecked" style="margin: 3px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

            <img *ngIf="heightPretermChecked" style="margin: 3px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />

            <div style="font-weight: 800;">Upto 32 Weeks</div>
        </div>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog()">
            <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div id="print-section" [ngStyle]="{'width': width+'px', 'height': height+'px'}">
        <app-height-graph [isForPopup]='true' #appHeighGraph1></app-height-graph>
    </div>
</ng-template>


<ng-template #hcGraph>
    <div class="modal-header" style="border-bottom: 0px;">

        <h4 class="modal-title" id="modal-basic-title">HC Graph</h4>
        <button class="new_btn" style="margin-left: 20px;" printSectionId="print-section" ngxPrint>Print</button>
        <div *ngIf="isPreTerm" class="form-group selectDropDown bloodGroup onFocus"
            style="width: 20%;margin-left: 20px;">
            <select class="newInputBox" (change)="onChange($event.target.value,'HC')"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;">
                <option *ngFor="let selectedHCWeeks of graphArr; let i = index" value="{{selectedHCWeeks}}">
                    {{selectedHCWeeks}}
                </option>
            </select>
        </div>
        <div *ngIf="selectedHCWeeks == 'PreTerm'" (click)="onChange32('HC')" class="searchable-component-style"
            style="width: 120px;margin-left: 17px;margin-top: 12px;display: flex;">
            <img *ngIf="!hcPretermChecked" style="margin: 3px 8px 0px 0px" src="assets/images/tick_mark_grey.svg"
                class="tick-icon" />

            <img *ngIf="hcPretermChecked" style="margin: 3px 8px 0px 0px" src="assets/images/tick_mark_color.svg"
                class="tick-icon" />

            <div style="font-weight: 800;">Upto 32 Weeks</div>
        </div>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog()">
            <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div id="print-section" [ngStyle]="{'width': width+'px', 'height': height+'px'}">
        <app-hc-graph [isForPopup]='true' #appHCGraph1></app-hc-graph>
    </div>
</ng-template>


<ng-template #targetHeightGraph>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">Target Height Graph</h4>
        <button class="new_btn" style="margin-left: 20px;" printSectionId="print-section" ngxPrint>Print</button>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog()">
            <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div id="print-section" [ngStyle]="{'width': width+'px', 'height': height+'px'}">
        <app-target-height-graph [isForPopup]='true'></app-target-height-graph>
    </div>
</ng-template>


<ng-template #BMIGraph>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">BMI Graph</h4>
        <button class="new_btn" style="margin-left: 20px;" printSectionId="print-section" ngxPrint>Print</button>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog()">
            <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div id="print-section" [ngStyle]="{'width': width+'px', 'height': height+'px'}">
        <app-bmi-graph [isForPopup]='true'></app-bmi-graph>
    </div>
</ng-template>

<ng-template #BPGraph>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">BP Graph</h4>
        <button class="new_btn" style="margin-left: 20px;" printSectionId="print-section" ngxPrint>Print</button>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog()">
            <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div id="print-section" [ngStyle]="{'width': width+'px', 'height': height+'px'}">
        <app-bp-graph [isForPopup]='true'></app-bp-graph>
    </div>
</ng-template> -->