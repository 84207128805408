import { Component, OnInit, Inject} from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import * as $ from 'jquery'
import { AdminService } from '../../../../services/admin.service'
import { ToastrService } from 'ngx-toastr';
import { GlobalService } from '../../../../services/global.service'
import { AuthService } from '../../../../services/auth.service'
import { filter, cloneDeep } from 'lodash'
import { LOCAL_STORAGE, WebStorageService } from 'ngx-webstorage-service'

@Component({
  selector: 'app-doctor-view',
  templateUrl: './doctor-view.component.html',
  styleUrls: ['./doctor-view.component.scss']
})
export class DoctorViewComponent implements OnInit {
  currentStage: string = '1'
  userId: any = null
  user: any = { doctor: {} }
  specialization: any = null
  degreeArray: any = []
  councilsArray: any = []
  specializations: any = []
  institutes: any = []
  specialitiesArray: any = []
  clinics: any = []
  showLetterhead = false
  primaryClinic:any = {}
  letterheadClinics = []
  showLoading = false
  readOnly = false

  constructor(@Inject(LOCAL_STORAGE) private storage: WebStorageService, private route: ActivatedRoute, private authService: AuthService, private adminService: AdminService, private globalService: GlobalService, private toastr: ToastrService, private router: Router) {
    this.route.params.subscribe(params => {
      this.userId = params.id
    })
      let params = {
        user_id: this.userId
      }
      this.showLoading = true
      this.adminService.getDetails(params).subscribe(response => {
        this.showLoading = false
        if (response.success) {
         
          this.user = response.user
          let currentYear = new Date().getFullYear()
          let birthYear = new Date(this.user.dob).getFullYear()
          this.user.age = currentYear - birthYear
          this.user.doctor = response.doctor
          this.user.doctor.awards = response.awards
          this.user.doctor.educations = response.educations
          if(this.user.doctor.speciality != 'Pediatrics'){
            this.readOnly = true
          }
          let doc_params = {
            doctor_id: this.user.doctor.id
          }
          this.adminService.getClinics(doc_params).subscribe(response => {
            if (response.success) {
              this.clinics = response.clinics
              this.clinics.forEach(clinic => {
                clinic.groupedSessions = this.groupSessions(clinic)
                clinic.groupedOnCalls = this.groupOnCalls(clinic)
              });
              this.primaryClinic = this.clinics.filter(function (obj) {
                return obj.primary
              })[0]
              if(!this.primaryClinic){
                this.primaryClinic = this.clinics[0]
              }
              this.letterheadClinics = this.clinics.filter(function (obj) {
                return (obj.include_on_letterhead && !obj.primary)
              })
            }
            else {
              this.toastr.error(response.error)
            }
          })
        } else {
          this.toastr.error(response.error)
        }
      })
      this.adminService.getConstants().subscribe(response => {
        this.degreeArray = response.degrees
        this.councilsArray = response.state_medical_councils
        this.specializations = response.specializations
        this.specialitiesArray = response.specialities
        this.institutes = response.institutes

      })
    
  }

  ngOnInit() {
    
  }

  addLabelClass(e) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

  

  groupOnCalls(clinic){
    let forGroupingSession = cloneDeep(clinic.ClinicSchedules)
    let groupedSessions = []
    forGroupingSession.map((day) => {
      if (day.day != null && day.on_call) {
        day.day = day.day.substring(0, 3)
        groupedSessions.push(day.day)
      }
    })
    return groupedSessions
  }

  groupSessions(clinic) {
    let forGroupingSession = cloneDeep(clinic.ClinicSchedules)
    let groupedSessions = {}
    forGroupingSession.map((day) => {
      if (day.day != null) {
        day.day = day.day.substring(0, 3)
        let key = ""
        if(day.morning_from != null && day.morning_to != null){
          day.morning_from = day.morning_from.split(':')[0] == '0' ? '12:00 AM' : day.morning_from
          day.morning_to = day.morning_to.split(':')[0] == '0' ? '12:00 AM' : day.morning_to
          key = key + day.morning_from +' to ' +day.morning_to
        }
        if(day.evening_from != null && day.evening_to != null){
          if(key != ""){
            key = key + ', '
          }
          day.evening_from = day.evening_from.split(':')[0] == '0' ? '12:00 AM' : day.evening_from
          day.evening_to = day.evening_to.split(':')[0] == '0' ? '12:00 AM' : day.evening_to
          key = key + day.evening_from +' to ' +day.evening_to
        }
        if (groupedSessions[key] && groupedSessions[key]['days'].length > 0) {
          groupedSessions[key]['days'].push(day)
        } else {
          groupedSessions[key] = {}
          groupedSessions[key]['days'] = []
          groupedSessions[key]['days'].push(day)
        }
      }
    })

    let sessions = Object.keys(groupedSessions).map(key => ({ time: key, value: groupedSessions[key] }))
    return sessions
  }

  previewLetterhead() {
    /*this.primaryClinic['groupedSessions'] = this.groupSessions(this.primaryClinic)
    this.letterheadClinics.forEach(clinic => {
      clinic.groupedSessions = this.groupSessions(clinic)
    });*/

    this.showLetterhead = true
  }

}
