<section>
  <div class="container">
    <div class="row">
      <div class="admin-login">
        <div class="form-section student-signup">
          <header class="header-wrapper mb-4">
            <div class="logo-section">
              <h4>Copious</h4>
            </div>
          </header>
          <div class="form-heading align-center">
            <h2 class="fn14 cn2 widthAuto underlinecn3">Reset Password</h2>
          </div>
          <form (submit)="submit()">
            <!--<div class="form-group">
              <div class="form-field-holder">
                <input type="email" class="form-control" #email="ngModel" name="email_id" placeholder="Enter email" [pattern]="emailPattern" [(ngModel)]="credentials.email_id" (keyup.enter)="login()">
                <i class="la la-envelope cn3"></i>
                <p class="errors " *ngIf="email.errors?.pattern">Please enter valid email id</p>
              </div>
            </div>-->
            <div class="form-group animateLabel">
              <input (keydown.space)="$event.preventDefault()" type="password"  [(ngModel)]="credentials.password" id="passwordInput" name="password" class="form-control animateInput" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
              <label [ngClass]="{'animatedLabel':credentials.password}">Enter Password</label>
              <p class="errors" *ngIf="errors.password">{{errors.password}}</p>
            </div>

            <div class="form-group animateLabel">
                <input (keydown.space)="$event.preventDefault()" type="password"  [(ngModel)]="credentials.confirm_password" id="passwordInput" name="password" class="form-control animateInput" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                <label [ngClass]="{'animatedLabel':credentials.confirm_password}">Confirm Password</label>
                <p class="errors" *ngIf="errors.confirm_password">{{errors.confirm_password}}</p>
              </div>
            <div class="form-group">
              <div class="form-field-holder">
                <button type="submit" class="btn-primary opd-primary-btn btn-md">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</section>
