<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
    <label for="" class="loader"></label>
</div>
<div class="row d-flex justify-content-center" style="flex-direction: column;">
    <div class="headerbar">

        <app-top-info-navbar [selectedIndex]='selectedIndex' [parentForm]="'PO'"></app-top-info-navbar>

    </div>


    <!-- OLD SCROLLABLE TABS -->
    <!-- <scrollable-tabs [tabs]="tabs" (emitSelectedTab)="selectedTab($event)"></scrollable-tabs> -->



    <!-- ***********OverView Section*********************** -->
    <div *ngIf="tab == 'Overview'">
        <div
            style="flex:1;flex-direction: row;justify-content: space-between;display: flex;margin-left: 30px;margin-right: 30px;">
            <div *ngIf="isPediatric" class="card" style="width: 48%;height: 140px;">
                <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                    <div style="display: flex; flex-direction: row;">
                        <div class="leftSideTextOnCard" style="padding-top: 10px;">Profile</div>
                    </div>
                    <div *ngIf="!allSummary.length" class="rightSideTextOnCard">
                        <div *ngIf="!patientResponse.discharge_note" class="rightSideTextOnCard"
                            (click)="openDialog(addUploadModal,'addUploadModal'); closeUploadModal();clickDisModal()">
                            Discharge Summary</div>
                    </div>
                    <div *ngIf="allSummary.length" class="rightSideTextOnCard">
                        <div *ngIf="!patientResponse.discharge_note" class="rightSideTextOnCard"
                            (click)="openDialog(discharge_Summary)"> View Summary
                        </div>
                    </div>

                    <div *ngIf="!allSummary.length" class="rightSideTextOnCard">
                        <div *ngIf="patientResponse.discharge_note" class="rightSideTextOnCard"
                            (click)="openDialog(addUploadModal ,'addUploadModal'); closeUploadModal()">Discharge Summary
                        </div>
                    </div>
                    <div *ngIf="allSummary.length" class="rightSideTextOnCard">
                        <div *ngIf="patientResponse.discharge_note" class="rightSideTextOnCard"
                            (click)="openDialog('discharge_Summary')"> View Summary
                        </div>
                    </div>
                </div>
                <div style="display: flex; flex-direction: row; margin-left: 15px; margin-top: 10px; ">
                    <div style="width: 25%;flex-direction: column;">
                        <div style="font-size: 14px;color: #333333;font-weight:500;">Type of Delivery</div>
                        <div style="font-size: 12px;color: #333;">{{typeOfDelivery}}</div>
                    </div>
                    <div style="width: 25%;flex-direction: column;">
                        <div style="font-size: 14px;color: #333333;font-weight:500;">Date of Birth</div>
                        <div style="font-size: 12px;color: #333;">{{dob}}</div>
                    </div>
                    <div style="width: 25%;flex-direction: column;">
                        <div style="font-size: 14px;color: #333333;font-weight:500;">EDD</div>
                        <div style="font-size: 12px;color: #333;">{{eDob}}</div>
                    </div>
                    <div style="width: 25%;flex-direction: column;">
                        <div style="font-size: 14px;color: #333333;font-weight:500;">GA</div>
                        <div style="font-size: 12px;color: #333;">{{gestationalAge.w}}w {{gestationalAge.d}}d</div>
                        <!-- <div style="font-size: 12px;color: #333;">{{gestationalAge}}</div> -->
                    </div>
                </div>
                <div style="display: flex; flex-direction: row; margin-left: 15px; margin-top: 10px; ">
                    <div style="width: 25%;flex-direction: column;">
                        <div style="font-size: 14px;color: #333333;font-weight:500;">Blood Group</div>
                        <div style="font-size: 12px;color: #333;">{{blood_group}}</div>
                    </div>
                    <div style="width: 25%;flex-direction: column;">
                        <div style="font-size: 12px;color: #333333;font-weight: 500;">Status </div>
                        <div *ngIf="isHighRisk==false" style="font-size: 12px;color: #FF0000;"></div>
                        <div *ngIf="isHighRisk==true" (click)="open(highRiskModal)"
                            style="font-size: 12px;color: #FF0000;text-decoration: underline; cursor: pointer;">High
                            Risk</div>
                    </div>
                    <div style="width: 25%;flex-direction: column;">
                        <div style="font-size: 12px;color: #333333;font-weight: 500; display: flex;">New Born
                            Screening:&nbsp;&nbsp;
                            <div *ngIf="!allHeelPrickTest.length" style="font-size: 12px;color: #333;"> No</div>
                            <div *ngIf="allHeelPrickTest.length" style="font-size: 12px;color: #333;"> Yes</div>
                        </div>
                        <div *ngIf="!allHeelPrickTest.length"
                            (click)="openDialog(addUploadModal ,'addUploadModal');closeUploadModal(); clickHeelDoc()"
                            style="font-size: 12px;font-weight: bold; color: #4687BF;text-decoration: underline; cursor: pointer;">
                            Upload Documents</div>

                        <div *ngIf="allHeelPrickTest.length" (click)="onClickViewHeelReports(heelPrickUrl)"
                            style="font-size: 12px;font-weight: bold; color: #4687BF;text-decoration: underline; cursor: pointer;">
                            View Report
                        </div>

                    </div>


                </div>
            </div>
            <div *ngIf="!isPediatric" class="card" style="width: 48%;height: 140px;">
                <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                    <div style="display: flex; flex-direction: row;">
                        <div class="leftSideTextOnCard" style="padding-top: 12px">Profile</div>
                    </div>
                    <div *ngIf="patientResponse && !patientResponse.discharge_note" class="rightSideTextOnCard"
                        (click)="onClickPastHistory()">Mrunalini</div>
                    <div *ngIf="patientResponse && patientResponse.discharge_note" class="rightSideTextOnCard"
                        (click)="open(dischargeSummeryModal,'dischargeSummeryModal')">Past History
                    </div>
                </div>
                <div style="display: flex; flex-direction: row; margin-left: 15px; margin-top: 10px; ">
                    <div style="width: 25%;flex-direction: column;">
                        <div style="font-size: 14px;color: #333333;font-weight:500;">Blood Group</div>
                        <div style="font-size: 12px;color: #8B8B8B;">{{blood_group}}</div>
                    </div>
                    <div style="width: 25%;flex-direction: column;">
                        <div style="font-size: 14px;color: #333333;font-weight:500;">Age</div>
                        <div style="font-size: 12px;color: #8B8B8B;">{{ageYears}}y</div>
                    </div>

                </div>
            </div>
            <div class="card" style="width: 50%;min-height: 140px;">
                <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                    <div style="display: flex; flex-direction: row;">
                        <div class="leftSideTextOnCard" style="padding-top: 12px">More Information</div>
                    </div>
                    <div class="rightSideTextOnCard" (click)="opensDialog(moreInfoModal, 'moreInfoModal')">Edit</div>
                </div>
                <div style="display: flex; flex-direction: column; margin-left: 15px; margin-top: 10px; ">
                    <div style="margin-right: 15px;">
                        <div style="font-size: 14px;color: #333333;align-self: flex-start;width: 25%;">
                            Allergies
                        </div>
                        <div style="font-size: 12px;color: #8B8B8B;align-self:flex-end;">{{allergies}}</div>
                    </div>
                    <div style="margin-right: 15px;">
                        <div style="font-size: 14px;color: #333333;align-self: flex-start;width: 25%;">
                            Conditions
                        </div>
                        <div style="font-size: 12px;color: #8B8B8B;align-self:flex-end;">{{medicalConditions}}</div>
                    </div>

                    <div style="margin-right: 15px;">
                        <div style="font-size: 14px;color: #333333;align-self: flex-start;width: 25%;">
                            Recurring Complaints
                        </div>
                        <div style="font-size: 12px;color: #8B8B8B;align-self:flex-end;">{{recurringComplaints}}
                        </div>
                    </div>
                    <div style="margin-right: 15px;">
                        <div style="font-size:14px;color: #333333;align-self:flex-start;width: 40%;">
                            Accidental/Procedural Information
                        </div>
                        <div style="font-size: 12px;color: #8B8B8B;align-self:flex-end;">{{accidentalHistory}}</div>
                    </div>
                </div>
            </div>

        </div>


        <div
            style=" flex:1;flex-direction: row;justify-content: space-between;display: flex;margin-left: 47px;margin-right: 30px; ">
            <div *ngIf="isPediatric" style="width: 48%;padding-left: 10px;;">
                <div style="display: flex; flex-direction: column; margin-left: -2px; ">
                    <div style="text-align:start; font-size: 16px;color: #333333;">Vitals At Birth</div>

                    <div class="row">
                        <div *ngFor="let vital of oldOrBirthVitalsArray; let i = index"
                            style="display: flex; flex-direction: row; margin-left:-9px;">
                            <div class="smallcard" style="width:95px;height:85px;display: flex;
                                flex-wrap: wrap;
                                margin-right: 18.5px;
                                margin-left: 6.5px;">
                                <div style="font-size: 12px;color: #8B8B8B;font-weight: 500;padding-top: 7px;">
                                    {{vital.name}}</div>
                                <div style="display: flex;flex-direction: row;">
                                    <div style="font-size: 20px;color: #333333;font-weight: bold;margin-right: 5px;">
                                        {{vital.value && vital.value !='null' ? vital.value : 'NA'}}</div>
                                    <div style="font-size: 12px;color: #8B8B8B;padding-top: 8px;">{{vital.unit}}</div>
                                </div>
                                <div style="display: flex;flex-direction: row;">
                                    <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                            class="navbar-avatar rounded-circle"
                                            src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                    </div>
                                    <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{vital.date}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>




            <div *ngIf="!isPediatric" style="width: 48%;padding-left: 10px;">

                <div *ngIf="adultPreviousVisit" style="display: flex; flex-direction: column; margin-left: 10px; ">

                    <div style="text-align:start;color: #333333; font-size: 16px;margin-left: -3px;">Vitals as of
                        {{adultPreviousVisitDate}}
                    </div>
                    <div class="row" style="margin-left: 0px;">
                        <div class="smallcard"
                            style="width:95px;height:80px;flex-direction:column;justify-content:center;align-items: center;margin-left:-7px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Weight</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.weight && adultPreviousVisit.weight !="null" ?
                                    adultPreviousVisit.weight : 'NA'}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;">Kg</div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width:95px;height:80px;flex-direction: column;justify-content: center;align-items: center;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Height</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.height}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;">cm</div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 90px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight: 500;padding-top: 4px;">BMI</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisitBMI && adultPreviousVisitBMI != 'NaN' ? adultPreviousVisitBMI :
                                    'NA'}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>
                        <div class="smallcard"
                            style="width: 90px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Temprature
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.Temp}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;">F</div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 90px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Hemoglobin
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.Bloodhaemoglobin}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div style="text-align:start;font-size: 16px; font-weight: 500;">Blood Sugar:</div>
                        <div class="smallcard" style="width: 90px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-left: -91px;
                            margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Random</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.BloodSugarRandom}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 90px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;  margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">HbA1c</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.Blood_Hba1c}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center; margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">FF/PP</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.Bloodsugar_F}}/{{adultPreviousVisit.Bloodsugar_PP}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div style="text-align:start;color: #333333; font-size: 16px;">Blood Pressure:</div>
                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-left:-114px;  margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight: 500;padding-top: 4px;">Sys/Dia</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.bpSys}}/{{adultPreviousVisit.bpDia}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Pulse</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.Pulse}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center; margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">HeartRate
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.Heartrate}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div style="text-align:start;color: #333333;font-weight:500; font-size: 16px;">Lipid Profile:
                        </div>
                        <div class="smallcard"
                            style="width: 95px;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-left: -89px;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">LDL Chol.
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.Chol_LDL}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">HDL Chol.
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.Chol_HDL}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Total Chol.
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.TotalCholesterol}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>
                        <div class="smallcard"
                            style="width: 115px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight: 500;padding-top: 4px;">
                                Ratio(LDL/HDL)</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisitCholRatio}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>
                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Triglycerides
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultPreviousVisit.Triglycerides_LFT}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultPreviousVisitDate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!adultPreviousVisit" style="display: flex; flex-direction: column; margin-left: 10px; ">
                    <div class="leftSideTextOnCard" style="text-align:start;">Vitals</div>
                    <div class="row">
                        <div class="leftSideTextOnCard" style="text-align:center; margin-left: 40%;">NA</div>

                    </div>
                </div>

            </div>
            <div *ngIf="isPediatric" style="width: 50%;padding-left: 10px;">
                <div style="display: flex; flex-direction: column;">
                    <div style="text-align: start; color: #333333; font-size: 16px; font-weight: 500;">Vitals As of
                        {{pediatricVisitDate}}</div>
                    <div class="row">
                        <div *ngFor="let vital of recentVitalsArray; let i = index"
                            style="display: flex; flex-direction: row; ">
                            <div class="smallcard"
                                style="width:95px;;height:80px;flex-direction: column;justify-content: center;align-items: center; ">
                                <div style="display: flex; justify-content: space-between;">
                                    <div style="font-size: 12px;color: #AAAAAA;font-weight: 500;padding-top: 7px;">
                                        {{vital.name}}</div>
                                    <div style="cursor: pointer;">
                                        <img *ngIf="vital.name != 'SPO2'" (click)="onClickVitalToShowGraph(vital.name)"
                                            style="height: 15px; width: 15px; align-self: center; margin-top: 7px;"
                                            src="assets/images/share.png" alt="" />
                                    </div>
                                </div>
                                <div style="display: flex;flex-direction: row;">
                                    <div style="font-size: 20px;color: #333333;font-weight: bold;margin-right: 5px;">
                                        {{vital.value}}</div>
                                    <div style="font-size: 10px;color: #cccccc;padding-top: 8px;">{{vital.unit}}</div>
                                </div>
                                <div style="display: flex;flex-direction: row;">
                                    <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                            class="navbar-avatar rounded-circle"
                                            src="../../../../assets/images/calendarIcon.svg" width="11" height="11">
                                    </div>
                                    <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{vital.date}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div *ngIf="!isPediatric" style="width: 50%;padding-left: 10px;">
                <div *ngIf="adultLatestVisit" style="display: flex; flex-direction: column;  ">
                    <div style="text-align:start;color: #333333; font-size: 16px; font-weight: 500;">Vitals as of
                        {{adultLatestVisitDate}}
                    </div>
                    <div class="row" style="margin-left: -2px;">
                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-left:-2px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight: 500;padding-top: 4px;">Weight</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.weight && adultLatestVisit.weight !='null' ?
                                    adultLatestVisit.weight : 'NA'}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;">Kg</div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;height: 80px;flex-direction: column;justify-content: center;align-items: center;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Height</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight: bold;margin-right: 5px;">
                                    {{adultLatestVisit.height}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;">cm</div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>
                        <div class="smallcard"
                            style="width: 95px;height: 80px;flex-direction: column;justify-content: center;align-items: center;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">SPO2</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight: bold;margin-right: 5px;">
                                    {{adultLatestVisit.spo2}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;">spo2</div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #cccccc;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight: bold;padding-top: 4px;">BMI</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisitBMI}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>
                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Temprature
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.Temp}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;">F</div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight: 500;padding-top: 4px;">Hemoglobin
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.Bloodhaemoglobin}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div style="text-align:start; color: #333333; font-size: 16px;">Blood Sugar:</div>
                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-left: -92px; margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Random</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.BloodSugarRandom}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">HbA1c</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.Blood_Hba1c}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight: bold;padding-top: 4px;">FF/PP</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.Bloodsugar_F}}/{{adultLatestVisit.Bloodsugar_PP}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="leftSideTextOnCard"
                            style="text-align:start; color: #333333; font-size: 16px; font-weight: 500;">Blood Pressure:
                        </div>
                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-left:-118px; margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight: bold;padding-top: 4px;">Sys/Dia
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.bpSys}}/{{adultLatestVisit.bpDia}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Pulse</div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.Pulse}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">HeartRate
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.Heartrate}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="leftSideTextOnCard"
                            style="text-align:start; color: #333333; font-size: 16px; font-weight: 500;">Lipid Profile:
                        </div>
                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-left:-92px;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">LDL Chol.
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.Chol_LDL}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">HDL Chol.
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.Chol_HDL}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>

                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight: bold;padding-top: 4px;">Total Chol.
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.TotalCholesterol}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>
                        <div class="smallcard"
                            style="width: 115px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Ratio(LDL/HDL)
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisitCholRatio}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>
                        <div class="smallcard"
                            style="width: 95px;;height: 80px;flex-direction: column;justify-content: center;align-items: center;margin-top: 28px;">
                            <div style="font-size: 14px;color: #AAAAAA;font-weight:500;padding-top: 4px;">Triglycerides
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="font-size: 20px;color: #333333;font-weight:bold;margin-right: 5px;">
                                    {{adultLatestVisit.Triglycerides_LFT}}</div>
                                <div style="font-size: 12px;color: #cccccc;padding-top: 8px;"></div>
                            </div>
                            <div style="display: flex;flex-direction: row;">
                                <div style="margin-right: 5px;"><img _ngcontent-xok-c140="" alt="user" title="user"
                                        class="navbar-avatar rounded-circle"
                                        src="../../../../assets/images/calendarIcon.svg" width="12" height="12">
                                </div>
                                <div style="font-size: 10px;color: #000000;padding-top: 5px;">{{adultLatestVisitDate}}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="!adultLatestVisit" style="display: flex; flex-direction: column; margin-left: 10px; ">
                    <div class="leftSideTextOnCard" style="text-align:start;">Vitals</div>
                    <div class="row">
                        <div class="leftSideTextOnCard" style="text-align:center; margin-left: 40%;">NA</div>

                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="isPediatric"
            style=" flex:1;flex-direction: row;justify-content: space-between;display: flex;margin-left: 30px;margin-right: 30px;">
            <div class="card" style="width: 48%;padding-top: 10px;padding-bottom: 10px;">
                <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                    <div style="display: flex; flex-direction: row;">
                        <div style="padding-top: 12px; color: #333333;font-weight: 500; font-size: 16px;">Next
                            Vaccination
                            <!-- <span *ngIf="foundPendingVaccines"
                                style="font-weight: 300; color: grey;">({{pendingVaccineObj.name}})</span> -->
                        </div>
                    </div>
                    <div *ngIf="foundPendingVaccines" class="rightSideTextOnCard">Due
                        {{pendingVaccineObj.data[0].dueDate}}</div>
                </div>
                <div style="display: flex; flex-direction: row; margin-left: 15px; margin-top: -9px; ">
                    <div *ngFor="let item of pendingVaccineObj.data; let i = index"
                        style=" display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px;">

                        <div class="vaccinantionCard">
                            <img style="margin: 0px 8px 0px 0px" *ngIf="!item.administeredStatus"
                                src="../../../../assets/images/curve_icon.svg" alt="" class="tick-icon" height="20"
                                width="20" />

                            <img style="margin: 0px 8px 0px 0px" *ngIf="item.administeredStatus"
                                src="../../../../assets/images/curve_icon_green.svg" alt="" class="tick-icon"
                                height="20" width="20" />
                            <div>{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div>

            <!-- <div class="card" style="width: 48%;padding-top: 10px;padding-bottom: 10px;">
                <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                    <div style="display: flex; flex-direction: row;">
                        <div style="padding-top: 12px; color: #333333;font-weight: 500; font-size: 16px;">Due
                            Milestones
                          
                        </div>
                    </div>
                  
                </div>
                <div style="margin-left: 10px;font-weight: 600;">(Age In Months) </div>
                <div style="display: flex; flex-direction: row; margin-left: 15px; margin-top: -9px; ">
                    
                    <div *ngFor="let item of dueMilestoneArray; let i = index"
                        style=" display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px;">
                      <div>
                        
                        <div style="margin-left: 15px; color: #000;">{{item.start_month}} To {{item.end_month}}</div>
                    </div>
                        <div class="vaccinantionCard" style="cursor: pointer;" (click)="openMilestoneTab();">
                            <img style="margin: 0px 8px 0px 0px" 
                                src="../../../../assets/images/curve_icon.svg" alt="" class="tick-icon" height="20"
                                width="20" />

                          
                            <div>{{item.name}}</div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <div style="display: flex;">

            <button *ngIf="showDelete" type="button"
                style="margin-left: 55px;background-color: #ff0000; color: '#fff'; border-radius: 4px;"
                class="new_delete_btn" (click)="opensDialog(addDeletePatient);">Delete</button>

            <div>

                <button type="submit" class="new_btn" style="text-transform: capitalize;margin-left: 55px"
                    (click)="opensDialog(link_patient_modal);  closeLinkData();">Link Patient</button>
            </div>
        </div>
    </div>


    <!-- ***********Visits Section*********************** -->
    <div *ngIf="tab == 'Visits'"
        style="display: flex; flex-direction: column; background-color: #fff; height: 100vh;width: 104vw; align-items: center">
        <patient-vitals [patientObj]="patientId" [showGraphs]="showGraphs" [graphName]="graphName"></patient-vitals>
    </div>

    <!-- commenting to remove duplicate graph container component -->
    <!-- <div *ngIf="tab == 'Visits' && showGraphs">
        <button *ngIf="showGraphs" (click)="hideGraph()" class="new_btn" style="margin-left: 70px !important">Hide Graphs</button>
        <app-graph-container [graphName]='graphName'></app-graph-container>
    </div> -->

    <!-- <div *ngIf="tab == 'Visits' && !showGraphs"
        style="display: flex; flex-direction: column; background-color: #ffffff; height: 100vh;width: 100%; align-items: center;">
        <div style="display: flex; width: 50%; justify-content: space-between; margin: 8px 30px;">
            <div style="
        font-size: 24px;
        color: #333;
        width: 50%;
        font-weight: bold;">Vitals</div>

            <button *ngIf="!showGraphs && !isVisitEdit"
                (click)="addNewVitalsLink();opensDialog(addVitalsModal, 'addVitalsModal');" class="new_btn">Add
                Vitals</button>

            <button *ngIf="!showGraphs && isVisitEdit"
                (click)="addVitals();opensDialog(editVitalsModal, 'editVitalsModal');" class="new_btn">Edit
                Vitals</button>
        </div>


        <div *ngIf="!showGraphs" style="height: '100%'; width: '80%';">
            <div *ngIf="isPediatric">
                <div style="border: 1px solid #e9eaea;background-color: #f1f1f1;height: 40px;">
                    <div style="padding: 7px;color: #333;font-size: 18px;font-weight:500;">Birth Records</div>
                </div>
                <div (click)="onSelectVisit(birthVisit);opensDialog(editVitalsModal, 'editVitalsModal');"
                    style="border: 1px solid #e9eaea;background-color: #ffffff;height: 40px; display:flex;flex-direction: row;cursor: pointer;">
                    <div style="padding: 7px;color: #333333;font-size: 16px;width: 50%;">
                        {{birthVisit.formattedDate}}
                    </div>
                    <div style="display:flex;flex-direction: row;width: 50%;">
                        <div *ngIf="birthVisit.updatedByName != null"
                            style="font-size: 16px; align-self: center; justify-content:flex-end; text-align:right; color:#808080;width: 60%;">
                            {{((birthVisit.updatedByName).length > 17) ?
                            (((birthVisit.updatedByName).substring(0, 17 - 3)) + '...') :
                            birthVisit.updatedByName}}
                        </div>
                        <div
                            style=" font-size: 16px; align-self:center; justify-content: flex-end; text-align: right;color:#808080;width: 28%; ">
                            {{moment(birthVisit.date).format('h:mm a')}}
                        </div>
                        <a>
                            <span>
                                <i style="color: #04C5BE;margin-left: 10px;font-size: 25px; margin-top: 5px;"
                                    class="fa fa-angle-right fa-4"></i></span></a>
                    </div>
                </div>
            </div>

            <div *ngIf="!visitsForVisitScreen.length" style="width: 100%;display: flex; flex-direction: column; 
            height: 80vh;
            display: flex;
           
            justify-content: center;
            align-items: center;">

                <div style="text-align: center;">
                    <img style="justify-content: center;align-items: center; width: 170px;margin-bottom: -10px"
                        src="assets/images/placeholder.svg" alt="" />

                    <div style="font-size: 20px; color: #333333; font-weight: bold;">No Visits</div>
                    <div style="font-size: 18px; color: grey;">No visits found for selected Patient</div>
                </div>
            </div>

            <div *ngFor="let eachMonthVisit of visitsForVisitScreen; let i = index">
                <div style="border: 1px solid #eee;background-color: #F7F7F8; height: 50px;">
                    <div style="padding: 7px;color: #757575;font-size: 20px;font-weight:bold;">
                        {{eachMonthVisit.month}}
                    </div>
                </div>

                <div *ngFor="let visit of eachMonthVisit.data; let j = index"
                    style="border: 1px solid #e9eaea;background-color: #ffffff; display:flex;flex-direction: row;cursor: pointer; align-items: center;"
                    (click)="onSelectVisit(visit);opensDialog(editVitalsModal, 'editVitalsModal');">
                    <div
                        style="padding: 7px;color: #333; font-size: 18px;width: 10%;display: flex; flex-direction: row;">
                        {{visit.formattedDate}} <br />
                        {{moment(visit.date).format('h:mm a')}}
                    </div>
                    <div style="width: 90%;display: flex; flex-direction: row;float: left;">
                        {{ visit.strToShowNonNullValues }}
                       

                    </div>
                </div>
            </div>
        </div>

    </div> 


    <!-- ***********History Section*********************** -->

    <div *ngIf="tab == 'History'">
        <app-patient-history [patientPrescription]="appointmentArr" [patientAllDocumnets]="allDocumentReports"
            [patientId]="patientId.id ? patientId.id : patientId._id">
        </app-patient-history>

    </div>


    <!-- ***********Vaccination Section*********************** -->

    <div *ngIf="tab == 'Vaccination'"
        style="display: flex; flex-direction: column; background-color: #ffffff; height: 105vh; width: 100%; align-items: center; margin-top: 0px;">

        <patient-immunization [patientObj]="patientId" [doctorObj]="currentDoctorObj"
            [specialSituationVaccines]="specialSituationVaccines" [specialVaccineInfo]="specialVaccineInfo">
        </patient-immunization>
    </div>

    <!-- ***********Milestones Section*********************** -->

    <div *ngIf="tab == 'Milestone'"
        style="display: flex; flex: 1; position: relative; flex-direction: column;  height: 100%; width: 100%;">
        <app-milestone [patientObj]="patientId" [doctorObj]="currentDoctorObj" [milestoneFlag]="milestoneFlag"></app-milestone>
    </div>


    <!-- ***********Notes Section*********************** -->

    <div *ngIf="tab == 'Notes'" style="display: flex; flex-direction: row; height: 100vh;">


        <div style="display: flex; justify-content: flex-start; width: 50%; flex-direction: column;">
            <!-- <div style="margin: 16px 0px 0px 30px; font-size: 24px; color: #333; font-weight: bold;">Notes</div> -->
            <div class="heading">Notes</div>
            <div class="subheading">This feature is used create notes for patients.</div>

            <div *ngIf="!notesArr.length"
                style="width: 100%;display: flex; flex-direction: column; width: 100%;height: 80vh;display: flex;justify-content: center;align-items: center;">
                <div style="text-align: center;">
                    <img style="justify-content: center;align-items: center; width: 170px;margin-bottom: -10px"
                        src="assets/images/placeholder.svg" alt="" />

                    <div style="font-size: 20px; color: #333333; font-weight: bold;">No Notes </div>
                    <div style="font-size: 18px; color: grey;">No notes found for selected Patient</div>

                </div>
            </div>

            <div style="margin-left: 16px; width: 95%;">
                <div *ngFor="let note of notesArr; let i = index">
                    <div class="card" style="width: 100%;align-self: center;padding: 10px;">
                        <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                            <div style="display: flex; flex-direction: row;">
                                <div class="leftSideTextOnCard">Dr. {{note.docName}}</div>
                            </div>
                            <div class="rightSideTextOnCard">{{note.date}}</div>
                        </div>
                        <div style="margin-top: 10px;margin-left: 15px;">
                            <div style="font-size: 16px;color: #8B8B8B; word-break: break-all !important">{{note.note}}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div
            style="display: flex; width: 50%; height: 100vh; flex-direction: column; justify-content: center; align-items: center;">

            <div hidden style="margin: 16px 0px 0px 30px;
            font-size: 24px;
            color: #333;
            font-weight: bold;">Notes</div>
            <div class="ticket"
                style="height: 410px; width: 90%; background-color: #fff; margin-top: 16px; border-color: rgb(70, 135, 191);">
                <div style="margin: 1px 0px 0px 0px;
                    font-size: 24px;
                    color: #333;
                    font-weight: bold;">Create Note</div>
                <div style="margin: 0px 0px 0px 0px;
                     font-size: 16px;
                     color: #798197;
                     font-weight: bold;
                     ">Create or update a note for this patient & keep it precise.</div>

                <textarea name="Text1" cols="40" rows="10" class="text-area-style" maxlength="500" placeholder=""
                    [(ngModel)]="notes_message">{{notes_message}}</textarea>

                <div style="display: flex; justify-content: flex-end; width: 100%">
                    <button type="submit" class=" btn-md w125 newBtnStyle"
                        style="align-self: flex-end; margin-top:16px; text-transform: capitalize; background-color: #4687BF !important; border: 1px solid #4687BF !important;"
                        (click)="saveNotes()">Save</button>
                </div>
            </div>

        </div>

    </div>

    <!-- ***********Receipt Section*********************** -->

    <div *ngIf="tab == 'Receipts'" style="display: flex; width: 100%; margin-top: 0px;">
        <app-patient-receipt [patientObj]="patientId" [patientType]="isPediatric" [doctorObj]="currentDoctorObj">
        </app-patient-receipt>

    </div>
    <!--***********Investigation*******************************-->



    <!--***********Investigation*******************************-->

    <div *ngIf="tab == 'Investigations'">

        <div *ngIf="innertab == 'Reports'" style="flex: 1; height: 100vh; width: '100%';">

            <div *ngIf="innertab == 'Reports'">
                <div style="background-color: #fff;
            height: 100%;
            width: 100%;
            z-index: 100;" class="d-flex justify-content-center align-items-center position-fixed"
                    *ngIf="showReportsLoading">
                    <label for="" class="loader"></label>
                </div>
            </div>


            <div
                style="width: 100%; height: 60px; background-color: #fff;padding: 16px; display: flex; justify-content: space-between; align-items: center; margin-top: 16px;">

                <div style="display: flex; flex-direction: column; justify-content: center; ">
                    <div class="heading" style="margin-top:0px">Reports</div>
                    <div class="subheading" style="width: 100%;">You can upload patient reports</div>
                </div>


                <div>
                    <button (click)="getAllDocumentsOfPatient(0)"
                        style="border: 1px solid #5D86CC;
                                padding: 8px 16px;
                                border-radius: 6px;
                                color: #5D86CC;
                                font-size: 18px;
                                font-weight: bold;
                                background-color: #fff;
                                margin-right: 12px;
                                outline: none;">Refresh</button>

                    <button (click)="openDialog(addUploadModal,'addUploadModal');closeUploadModal(); clickReportDoc()"
                        style="background-color: #5D86CC;
padding: 8px 16px;
border-radius: 6px;
color: #fff;
font-size: 18px;
font-weight: bold;
border: 0px;
margin-right: 20px;
outline: none;">Upload Report</button>

                </div>

            </div>


            <div style="display: flex; flex: 1; justify-content: center;">

                <div style=" width: 80%; justify-content:center;">

                    <div *ngIf="!allReports.length" style="width: 100%;display: flex; flex-direction: column; width: 100%;
                    height: 80vh;
                    display: flex;
                   
                    justify-content: center;
                    align-items: center;">

                        <div style="text-align: center;">
                            <img style="justify-content: center;align-items: center; width: 200px; height: 200px; margin-bottom:0px"
                                src="assets/images/placeholder.svg" alt="" />

                            <div style="font-size: 20px; margin-top: 16px; color: #333333; font-weight: bold;">No
                                Reports</div>
                            <div style="font-size: 18px; color: grey;">No reports found for selected Patient</div>
                        </div>
                    </div>


                    <div *ngFor="let reports of allReports; let i = index">

                        <div class="card" style="align-self: center;padding: 10px; display: flex; ">

                            <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                                <div style="display: flex; flex-direction: row;">
                                    <div class="leftSideTextOnCard">{{reports.documentType}}: </div>
                                </div>
                                <div class="leftSideTextOnCard" style="margin-left: 10px;
                              
                                color: #8C8B8C;"> {{reports.notes}}</div>
                                <div class="rightSideTextOnCard">{{reports.documentDate | date: 'dd/MM/yyyy'}}</div>
                            </div>


                            <div style="display: flex; flex-direction: row; margin-left: 10px; ">
                                <div
                                    style="display: flex; flex-direction: row; width:100%; justify-content: space-between; margin-top:8px;">

                                    <div *ngIf="reports.url" class="leftSideTextOnCard"
                                        (click)="onClickViewReports(reports)" style="color:#4687BF;"> Preview
                                    </div>

                                    <div *ngIf="reports.isLocked" class="leftSideTextOnCard"
                                        (click)="openDialog(unlock_report_confirmation_modal, i); setSelectedReport(reports)"
                                        style="color:#4687BF;"> Unlock for Patient
                                    </div>


                                </div>

                            </div>

                        </div>


                    </div>

                </div>
            </div>

        </div>


        <div *ngIf="innertab == 'Requisitions'">
            <app-patient-requisition [allRequistionTest]="allRequistionTest" [patientResponse]="patientResponse" [doctorObj]="currentDoctorObj"
                [originalSectionWiseRequisitionTests]="originalSectionWiseRequisitionTests"></app-patient-requisition>


        </div>

    </div>

    <!--Referral-->
    <div *ngIf="tab == 'Referral'">
        <app-patient-referral [patientResponse]="patientResponse" [doctorObj]="currentDoctorObj"></app-patient-referral>
    </div>
    <!--Certificate-->
    <div *ngIf="tab == 'Certificate'">
        <app-patient-certificate [patientResponse]="patientResponse" [doctorObj]="currentDoctorObj"
            [patientType]="isPediatric"></app-patient-certificate>
    </div>


    <ng-template #moreInfoModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">More Information</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="width:50vw;">

            <div style="display: flex;flex-direction: column; padding-top: 10px;">

                <mat-form-field appearance="outline">
                    <mat-label>Medical Conditions</mat-label>
                    <input matInput [(ngModel)]="medicalConditions" placeholder="Enter Medical Conditions">
                   
                  </mat-form-field>
                  <mat-chip-list aria-label="Fish selection" [multiple]="true" style="margin:10px 6px;">
                    <mat-chip class="price-filter" style="font-size: 16px; background-color: #E8F7F5;" #ref="matChip"
                        *ngFor="let item of medicalConditionArray;let i=index" (click)='selectmedicalConditions(item);'
                        [value]="item">
                        {{item.name}}
                    </mat-chip>
        
                </mat-chip-list>


                  <mat-form-field appearance="outline">
                    <mat-label>Allergies</mat-label>
                    <input matInput [(ngModel)]="allergies" placeholder="Enter Allergies">
                   
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Recurring Complaints</mat-label>
                    <input matInput [(ngModel)]="recurringComplaints" placeholder="Enter Recurring Complaints">
                   
                  </mat-form-field>

                  <mat-form-field appearance="outline">
                    <mat-label>Accidental / Procedural
                        History</mat-label>
                    <input matInput [(ngModel)]="accidentalHistory" placeholder="Enter Accidental / Procedural
                    History">
                   
                  </mat-form-field>
            </div>
         
            <div style="display: flex;width: 100%;align-items: flex-end;justify-content: flex-end;">
                <button type="submit" class="new_btn"
                    style="  display: flex;text-transform: capitalize;align-items: center;justify-content: center;"
                    (click)="closeDialog('Cross click');saveMoreInfo()">Save</button>
            </div>
        </div>


    </ng-template>

    <ng-template #addVitalsModal let-modal>
        <div style="height:100vh;">


            <app-add-vitals [addVisit]="true" [patientResponse]="patientResponse" [isPediatric]="isPediatric"
                [visitDate]="visitDate"></app-add-vitals>

        </div>

    </ng-template>

    <ng-template #editVitalsModal let-modal>
        <div style="height:100vh;">
            <app-add-vitals [addVisit]="true" [patientResponse]="patientResponse" [isPediatric]="isPediatric"
                [visitDate]="visitDate"></app-add-vitals>
        </div>
    </ng-template>


    <ng-template #dischargeSummeryModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title"></h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div>
                <img width="540" height="540" style="border: solid 1px #ccc;" [src]="patientResponse.discharge_note" />
            </div>
            <button type="submit" class=" opd-primary-btn btn-md w125 newBtnStyle"
                style="margin-left: 70%;margin-top: 5%;text-transform: capitalize;"
                (click)="modal.dismiss('Cross click');">Close</button>
        </div>


    </ng-template>

    <ng-template #addNotesModal let-modal>
        <div class="modal-header">
            <h4 class="modal-title" id="modal-basic-title">Patient Note</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body" style="justify-content: center; display: flex; flex-direction: column;">
            <textarea name="Text1" cols="40" rows="10" class="text-area-style" maxlength="500"
                placeholder="Add a note about patient and keep it precise"
                [(ngModel)]="notes_message">{{notes_message}}</textarea>

            <button type="submit" class=" btn-md w125 newBtnStyle"
                style="align-self: flex-end; margin-top: 5%;text-transform: capitalize; background-color: #4687BF !important; border: 1px solid #4687BF !important;"
                (click)="saveNotes()">Save</button>
        </div>
    </ng-template>



    <ng-template #addUploadModal let-modal>
        <div style="display: flex;flex-direction: column;">
            <div style="font-size: 24px; display: flex;flex-direction: row;justify-content: space-between;
    color: #333;
    width: 400px;
    font-weight: bold;">Upload Documents
                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();closeAddReceiptModal();closeUploadModal()">
                    <span aria-hidden="true" style="font-size: 40px;color: #000;" (click)="closeDialog()">&times;</span>
                </button>
            </div>
            <div style="font-size: 16px;
    color: #798197;
     width: 400px;
    font-weight: bold;">Upload health reports and related documents as photos,images and
                PDFs.</div>

        </div>


        <div style="display: flex;flex-direction: column; margin-top: 50px 0px;">
            <mat-form-field style="width: 400px;">
                <mat-label style="font-size: 15px;">Document Types
                </mat-label>
                <mat-select [value]="dose" [(ngModel)]="selectedDocumentType"
                    (selectionChange)="onChangeDoc($event.value)">
                    <mat-option *ngFor="let docType of documentType" [value]="docType">
                        {{docType}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field style="width: 400px">
                <mat-label style="font-size: 16px;">Created By</mat-label>
                <input matInput [(ngModel)]="notes">
            </mat-form-field>
            <mat-form-field style="width: 400px">
                <mat-label style="font-size: 15px;">Document On</mat-label>
                <input matInput [max]="date" [min]="minDate" [matDatepicker]="picker" [(ngModel)]="createdOnRaw">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>

            </mat-form-field>

        </div>


        <div style="display: flex;flex-direction: column;">
            <div style="font-size: 18px; display: flex;flex-direction: row;justify-content: space-between;
        color: #333;
        width: 100%;
        font-weight: bold;">
                Select Document(Max:10 mb)
            </div>
            <div style="font-size: 16px;
        color: #798197;
        width: 100%;
        font-weight: bold;">Uploaded documents are shared with your doctor</div>

        </div>
        <div class="modal-body modal-lg">
            <!-- <p style="color: #777477;font-size: 14px;">Upload health reports and related documents as photos,images and
            PDFs.</p> -->
            <div>

                <div style="display: flex; justify-content: center;">
                    <div for="fileDropRef" style="margin-left: 5px;">

                        <div class="container" appDnd (fileDropped)="onFileDropped($event)"
                            style="margin-left: 5px;width: 400px;height: 256px;">
                            <input type="file" #fileDropRef id="fileDropRef"
                                (change)="fileBrowseHandler($event.target.files)" />

                            <img src="assets/images/new_upload.svg" alt="" width="90" height="90" />

                            <div style="font-weight: 600; font-size: 16px; color: #717887;">Drag and drop pdf file
                                here(Max,'10'mb)</div>
                            <h3>or</h3>
                            <h6 style="color:red">{{report_message}}</h6>


                            <div style="color: green;">{{fileInfo}}</div>
                            <label class="btnNewStyle" for="fileDropRef">Browse for file</label>
                        </div>

                        <div class="files-list">
                            <div class="single-file" *ngFor="let file of files; let i = index">
                                <div class="file-icon" style="width: 50px">
                                    <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                                        xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58 58"
                                        style="enable-background:new 0 0 58 58;" xml:space="preserve">
                                        <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                                        <g>
                                            <path style="fill:#CEC9AE;"
                                                d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                                            <path style="fill:#CEC9AE;"
                                                d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                                            <path style="fill:#CEC9AE;"
                                                d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                                            <path style="fill:#CEC9AE;"
                                                d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                                            <path style="fill:#CEC9AE;"
                                                d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                                        </g>
                                        <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                                    </svg>
                                </div>
                                <div class="info">
                                    <h4 class="name">
                                        {{ file?.name }}
                                    </h4>
                                    <p class="size">
                                        {{ formatBytes(file?.size) }}
                                    </p>
                                    <app-progress [progress]="file?.progress"></app-progress>
                                </div>

                                <div class="delete" (click)="deleteFile(i)">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                                        <path fill="#B1B1B1" fill-rule="nonzero"
                                            d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                                    </svg>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>

            <!-- <div style="display:flex; flex-direction: row; justify-content: space-between; flex: 1; margin-top: 5px; ">
            <div (click)="editRefferal();modal.dismiss();" class="quickrx" style="margin-left: -1px;"> Cancel</div>
           
             <div (click)=" printNewRefreel('share')" class="quickrx" style="margin-right:-29px">Upload</div>
        </div> -->


            <!-- <button type="submit" class=" opd-primary-btn btn-md w125 newBtnStyle"
            style="margin-left: 78%;margin-top: 0%;text-transform: capitalize;"
            (click)="uploadDocument();">Upload</button> -->

            <div style="display: flex; height: 100%; width: 100%; justify-content: flex-end;">
                <button (click)="uploadDocument();closeDialog();" style="background-color: #5D86CC;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            border: 0px;
            display: flex;
            outline: none;">Upload</button>
            </div>
        </div>


    </ng-template>

    <!--********************DeleteModel*******************-->
    <ng-template #addShareWhatsAppModal let-modal>
        <div class="modal-header" style="border-bottom: 0px;">
            <h4 class="modal-title" id="modal-basic-title">Share Requisition</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="modal.dismiss('Cross click');">
                <span aria-hidden="true" style="font-size: 40px;">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="font-size: 16px;">
                We successfully shared the requisition with the favourite Lab. If want to share it on WhatsApp
            </div>


            <!-- <div *ngIf="requisition.doctorId===currentDoctorObj.id" class="rightSideTextOnCard"(click)="onClickRequisitionDelete(requisition)" style="color: #ff0000;">Delete</div>    -->

        </div>



        <div class="modal-footer">

            <button type="button" style="
        border-color: #6c757d; color:#6c757d; border-radius: 4px;" class="btn btn-outline-secondary"
                (click)="modal.dismiss('addShareWhatsAppModal')">Cancel</button>
            <div (click)="share();modal.dismiss();" class="quickrx" style="font-size: 12px;width: 152px;">
                <img style="height: 25px; margin-left: 0x; width: 20px;"
                    src="assets/images/whatsapp-icon-seeklogo.com.svg" alt="" /><span
                    style="margin-left: 5px;">Share</span>
            </div>
            <!-- <button type="button" style="background-color: hsl(249, 100%, 50%); color: '#fff'; border-radius: 4px;" class="btn"
            (click)="share()">Share</button> -->


        </div>
    </ng-template>



    <ng-template #addDeleteModal>
        <h4>Delete Medicine?</h4>
        <div style="color: #555; font-size: 16px;"> Are you sure you want to delete the selected records?</div>
        <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
            <button (click)="closeDialog()" style="background-color: #eee;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 4px;
                  color: #444;
                  outline: none;">Cancel</button>

            <button (click)="onClickRequisitionDelete();closeDialog()" style="background-color: #f44336;
              padding: 8px 16px;
              border-radius: 6px;
              color: #fff;
              font-weight: bold;
              border: 0px;
              margin: 4px;
            outline: none;">Delete</button>
        </div>
    </ng-template>



    <ng-template #link_patient_modal let-modal>
        <div class="modal-header" style="border-bottom: 0px;padding-bottom: 80px;">

            <div style="display: flex;flex-direction: row;">

                <h4 class="modal-title" id="modal-basic-title">Link Patient

                    <button type="button" class="close" style="outline: none; border-width: 0px;padding-top: 25px;"
                        aria-label="Close" (click)="closeDialog('Cross click')">
                        <span aria-hidden="true">&times;</span>
                    </button>
                    <p style="color: #798197;font-size: 16px;">Enter doctor complete mobile number</p>
                    <div class="inventory-outer  pl-1 fs-18 mb-0 pb-0" style="padding-top: 20px;">
                        <!-- <div class="todaysAppointments headingUpprcase18 newInputStyleLabel" style="text-transform: capitalize;"
            *ngIf="recentlyAuthorizedPatients.length > 0">My Patients</div> -->
                        <!-- <div class="todaysAppointments notFound border-0"
              *ngIf="recentlyAuthorizedPatients && recentlyAuthorizedPatients.length == 0">No patient found</div> -->
                        <div class="appointment float-right searchIcon active"
                            style="width: 100%; margin-right: 5px; margin-left: 5px; border-radius: 4px; border-color: #cccc; background-color: #ffffff;">
                            <div class="form-group searchField pull-left"
                                style="width: 85%; background-color: #ffffff;">
                                <input type="text" placeholder="Doctor's Full Mob No" class="form-control" #searchInput
                                    [(ngModel)]="searchText" (keyup)="searchDoctors()"
                                    (keypress)="validateNumber($event)" [maxLength]='10'
                                    style="font-size: 15px; border-radius: 4px !important; border-right: 0px solid #000 !important; padding: 8px !important; background-color: #ffffff !important;" />
                                <!-- <a (click)="closeSearch()"><i class="fa fa-times-circle" aria-hidden="true"></i></a> -->
                                <a type="button" (click)="modal.dismiss();" style="padding-left: 6px;
              padding-right: 6px;
              font-size: 34px; top: -4px;">×</a>
                            </div>

                            <div class="searchbtn" style="width: 15%;">
                                <button (click)="searchDoctors()"><img src="assets/images/searchIcon.svg"
                                        alt="" /></button>
                            </div>


                        </div>

                    </div>

                </h4>

            </div>

        </div>
        <div class="modal-body ">

            <div style="margin-top: -45px;">
                <div *ngIf="selectedDoctor"
                    style="font-size: 18px; font-weight: bold; color: #333; display: flex;flex-direction: row; justify-content: center;">
                    <figure>

                        <img width="40" height="40" style="border: solid 1px #ccc;border-radius: 81px;"
                            src="assets/images/profile_placeholder.png" alt="" class="navbar-avatar rounded-circle" />
                    </figure>
                </div>
                <div style="display: flex;flex-direction: row; justify-content: center;">

                    <div style="color: black;font-weight: bold; margin-top: 2px;">{{docName}}</div>
                </div>
                <div style="display:flex;justify-content: center; color: #8B8B8B;margin-top: 2px;">{{docEmail}}</div>
            </div>
            <div *ngIf="selectedDoctor"
                style="display: flex; justify-content: center;padding-top: 5px;padding-bottom: 40px;">
                <div *ngIf="selectedDoctor" (click)="
        linkDoctorPatient();closeDialog();" class="new_btn">Link</div>
            </div>
            <div *ngIf="!selectedDoctor">
                <div style="text-align: center;padding-bottom: 25px;">
                    <img style="justify-content: center;align-items: center; width: 120px;"
                        src="assets/images/placeholder.svg" alt="" />

                    <div style="font-size: 20px; color: #333333; font-weight: bold;">No doctor found</div>
                    <div style="font-size: 18px; color: grey;">Try to enter complete mobile number.</div>
                </div>
            </div>
        </div>

    </ng-template>


    <ng-template #addDeletePatient let-modal>
        <div class="modal-header" style="border-bottom: 0px;">
            <h4 class="modal-title" id="modal-basic-title">Delete Patient</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog('Cross click');">
                <span aria-hidden="true">&times;</span>
            </button>

        </div>
        <div class="modal-title">
            <div style="font-size: 16px;">
                Are you sure you want to delete the selected Patient?
            </div>


            <!-- <div *ngIf="requisition.doctorId===currentDoctorObj.id" class="rightSideTextOnCard"(click)="onClickRequisitionDelete(requisition)" style="color: #ff0000;">Delete</div>    -->

        </div>



        <div class="modal-footer">

            <button type="button" style="
        border-color: #6c757d; color:#6c757d; border-radius: 4px;" class="btn btn-outline-secondary"
                (click)="closeDialog('addDeletePatient ')">Cancel</button>
            <button type="button" style="background-color: #ff0000; color: '#fff'; border-radius: 4px;"
                class="new_delete_btn" (click)=" onClickDeletePatient();closeDialog('addDeletePatient')">Delete</button>


        </div>
    </ng-template>

    <!---------------addUploadFileModal-->

    <ng-template #addUploadFileModal let-modal>
        <div class="modal-header" style="border-bottom: 0px;">
            <h4 class="modal-title" id="modal-basic-title">Upload Reports</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="modal.dismiss('Cross click');">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div class="container" appDnd (fileDropped)="onFileDropped($event)">
                <input type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" />

                <img src="assets/images/upload-to-cloud.svg" alt="" width="63" height="64" />

                <h3 style="font-weight: 500; font-size: 16px;">Drag and drop pdf file here(Max,'10'mb)</h3>
                <h3>or</h3>
                <h6 style="color:red">{{report_message}}</h6>
                <label for="fileDropRef">Browse for file</label>
            </div>
            <div class="files-list">
                <div class="single-file" *ngFor="let file of files; let i = index">
                    <div class="file-icon" style="width: 50px">
                        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg"
                            xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 58 58"
                            style="enable-background:new 0 0 58 58;" xml:space="preserve">
                            <polygon style="fill:#EDEADA;" points="51.5,14 37.5,0 6.5,0 6.5,58 51.5,58 " />
                            <g>
                                <path style="fill:#CEC9AE;"
                                    d="M16.5,23h25c0.552,0,1-0.447,1-1s-0.448-1-1-1h-25c-0.552,0-1,0.447-1,1S15.948,23,16.5,23z" />
                                <path style="fill:#CEC9AE;"
                                    d="M16.5,15h10c0.552,0,1-0.447,1-1s-0.448-1-1-1h-10c-0.552,0-1,0.447-1,1S15.948,15,16.5,15z" />
                                <path style="fill:#CEC9AE;"
                                    d="M41.5,29h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,29,41.5,29z" />
                                <path style="fill:#CEC9AE;"
                                    d="M41.5,37h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,37,41.5,37z" />
                                <path style="fill:#CEC9AE;"
                                    d="M41.5,45h-25c-0.552,0-1,0.447-1,1s0.448,1,1,1h25c0.552,0,1-0.447,1-1S42.052,45,41.5,45z" />
                            </g>
                            <polygon style="fill:#CEC9AE;" points="37.5,0 37.5,14 51.5,14 " />
                        </svg>
                    </div>
                    <div class="info">
                        <h4 class="name">
                            {{ file?.name }}
                        </h4>
                        <p class="size">
                            {{ formatBytes(file?.size) }}
                        </p>
                        <app-progress [progress]="file?.progress"></app-progress>
                    </div>

                    <div class="delete" (click)="deleteFile(i)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
                            <path fill="#B1B1B1" fill-rule="nonzero"
                                d="M1 16c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2H3c-1.1 0-2 .9-2 2v10zm3.17-7.83a.996.996 0 0 1 1.41 0L7 9.59l1.42-1.42a.996.996 0 1 1 1.41 1.41L8.41 11l1.42 1.42a.996.996 0 1 1-1.41 1.41L7 12.41l-1.42 1.42a.996.996 0 1 1-1.41-1.41L5.59 11 4.17 9.58a.996.996 0 0 1 0-1.41zM10.5 1L9.79.29C9.61.11 9.35 0 9.09 0H4.91c-.26 0-.52.11-.7.29L3.5 1H1c-.55 0-1 .45-1 1s.45 1 1 1h12c.55 0 1-.45 1-1s-.45-1-1-1h-2.5z" />
                        </svg>
                    </div>
                </div>
            </div>

        </div>

    </ng-template>

    <!--------***********************viewReceiptModal***************************-->
    <ng-template #viewReceiptModal let-modal>
        <div>
            <div class="modal-header" style="border-bottom: none;">
                <h4 class="modal-title" id="modal-basic-title">View receipt</h4>
                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();closeAddReceiptModal()">
                    <span aria-hidden="true" style="font-size: 40px; color:#000;">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="display: flex; height: 70vh; margin-top: 0px; width: 70vw;">
                    <embed [attr.src]="receiptPdfURL" type="application/pdf" width="100%" height="100%">
                </div>
                <div style="display: flex;justify-content: flex-end; margin-top: 10px;">
                    <button type="submit" class=" opd-primary-btn btn-md w125 newBtnStyle"
                        style="text-transform: capitalize;"
                        (click)="onClickBillShare(receiptURL);closeAddReceiptModal();closeDialog();">Share
                        Receipt</button>
                </div>


            </div>

        </div>
    </ng-template>


    <!--***********************viewReportsModal*********-->
    <ng-template #viewReportsModal let-modal>
        <div>
            <div class="modal-header" style="border-bottom: none;">
                <h4 class="modal-title" id="modal-basic-title">View Report</h4>
                <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                    (click)="closeDialog();">
                    <span aria-hidden="true" style="font-size: 40px;color: #000;">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div style="display: flex; height: 70vh; margin-top: 0px; width: 70vw;">
                    <embed [attr.src]="reportsPdfURL" type="application/pdf" width="100%" height="100%">
                </div>


            </div>

        </div>
    </ng-template>

    <ng-template #viewHeelReportsModal let-modal>
        <div>
            <div class="modal-header" style="border-bottom: none;">
                <div style="display:flex; flex-direction: row; justify-content: space-between; flex: 1;">
                    <h4 class="modal-title" id="modal-basic-title">View Report</h4>
                    <div style="display: flex;flex-direction: row;">

                        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                            (click)="closeDialog();">
                            <span aria-hidden="true" style="font-size: 40px;color: #000;">&times;</span>
                        </button>
                    </div>
                </div>
            </div>
            <div class="modal-body">
                <div style="display: flex; height: 70vh; margin-top: 0px;width: 70vw;">
                    <embed [attr.src]="heelreportsPdfURL" type="application/pdf" width="100%" height="100%">
                </div>
                <div style="display: flex;flex-direction: row;justify-content: flex-end; flex: 1; margin-top: 10px;">
                    <div (click)="shareWithOtherHeelPrick(); closeDialog();" class="quickrx">
                        <img style="height: 25px; margin-left: 0x; width: 20px;"
                            src="assets/images/whatsapp-icon-seeklogo.com.svg" alt="" /><span
                            style="margin-left: 5px;">Share With Other</span>
                    </div>
                </div>


            </div>

        </div>
    </ng-template>

    <!--************** SHARE REQUISTION MODAL-->


    <!--**************viewRequisiontionModal-->


    <ng-template #discharge_Summary let-modal>
        <div class="modal-header" style="border-bottom: 0px;">
            <h4 class="modal-title" id="modal-basic-title">View Summary</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="closeDialog();">
                <span style="margin-right: 6px; font-size: 40px;color: #000;" aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="width: 98%;
        padding-left: 5px;
        margin-top: 14px;
        margin-bottom: 4px;
        margin-left: 8px;">
                <div class="row">
                    <div style="width: 477px;">


                        <div *ngFor="let reports of allSummary; let j = index"
                            style="border: 1px solid #e9eaea;background-color: #ffffff;height: 40px; display:flex;flex-direction: row;cursor: pointer;"
                            (click)="onClickViewReports(reports)">
                            <div style="padding: 7px;color: #333333;font-size: 16px;width: 50%;">
                                {{reports.documentType}}
                            </div>
                            <div style="display:flex;flex-direction: row;width: 50%;">
                                <div
                                    style="font-size: 14px; align-self: center; justify-content:flex-end; text-align:right; color:#808080;width: 60%;">
                                    {{reports.documentDate | date: 'dd/MM/yyyy'}}
                                </div>

                                <a>
                                    <span>
                                        <i style="color: #04C5BE;margin-left: 10px;font-size: 25px; margin-top: 5px;"
                                            class="fa fa-angle-right fa-4"></i></span></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal-footer">

        </div>
    </ng-template>


    <ng-template #heel_Summary let-modal>
        <div class="modal-header" style="border-bottom: 0px;">
            <h4 class="modal-title" id="modal-basic-title">View Summary</h4>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="width: 98%;
        padding-left: 5px;
        margin-top: 14px;
        margin-bottom: 4px;
        margin-left: 8px;">
                <div class="row">
                    <div style="width: 477px;">


                        <div *ngFor="let reports of allHeelPrickTest; let j = index"
                            style="border: 1px solid #e9eaea;background-color: #ffffff;height: 40px; display:flex;flex-direction: row;cursor: pointer;"
                            (click)="onClickViewReports(reports)">
                            <div style="padding: 7px;color: #333333;font-size: 16px;width: 50%;">
                                {{reports.documentType}}
                            </div>
                            <div style="display:flex;flex-direction: row;width: 50%;">
                                <div
                                    style="font-size: 14px; align-self: center; justify-content:flex-end; text-align:right; color:#808080;width: 60%;">
                                    {{reports.documentDate | date: 'dd/MM/yyyy'}}
                                </div>

                                <a>
                                    <span>
                                        <i style="color: #04C5BE;margin-left: 10px;font-size: 25px; margin-top: 5px;"
                                            class="fa fa-angle-right fa-4"></i></span></a>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
        <div class="modal-footer">

        </div>
    </ng-template>


    <ng-template #highRiskModal let-modal>
        <div class="modal-header" style="border-bottom: 0px;">
            <h5 class="modal-title" id="modal-basic-title" style="display: flex;flex-direction: row; color: #333333;">
                High-Risk Category:



            </h5>
            <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
                (click)="modal.dismiss('Cross click')">
                <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
            </button>
        </div>
        <div class="modal-body">
            <div style="width: 98%;
        padding-left: 5px;
        margin-top: 14px;
        margin-bottom: 4px;
        margin-left: 8px;">
                <div class="row" *ngFor="let item of highRiskCategory ">

                    -{{item}}

                </div>
            </div>
        </div>

        <div style="display: flex;flex-direction: row;justify-content:flex-end;padding-bottom: 10px;">
            <div (click)="modal.dismiss();" class="btn-type-filled">Ok</div>

        </div>


    </ng-template>


    <!-- <p-toast position="center"  (onClose)="onReject()" [baseZIndex]="5000">
    </p-toast> -->
    <p-toast position="top-right" [baseZIndex]="5000">
        <ng-template let-message pTemplate="message">
            <div class=" p-flex p-flex-column"
                style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;">
                <div>
                    <img (click)="goBack()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
                        src="assets/images/exclamation-circle-blue.svg" alt="" />
                </div>
                <div class=" p-text-center">
                    <div style="font-size: 18px;
                    font-weight: bold;
                    padding: 12px 0px 0px 12px;
                    
                    color: #034A70;">{{message.summary}}</div>
                    <div
                        style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
                        {{message.detail}}</div>
                </div>
            </div>
        </ng-template>
    </p-toast>

    <ng-template #unlock_report_confirmation_modal>
        <h4>Unlock Report for Patient?</h4>
        <div style="color: #555; font-size: 16px;"> When you unlock report, patient will have access to see this report
            in Health App.</div>
        <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
            <button (click)="closeDialog()" style="background-color: #eee;
                      padding: 8px 16px;
                      border-radius: 6px;
                      color: #fff;
                      font-weight: bold;
                      border: 0px;
                      margin: 4px;
                      color: #444;
                      outline: none;">Cancel</button>

            <button (click)="unlockReportForPatient(selectedReport, i); closeDialog()" style="background-color: #034A70;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 4px;
                outline: none;">Yes, Unlock for Patient</button>
        </div>
    </ng-template>