<div class=" col-lg-12">
  <div class="dashboard-content">
    <div class="user-management-data">

      <div class="row mt-2">






        <div class="col-xl-10 col-lg-10 d-flex justify-content-end align-items-top" style="margin-bottom: 15px;">
          <button type="button" class="btn-primary opd-secondary-btn btn-sm" (click)="getOTPs()">refresh to see new
            otp</button>
        </div>
      </div>

      <table id="dtVerticalScrollExample" class="table  table-fixed">
        <thead>
          <tr class="col-12 pl-0 pr-0">

            <th class="col-2 d-flex justify-content-start align-items-center">
              Phone Number
              <div class="topDown">

              </div>
            </th>
            <th class="col-2 d-flex justify-content-start align-items-center">OTP

            </th>
            <th class="col-3 d-flex justify-content-start align-items-center">
              Action

            </th>

            <th class="col-2 d-flex justify-content-start align-items-center">Date & Time
              <div class="topDown">

              </div>
            </th>



          </tr>
        </thead>
        <tbody *ngIf="!isLoading" (scroll)="loadActivities($event)">
          <tr *ngIf="otpList.length === 0" class="notFound">
            <td class="noRecords" colspan="3">No records found</td>
          </tr>
          <tr class="col-12 pl-0 pr-0" *ngFor="let activity of otpList; let i = index">

            <td class="col-2">{{activity.phonenumber}}</td>
            <td class="col-2">{{activity.OTP}}</td>

            <td class="col-3 capitalize">{{activity.action}}</td>
            <td class="col-2">{{activity.createdAt |  date: 'dd/MM/yyyy hh:mm a'}}</td>

          </tr>
        </tbody>

      </table>
      <!-- <div class="col-12 d-flex justify-content-end align-items-center pr-0 font12">Displaying {{totalRecords}} records
      </div> -->
    </div>

  </div>
</div>
<p-confirmDialog appendTo="body"></p-confirmDialog>