import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

import { MenuItems, AdminMenuItems,LabMenuItems } from './menu-items/menu-items';
import { AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective } from './accordion';
import { ToggleFullscreenDirective } from './fullscreen/toggle-fullscreen.directive';
import { HomePageComponent } from './home-page/home-page.component';
import { FormsModule } from '@angular/forms';


@NgModule({
  declarations: [AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective, ToggleFullscreenDirective, HomePageComponent],
  exports: [AccordionAnchorDirective, AccordionLinkDirective, AccordionDirective, ToggleFullscreenDirective, HomePageComponent],
  imports: [RouterModule, CommonModule, FormsModule],
  providers: [MenuItems, AdminMenuItems,LabMenuItems]
})
export class SharedModuleApp { }
