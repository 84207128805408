<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
  <label for="" class="loader"></label>
</div>
<ng-sidebar-container class="app opd-main-wrapper"
  [ngClass]="{'mode-boxed': isBoxed, 'sidebar-opened': isOpened, 'theme-light': theme == 'light', 'theme-dark': theme == 'dark', 'mode-push': _mode == 'push', 'mode-dock': _mode == 'dock', 'mode-over': _mode == 'over', 'mode-slide': _mode == 'slide', 'no-footer': options?.removeFooter, 'map-header': options?.mapHeader}"
  style="background-color: #fff; ">
  <ng-sidebar [(opened)]="isOpened" [(mode)]="_mode" [position]="'left'" [dockedSize]="'80px'"
    [autoCollapseWidth]="'991'" [closeOnClickOutside]="isOver()" [showBackdrop]="isOver()"
    [sidebarClass]="'sidebar-panel'" #sidebar>
    <nav
      style="background-color: #fff; font-size: 14px; text-transform: uppercase; display: flex; justify-content: center; align-items: center; padding: 12px;">
      <a [routerLink]="['/doctor/homepage']" title="home">
        <img _ngcontent-c0="" alt="" width="75px" src="assets/images/new_logo.svg">
        <!-- <span style="color: #fff;">
          copious </span> -->
        <!-- <view-all-cases-popup myNum="5" [master]="master" ></view-all-cases-popup> -->
      </a>

      <!-- <ul class="navbar-nav">
        <li class="nav-item">
          <a href="javascript:;" class="nav-link" (click)="toogleSidebar()">
            <i class="hamburger-icon v2">
              <span></span>
            </i>
          </a>
        </li>
      </ul> -->
    </nav>
    <div style="display:flex;justify-content: center; width: 100%;">V2.7</div>
    <div (click)="openDialog(whatsNew,0)" style="display:flex;justify-content: center; width: 100%; color: #4a76b8; cursor: pointer; font-weight: 700; text-decoration: underline; font-size: 16px;">What's New</div>

    <!-- <div class="profile-image">
      <figure>
          <img class="rounded-circle" (click)="showDialog()"[src]="data.image" [width]="cropperSettings.croppedWidth" (click)="showDialog()" [height]="cropperSettings.croppedHeight">
          <input type="file" style="display:none" name="profile_pic" #fileInput type="file" (change)="fileChangeListener($event)" accept="image/*"  />
      </figure>
    </div> -->
    <div *ngIf="path !== 'signup' && path != 'thankyou'"
      style="height: 20%; width: 100%; background-color: #fff;display: flex;justify-content: center; align-items: center; flex-direction: column;">

      <!-- <figure> -->
      <a href="javascript:;" class="nav-link clearfix">
        <img (click)="openDialog(addImageModal, 0 )" [src]="profileImage" class="navbar-avatar rounded-circle"
          alt="user" title="user" [width]="cropperSettings.croppedWidth" [height]="cropperSettings.croppedHeight"
          style="height: 75px; width: 75px; border: 0.5px solid #ccc; border-radius: 12px !important;">
        <input type="file" style="display:none" name="profile_pic" #fileInput type="file"
          (change)="fileChangeListener($event)" />

      </a>
     
      <div style="font-weight: bold; font-size: 16px; color: #333; padding: 8px;">Dr. {{userDetails.first_name}}</div>

      <!-- </figure> -->


      <!-- <ul>
        <li class="nav-item" ngbDropdown placement="bottom-right">
          <a href="javascript:;" class="nav-link clearfix" ngbDropdownToggle>
            <figure>
              <img [src]="profileImage" class="navbar-avatar rounded-circle" alt="user" title="user"
                [width]="cropperSettings.croppedWidth" [height]="cropperSettings.croppedHeight">
              <input type="file" style="display:none" name="profile_pic" #fileInput type="file"
                (change)="fileChangeListener($event)" />
            </figure> -->

      <!-- <span>Dr. {{userDetails.first_name}}</span> -->

      <!-- </a>
          <div class="changeProPic" (click)="showDialog()">
            <div class="proPic"></div>
            <a>Change profile picture</a>
          </div>
        </li>
      </ul> -->

    </div>




    <!-- main navigation -->
    <nav
      style="background-color: #fff; font-size: 14px; text-transform: uppercase; display: flex; justify-content: center; align-items: center; padding: 12px; border-right: 1px solid #eee;"
      class="menu" *ngIf="path != 'signup' && path != 'thankyou'"
      style="background-color: #fff; border-right: 1px solid #eee; margin-right: 0px; padding-right: 0px;">
      <ul class="navigation" appAccordion>

        <li class="navigation-item" appAccordionLink *ngFor="let menuitem of menuItems.getAll()"
          group="{{menuitem.state}}">
          <a class="navigation-link" routerLinkActive="active"
            (click)="otpSection = false; removeCase();  removeData();" [routerLink]="['/', 'doctor', menuitem.state]"
            *ngIf="menuitem.type === 'link'" style="color: #000;">
            <div>
              <div>OK</div>
              <!-- <div>{{menuitem.name}}</div> -->
            </div>
            <!-- <span>{{ menuitem.name }}</span>
            <span class="mr-auto"></span>
            <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span> -->
          </a>

          <!-- HOME & VIEW RX -->
          <a class="navigation-link" routerLinkActive="active"
            (click)="otpSection = false; removeCase(); removeData(menuitem.name=='Home' ? 0 : 1);" appAccordionToggle
            [routerLink]="['/','doctor',menuitem.state]" *ngIf="menuitem.type === 'extLink'"
            style="height: 50px !important; border-radius: 6px;"
            [ngStyle]="{'background-color' : selectedIndex == 0 && menuitem.name=='Home' ? '#E2ECFB' : selectedIndex == 1 && menuitem.name=='View Rx' ? '#E2ECFB' : 'transparent'}">

            <div style="flex-direction: row; display: flex; align-self: center;">

              <img *ngIf="menuitem.name=='Home' && selectedIndex == 0" src="assets/images/home_leftbar.svg"
                class="navbar-avatar rounded-circle" alt="user" title="user">
              <img *ngIf="menuitem.name=='Home' && selectedIndex != 0" src="assets/images/home_leftbar_off.svg"
                class="navbar-avatar rounded-circle" alt="user" title="user">

              <img *ngIf="menuitem.name=='View Rx'  && selectedIndex == 1" src="assets/images/leftbar_prescription.svg"
                class="navbar-avatar rounded-circle" alt="user" title="user">
              <img *ngIf="menuitem.name=='View Rx' && selectedIndex != 1"
                src="assets/images/leftbar_prescription_off.svg" class="navbar-avatar rounded-circle" alt="user"
                title="user">
                <!-- <img *ngIf="menuitem.name=='Favourite'  && selectedIndex == 4" src="assets/images/favourite_heart_red.svg"
                class="navbar-avatar rounded-circle" alt="user" title="user">
              <img *ngIf="menuitem.name=='Favourite' && selectedIndex != 4"
                src="assets/images/favourite_heart_red.svg" class="navbar-avatar rounded-circle" alt="user"
                title="user"> -->

              <!-- [ngStyle]="{'color' : selectedIndex == 0  ? 'red' : '#ccc'}" -->
              <div style="margin-left: 6px; font-size: 18px;"
                [ngStyle]="{'color' : selectedIndex == 0 && menuitem.name=='Home' ? '#4686be'  : selectedIndex == 1 && menuitem.name=='View Rx' ? '#4686be' : '#8B8B99'}">
                {{menuitem.name}}</div>

                
            </div>
            <!-- <span>{{ menuitem.name }}</span> -->
            <span class="mr-auto"></span>
            <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>

        

          <!-- PROFILE -->
          <a class="navigation-link" (click)="otpSection = false; removeCase(); removeData(2);" appAccordionToggle
            [ngClass]="{'active': currentUrl.indexOf('profileView') != -1 || currentUrl.indexOf('editProfile') != -1}"
            [routerLink]="['/','doctor',menuitem.state]" *ngIf="menuitem.type === 'profile'"
            [ngStyle]="{'background-color' : selectedIndex == 2 ? '#E2ECFB' :'transparent'}"
            style="height: 50px !important; border-radius: 6px;">
            <div style="flex-direction: row; display: flex;  align-self: center;">

              <img *ngIf="selectedIndex != 2" src="assets/images/profile_leftbar.svg"
                class="navbar-avatar rounded-circle svg" alt="user" title="user">
              <img *ngIf="selectedIndex == 2" src="assets/images/profile_selected_leftbar.svg"
                class="navbar-avatar rounded-circle svg" alt="user" title="user">
              <div style="margin-left: 6px; font-size: 18px"
                [ngStyle]="{'color' : selectedIndex == 2 ? '#4686be'  :'#8B8B99'}">
                {{menuitem.name}}</div>
              <!-- <span>{{ menuitem.name }}</span>
              <span class="mr-auto"></span>
              <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span> -->
            </div>
          </a>

          <a class="navigation-link" routerLinkActive="active" (click)="otpSection = false; removeCase(); removeData();"
            appAccordionToggle [routerLink]="['/','doctor',menuitem.state]" target="_blank"
            *ngIf="menuitem.type === 'extTabLink'">
            <span>{{ menuitem.name }}</span>
            <span class="mr-auto"></span>
            <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
          </a>

          <a (click)="removeCase(); removeData();" class="navigation-link" routerLinkActive="active"
            (click)="otpSection = false" appAccordionToggle [routerLink]="['/', 'doctor', menuitem.state]"
            *ngIf="menuitem.type === 'sub' && menuitem.for == 'patient'">

            <span>{{ menuitem.name }}</span>
            <span class="mr-auto"></span>
            <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span>
            <i class="menu-caret fa fa-caret-right" *ngIf="patientId"></i>
          </a>

          <ul class="navigation-submenu" *ngIf="menuitem.type === 'sub' && menuitem.for == 'patient' && patientId">
            <li class="navigation-item" *ngFor="let childitem of menuitem.children" routerLinkActive="open">
              <a (click)="otpSection = false; removeData();"
                [routerLink]="['/', 'doctor' ,  menuitem.state, childitem.state, 'view' ]"
                class="navigation-link relative" [ngClass]="{'active': currentUrl.indexOf(childitem.state) != -1}">{{
                childitem.name }}</a>
            </li>
          </ul>


          <!-- REPORTS -->
          <a class="navigation-link" routerLinkActive="active" appAccordionToggle
            *ngIf="menuitem.type === 'sub'  && menuitem.for != 'patient'"
            style="height: 50px !important; border-radius: 6px;" (click)="removeData(3)"
            [ngStyle]="{'background-color' : selectedIndex == 3 ? '#E2ECFB' :'transparent'}">
            <div style="flex-direction: row; display: flex;  align-self: center;">
              <!-- <img src="assets/images/new_documents_topbar_off.svg" class="navbar-avatar rounded-circle" alt="user"
                title="user"> -->

              <img *ngIf="selectedIndex == 3" src="assets/images/new_documents_topbar.svg"
                class="navbar-avatar rounded-circle svg" alt="user" title="user">
              <img *ngIf="selectedIndex != 3" src="assets/images/new_documents_topbar_off.svg"
                class="navbar-avatar rounded-circle svg" alt="user" title="user">
              <div style="margin-left: 6px; font-size: 18px;"
                [ngStyle]="{'color' : selectedIndex == 3 ? '#4686be'  :'#8B8B99'}">{{menuitem.name}}</div>
            </div>

            <!-- <span>{{ menuitem.name }}</span>
            <span class="mr-auto"></span>
            <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span> -->
            <i class="menu-caret fa fa-caret-right"></i>
          </a>

          

          <ul class="navigation-submenu" *ngIf="menuitem.type === 'sub' && menuitem.for != 'patient'"
            style="margin-left: 22px;">
            <li class="navigation-item" *ngFor="let childitem of menuitem.children" routerLinkActive="open">
              <a (click)="otpSection = false; removeCase(); removeData();"
                [routerLink]="['/', 'doctor' ,  menuitem.state, childitem.state ]" class="navigation-link relative"
                routerLinkActive="active">{{ childitem.name }}</a>
            </li>
          </ul>

          <a class="navigation-link" (click)="otpSection = false; removeCase(); removeData(4);" appAccordionToggle
          [ngClass]="{'active': currentUrl.indexOf('favouriteManagement') != -1 || currentUrl.indexOf('editProfile') != -1}"
          [routerLink]="['/','doctor',menuitem.state]" *ngIf="menuitem.type === 'Favourite'"
          [ngStyle]="{'background-color' : selectedIndex == 4 ? '#E2ECFB' :'transparent'}"
          style="height: 50px !important; border-radius: 6px; margin-top: -4px;">
          <div style="flex-direction: row; display: flex;  align-self: center;">

            <img *ngIf="selectedIndex != 4" src="assets/images/favourite_grayButton.svg"
              class="navbar-avatar rounded-circle svg" alt="user" title="user" style="width: 22px;">
            <img *ngIf="selectedIndex == 4" src="assets/images/favourite_blueButton.svg"
              class="navbar-avatar rounded-circle svg" alt="user" title="user" style="width: 22px;">
            <div style="margin-left: 6px; font-size: 18px"
              [ngStyle]="{'color' : selectedIndex == 4 ? '#4686be'  :'#8B8B99'}">
              {{menuitem.name}}</div>
            <!-- <span>{{ menuitem.name }}</span>
            <span class="mr-auto"></span>
            <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span> -->
          </div>
        </a>
        <a class="navigation-link" (click)="otpSection = false; removeCase(); removeData(5);" appAccordionToggle
        [ngClass]="{'active': currentUrl.indexOf('vaccineInventory') != -1 || currentUrl.indexOf('editProfile') != -1}"
        [routerLink]="['/','doctor',menuitem.state]" *ngIf="menuitem.type === 'Inventory'"
        [ngStyle]="{'background-color' : selectedIndex == 5 ? '#E2ECFB' :'transparent'}"
        style="height: 50px !important; border-radius: 6px; margin-top: -4px;">
        <div style="flex-direction: row; display: flex;  align-self: center;">
          <mat-icon *ngIf="selectedIndex != 5" [ngClass]="'grayIcon'">
            inventory
        </mat-icon>
          <!-- <img *ngIf="selectedIndex != 5" src="assets/images/favourite_grayButton.svg"
            class="navbar-avatar rounded-circle svg" alt="user" title="user" style="width: 22px;"> -->
            <mat-icon *ngIf="selectedIndex == 5" [ngClass]="'blueIcon'">
              inventory
          </mat-icon>
          <!-- <img *ngIf="selectedIndex == 5" src="assets/images/favourite_blueButton.svg"
            class="navbar-avatar rounded-circle svg" alt="user" title="user" style="width: 22px;"> -->
          <div style="margin-left: 6px; font-size: 18px"
            [ngStyle]="{'color' : selectedIndex == 5 ? '#4686be'  :'#8B8B99'}">
            {{menuitem.name}}</div>
          <!-- <span>{{ menuitem.name }}</span>
          <span class="mr-auto"></span>
          <span class="badge badge-{{ badge.type }}" *ngFor="let badge of menuitem.badge">{{ badge.value }}</span> -->
        </div>
      </a>
        </li>
        <li class="navigation-item">
        </li>

      </ul>

      <!-- SHARE APP LINK -->
      <ul class="navigation clearfix">
        <li (click)="openDialog(share_Link_App);">
          <div
            style="flex-direction: row; display: flex;  padding: 16px; margin: 8px;">
           
              <img src="assets/images/share_link.svg" class="navbar-avatar rounded-circle svg" alt="user"
                title="user">

              <a style="margin-left: 6px; font-weight: bold; font-size: 18px;" [ngStyle]="{'color' :'#8B8B99'}">Share</a>
          </div>


        </li>
      </ul>


    </nav>
 <!-- LOGOUT -->

    <!-- <nav class="menu botttomMenu" *ngIf="path !== 'signup'  && path != 'thankyou'" style="background-color: #fff; ">

     
      <a class="navigation-link" (click)="logout()" style="height: 50px !important">
        <div style="flex-direction: row; display: flex;  align-self: center;">

          <img src="assets/images/logout.svg" class="navbar-avatar rounded-circle svg" alt="user" title="user">

          <div style="margin-left: 6px; font-size: 18px" [ngStyle]="{'color' : false ? '#4686be'  :'#8B8B99'}">
            Logout</div>
        </div>
      </a>
    </nav> -->
 <!-- LOGOUT -->
    <!-- <nav class="menu botttomMenu" *ngIf="path !== 'signup'  && path != 'thankyou'" style="background-color: #fff; ">

     
      <a class="navigation-link" (click)="logout()" style="height: 50px !important">
        <div style="flex-direction: row; display: flex;  align-self: center;">

          <img src="assets/images/logout.svg" class="navbar-avatar rounded-circle svg" alt="user" title="user">

          <div style="margin-left: 6px; font-size: 18px" [ngStyle]="{'color' : false ? '#4686be'  :'#8B8B99'}">
            Logout</div>
        </div>
      </a>



    </nav> -->
    <!-- /main navigation -->
  </ng-sidebar>

  <div ng-sidebar-content class="app-inner">
    <!-- old header -->
    <!-- <nav class="navbar custom-navbar bg-faded main-header"> -->
    <!-- <nav style="height: 5px;background-color:#195BA9;"> -->
    <nav style="height: 0px;">
      <ul class="navbar-nav">
        <li class="nav-item">
          <span class="topHeading" [innerHtml]="topHeading"></span>
          <!--<a href="javascript:;" class="nav-link" (click)="toogleSidebar()">
            <i class="hamburger-icon v2">
              <span></span>
            </i>

          </a>-->
        </li>
      </ul>
      <span class="navbar-heading hidden-xs-down">{{options?.heading}}</span>
      <span class="mr-auto"></span>
      <ul class="addNewSearchSection clearfix" *ngIf="path != 'signup'">

        <!--<li class="addLable li" *ngIf="patientId && path != 'patients'" >
         <div>
           <a class="mr-1 ml-1">Case ID: <span>C{{caseIdheader}}</span></a>
         </div>
       </li>
        <li class="pageNav li" *ngIf="patientId && path != 'patients'">
          <div ngbDropdown class="d-inline-block">
            <button class="btn btn-navigation" id="dropdownBasic1" ngbDropdownToggle *ngIf="selectedIndex == null">Select Visit<i class="menu-caret fa fa-caret-down"> </i> </button>
            <button class="btn btn-navigation" id="dropdownBasic1" ngbDropdownToggle *ngIf="selectedIndex != null">Visit {{allVisits.length - selectedIndex }} ({{selectedVisit.createdAt | date: 'dd/MM/yyyy'}})<i class="menu-caret fa fa-caret-down"> </i> </button>
            <ul ngbDropdownMenu aria-labelledby="dropdownBasic1" class="uldropdown mt-0 pt-0">
              <li class="scroller">
                <div class="show" ngbDropdownItem id="visitContainer">
                  <div class="items" *ngFor="let visit of allVisits; let i = index">
                    <a (click)="viewVisitDetails(visit.CaseId,visit.id); selectedVisit = visit; selectedIndex = i">Visit {{allVisits.length - i }} ({{visit.createdAt | date: 'dd/MM/yyyy'}})</a>
                  </div>
                </div>
              </li>
             *ngIf="allVisits && allVisits[0].Doctor.User.id == userID" id="addVisit"
              <li class="" ngbDropdownItem >
                <div class="text-center">
                  <button class="btn btn-newVisit mt-2" *ngIf="caseStatus == 'Open'" (click)="createNewVisit(allVisits[0].CaseId,d)"><span class="plusIcon">+</span> New Visit</button>
                </div>
              </li>
            </ul>

          </div>
        </li>-->
        <!-- <li class="addNew li" style="align-self: center;">
          <a class="btn btn-primary opd-primary-btn transformNormal btn-lg d-flex newBtnStyle"
            style="align-self: center;" (click)="initAddPatient(addNewPatient)" placement="bottom"
            ngbTooltip="Add New Patient"><img style="align-self: center; margin-right: 5px;" height="20"
              src="assets/images/plus_orange.png" alt="">
            <span style="align-self: center;color: #fff;font-size: 16px;">Add New Patient</span> </a>
        </li> -->
        <li class="todayBirthday li">
          <a>{{todaysDayOfWeek}}<br />{{todaysDate | date:'d MMMM, yyyy'}}</a>
        </li>
      </ul>
      <ul class="navbar-nav hidden">
        <li class="nav-item" ngbDropdown placement="bottom-right">
          <a href="javascript:;" class="nav-link" routerLinkActive="active" ngbDropdownToggle>
            <img src="assets/images/avatar.jpg" class="navbar-avatar rounded-circle" alt="user" title="user">
          </a>
          <div ngbDropdownMenu class="dropdown-menu dropdown-menu-right">
            <a class="dropdown-item" routerLinkActive="active" href="javascript:;">
              <i class="icon icon-basic-settings mr-3"></i>
              <span>Settings</span>
            </a>
            <a routerLink="/doctor/profileView" class="dropdown-item" routerLinkActive="active">
              <i class="icon icon-basic-postcard mr-3"></i>
              <span>Profile</span>
            </a>
            <a class="dropdown-item" routerLinkActive="active" href="javascript:;">
              <i class="icon icon-basic-message-multiple mr-3"></i>
              <span>Notifications</span>
            </a>
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="javascript:;" routerLinkActive="active">
              <i class="icon icon-arrows-switch-vertical mr-3"></i>
              <span>Signout</span>
            </a>
          </div>
        </li>
      </ul>
    </nav>

    <div class="main-content">

      <router-outlet *ngIf="!otpSection && !authorizePatient"></router-outlet>

      <div class="otp_section" *ngIf="otpSection">
        <div class="container-fluid basic-info-wrapper">
          <div class="col-12">
            <h3 class="inner-header">Confirm your information</h3>
            <form>

              <div class="col-12">
                <div class="optHolder">
                  <div class="form-group mb-4 onFocus">
                    <label>Enter OTP</label>
                    <div class="row">

                      <div class="col-12">
                        <input type="text" class="form-control otpInputField" maxlength="6" [(ngModel)]="otp" name="otp"
                          id="otp" (keypress)="validateNumber($event)">
                        <!--placeholder="* * * * * *"
                          onfocus="if(placeholder=='* * * * * *'){placeholder=''}"
                          onblur="if(placeholder==''){placeholder='* * * * * *'}"-->
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="terms-line"><a class="req-otp not-active" id="resend-otp" (click)="reqOtp()">Resend OTP?</a>
                    if you don't receive it in 2 mins.
                    <!-- <p class="terms-line">Please wait for couple of minutes to receive the OTP. If you do not receive OTP,
                    <a class="req-otp" (click)="reqOtp()">Request OTP</a>
                  </p> -->
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3">
                  <button type="submit" class="btn-primary opd-primary-btn btn-md"
                    (click)="createPatient()">Submit</button>
                  <button type="button" class="btn-primary opd-secondary-btn btn-md ml-2" (click)="goBackBtn();">Go
                    back</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="otp_section" *ngIf="authorizePatient">
        <div class="container-fluid basic-info-wrapper">
          <div class="col-12">
            <h3 class="inner-header">Confirm your information</h3>
            <form>

              <div class="col-12">
                <div class="optHolder">
                  <div class="form-group mb-4 onFocus">
                    <label>Enter OTP</label>
                    <div class="row">

                      <div class="col-12">
                        <input type="text" class="form-control otpInputField" maxlength="6" [(ngModel)]="otp" name="otp"
                          id="otp" (keypress)="validateNumber($event)">
                        <!--placeholder="* * * * * *"
                          onfocus="if(placeholder=='* * * * * *'){placeholder=''}"
                          onblur="if(placeholder==''){placeholder='* * * * * *'}"-->
                      </div>

                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <p class="terms-line"><a class="req-otp not-active" id="resend-otp" (click)="reqOtp()">Resend OTP?</a>
                    if you don't receive it in 2 mins.
                    <!-- <p class="terms-line">Please wait for couple of minutes to receive the OTP. If you do not receive OTP,
                    <a class="req-otp" (click)="reqOtp()">Request OTP</a>
                  </p> -->
                </div>
              </div>
              <div class="row">
                <div class="col-12 mt-3">
                  <button type="submit" class="btn-primary opd-primary-btn btn-md" (click)="authorize()">Submit</button>
                  <button type="button" class="btn-primary opd-secondary-btn btn-md ml-2" (click)="goBackBtn();">Go
                    back</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

</ng-sidebar-container>

<!--<ng-template #search let-c="close" let-d="dismiss">
  <form class="search__form" action="">
    <input class="search-input" name="search" type="search" placeholder="Search..." autocomplete="off" autocorrect="off" autocapitalize="off"
      spellcheck="false" autofocus="true" />
    <p class="text-muted">
      <small>
        <strong>Hit enter to search or ESC to close</strong>
      </small>
    </p>
  </form>
  <div class="search-suggestions">
    <h6 class="text-uppercase">
      <strong>Suggestions?</strong>
    </h6>
    <p class="text-primary">#medical #analytics #fitness #transport #ui #dashboard #admin #bootstrap #angular #typescript</p>
  </div>
  <button type="button" class="search-close" aria-label="Close search form" (click)="d('Cross click')">
    <i class="fi flaticon-close"></i>
  </button>
</ng-template>-->

<!-- utility dower  -->

<!-- <div class="configuration hidden-sm-down" [ngClass]="{'active': showSettings}" *ngIf="patientId"
  (document:click)="handleDrawerClick($event)" id="utilityDawer">
  <div class="configuration-cog halfCircle" title="Utility drawer">
    <span class="drawerToggle"><i class="drawerToggle fa {{ showSettings ? 'fa-angle-right' : 'fa-angle-left'}}"
        aria-hidden="true"></i></span>
  </div>
  <div class="card">

    <div class="card-body">
      <div class="cardDetails">
        <ul class="utilityUL">
          <li class="drawerCloser">
            <a class="drawerCloser"><button
                class="btn mr-2 opd-primary-btn drawerCloser transformNormal questionnaireButton utilityButton mt-3"
                (click)="open(dischargeNote, 'dischargeNotePopoup')">
                <img alt="" class="drawerCloser" src="./../../../assets/images/logos/writing.png"> Discharge
                Note</button>
            </a>
          </li>
          <li class="drawerCloser">
            <a class="drawerCloser"><button
                class="btn mr-2 opd-primary-btn drawerCloser transformNormal questionnaireButton utilityButton mt-3"
                (click)="open(riskFactors, 'riskFactorsPopoup')">
                <img alt="" class="drawerCloser" src="./../../../assets/images/logos/antibacterial.png"> Risk Factors
                and Allergies</button>
            </a>
          </li>
          <li class="drawerCloser">
            <a class="drawerCloser"><button
                class="btn mr-2 opd-primary-btn drawerCloser transformNormal questionnaireButton utilityButton mt-3"
                (click)="open(VaccinationStatus, 'VaccinationStatusPopoup')">
                <img alt="" class="drawerCloser" src="./../../../assets/images/logos/vaccine.png"> Vaccination
                Status</button>
            </a>
          </li>
          <li class="drawerCloser">
            <a class="drawerCloser"><button
                class="btn mr-2 opd-primary-btn drawerCloser transformNormal questionnaireButton utilityButton mt-3"
                (click)="open(presentParameters, 'presentParametersPopoup')">
                <img alt="" class="drawerCloser" src="./../../../assets/images/logos/parameters.png"> Present
                Parameters</button>
            </a>
          </li>
          <li class="drawerCloser">
            <a class="drawerCloser"><button
                class="btn mr-2 opd-primary-btn drawerCloser transformNormal questionnaireButton utilityButton mt-3"
                (click)="open(highlights, 'highlightsPopoup')">
                <img alt="" class="drawerCloser" src="./../../../assets/images/logos/chat.png"> Highlights and
                Comments</button>
            </a>
          </li>
          <li class="drawerCloser">
            <a class="drawerCloser"><button
                class="btn mr-2 opd-primary-btn drawerCloser transformNormal questionnaireButton utilityButton mt-3"
                disabled (click)="open(alternativeMedicine, 'alternativeMedicinePopoup')">
                <img alt="" class="drawerCloser" src="./../../../assets/images/logos/medication.png"> Alternative
                Medicine</button>
            </a>
          </li>
          <li class="drawerCloser">
            <a class="drawerCloser"><button
                class="btn mr-2 opd-primary-btn drawerCloser transformNormal questionnaireButton utilityButton mt-3"
                (click)="open(presentDiet, 'presentDietPopoup')">
                <img alt="" class="drawerCloser" src="./../../../assets/images/logos/diet.png"> Present Diet</button>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div> -->

<!-- utility dower popup designs  -->
<ng-template #dischargeNote let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase">Discharge Note</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="model-content vaccinationBody">
        <div class="row">
          <div class="col-12">
            <div class="form-group mb-3 mt-4">
              <label class="headingUpprcase18">Discharge Note Information</label>
              <div class="d-flex">
                <p class="filleData"></p>
                <label class="units"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="row utilityPopupContent utilityPopupContentUpload"
          *ngIf="utilityData && !utilityData.discharge_note">
          <div class="UploadImgDiv" (click)="fileInput.click()">
            <div class="">
              <img class=" d-flex mx-auto" src="assets/images/ic-upload.png" alt="" style="width:100px">

              <input class="UploadImgDivinput" type="file" name="profile_pic" #fileInput type="file"
                (change)="uploadImage($event)" />
            </div>

          </div>
          <div class="form-group d-flex mx-auto mb-0">
            <label for="" class="">(img, doc, pdf)</label>
          </div>
        </div>
        {{dischargeNoteName}}
        <!-- after uploading the img user can only view the discharge not img  -->
        <div class="row text-center" *ngIf="utilityData && !utilityData.discharge_note">
          <button class="btn btn-primary opd-primary-btn transformNormal btn-md upprcase mx-auto mt-3 mb-4"
            (click)="uploadDoc(d)" [disabled]="!dischargeNoteName">Upload </button>
        </div>
        <div class="row text-center">
          <button class="btn btn-primary opd-primary-btn transformNormal btn-md upprcase mt-3 mb-4"
            (click)="downloadDoc()" *ngIf="utilityData.discharge_note">Download Discharge Note</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #VaccinationStatus let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase">Vaccination Status</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="model-content vaccinationBody">
        <div class="row">
          <div class="col-12">
            <div class="form-group mb-3 mt-4">
              <label class="headingUpprcase18">Displays past 3 vaccination information available on the patient
                record</label>
              <div class="d-flex">
                <p class="filleData"></p>
                <label class="units"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class=" col-12 vaccination-body">
            <div class="vaccinationDeta">
              <div class="vaccinationDetaHead">
                <div>NAME</div>
                <div>DUE ON</div>
                <div>TAKEN ON</div>
                <div>IMAGE</div>
                <div>DESCRIPTION</div>
              </div>
              <div class="vaccinationDetabody" *ngIf="utilityData.vaccines && utilityData.vaccines.length > 0">
                <div class="vaccinationDetaRow" *ngFor="let vaccine of utilityData.vaccines">
                  <div class="pr-0"><span class="textNoWap">{{vaccine.MasterVaccine.name}}</span></div>
                  <div><span>{{vaccine.due_on | date: 'dd/MM/yyyy'}}</span></div>

                  <div>
                    <span>{{vaccine.taken_on | date: 'dd/MM/yyyy'}}</span>
                    <div class="form-group hidden calendarIcon ">
                    </div>
                  </div>
                  <div>
                    <a *ngIf="vaccine.taken_on && vaccine.image"
                      (click)="selectvaccine(vaccine); open(VaccinationUploaded, 'vaccinationPopoup')"><img
                        src="assets/images/img.png" alt="" /></a>
                  </div>
                  <div><span>{{vaccine.scanned_data ? vaccine.scanned_data : 'N/A'}}</span></div>
                </div>
                <!-- <div  class="noRecords">

                        <p class="note mt-1 mb-1 pb-1"> <strong>Note:</strong> Displays past 3 vaccination information available on the patient record</p>


                      </div> -->
              </div>
              <div class="vaccinationDetabody" *ngIf="!utilityData.vaccines || utilityData.vaccines.length == 0">
                <div class="vaccinationDetaRow">
                  N/A
                </div>

              </div>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <button class="btn btn-primary opd-primary-btn transformNormal btn-md upprcase mx-auto mt-4"
            (click)="d()">Close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #VaccinationUploaded let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase">VACCINATION UPLOAD & SCAN</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="model-content vaccinationBody">

        <div class="row">
          <div class="col-12 ">
            <img [src]="vaccineImage" style="width: 100%;" />
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-template>
<ng-template #riskFactors let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase">Risk Factors and Allergies</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="model-content vaccinationBody">
        <div class="row">
          <div class="col-12">
            <div class="form-group mb-3 mt-4">
              <label class="headingUpprcase18">information specified in patient record</label>
              <div class="d-flex">
                <p class="filleData"></p>
                <label class="units"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="row utilityPopupContent">
          <div class="col-8">
            <div class="form-group mb-3 mt-4">
              <label class="">Affected</label>
              <div class="d-flex">
                <p class="filleData">
                  {{utilityData.patient && utilityData.patient.affected && utilityData.patient.affected_comment ?
                  utilityData.patient.affected_comment : 'N/A'}}
                </p>
                <label class="units"></label>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group mb-3 mt-4">
              <label class="">Carrier</label>
              <div class="d-flex">
                <p class="filleData">
                  {{utilityData.patient && utilityData.patient.carrier ? utilityData.patient.carrier : 'N/A'}}</p>
                <label class="units"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <button class="btn btn-primary opd-primary-btn transformNormal btn-md upprcase mx-auto mt-4"
            (click)="d()">close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #presentParameters let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase">Present Parameters</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="model-content vaccinationBody">
        <div class="row">
          <div class="col-12">
            <div class="form-group mb-3 mt-4">
              <label class="headingUpprcase18">Latest Statistics <span
                  *ngIf="utilityData.visit && utilityData.visit.createdAt">(
                  {{utilityData.visit.createdAt | date: 'dd/MM/yyyy'}} )</span></label>
              <div class="d-flex">
                <p class="filleData"></p>
                <label class="units"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="row utilityPopupContent">
          <div class="col-4">
            <div class="form-group mb-1">
              <label>WEIGHT</label>
              <div class="d-flex">
                <p class="filleData">
                  {{ utilityData.visit && utilityData.visit.weight ? utilityData.visit.weight : 'N/A'}}</p>
                <label class="units">{{utilityData.visit && utilityData.visit.weight ? 'kgs' : ''}}</label>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group mb-1">
              <label>HEIGHT</label>
              <div class="d-flex">
                <p class="filleData">
                  {{utilityData.visit && utilityData.visit.height ? utilityData.visit.height : 'N/A'}}</p>
                <label class="units">{{utilityData.visit && utilityData.visit.height ? 'cms' : ''}}</label>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group mb-1">
              <label>HC</label>
              <div class="d-flex">
                <p class="filleData">
                  {{utilityData.visit && utilityData.visit.head_circumference ? utilityData.visit.head_circumference :
                  'N/A'}}
                </p>
                <label class="units">{{utilityData.visit && utilityData.visit.head_circumference ? 'cms' : ''}}</label>
              </div>
            </div>
          </div>
          <div class="col-4">
            <div class="form-group mb-1">
              <label>CC</label>
              <div class="d-flex">
                <p class="filleData">
                  {{utilityData.visit && utilityData.visit.chest_circumference ? utilityData.visit.chest_circumference :
                  'N/A'}}
                </p>
                <label class="units">{{utilityData.visit && utilityData.visit.chest_circumference ? 'cms' : ''}}</label>
              </div>
            </div>
          </div>

        </div>
        <div class="row text-center">
          <button class="btn btn-primary opd-primary-btn transformNormal btn-md upprcase mx-auto mt-4"
            (click)="d()">Close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #highlights let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase">Highlights and Comments</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="model-content vaccinationBody">
        <div class="row">
          <div class="col-12">
            <div class="form-group mb-3 mt-4">
              <label class="headingUpprcase18">comments added on past 3 visits on the patient record</label>
              <div class="d-flex">
                <p class="filleData"></p>
                <label class="units"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="row utilityPopupContent">
          <div class="col-12" *ngFor="let visit of utilityData.visits | slice:0:3">
            <div class="form-group mb-3 mt-4">
              <label class="">Diagnosis ( {{visit.createdAt | date: 'dd/MM/yyyy'}} )</label>
              <div class="d-flex">
                <p class="filleData">{{visit.complaint_diagnosis ? visit.complaint_diagnosis : 'N/A'}}</p>
                <label class="units"></label>
              </div>
            </div>
          </div>

        </div>
        <div class="row text-center">
          <button class="btn btn-primary opd-primary-btn transformNormal btn-md upprcase mx-auto mt-4"
            (click)="d()">close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #alternativeMedicine let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase">Alternative Medicine</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="model-content vaccinationBody">
        <div class="row">
          <div class="previewImgDiv">
            <div class="previewImg"></div>
          </div>
        </div>
        <div class="row text-center">
          <button class="btn btn-primary opd-primary-btn transformNormal btn-md upprcase mx-auto">Edit</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #presentDiet let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase">Present Diet</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="model-content vaccinationBody">
        <div class="row">
          <div class="col-12">
            <div class="form-group mb-3 mt-4">
              <label class="headingUpprcase18">patient's Diet information</label>
              <div class="d-flex">
                <p class="filleData"></p>
                <label class="units"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="row utilityPopupContent">
          <div class="col-6">
            <div class="form-group mb-3 mt-4">
              <label class="">Food Choice</label>
              <div class="d-flex">
                <p class="filleData">{{utilityData.food_choice ? utilityData.food_choice : 'N/A'}}</p>
                <label class="units"></label>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group mb-3 mt-4">
              <label class="">Present Food Intake</label>
              <div class="d-flex">
                <p class="filleData">
                  {{utilityData.visit && utilityData.visit.food_intake ? utilityData.visit.food_intake : 'N/A'}}</p>
                <label class="units"></label>
              </div>
            </div>
          </div>
        </div>
        <div class="row text-center">
          <button class="btn btn-primary opd-primary-btn transformNormal btn-md upprcase mx-auto mt-4"
            (click)="d()">close</button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<!-- utility popups ends he -->


<ng-template #searchResult let-c="close" let-d="dismiss">
  <div class="popUps searchResult">
    <div class="modal-header">
      <div class="form-group m-0">
        <input type="text" class="form-control" placeholder="Search Patient" id="searchInput"
          [(ngModel)]="searchPatient" (keyup)="searchPatients()" />
        <button><img class="searchImg" src="assets/images/searchIcon.svg" alt="" /></button>
      </div>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img class="mr-3" src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">
        <div class="appointments recentlyAuthorizedPatients" (scroll)="scrollHandler($event)">
          <!--<div class="todaysAppointments ml-3 pl-1 mt-2 pt-1 pb-2">Recently Authorized Patients</div>-->
          <ul>
            <a (click)="checkIfAuthorized(recentlyAuthorizedPatient, d);d();"
              *ngFor="let recentlyAuthorizedPatient of patientsArray; let i = index">
              <li class="clearfix">
                <figure>
                  <img *ngIf="recentlyAuthorizedPatient.profile_photo"
                    src="{{patientProfileImagePath}}{{recentlyAuthorizedPatient.profile_photo}}" alt="" />
                  <img *ngIf="!recentlyAuthorizedPatient.profile_photo" src="assets/images/user_default.png" alt="" />
                </figure>
                <figcaption>
                  <span class="capitalize">{{recentlyAuthorizedPatient.first_name}}</span>
                  <span class="capitalize">{{recentlyAuthorizedPatient.last_name}}</span>
                  <span>(DOB: {{recentlyAuthorizedPatient.dob | date: 'dd/MM/yyyy'}})</span>
                  <span class="phone_number">{{recentlyAuthorizedPatient.phone_number}}</span>
                </figcaption>
              </li>
            </a>
            <li *ngIf="patientsArray && patientsArray.length == 0" class="todaysAppointments center border-0 mt-55">No
              records found.</li>
          </ul>
        </div>
        <!-- <div class="searchSection">
          <ul class="addNewSearchSection p-0">
            <li class="addNew li float-none text-left">
              <a class="btn btn-primary opd-primary-btn transformNormal btn-lg"
                (click)="initAddPatient(addNewPatient);d()"><span>+</span> Add new patient</a>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
  </div>
</ng-template>
<p-dialog appendTo="body" [modal]="true" [draggable]="false" [blockScroll]="true" [closable]="true"
  (onHide)="hideDialog()" [(visible)]="cropperDisplay" styleClass="changePropic">
  <p-header>
    Upload your profile picture
  </p-header>
  <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
  <p class="errors" *ngIf="imageError">{{imageError}}</p>
  <div class="col-lg-12 cropper_buttons pl-0" style="display: flex; justify-content: flex-end; padding-top: 8px;">
    <button type="button" class="btn-primary opd-primary-btn btn-md mr-2 newBtnStyle" style="text-transform: none;"
      (click)="hideCropper(); uploadPhoto();" [disabled]="!data.image">Save</button>
    <button type="button" class="btn-primary opd-secondary-btn btn-md" style="text-transform: none; padding: 6px 10px;"
      (click)="hideCropper();">Cancel</button>
  </div>
</p-dialog>



<!-- <ng-template #saveImageModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="font-family: 'Mukta', sans-serif;"> Upload your profile
        picture
      </h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">


      <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
      <p class="errors" *ngIf="imageError">{{imageError}}</p>
      <div class="col-lg-12 cropper_buttons pl-0" style="display: flex; justify-content: flex-end; padding-top: 8px;">
        <button type="button" class="btn-primary opd-primary-btn btn-md mr-2 newBtnStyle" style="text-transform: none;"
          (click)="hideCropper(); uploadPhoto();" [disabled]="!data.image">Save</button>
        <button type="button" class="btn-primary opd-secondary-btn btn-md"
          style="text-transform: none; padding: 6px 10px;" (click)="hideCropper();">Cancel</button>
      </div>


    </div>

  </div>
</ng-template> -->

<!-- <p-dialog appendTo="body" [modal]="true" [draggable]="false" [blockScroll]="true" [closable]="true"
  (onHide)="hideDialog()" [(visible)]="display">
  <p-header style="font-size: 18px;">
    Select
  </p-header>
  <div class="row upload_buttons"><button type="button" class="newBtnStyle" (click)="selectFile()">Choose Image</button>
  </div>
  <div class="row upload_buttons"><button type="button" class="btn-primary opd-secondary-btn btn-md"
      style="text-transform: none; font-size: 14px;" (click)="hideDialog(); removePhoto();"
      [disabled]="!userDetails.profile_photo">Remove</button></div>

</p-dialog> -->


<ng-template #addImageModal let-modal>
  <div class="modal-content">
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title" style="font-family: 'Mukta', sans-serif;">Select Profile Picture
      </h4>
      <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">


      <div class="row upload_buttons"><button type="button" class="newBtnStyle" (click)="selectFile()">Choose
          Image</button>
      </div>
      <!--<div class="row upload_buttons"><button type="button" class="btn btn-success btn-purple" [disabled]="true" >Web Cam</button></div>-->
      <div class="row upload_buttons"><button type="button" class="btn-primary opd-secondary-btn btn-md"
          style="text-transform: none; font-size: 14px;" (click)="hideDialog(); removePhoto();"
          [disabled]="!userDetails.profile_photo">Remove</button></div>





    </div>

  </div>
</ng-template>


<p-confirmDialog class="pConfirmZindex" appendTo="body"></p-confirmDialog>


<ng-template #addNewPatient let-c="close" let-d="dismiss" class="addnewpatient">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title" style="text-transform: capitalize;">Add new patient</h6>
      <button type="button" class="close" aria-label="Close" (click)="c()" style="margin-right: 0px;">
        <span aria-hidden="true" style="font-size: 34px;" alt="">
          ×
          <!-- <img src="assets/images/close-btn.png" alt=""> -->
        </span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">
        <div class="row">
          <div class="col-12">
            <!-- <div class="form-group animateLabel">
                <input type="text" class="form-control animateInput" [ngClass]="{'error-field':error}"  [textMask]="{mask: mask,guide:false}" maxlength="12" [(ngModel)]="user.phone_number" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                <label>Mobile Number</label>
                <p class="errors" *ngIf="errors.phone_number">{{errors.phone_number}}</p>
              </div> -->
            <div class="form-group mobNumber clearfix">
              <label class="newInputStyleLabel">Your Mobile Number <span class="required">*</span></label>
              <div class="stdCode" style="padding-top: 2px !important;">
                <span class="stdCodeInputSign">+</span>
                <input class="stdCodeInput form-control newInputBox" type="text" maxlength="2"
                  (keypress)="validateNumber($event)" [(ngModel)]="stdCode">
                <p class="errors" *ngIf="errors.stdCode">{{errors.stdCode}}</p>
              </div>
              <div class="animateLabel" style="padding-top: 2px !important;">
                <input type="text" class="form-control newInputBox" [ngClass]="{'error-field':error}"
                  [textMask]="{mask: mask,guide:false}" maxlength="10" [(ngModel)]="user.phone_number"
                  (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">

                <p class="errors" *ngIf="errors.phone_number">{{errors.phone_number}}</p>
              </div>
            </div>
            <!-- <div class="form-group checkbox withLabel mb-0">
                <label class="mb-4">
                  <input class="filled-in" type="checkbox" checked>
                  <span>This is patient's own number</span>
                </label>
              </div> -->
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label class="newInputStyleLabel">First and Middle Name <span class="required">*</span></label>
              <input type="text" class="capitalize newInputBox" [ngClass]="{'error-field':error}"
                [(ngModel)]="user.first_name" maxlength="25"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                placeholder="Enter first name and middle name"
                onkeypress="return (event.charCode > 64 &&
	              event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9) || (event.which == 32)|| (event.charCode == 39)|| (event.charCode == 45)">

              <p class="errors" *ngIf="errors.first_name">{{errors.first_name}}</p>
            </div>
          </div>
          <!-- <div class="col-6">
            <div class="form-group">
              <label class="newInputStyleLabel">Middle Name </label>
              <input type="text" class="capitalize newInputBox" [ngClass]="{'error-field':error}"
                [(ngModel)]="user.middle_name" maxlength="25"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                placeholder="Enter middle name"
                onkeypress="return (event.charCode > 64 &&
	              event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9)">

              <p class="errors" *ngIf="errors.middle_name">{{errors.first_name}}</p>
            </div>
          </div> -->
        </div>
        <div class="row">
          <div class="col-12">
            <!-- <div class="form-group animateLabel">
              <input type="text" class="form-control animateInput capitalize" [ngClass]="{'error-field':error}"
                [(ngModel)]="user.last_name" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)"
                maxlength="25" onkeypress="return (event.charCode > 64 &&
                event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9)
                ">
              <label>Last Name <span class="required">*</span></label>
              <p class="errors" *ngIf="errors.last_name">{{errors.last_name}}</p>
            </div> -->
            <div class="form-group ">
              <label class="newInputStyleLabel">Last Name <span class="required">*</span></label>
              <input type="text" class="form-control  capitalize newInputBox" [ngClass]="{'error-field':error}"
                [(ngModel)]="user.last_name" (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)"
                maxlength="25"
                onkeypress="return (event.charCode > 64 &&
                event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9) || (event.which == 32) || (event.charCode == 39)|| (event.charCode == 45)"
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                placeholder="Enter last name">

              <p class="errors" *ngIf="errors.last_name">{{errors.last_name}}</p>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <div class="form-group selectDropDown calendarIcon calender onFocus">
              <label style="margin-top: 4px !important; margin-bottom: 4px !important;" class="newInputStyleLabel">Date
                of Birth <span class="required">*</span></label>
              <input placeholder="DD/MM/YYYY" type="text" [maxDate]="date" [minDate]="minDate"
                [ngClass]="{'error-field':error}" id="dobInput" class="form-control newInputBox" [(ngModel)]="user.dob"
                (ngModelChange)="getAge()" ngbDatepicker #d="ngbDatepicker" (document:click)="closeCalender($event, d)"
                (focus)=" d.open()" readonly
                style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;">

              <img (click)="d.toggle()" class="calendarbg" style="top: 27px; right: 8px;"
                src="assets/images/calendarIcon.svg" alt="" />
              <p class="errors" *ngIf="errors.dob">{{errors.dob}}</p>
            </div>
          </div>
          <div class="col-6 age">
            <div class="row">
              <div class="col-12 onFocus">
                <label class="ifnoLabel newInputStyleLabel">Age</label>
              </div>
              <div class="col-12">
                <div class="form-group onFocus">

                  <input disabled [(ngModel)]="user.age.years" class="form-control newInputBox" placeholder="00"
                    style="width: 15%;display: inline-block; border-radius: 0px !important; color: #656565 !important; font-size: 1rem !important; border-top: 0px !important; border-left:  0px !important; border-right: 0px !important;"
                    type="text">
                  <label class="pt-1" style="font-size: 14px;display: inline-block;">Yrs</label>
                  <input disabled [(ngModel)]="user.age.months" class="form-control newInputBox" placeholder="00"
                    style="margin-left: 2px;width: 15%;display: inline-block; border-radius: 0px !important; color: #656565 !important; font-size: 1rem !important; border-top: 0px !important; border-left:  0px !important; border-right: 0px !important;"
                    type="text">
                  <label class="pt-1" style="font-size: 14px;display: inline-block;">Mths</label>
                  <input disabled [(ngModel)]="user.age.weeks" class="form-control newInputBox" placeholder="00"
                    style="margin-left: 2px;width: 15%;display: inline-block; border-radius: 0px !important; color: #656565 !important; font-size: 1rem !important; border-top: 0px !important; border-left:  0px !important; border-right: 0px !important;"
                    type="text">
                  <label class="pt-1" style="font-size: 14px;display: inline-block;">Wks</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 pr-0">
            <div class="form-group mb-3 genderLabel onFocus genderHeight">
              <label class="newInputStyleLabel">Gender <span class="required">*</span></label>
              <div class="basicinfoGender1">
                <div class="form-check-inline">
                  <!-- <label class="custom-control custom-radio" [ngClass]="{'active-gender': user.gender == 'male'}">
                    <input (ngModelChange)="genderToggle('no')" class="custom-control-input" checked type="radio"
                      name="gender" [(ngModel)]="user.gender" value="male">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">Male</span>
                  </label> -->

                  <label class="container-radio" style="margin: 6px 15px 5px 4px;
              color: #333; padding-top: 2px; font-size: 16px">Male
                    <input type="radio" (ngModelChange)="genderToggle('no')" checked name="gender" value="male"
                      [(ngModel)]="user.gender">
                    <span class="checkmark-radio"></span>
                  </label>

                </div>
                <div class="form-check-inline mr-0">
                  <!-- <label class="custom-control custom-radio" [ngClass]="{'active-gender': user.gender == 'female'}">
                    <input (ngModelChange)="genderToggle('no')" class="custom-control-input" type="radio" name="gender"
                      [(ngModel)]="user.gender" value="female">
                    <span class="custom-control-indicator"></span>
                    <span class="custom-control-description">Female</span>
                  </label> -->
                  <label class="container-radio custom-control mr-3" style="margin: 6px 15px 5px 4px;
              color: #333; padding-top: 2px; font-size: 16px">Female
                    <input type="radio" (ngModelChange)="genderToggle('no')" name="gender" value="female"
                      [(ngModel)]="user.gender">
                    <span class="checkmark-radio"></span>
                  </label>
                </div>
                <!-- <div class="form-check-inline mr-0 ">
               

                  <label class="container-radio custom-control mr-3" style="margin: 6px 15px 5px 4px;
              color: #333; padding-top: 2px; font-size: 16px">Non-specified
                    <input type="radio" (ngModelChange)="genderToggle('yes')" name="gender" value="non_specified"
                      [(ngModel)]="user.gender_type">
                    <span class="checkmark-radio"></span>
                  </label>
                </div> -->
              </div>
              <p class="errors" *ngIf="errors.gender && user.gender_type != 'non_specified'">{{errors.gender}}</p>
            </div>
          </div>
        </div>
        <div class="row " *ngIf="user.gender_type == 'non_specified'">
          <div class="col-12">
            <div class="form-group mb-3 genderLabel d-flex onFocus basicinfoGender">
              <label class="align-self-center newInputStyleLabel">Final Gender is : </label>
              <div class="pl-3">
                <div class="form-check-inline">
                  <label class="container-radio">
                    <input class="custom-control-input" type="radio" name="gender_type" [(ngModel)]="user.gender"
                      value="Male">
                    <span class="checkmark-radio"></span>
                    <span class="custom-control-description">Male</span>
                  </label>
                </div>
                <div class="form-check-inline">
                  <label class="container-radio">
                    <input class="custom-control-input" type="radio" name="gender_type" [(ngModel)]="user.gender"
                      value="Female">
                    <span class="checkmark-radio"></span>
                    <span class="custom-control-description">Female</span>
                  </label>
                </div>
                <p class="errors clearfix" *ngIf="errors.gender && user.gender_type == 'non_specified'">
                  {{errors.gender}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12" style="margin-top: 10px;">
          <div class="row">
            <div class="col-7 pl-0">
              <div class="form-group selectDropDown bloodGroup onFocus">
                <label class="newInputStyleLabel">Blood Group </label>
                <select class="newInputBox"
                  style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important; padding: 6px;"
                  [ngClass]="{'ifnoLabel': !user.blood_group}" [(ngModel)]="user.blood_group">
                  <option disabled value="null">Select</option>
                  <option *ngFor="let bloodGroup of bloodGroups; let i = index" value="{{i}}">{{bloodGroup}}</option>
                </select>
                <p class="errors" *ngIf="errors.blood_group">{{errors.blood_group}}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="row" style="display: flex; justify-content: flex-end;">
          <div class="col-12 mt-3 mb-3" style="display: flex; justify-content: flex-end;">
            <button class="newBtnStyle" style="text-transform: capitalize;" type="button" (click)="addPatient(c)">Save
              Patient</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #ViewAllCases let-c="close" let-d="dismiss" id="ViewAllCasesId">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase">Patient cases</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body">
      <div class="model-content">
        <div class="row">
          <div class="col-6">
            <div class="form-group checkbox withLabel">
              <label>
                <input class="filled-in" type="checkbox" [(ngModel)]="otherCases" (change)="showOtherCases(otherCases)">
                <span class="mt-1">Show other cases</span>
              </label>
            </div>
          </div>
          <div class="col-6 text-right createNewCaseBtn">
            <a class="btn btn-primary opd-primary-btn transformNormal btn-lg" (click)="createNewCase(d);d()">Create New
              Case</a>
          </div>
        </div>
        <div class="appointments inventory">
          <ul>
            <li>
              <ul class="head clearfix">
                <li>&nbsp;</li>
                <li style="padding-left: 13px;">Visits</li>
                <li style="padding-left: 12px;">Doctor name</li>
                <li style="padding-left: 7px;">Last Visited </li>
                <li style="padding-left: 4px;">Status</li>
                <li class="pl-0 pr-0">&nbsp;</li>
                <li class="pl-0 pr-0">&nbsp;</li>
              </ul>
            </li>
            <li class="body">
              <ul class="noDataMessage mt-2 mb-2" *ngIf="!cases || cases.length == 0">No past cases found</ul>
              <ul class="morningSessionDetails clearfix clickable" *ngFor="let caseVisit of cases; let i = index">
                <li class="pt-2" (click)="toggleIsOpen(caseVisit.id)">
                  <span>
                    <!-- *ngIf="inventory.lastVisited1 || inventory.lastVisited2" -->
                    <div *ngIf="caseVisit.Visits && caseVisit.Visits.length > 1"><i
                        class="fa {{ caseVisit.id == openCaseId ? 'fa-angle-down' : 'fa-angle-right' }} icon"
                        placement="bottom" [ngbTooltip]=" caseVisit.id !== openCaseId ? 'open' : 'close'"
                        aria-hidden="true"></i></div>
                  </span>
                </li>
                <!-- <li class="pt-2"  (click)="toggleIsOpen(inventory.id)"><span placement="bottom" ngbTooltip="Open"><i class="fa fa-angle-right icon" aria-hidden="true"></i></span></li> -->
                <li (click)="toggleIsOpen(caseVisit.id)">{{caseVisit.Visits.length}}</li>
                <li (click)="toggleIsOpen(caseVisit.id)">
                  <span *ngIf="caseVisit.Visits[0] && caseVisit.Visits[0].Doctor">
                    {{caseVisit.Visits[0].Doctor.User.first_name}} {{caseVisit.Visits[0].Doctor.User.last_name}}</span>
                  <div *ngIf="caseVisit.id == openCaseId">
                    <p class="mb-2" *ngFor="let visit of caseVisit.Visits; let i = index;">
                      <span *ngIf="i != 0" class="capitalize">{{visit.Doctor.User.first_name}}
                        {{visit.Doctor.User.last_name}}</span>
                    </p>
                  </div>
                </li>
                <li (click)="toggleIsOpen(caseVisit.id)">
                  <p class="mb-2" (click)="toggleIsOpen(caseVisit.id)">
                    {{caseVisit.Visits[0] && caseVisit.Visits[0].createdAt | date: 'dd/MM/yyyy'}}</p>
                  <div *ngIf="caseVisit.id == openCaseId">
                    <p class="mb-2" *ngFor="let visit of caseVisit.Visits; let i = index;"><span *ngIf="i != 0">{{visit
                        && visit.createdAt | date: 'dd/MM/yyyy'}}</span></p>
                  </div>
                </li>
                <li (click)="toggleIsOpen(caseVisit.id)">{{caseVisit && caseVisit.status}}</li>
                <!--&& caseVisit.Doctor && caseVisit.Doctor.User.id == userID-->
                <li><a *ngIf="caseVisit.status == 'Open'" (click)="createNewVisit(caseVisit.id,d)" placement="bottom"
                    ngbTooltip="Add visit"><img src="assets/images/plusIcon.svg" alt="" /></a></li>
                <li>
                  <div>
                    <p class="" (click)="viewVisitDetails(caseVisit.id,caseVisit.Visits[0].id,d)"><a placement="bottom"
                        ngbTooltip="View visit"><img class="pt-2" src="assets/images/eyeGray.svg" alt="" /></a></p>
                  </div>
                  <div *ngIf="caseVisit.id == openCaseId">
                    <p class="mb-2" *ngFor="let visit of caseVisit.Visits; let i = index"
                      (click)="viewVisitDetails(caseVisit.id,visit.id,d)"><span *ngIf="i != 0"><a placement="bottom"
                          ngbTooltip="View visit"><img src="assets/images/eyeGray.svg" alt="" /></a></span></p>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<div class="lock-screen" *ngIf="idle">
  <div class="">
    <div class="text-center">
      <i class="fa fa-lock"></i>
    </div>
    <div class="text-center">
      <label class="headingUpprcase16 labelColor" style="text-transform: capitalize;">Your screen is locked. please
        enter password</label>
    </div>
  </div>
  <div class="form-group animateLabel pt-2">
    <input (keydown.space)="$event.preventDefault()" (cut)="$event.preventDefault()" (copy)="$event.preventDefault()"
      (paste)="$event.preventDefault()" type="password" [(ngModel)]="password" id="passwordInput"
      placeholder="Enter Password" name="password" class="form-control" (keyup.enter)="validateUser()"
      onfocus="if(placeholder=='Enter Password'){placeholder=''}"
      onblur="if(placeholder==''){placeholder='Enter Password'}">
  </div>
  <div class="row d-flex justify-content-between pl-2 pr-2">
    <div class="">
      <!-- <button class="newBtnStyle"
        style="text-transform: capitalize; background-color: #999 !important; border-color: #999 !important;"
        type="submit" (click)="logout()">Logout</button> -->
    </div>
    <div class="">
      <button class="newBtnStyle" style="text-transform: capitalize;" type="submit"
        (click)="validateUser()">Submit</button>
    </div>
  </div>
</div>
<ng-template #todaysBday let-c="close" let-d="dismiss">
  <div class="popUps">
    <div class="modal-header">
      <h6 class="modal-title text-uppercase" style="text-transform: capitalize;">Patient Info</h6>
      <button type="button" class="close" aria-label="Close" (click)="d()">
        <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
      </button>
    </div>
    <div class="modal-body ">
      <div class="model-content ">
        <div *ngIf="cases.length > 0">
          <div class="row">
            <div class="row">
              <div class="col-12">
                <div class="form-group selectDropDown bloodGroup onFocus">
                  <label class="">Choose Visit<span class="required">*</span></label>
                  <select (change)="onChange($event.target.value)" class="infoLabel ng-pristine ng-valid ng-touched">
                    <option *ngFor="let caseVisit of cases; let i = index" [value]="i">
                      {{caseVisit.Visits[0] && caseVisit.Visits[0].createdAt | date: 'dd/MM/yyyy'}}</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="certificates topBottomBorder birthdaylist">
              <ul class="pl-0" *ngFor="let path of pathArray; let i = index">
                <li class="clearfix">
                  <div class="row" style="margin: 15px;">
                    <div class="form-group checkbox withLabel" style="width: 60%; margin-left: 3px;">
                      <label style="color: #333;padding:3px">
                        {{path.name}}
                      </label>
                    </div>
                    <div class="row d-flex" style="width: 36%;margin: 3px;">
                      <a style="font-size: 14px;height: 30px;color: #FFF;background-color: #FF8E14;padding: 5px; border-radius: 4px; margin-left: 10px;margin-right: 10px;text-transform: capitalize;"
                        (click)="goToLink(path,d)">Go</a>
                    </div>
                  </div>
                </li>
                <!--- <li class="clearfix">
                  <div class="form-group checkbox withLabel">
                    <label>
                      <input class="form-control filled-in" type="checkbox">
                      <span>Ganpati Venkatevshvar Ramanujan</span>
                    </label>
                  </div>
                </li>
                <li class="clearfix">
                  <div class="form-group checkbox withLabel">
                    <label>
                      <input  class="form-control filled-in" type="checkbox">
                      <span>Consult for Continuation</span>
                    </label>
                  </div>
                </li>
                <li class="clearfix">
                  <div class="form-group checkbox withLabel">
                    <label>
                      <input  class="form-control filled-in" type="checkbox">
                      <span>Vatsala Ganpati Ramanujan</span>
                    </label>
                  </div>
                </li>-->
              </ul>
            </div>
          </div>

        </div>
        <div *ngIf="!cases || cases.length == 0">
          <div class="row">
            <div class="form-group checkbox withLabel mb-2 selectAll">
              <label>
                <!--<input class="form-control filled-in" type="checkbox">-->
                <span>No patient selected</span>
              </label>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- <ng-template #share_Link_App let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Share Health App Link</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="modal.dismiss('Cross click')">
      <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="width: 98%;
      padding-left: 5px;
      margin-top: 14px;
      margin-bottom: 4px;
      margin-left: 8px;">
      <div class="row">
        <label class="fieldsetlable" style="margin-bottom: -14px;">Enter Complete mobile number of patient</label>
        <input type="text" class="form-control" [maxLength]="10" (keypress)="validateNumber($event)"
          style="color: #8C8B8C !important;border-radius: 4px !important;  font-size: 1rem !important;padding-bottom: 0px;"
          [(ngModel)]="mobileNumber">

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div (click)="shareLinkApp(); modal.dismiss()" class="btn-type-filled">Send</div>
  </div>
</ng-template> -->

<ng-template #whatsNew let-modal>
  <div style="display: flex; flex-direction: row; justify-content: space-between;">
    <h4  style="color:#4687BF ;">What's New</h4>
    <!-- <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
        (click)="closeDialog('Cross click')">
        <span aria-hidden="true" style="font-size: 30px;">&times;</span>
    </button> -->
    <button (click)="closeDialog()" class="cancelUibtn">Close</button>

  </div>
  <br>
  <div style="height: 50vh; width: 50vw; overflow: scroll; " >

 

  <mat-accordion class="example-headers-align" [multi]="true">
    <mat-expansion-panel *ngFor="let item of vesionArray; first as isFirst" [expanded]="true">
      <mat-expansion-panel-header >
        <mat-panel-title>Version {{item.version}} </mat-panel-title>
        <mat-panel-title>{{item.title}} </mat-panel-title>
         
        <div style="margin-right: 10px;">{{item.date}}</div> 
      </mat-expansion-panel-header>
    
     
      <mat-panel-description *ngFor="let ele of item.changeLog;let i = index "> 
        <!-- <mat-icon >  circle </mat-icon> -->
     <br> 
    
   <div style="display: flex;flex-direction: column;">
    <div *ngIf="ele.name" style="font-size: 17px; font-weight: 600; ">
      {{ele.name}}:
    </div>
    <div style="margin-top: 10px;">
      <mat-icon style="font-size: 8px;" >  circle </mat-icon>  {{ele.feature}}
    </div>

    </div>
      
   <!-- <span>
    <span *ngIf="ele.name"><span style="font-size: 17px; font-weight: 600; ">{{ele.name}}:
    </span><br> </span> 
    <span>{{ele.feature}}</span>  </span>  -->
     <div style="margin-top: 0px;" *ngIf="ele.url!=null" >
        <button (click)="openUrl(ele.url)" mat-raised-button style="background-color: #5D86CC; color: #fff; height: 32px;margin-left: 9px;margin-top: 0px;font-size: 12px;font-weight: 700; text-align: center;">Video Link 
          <mat-icon >
           link
         </mat-icon>
        </button>
    </div>
          </mat-panel-description>
    
     
    </mat-expansion-panel>
  </mat-accordion>
</div>
</ng-template>

<ng-template #share_Link_App let-modal>
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Share Health App Link</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div style="width: 98%;
      padding-left: 5px;
      margin-top: 14px;
      margin-bottom: 4px;
      margin-left: 8px;">
      <div class="row">
        <label class="fieldsetlable" style="margin-bottom: -14px;">Enter Complete mobile number of patient</label>
        <input type="text" class="form-control" [maxLength]="10" (keypress)="validateNumber($event)"
          style="color: #8C8B8C !important;border-radius: 4px !important;  font-size: 1rem !important;padding-bottom: 0px; height: 50px;"
          [(ngModel)]="mobileNumber">

      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div (click)="shareLinkApp(); closeDialog()" class="btn-type-filled">Send</div>
  </div>
</ng-template>