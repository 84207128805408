import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import * as moment from 'moment';
import { MatTableDataSource } from '@angular/material/table';
import * as XLSX from 'xlsx';
import { MatDateFormats, NativeDateAdapter, MAT_DATE_FORMATS, DateAdapter } from '@angular/material/core';


export interface ReportsMgmt {
  srno: number;
  doctorName: string;
  // totalCount: string;
  // oldPatients: number;
  // patientEnrolled: number;
  // healthApps: string;
  // prescriptions: number;
  // vaccines: number;
  // labRequisitions: number;
  // appointments: number,
  // city: string;
  // area: string;
  // salesperson: string


}

export class AppDateAdapter extends NativeDateAdapter {
  format(date: Date, displayFormat: Object): string {
    if (displayFormat === 'input') {
      let day: string = date.getDate().toString();
      day = +day < 10 ? '0' + day : day;
      let month: string = (date.getMonth() + 1).toString();
      month = +month < 10 ? '0' + month : month;
      let year = date.getFullYear();
      return `${day}/${month}/${year}`;
    }
    return date.toDateString();
  }
}
export const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { month: 'short', year: 'numeric', day: 'numeric' },
  },
  display: {
    dateInput: 'input',
    monthYearLabel: { year: 'numeric', month: 'numeric' },
    dateA11yLabel: {
      year: 'numeric', month: 'long', day: 'numeric'
    },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  }
};

const ELEMENT_DATA: ReportsMgmt[] = [];

@Component({
  selector: 'app-management-reports',
  templateUrl: './management-reports.component.html',
  styleUrls: ['./management-reports.component.scss'],
  providers: [{ provide: DateAdapter, useClass: AppDateAdapter }, { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }],
})


export class ManagementReportsComponent implements OnInit {

  today = new Date();
  displayedColumns: string[] = ['srno', 'doctorName', 'totalCount', 'oldPatients', 'patientsEnrolled', 'healthApps', 'prescriptions', 'vaccines', 'labRequisitions', 'appointments', 'city', 'area', 'salesperson'];
  displayedColumnsFooter: string[] = ['srno', 'doctorNameTotal', 'totalCountTotal', 'oldPatientsTotal', 'patientsEnrolledTotal', 'healthAppsTotal', 'prescriptionsTotal', 'vaccinesTotal', 'labRequisitionsTotal', 'appointmentsTotal', 'cityTotal', 'areaTotal', 'salespersonTotal'];

  dataSource: any = [];
  fromDateForAllReports: any = "";
  toDateForForAllReports: any = "";
  doctorType: string = "d";

  fromDate: string = "";
  toDate: string = "";
  loading: boolean = false;

  filterReady: boolean = false;

  searchReports = '';

  liveDoctorStatus:boolean = false;

  mainDataSource=[];
  emptyAppointmentDataSource = [];
  combinedDataSource=[];

  constructor(private adminService: AdminService) { }

  ngOnInit(): void {
    this.today.setDate(this.today.getDate() + 1);
    //do init ops
  }
  onSearchReports = (event: Event) => {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  applyChanges = () => {

    this.fromDate = moment(this.fromDateForAllReports).format('YYYY-MM-DD');
    this.toDate = moment(this.toDateForForAllReports).format('YYYY-MM-DD');


    // this.getManagementReports();
    // return;
    if (moment(this.toDate).diff(moment(this.fromDate), 'days') <= 30 && moment(this.toDate).diff(moment(this.fromDate), 'days') > 0) {

      if (this.doctorType != '' && this.fromDate != 'Invalid date' && this.toDate != "Invalid date") {
        this.loading = true;
        this.getManagementReports();
      } else {
        alert('You must specify doctor type, start date and end date to search');

      }

    } else {
      alert("Date max range allowed is 30 days")
    }
  }

  toggleLiveDoctor(state){
    let totalLabReq = 0;

    if(state){
      
      this.mainDataSource.map((element, index)=>{
        element.srno = index+1;
        totalLabReq += element.labRequisitions
      });
      this.dataSource = new MatTableDataSource(this.mainDataSource);
    }else{
      this.dataSource = new MatTableDataSource(this.combinedDataSource);
    }
    this.liveDoctorStatus = state;
  }

  getManagementReports = () => {

    let params = {
      "doctorType": this.doctorType,
      "fromDate": this.fromDate,
      "toDate": this.toDate
    }

    this.adminService.getManagementReports(params).subscribe(reports => {
      this.loading = false;
      let newDatasource = [];//134 now 126
      let emptyApptDatasource = []; //8
      let combinedDatasource = [];
      let dataRow = {}
      if (reports && reports.length) {
        console.log('reports', reports);
        reports.map((row, index) => {

          let adminservices = row.adminservices ? JSON.parse(row.adminservices) : null;
          dataRow =
          {
            srno: index + 1,
            doctorName: row.first_name + ' ' + row.last_name,
            totalCount: row.TOTAL_PATIENTS  ? row.TOTAL_PATIENTS  : "0",
            oldPatients: row.TOTAL_OLD_PATIENTS ? row.TOTAL_OLD_PATIENTS : 0,
            patientEnrolled: row.TOTAL_ENROLL ? row.TOTAL_ENROLL : 0,
            healthApps: row.TOTAL_HEALTH_DL && row.TOTAL_HEALTH_IN ? row.TOTAL_HEALTH_DL + "[" + row.TOTAL_HEALTH_IN + "]" : row.TOTAL_HEALTH_DL ? row.TOTAL_HEALTH_DL : row.TOTAL_HEALTH_IN ? '0 [' + row.TOTAL_HEALTH_IN + ']' : "0",
            prescriptions: row.TOTAL_RX ? row.TOTAL_RX : 0,
            vaccines: row.TOTAL_VACCINES ? row.TOTAL_VACCINES : 0,
            labRequisitions: row.Lab_REQ ? row.Lab_REQ : 0,
            appointments: row.TOTAL_APPTS ? row.TOTAL_APPTS : 0,
            city: adminservices && adminservices.city ? adminservices.city : 'NA',
            area: adminservices && adminservices.area ? adminservices.area : 'NA',
            salesperson: adminservices && adminservices.salesperson ? adminservices.salesperson : 'NA'
          };
          
          if ((row.TOTAL_APPTS && row.TOTAL_APPTS > 0)|| row.TOTAL_ENROLL && row.TOTAL_ENROLL >0) {
            newDatasource.push(dataRow);
            combinedDatasource.push(dataRow);
          } else {
            emptyApptDatasource.push(dataRow);
            combinedDatasource.push(dataRow);
          }
        });

        this.mainDataSource = newDatasource;
        this.emptyAppointmentDataSource = emptyApptDatasource;
        this.combinedDataSource = combinedDatasource;
        if(this.liveDoctorStatus){
          newDatasource.map((element, index)=>{
            element.srno = index+1
          })
          this.dataSource = new MatTableDataSource(newDatasource);
        }else{
          this.dataSource = new MatTableDataSource(combinedDatasource);
        }
        
      } else {
        this.dataSource = new MatTableDataSource([]);
      }
    })
  }
 
    DownloadExcel(filename) {
      console.log(filename);
      this.fileName = filename;
      this.exportexcel();
    }
    fileName;
    exportexcel(): void {
      /* table id is passed over here */
      let element = document.getElementById('allReportData');
      const ws: XLSX.WorkSheet = XLSX.utils.table_to_sheet(element);
  
      /* generate workbook and add the worksheet */
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
  
      /* save to file */
      XLSX.writeFile(wb, this.fileName);
  
    }

}
