<div class="main-content-loader d-flex justify-content-center align-items-center position-fixed" *ngIf="showLoading">
    <label for="" class="loader"></label>
</div>

<!-- new code start -->
<div
    style="background-color:#ffffff; display:flex; height:8% !important; width:100%; border-bottom: 1px solid #eee; justify-content: space-between; align-items: center;">

    <div style="display: flex; flex-direction: row; width: 100%; height: 100%; align-items: center;">
        <!-- Search Bar -->
        <div
            style="height: 70%; width: 30%; margin-left: 16px; border-radius: 6px; border: 0.4px solid #005CAF; display: flex; flex-direction: row;">
            <div style="width: 100%; height: 100%;">
                <input type="text" placeholder="Search by Name/Mobile/Other ID"
                    class="form-control" #searchInput [(ngModel)]="searchString" (keyup)="keyPressForSearch($event)"
                    style="height: 100% !important; font-size: 20px;font-weight: 600; border: 1px solid transparent !important; background-color: #fff !important; border-radius: 8px !important; letter-spacing: 1px;" />
            </div>

            <div style="width: 10%; height: 100%; display: flex; justify-content: center; align-items: center;">
                <a type="button" (click)="closeSearchTopBar()" style="padding-left: 6px;
            padding-right: 6px;
            font-size: 34px; top: -4px;">×</a>
            </div>
            <div style="width: 10%; height: 100%; display: flex; justify-content: center; align-items: center;">
                <button (click)="searchPatientFromDb()"
                    style="border: 0px; background-color: transparent; outline: none; background-color: #4687BF; height: 100%; border-top-right-radius: 5px; border-bottom-right-radius: 5px;"><img
                        src="assets/images/search_icon_white.svg" alt="" /></button>
            </div>
        </div>

        <div
            style="display: flex; flex-direction: row; height: 100%; width: 120px; align-items: center; margin-left: 8px;">
            <div id="createPatient" (click)="createNewPatient()" style="
    
                    cursor: pointer;
                    padding: 10px;
                border-radius: 6px;
                background-color: #fff;
                color: #4687BF;
                border: 1px solid #4687BF;
                font-size: 16px;
                font-weight: bold; ">New Patient</div>
        </div>
    </div>


    <!-- COMMENTING TOP BAR ITEMS FOR RELEASE -->
    <!-- <div style="display: flex; flex-direction: row; align-items: center;">

        <div  class="linktop">
            <img *ngIf="selectedIndex==4" style="height: 24px; width: 24px; align-self: center;"
                src="assets/images/new_documents_topbar.svg" alt="" />
            <img *ngIf="selectedIndex!=4" style="height: 24px; width: 24px; align-self: center;"
                src="assets/images/notification_icon_homebar.svg" alt="" />
            <div style="font-size: 16px; color: #807E7E;"
                [ngStyle]="{'color': (selectedIndex == 4) ? '#4687BF' : '#807E7E'}">Notifications</div>
            <div style="height: 6px; width: 100%; background-color: #4687BF; border-top-right-radius: 6px; border-top-left-radius: 6px;"
                [ngStyle]="{'visibility': (selectedIndex == 4) ? 'visible' : 'hidden'}">
            </div>
        </div>

        <div (mouseenter)="mouseEnter(2)" class="linktop" [matMenuTriggerFor]="menuForPatientInfo">
            <img *ngIf="selectedIndex==4" style="height: 24px; width: 24px; align-self: center;"
                src="assets/images/new_documents_topbar.svg" alt="" />
            <img *ngIf="selectedIndex!=4" style="height: 24px; width: 24px; align-self: center;"
                src="assets/images/whatsnew_icon_homebar.svg" alt=""
                />
            <div style="font-size: 16px; color: #807E7E;"
                [ngStyle]="{'color': (selectedIndex == 4) ? '#4687BF' : '#807E7E'}">What's New?</div>
            <div style="height: 6px; width: 100%; background-color: #4687BF; border-top-right-radius: 6px; border-top-left-radius: 6px;"
                [ngStyle]="{'visibility': (selectedIndex == 4) ? 'visible' : 'hidden'}">
            </div>
        </div>

        <div class="linktop">
            <img *ngIf="selectedIndex==4" style="height: 24px; width: 24px; align-self: center;"
                src="assets/images/new_documents_topbar.svg" alt="" />
            <img *ngIf="selectedIndex!=4" style="height: 24px; width: 24px; align-self: center;"
                src="assets/images/contactus_icon_homebar.svg" alt="" />
            <div style="font-size: 16px; color: #807E7E;"
                [ngStyle]="{'color': (selectedIndex == 4) ? '#4687BF' : '#807E7E'}">Contact Us</div>
            <div style="height: 6px; width: 100%; background-color: #4687BF; border-top-right-radius: 6px; border-top-left-radius: 6px;"
                [ngStyle]="{'visibility': (selectedIndex == 4) ? 'visible' : 'hidden'}">
            </div>
        </div>
    </div> -->



    <!-- SHARE APP AND NEW PATIENT BUTTONS -->
    <!-- <div style="display:flex; flex-direction: row;">
        <div (click)="openDialog(share_Link_App,'share_Link_App');closesShareLinkModal();" style="margin: 24px 6px;
   cursor: pointer;
            border: 1px solid#4687BF;
            padding: 8px 0px 8px 0px;
            border-radius: 6px;
            background-color:white;
            color:#4687BF;  
            text-align: center;
            width: 140px; font-weight: bold; ">Share Health Link</div>
        <div (click)="createNewPatient()" style="margin: 24px 24px 24px 0px;
    cursor: pointer;
  padding: 8px 24px 8px 24px;
  border-radius: 6px;
  background-color: #4687BF;
  color: #FFF;
  border-width: 0px;
  font-weight: bold; ">New Patient</div>
    </div> -->

</div>

<!-- new code end -->

<div style="display: flex; flex: 1; flex-direction: column; background-color: #fff;">

    <div
        style="width: 100%; height: 7%; background-color: #fff; display: flex; flex-direction: row; align-items: center; z-index: 1; overflow: visible; margin-top: 8px;">
        <div *ngFor="let element of analyticsInfo; let i = index"
            style="height: 70%; width: 140px; background-color: #fff; margin-left: 8px; border-radius: 8px; border: 1px solid #ccc; justify-content: center; align-items: center; display: flex; flex-direction: row;   ">
            <!-- box-shadow: 2px 2px 4px #ccc;  -->
            <div style="color: #000;  align-self: center; font-size: 16px; font-weight: bold;">{{element.key}} : </div>
            <div style="color: #024085; font-weight: bold; align-self: center; font-size: 22px; padding-left: 8px;">
                {{element.value}}</div>
        </div>
        <div (click)="refreshAnalytics()"
            style="cursor: pointer; color: #024085; align-self: center; background-color: #EAECFB; height: 70%; width: 70px; border-radius: 8px; align-self: center; text-align: center; margin-left: 8px; font-size: 20px; font-weight: bolder; padding-top: 4px;">
            ⟲ </div>

    </div>


    <div style="display: flex; flex-direction: row; height: 100%; width: 100%; margin-top: -8px;">
        <div style="height: 100%; width: 36%; background-color: #fff;">
            <app-patient [searchPatientData]="searchPatientData" [searchPatientDataOrg]="searchPatientDataOrg"
                (messageEvent)="receiveMessage($event)"></app-patient>
        </div>

        <div style="height: 100%; width: 64%; background-color: #eee;">
            <app-appointments [searchAppointmentsKeywords]="searchString" style="width: 100%;"></app-appointments>
        </div>
    </div>

</div>

<!-- <div class="row">
    <div style="width: 100%; height: 100%; background-color: red;">
        <app-patient (messageEvent)="receiveMessage($event)"></app-patient>
    </div>
</div> -->


<p-confirmDialog appendTo="body"></p-confirmDialog>


<ng-template #Billgenerate let-c="close" let-d="dismiss">
    <div class="popUps">
        <div class="modal-header">
            <h6 class="modal-title " style="text-transform: capitalize;">Create Invoice</h6>
            <button type="button" class="close" aria-label="Close" (click)="d()">
                <span aria-hidden="true" style="font-size: 34px;">
                    ×
                </span>
            </button>
        </div>
        <div class="modal-body">
            <div class="model-content">
                <div class="selectionTable" style="width: 90%; align-self: center; overflow-x:hidden;">

                    <div class="row">
                        <div class="col-12">
                            <label for="" style="font-size: 18px;color: #454A51;" class="newInputStyleLabel">Patient
                                Name : {{appointmentDataForPresc.comepleteData.Patient.User.first_name}}
                                {{ appointmentDataForPresc.comepleteData.Patient.User.last_name}}</label>
                            <div class="form-group  onFocus">
                                <label [ngClass]="{'animatedLabel':firstName['first_name'] || editMode}"
                                    class="newInputStyleLabel">Bill
                                    Amount (Rs.) <span class="required">*</span></label>
                                <input [(ngModel)]="billAmount" type="text" class="form-control newInputBox"
                                    (focus)="addLabelClass($event)" [textMask]="{mask: mask,guide:false}"
                                    placeholder="Enter bill amount"
                                    style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                                    (blur)="removeLabelClass($event)">

                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="form-group  onFocus">
                                <label class="newInputStyleLabel"
                                    [ngClass]="{'animatedLabel':lastName['last_name'] || editMode}">Patient Email
                                    (Optional)</label>
                                <input [(ngModel)]="patientEmail" type="text" class="form-control newInputBox"
                                    placeholder="Enter patient email"
                                    style="border-radius: 4px !important; color: #656565 !important; font-size: 1rem !important;"
                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">

                            </div>
                        </div>
                    </div>
                </div>
                <div class="row d-flex pt-3" style="display: flex; justify-content: flex-end;">
                    <button class="newBtnStyle" (click)="submitBillGenerateForm(c)">Save & Send to Patient
                    </button>

                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #todaysBday let-c="close" let-d="dismiss">
    <div class="popUps">
        <div class="modal-header">
            <h6 class="modal-title text-uppercase" style="text-transform: capitalize;">Patient Info</h6>
            <button type="button" class="close" aria-label="Close" (click)="d()">
                <span aria-hidden="true"><img src="assets/images/close-btn.png" alt=""></span>
            </button>
        </div>
        <div class="modal-body ">
            <div class="model-content ">
                <div *ngIf="cases.length > 0" style="height: 400px; align-self: center; overflow-y:scroll;">
                    <div class="row">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group selectDropDown bloodGroup onFocus" style="margin-left: 10px;">
                                    <label class="">Choose Visit<span class="required">*</span></label>
                                    <select (change)="onChange($event.target.value)"
                                        class="infoLabel ng-pristine ng-valid ng-touched">
                                        <option *ngFor="let caseVisit of cases; let i = index" [value]="i">
                                            {{caseVisit.Visits[0] && caseVisit.Visits[0].createdAt | date:
                                            'dd/MM/yyyy'}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="certificates topBottomBorder birthdaylist">
                            <ul class="pl-0" *ngFor="let path of pathArray; let i = index">
                                <li class="clearfix">
                                    <div class="row" style="margin: 15px;">
                                        <div class="form-group checkbox withLabel"
                                            style="width: 60%; margin-left: 3px;">
                                            <label style="color: #333;padding:3px">
                                                {{path.name}}
                                            </label>
                                        </div>
                                        <div class="row d-flex" style="width: 36%;margin: 3px;">
                                            <a style="font-size: 14px;height: 30px;color: #FFF;background-color: #FF8E14;padding: 5px; border-radius: 4px; margin-left: 10px;margin-right: 10px;text-transform: capitalize;"
                                                (click)="goToLink(path,d)">Go</a>
                                        </div>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </div>

                </div>
                <div *ngIf="!cases || cases.length == 0">
                    <div class="row">
                        <div class="form-group checkbox withLabel mb-2 selectAll">
                            <label>
                                <!--<input class="form-control filled-in" type="checkbox">-->
                                <span>No patient selected</span>
                            </label>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #showPrescription_modal let-modal>
    <h4>Create Prescription</h4>
    <div style="color: grey; width: 50vh; line-height: 16px;">You are almost done! Before sharing Rx with your patient
        please specify
        the following fields.</div>
    <hr>
    <div style="display: flex;flex-direction: column;">





        <mat-radio-group aria-label="Select an option" style="margin: 0px 0px;"
            *ngFor="let item of createPescription;let i=index" (click)="selectPrescriptionValue(i)">
            <div [ngClass]="(item.selected) ?  'highlightedBoxPer' :'UnhighlightedBoxPer'">

                <mat-radio-button value="true" [checked]="item.selected">&nbsp;{{item.name}}</mat-radio-button><br><br>
            </div>


        </mat-radio-group>

        <mat-form-field id="fourth" style="width: 100%; margin: 0px 6px;" *ngIf="customPerscription">
            <mat-label>Select Custom Prescription</mat-label>
            <mat-select [value]="dose" (selectionChange)="onDropdownSelectFavMed(favArray,$event.value)">
                <mat-option *ngFor="let item of favArray" [value]="item">{{item.favApptName}}</mat-option>
            </mat-select>
        </mat-form-field>



    </div>

    <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
        <button (click)="closeDialog()" style="background-color: #eee;
                  padding: 8px 16px;
                  border-radius: 6px;
                  color: #fff;
                  font-weight: bold;
                  border: 0px;
                  margin: 8px;
                  color: #444;
                  outline: none;">Cancel</button>

        <button (click)="openPrescriptions();closeDialog()" style="background-color:#3f51b5;
        padding: 8px 16px;
        border-radius: 6px;
        color: #fff;
        font-weight: bold;
        border: 0px;
        margin: 8px 0px;
      outline: none;">Select</button>

    </div>
</ng-template>


<ng-template #share_Link_App let-modal>
    <div class="modal-header" style="border-bottom: 0px;">
        <h4 class="modal-title" id="modal-basic-title">Share Health App Link</h4>
        <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
            (click)="closeDialog()">
            <span style="margin-right: 6px;" aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <div style="width: 98%;
        padding-left: 5px;
        margin-top: 14px;
        margin-bottom: 4px;
        margin-left: 8px;">
            <div class="row">
                <label class="fieldsetlable" style="margin-bottom: -14px;">Enter Complete mobile number of
                    patient</label>
                <input type="text" class="form-control" [maxLength]="10" (keypress)="validateNumber($event)"
                    style="color: #8C8B8C !important;border-radius: 4px !important;  font-size: 1rem !important;padding-bottom: 0px; height: 50px;"
                    [(ngModel)]="mobileNumber">

            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div (click)="shareLinkApp(); closeDialog()" class="btn-type-filled">Send</div>
    </div>
</ng-template>

<p-toast position="top-right" [baseZIndex]="5000">
    <ng-template let-message pTemplate="message">
        <div class=" p-flex p-flex-column"
            style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;top:67px ;">
            <div>

            </div>
            <div class=" p-text-center">
                <div style="font-size: 18px;
                    font-weight: bold;
                    padding: 12px 0px 0px 12px;
                    
                    color: #034A70;">{{message.summary}}</div>
                <div
                    style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
                    {{message.detail}}</div>
            </div>
        </div>
    </ng-template>
</p-toast>

<mat-menu #menuForPatientInfo="matMenu" xPosition="before" class="my-menu">
    <div style="height: 100%; width: 100%;" (mouseleave)="mouseLeave(0)">

    </div>
</mat-menu>



<ng-template #StoperPop let-modal >
    <div style="display: flex; flex-direction: row; justify-content: space-between;">
      <h4  style="color:#333;font-weight: 700;">Hello Dr. {{doctorFullName}},</h4>
      <!-- <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
          (click)="closeDialog('Cross click')">
          <span aria-hidden="true" style="font-size: 30px;">&times;</span>
      </button> -->
      <!-- <button (click)="closeDialog()" class="cancelUibtn">Close</button> -->
  
    </div>
    
    <div style="font-size: 18px;" >

        Gretting from Copious Health,We are in <br>
        process of onboarding you  to ur system which <br>
         typeically requires less than 24 hours,
         <br>
         <br>
         In case of any queries or assistance,you may <br>
         reach us at<br>
         <span style="color:#4687BF;font-weight: 700;"> support@copious.care</span><br><br>

         Regards,<br>
         <span style="color:#000;font-weight: 700;">Team<br>
            Copious Health Care

         </span>


     

  </div>
  <div style="display: flex;flex-direction: row;justify-content: end;">
     <button (click)="closeDialog();logout();" class="cancelUibtn">Logout</button>

  </div>
  </ng-template>