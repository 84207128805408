import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs/Observable'
import { map } from 'rxjs/operators/map'
import { Router } from '@angular/router'
import { Constants } from "../../config/constants";
import 'rxjs';
import { Observer } from 'rxjs/Observer';
interface TokenResponse {
  status: string,
  data: {
    token: string
    user_id: string
    user_type: string
  }
}
@Injectable()
export class UserService {
  private token: string
  private user_id: string
  private user_type: string
  constructor(private http: HttpClient, private router: Router) { 
    if (Constants.APICONFIG.HOST == 'localhost'){
      this.APIURL = 'http://localhost:3000/v1';
      this.PIVOTALAPIURL = 'http://localhost:3200/api';
  }
  }

  noAuthHeader = { headers: new HttpHeaders({ 'NoAuth': 'True' }) };
  APIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/v1`
  PIVOTALAPIURL = `${Constants.APICONFIG.PROTOCOL}://${Constants.APICONFIG.HOST}:${Constants.APICONFIG.PORT}/api`


  //function to validateUser
  public validateUser(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/validateUser`, req_vars, this.noAuthHeader)
  }

  public register(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/users`, req_vars, this.noAuthHeader)
  }

  //function to check entered OTP
  public checkOTP(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/checkOtp`, req_vars, this.noAuthHeader)
  }

  //function to regenerate OTP
  public generateOTP(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/generateOtp`, req_vars, this.noAuthHeader)
  }

  //function to  check if email id is registered
  public checkPhoneNumberandSendOTP(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/checkPhoneNumberandSendOTP`, req_vars, this.noAuthHeader)
  }

  //function to  check if email id is registered
  public checkPassword(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/checkPassword`, req_vars, this.noAuthHeader)
  }

  //function to  reset password of user
  public resetPassword(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/resetPassword`, req_vars, this.noAuthHeader)
  }

  public usersList(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/userList`, req_vars)
  }

  public userUpdate(req_vars: any): Observable<any> {
    return this.http.post(`${this.APIURL}/updateUser`, req_vars)
  }

  public updateProfilePic(req_vars): Observable<any> {
    return this.http.post(`${this.APIURL}/updateProfilePic`, req_vars)
  }

  public getProfilePic(req_vars): Observable<any> {
    return this.http.post(`${this.APIURL}/getProfilePic`, req_vars)
  }

  public checkEmailPassword(req_vars): Observable<any> {
    return this.http.post(`${this.APIURL}/checkEmailPassword`, req_vars)
  }

  public checkPhonePassword(req_vars): Observable<any> {
    return this.http.post(`${this.APIURL}/checkPhonePassword`, req_vars)
  }

  public update(req_vars): Observable<any> {
    return this.http.post(`${this.APIURL}/removePhoto`, req_vars)
  }

  public updateUser(req_vars): Observable<any> {
    return this.http.post(`${this.APIURL}/updateUser`, req_vars)
  }


  public removePhoto(req_vars): Observable<any> {
    return this.http.post(`${this.APIURL}/removePhoto`, req_vars)
  }

  public getUserDetails(req_vars): Observable<any> {
    return this.http.post(`${this.APIURL}/getUser`, req_vars)
  }


  public searchUsers(req_vars): Observable<any> {
    return this.http.get(`${this.PIVOTALAPIURL}/doctor/patients/${req_vars}`)
  }

  public sendDoctorEnquiryEmail(req_vars): Observable<any> {

    return this.http.post(`${this.APIURL}/sendDoctorEnquiryEmail/`, req_vars)
  }
}
