// import { Component, OnInit } from '@angular/core';

// @Component({
//   selector: 'app-sidenav-direction',
//   templateUrl: './sidenav-direction.component.html',
//   styleUrls: ['./sidenav-direction.component.scss']
// })
// export class SidenavDirectionComponent implements OnInit {

//   constructor() { }

//   ngOnInit(): void {
//   }

// }

export enum SidenavDirectionComponent {
  Left = 'left',
  Right = 'right'
}
