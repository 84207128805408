<!--Basic info thank you section(start here) -->
<div *ngIf="currentStage == '1'" style="margin: 12px;">
    <form name="form" #f="ngForm">
        <div class="clinician-detailed-wrapper-outer pr-4">
            <div class="row clinician-detailed-wrapper">
                <div class="col-12 clearfix grayBottomBorder" *ngIf="!editMode">
                    <div class="profileDetailedWrapper">
                        <div class="profileDetailedTopSection">
                            <span class="headingUpprcase18">Create your detailed profile</span>
                            <a class="right editprofileDetailed"><i class="fa fa-pencil-square-o" aria-hidden="true"
                                    (click)="editUser()"></i></a>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="clinicianDetailed">
                                <p class="ifnoLabel">Clinician's Name</p>
                                <p class="ifnoDetail">Dr. {{user.first_name}} {{user.last_name}}</p>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="clinicianDetailed">
                                <p class="ifnoLabel">Clinician's Degree</p>
                                <p class="ifnoDetail">{{degreeArray[user.doctor.degree]}}</p>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="clinicianDetailed">
                                <p class="ifnoLabel">Clinician's Speciality / Sub-Speciality</p>
                                <p class="ifnoDetail">{{user.doctor.speciality}} / {{user.doctor.sub_speciality}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row ">
                        <div class="col-4">
                            <div class="clinicianDetailed clearfix">
                                <div class="left">
                                    <p class="ifnoLabel">Gender</p>
                                    <p class="ifnoDetail capitalize" *ngIf="user.gender != 'do_not_disclose'">
                                        {{user.gender}}</p>
                                    <p class="ifnoDetail capitalize" *ngIf="user.gender == 'do_not_disclose'">
                                        Non-specified 3</p>
                                </div>
                                <div class="left mar-left-30">
                                    <p class="ifnoLabel">Date of Birth</p>
                                    <p class="ifnoDetail">{{user.dob | date: 'dd/MM/yyyy'}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="clinicianDetailed">
                                <p class="ifnoLabel">Registered Name with Medical Council</p>
                                <p class="ifnoDetail capitalize" *ngIf="user.doctor.registered_name">Dr.
                                    {{user.doctor.registered_name}}</p>
                                <p class="ifnoDetail" *ngIf="!user.doctor.registered_name">-</p>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="clinicianDetailed">
                                <p class="ifnoLabel">Years of experience </p>
                                <p class="ifnoDetail capitalize" *ngIf="user.doctor.experience">
                                    {{user.doctor.experience}} Years</p>
                                <p class="ifnoDetail" *ngIf="!user.doctor.experience">-</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="clinicianDetailed">
                                <p class="ifnoLabel">Office Email</p>
                                <p class="ifnoDetail">{{user.email_id}}</p>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="clinicianDetailed">
                                <p class="ifnoLabel">State Medical Council</p>
                                <p class="ifnoDetail">{{councilsArray[user.doctor.state_medical_council]}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4">
                            <div class="clinicianDetailed">
                                <p class="ifnoLabel">Mobile Number</p>
                                <p class="ifnoDetail">{{user.phone_number}}</p>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="clinicianDetailed">
                                <p class="ifnoLabel">Registration Number</p>
                                <p class="ifnoDetail">{{user.doctor.registration_number}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <p class="ifnoDetail m-0">Bio / Summary</p>
                            <p class="ifnoLabel1 mb-3  line-breaker" *ngIf="user.doctor.bio ">{{user.doctor.bio}}</p>
                            <p class="ifnoLabel mb-3" *ngIf="!user.doctor.bio">-</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class=" clinician-detailed-wrapper grayBottomBorder edit-profile" *ngIf="editMode">
                <div class="profileDetailedWrapper">
                    <div class="profileDetailedTopSection">
                        <span class="headingUpprcase18">Create your detailed profile</span>
                        <!-- <a class="right editprofileDetailed"><i class="fa fa-save" aria-hidden="true" (click)="editMode = false"></i></a>-->
                    </div>
                </div>
                <div class="row">
                    <div class="col-3">
                        <div class="form-group animateLabel">
                            <input type="text" class="form-control animateInput capitalize" name="first_name"
                                [(ngModel)]="user.first_name" (focus)="addLabelClass($event)"
                                (blur)="removeLabelClass($event)" maxlength="25"
                                onkeypress="return (event.charCode > 64 &&
          	event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9)"
                                (ngModelChange)="updatedPersonalDetails = true">
                            <label [ngClass]="{'animatedLabel':user.first_name != null}">Your First Name <span
                                    class="required">*</span></label>
                            <p class="errors" *ngIf="errors.fname">Please eneter your first name</p>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group animateLabel">
                            <input type="text" class="form-control animateInput capitalize" name="last_name"
                                [(ngModel)]="user.last_name" (ngModelChange)="updatedPersonalDetails = true"
                                (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)" maxlength="25"
                                onkeypress="return (event.charCode > 64 &&
          	event.charCode < 91) || (event.charCode > 96 && event.charCode < 123) || (event.which == 8) || (event.keyCode  == 9)">
                            <label [ngClass]="{'animatedLabel':user.last_name != null}">Your Last Name <span
                                    class="required">*</span></label>
                            <p class="errors" *ngIf="errors.lname">Please eneter your last name</p>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 calender">
                        <div class="form-group  dob-field onFocus">
                            <label>Date of Birth <span class="required">*</span></label>
                            <input id="dobInput" type="text" class="form-control animateInput " [maxDate]="date"
                                [minDate]="minDate" [(ngModel)]="user.dob"
                                (ngModelChange)="updatedPersonalDetails = true" name="dob" ngbDatepicker
                                #d="ngbDatepicker" (focus)="addLabelClass($event); d.open()"
                                (document:click)="closeCalender($event, d)" (blur)="removeLabelClass($event)" readonly>

                            <img class="calendarbg" alt="" src="assets/images/calendarIcon.svg">
                            <p class="errors" *ngIf="errors.dob">Please eneter your date of birth</p>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-6">
                        <div class="form-group genderLabel mb-0 onFocus">
                            <label>Gender <span class="required">*</span></label>
                            <div class="form-check-inline">
                                <label
                                    class="form-check-label custom-control custom-radio  {{user.gender === 'male' ? 'active-gender' : '' }}">
                                    <input class="form-check-input custom-control-input" name="gender"
                                        [(ngModel)]="user.gender" (ngModelChange)="updatedPersonalDetails = true"
                                        value="male" type="radio" ng-reflect-name="primary_clinic_temp"
                                        ng-reflect-value="0" ng-reflect-model="1">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">Male</span>
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label
                                    class="form-check-label custom-control custom-radio  {{user.gender === 'female' ? 'active-gender' : '' }}">
                                    <input class="form-check-input custom-control-input" checked="true" name="gender"
                                        [(ngModel)]="user.gender" (ngModelChange)="updatedPersonalDetails = true"
                                        value="female" type="radio" ng-reflect-name="primary_clinic_temp"
                                        ng-reflect-value="0" ng-reflect-model="1">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">Female</span>
                                </label>
                            </div>
                            <div class="form-check-inline">
                                <label
                                    class="form-check-label custom-control custom-radio  {{user.gender === 'do_not_disclose' ? 'active-gender' : '' }}">
                                    <input class="form-check-input custom-control-input" name="gender"
                                        [(ngModel)]="user.gender" value="do_not_disclose"
                                        (ngModelChange)="updatedPersonalDetails = true" type="radio"
                                        ng-reflect-name="primary_clinic_temp" ng-reflect-value="0" ng-reflect-model="1">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">Non-specified</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="form-group">
                            <div class="form-group animateLabel">
                                <input type="text" class="form-control animateInput capitalize" name="registered_name"
                                    [(ngModel)]="user.doctor.registered_name"
                                    (ngModelChange)="updatedPersonalDetails = true" (focus)="addLabelClass($event)"
                                    (blur)="removeLabelClass($event)" maxlength="25">
                                <label [ngClass]="{'animatedLabel':user.doctor.registered_name != null}">Registered
                                    Name</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row" style="width:100%;">
                    <div class="col-3">

                        <div class="form-group selectDropDown onFocus">
                            <label>Degree <span class="required">*</span></label>
                            <select class="form-control" name="degree" [(ngModel)]="user.doctor.degree"
                                (ngModelChange)="updatedPersonalDetails = true">
                                <option disabled value="null">Select</option>
                                <option *ngFor="let degree of degreeArray; let i = index" value="{{i}}">{{degree}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-3 pr-0">

                        <div class="form-group selectDropDown onFocus">
                            <label>State Medical Counci <span class="required">*</span></label>
                            <select class="form-control" name="registeredCouncil"
                                [(ngModel)]="user.doctor.state_medical_council"
                                (ngModelChange)="updatedPersonalDetails = true">
                                <option disabled value="null">Select</option>
                                <option *ngFor="let council of councilsArray; let i = index" value="{{i}}">{{council}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="col-xl-2 col-lg-3 pl-3 pr-0">

                        <div class="form-group selectDropDown onFocus">
                            <label>Years of experience <span class="required">*</span></label>
                            <select class="form-control" name="experience" [(ngModel)]="user.doctor.experience"
                                (ngModelChange)="updatedPersonalDetails = true">
                                <option disabled value="null">Select</option>
                                <option *ngFor="let year of experienceArray; let i = index" value="{{year}}">{{year}}
                                    Years</option>
                            </select>
                        </div>
                    </div>

                </div>
                <div class="row" style="width:100%;">
                    <div class="col-3">
                        <div class="form-group animateLabel">
                            <input type="text" [textMask]="{mask: registrationMask,guide:false}"
                                class="form-control animateInput" name="registrationNumber"
                                [(ngModel)]="user.doctor.registration_number"
                                (ngModelChange)="updatedPersonalDetails = true" (focus)="addLabelClass($event)"
                                (blur)="removeLabelClass($event)">
                            <label [ngClass]="{'animatedLabel':user.doctor.registration_number}">Registration Number
                                <span class="required">*</span></label>
                            <p class="errors" *ngIf="errors.registration_number">Please enter your registartion number
                            </p>
                        </div>
                    </div>
                    <div class="col-3 pr-0">
                        <div class="form-group animateLabel onFocus">
                            <p-autoComplete class="autocomplete animateInput" name="speciality"
                                (onFocus)="addLabelAutoComplete($event)" (onBlur)="removeLabelAutoComplete($event)"
                                [(ngModel)]="user.doctor.speciality" field="speciality"
                                [suggestions]="specialitiesArray" (completeMethod)="searchSpecialities($event)"
                                (onClear)="clearSubSpeciality()"
                                (onSelect)="getSubSpecialityList(); clearSubSpeciality()"
                                (ngModelChange)="updatedPersonalDetails = true" forceSelection=true>
                            </p-autoComplete>
                            <label [ngClass]="{'animatedLabel':user.doctor.speciality != null}">Your Speciality <span
                                    class="required">*</span></label>
                            <p class="errors" *ngIf="errors.speciality">Please enter your speciality</p>
                        </div>
                    </div>
                    <div class="col-3 pl-3 pr-0">
                        <div class="form-group animateLabel onFocus">
                            <p-autoComplete class="autocomplete animateInput" name="subSpeciality"
                                (onFocus)="addLabelAutoComplete($event)" [(ngModel)]="user.doctor.sub_speciality"
                                (onBlur)="removeLabelAutoComplete($event)" [suggestions]="subSpecialitiesArray"
                                (completeMethod)="searchSubspecialities($event)"
                                (ngModelChange)="updatedPersonalDetails = true" forceSelection=true>
                            </p-autoComplete>
                            <label [ngClass]="{'animatedLabel':user.doctor.sub_speciality}">Your Sub-Speciality <span
                                    class="required">*</span></label>
                            <p class="errors" *ngIf="errors.sub_speciality">Please enter your sub speciality</p>
                        </div>
                    </div>

                </div>
                <div class="row">
                    <div class="col-9 form-group">
                        <div class="form-group animateLabel">
                            <textarea (blur)="trimInput(user.doctor,'bio')" class="form-control animateInput" name="bio"
                                [(ngModel)]="user.doctor.bio" (ngModelChange)="updatedPersonalDetails = true"
                                (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)" maxlength="500"
                                (keyup)="capitalize($event)"></textarea>
                            <label [ngClass]="{'animatedLabel':user.doctor.bio}">Bio / Summary</label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="salutationSpecialization">
                <div class="row mb-4">
                    <div class="col-12">
                        <label class="headingUpprcase16 mb-4">Salutation about Specialization</label>
                    </div>
                    <div class="col-6">
                        <div class="row">
                            <div class="col-6 pr-3">
                                <div class="form-group selectDropDown onFocus">
                                    <label>Letterhead specialization</label>
                                    <select [ngClass]="{'ifnoLabel': user.doctor.letterhead_specialization == null}"
                                        class="form-control" [(ngModel)]="user.doctor.letterhead_specialization"
                                        (ngModelChange)="updatedEducationDetails = true" name="specialization">
                                        <option disabled value="null">Select</option>
                                        <option *ngFor="let specialization of specializations; let i = index"
                                            value="{{i}}">{{specialization}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mb-4">
                        <label class="ifnoLabel upprcase ifnoDetail">Education</label>
                    </div>
                    <div class="col-12" *ngFor="let education of educationArray; let i = index">
                        <div class="row fieldset">
                            <div class="col-7">
                                <div class="row">
                                    <div class="col-5">

                                        <div class="form-group selectDropDown onFocus">
                                            <label>Letterhead specialization</label>
                                            <select [ngClass]="{'ifnoLabel': educationArray[i].speciality == null}"
                                                class="form-control" [(ngModel)]="educationArray[i].speciality"
                                                (ngModelChange)="updatedEducationDetails = true"
                                                name="education_specialization{{i}}">
                                                <option disabled value="null">Specialization</option>
                                                <option *ngFor="let specialization of specializations; let i = index"
                                                    value="{{i}}">{{specialization}}</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-7">

                                        <div class="form-group selectDropDown onFocus">
                                            <label>Name of the Institute</label>
                                            <select [ngClass]="{'ifnoLabel': !educationArray[i].institute}"
                                                class="form-control" [(ngModel)]="educationArray[i].institute"
                                                (ngModelChange)="updatedEducationDetails = true"
                                                name="education_institute{{i}}">
                                                <option disabled value="null">Institute</option>
                                                <option *ngFor="let institute of institutes; let i = index"
                                                    value="{{i}}">{{institute}}</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-5 col-lg-5">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-4">
                                        <div class="form-group selectDropDown yearDropDown onFocus">
                                            <label>Year</label>
                                            <select class="form-control"
                                                [ngClass]="{'ifnoLabel': !educationArray[i].year}"
                                                [(ngModel)]="educationArray[i].year"
                                                (ngModelChange)="updatedEducationDetails = true"
                                                name="education_year{{i}}">
                                                <option disabled value="null">Select</option>
                                                <option *ngFor="let year of yearsArray; let i = index" value="{{year}}">
                                                    {{year}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="addMore paddtop32 ml-15 mr-15" *ngIf="i != 0">
                                            <a (click)="removeField('educationArray',i)"><i _ngcontent-c12=""
                                                    class="icon icon-arrows-circle-minus"></i> Delete</a>
                                        </div>
                                        <div class="addMore paddtop32 ml-15" *ngIf="educationArray.length < 3">
                                            <a (click)="addField('educationArray')"
                                                *ngIf="i == educationArray.length -1"><i _ngcontent-c12=""
                                                    class="icon icon-arrows-circle-plus"></i> Add more</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="errors" *ngIf="educationErrors[i] == 'error'">Please select all the fields.</p>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 mb-4">
                        <label class="ifnoLabel upprcase ifnoDetail mb-1">Awards & Recognition</label>
                    </div>
                    <div class="col-12" *ngFor="let award of awardArray; let i = index">
                        <div class="row fieldset">
                            <div class="col-7">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <div class="form-group animateLabel">
                                                <input (blur)="trimInput(awardArray,i,'title')" #award_title type="text"
                                                    [required]="awardArray[i].year != null"
                                                    class="form-control animateInput capitalize"
                                                    [(ngModel)]="awardArray[i].title"
                                                    (ngModelChange)="updatedEducationDetails = true"
                                                    name="award_title{{i}}" (focus)="addLabelClass($event)"
                                                    (blur)="removeLabelClass($event)" maxlength="100">
                                                <label [ngClass]="{'animatedLabel':awardArray[i].title}">Award
                                                    Title</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5">
                                <div class="row">
                                    <div class="col-xl-3 col-lg-4">

                                        <div class="form-group selectDropDown onFocus">
                                            <label>Year</label>
                                            <select [ngClass]="{'ifnoLabel': !awardArray[i].year}" class="form-control"
                                                [(ngModel)]="awardArray[i].year"
                                                (ngModelChange)="updatedEducationDetails = true" name="award_year{{i}}">
                                                <option disabled value="null">Year</option>
                                                <option *ngFor="let year of yearsArray; let i = index" value="{{year}}">
                                                    {{year}}</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="addMore paddtop32 ml-15 mr-15" *ngIf="i != 0">
                                            <a (click)="removeField('awardArray',i)"><i _ngcontent-c12=""
                                                    class="icon icon-arrows-circle-minus"></i> Delete</a>
                                        </div>
                                        <div class="addMore paddtop32 ml-15" *ngIf="awardArray.length < 3">
                                            <a (click)="addField('awardArray')" *ngIf="i == awardArray.length -1"><i
                                                    _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i> Add
                                                more</a>
                                        </div>
                                    </div>


                                </div>
                            </div>
                            <div class="col-12">
                                <p class="errors" *ngIf="awardsErrors[i] == 'error'">Please select all the fields.</p>
                            </div>
                        </div>
                        <!-- <p class="errors" *ngIf="awardsErrors[i] == 'error'">Please select all the required feilds.</p> -->
                    </div>
                </div>

                <div class="row">
                    <div class="col-12">
                        <label class="ifnoLabel upprcase ifnoDetail">Details about more services <span
                                class="subIfnoLabel">(Charity / Teaching / Attachments)</span></label>
                    </div>
                    <div class="col-9">
                        <div class="row">
                            <div class="col-12">
                                <div class="form-group">
                                    <div class="form-group animateLabel">
                                        <textarea (blur)="trimInput2(user.doctor,'services')"
                                            class="form-control animateInput" name="services"
                                            [(ngModel)]="user.doctor.services"
                                            (ngModelChange)="updatedEducationDetails = true"
                                            (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)"
                                            maxlength="500" (keyup)="capitalize($event)"></textarea>
                                        <!--<label [ngClass]="{'animatedLabel':user.doctor.more_services}">Details about more services</label>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="center buttons">
                <button class="btn btn-primary opd-primary-btn transformNormal btn-lg upprcase"
                    (click)="saveStepOne(f)">Save & Next</button>
                <button class="btn btn-secondary opd-primary-btn transformNormal btn-lg upprcase"
                    *ngIf="path == 'editprofile'" routerLink="/doctor/profileView">Cancel</button>
                <button class="btn btn-secondary opd-primary-btn transformNormal btn-lg upprcase"
                    *ngIf="path !== 'editprofile'" routerLink="/doctor/profileSetup/stepTwo">Skip</button>
            </div>
        </div>
    </form>
</div>
<div *ngIf="currentStage == '2'" style="margin: 12px;">
    <div class="clinician-detailed-wrapper-outer">
        <div class="row">
            <div class="col-12">
                <label class="ifnoLabel upprcase ifnoDetail">Let's know about your clinic</label>
            </div>
        </div>
        <div class="salutationSpecialization stage2" *ngFor="let clinic of clinicArray; let i = index">
            <div class="clinic">
                <div class="row">
                    <div class="col-12">
                        <label class="ifnoLabel ifnoDetail">Clinic {{i+1}}</label>
                    </div>
                    <div class="col-12">
                        <div class="row fieldset">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="animateLabel">
                                                <input type="text" class="form-control animateInput"
                                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                                <label>Clinic Name</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row fieldset">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="animateLabel">
                                                <input type="text" class="form-control animateInput"
                                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                                <label>Address Line 1</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="animateLabel">
                                                <input type="text" class="form-control animateInput"
                                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                                <label>Address Line 2</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="animateLabel">
                                                <input type="text" class="form-control animateInput"
                                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                                <label>Area / Landmark</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row fieldset">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="animateLabel">
                                                <input type="text" class="form-control animateInput"
                                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                                <label>City</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="animateLabel">
                                                <input type="text" class="form-control animateInput"
                                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                                <label>Pincode</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12"><br />
                        <label class="ifnoLabel ifnoDetail">Let us set Clinic schedule</label>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-3"></div>
                            <div class="col-9">
                                <div class="row clinicScheduleHead">
                                    <div class="col-lg-1 col-sm-2">
                                        <p class="ifnoDetail center mar0">On Call</p>
                                    </div>
                                    <div class="col-lg-4 col-sm-5">
                                        <div class="sessionOne">
                                            <p class="ifnoDetail mar0">Session one</p>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-5">
                                        <div class="sessionOne">
                                            <p class="ifnoDetail mar0">Session two</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row fieldset clinicSessionOuter">
                            <div class="col-3">
                                <div class="form-group">
                                    <div class="custom-droupdown dropdown btn-group" ngbDropdown>
                                        <button type="button" class="btn dropdown-toggle cust-toggle" ngbDropdownToggle>
                                            <span class="selected-name">Monday</span>
                                            <span class="caret"></span>
                                        </button>
                                        <div ngbDropdownMenu class="dropdown-menu" role="menu">
                                            <a class="dropdown-item">Monday</a>
                                            <a class="dropdown-item">Tuesday</a>
                                            <a class="dropdown-item">Wednesday</a>
                                            <a class="dropdown-item">Thursday</a>
                                            <a class="dropdown-item">Friday</a>
                                            <a class="dropdown-item">Saturday</a>
                                            <a class="dropdown-item">Sunday</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="row">
                                    <div class="col-lg-1 col-sm-2">
                                        <div class="form-group checkbox">
                                            <label>
                                                <input type="checkbox" class="filled-in">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-5">
                                        <div class="clearfix clinicSession">
                                            <div class="width45Percent">
                                                <div class="form-group">
                                                    <input type="text" placeholder="09:45AM"
                                                        class="form-control animateInput"
                                                        (focus)="addLabelClass($event)"
                                                        (blur)="removeLabelClass($event)">
                                                </div>
                                            </div>
                                            <div class="width10Percent to">
                                                <p class="ifnoLabel center">To</p>
                                            </div>
                                            <div class="width45Percent">
                                                <div class="form-group">
                                                    <input type="text" placeholder="11:00AM"
                                                        class="form-control animateInput"
                                                        (focus)="addLabelClass($event)"
                                                        (blur)="removeLabelClass($event)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-5">
                                        <div class="clearfix clinicSession">
                                            <div class="width45Percent">
                                                <div class="form-group">
                                                    <input type="text" placeholder="05:30PM"
                                                        class="form-control animateInput"
                                                        (focus)="addLabelClass($event)"
                                                        (blur)="removeLabelClass($event)">
                                                </div>
                                            </div>
                                            <div class="width10Percent to">
                                                <p class="ifnoLabel center">To</p>
                                            </div>
                                            <div class="width45Percent">
                                                <div class="form-group">
                                                    <input type="text" placeholder="08:00PM"
                                                        class="form-control animateInput"
                                                        (focus)="addLabelClass($event)"
                                                        (blur)="removeLabelClass($event)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row fieldset clinicSessionOuter">
                            <div class="col-3">
                                <div class="form-group">
                                    <div class="custom-droupdown dropdown btn-group" ngbDropdown>
                                        <button type="button" class="btn dropdown-toggle cust-toggle" ngbDropdownToggle>
                                            <span class="selected-name">Monday</span>
                                            <span class="caret"></span>
                                        </button>
                                        <div ngbDropdownMenu class="dropdown-menu" role="menu">
                                            <a class="dropdown-item">Monday</a>
                                            <a class="dropdown-item">Tuesday</a>
                                            <a class="dropdown-item">Wednesday</a>
                                            <a class="dropdown-item">Thursday</a>
                                            <a class="dropdown-item">Friday</a>
                                            <a class="dropdown-item">Saturday</a>
                                            <a class="dropdown-item">Sunday</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="row">
                                    <div class="col-lg-1 col-sm-2">
                                        <div class="form-group checkbox">
                                            <label>
                                                <input type="checkbox" class="filled-in">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-5">
                                        <div class="clearfix clinicSession">
                                            <div class="width45Percent">
                                                <div class="form-group">
                                                    <input type="text" placeholder="09:45AM"
                                                        class="form-control animateInput"
                                                        (focus)="addLabelClass($event)"
                                                        (blur)="removeLabelClass($event)">
                                                </div>
                                            </div>
                                            <div class="width10Percent to">
                                                <p class="ifnoLabel center">To</p>
                                            </div>
                                            <div class="width45Percent">
                                                <div class="form-group">
                                                    <input type="text" placeholder="11:00AM"
                                                        class="form-control animateInput"
                                                        (focus)="addLabelClass($event)"
                                                        (blur)="removeLabelClass($event)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-5">
                                        <div class="clearfix clinicSession">
                                            <div class="width45Percent">
                                                <div class="form-group">
                                                    <input type="text" placeholder="05:30PM"
                                                        class="form-control animateInput"
                                                        (focus)="addLabelClass($event)"
                                                        (blur)="removeLabelClass($event)">
                                                </div>
                                            </div>
                                            <div class="width10Percent to">
                                                <p class="ifnoLabel center">To</p>
                                            </div>
                                            <div class="width45Percent">
                                                <div class="form-group">
                                                    <input type="text" placeholder="08:00PM"
                                                        class="form-control animateInput"
                                                        (focus)="addLabelClass($event)"
                                                        (blur)="removeLabelClass($event)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row fieldset clinicSessionOuter">
                            <div class="col-3">
                                <div class="form-group">
                                    <div class="custom-droupdown dropdown btn-group" ngbDropdown>
                                        <button type="button" class="btn dropdown-toggle cust-toggle" ngbDropdownToggle>
                                            <span class="selected-name">Monday</span>
                                            <span class="caret"></span>
                                        </button>
                                        <div ngbDropdownMenu class="dropdown-menu" role="menu">
                                            <a class="dropdown-item">Monday</a>
                                            <a class="dropdown-item">Tuesday</a>
                                            <a class="dropdown-item">Wednesday</a>
                                            <a class="dropdown-item">Thursday</a>
                                            <a class="dropdown-item">Friday</a>
                                            <a class="dropdown-item">Saturday</a>
                                            <a class="dropdown-item">Sunday</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-9">
                                <div class="row">
                                    <div class="col-lg-1 col-sm-2">
                                        <div class="form-group checkbox">
                                            <label>
                                                <input type="checkbox" class="filled-in">
                                                <span></span>
                                            </label>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-5">
                                        <div class="clearfix clinicSession">
                                            <div class="width45Percent">
                                                <div class="form-group">
                                                    <input type="text" placeholder="09:45AM"
                                                        class="form-control animateInput"
                                                        (focus)="addLabelClass($event)"
                                                        (blur)="removeLabelClass($event)">
                                                </div>
                                            </div>
                                            <div class="width10Percent to">
                                                <p class="ifnoLabel center">To</p>
                                            </div>
                                            <div class="width45Percent">
                                                <div class="form-group">
                                                    <input type="text" placeholder="11:00AM"
                                                        class="form-control animateInput"
                                                        (focus)="addLabelClass($event)"
                                                        (blur)="removeLabelClass($event)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-sm-5">
                                        <div class="clearfix clinicSession">
                                            <div class="width45Percent">
                                                <div class="form-group">
                                                    <input type="text" placeholder="05:30PM"
                                                        class="form-control animateInput"
                                                        (focus)="addLabelClass($event)"
                                                        (blur)="removeLabelClass($event)">
                                                </div>
                                            </div>
                                            <div class="width10Percent to">
                                                <p class="ifnoLabel center">To</p>
                                            </div>
                                            <div class="width45Percent">
                                                <div class="form-group">
                                                    <input type="text" placeholder="08:00PM"
                                                        class="form-control animateInput"
                                                        (focus)="addLabelClass($event)"
                                                        (blur)="removeLabelClass($event)">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="addMore">
                            <a href="#"><i _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i> Add more</a>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12"><br />
                        <label class="ifnoDetail">Clinic Contact Details</label>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="animateLabel">
                                                <input type="text" class="form-control animateInput"
                                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                                <label>Phone No.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="animateLabel">
                                                <input type="text" class="form-control animateInput"
                                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                                <label>Email ID</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="addMore paddtop32 ml-4">
                                            <a href="#"><i _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i>
                                                Add contact</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12"><br />
                        <label class="ifnoDetail">Add In-house Services / Facilities</label>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-lg-3 col-sm-6">
                                <div class="form-group">
                                    <div class="animateLabel">
                                        <input type="text" class="form-control animateInput"
                                            (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                        <label>Type of Services / Facilities</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="addMore paddtop32 ml-4">
                                    <a href="#"><i _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i>Add more
                                        services / facilities</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <div class="form-group checkbox withLabel">
                            <label>
                                <input type="checkbox" class="filled-in">
                                <span>Is your clinic multispeciality?</span>
                            </label>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12"><br />
                        <label class="ifnoDetail">Add Consultant</label>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="animateLabel">
                                                <input type="text" class="form-control animateInput"
                                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                                <label>First Name</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="animateLabel">
                                                <input type="text" class="form-control animateInput"
                                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                                <label>Last Name</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12">
                        <div class="row">
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="animateLabel">
                                                <input type="text" class="form-control animateInput"
                                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                                <label>Phone No.</label>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-6">
                                        <div class="form-group">
                                            <div class="animateLabel">
                                                <input type="email" class="form-control animateInput"
                                                    (focus)="addLabelClass($event)" (blur)="removeLabelClass($event)">
                                                <label>Email ID</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="addMore paddtop32">
                                            <a><i _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i>Add more
                                                consultant</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="addMore pt-3">
                <a (click)="addField('clinicArray')"><i _ngcontent-c12="" class="icon icon-arrows-circle-plus"></i>Add
                    more clinic</a>
            </div>
        </div>
        <div class="center buttons">
            <button class="btn btn-primary opd-primary-btn transformNormal btn-lg upprcase" (click)="setStage('3')">Save
                & Next</button>
            <button class="btn btn-secondary opd-primary-btn transformNormal btn-lg upprcase">Clear Form</button>
        </div>
    </div>
</div>
<div *ngIf="currentStage == '3'" style="margin: 12px;">
    <div class="clinician-detailed-wrapper-outer">
        <div class="row">
            <div class="col-12">
                <label class="ifnoLabel upprcase ifnoDetail">Select the clinic you would want to include on your
                    letterhead</label>
            </div>
        </div>
        <div class="salutationSpecialization stage3">
            <div class="row grayBottomBorder pb-4">
                <div class="col-6">
                    <label class="ifnoLabel ifnoDetail mb-2">Children's Clinic<br /><span
                            class="subIfnoLabel">(Multispeciality)</span></label>
                    <p class="ifnoLabel">6, Yashraj Apartments, Opp. Deenanath Mangeshkar<br /> Hospital, Erandawane,
                        Pune 411004</p>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group checkbox withLabel">
                                <label>
                                    <input class="filled-in" type="checkbox">
                                    <span>Include on letterhead</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="custom-control custom-radio">
                                    <input class="custom-control-input" id="radio1" name="radio" type="radio">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">Mark as primary</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row grayBottomBorder pb-4 pt-4">
                <div class="col-6">
                    <label class="ifnoLabel ifnoDetail mb-2">Deenanath Mangeshkar Hospital<br /><span
                            class="subIfnoLabel">(Multispeciality)</span></label>
                    <p class="ifnoLabel">B-213, Go Square, Survey No 251/1C, Wakad,<br /> Pune, Maharashtra 411057</p>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group checkbox withLabel">
                                <label>
                                    <input class="filled-in" type="checkbox">
                                    <span>Include on letterhead</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="custom-control custom-radio">
                                    <input class="custom-control-input" id="radio1" name="radio" type="radio">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">Mark as primary</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row grayBottomBorder pb-4 pt-4">
                <div class="col-6">
                    <label class="ifnoLabel ifnoDetail mb-2">VitaLife<br /><span
                            class="subIfnoLabel">(Multispeciality)</span></label>
                    <p class="ifnoLabel">Vital life lane, 40, Sasoon Rd, Sangamvadi,<br /> Pune, Maharashtra 411001</p>
                </div>
                <div class="col-6">
                    <div class="row">
                        <div class="col-6">
                            <div class="form-group checkbox withLabel">
                                <label>
                                    <input class="filled-in" type="checkbox">
                                    <span>Include on letterhead</span>
                                </label>
                            </div>
                        </div>
                        <div class="col-6">
                            <div class="form-group">
                                <label class="custom-control custom-radio">
                                    <input class="custom-control-input" id="radio1" name="radio" type="radio">
                                    <span class="custom-control-indicator"></span>
                                    <span class="custom-control-description">Mark as primary</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row pb-4 pt-4">
                <div class="col-12">
                    <p class="ifnoLabel">The primary clinic will appear on the header section of your letterhead
                        template by default.</p>
                </div>
            </div>
        </div>
        <div class="buttons">
            <button class="btn btn-primary opd-primary-btn transformNormal btn-lg" (click)="setStage('4')">Preview
                Letterhead </button>
        </div>
    </div>
</div>
<div *ngIf="currentStage == '4'" style="margin: 12px;">
    <div class="clinician-detailed-wrapper-outer">
        <div class="row">
            <div class="col-12">
                <label class="ifnoLabel upprcase ifnoDetail mb-3">Letterhead</label>
            </div>
        </div>
        <div class="row">
            <div class="col-6">
                <div class="letterHedOpt">
                    <div class="row grayBottomBorder m-0">
                        <div class="col-6 p-0 pb-2">
                            <label class="ifnoLabel lLabel ifnoDetail">Children's Clinic</label>
                            <p class="sLabel">6, Yashraj Apartments, Opp. Deenanath Mangeshkar<br /> Hospital,
                                Erandawane, Pune 411004</p>
                            <p class="mLabel mt-1 mb-1">For Appointment: 020-65202132</p>
                            <p class="mLabel">MON to SAT: <span>5:00 PM to 8:00 PM</span></p>
                            <p class="mLabel">TUE / THU / FRI: <span>1:00 PM to 8:00 PM</span></p>
                        </div>
                        <div class="col-6">
                            <div class="textAlingRight">
                                <label class="ifnoDetail lLabel mb-0">Dr. Rashmi Gapchup</label>
                                <p class="sLabel">M.B.B.S, D.C.H, F.C.P, D.N.B</p>
                                <label class="mLabel mt-1 mb-1">Consulting Pediatrician</label>
                                <p class="sLabel">Regn. No. 54039</p>
                            </div>
                        </div>
                    </div>
                    <div class="row prescription pt-1 pb-1">
                        <div class="col-6">
                            <p class="boldRegular"><span>Prescription No.:</span> PGC/Rx/022016/10970 </p>
                        </div>
                        <div class="col-6 textAlingRight">
                            <p><span>Date:</span> 17/02/2016</p>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div class="patientDetails grayBorder">
                                <div class="row grayBottomBorder m-0">
                                    <div class="col-6 grayRightBorder pt-2 pl-3 pb-2">
                                        <p class="sLabel">Patient's Name</p>
                                        <p class="lLabel upprcase">Netra Sarjerao Mali</p>
                                        <p class="sLabel upprcase">Karvenagar pune 411052</p>
                                        <p class="sLabel">M: 9623386163</p>
                                    </div>
                                    <div class="col-3 pt-2 pl-3 pb-2 grayRightBorder">
                                        <p class="sLabel">Gender:</p>
                                        <p class="lLabel upprcase">Female</p>
                                        <p class="sLabel boldRegular upprcase"><span>Weight (KG):</span> 39.3 </p>
                                    </div>
                                    <div class="col-3 pt-2 pl-3 pb-2">
                                        <p class="sLabel">Age:</p>
                                        <p class="lLabel upprcase">10Y, 8M, 24D</p>
                                        <p class="sLabel boldRegular upprcase"><span>Height (CM):</span> 136.5</p>
                                    </div>
                                </div>
                                <div class="row m-0">
                                    <div class="col-6 grayRightBorder  pt-2 pl-3 pb-2">
                                        <p class="sLabel">Chief Complaint</p>
                                        <p class="lLabel upprcase">Cold, Cough</p>
                                    </div>
                                    <div class="col-6  pt-2 pl-3 pb-2">
                                        <p class="sLabel">On Examination</p>
                                        <p class="lLabel upprcase">Chest clear, Throat: PND, Ears: Normal, Nose+,
                                            No-Lad, JDJ Small in insig</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <p class="boldRegular pt-2 pb-2"><span>Diagnosis:</span> UTRI</p>
                            <p class="lLabel">Rx</p>
                            <ol class="pl-3">
                                <li><span class="upprcase">Solvin Cols - ls Exp (AMBROXOL)</span><br />Dosage: 5 ML---5
                                    ML---5 ML for 5 days, Total QtyL 75 ML</li>
                                <li><span class="upprcase">STEPTILIN (AYURVEDIC)</span><br /> Dosage: 5 ML---5 ML---5 ML
                                    for 5 days, Total QtyL 75 ML</li>
                            </ol>
                            <p class="upprcase">General Instructions</p>
                            <ol class="pl-3">
                                <li>Marathi Font:</li>
                                <li>Steam inhalation at night</li>
                            </ol>
                            <p class="boldRegular upprcase mb-2"><span>Review After:</span> 2 DAYS</p>
                        </div>
                        <div class="col-12 clearfix">
                            <div class="dispensedBy pull-right">
                                <p class="m-0 sLabel">Dispensed by</p>
                                <p class="mLabel">Dr. Rashmi Gapchup</p>
                            </div>
                        </div>
                        <div class="col-12 clearfix">
                            <div class="grayBottomBorder pb-2 mt-6">
                                <p class="m-0 mLabel">Trustee: Children's Liver Trst, India</p>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-2">
                        <div class="col-6">
                            <p class="mLabel">Deenanath Mangeshkar Hospital</p>
                            <p class="boldRegular">MON: <span>9:00 am to 1:00 PM</span> PH: <span>40151000</span></p>
                        </div>
                        <div class="col-6 textAlingRight">
                            <p class="boldRegular">Vitalife: <span>Pashan- Sus Road, Pune 411004</span></p>
                            <p class="boldRegular">WED & SAT: <span>By Appointment Only</span> PH: <span>66859900</span>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mt-3">
                <div class="form-group checkbox withLabel">
                    <label>
                        <input class="filled-in" type="checkbox">
                        <span>I agree to the layout and content of the letterhead and prescription for patients.</span>
                    </label>
                </div>
            </div>
        </div>
        <div class="center buttons">
            <button (click)="setStage('5')" class="btn btn-primary opd-primary-btn transformNormal btn-lg upprcase">Save
                &amp; Next</button>
            <button (click)="resetForm()"
                class="btn btn-secondary opd-primary-btn transformNormal btn-lg upprcase">Clear Form</button>
        </div>
    </div>
</div>
<div *ngIf="currentStage == '5'">
    <div class="col-12">
        <div class="thankYouSection">
            <div class="successOuer">
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                    <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                </svg>
            </div>
            <h2 class="inner-header">Success</h2>
            <p class="mb-0">Now you are set to start your practice with COPIOUS 'Care'.</p>
            <p>Kids are waiting for you to take care of them</p>
            <p><img src="assets/images/signature.png" alt="" /></p>
            <p class="upprcase"><b>Copious 'Care'</b></p>

            <div class="buttons mt-2">
                <a class="btn-primary opd-primary-btn btn-md" routerLink="/doctor/appointments">Explore</a>
            </div>

        </div>
    </div>
</div>