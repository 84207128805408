<div style="display: flex; width: 100%; background-color: #b36d6d; flex-direction: row; height: 100vh;">
  <div style="display: flex; width: 75%; flex-direction: column;     background-color: #ffffff;">
    <div style="display: flex; flex-direction: row; width: 35%; justify-content: space-between;">
      <div class="tabGroup" [class.active]="selectedTab === 'allProducts'"
        (click)="selectedTab = 'allProducts'; onTabClick('allProducts')"> All Products</div>
      <div class="tabGroup" [class.active]="selectedTab === 'orders'"
        (click)="selectedTab = 'orders'; onTabClick('orders')"> Orders</div>
      <div class="tabGroup" [class.active]="selectedTab === 'inventory'"
        (click)="selectedTab = 'inventory'; onTabClick('inventory')">Inventory</div>
    </div>

    <ng-container *ngIf="selectedTab === 'allProducts'">
      <!-- ng-template for All Products -->
      <ng-container *ngTemplateOutlet="allProductsTab"></ng-container>
    </ng-container>

    <ng-container *ngIf="selectedTab === 'orders'">
      <!-- ng-template for Orders -->
      <ng-container *ngTemplateOutlet="ordersTab"></ng-container>
    </ng-container>

    <ng-container *ngIf="selectedTab === 'inventory'">
      <!-- ng-template for Inventory -->
      <ng-container *ngTemplateOutlet="inventoryTab"></ng-container>
    </ng-container>

    <!-- Your ng-templates -->
    <ng-template #allProductsTab>
      <!-- Your All Products content here -->
      <app-products (activateAllProductTab)="activateAllProductTab()"  (activateInventoryTab)="activateInventoryTab()"  
      [cartData]="cartData" 
      (itemAdded)="onItemAdded($event)" 
      (productAddedToCart)="refreshCartData()"></app-products>
    </ng-template>

    <ng-template #ordersTab>
      <!-- Your Orders content here -->
      <app-orders ></app-orders>
    </ng-template>

    <ng-template #inventoryTab>
      <!-- Your Inventory content here -->

      <app-inventory></app-inventory>

    </ng-template>
  </div>

  <div style="display: flex; width: 25%; background-color:#F8F8F8;">

    <!-- search Patient by contact number  -->
    <!-- *ngIf="cartData.length" -->
    <div style="display: flex; flex-direction: column; margin: 10px; width: 100%; ">
      <div class="userInfo">
        <div style="display: flex; flex-direction:row ; justify-content: space-between;">
          <mat-form-field class="example-full-width" style="margin-top: 10px; width: 70%;">
            <mat-label>Search Patient by Mobile</mat-label>
            <input matInput placeholder="" [(ngModel)]="searchNumber">
          </mat-form-field>
          <button (click)="getUserByContactNumber()" style="border: 0px; background-color: transparent; outline: none; background-color: #3f51b5; height: 120%; border-radius: 5px;
                  margin: 4px 0 0 20px;width: 50px; height: 50px;">
            <img src="assets/images/search_icon_white.svg" alt="" />
          </button>
        </div>
        <div style="color: red;" *ngIf="!issearchNumberLengthIs10">
          <p>Number must be of 10 digits</p>
        </div>

        <!-- modal for displaying User data after searching based on number -->
        <div class="totalCards">

          <div *ngIf="userData.length && !isUserSelected" class="userdata">


            <div *ngFor="let user of userData">
              <mat-card class="example-card"
                style="height: 55px; margin: 5px; padding: 6px; background-color: #c4c4c4; display: flex; align-items: center;"
                (click)="userSelected(user)">


                <div style="display: flex; flex-direction: row; width: 100%;">
                  <div style="width: 20%;display: flex;justify-content: center; align-items: center;">


                    <div class="patientNameCircle">
                      <h3>
                        {{user.firstName.charAt(0).toUpperCase()}} {{user.lastName.charAt(0).toUpperCase()}}
                      </h3>
                    </div>
                  </div>
                  <div style="width: 80%; display: flex; flex-direction: column; justify-content: center; ">
                    <div style="font-size: 16px; font-weight: bold;">{{user.firstName}} {{user.lastName}}</div>
                    <div style="font-size: 16px; font-weight: bold; color: rgb(162 162 162);">{{user.contactNo}}</div>

                  </div>
                </div>



              </mat-card>
              <!-- <mat-card style="padding: 5px;" (click)="userSelected(user)">
              <mat-card-content><b>Name : </b>{{user.firstName}} {{user.lastName}}<br>
                <b>Contact No : </b>{{user.contactNo}}</mat-card-content>
              <mat-card-content><b>Contact No : </b>{{user.contactNo}}</mat-card-content>
            </mat-card> -->
            </div>
          </div>
        </div>

        <!--  -->




        <div *ngIf="isUserSelected">



          <mat-card style="    display: flex;justify-content: center;flex-direction: column;">
            <div style="display: flex; flex-direction: row; width: 100%;">
              <div style="width: 20%;display: flex;justify-content: center; align-items: center;">


                <div class="patientNameCircle">
                  <h3>
                    {{selectedUser.firstName.charAt(0).toUpperCase()}} {{selectedUser.lastName.charAt(0).toUpperCase()}}
                  </h3>
                </div>
              </div>
              <div style="width: 80%; display: flex; flex-direction: column; justify-content: center; ">
                <div style="font-size: 16px; font-weight: bold;">
                  <div style="font-size: 16px; font-weight: bold;">{{selectedUser.firstName}}
                    {{selectedUser.lastName}}
                  </div>
                  <div style="font-size: 16px; font-weight: bold; color: rgb(162 162 162);">{{selectedUser.contactNo}}
                  </div>

                </div>
              </div>

            </div>
            <div style="margin-left: 10px;font-size: 18px;font-weight: bold; color: rgb(162 162 162);">
              {{selectedUser.address}}
            </div>
          </mat-card>
        </div>
      </div>

      <div></div>
      <div class="cartHeading">
        <h2>Cart</h2>
      </div>
      <div class="Car" style="    overflow-y: scroll;">
       
        <!-- Loop through cartData and display cart items -->
        <div *ngFor="let item of cartData; let i = index" >
          <mat-card style="display: flex; justify-content: space-between; flex-direction: column; margin: 5px; height: 125px;">
            <!-- Display cart item details -->
            <div class="productCartRow">
              <div>{{ item.productName.charAt(0).toUpperCase() + item.productName.slice(1) }}</div>
              <div style="font-size: 16px;"> &#8377;{{ item.priceAtOrderTime }} </div>
            </div>
            <div class="productCartRow">
              <div>{{ item.selectedUnit }} items x {{ item.measurement }} (each)</div>
            </div>
            <div class="productCartRow" style="font-size: 16px;font-weight: bold;">
              <div>Total</div>
              <div> &#8377;{{ item.total  }} </div>
            </div>
            <!-- Button to remove item from cart -->
            <button mat-button color="warn" (click)="removeCartItem(i)">Delete</button>
          </mat-card>
        </div>



      </div>

      <mat-card style="margin: 5px; background-color: #F2F9F5;">
        <div class="paymentDetails">
          <div class="productCartRow" style="font-size: 16px;">
            <div>Payment Type</div>
            <div>
              <mat-form-field>
                <mat-label>Select</mat-label>
                <mat-select [(ngModel)]="paymentType">
                  <mat-option *ngFor="let type of paymentTypes" [value]="type.value" (click)="selectPaymentType(type.viewValue)">{{type.viewValue}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
      
          <div class="productCartRow" style="font-size: 16px;">
            <div>Total Items </div>
            <div>{{ cartData.length }}</div>
          </div>
          
          <div class="productCartRow" style="font-size: 16px; font-weight: bold;">
            <div>Grand Total </div>
            <div>&#8377;{{ calculateGrandTotal() }}</div>
          </div>
          
          <!-- <mat-card-content style="display: flex; justify-content: center;">
            <button mat-button color="warn" (click)="placeOrder(); ">Place Order</button>
          </mat-card-content> -->
        </div>
      </mat-card>

      <mat-card-content style="display: flex; justify-content: center;">
        <button mat-button style="    background-color: #6887C7;
        width: 95%;
        border-radius: 2%;" (click)="placeOrder(); ">
        <span style="color: #fff; font-size: 18px; font-weight: bold;">Place Order</span></button>
      </mat-card-content>
      


    </div>
  </div>