import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import {SidenavDirectionComponent} from '../sidenav-direction/sidenav-direction.component'
import { NavigationServiceService } from '../../services/navigation-service.service';

@Component({
  selector: 'app-patient-list',
  templateUrl: './patient-list.component.html',
  styleUrls: ['./patient-list.component.scss']
})
export class PatientListComponent implements OnInit {

  showSideNav: Observable<boolean>;

  @Input() sidenavTemplateRef: any;
  @Input() duration: number = 0.25;
  @Input() navWidth: number = window.innerWidth;
  @Input() direction: SidenavDirectionComponent = SidenavDirectionComponent.Left;
  
  constructor(private navService: NavigationServiceService) {}

  ngOnInit(): void {
    console.log("OCT4",this.sidenavTemplateRef)
    this.showSideNav = this.navService.getShowNav();
    console.log("OCTTTT",this.showSideNav)
   
  }

  onSidebarClose() {
   this.navService.setShowNav(false);
  }

  getSideNavBarStyle(showNav: boolean) {
    let navBarStyle: any = {};
    
    navBarStyle.transition = this.direction + ' ' + this.duration + 's, visibility ' + this.duration + 's';
    navBarStyle.width = this.navWidth + 'px';
    navBarStyle[this.direction] = (showNav ? 0 : (this.navWidth * -1)) + 'px';
    
    return navBarStyle;
  }

}
