<div style="display: flex; flex-direction: row; width: 100%;  background-color: #fff; height: 100%;">
  <div style="background-color:#fff; border: 1px solid #ccc; width: 18%; ">
    <div style="display:flex; flex-direction: row; width: 146%;margin: 10px;  ">
      <div style="display:flex; flex-direction: row; font-size: 18px; font-weight: 600; width: 54%;">Favourite
        Management
      </div>
      <button (click)="getFavApptsOfDoctor();" ngbTooltip="Refresh Favourite" class="refershButton">
        <span style="font-weight: bolder;
            font-size: 24px;">⟲</span></button>

    </div>
    <div
      style=" width: 93.5%; border-radius: 6px; border: 0.4px solid #005CAF; display: flex; flex-direction: row; margin: 12px 9px 7px 9px;">
      <div style="width: 90%; ">
        <input type="text" (input)="onSearchChangeFav($event.target.value)" placeholder="Search Favourite"
          class="form-control" [(ngModel)]="searchFavouriteMed"
          style="height: 100% !important; font-size: 16px; border: 1px solid transparent !important; background-color: #fff !important; border-radius: 8px !important;" />
      </div>
      <div style="width: 16%;  display: flex; justify-content: center; align-items: center; margin-top: 5px;">
        <button style="border: 0px; background-color: transparent; outline: none;"><img
            src="assets/images/search_icon_blue.svg" alt="" /></button>
      </div>
    </div>

    <div *ngIf="patientId" style="display:flex; justify-content: center;">
      <button (click)="createFavMed();" class="new_btn" style="margin-top: 10px;">+Add New Favourite</button>
    </div>

    <div style="height: 70%; overflow: scroll;">

      <mat-list *ngFor="let favName of favArray;let i = index">
        <mat-list-item [ngClass]="(favName.selected==true) ?  'activematList' :'unActivematList'"
          (click)="handleClick(favName,i)">{{favName.value}}</mat-list-item>
        <!-- <mat-divider></mat-divider> -->

      </mat-list>
    </div>
  </div>



  <div *ngIf="showPlaceholder" style="width: 82%;display: flex; flex-direction: column; 
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;">

    <div style="text-align: center;">
      <img style="justify-content: center;align-items: center; width: 200px;margin-bottom: 0px"
        src="assets/images/favourite_placeholder.svg" alt="" />

      <div style="font-size: 20px; margin-top: 16px; color: #333333; font-weight: bold;">No Favourite Selected
      </div>
      <div style="font-size: 18px; color: grey;">Select a favourite from the list or add new</div>
    </div>
  </div>


  <div *ngIf="!showPlaceholder" style="background-color:#fff; width: 39%;height: 100%;">
    <div *ngIf="editMode" style="margin:10px; font-size: 18px; font-weight: 600;"> Update Favourite </div>
    <div *ngIf="!editMode" style="margin:10px; font-size: 18px; font-weight: 600;"> Add New Favourite </div>
    <div style="display: flex; flex-direction: row; justify-content: space-between;  margin: 14px; width: 93%;">
      <div style="display:flex; flex-direction: row;">

        <div *ngIf="!editTextBox" style="font-size:20px; color: #005CAF; font-weight: 600; margin-top: 14px;">
          {{selectedFavName}}</div>
        <mat-form-field *ngIf="editTextBox" appearance="legacy">
          <mat-label>Favourite Name</mat-label>
          <input matInput placeholder="Enter Favourite Name" [(ngModel)]="selectedFavName">
        </mat-form-field>

        <div *ngIf="showOnlyFavourite" (click)="changeFavName();" style="margin: 13px 9px; cursor: pointer;">
          <mat-icon [ngClass]="'editIcon'">edit</mat-icon>
        </div>
      </div>


      <div style="display: flex;flex-direction: row;">

        <div *ngIf="showDeleteButton">

          <button (click)="openDialog(delete_modalFav)" class="btn btn-secondaryDelete "
            style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

            <mat-icon [ngClass]="'redIcon'">
              delete
            </mat-icon>

            <span style="margin-left: 4px;">Delete</span>
          </button>
          <!-- <img (click)="openDialog(delete_modalFav)" 
            style="width:30px; margin: 10px 19px; color:#007d7e; cursor: pointer; height: 20px;"
            src="assets/images/delete_red.svg" class="largerCheckbox" /> -->
        </div>


        <div *ngIf="editMode && patientId">

          <button (click)="previewPrescriptionPDF();" class="btn btn-secondary"
            style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

            <mat-icon [ngClass]="'blue-icon'">
              save
            </mat-icon>

            <span style="margin-left: 4px;">Save</span>
          </button>
          <!-- <button (click)="previewPrescriptionPDF();"
            class="new_btn" style="margin-top: 0px;">Save</button> -->
        </div>
        <!-- <div *ngIf="editMode">
          <button class="btnCancel " style="margin-top: 7px;">Save f</button>
        </div> -->
      </div>


    </div>

    <div *ngFor="let element of selectedFavMed; let i = index"
      style="display: flex; flex-direction: row; justify-content: space-between; align-items: center; margin: 10px 0px 10px 11px;">

      <div class="newCardStyle" [ngStyle]="{'border': element.editing ? '1px solid #F08439' : '1px solid #DFDFDF'}">

        <div style="width: 86%;">
          <div [ngClass]="(false) ?  'titleActive' :'titleInactive'">{{element.Drug ?
            element.Drug.product_name ? element.Drug.product_name:'':''}}</div>
          <div id="something" name="something" *ngIf="!element.editing"
            [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'">
            {{element.dosage2}} {{element.duration2}}</div>
          <div id="something" name="something" *ngIf="!element.editing"
            [ngClass]="(false) ?  'subtitleActive' :'subtitleInactive'" style="color: grey; font-size: 13px;">
            {{element.instructions}}</div>

        </div>
        <div *ngIf="showOnlyFavourite">
          <img (click)="openDialog(delete_modal, i)"
            style="width:30px; margin: 0px 4px; color:#007d7e; cursor: pointer;"
            src="assets/images/delete_icon_round.svg" class="largerCheckbox" />
        </div>
      </div>



    </div>
    <div *ngIf="showAddButton && patientId" style="display:flex; justify-content: center;">

      <button (click)="addMedicine();" class="btn btn-secondary"
        style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

        <mat-icon [ngClass]="'blue-icon'">
          add
        </mat-icon>

        <span style="margin-left: 4px;">Add Medicine</span>
      </button>
      <!-- <button (click)="addMedicine();"
        class="new_btn" style="margin-top: 10px;">Add Medicine</button> -->
    </div>



  </div>


  <div *ngIf="showAddMedicineBox" style="background-color:#fff; display: flex;  height: 100%; width: 42%;  ">
    <div class="row" style="justify-content: center; overflow-y: scroll; height: 100vh;  align-items: center;">

      <div onclick="event.stopPropagation();" (click)="addNewMedicine($event)"
        style="width: 100%; height: 60px; border-radius: 8px; border: 2px dashed #047A7B; display: flex; align-items: center; justify-content: space-around; margin-bottom: 10px; color: #047A7B; font-weight: bold;"
        [ngStyle]="{'height': isAddMedicine ? null : '60px'}">
        <div style="height: 100%; width: 94%; display: flex; flex-direction: column; justify-content: center">
          <div
            style="display: flex; flex-direction: row; justify-content: space-between; width: 100%; padding-top: 16px; align-items: center;">

            <div style="display: flex; align-items: center;">
              <div style="font-size: 20px; margin-left: 4px;">Add New Medicine</div>
            </div>
            <div style="display: flex; align-items: center;">

              <div #tooltip="matTooltip" (click)="dialog.open(add_new_drug_modal);"
                matTooltip="Cannot find drugs by search? Add New." matTooltipPosition="below"
                class="addNewDrugAndMoreInsButton">+New Drug</div>
            </div>
          </div>
          <div
            style="
          display: flex; flex-direction: row; width: 97%; height: 40px; margin: 16px 0px; justify-content: space-between;">
            <div style="position: relative; width: 80%; height: 100%; display: flex; align-items: center;">
              <div *ngIf="medicineLoader" style="position: absolute; right: 8px; height: 1.5rem; width: 1.5rem;"
                class="spinner-border text-muted">
              </div>

              <input #typeAhead id="typeahead-format" type="text" class="form-control" placeholder="{{searchText}}"
                [resultTemplate]="searchDrugResultFormatterTemplate" [(ngModel)]="medicineName"
                [ngbTypeahead]="searchDrugsByTyping" [inputFormatter]="formatMatches" [resultFormatter]="formatter"
                (input)="getSearchedMeds($event.target.value)" (selectItem)="setSelectMedicine($event)"
                [ngClass]="(unselectedDrugName) ? 'errorBorder' : 'commonBorder'" (ngModelChange)="onChangeDrugName()"
                style="
              height: 100%;
              width: 100%;
              border-radius: 8px;
              outline: none;    
              font-size: 18px;
              padding-left: 16px;" name="fname" popupClass="background-color:red">
            </div>

            <mat-form-field style="width: 90px; margin: 0px 6px;">
              <mat-label>Drug Type</mat-label>
              <mat-select placeholder="{{drugType}}" [formControl]="drugTypeField" [value]="selectedDrugType" required
                (selectionChange)="dropdownSelect('drugType',$event.value)">
                <mat-option *ngFor="let item of drugTypeSelection" [value]="item">{{item}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>

          <!-- add drug -->

          <div *ngFor="let dose of doseCount; let i = index"
            style="margin-left: 8px; display: flex; flex-direction: row;  justify-content: space-around; align-items: center; ">

            <div style="display: flex; flex-direction: column; ">
              <div *ngFor="let element of frequencyWithVal.slice(0, frequencyCollapsibleVariable); let j = index"
                style="display: flex; flex-direction: row; justify-content: center; align-items: center; margin-bottom: 6px;">

                <div style="display: flex; flex-direction: row;" *ngIf="selectedUnit !='Apply Locally'">

                  <input *ngIf="i==0" class="unitInput" type="number" min="0" step="any" [(ngModel)]="element.value"
                    (input)="onUnitChange($event.target.value, j)" id="drugUnit_{{j}}"
                    onKeyPress="if(this.value.length==5) return false;" />

                  <!-- <input *ngIf="i!=0" class="unitInput" type="number" min="0" step="any"
                    (input)="onUnitChange($event.target.value)" id="drugUnit_{{i}}" /> -->

                  <span *ngIf="drugTypeUnit !='Fingertip'" style="padding: 6px;
                    margin-top: 2px;
                    font-size: 16px;
                    width: 60px;
                    font-weight: 500;
                    color: #333;">{{drugTypeUnit}}</span>
                </div>



                <div style="font-size: 16px; width: 100px;"> x {{element.name}}</div>


              </div>

              <a (click)="toggleFrequencyCollapse()" style="color: #F57F7F;">Show {{frequencyCollapsibleVariable
                == 4 ? 'More' : 'Less' }} </a>

            </div>

            <!-- <img style="width:24px; margin-bottom: 6px; color:#007d7e;" src="assets/images/curly_brace.svg"
              class="largerCheckbox" /> -->

            <div style="display: flex; flex-direction: row;">


              <!-- Drug Unit -->
              <mat-form-field id="fourth" style="width: 110px; margin: 0px 6px;" *ngIf="i == 0">
                <!-- <mat-label>When to take</mat-label> -->
                <mat-select placeholder="Drug Unit" [formControl]="DrugUnitField" [value]="selectedDrugUnit"
                  (selectionChange)="dropdownSelect('drugUnit',$event.value)">
                  <mat-option *ngFor="let item of drugUnitTypes" [value]="item">{{item}}</mat-option>
                </mat-select>
              </mat-form-field>


              <!-- WHEN TO TAKE -->
              <mat-form-field id="fourth" style="width: 110px; margin: 0px 6px;" *ngIf="i == 0">
                <!-- <mat-label>When to take</mat-label> -->
                <mat-select placeholder="{{Whentotake}}" [formControl]="WhentotakeField" [value]="dose.whenToTake"
                  (selectionChange)="dropdownSelect('whenToTake',$event.value)">
                  <mat-option *ngFor="let item of whenToTake" [value]="item">{{item}}</mat-option>
                </mat-select>
              </mat-form-field>


              <!-- DURATION -->
              <mat-form-field id="third" style="width: 90px; margin: 0px 6px;">
                <!-- <mat-label>Duration</mat-label> -->
                <mat-select placeholder="{{Duration}}" [value]="dose.duration" [formControl]="DurationField"
                  (selectionChange)="dropdownSelect('duration',$event.value)">
                  <mat-option *ngFor="let day of duration" [value]="day">
                    {{day}}
                  </mat-option>
                </mat-select>
              </mat-form-field>

            </div>

            <div id="fourth" style="width: 100px; margin: 0px 6px;" *ngIf="i != 0">

            </div>

            <!-- <img (click)="removeDose(i, $event)" *ngIf="i != 0"
              style="width:24px; margin-bottom: 6px; color:#007d7e;" src="assets/images/cross_red.svg"
              class="largerCheckbox" /> -->
          </div>


          <div
            style="display: flex; flex-direction: row; justify-content: space-between; width: 82%; padding-top: 0px; align-items: center;">

            <div style="display: flex; align-items: center;width: '100%';">
              <app-language-select-textbox #lngMedInstr [width]="100" styleForWidth="instructions"
                placeholder="Instructions" style="padding-left: 0px; width: 100%;">
              </app-language-select-textbox>
              <div style="display: flex; align-items: center; margin-right: 23px;">

                <div (click)="dialog.open(medicine_instructions_modal);" #tooltip="matTooltip" style="margin-top: 2px; "
                  matTooltip="Cannot find drugs by search? Add New." matTooltipPosition="below"
                  class="addNewDrugAndMoreInsButton">+More</div>
              </div>
            </div>
          </div>




          <div style="display: flex; flex-direction: row; justify-content: space-between;">
            <div (click)="addToList()" onclick="event.stopPropagation();" class="addToListBtn">Add to List</div>
            <div (click)="closeAddTolistPop()" class="addToListCanclebtn">Cancel</div>
          </div>


        </div>


      </div>



    </div>
  </div>



</div>





<ng-template #searchDrugResultFormatterTemplate let-r="result" let-t="term">
  <ngb-highlight style="font-weight: bold;" [result]="r.product_name.toUpperCase()" [term]="t"></ngb-highlight>
  <label *ngIf="r.product_composition && r.product_composition!=''" for="">&nbsp;( </label>
  <ngb-highlight *ngIf="r.product_composition && r.product_composition!=''" [result]="r.product_composition">
  </ngb-highlight>
  <label *ngIf="r.product_composition && r.product_composition!=''" for=""> )</label>

</ng-template>

<ng-template #delete_modal>
  <h4>Delete Medicine?</h4>
  <div style="color: #555; font-size: 16px;">Are you sure you want to delete selected medicine?</div>
  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
    <button (click)="closeDialog()" class="cancelbtn">Cancel</button>

    <button (click)="deleteAction()" style="background-color: #f44336;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            border: 0px;
            margin: 4px;
          outline: none;">Delete</button>
  </div>
</ng-template>


<ng-template #delete_modalFav>
  <h4>Delete Favourite?</h4>
  <div style="color: #555; font-size: 16px;">Are you sure you want to delete this favourite collection?</div>
  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
    <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 4px;
                color: #444;
                outline: none;">Cancel</button>

    <button (click)="onClickDeleteFavMed()" style="background-color: #f44336;
            padding: 8px 16px;
            border-radius: 6px;
            color: #fff;
            font-weight: bold;
            border: 0px;
            margin: 4px;
          outline: none;">Yes, Delete</button>
  </div>
</ng-template>


<ng-template #add_new_drug_modal let-modal>
  <div class="modal-header" style="border-bottom: 0px; padding: 0px;">
    <div style="display: flex; flex-direction: column;">
      <h4 class="modal-title" id="modal-basic-title">Add New Drug</h4>

      <app-alertbox *ngIf="medicineName.length > 2" [title]="'Medicine not found'"
        [subtitle]="'The medicine name you have selected is not found in our system, you may need to add this medicine once in our system.'"
        [type]="'Warning'"></app-alertbox>

    </div>

  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <div style="flex-direction: column;" class="input-group">

          <div style="flex-direction: row; display: flex; width: 100%;">
            <div style="width: 50%;">
              <div style="color: grey;">Drug Name</div>
              <!-- (input)="onSearchChange($event.target.value, 'drugTypes')" -->
              <input [(ngModel)]="drugNameAddNewDrugs" id="searchbox" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; 
            font-size: 16px; width: 97%;" class="form-control" placeholder="Enter Drug Name" name="drugName">
            </div>

            <div style="width: 50%;">
              <div style="color: grey;">Drug Type</div>
              <div>
                <input placeholder="Enter Drug Type" type="text" aria-label="Number" matInput [matAutocomplete]="auto"
                  [formControl]="myControl" [(ngModel)]="drugTypeAddNewDrugs" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; width: 91%;
              font-size: 16px; z-index: 0;">
                <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete"
                  (optionSelected)="dropdownSelect('drugType',$event.option.value)">
                  <mat-option *ngFor="let option of drugTypes | async" [value]="option">
                    {{option}}
                  </mat-option>
                </mat-autocomplete>
              </div>
            </div>
          </div>

          <div style="flex-direction: row; display: flex; width: 100%; margin-top: 16px;">
            <div style="width: 50%;">
              <div style="color: grey;">Generic Name (Drug Composition)</div>
              <!-- (input)="onSearchChange($event.target.value, 'drugTypes')" -->
              <input [(ngModel)]="genericDrugNameAddNewDrugs" id="generic" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px;
            font-size: 16px; width: 97%;" class="form-control" placeholder="Enter Drug Generic Name"
                name="genericName">
            </div>

            <div style="width: 50%;">
              <div style="color: grey;">Drug Company</div>
              <div>
                <!-- (input)="onSearchChange($event.target.value, 'drugTypes')"  -->
                <input [(ngModel)]="drugCompanyAddNewDrugs" id="drugCompany" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top:0px; width: 97%;
              font-size: 16px; z-index: 0;" class="form-control" placeholder="Enter name of Drug Company"
                  name="drugCompany">
              </div>
            </div>
          </div>


        </div>

        <h5 class="modal-title" style="margin-top: 20px;" id="modal-basic-title">Set custom dosage for drug</h5>
        <div style="background-color: #F7F6F9; border-radius: 6px;">
          <div>

            <input class="unitInput" type="number" min="0" (input)="onUnitChange($event.target.value)"
              [(ngModel)]="unitForNewMed" name="unitfornewmed" style="border: 1px solid #949494;
                border-radius: 6px;
                height: 40px;
                width: 60px;
                font-size: 18px;
                color: #333333;
                font-weight: bold;
                padding: 6px;" />

            <span style="padding: 6px;
            margin-top: 0px;
            font-size: 16px;
            width: 50px;
            font-weight: 500;
            color: #333;">{{drugTypeUnit}}</span>

            <mat-form-field style="width: 300px; margin: 0px 6px;">
              <!-- <mat-label>Frequency</mat-label> -->
              <mat-select placeholder="{{toFrequency}}" [formControl]="FrequencyFieldNewMed"
                [value]="frequencyForNewMed" multiple
                (selectionChange)="dropdownSelect('frequencyNewMed',$event.value)">
                <mat-option *ngFor="let item of frequency" [value]="item">{{item}}

                </mat-option>
              </mat-select>
            </mat-form-field>


            <mat-form-field id="third" style="width: 90px; margin: 0px 6px;">
              <!-- <mat-label>Duration</mat-label> -->
              <mat-select placeholder="{{Duration}}" [formControl]="DurationFieldNewMed" [value]="durationForNewMed"
                (selectionChange)="dropdownSelect('durationNewMed',$event.value)">
                <mat-option *ngFor="let day of duration" [value]="day">
                  {{day}}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field id="fourth" style="width: 110px; margin: 0px 6px;">
              <!-- <mat-label>When to take</mat-label> -->
              <mat-select placeholder="{{Whentotake}}" [formControl]="WhentotakeFieldNewMed"
                [value]="whenToTakeForNewMed" (selectionChange)="dropdownSelect('whenToTakeNewMed',$event.value)">
                <mat-option *ngFor="let item of whenToTake" [value]="item">{{item}}</mat-option>
              </mat-select>
            </mat-form-field>

          </div>

          <input type="text" class="form-control" [(ngModel)]="instructionsForNewMed"
            (input)="updateNewMedInstructions($event.target.value)" placeholder="Instructions" style="
            width: 100%;
            border-radius: 0px;
            outline: none;    
            font-size: 16px;
            border:0px;
            padding-left: 16px;" id="fname" name="fname">



        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">

    <button (click)="closeDialog()" style="background-color: #eee;
    padding: 8px 16px;
    border-radius: 6px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    border: 0px;
    margin: 8px 0px;
    color: #444;
    outline: none;">Cancel</button>

    <div (click)="saveModalValues('drugTypesAddNewDrug'); dialog.closeAll()" class="btn-type-filled"
      style="background-color:#5D86CC; font-size: 16px;">Add New Drug</div>
  </div>
</ng-template>


<ng-template #medicine_instructions_modal let-modal style="width: 55vw;">
  <div class="modal-header" style="border-bottom: 0px;">
    <h4 class="modal-title" id="modal-basic-title">Instructions</h4>
    <button type="button" class="close" style="outline: none; border-width: 0px;" aria-label="Close"
      (click)="closeDialog()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <div class="input-group">
          <input id="searchbox" (input)="onSearchChange($event.target.value, 'instructions')" style="border-radius:6px; padding: 0px 8px 0px 8px; height: 40px; margin-top: -20px;
        font-size: 16px;" class="form-control" placeholder="Start typing to search" name="dp">
        </div>
        <div style="height: 80vh; overflow: scroll;">
          <div
            style="display: flex; flex-direction: row; flex-wrap: wrap; margin-top: 16px; margin-bottom: 16px; width: 45vw; ">

            <div *ngFor="let item of allMedicineInstructions; let i = index" (click)="selectModalValue(i)"
              class="searchable-component-style" [ngStyle]="{'background-color': item.selected ? '#E5F6F4' : '#fff'}">
              <img *ngIf="item.selected  != true" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
                src="assets/images/tick_mark_grey.svg" class="tick-icon" />

              <img *ngIf="item.selected" style="margin: 0px 8px 0px 0px; height: 18px; width: 18px;"
                src="assets/images/tick_mark_color.svg" class="tick-icon" />
              <div style="font-size: 16px;">{{item.name}}</div>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <div (click)="saveModalValues('medicine_instructions'); closeDialog()" class="btn-type-filled"
      style="background-color: #5D86CC; font-size: 15px;">Add Instructions</div>
  </div>
</ng-template>

<ng-template #incomplete_data_modal>
  <h4>Mandatory Fields</h4>
  <div style="color: #555; font-size: 16px;">Please fill all the required fields to save medicine.</div>
  <div style="padding-top: 16px; display: flex; justify-content: flex-end;">
    <button (click)="closeDialog()" style="background-color: #eee;
                padding: 8px 16px;
                border-radius: 6px;
                color: #fff;
                font-weight: bold;
                border: 0px;
                margin: 8px;
                color: #444;
                outline: none;">Ok</button>
  </div>
</ng-template>


<p-toast position="top-right" [baseZIndex]="5000">
  <ng-template let-message pTemplate="message">
    <div class=" p-flex p-flex-column"
      style="flex: 1; background-color:#BAE8FC; opacity: 0.95; display: flex; flex-direction: row; align-items: center; border-left: 8px solid #0D8CCD; border-radius: 8px;">
      <div>
        <img (click)="goBack()" style="height: 36px; margin-left: 12px; width: 36px; align-self: center;"
          src="assets/images/exclamation-circle-blue.svg" alt="" />
      </div>
      <div class=" p-text-center">
        <div style="font-size: 18px;
              font-weight: bold;
              padding: 12px 0px 0px 12px;
              
              color: #034A70;">{{message.summary}}</div>
        <div style="padding-left: 12px; padding-bottom: 12px; color: #034A70; font-size: 16px; margin-top: -6px;">
          {{message.detail}}</div>
      </div>
    </div>
  </ng-template>
</p-toast>