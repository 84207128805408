
<div id="desktop" *ngIf="!mobile">
<div id="top_header"
    style="display: flex;flex-direction: row;  background-color:#ffffff;  height:8% !important; width:100%; align-items: center;">

    <div style=" display: flex; width: 100%;  align-items: center;">
        <!-- Search Bar -->
        <div style="color: #fff;
        background-color: #3d77c7;
        width: 45%;
        height: 25%;
        border-radius: 4px 3px 100px 0px"> 
        <div style="display:flex; flex-direction: row;"> <img style="border-radius: 53%;
          background-color: #fff;
          height: 52px;
          width: 63px;
          margin: 6px 9px 9px 18px;" _ngcontent-c0="" alt="" width="108px" src="assets/images/copious-logo.png">
          <div style="font-size: 18px;font-weight: 600; margin: 17px; ">Dr.{{doctorName}} </div>  
        </div>
        
        </div>
      </div>
    <div style="display: flex;flex-direction: row;justify-content: end;">
        <button (click)="openNewSpecialVaccine();" class="btn btn-secondary"
        style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

        <mat-icon >
           call
        </mat-icon>

        <span style="margin-left: 4px;">Call Now</span>
    </button>

    <button (click)="openNewSpecialVaccine();" class="btn btn-secondary"
    style="margin-top: 6px; display: flex; justify-content: center; align-items: center;">

    <mat-icon >
      calendar_today
    </mat-icon>

    <span style="margin-left: 4px;">Book Appointment</span>
</button>
  </div>
      

         <div style="font-size: 16px; color: rgb(139, 136, 136); margin-left: 20px; font-weight: bold; "></div>
   

</div>

<div id="doctorcontent" style="background-color:#fff">
  <div style="display:flex;flex-direction: column; ">

  <div style="display:flex ; flex-direction: row;">

   

<div class="card" style="width:33% ;height: 40vh;">
  <div style="display:flex; flex-direction: row;">
<div>
    <img style="border-radius: 50%;border:1px solid #eee;
     box-shadow: 1px 1px 8px #eee;
    background-color: #fff;
    margin: 11px;
    height: 90%;
    width: 84%;" _ngcontent-c0="" alt="" width="149px"  [src]="profileImage">
    </div>
 <div style="display:flex; flex-direction: column;  margin: 34px 10px;">
  <div>
  <div style="font-size: 18px;font-weight: 600; ">Dr.{{doctorName}}</div>
  <div style="display:flex ; flex-direction: row; ">
    <div style="font-size: 16px;">{{degree}}</div>&nbsp;&nbsp;
    <div style="font-size: 16px;"><mat-icon [ngClass]="'blue-icon'">fiber_manual_record</mat-icon>{{speciality}}</div>&nbsp;&nbsp;
    <div style="font-size: 16px;"><mat-icon [ngClass]="'blue-icon'">fiber_manual_record</mat-icon>{{experience}} Years Experience </div>
  </div>
  <div style="display:flex ; flex-direction: row; ">
    <div style="font-size: 16px;"><mat-icon [ngClass]="'email-icon'">call</mat-icon>+{{mobileNumber}}</div>&nbsp;&nbsp;
    <div style="font-size: 16px;"><mat-icon [ngClass]="'email-icon'">email</mat-icon>{{email_id}}</div>
  </div>
</div>
</div>
   
  </div>

  <div style="margin: 21px;">Dr. Rashmi Gapchup is a Pediatrician and has an experience of 36 years in this field. She completed MBBS from MKCG Medical College, Berhampur, Orissa in 1983 and DNB - Paediatrics from King Edward Memorial Hospital and Seth Gordhandas Sunderdas Medical College in 1989</div>

  
</div>

<div class="card" style="width:35% ;height: 40vh;">
  <div style="font-size: 20px;font-weight: 600; margin: 11px 0px 0px 16px;"> <mat-icon >
    alarm
  </mat-icon>Clinic Timings: </div>
  <div style="display:flex ;flex-direction: row; justify-content: space-between; margin: 6px 5px 6px 56px;">

 
  <div style="font-size: 18px;font-weight: 600;">Morning Timings</div>
  <div style="font-size: 18px;font-weight: 600; margin-right: 108px;">Evening Timings</div>
</div>
<div *ngFor="let timing of clinicAdress.ClinicSchedules" style="display:flex; flex-direction: row; justify-content: space-between;
margin: 0px 0px 6px 56px; ">
  <div style="font-size: 16px;">  {{timing.day.substring(0,3)}} : {{timing.morning_from}} - {{timing.morning_to}}</div>
  <div style="font-size: 16px; margin-right: 108px;">   {{timing.evening_from}} - {{timing.evening_to}}</div>

</div>

</div>

<div class="card" style="width:30% ;height: 40vh;">
  <div style="font-size: 20px;font-weight: 600; margin: 11px 0px 0px 16px;"> <mat-icon >

  </mat-icon>Address: </div>
  <div style="display: flex; flex-direction: column; margin: 10px 0px 0px 40px; ">
    <div>4, Yashraj Complex, opposite Deenanath Mangeshkar Hospital, </div>
    <div >Erandwane, Pune, Maharashtra 411004</div>
  
  </div>
</div>



</div>
<div style="display:flex ;flex-direction: row; justify-content: center; width: 100%;">
  <div style="width: 19%;"></div>
<div class="card" style="width:48% ;height: 39vh;">
  <div class="content" style=" width: 87%;
  margin: 17px 25px 15px 34px; ;" [innerHTML]="doctorLocationLink"></div>
</div>
</div>
</div>


<div id="filler" style="height: 0px;">
  &nbsp;
</div>

<div class="footer">
   
   <div style="display: flex; flex-direction: row;  width: 126%;  align-items: center;">


    <div style="display:flex; flex-direction: row; width: 31%;"> <img style="border-radius: 53%;
      background-color: #fff;
      height: 112px;
     
      margin: 6px 9px 9px 18px;" _ngcontent-c0="" alt="" width="208px" src="assets/images/copious-logo.png">
      <div style="font-size: 18px;font-weight: 600; margin: 17px; ">Dr.{{doctorName}} </div>  
    </div>
    <div style="color: #fff;
    background-color: #3d77c7;
    width: 190%;
    height: 17vh;
    border-radius: 53px 3px 0px 53px"> 

<div style="display: flex; flex-direction: row; justify-content: end; margin-top: 10px;">
  <img _ngcontent-c0="" alt="" width="120px" height="30%" src="assets/images/copious-footer-appstore-icon.png">
  <img _ngcontent-c0="" alt="" width="120px" height="30%" src="assets/images/copious-footer-playstore-icon.png">
</div> 
<div style="display:flex; flex-direction: row; justify-content: space-around;">
<div style="display: flex; flex-direction: column; ">
  <div>4, Yashraj Complex, opposite Deenanath Mangeshkar Hospital, </div>
  <div >Erandwane, Pune, Maharashtra 411004</div>

</div>
<div>
  <div style="font-size: 16px;"><mat-icon [ngClass]="'email-icon'">email</mat-icon>{{email_id}}</div>
</div>
<div>
  <div style="font-size: 16px;"><mat-icon [ngClass]="'email-icon'">call</mat-icon>+{{mobileNumber}}</div>
</div>
<div>
  <div style="font-size: 16px;"><mat-icon [ngClass]="'email-icon'">language</mat-icon>www.copious.care</div>
</div>
<div>
 To Book Appointment and skip Queues<br>
 download Health by Copious App
</div>
</div>
    
    </div>

  

     <div style="font-size: 16px; color: rgb(139, 136, 136); margin-left: 20px; font-weight: bold; "></div>
</div> 


</div> 

</div>

</div>

<div  id="mobile" *ngIf="mobile">
<div id="mobile" *ngIf="mobile" style="display: flex;flex-direction: row;  background-color:#ffffff;  height:8% !important; width:100%; align-items: center;">
 
  <div style=" display: flex; width: 100%;  align-items: center;">
    <!-- Search Bar -->
    <div style="color: #fff;
    background-color: #3d77c7;
    width: 100%;
    height: 25%;
    border-radius: 4px 3px 100px 0px"> 
    <div style="display:flex; flex-direction: row;"> <img style="border-radius: 53%;
      background-color: #fff;
      height: 52px;
      width: 63px;
      margin: 6px 9px 9px 18px;" _ngcontent-c0="" alt="" width="108px" src="assets/images/copious-logo.png">
      <div style="font-size: 18px;font-weight: 600; margin: 17px; ">Dr.{{doctorName}} </div>  
    </div>
    
    </div>
  </div>

</div>

<div>
  <div class="card" style="width:93% ;height: fit-content;">
    <div style="display:flex; flex-direction: column;">
  <div>
      <img style="border-radius: 50%;border:1px solid #eee;
       box-shadow: 1px 1px 8px #eee;
      background-color: #fff;
      margin: 25px;
      height: 90%;
      width: 84%;" _ngcontent-c0="" alt="" width="149px"  [src]="profileImage">
      </div>
   <div style="display:flex; flex-direction: column; justify-content: center;  margin: 34px 10px;">
    <div>
    <div style="font-size: 18px;font-weight: 600; text-align: center; ">Dr.{{doctorName}}</div>
    <div style="display:flex ; flex-direction: row; justify-content: center; ">
      <div style="font-size: 16px;">{{degree}}</div>&nbsp;&nbsp;
      <div style="font-size: 16px;"><mat-icon [ngClass]="'blue-icon'">fiber_manual_record</mat-icon>{{speciality}}</div>&nbsp;&nbsp;
      <div style="font-size: 16px;"><mat-icon [ngClass]="'blue-icon'">fiber_manual_record</mat-icon>{{experience}} Years Experience </div>
    </div>
    <div style="display:flex ; flex-direction: row; justify-content: center; ">
      <div style="font-size: 16px;"><mat-icon [ngClass]="'email-icon'">call</mat-icon>+{{mobileNumber}}</div>&nbsp;&nbsp;
      <div style="font-size: 16px;"><mat-icon [ngClass]="'email-icon'">email</mat-icon>{{email_id}}</div>
    </div>
  </div>
  </div>
     
    </div>
  
    <div style="margin: 10px;">Dr. Rashmi Gapchup is a Pediatrician and has an experience of 36 years in this field. She completed MBBS from MKCG Medical College, Berhampur, Orissa in 1983 and DNB - Paediatrics from King Edward Memorial Hospital and Seth Gordhandas Sunderdas Medical College in 1989</div>
  
    
  </div>
 </div>

 <div class="card" style="width:92% ;height: fit-content;">
  <div style="font-size: 20px;font-weight: 600; margin: 11px 0px 0px 16px; text-align: center;"> <mat-icon >
    alarm
  </mat-icon>Clinic Timings: </div>
  <div style="display:flex ;flex-direction: row; justify-content: space-between; margin: 6px 5px 6px 39px; width: 108%;">

 
  <div style="font-size: 18px;font-weight: 600;">Morning Timings</div>
  <div style="font-size: 18px;font-weight: 600; margin-right: 108px;">Evening Timings</div>
</div>
<div *ngFor="let timing of clinicAdress.ClinicSchedules" style="display:flex; flex-direction: row; justify-content: space-between;
margin: 0px 0px 6px 39px;width: 108%; ">
  <div style="font-size: 16px;">  {{timing.day.substring(0,3)}} : {{timing.morning_from}} - {{timing.morning_to}}</div>
  <div style="font-size: 16px; margin-right: 108px;">   {{timing.evening_from}} - {{timing.evening_to}}</div>

</div>

</div>

<div class="card" style="width:92% ;height: fit-content;">
  <div style="font-size: 20px;font-weight: 600; margin: 11px 0px 0px 16px;"> <mat-icon >

  </mat-icon>Address: </div>
  <div style="display: flex; flex-direction: column; margin: 10px 0px 0px 40px; ">
    <div>4, Yashraj Complex, opposite Deenanath Mangeshkar Hospital, </div>
    <div >Erandwane, Pune, Maharashtra 411004</div>
  
  </div>
</div>

<div style="display:flex ;flex-direction: row; justify-content: center; width: 100%;">
 
<div class="card" style="width:92% ;height:fit-content;">
  <div class="content" style=" width: 87%;
  margin: 17px 25px 15px 28px; ;" [innerHTML]="doctorLocationLink"></div>
</div>
</div>


</div>