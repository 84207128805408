import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../../services/auth.service'
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { PatientService } from '../../../services/patient.service'
import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";
import { ToastrService } from "ngx-toastr";
import { GlobalService } from "../../../services/global.service";
import { MessageService } from 'primeng/api';
import * as $ from 'jquery'
import * as moment from 'moment';
import { DoctorService } from '../../../services/doctor.service';


import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { refreshService } from '../../../services/refersh.service';
import { Constants } from '../../../../config/constants';

let habbits = [{ name: 'Chewing', past: '0', present: '0' }, { name: 'Smoking', past: '0', present: '0' }, { name: 'Drinking', past: '0', present: '0' }, { name: 'Drugs', past: '0', present: '0' }, { name: 'other', past: '0', present: '0' }]
let tabForOnBording = [{ name: "Profile", selected: true, value: "", highlighted: true }, { name: "Health Profile", selected: false, value: "", highlighted: false }, { name: "Family Profile", selected: false, value: "", highlighted: false }]
let gender = [{ name: "Female", selected: false, value: "" }, { name: "Male", selected: false, value: "" }]

@Component({
  selector: 'app-patient-onboard',
  templateUrl: './patient-onboard.component.html',
  styleUrls: ['./patient-onboard.component.scss'],
  providers: [MessageService],
})
export class PatientOnboard implements OnInit {

  cropperSettingsForProfile: CropperSettings;
  //commenting-for-release
  // @ViewChild("cropper")



  @ViewChild("patientListModal") patientListModal;
  @ViewChild("continueProfileModal ") continueProfileModal;
  @ViewChild("doneProfileModal") doneProfileModal;
  @ViewChild("letmessageStick") letmessageStick;
  @ViewChild("checkDateModal") checkDateModal;

  profileImagePath = Constants.APICONFIG.patientProfileImagePath
  
  cropper: ImageCropperComponent;
  cropperDisplayforProfile: boolean = false;
  dataPic: any;
  date: any;
  userId = null
  patientId = null
  imageError: String;
  profileImageofpatient = null;
  bloodGroups = [];
  habbitCheckBox = false;
  stdCode: any = '+91';
  closeResult: any;
  minDate: any;
  showHighRisk = false;
  showInfoBirth = false;
  showProfile = false;
  showHealthProfile = false
  showFamilyProfile = false;
  tabArray: any = [];
  modifyRmn = false;
  displayForProfile: boolean = false;
  showCurrentTab = false;
  first_name: any = "";
  middle_name = "";
  last_name = "";
  gender = 'male';
  languageForParent = 'en';
  dob: any;
  eDob: any;
  vacDate: any;
  ageYears: any;
  showAgeYear = false;
  ageMonths: any;
  ageWeeks: any;
  mobileNumber = '';
  otp: any;
  email = '';
  selectGender: any;
  relation = null;
  blood_group = null;
  maleRelations = ["Self", "Son", "Spouse", "Father", "Brother", "Uncle", "G. Father", "Other",];
  femaleRelations = ["Self", "Daughter", "Spouse", "Mother", "Sister", "Aunty", "G. Mother", "Other",];
  typeOfDelivery = 'Normal';
  fathersName = '';
  mothersName: any = "";
  babyOf = false;
  isHighRisk = false;
  patientNewData:any={}
  newBornScreening: Number;
  birth_weight = '';
  birth_height = '';
  birth_hc = '';
  birth_cc = '';
  birth_bpsys = '';
  birth_bpdia = '';
  otherIDNumber = '';
  fathersHeightCm = '';
  mothersHeightCm = '';
  fathersHeightFt = { ft: '0', in: '0' };
  mothersHeightFt = { ft: '0', in: '0' };
  showOtherInformation = false;
  showDate = false;
  fathersHeightInFt = 'ft';
  mothersHeightInFt = 'ft';
  isPediatric = false;
  isBabyOf = false;
  gestationalAge: any;
  isPreTerm: any;
  medicalConditions: string = '';
  highRiskType: string = "";

  familyMedicalConditions: string = '';
  geneticDisorders: string = '';
  familyGeneticDisorders: string = '';
  allergies = '';
  recurringComplaints = '';
  accidentalHistory = '';
  typeOfDeliveryArr = ['Normal', 'Vaccum', 'Forceps', 'C-Section'];
  heightArr = ['ft', 'cm'];
  medicalConditionArrayOg: any = [];
  geneticDisordersOg: any = [];
  geneticDisordersArray: any = [];
  medicalConditionArray: any = [];
  familyMedicalArray: any = [];
  highRiskTypeArray: any = [];
  highRiskTypeOg: any = [];
  familyMedicalArrayOg: any = [];
  familyGeneticDisordersArray: any = [];
  familyGeneticDisordersOrg: any = [];
  isOTPConfirmed = false;
  errors: any = {};
  habbitsArray: any = { Chewing: { past: 0, present: 0 }, Smoking: { past: 0, present: 0 }, Drinking: { past: 0, present: 0 }, Drugs: { past: 0, present: 0 }, Other: { past: 0, present: 0 } }
  habbits: any;
  imageUrlS3 = '';
  isEdit = false;
  showLoading = false;
  showFamilyProfilePed = false;
  showHealthHistory = false;
  recievedOtp = '';
  patientObj: any
  patientResponse: any
  field2: any = '';
  firstname: any;
  confrimMobile = false;
  dobDate: any;
  genderArry: any = [];
  showFirstName = false;
  selectedMenuToTake: any = '';
  selectedFormValue: any = {}
  secondNumber: any = "";
  patientList: any = [];
  checkValue = '';
  checkValueHeelPrickTest: any = ''
  uploadTestValue: any;
  checkedValue = false
  heelPrickTest = false
  initialName: any
  patientImage:any
  nameInitials: any;
  patientData: any = {}
  patientDataUpdate: any = {};
  showUpdateButton = false;
  onbordingMenu: any = ["Profile", "Antenetal & Birth History", "Health History", " Family Profile"];
  userID = "";
  isAppointmentEnabled = '0';

  constructor(private authService: AuthService,  private refreshservice: refreshService, private route: ActivatedRoute, private router: Router, private messageService: MessageService, private patientService: PatientService, private toastr: ToastrService, private globalService: GlobalService, private modalService: NgbModal, private toaster: ToastrService, private doctorService: DoctorService,
  ) {

    this.cropperSettingsForProfile = new CropperSettings();
    this.cropperSettingsForProfile.rounded = true;
    this.cropperSettingsForProfile.width = 75;
    this.cropperSettingsForProfile.height = 75;
    this.cropperSettingsForProfile.croppedWidth = 75;
    this.cropperSettingsForProfile.croppedHeight = 75;
    this.cropperSettingsForProfile.canvasWidth = 400;
    this.cropperSettingsForProfile.canvasHeight = 400;
    this.cropperSettingsForProfile.cropperDrawSettings.strokeColor = "#b147b1";
    this.profileImageofpatient = "assets/images/user_default.png";
    this.dataPic = {};
    this.date = this.reverseDate(new Date());
    this.tabArray = tabForOnBording;
    this.habbits = habbits
    this.genderArry = gender;
    this.userID = this.authService.getUser();
  }

  ngOnInit() {

    this.showDate = true
    this.showProfile = true;
    this.isHighRisk = false;
    this.highRiskType = ""
    this.checkValue = "No"
    this.switchMenu(0);

    // this.getPatientInfo();

    setTimeout(() => {
      this.genderArry.map(ins => ins.selected = false);
    })
    setTimeout(() => {
      this.habbits.map(ins => {
        if (ins.past == "1" && ins.present == "1") {
          ins.past = "0"
          ins.present = "0";
        }

      })

    })
    this.stdCode = "+91";
    this.userId = this.authService.getUser()
    this.patientId = localStorage.getItem('patientId');

    this.getConstants();
    this.getAllRequiredConstants();
    // this.onFormValue();
    if (history.state.data) {
      this.patientObj = history.state.data
      this.patientImage=this.patientObj.User.profile_photo
      this.isEdit = true;

      this.getPatientInfo();

    }
    setTimeout(() => {
      this.vacDate = moment(this.parseDate(this.vacDate)).format("YYYY-MM-DD");

    })
    setTimeout(() => {
      this.removeTabFromCollection("Antenetal & Birth History");
      this.removeTabFromCollection("Health History");

    })

    setTimeout(() => {

      if (this.patientId !== '' && this.patientId !== undefined && this.patientId !== null) {



        var age = moment().diff(moment(new Date(this.parseDate(this.dob)), 'DD-MMM-YYYY'), 'years');

        if (age < 12) {
          this.removeTabFromCollection("Antenetal & Birth History");
          this.checkIfTabsArrayContains("Antenetal & Birth History")
          this.removeTabFromCollection("Health History");
          this.tabArray.splice(1, 0, { name: "Antenetal & Birth History", selected: false, value: "", highlighted: false })

        }
        else {
          console.log("History........", JSON.stringify(this.tabArray));
          this.removeTabFromCollection("Health History");
          if (!this.checkIfTabsArrayContains("Health History")) {
            console.log("1234");
            this.removeTabFromCollection("Antenetal & Birth History");
            this.tabArray.splice(1, 0, { name: "Health History", selected: false, value: "", highlighted: false })
          }

        }
      }
      else {

        this.tabArray = tabForOnBording;
      }
    }, 500);



    this.selectedFormValue = {
      firstname: '',
      middlename: '',
      lastname: '',
      allergies: '',
      reactions: '',
      recurring: '',
      hospitalisation: '',
      healthHistory: '',
      emailId: '',
      otherId: '',
      birth_weight: '',
      birth_height: '',
      birth_hc: '',
      birth_cc: '',
      birth_bpsys: '',
      birth_bpdia: '',
      healthHistoryHighlights: '',
      mothersName: '',
      fathersName: '',
      addtionalInfo: null,


    }
    setTimeout(() => {
      this.dobDate = moment(this.parseDate(this.dob)).format("DD-MMM-YYYY")
      console.log("date", this.dobDate)

    }, 500)

    this.getDoctorDetails();
  }

  tostFun() {
    setTimeout(() => {
      $(".alert").fadeTo().slideUp(function () {
        $(this).remove();
      });
    })




  }
  tostFunSecondNo() {

    $(".alert").fadeTo().slideUp(function () {
      $(this).remove();
    });



  }
  nextPrev() {

    var d = document.getElementsByClassName("mru");


  }

  pastHabbitsAdd(e, str1, str2) {
    this.habbitsArray[str1][str2] = e.target.checked ? 1 : 0;

    console.log("newHabbits", this.habbitsArray)




  }



  checkBoxValueChnage(e) {
    if (e.target.checked) {
      this.showHighRisk = true;
      this.isHighRisk = true
      this.checkValue = "Yes"
      this.highRiskType = ""
    }
    else {
      this.showHighRisk = false;
      this.isHighRisk = false
      this.checkValue = "No"
      this.highRiskType = ""
    }

  }
  checkHeelPrickTest(e) {
    if (e.target.checked) {

      this.newBornScreening = 1
      this.checkValueHeelPrickTest = "Done"
      this.uploadTestValue = 'Upload heel prick test documents from upload document section'

    }
    else {
      this.newBornScreening = 0
      this.checkValueHeelPrickTest = "No"
      this.uploadTestValue = ""

    }

  }
  onFormValue = (value, identifier) => {
    this.selectedFormValue[identifier] = value
    if(identifier=="firstname"){
      this.selectedFormValue.firstname = value;

    }
   else if(identifier=="middlename"){
      this.selectedFormValue.middlename = value;

    }
  }

  getDoctorDetails = () => {
    let params = {
      user_id: this.userID
    }
    this.doctorService.getDetails(params).subscribe(response => {
      this.isAppointmentEnabled = response.doctor && response.doctor.isAppointmentEnabled ? response.doctor.isAppointmentEnabled : '0';
    })
  }




  switchMenu(index) {

    //here

    var data = this.patientId;
    console.log("dataid", data);



    let array = this.tabArray;
    console.log("tabArray", array);
    this.tabArray[index].selected = true


    // this.tabArray.map((ins, ind)=>{

    //   if(index != ind){
    //     // the one not clicked
    //     if(ind < index){
    //       ins.highlighted = false;
    //     ins.selected = true



    //     }else{
    //       ins.highlighted = false;
    //       ins.selected = false
    //     }

    //   }else{
    //     //the one clicked
    //     ins.highlighted = true;
    //     ins.selected = true;
    //   }

    // })


    array.map((ins, ind) => {

      if (ind <= index) {
        array[index].selected = true



        if (ins.selected == true && ins.highlighted == false) {
          ins.highlighted = true
        }
        else {
          ins.highlighted = false
        }





        // console.log("index",array)


        this.selectedMenuToTake = ins.name;
        this.tabArray.map((ins, ind) => {

          if (index != ind) {
            // the one not clicked
            if (ind < index) {
              ins.highlighted = false;
              ins.selected = true



            } else {
              ins.highlighted = false;
              ins.selected = false
            }

          } else {
            //the one clicked
            ins.highlighted = true;
            ins.selected = true;
          }

        })



        console.log("tabMenu", array)
        if (this.selectedMenuToTake == 'Antenetal & Birth History') {
          if (this.isEdit) {
            //this.addAntenetalBirth();
           this.patientData = this.patientResponse;
          }

          console.log("myPatientData", this.patientData)

          this.dobDate = moment(this.parseDate(this.dob)).format("DD-MMM-YYYY")
          // this.vacDate=moment(this.parseDate(this.vacDate)).format("YYYY-MM-DD");
          this.showInfoBirth = true
          this.showProfile = false
          this.showHealthProfile = false
          this.showFamilyProfile = false
          this.showFamilyProfilePed = false
          this.showHealthHistory = false;


        }
        else if (this.selectedMenuToTake == 'Profile') {
          //this.patientData=this.patientResponse;
          if (this.isEdit) {
            this.patientData = this.patientResponse

          }
          this.showInfoBirth = false
          this.showHealthProfile = false
          this.showProfile = true
          this.showFamilyProfile = false
          this.showFamilyProfilePed = false
          this.showHealthHistory = false;

        }
        else if (this.selectedMenuToTake == 'Health Profile') {
        
         
          //  this.showProfile=true




          this.showCurrentTab = true
          this.showHealthProfile = true;
          this.showInfoBirth = false;
          this.showProfile = false
          this.showFamilyProfile = false
          this.showFamilyProfilePed = false
          this.showHealthHistory = false;

        }

        else if (this.selectedMenuToTake == 'Family Profile') {
        
          //this.patientData=this.patientResponse;
          if (this.ageYears > 12) {
            // this.patientData=this.patientResponse;
            this.showFamilyProfilePed = true;
            this.showHealthProfile = false;
            this.showInfoBirth = false;
            this.showProfile = false
            this.showFamilyProfile = false
            this.showHealthHistory = false;
          }
          else {
            this.showFamilyProfilePed = false
            this.showFamilyProfile = true
            this.showHealthProfile = false;
            this.showInfoBirth = false;
            this.showProfile = false
            this.showHealthHistory = false;
          }
        }
        else if (this.selectedMenuToTake == 'Health History') {
          if (this.isEdit) {
            this.patientData = this.patientResponse
            console.log("healthHistory", this.patientData)


          }
          console.log("helathHistory", this.patientData)

          this.showHealthHistory = true;
          this.showFamilyProfilePed = false
          this.showFamilyProfile = false
          this.showHealthProfile = false;
          this.showInfoBirth = false;
          this.showProfile = false
        }
      } else {
        ins.highlighted = false;
        ins.selected = false;

        // if(ins.selected=true && ind>=ins){
        //   this.showCurrentTab=true;
        // }
        // else{
        //   this.showCurrentTab=false;
        // }
      }

    })

  }


  selectModalValueGender(index) {


    this.genderArry.map((ins, ind) => {
      if (ind == index) {
        this.genderArry[index].selected = true;
        this.selectGender = ins.name;

      } else {
        ins.selected = false;
      }
    });
  

  }

  onChange(value, isFrom) {
    if (isFrom === 'father') {
      if (value === 'cm') {
        this.fathersHeightInFt = 'cm'
      }
      else {
        this.fathersHeightInFt = 'ft'
      }
      this.convertUnitsFather();
    }
    else {
      if (value === 'cm') {
        this.mothersHeightInFt = 'cm'
      }
      else {
        this.mothersHeightInFt = 'ft'
      }
      this.convertUnitsMother();
    }

  }

  convertUnitsFather() {
    if (this.fathersHeightInFt === 'cm' && this.fathersHeightFt) {
      var fatherCmHeight = 0.0;
      if (this.fathersHeightFt.ft != '') {
        if (Number(this.fathersHeightFt.ft) == 0) {
          fatherCmHeight = Math.round((Number(this.fathersHeightFt.in) * 2.54 * 100)) / 100;
          this.fathersHeightCm = fatherCmHeight.toString();
        } else {
          fatherCmHeight = Math.round(((((Number(this.fathersHeightFt.ft) * 12) + Number(this.fathersHeightFt.in)) * 2.54) * 100)) / 100;
          this.fathersHeightCm = fatherCmHeight.toString();
        }

      }
      else {
        fatherCmHeight = Math.round((Number(this.fathersHeightFt.in) * 2.54 * 100)) / 100;
        this.fathersHeightCm = fatherCmHeight.toString();
      }
    }
    else {
      if (this.fathersHeightCm != '') {


        if (Number(this.fathersHeightCm) < 31) {
          var fatherInHeight = 0.0;
          fatherInHeight = Math.round(Number(this.fathersHeightCm) / 2.54 * 10) / 10;
          this.fathersHeightFt = { ft: '0', in: fatherInHeight.toString() };
        }
        else {
          var divideby2 = 0;
          var divideby12 = 0;
          var divideby12Mod = 0;
          divideby2 = Math.round(Number(this.fathersHeightCm) / 2.54 * 10) / 10;
          divideby12Mod = Math.round(divideby2 % 12 * 10) / 10;
          divideby12 = Math.trunc(divideby2 / 12);

          this.fathersHeightFt = { ft: divideby12.toString(), in: divideby12Mod.toString() };
        }
      }

    }

  }
  convertUnitsMother() {
    if (this.mothersHeightInFt === 'cm' && this.mothersHeightFt) {
      var motherCmHeight = 0.0;
      if (this.mothersHeightFt.ft != '') {
        if (Number(this.mothersHeightFt.ft) == 0) {
          motherCmHeight = Math.round((Number(this.mothersHeightFt.in) * 2.54 * 100)) / 100;
          this.mothersHeightCm = motherCmHeight.toString();
        } else {
          motherCmHeight = Math.round(((((Number(this.mothersHeightFt.ft) * 12) + Number(this.mothersHeightFt.in)) * 2.54) * 100)) / 100;
          this.mothersHeightCm = motherCmHeight.toString();
        }
      }
      else {
        motherCmHeight = Math.round((Number(this.mothersHeightFt.in) * 2.54 * 100)) / 100;
        this.mothersHeightCm = motherCmHeight.toString();
      }
    }
    else {
      if (this.mothersHeightCm != '') {


        if (Number(this.mothersHeightCm) < 31) {
          var motherInHeight = 0.0;
          motherInHeight = Math.round(Number(this.mothersHeightCm) / 2.54 * 10) / 10;
          this.mothersHeightFt = { ft: '0', in: motherInHeight.toString() }
        }
        else {
          var divideby2 = 0;
          var divideby12 = 0;
          var divideby12Mod = 0;
          divideby2 = Math.round(Number(this.mothersHeightCm) / 2.54 * 10) / 10;
          divideby12Mod = Math.round(divideby2 % 12 * 10) / 10;
          divideby12 = Math.trunc(divideby2 / 12);

          this.mothersHeightFt = { ft: divideby12.toString(), in: divideby12Mod.toString() }
        }
      }

    }
  }

  confirmOTP() {
    // if (this.recievedOtp === this.otp) {
    this.isOTPConfirmed = true;
    // } else {
    //   this.isOTPConfirmed = false;
    //   this.errors = {};
    //   this.errors["mobileNumber"] = "Please enter valid OTP";
    // }
  }
  validateNumber(e) {
    return ((e.charCode > 47 && e.charCode < 58)) || (e.which == 8) || (e.which == 13) || (e.keyCode == 9) || (e.keyCode == 190) || (e.keyCode == 110) || (e.keyCode == 46)
  }
  showCropperForPatient() {
    this.cropperDisplayforProfile = true;
    this.displayForProfile = false;
  }

  hideCropperForPatient() {
    this.cropperDisplayforProfile = false;
  }
  showDialogForPatient() {
    this.displayForProfile = true;
    document.body.className += " modal-open";
  }
  hideDialogForPatient() {
    this.displayForProfile = false;
    document.body.className = document.body.className.replace("modal-open", "");
  }
  getConstants() {
    this.globalService.getConstants().subscribe(response => {
      this.bloodGroups = response.blood_groups;
    });
  }
  fileChangeListenerForProfile($event) {
    this.hideDialogForPatient();
    this.cropper.reset();
    var image: any = new Image();
    var file: File = $event.target.files[0];

    //validations for profile image. Max file size allowed = 5MB
    if (file.type.match("image.*")) {
      this.imageError = "";
      var myReader: FileReader = new FileReader();
      var that = this;
      myReader.onloadend = function (loadEvent: any) {
        image.src = loadEvent.target.result;
        setTimeout(function () {
          that.cropper.setImage(image);
          // that.cropper.image = image
          that.showCropperForPatient();
          that.cropperDisplayforProfile = true;
        }, 500);
      };
      myReader.readAsDataURL(file);
    } else {
      this.toastr.error("Invalid file format.");
    }
  }

  uploadProfileImageFile() {
    this.profileImageofpatient = this.dataPic.image;
    const data = new FormData();
    data.append("file", this.profileImageofpatient);
    this.patientService.sendImageProfilePic(data).subscribe(response => {
      if (response) {
        this.toaster.success("Success");
        this.showLoading = false;
      }
    })

  }
  selectFileForPatient() {
    document.getElementsByName("patient_profile_pic")[0].click();
  }
  addLabelClass(e) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e) {
    if (e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }


  getAllRequiredConstants = () => {
    this.patientService.getAllRequiredConstants('').subscribe(response => {
      if (response) {

        console.log("Response Constants", response);

        this.medicalConditionArrayOg = response.data.medicalConditions.map(ins => { return { name: ins.name, selected: false } });
        this.medicalConditionArray = response.data.medicalConditions.map(ins => { return { name: ins.name, selected: false } });

        this.familyMedicalArray = response.data.familyConditions.map(ins => { return { name: ins.name, selected: false } });
        this.familyMedicalArrayOg = response.data.familyConditions.map(ins => { return { name: ins.name, selected: false } });

        this.geneticDisordersArray = response.data.geneticDisorders.map(ins => { return { name: ins.name, selected: false } });
        this.geneticDisordersOg = response.data.geneticDisorders.map(ins => { return { name: ins.name, selected: false } });

        this.familyGeneticDisordersArray = response.data.familyGeneticDisorders.map(ins => { return { name: ins.name, selected: false } });
        this.familyGeneticDisordersOrg = response.data.familyGeneticDisorders.map(ins => { return { name: ins.name, selected: false } });

        this.highRiskTypeArray = response.data.highRiskType.map(ins => { return { name: ins.name, selected: false } });
        this.highRiskTypeOg = response.data.highRiskType.map(ins => { return { name: ins.name, selected: false } });




      } else {
        alert('Something went wrong')
      }
    });
  }


  open(content, type) {

    switch (type) {
      case 'medicalConditions':
        this.medicalConditionArray = this.medicalConditionArrayOg;
        this.medicalConditionArray.map((ins, ind) => {
          ins.selected = false;
          this.medicalConditions.split(', ').map(itr => {

            if (ins.name == itr) {
              this.medicalConditionArray[ind].selected = true;
              this.medicalConditionArrayOg[ind].selected = true;
            }
          })
        });
        break;

      case 'familyMedicalConditions':
        this.familyMedicalArray = this.familyMedicalArrayOg;
        this.familyMedicalArray.map((ins, ind) => {
          ins.selected = false;
          this.familyMedicalConditions.split(', ').map(itr => {

            if (ins.name == itr) {
              this.familyMedicalArray[ind].selected = true;
              this.familyMedicalArrayOg[ind].selected = true;
            }
          })
        });
        break;
      case 'geneticDisorders':
        this.geneticDisordersArray = this.geneticDisordersOg;
        this.geneticDisordersArray.map((ins, ind) => {
          ins.selected = false;
          this.geneticDisorders.split(', ').map(itr => {

            if (ins.name == itr) {
              this.geneticDisordersArray[ind].selected = true;
              this.geneticDisordersOg[ind].selected = true;
            }
          })
        });
        break;
      case 'familyGeneticDisorders':
        this.familyGeneticDisordersArray = this.familyGeneticDisordersOrg;
        this.familyGeneticDisordersArray.map((ins, ind) => {
          ins.selected = false;
          this.familyGeneticDisorders.split(', ').map(itr => {

            if (ins.name == itr) {
              this.familyGeneticDisordersArray[ind].selected = true;
              this.familyGeneticDisordersOrg[ind].selected = true;
            }
          })
        });
        break;

      case 'highRiskType':
        this.highRiskTypeArray = this.highRiskTypeOg;
        this.highRiskTypeArray.map((ins, ind) => {
          ins.selected = false;
          this.highRiskType.split(', ').map(itr => {

            if (ins.name == itr) {
              this.highRiskTypeArray[ind].selected = true;
              this.highRiskTypeOg[ind].selected = true;
            }
          })
        });
        break;



      default:
        break;
    }

    this.modalService.open(content, { backdrop: false, size: 'xl', centered: false, windowClass: 'dark-modal' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      console.log("dismissed")
    });
  }

  onSearchChange(searchValue: string, type: string): void {

    let data = [];
    let newData = [];

    switch (type) {
      case 'medicalConditions':
        data = this.medicalConditionArrayOg;
        break;

      case 'familyMedicalConditions':
        data = this.familyMedicalArrayOg;
        break;
      case 'geneticDisorders':
        data = this.geneticDisordersOg;
        break;
      case 'familyGeneticDisorders':
        data = this.familyGeneticDisordersOrg;
        break;
      case 'highRiskType':
        data = this.highRiskTypeOg;
        break;

      default:
        break;
    }

    if (searchValue.length) {
      newData = data.filter(item => {
        return item.name.toLowerCase().includes(searchValue.toLowerCase())
      });
    } else {
      newData = data;
    }


    switch (type) {
      case 'medicalConditions':
        this.medicalConditionArray = newData;
        break;

      case 'familyMedicalConditions':
        this.familyMedicalArray = newData;
        break;
      case 'geneticDisorders':
        this.geneticDisordersArray = newData;
        break;
      case 'familyGeneticDisorders':
        this.familyGeneticDisordersArray = newData;
        break;
      case 'highRiskType':
        this.highRiskTypeArray = newData;
        break;

      default:
        break;
    }

  }

  selectModalValue(index, type) {

    switch (type) {
      case 'medicalConditions':
        if (this.medicalConditionArray[index].selected) {
          this.medicalConditionArray[index].selected = false;
          this.medicalConditionArrayOg[index].selected = false;

          this.medicalConditions = this.medicalConditions.replace(this.medicalConditionArray[index].name + ',', '');
          this.medicalConditions = this.medicalConditions.replace(this.medicalConditionArray[index].name, '');
        } else {
          this.medicalConditionArray[index].selected = true;
        }
        break;

      case 'familyMedicalConditions':
        if (this.familyMedicalArray[index].selected) {
          this.familyMedicalArray[index].selected = false;
          this.familyMedicalArrayOg[index].selected = false;

          this.familyMedicalConditions = this.familyMedicalConditions.replace(this.familyMedicalArray[index].name + ',', '');
          this.familyMedicalConditions = this.familyMedicalConditions.replace(this.familyMedicalArray[index].name, '');
        } else {
          this.familyMedicalArray[index].selected = true;
        }
        break;
      case 'geneticDisorders':
        if (this.geneticDisordersArray[index].selected) {
          this.geneticDisordersArray[index].selected = false;
          this.geneticDisordersOg[index].selected = false;

          this.geneticDisorders = this.geneticDisorders.replace(this.geneticDisordersArray[index].name + ',', '');
          this.geneticDisorders = this.geneticDisorders.replace(this.geneticDisordersArray[index].name, '');
        } else {
          this.geneticDisordersArray[index].selected = true;
        }
        break;

      case 'familyGeneticDisorders':
        if (this.familyGeneticDisordersArray[index].selected) {
          this.familyGeneticDisordersArray[index].selected = false;
          this.familyGeneticDisordersOrg[index].selected = false;

          this.familyGeneticDisorders = this.familyGeneticDisorders.replace(this.geneticDisordersArray[index].name + ',', '');
          this.familyGeneticDisorders = this.familyGeneticDisorders.replace(this.geneticDisordersArray[index].name, '');
        } else {
          this.familyGeneticDisordersArray[index].selected = true;
        }
        break;
      case 'highRiskType':
        if (this.highRiskTypeArray[index].selected) {
          this.highRiskTypeArray[index].selected = false;
          this.highRiskTypeOg[index].selected = false;

          this.highRiskType = this.highRiskType.replace(this.highRiskTypeArray[index].name + ',', '');
          this.highRiskType = this.highRiskType.replace(this.highRiskTypeArray[index].name, '');
        } else {
          this.highRiskTypeArray[index].selected = true;
        }
        break;
      default:
        break;
    }
  }

  saveModalValues(type: string) {

    switch (type) {
      case 'medicalConditions':
        this.medicalConditionArray.map(ins => {
          if (ins.selected && !this.medicalConditions.includes(ins.name)) {
            this.medicalConditions = this.medicalConditions.length ? this.medicalConditions + ', ' + ins.name : ins.name

            console.log(">>>>>>>>", this.medicalConditions);
          }
        });
        break;

      case 'familyMedicalConditions':
        this.familyMedicalArray.map(ins => {
          if (ins.selected && !this.familyMedicalConditions.includes(ins.name)) {
            this.familyMedicalConditions = this.familyMedicalConditions.length ? this.familyMedicalConditions + ', ' + ins.name : ins.name
          }
        });
        break;
      case 'geneticDisorders':
     
        this.geneticDisordersArray.map(ins => {
          if (ins.selected && !this.geneticDisorders.includes(ins.name)) {
            this.geneticDisorders = this.geneticDisorders.length ? this.geneticDisorders + ', ' + ins.name : ins.name
          }
        });
        break;
      case 'familyGeneticDisorders':
        this.familyGeneticDisordersArray.map(ins => {
          if (ins.selected && !this.familyGeneticDisorders.includes(ins.name)) {
            this.familyGeneticDisorders = this.familyGeneticDisorders.length ? this.familyGeneticDisorders + ', ' + ins.name : ins.name
          }
        });
        break;
      case 'highRiskType':
        this.highRiskType;
        this.highRiskTypeArray.map(ins => {
          if (ins.selected && !this.highRiskType.includes(ins.name)) {
            this.highRiskType = this.highRiskType.length ? this.highRiskType + ', ' + ins.name : ins.name
          }
        });
        break;


      default:
        break;
    }


  }

  checkIfTabsArrayContains(tabname) {
    for (var i = 0; i < this.tabArray.length; i++) {
      console.log(tabname, "----contains----", this.tabArray[i].name);
      if (this.tabArray[i].name == tabname) {
        return true;
      }
    }
    return false;
  }

  removeTabFromCollection(tabname) {
    for (var i = 0; i < this.tabArray.length; i++) {
      console.log(tabname, "----remove----", this.tabArray[i].name);
      if (this.tabArray[i].name == tabname) {
        this.tabArray.splice(i, 1);
        console.log(this.tabArray);
        return;
      }
    }
  }


  getAge() {
    this.showAgeYear = true;
    const { day, month, year } = this.dob;
    this.eDob = this.dob;
    let bdate = new Date();
    bdate.setMonth(month - 1);
    bdate.setDate(day);
    // 0
    bdate.setFullYear(year);
    var vacDate = bdate;
    vacDate.setDate(vacDate.getDate() + 2);
    this.vacDate = this.reverseDate(vacDate);

    console.log("tabmenu", this.tabArray)

    let age1 = moment().diff(moment(bdate, 'DD-MMM-YYYY'), 'years');
    if (age1 < 12) {
      this.isPediatric = true;
    } else {
      this.isPediatric = false;
    }

    this.setExpectedBDateAndGestestionalAge();
    let age = this.calculateAge(new Date(), bdate);
    this.ageYears = age[0];

    if (this.ageYears <= 12) {
      this.ageYears = "under 12 year";
      this.showAgeYear = false;

      if (!this.checkIfTabsArrayContains("Antenetal & Birth History")) {
        console.log("5678");
        this.removeTabFromCollection("Health History");
        this.tabArray.splice(1, 0, { name: "Antenetal & Birth History", selected: false, value: "", highlighted: false })
        console.log("includes", JSON.stringify(this.tabArray));
      }
    } else {
      this.ageYears = age[0]
      console.log("History........", JSON.stringify(this.tabArray));
      if (!this.checkIfTabsArrayContains("Health History")) {
        console.log("1234");
        this.removeTabFromCollection("Antenetal & Birth History");
        this.tabArray.splice(1, 0, { name: "Health History", selected: false, value: "", highlighted: false })
      }
      // this.habbits.map(ins=>{
      //   if(ins.past=="1" && ins.present=="1" && ins.name=="Drugs"){
      //     ins.past="0";
      //     ins.present="0";
      //   }
      // })
      console.log("this.Habbits", this.habbits);

      // this.removeTabFromCollection("Health History");
    }
    this.ageMonths = age[1];
    if (this.ageMonths <= 0) {
      this.ageMonths = "Under 1 year";

    }
    else {
      this.ageMonths = age[0]
      this.showAgeYear = true;
    }
    this.ageWeeks = Math.floor(age[2] / 7);
  }
  addAge() {
   
    if (this.ageYears < 12) {
      this.errors["ageYears"] = "Use DOB for age less than 12 yrs";
     
      if (!this.checkIfTabsArrayContains("Antenetal & Birth History")) {
        console.log("5678");
        this.removeTabFromCollection("Health History");
        this.tabArray.splice(1, 0, { name: "Antenetal & Birth History", selected: false, value: "", highlighted: false })
        console.log("includes", JSON.stringify(this.tabArray));
      }
      //alert(this.errors)
    }




    else {
      this.errors["ageYears"] = "";
      console.log("History........", JSON.stringify(this.tabArray));
      if (!this.checkIfTabsArrayContains("Health History")) {
        console.log("1234");
        this.removeTabFromCollection("Antenetal & Birth History");
        this.tabArray.splice(1, 0, { name: "Health History", selected: false, value: "", highlighted: false })
      }
    
    }
    // this.dob=false
    let age: any = moment().format('YYYY');
    age = age - this.ageYears;
    // this.eDob=this.field2;
    this.dob = moment(`01-JAN-${age}`).format("DD-MMM-YYYY");

    console.log("dob", this.dob);
    //  this.getAge()

  }

  setExpectedBDateAndGestestionalAge() {
    const { day, month, year } = this.dob;
    let bdate = new Date();
    bdate.setMonth(month - 1);
    bdate.setDate(day);
    bdate.setFullYear(year);
    let edobdate = new Date();
    edobdate.setMonth(this.eDob.month - 1);
    edobdate.setDate(this.eDob.day);
    edobdate.setFullYear(this.eDob.year);

    try {
      const PATIENT_DOB_FORMAT = "DD-MMM-YYYY";
      const actualDate = moment(bdate, PATIENT_DOB_FORMAT); // sept 18 2018
      const expectedDate = moment(edobdate, PATIENT_DOB_FORMAT);// oct 15 2018
      const refDate = expectedDate.subtract(40, "weeks"); //january 8, 2018
      const diffWeeks = actualDate.diff(refDate, "days"); // 253
      console.log("??????????????/", bdate, this.eDob)
      const gestationalAge = {
        w: Math.round(Number(diffWeeks / 7)), // 36
        d: diffWeeks % 7 // 1
      };
      this.gestationalAge = gestationalAge;
      var weeks = this.calculateWeek(new Date(this.dob).getTime(), new Date(this.eDob).getTime());
      if (weeks < 17 && weeks >= 13) {
        this.isPreTerm = 2;
      } else if (weeks < 13 && weeks > 2) {
        this.isPreTerm = 1;
      }
      else {
        this.isPreTerm = 0;
      }
    } catch (Exception) {
      this.gestationalAge = {
        w: 0, // 36
        d: 0 // 1
      };
    }
  }


  calculateWeek(d1, d2) {
    return Math.round((d2 - d1) / (7 * 24 * 60 * 60 * 1000));
  }


  parseDate(dateObject) {
    if (dateObject?.year) {
      return `${dateObject.year}-${dateObject.month}-${dateObject.day}`;
    } else {
      return `${dateObject}`
    }
  }
  calculateAge(endingDate, startingDate) {
    var startDate = new Date(
      new Date(startingDate).toISOString().substr(0, 10)
    );
    if (!endingDate) {
      endingDate = new Date().toISOString().substr(0, 10); // need date in YYYY-MM-DD format
    }
    var endDate = new Date(endingDate);
    if (startDate > endDate) {
      var swap = startDate;
      startDate = endDate;
      endDate = swap;
    }
    var startYear = startDate.getFullYear();
    var february =
      (startYear % 4 === 0 && startYear % 100 !== 0) || startYear % 400 === 0
        ? 29
        : 28;
    var daysInMonth = [31, february, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    var yearDiff = endDate.getFullYear() - startYear;
    var monthDiff = endDate.getMonth() - startDate.getMonth();
    if (monthDiff < 0) {
      yearDiff--;
      monthDiff += 12;
    }
    var dayDiff = endDate.getDate() - startDate.getDate();
    if (dayDiff < 0) {
      if (monthDiff > 0) {
        monthDiff--;
      } else {
        yearDiff--;
        monthDiff = 11;
      }
      dayDiff += daysInMonth[startDate.getMonth()];
    }
    return [yearDiff, monthDiff, dayDiff];
  }
  reverseDate(date) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }
  closeCalender(e, d) {
    if (e.target.offsetParent == null) d.close();
    else if (
      e.target.offsetParent &&
      e.target.offsetParent.nodeName != "NGB-DATEPICKER" &&
      e.target.offsetParent.className.indexOf("calender") == -1 &&
      e.target.offsetParent.className.indexOf("custom-select") == -1
    )
      d.close();
  }






  getPatientInfo() {

    this.patientService.getPatientInfo(this.patientObj.id ? this.patientObj.id : this.patientObj._id).subscribe(response => {
      if (response) {

        console.log("ResponseOnboard", response);
        localStorage.setItem("patientOnBoardData",JSON.stringify(response))
        this.patientResponse = response;
        this.patientData=this.patientResponse;
        this.profileImageofpatient = response.profilePic
        this.selectedFormValue.firstname = response.firstName;
        this.selectedFormValue.middlename = response.middleName;
        this.selectedFormValue.lastname = response.lastName;
        this.patientId = response._id;
        console.log("respId", this.patientId)


        this.selectGender = response.gender;
        if (this.selectGender == "Male") {
          this.genderArry.map(ins => {
            if (ins.name == "Male") {
              ins.selected = true;
            }
            else {
              ins.selected = false;

            }
          })
        }
        if (this.selectGender == "Female") {
          this.genderArry.map(ins => {
            if (ins.name == "Female") {
              ins.selected = true;
            }
            else {
              ins.selected = false;

            }
          })
        }
        console.log("gender", this.selectGender)
        this.languageForParent = response.parent.selectedLanguage;
        this.dob = this.reverseDate(response.DOB);
        this.dobDate = moment(this.parseDate(this.dob)).format("DD-MMM-YYYY")
        console.log("dob", this.dobDate)

        this.eDob = this.reverseDate(response.eDOB);
        this.vacDate = this.reverseDate(response.vaccinationStartDate);
        // this.vacDate=moment(this.parseDate(this.vacDate)).format("DD-MMM-YYYY")
        console.log("thisVACdATE", this.vacDate)
        this.gestationalAge = response.gestationalAge;
        //  this.field2=response.age.years;
        this.ageYears = response.age.years;
        this.ageMonths = response.age.months;
        this.ageWeeks = response.age.days;
        this.mobileNumber = response["contactNo"].replace("91", "");
        this.secondNumber = response["spousecontactno"].replace("+91", "");
        if (this.secondNumber.includes("+91")) {
          this.secondNumber.replace("+91", " ")

        }
        this.selectedFormValue.emailId = response.parent.email;
        this.relation = response.relationWithCustomer;
        this.blood_group = response.blood_group;
        this.selectedFormValue.hospitalisation = response.accidentalHistory

        this.typeOfDelivery = response.type_of_delivery;
        this.selectedFormValue.fathersName = response.parent.fathersName;
        this.selectedFormValue.mothersName = response.parent.mothersName;
        this.isHighRisk = response.isHighRisk;
        if (this.isHighRisk == true) {
          this.checkedValue = true;
          this.checkValue = "Yes"
          this.showHighRisk = true;
          this.highRiskType = ""
        }
        else {
          this.checkedValue = false;
          this.showHighRisk = false;
          this.checkValue = "No"
          this.highRiskType = ""
        }


        this.selectedFormValue.otherId = response.otherIdNo;
        this.fathersHeightCm = response.fathersHeight;
        this.mothersHeightCm = response.mothersHeight;
        this.showOtherInformation = true;
        this.fathersHeightInFt = 'ft';
        this.mothersHeightInFt = 'ft';
        this.isPediatric = response.patientTypes.length === 2 ? true : false;
        this.isBabyOf = response.babyOf;

        // this.newBornScreening = response.newBornScreening;
        this.medicalConditions = response.affected_comment ? response.affected_comment : '';
        this.familyMedicalConditions = response.familyHistory ? response.familyHistory : '';

        this.selectedFormValue.allergies = response.allergies;
        this.selectedFormValue.recurring = response.comment;
        this.accidentalHistory = response.accidentalHistory;
        this.selectedFormValue.additionalInfo = JSON.parse(response.additionalInfo)
        this.habbitsArray = this.selectedFormValue.additionalInfo.habits;
        this.newBornScreening = this.selectedFormValue.additionalInfo.newBornScreening;
        this.highRiskType = this.selectedFormValue.additionalInfo.highRiskType

        // this.newBornScreening=response.newBornScreening
        if (this.newBornScreening == 1) {
          this.heelPrickTest = true;
          this.checkValueHeelPrickTest = "Done";
          this.uploadTestValue = 'Upload heel prick test documents from upload document section'

        }
        else {
          this.heelPrickTest = false;
          this.checkValueHeelPrickTest = "No";
        }
        console.log("habbits", this.habbitsArray)
        console.log("parse", this.selectedFormValue.additionalInfo)
        this.geneticDisorders = this.selectedFormValue.additionalInfo.geneticDisorders ? this.selectedFormValue.additionalInfo.geneticDisorders : '';
        this.familyGeneticDisorders = this.selectedFormValue.additionalInfo.familyGeneticDisorders?this.selectedFormValue.additionalInfo.familyGeneticDisorders:''
        this.selectedFormValue.healthHistoryHighlights = this.selectedFormValue.additionalInfo.healthHistoryHighlights ?this.selectedFormValue.additionalInfo.healthHistoryHighlights:''
        this.selectedFormValue.healthHistory = this.selectedFormValue.additionalInfo.healthHistory? this.selectedFormValue.additionalInfo.healthHistory:'';
        this.selectedFormValue.reactions = this.selectedFormValue.additionalInfo.reactionToMeds?this.selectedFormValue.additionalInfo.reactionToMeds:'';



        if (!this.checkIfTabsArrayContains("Health History")) {
          console.log("1234");
          this.removeTabFromCollection("Antenetal & Birth History");
          this.tabArray.splice(1, 0, { name: "Health History", selected: false, value: "", highlighted: false })
        }


        if (this.isPediatric) {

          this.selectedFormValue.birth_weight = response.visits[0]["weight"];
          this.selectedFormValue.birth_height = response.visits[0]["height"];
          this.selectedFormValue.birth_hc = response.visits[0]["hc"];
          this.selectedFormValue.birth_cc = response.visits[0]["cc"];
          this.selectedFormValue.birth_bpsys = response.visits[0]["bpSys"];
          this.selectedFormValue.birth_bpdia = response.visits[0]["bpDia"];
        }
        this.convertUnitsFather();
        this.convertUnitsMother();
      } else {
        alert('Something went wrong')
      }
    });

  }

  sentParentOTP() {
    const data = { contactNo: `+${this.stdCode}${this.mobileNumber}` };
    // httpCall('POST', 'parent/verify', data).then(response => {
    //     const otp = response.data.otp;
    //     // console.warn(response.data);
    //     this.setState({ otp: otp, });
    // })
    //     .catch(error => {
    //         console.warn("Error:   " + JSON.stringify(error));
    //     });
    this.patientService.sentParentOTP(data).subscribe(response => {
      if (response) {
        console.log(">>>>>>>>>>>>>>>", response);
        this.recievedOtp = response.otp;
      }
    })
  }
  onResendMessage() {
    const data = { contactNo: `+${this.stdCode}${this.mobileNumber}` };
    // httpCall('POST', 'parent/otp', data).then(response => {
    //     const otp = response.data.otp;
    //     this.setState({ otp: otp });
    //     if (this.flag) {
    //         ToastAndroid.showWithGravity(
    //             'New OTP is sent',
    //             ToastAndroid.SHORT,
    //             ToastAndroid.CENTER
    //         );
    //     }
    // })
    //     .catch(error => {
    //         this.setState({ disableResend: false, OTPDuration: 0, isVerified: false })
    //         if (error.request.status === 0) {
    //             Alert.alert(
    //                 'Connection Unavailable',
    //                 'Check your internet connection and try again.',
    //                 [
    //                     { text: 'OK', onPress: () => { } },
    //                 ],
    //                 { cancelable: false }
    //             )
    //         }

    //     });

    this.patientService.onResendMessage(data).subscribe(response => {
      if (response.success) {
        console.log(">>>>>>>>>>>>>>>", response)
        this.recievedOtp = response.data.otp;
      }
    })
  }
  findPatientByName() {


    if (this.mobileNumber.length < 10) {
      this.messageService.add({ severity: 'custom', detail: 'Please Enter valid  Mobile Number' });

    }
    else if (this.selectedFormValue.firstname == '') {
      this.messageService.add({ severity: 'custom', detail: 'Please Enter First Name' });
    }
    else if (this.selectedFormValue.lastname == '') {
      this.messageService.add({ severity: 'custom', detail: 'Please Enter Last Name' });
    }
    else if (!this.dob) {
      this.messageService.add({ severity: 'custom', detail: "Please select patient's date of birth" });
      // this.errors["dob"] = "Please select patient's date of birth";
    }
    else if (!this.selectGender) {
      this.messageService.add({ severity: 'custom', detail: 'Please Select Gender' });

    }
    else if (!this.isOTPConfirmed) {
      this.messageService.add({ severity: 'custom', detail: 'Please Confrim Mobile Number' });

    }
    // else if (this.secondNumber.length < 10) {
    //   this.messageService.add({ severity: 'custom', detail: "Please enter valid phone number" });
    //   //this.errors["secondNumber"] = "Please enter valid phone number";
    // }
    // else if(this.genderArry.map(ins => ins.selected = false)){
    //   this.messageService.add({ severity: 'custom', detail: 'Please Select Gender' });
    // }
    else {


      let postData: any = {
        contactNo: this.stdCode + this.mobileNumber,
        spouseContactNo: this.stdCode + this.secondNumber,
        profilePic: this.imageUrlS3,
        gender: this.selectGender,
        firstName: this.selectedFormValue.firstname,
        lastName: this.selectedFormValue.lastname,
        middleName: this.selectedFormValue.middlename,
        DOB: moment(this.parseDate(this.dob)).format("DD-MMM-YYYY"),
        eDOB: moment(this.parseDate(this.eDob)).format("DD-MMM-YYYY"),
        vaccinationStartDate: moment(this.parseDate(this.vacDate)).format("YYYY-MM-DD")
      }


      this.patientService.findPatientsByNameDOBNumber(postData).subscribe(response => {

        if (response) {
          console.log("patientList", response)
          this.patientList = response;


          // var initial=fullName.charAt(0).toUpperCase()+fullName.charAt(0).toUpperCase();
          // console.log("initial",initial)
          this.open(this.patientListModal, 'patientListModal')

        }
        else {

          this.open(this.continueProfileModal, 'continueProfileModal ')
          this.onSubmitButtonn();
        }
      })
    }

  }

  // getInital(){

  //   const fullName = this.nameInitials.split(' ');
  // this.initialName = fullName.shift().charAt(0) + fullName.pop().charAt(0);
  // return this.initialName.toUpperCase();

  // }


  gotNextTab(index) {

    this.tabArray.map((ins, ind) => {



      this.selectedMenuToTake = ins.name;
      if (this.selectedMenuToTake == 'Profile') {
        ins.selected = true

        ins.highlighted = false
      }
      if (this.selectedMenuToTake == 'Antenetal & Birth History') {
        this.dobDate = moment(this.parseDate(this.dob)).format("DD-MMM-YYYY")
        this.showUpdateButton = true
        this.showInfoBirth = true;
        this.showProfile = false

        ins.selected = true

        ins.highlighted = true
        // this.onSubmitButtonn();

      }
      else if (this.selectedMenuToTake == 'Health History') {
        this.dobDate = moment(this.parseDate(this.dob)).format("DD-MMM-YYYY")
        this.showUpdateButton = true
        this.showInfoBirth = false;
        this.showProfile = false
        this.showHealthHistory = true

        ins.selected = true

        ins.highlighted = true

      }

    })

    // if(this.selectedMenuToTake=='Antenetal & Birth History'){


    // }



    // this.showInfoBirth=true
  }
  modifyVitals() {
    // this.gotNextTab(1)
    // this.vacDate=moment(this.parseDate(this.vacDate)).format("YYYY-MM-DD");
    this.switchMenu(1);
    this.modalService.dismissAll();

  }
  getvaccDate() {
    this.dobDate = moment(this.parseDate(this.dob)).format("DD-MMM-YYYY");
    this.vacDate = moment(this.parseDate(this.vacDate)).format("YYYY-MM-DD");

  }
  goToAddVitals() {
    var localstoredpatient:any={}
    var objData:any={}
    // localstoredpatient.UserId=this.patientNewData.UserId
    // localstoredpatient.id=this.patientNewData.id
    localstoredpatient['Patient']=this.patientNewData
    localStorage.setItem('patientData', JSON.stringify(this.patientNewData));

    //add to top of locally save Patients so that new patient appears on top of home page

    let localacessedpatientsstr = localStorage.getItem("localacessedpatients");
    let localacessedpatients = [];
    if(localacessedpatientsstr){
      localacessedpatients = JSON.parse(localacessedpatientsstr);
    }

    // remove/add current patient on top
    for(var i = 0; i < localacessedpatients.length; i++ ) {
      if ( localacessedpatients[i].Patient.User.id == localstoredpatient.Patient.User.id){
        localacessedpatients.splice(i,1);
      }
    }
    //adding back on top of list
    localacessedpatients.unshift(localstoredpatient);
    localStorage.setItem("localacessedpatients", JSON.stringify(localacessedpatients));
   
    localStorage.setItem('patientDataForVitals', JSON.stringify(this.patientData));
    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientData._id,visit:true } });
    // setTimeout(()=>{
    //   this.refreshservice.OnPatTopNav_MenuSelected(this.patientNewData.id,this.refreshservice.EventName_Visits)
    // },500)
    this.modalService.dismissAll();
  }
  uploadDocument() {
    localStorage.setItem('patientData', JSON.stringify(this.patientNewData));
    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientNewData.id, uploadDocument: true } });
    // setTimeout(()=>{
    //   this.refreshservice.OnPatTopNav_MenuSelected(this.patientNewData.id,this.refreshservice.EventName_Upload)
    // },500)
  }
  goToRx() {
    var localstoredpatient:any={}
    var objData:any={}
    // localstoredpatient.UserId=this.patientNewData.UserId
    localstoredpatient.id=this.patientNewData.id
    localstoredpatient['Patient']=this.patientNewData
    localStorage.setItem('patientData', JSON.stringify(this.patientNewData));

    //add to top of locally save Patients so that new patient appears on top of home page

    let localacessedpatientsstr = localStorage.getItem("localacessedpatients");
    let localacessedpatients = [];
    if(localacessedpatientsstr){
      localacessedpatients = JSON.parse(localacessedpatientsstr);
    }

    // remove/add current patient on top
    for(var i = 0; i < localacessedpatients.length; i++ ) {
      if ( localacessedpatients[i].Patient.User.id == localstoredpatient.Patient.User.id){
        localacessedpatients.splice(i,1);
      }
    }
    //adding back on top of list
    localacessedpatients.unshift(localstoredpatient);
    localStorage.setItem("localacessedpatients", JSON.stringify(localacessedpatients));
  
   
    localStorage.setItem('patientData', JSON.stringify(this.patientNewData));
 
    this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientNewData.id } });
  

    setTimeout(()=>{
      this.refreshservice.OnPatTopNav_MenuSelected(this.patientNewData.id,this.refreshservice.EventName_Rx)
    },500)
  }
  createAppointment() {
    
   
    localStorage.setItem('patientData', JSON.stringify(this.patientNewData));
    //this.patientName=this.patientResponse.first_name+" "+this.patientResponse.middle_name+" "+this.patientResponse.last_name
    if (this.isAppointmentEnabled == '1') {
      this.router.navigate(['/doctor/homepage/'], { queryParams: { patientId: this.patientNewData.id } });
    }
    else if (this.isAppointmentEnabled == '2') {
      this.router.navigate(['/doctor/homepage/'], { queryParams: { patientidfornewQueue: this.patientNewData.id,queueFlag:true } });
    }
    else {

    }

  }

  patientFormOpen() {

    this.router.navigate([`/doctor/patients/patientOnboard`])
    this.modifyRmn = true;
    this.isOTPConfirmed = false
  }
  onSubmitButtonn() {

    let postData: any = {
      contactNo: this.stdCode + " " + this.mobileNumber,
      spouseContactNo: this.stdCode + " " + this.secondNumber,
      profilePic: this.imageUrlS3,
      gender: this.selectGender,
      firstName: this.selectedFormValue.firstname,
      lastName: this.selectedFormValue.lastname,
      middleName: this.selectedFormValue.middlename,
      DOB: moment(this.parseDate(this.dob)).format("DD-MMM-YYYY"),
      eDOB: moment(this.parseDate(this.eDob)).format("DD-MMM-YYYY"),
      vaccinationStartDate: moment(this.parseDate(this.vacDate)).format("YYYY-MM-DD"),
      isHighRisk: this.isHighRisk,
      // newBornScreening:this.newBornScreening,
      // familyHistory: this.familyMedicalConditions,

      // blood_group: this.blood_group,
      // type_of_delivery: this.typeOfDelivery,
      patientTypes: ['Ba'],
      visit: [],





    }
    var age = moment().diff(moment(new Date(this.parseDate(this.dob)), 'DD-MMM-YYYY'), 'years');
    console.log("ageNumber", age)
    if (Number(age < 12)) {

      postData.patientTypes = ['Ba', 'Pe'];
      postData.visit = {
        weight: '',
        height: 0,
        hc: 0,
        cc: 0,
        bpSys: 0,
        bpDia: 0,
        addedBy: 'doctor'
      }
    }
    if (this.isEdit) {
      // this.showDate=false
      // postData.gestationalAge = this.gestationalAge;
      // postData.isSMSInviteSent = this.patientResponse.isSMSInviteSent;

      const data = {
        patient: postData,
        patientCreatedAt: this.patientResponse.createdAt
      }

      this.patientService.updatePatientInfo(data, this.patientResponse._id).subscribe(response => {
        if (response) {
          console.log("?????????????????", response)
          this.patientResponse = response
          this.patientData=this.patientResponse

          this.toaster.success("Success");
          this.showLoading = false;
          this.switchMenu(1)
          //this.router.navigate(['/doctor/homepage'])
        }
      })
    } else {


      this.patientService.addNewPatient(postData).subscribe(response => {
        if (response) {
          console.log("?????????????????", response)
        //  localStorage.setItem("newData",JSON.parse(response))
          this.patientData = response;
        //  localStorage.setItem("newpatientData",JSON.stringify(this.patientNewData))
          this.patientId = response._id
          let obj={}
          let objData={}
          obj['id'] = this.patientData.userId;
          obj['phone_number']=this.patientData.contactNo
       
          obj['spousecontactno']=this.patientData.spousecontactno
          obj['dob']=this.patientData.DOB
          var gender:any=""
          if(this.patientData.gender=='Male'){
            gender="male"
          }
          else{
            gender="female"
          }
          obj['gender']=gender
          obj['first_name']=this.patientData.firstName
          obj['last_name']=this.patientData.lastName
          obj['middle_name']=this.patientData.middleName
          obj['profile_photo']=this.patientData.profilePic ? this.patientData.profilePic:'';
         
          objData['User']= {...obj,Customer:{ phone_number: this.patientData.contactNo,spousecontactno:this.patientData.spousecontactno }};
          objData['id']=this.patientData._id;
          
          this.patientNewData=objData
          localStorage.setItem("patientData",JSON.stringify(this.patientNewData))
        
          console.log("patientData", this.patientId)
          // this.toaster.success("Success");
          this.showLoading = false;
          // this.router.navigate(['/doctor/homepage'])
        }
      })
    }

  }
  nextTabHomePage() {
    var localstoredpatient:any={}
    var objData:any={}
    localstoredpatient['Patient']=this.patientNewData
    localStorage.setItem('patientData', JSON.stringify(this.patientNewData));

    //add to top of locally save Patients so that new patient appears on top of home page
    let localacessedpatientsstr = localStorage.getItem("localacessedpatients");
    let localacessedpatients = [];
    if(localacessedpatientsstr){
      localacessedpatients = JSON.parse(localacessedpatientsstr);
    }

    // remove/add current patient on top
    for(var i = 0; i < localacessedpatients.length; i++ ) {
      if ( localacessedpatients[i].Patient.User.id == localstoredpatient.Patient.User.id){
        localacessedpatients.splice(i,1);
      }
    }
    //adding back on top of list
    localacessedpatients.unshift(localstoredpatient);
    localStorage.setItem("localacessedpatients", JSON.stringify(localacessedpatients));
    

    this.router.navigate([`/doctor/patients/profileSummary/view`], { queryParams: { patientId: this.patientNewData.id } });
  }
 
  // proceedWithRmn(){
  //   this.onSubmitButton();
  //   this.open(this.continueProfileModal,"continueProfileModal");



  // }
  updatePatient() {
    this.switchMenu(2);
  }
  addAntenetalBirth() {
    this.patientData.DOB = moment(this.parseDate(this.dob)).format("DD-MMM-YYYY");
    this.patientData.eDOB = moment(this.parseDate(this.eDob)).format("DD-MMM-YYYY");
    this.patientData.vaccinationStartDate = moment(this.parseDate(this.vacDate)).format("YYYY-MM-DD");
    this.patientData.blood_group = this.blood_group;
    this.patientData.type_of_delivery = this.typeOfDelivery;
    this.patientData.gestationalAge = this.gestationalAge;
    this.patientData.isHighRisk = this.isHighRisk;
    var additionalInfo = this.patientData.additionalInfo && this.patientData.additionalInfo != null ? JSON.parse(this.patientData.additionalInfo) : {};
    additionalInfo.newBornScreening = this.newBornScreening;
    additionalInfo.highRiskType = this.highRiskType;
    this.patientData.additionalInfo = JSON.stringify(additionalInfo);
    var age = moment().diff(moment(new Date(this.parseDate(this.dob)), 'DD-MMM-YYYY'), 'years');
    if (Number(age < 12)) {

      this.patientData.patientTypes = ['Ba', 'Pe'];
      this.patientData.visit = {
        weight: this.selectedFormValue.birth_weight ==='' ? 0 :this.selectedFormValue.birth_weight,
        height: this.selectedFormValue.birth_height === '' ? 0 : this.selectedFormValue.birth_height,
        hc: this.selectedFormValue.birth_hc === '' ? 0 : this.selectedFormValue.birth_hc,
        cc: this.selectedFormValue.birth_cc === '' ? 0 : this.selectedFormValue.birth_cc,
        bpSys: this.selectedFormValue.birth_bpsys === '' ? 0 : this.selectedFormValue.birth_bpsys,
        bpDia: this.selectedFormValue.birth_bpdia === '' ? 0 : this.selectedFormValue.birth_bpdia,
        addedBy: 'doctor',

      }
    }
 
    const data = {
      patient: this.patientData,
      patientCreatedAt: this.patientData.createdAt
    }






    this.patientService.updatePatientInfo(data, this.patientData._id).subscribe(response => {
      if (response) {
        console.log("AntentalRecord", response)
        this.toaster.success("Success");
        this.showLoading = false;
        this.switchMenu(2);
        // this.router.navigate(['/doctor/homepage'])
      }
    })

  }
  addHealthProfile() {
    this.patientData.email = this.selectedFormValue.emailId;
    this.patientData.otherIdNo = this.selectedFormValue.otherId;
    this.patientData.allergies = this.selectedFormValue.allergies;
    this.patientData.comment = this.selectedFormValue.recurring;
    this.patientData.affected_comment = this.medicalConditions;
    this.patientData.accidentalHistory = this.selectedFormValue.hospitalisation;
    var additionalInfo = this.patientData.additionalInfo && this.patientData.additionalInfo != null ? JSON.parse(this.patientData.additionalInfo) : {};
    additionalInfo.geneticDisorders = this.geneticDisorders;
    additionalInfo.healthHistory = this.selectedFormValue.healthHistory;
    additionalInfo.reactionToMeds = this.selectedFormValue.reactions;
    this.patientData.additionalInfo = JSON.stringify(additionalInfo);


    const data = {
      patient: this.patientData,
      patientCreatedAt: this.patientData.createdAt
    }


    this.patientService.updatePatientInfo(data, this.patientData._id).subscribe(response => {
      if (response) {
        console.log("AntentalRecord", response)
       
      
        // this.toaster.success("Success");
        this.showLoading = false;
        this.switchMenu(3);
        //  this.router.navigate(['/doctor/homepage'])
      }
    })

    // this.router.navigate(['/doctor/homepage'])

  }

  addHealthHistory() {

    this.patientData.blood_group = this.blood_group;
    var additionalInfo = this.patientData.additionalInfo && this.patientData.additionalInfo != null ? JSON.parse(this.patientData.additionalInfo) : {};
    additionalInfo.healthHistoryHighlights = this.selectedFormValue.healthHistoryHighlights;
    additionalInfo.habits = this.habbitsArray;
    this.patientData.additionalInfo = JSON.stringify(additionalInfo);

    const data = {
      patient: this.patientData,
      patientCreatedAt: this.patientData.createdAt
    }

    this.patientService.updatePatientInfo(data, this.patientData._id).subscribe(response => {
      if (response) {
        console.log("HealthHitory", response)
        this.toaster.success("Success");
        this.showLoading = false;
        this.switchMenu(2);
        // this.router.navigate(['/doctor/homepage'])
      }
    })

  }
  addFamilyPrfofileisPediatric() {

    this.patientData.mothersName = this.selectedFormValue.mothersName;
    this.patientData.fathersName = this.selectedFormValue.fathersName;
    if (this.fathersHeightInFt === 'ft') {
      this.fathersHeightInFt = 'cm'
      this.convertUnitsFather();

    }
    if (this.mothersHeightInFt === 'ft') {
      this.mothersHeightInFt = 'cm'
      this.convertUnitsMother();
    }
    this.patientData.fathersHeight = this.fathersHeightCm ? this.fathersHeightCm : '0.0',
      this.patientData.mothersHeight = this.mothersHeightCm ? this.mothersHeightCm : '0.0',

      this.patientData.familyHistory = this.familyMedicalConditions
    var additionalInfo = this.patientData.additionalInfo && this.patientData.additionalInfo != null ? JSON.parse(this.patientData.additionalInfo) : {};
    additionalInfo.familyGeneticDisorders = this.familyGeneticDisorders;
    this.patientData.additionalInfo = JSON.stringify(additionalInfo);
    const data = {
      patient: this.patientData,
      patientCreatedAt: this.patientData.createdAt
    }
    console.log("familyProfilePed", data);
    this.patientService.updatePatientInfo(data, this.patientData._id).subscribe(response => {
      if (response) {
        console.log("familyProfilePed", response)
        this.patientData=response
        this.toaster.success("Success");

 
  let obj={}
  let objData={}
  obj['id'] = this.patientData.userId;
  obj['phone_number']=this.patientData.contactNo
  obj['dob']=this.patientData.DOB
  var gender:any=""
  if(this.patientData.gender=='Male'){
    gender="male"
  }
  else{
    gender="female"
  }
  obj['gender']=gender
  obj['first_name']=this.patientData.firstName
  obj['last_name']=this.patientData.lastName
  obj['middle_name']=this.patientData.middleName
  obj['profile_photo']=this.patientData.profilePic ? this.patientData.profilePic:'';
  objData['User']= {...obj,Customer:{ phone_number: this.patientData.contactNo,spousecontactno:this.patientData.spousecontactno }};
  objData['id']=this.patientData._id;
  objData['id']=this.patientData._id;
  
  this.patientNewData=objData
  localStorage.setItem("patientData",JSON.stringify(this.patientNewData))
        this.showLoading = false;
        //   this.switchMenu(2);
        localStorage.setItem('patientData', JSON.stringify(this.patientNewData));
        this.open(this.doneProfileModal, "doneProfileModal");
        // this.router.navigate(['/doctor/homepage'])
      }
    })




  }
  addFamilyProfilePed() {
    this.patientData.familyHistory = this.familyMedicalConditions
    var additionalInfo = this.patientData.additionalInfo && this.patientData.additionalInfo != null ? JSON.parse(this.patientData.additionalInfo) : {};
    additionalInfo.familyGeneticDisorders = this.familyGeneticDisorders;
    this.patientData.additionalInfo = JSON.stringify(additionalInfo);
    const data = {
      patient: this.patientData,
      patientCreatedAt: this.patientData.createdAt
    }
    console.log("familyProfile", data);
    this.patientService.updatePatientInfo(data, this.patientData._id).subscribe(response => {
      if (response) {
        console.log("familyProfile", response)
        this.patientData=response
        this.toaster.success("Success");
        this.showLoading = false;

 
  let obj={}
  let objData={}
  obj['id'] = this.patientData.userId;
  obj['phone_number']=this.patientData.contactNo
  obj['dob']=this.patientData.DOB
  var gender:any=""
  if(this.patientData.gender=='Male'){
    gender="male"
  }
  else{
    gender="female"
  }
  obj['gender']=gender
  obj['first_name']=this.patientData.firstName
  obj['last_name']=this.patientData.lastName
  obj['middle_name']=this.patientData.middleName
  obj['profile_photo']=this.patientData.profilePic ? this.patientData.profilePic:'' ;
  objData['User']= {...obj,Customer:{ phone_number: this.patientData.contactNo,spousecontactno:this.patientData.spousecontactno }};
  objData['id']=this.patientData._id;
  objData['id']=this.patientData._id;
  
  this.patientNewData=objData
  localStorage.setItem("patientData",JSON.stringify(this.patientNewData))
        //   this.switchMenu(2);
        localStorage.setItem('patientData', JSON.stringify(this.patientNewData));
        this.open(this.doneProfileModal, "doneProfileModal");
        // this.router.navigate(['/doctor/homepage'])
      }
    })

  }
  onSubmitButton() {
    this.errors = {};
    if (!this.isBabyOf && !this.first_name) {
      this.errors["first_name"] = "Please enter patient's name";
    }
    // else if (this.isPediatric && !this.mothersName) {
    //   this.errors["mothersName"] = "Please enter mother's name";
    // }
    else if (!this.last_name) {
      this.errors["last_name"] = "Please enter patient's last name";
    } else if (!this.dob) {
      this.errors["dob"] = "Please select patient's date of birth";
    }
    else if (this.mobileNumber.length < 10) {
      this.errors["mobileNumber"] = "Please enter valid phone number";
    }
    else if (!this.isOTPConfirmed && !this.isEdit) {
      this.errors["mobileNumber"] = "Please confirm OTP";
    }
    else if (this.isPediatric && !this.birth_weight) {
      this.errors["birth_weight"] = "Please enter patient's weight";
    }

    else {
      if (this.fathersHeightInFt === 'ft') {
        this.fathersHeightInFt = 'cm'
        this.convertUnitsFather();

      }
      if (this.mothersHeightInFt === 'ft') {
        this.fathersHeightInFt === 'cm'
        this.convertUnitsMother();
      }

      // alert(JSON.stringify(this.state.conditions + ', ' + this.state.selectedDataConditions.join(', ')))
      // let fname = this.state.firstName.replace("Baby of Mrs. ", "");
      // fname = fname.replace("Baby of ", "");
      let postData: any = {
        profilePic: this.imageUrlS3,
        gender: this.gender,
        babyOf: this.isBabyOf,
        isPreTerm: this.isPreTerm,
        firstName: this.isBabyOf ? "B/o" + " " + this.mothersName : this.first_name,
        contactNo: this.stdCode + " " + this.mobileNumber,
        lastName: this.last_name,
        middleName: this.middle_name,
        DOB: moment(this.parseDate(this.dob)).format("DD-MMM-YYYY"),
        isHighRisk: this.isHighRisk,
        eDOB: moment(this.parseDate(this.eDob)).format("DD-MMM-YYYY"),
        fathersHeight: this.fathersHeightCm ? this.fathersHeightCm : '0.0',
        mothersHeight: this.mothersHeightCm ? this.mothersHeightCm : '0.0',
        mothersName: this.isBabyOf ? this.mothersName : '',
        fathersName: this.fathersName,
        email: this.email,
        adultAge: '0',
        selectedLanguage: this.languageForParent,
        spouseContactNo: this.stdCode + " " + this.mobileNumber,
        vaccinationStartDate: moment(this.parseDate(this.vacDate)).format("YYYY-MM-DD"),
        otherIdNo: this.otherIDNumber,
        blood_group: this.blood_group,
        type_of_delivery: this.typeOfDelivery,
        relationWithCustomer: this.relation,
        allergies: this.allergies,
        affected_comment: this.medicalConditions,
        familyHistory: this.familyMedicalConditions,
        comment: this.recurringComplaints,
        accidentalHistory: this.accidentalHistory,
        patientTypes: ['Ba'],
        visit: [],

      };
      if (this.isPediatric) {
        postData.patientTypes = ['Ba', 'Pe'];
        postData.visit = {
          weight: this.birth_weight=== '' ? 0 :this.birth_weight,
          height: this.birth_height === '' ? 0 : this.birth_height,
          hc: this.birth_hc === '' ? 0 : this.birth_hc,
          cc: this.birth_cc === '' ? 0 : this.birth_cc,
          bpSys: this.birth_bpsys === '' ? 0 : this.birth_bpsys,
          bpDia: this.birth_bpdia === '' ? 0 : this.birth_bpdia,
          addedBy: 'doctor'
        }
      }
      if (this.isEdit) {
        this.showDate = false
        postData.gestationalAge = this.gestationalAge;
        postData.isSMSInviteSent = this.patientResponse.isSMSInviteSent;
        // postData.illnesses_comment = this.patientResponse.illnesses_comment;
        // postData.visits = [];
        // postData.visits = [...this.patientVisits];
        // postData.visits[0] = visitData;
        const data = {
          patient: postData,
          patientCreatedAt: this.patientResponse.createdAt
        }

        this.patientService.updatePatientInfo(data, this.patientResponse._id).subscribe(response => {
          if (response) {
            console.log("?????????????????", response)
            this.toaster.success("Success");
            this.showLoading = false;
            this.router.navigate(['/doctor/homepage'])
          }
        })
      } else {
        this.showLoading = true;
        this.patientService.addNewPatient(postData).subscribe(response => {
          if (response) {
            console.log("?????????????????", response)
            this.toaster.success("Success");
            this.showLoading = false;
            this.router.navigate(['/doctor/homepage'])
          }
        })

      }
    }
  }


  removePhoto() {
    //placeholder
  }
}
