import { MatDialog } from '@angular/material/dialog';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PatientService } from "../../../services/patient.service";
import { Router, ActivatedRoute, } from "@angular/router";
import { ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import * as _ from "lodash";
import { DoctorService } from '../../../services/doctor.service';
import { AuthService } from '../../../services/auth.service';
// import {maxlengthContentEditable} from 'maxlength-contenteditable';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { LanguageSelectTextboxComponent } from '../../../components/language-select-textbox/language-select-textbox.component';
import { refreshService } from '../../../services/refersh.service';

let whenToTake = ["-", "Before food", "After food", "With food", "With Milk", "With fruit juice", "At bed time", "Early morning", "Empty stomach", "If required"];
@Component({
  selector: 'app-prescription-live-preview',
  templateUrl: './prescription-live-preview.component.html',
  styleUrls: ['./prescription-live-preview.component.css']
})
export class PrescriptionLivePreviewComponent implements OnInit {

  // @Input() onClick : (arg)=>void;

  @Output() onDeleteMedicineFromPreview: EventEmitter<any> = new EventEmitter();
  @Input() public selectedComplaints: string;
  @Input() public selectedDiagnosis: string;
  @Input() public allMedicines: any[];
  
  @Input() public set changedHtWt(input: any) {
    console.log("input obj", input);
    if (input) {
      this.weight = input.wt;
      this.height = input.ht;
    }

  }

  //@Input() public selectedDietaryAdvices : string;
  @Input() public set selectedDietaryAdvices(input: string) {
    console.log(" :) xxx :) : ): )", input);
    var abc = input
    console.log("typeOf",abc.length);
    if(input.length == 0 ){
      console.log("lenth of input iz zero ");
      this.langDietary.setTextBoxValue(input)
      
    }
   
    if (input) {
      setTimeout(() => {
        this.langDietary.setTextBoxValue(input)
      }, 500)
    }



    //set it to txt set

  }
  @Input() public set selectedGeneralInstructions(input: string) {
    if(input.length == 0 ){
      console.log("lenth of input iz zero ");
      this.langInstructions.setTextBoxValue(input)
      
    }
    if (input) {
      setTimeout(() => {

        this.langInstructions.setTextBoxValue(input)
      }, 500)
    }

  };
  @Input() public selectedOnExamination: string
  @Input() public nextFollowUp: string
  @Input() public editMode: boolean;
  @Input() callBack: (args: any) => void;

  @ViewChild("langFollowupMessage") langFollowupMessage: LanguageSelectTextboxComponent;
  @ViewChild('idChiefComplaints') idChiefComplaints: ElementRef;
  @ViewChild('langDietary') langDietary: LanguageSelectTextboxComponent
  @ViewChild('langInstructions') langInstructions: LanguageSelectTextboxComponent
  @ViewChild('langMedInstructions') langMedInstructions: LanguageSelectTextboxComponent
  @ViewChild("pendingVaccination_modal") pendingVaccination_modal




  patientId = null;
  todaysDate: any;
  patientData: any;
  ageObj: any = {};
  fullName: any = "";
  gender: any = "";
  dob: any = "";
  contactNo: any = "";
  showAge: any = "";
  age: any = "";
  patient: any = null;
  visits: any = null;
  vitalsArray: any = [];
  weight: any = "";
  height: any = "";
  doctor: any = {};
  userId: any
  patientSelectedLanguage: any
  otherId: any
  complaints: any = "";
  onExamination: any = "";
  diagnosis: any = "";
  dietaryAdvice: any = "";
  generalInstructions: any = "";
  selectedIndex: any = null;
  editFlag = false;
  birthweight = '';

  showOtherId: number
  editMedicineTextInstructions: any = "";
  editMedicineTextDuration: any = "";
  editMedicineText: any = "";
  editMedicineWhenToTake
  patientAllData: any;
  followUpMessageForLanguage: any = [];
  patientDataFromLocal
  pendingVaccination: any = [];
  vaccineList: any = [];
  upcomingVaccineList: any = [];
  displayedColumns: string[] = ['visit', 'vaccine', 'recommendedDate', 'catchupDate'];
  selectedVaccination: any = [];
  vaccinationFlag: boolean = false;
  vacDate: any







  constructor(
    private patientService: PatientService,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: MatDialog,
    private doctorService: DoctorService, private authService: AuthService,
    private refreshservice: refreshService
  ) {
    this.userId = this.authService.getUser();

  }

  ngOnInit(): void {

    this.getDoctorDetails();
    this.getAllRequiredConstants();



    //console.log("dietary",instructions)
    // let formattedMedicines = [];
    this.allMedicines.forEach(ins => {
      ins.editing = false;
      // if (ins.selected) {
      //   formattedMedicines.push(ins);
      // }
    });

    // this.allMedicines = formattedMedicines;

    this.doctor = JSON.parse(localStorage.getItem('docObj'));
    this.patientAllData = JSON.parse(sessionStorage.getItem("currentPreviewPrescriptionObject"));
    console.log("patientData currentPreviewPrescriptionObject", this.patientAllData)
    this.route.queryParams
      .subscribe(params => {
        this.patientId = params.patientId;
        this.editFlag = params.edit
        console.log("???", this.editFlag)

        this.getPatientInfo();
        this.getPatientsVaccination();
      }
      )

    this.getTodaysDate();
    this.patientData = JSON.parse(localStorage.getItem("patientData"));
    console.log('Patient', this.patientData);

    // this.doctor = localStorage.getItem("doctor");
    // console.log('Doctor', this.doctor);
    this.refreshservice.VitalsRefreshEvent.subscribe(valuesent => {
      console.log("GOT REFRESH EVENT");
      this.patientId = this.patientData.id
      this.getPatientInfo();
    });


  }

  onDateChangeForVacc(event) {
    this.vacDate = event.value
  }

  selectedRowIndex = -1;
  selectedRows(item) {
    this.selectedRowIndex = item.id;

  }
  getVaccData(element) {
    this.selectedVaccination.push(element)
    console.log("may26", this.selectedVaccination)

  }

  reverseDate(date) {
    let d = new Date(date);
    return { year: d.getFullYear(), month: d.getMonth() + 1, day: d.getDate() };
  }
  parseDate(dateObject) {
    var date = new Date();
    date.setDate(dateObject.day);
    date.setMonth(dateObject.month - 1);
    date.setFullYear(dateObject.year);
    return moment(date).format("YYYY-MM-DD HH:mm:ss");
  }

  savePendingVaccination() {
    this.vaccinationFlag = true
    var vaccinationName: any = ""
    var data = this.langInstructions.getValue();
    console.log("apr4", data)
    if (this.vacDate != undefined) {
      var vaccDate: any = this.reverseDate(this.vacDate);
      vaccDate = moment(new Date(this.parseDate(vaccDate))).format("DD/MM/YYYY");
      this.langInstructions.appendTexttoBox(vaccDate + " " + "-" + " ")
      this.selectedVaccination.map(ins => {
        if (ins.name) {
          this.langInstructions.appendTexttoBox(' ' + " " + ins.name + "");

        }
      })

      // this.getLangTextBoxData();
      // setTimeout(() => {

      //   this.openDialog(this.followup_modal, 'followup_modal')
      // }, 500)

    }

  }


  isJson(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  }


  getPatientsVaccination = () => {

    // let patientMoreData: any = JSON.parse(localStorage.getItem("patientMoreData"));
    // let joiningDate = moment(patientMoreData.createdAt).format('DD MMM, YYYY')
    this.patientDataFromLocal = JSON.parse(localStorage.getItem("patientData"));
    console.log("patientDataFromLocal",this.patientDataFromLocal);
    var patientId = this.patientDataFromLocal.id
    this.patientService.getPatientsVaccination(this.patientId? this.patientId :patientId).subscribe(response => {

      let vaccineList = []
      if (response) {
        vaccineList = response;
        for (let i = 0; i < vaccineList.length; i++) {
          if (this.isJson(vaccineList[i].name)) {
            let scheduleNames = JSON.parse(vaccineList[i].name);
            if (scheduleNames && scheduleNames.length > 0) {
              scheduleNames.forEach(langpart => {
                if (langpart.lang == 'en') {
                  vaccineList[i].name = langpart.text;
                }
              });
            }
          }
        }

        console.log('VACCCC', vaccineList)

        let vaccineArray = [];
        let newArray = [];
        let tempArr = [];
        let administeredVaccines = [];


        vaccineList.map(ins => {
          ins.data.map((item, idx) => {
            if (!tempArr.includes(ins.name)) {
              tempArr.push(ins.name)
              item['vaccineVisit'] = ins.name;
            } else {
              item['vaccineVisit'] = "";
            }

            item['visitName'] = ins.name;
            item['index'] = idx;
            item['catchupDate'] = item.catchUpPeriod && item.catchUpPeriod != "" && item.dueDate && item.dueDate != "" ? moment(item.dueDate).add(item.catchUpPeriod, 'w') : 'NA';
            item['catchupDate'] = item.catchupDate == 'NA' ? 'NA' : item.catchupDate.format('DD-MM-YYYY');

            //overdue logic
            if (item.catchupDate && item.catchupDate != "NA") {
              var todayDate: any = moment().format('YYYY-MM-DD');
              var pastDate: any = moment(item.catchupDate, 'DD-MM-YYYY').format('YYYY-MM-DD');
              var bool = moment(todayDate).isAfter(moment(pastDate))
              if (bool && !item.administeredStatus) {
                item['isOverdue'] = true;
              }
            }

            //all administeredVaccines for today
            if (item.administeredStatus && moment().isSame(item.administeredDate, 'day')) {
              administeredVaccines.push(item.name);
            }

            if (moment(item.dueDate).isBefore(moment())) {
              item['isPending'] = true
            }
            vaccineArray.push(item);
          });

        });

        // this.unformattedVaccineList = vaccineList;
        vaccineList = newArray.map((ins, index) => { return { name: ins.name, selected: false, id: index } });
        vaccineList = vaccineArray;
        this.vaccineList = vaccineList
        console.log('Administered Vaccines', administeredVaccines);
        console.log('Vaccine List', vaccineList);

        // if (administeredVaccines.length) {

        //   this.selectedGeneralInstructions.map((ins, ind) => {
        //     if (ins.includes('Vaccines given')) {
        //       this.selectedGeneralInstructions.splice(ind, 1)
        //     }
        //   });

        //   this.selectedGeneralInstructions.push('Vaccines given - ' + administeredVaccines.join(', '))

        // }

      }
    });
  }


  getAllRequiredConstants = () => {

    this.patientService.getAllRequiredConstants(this.patientSelectedLanguage).subscribe(response => {
      if (response) {

        this.followUpMessageForLanguage = response.data.nextFollowupMessages;

      }
    })
  }

  selectFollowUpMessage(event) {

    // aaleenm
    var followupmessage = this.langInstructions.getValue()
    if (followupmessage == event.value) {

    }
    else {
      this.langInstructions.appendTexttoBox(event.value)

    }


    if (event.value == "Next Vaccination due:") {
      this.vaccineList.map(ins => {
        if (ins.isOverdue) {
          this.pendingVaccination.push(ins)
        }
        else if (!ins.administeredStatus) {
          this.upcomingVaccineList.push(ins)
        }
      })
      this.pendingVaccination = this.pendingVaccination.reduce((unique, o) => {
        if (!unique.some(obj => obj.name == o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);
      this.upcomingVaccineList = this.upcomingVaccineList.reduce((unique, o) => {
        if (!unique.some(obj => obj.name == o.name)) {
          unique.push(o);
        }
        return unique;
      }, []);


      this.openDialog(this.pendingVaccination_modal, 'pendingVaccination_modal');

    }


  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.allMedicines, event.previousIndex, event.currentIndex);
  }

  addAnotherMedicine = () => {
    // sessionStorage.setItem('patientData', JSON.stringify(this.patientData));
    this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientId, backToEdit: true } });
  }


  closeDialog() {
    this.dialog.closeAll()
  }


  deleteAction() {



    // alert(this.selectedIndex)
    // here
    let obj = this.allMedicines[this.selectedIndex];

    this.onDeleteMedicineFromPreview.emit(obj.Drug.id);

    this.allMedicines.splice(this.selectedIndex, 1);

    sessionStorage.setItem("qrx_allMedicines", JSON.stringify(this.allMedicines))
    this.dialog.closeAll()
  }


  //    setCaret() {
  //     var mainDiv = document.getElementById("mru");
  // var startNode = mainDiv.firstChild.firstChild;
  // var endNode = mainDiv.childNodes[2].firstChild;

  // var range = document.createRange();
  // range.setStart(startNode, 6); // 6 is the offset of "world" within "Hello world"
  // range.setEnd(endNode, 7); // 7 is the length of "this is"
  // var sel = window.getSelection();
  // sel.removeAllRanges();
  // sel.addRange(range);
  // }
  onInput(key, value) {

  }

  openDialog(content, index) {

    this.selectedIndex = index;
    this.dialog.open(content);
  }



  getDoctorDetails = () => {
    let params = {
      user_id: this.userId
    }
    this.doctorService.getDetails(params).subscribe(response => {
      console.log('Doctor Response', response.doctor)
      //  this.currentDoctorObj = response.doctor
      if (response.success) {
        if (response.doctor.services && response.doctor.services !== null) {
          var services = JSON.parse(response.doctor.services);
          console.log("services", services)
          // this.selectedTemplate=JSON.parse(response.doctor.ser)
          this.showOtherId = services && services.showAnotherId ? services.showAnotherId : ''


        }
        else {

        }
      }
    })
  }

  getPatientInfo = () => {
    this.patientDataFromLocal = JSON.parse(localStorage.getItem("patientData"));
    var patientID = this.patientDataFromLocal.id
    this.patientService.getPatientInfo(this.patientId? this.patientId:patientID).subscribe(response => {
      if (response) {
        console.log("Response Patients backto edit", response);

        this.fullName = response.firstName + " " + response.lastName;
        this.gender = response.gender.toLowerCase() == 'male' ? 'M' : 'F';
        this.patientSelectedLanguage = response.parent.selectedLanguage

        //set language of text bioxes
        this.langDietary.setLanguage(this.patientSelectedLanguage);
        this.langInstructions.setLanguage(this.patientSelectedLanguage);



        this.dob = response.DOB
        this.ageObj = response.age;
        this.contactNo = response.contactNo;
        this.otherId = response.otherIdNo
        if (this.ageObj.years < 12) {
          // this.ageObj.years="Under 1 Year";
          this.showAge = true;
        }
        else {
          this.ageObj.years;
          this.showAge = false;
        }
        this.age = moment().diff(this.dob, 'years');

        this.patient = response;
        var latesVisit: any = []
        this.visits = response.visits && response.visits.length ? response.visits : [];

        if (this.visits && this.visits.length > 0) {
          this.visits.forEach(v => {
            if (v.addedBy == 'birth') {
              this.birthweight = v.weight;
            }
          })
        }

        this.visits.map((element, index) => {
          if (element._id === "synthetic_record") {
            this.visits.splice(index, 1);
          }


        });


        this.getPatientAgeCategory();
      } else {
        alert('Something went wrong')
      }
    });

  }


  getPatientAgeCategory = () => {
    //Pediatrics - Age till 3 Years
    // Weight Height HC CC

    //Pediatrics - Age > 3 Years and less than 18
    // Weight Height BMI BP(sys) BP(dys)

    //GP - Adult
    //Weight Height BMI BP(sys) BP(dys) Blood Sugar(Fasting) Blood Sugar(PP)
    let visits: any = []
    this.visits.map((element, index) => {
      if (element._id === "synthetic_record") {
        this.visits.splice(index, 1);
      }
      if (new Date(element.date).getDate() == new Date().getDate() && new Date(element.date).getMonth() == new Date().getMonth() && new Date(element.date).getFullYear() == new Date().getFullYear()) {
        visits = element

      }
      // else{
      //   visits = this.visits && this.visits.length ? this.visits[this.visits.length - 1] : [];
      // }
    })

    console.log("june21", visits)
    let vitalsArray = this.vitalsArray;

    let weight = visits.weight && visits.weight != "null" ? visits.weight : 'N/A';
    let height = visits.height && visits.height != "null" ? visits.height : 'N/A';

    this.weight = weight;
    this.height = height;

    let hc = visits.hc && visits.hc != "null" ? visits.hc : 'N/A';
    let cc = visits.cc && visits.cc != "null" ? visits.cc : 'N/A';
    let bmi_calc = (visits.weight / visits.height / visits.height) * 10000;
    let num = Number(bmi_calc).toFixed(2);
    let bmi = visits.weight && visits.weight && visits.height && visits.height != "null" && !isNaN(bmi_calc) ? num : 'N/A';
    if (!Number.isNaN(bmi)) {
      bmi = ''
    }
    else {
      bmi;
    }
    let bpSys = visits.bpSys && visits.bpSys != "null" ? visits.bpSys : 'N/A';
    let bpDia = visits.bpDia && visits.bpDia != "null" ? visits.bpDia : 'N/A';
    let Bloodsugar_F = visits.Bloodsugar_F && visits.Bloodsugar_F != "null" ? visits.Bloodsugar_F : 'N/A';
    let Bloodsugar_PP = visits.Bloodsugar_PP && visits.Bloodsugar_PP != "null" ? visits.Bloodsugar_PP : 'N/A';



    if (this.age < 4) {
      vitalsArray.push({ value: weight, name: 'Weight', unit: 'kg' });
      vitalsArray.push({ value: height, name: 'Height', unit: 'cm' })
      vitalsArray.push({ value: hc, name: 'HC', unit: 'cm' })
      vitalsArray.push({ value: cc, name: 'CC', unit: 'cm' })

    } else if (this.age < 18) {
      vitalsArray.push({ value: weight, name: 'Weight', unit: 'kg' });
      vitalsArray.push({ value: height, name: 'Height', unit: 'cm' })
      vitalsArray.push({ value: bmi, name: 'BMI', unit: '' });


      vitalsArray.push({ value: bpSys, name: 'BP(Sys)', unit: '' })
      vitalsArray.push({ value: bpDia, name: 'BP(Dia)', unit: '' })
    } else {
      vitalsArray.push({ value: weight, name: 'Weight', unit: 'kg' });
      vitalsArray.push({ value: height, name: 'Height', unit: 'cm' })
      vitalsArray.push({ value: bmi, name: 'BMI', unit: '' })
      vitalsArray.push({ value: bpSys, name: 'BP(Sys)', unit: '' })
      vitalsArray.push({ value: bpDia, name: 'Bp(Dia)', unit: '' })
      vitalsArray.push({ value: Bloodsugar_F, name: 'Blood Sugar F', unit: '' })
      vitalsArray.push({ value: Bloodsugar_PP, name: 'Blood Sugar PP', unit: '' })
    }

    //console.log("AGE", this.age)
    //console.log("Vitals Array", vitalsArray)
  }

  getTodaysDate() {
    var today = new Date();
    var dd = String(today.getDate()).padStart(2, '0');
    var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = today.getFullYear();
    this.todaysDate = dd + '/' + mm + '/' + yyyy;
  }


  geteditedValues() {



    var selectedDietaryAdvices = this.langDietary.getValue();
    var selectedGeneralInstructions = this.langInstructions.getValue()
    let editedValues = {
      'selectedComplaints': this.selectedComplaints,
      'selectedOnExamination': this.selectedOnExamination,
      'selectedDiagnosis': this.selectedDiagnosis,

      'selectedDietaryAdvices': selectedDietaryAdvices,
      'selectedGeneralInstructions': selectedGeneralInstructions,
      'nextFollowUp': this.nextFollowUp,

    }

    return editedValues;
  }

  updatePrescription = () => {

    let obj = {
      'selectedComplaints': this.selectedComplaints,
      'selectedOnExamination': this.selectedOnExamination,
      'selectedDiagnosis': this.selectedDiagnosis,

      'selectedDietaryAdvices': this.selectedDietaryAdvices,
      'selectedGeneralInstructions': this.selectedGeneralInstructions,
      'nextFollowUp': this.nextFollowUp,
    }

    this.callBack(obj);
  }
  backToEdit() {
    localStorage.setItem('patientData', JSON.stringify(this.patientData));
    this.router.navigate([`/doctor/patients/quickprescription/view`], { queryParams: { patientId: this.patientData.id } });

  }
  editMedicineDose = (index, event) => {
    //console.log(index, event);

    this.allMedicines.forEach(ins => {
      ins.editing = false;
    })

    setTimeout(function () {
      var div = document.getElementById('editableMedicineDiv');
      div.focus();
    }, 500);

    this.allMedicines[index].editing = true;

    this.editMedicineText = this.allMedicines[index].dosage2
    this.editMedicineWhenToTake = this.allMedicines[index].whenToTake
    this.editMedicineTextDuration = this.allMedicines[index].duration2;
    this.editMedicineTextInstructions = this.allMedicines[index].instructions
    setTimeout(() => {
      this.langMedInstructions.setTextBoxValue(this.editMedicineTextInstructions);
    }, 500)


  }


  updateMedicineDose(index, $event) {
    // COMMENT
    this.allMedicines[index].dosage = this.editMedicineText;
    this.allMedicines[index].dosage2 = this.editMedicineText;
    this.allMedicines[index].whenToTake = this.editMedicineWhenToTake;
    this.allMedicines[index].duration2 = this.editMedicineTextDuration;
    this.editMedicineTextInstructions = this.langMedInstructions.getValue()
    this.allMedicines[index].instructions = this.editMedicineTextInstructions;

    if (this.allMedicines[index].editing == true) {
      this.allMedicines[index].editing = false;

    } else {
      this.allMedicines[index].editing = true;
    }

    sessionStorage.setItem("qrx_allMedicines", JSON.stringify(this.allMedicines))


  }



}