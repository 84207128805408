<div
    style="background-color:#ffffff; display:flex; height:8% !important; width:100%; border-bottom: 1px solid #eee; justify-content: space-between; align-items: center;">

    <div style="display: flex; flex-direction: row; width: 100%; height: 100%; align-items: center;">
        <!-- Search Bar -->
        <div style="font-size: 18px; color: #333; font-weight: bold; margin-left: 30px;">{{labName}}</div>

         <div style="font-size: 16px; color: rgb(139, 136, 136); margin-left: 20px; font-weight: bold; ">{{labAddress}}</div>
    </div>

</div>