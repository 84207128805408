import { Component, OnInit, OnDestroy, ViewChild,  OnChanges,HostListener, NgZone, AfterViewInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from '../../services/auth.service'
import { AdminMenuItems } from '../../shared/menu-items/menu-items';
import { Subscription } from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
//import { TranslateService } from '@ngx-translate/core';
import * as $ from 'jquery'

const SMALL_WIDTH_BREAKPOINT = 991;

export interface Options {
  heading?: string;
  removeFooter?: boolean;
  mapHeader?: boolean;
}

@Component({
  selector: 'app-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, OnDestroy, AfterViewInit,  OnChanges {

  private _router: Subscription;
  private mediaMatcher: MediaQueryList = matchMedia(`(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`);
  currentUrl = []
  currentLang = 'en';
  options: Options;
  theme = 'light';
  showSettings = false;
  isDocked = false;
  isBoxed = false;
  isOpened = true;
  mode = 'push';
  _mode = this.mode;
  _autoCollapseWidth = 991;
  width = window.innerWidth;
  topHeading: string = "LET'S GET STARTED"
  path: string = ""
  doctor: string = "Dr. Rashmi Gapchup"
  headingArray: any = []
  closeResult: string;

  todaysDate: number
  mainMenu: string = ""
  @ViewChild('sidebar') sidebar;

  constructor (
    public menuItems: AdminMenuItems,
    private router: Router,
    private route: ActivatedRoute,
    //public translate: TranslateService,
    private modalService: NgbModal,
    private titleService: Title,
    private authService: AuthService,
    private zone: NgZone) {
    // const browserLang: string = translate.getBrowserLang();
    // translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    // this.mediaMatcher.addListener(mql => zone.run(() => this.mediaMatcher = mql));
  }

  ngOnInit(): void {
    this.todaysDate = Date.now();
    if (this.isOver()) {
      this._mode = 'push';
      this.isOpened = false;
    }

    const urlArray = location.href.split('/')
    this.path = urlArray[urlArray.length - 1].toLowerCase()
   // this.topHeading = this.headingArray[this.path]
    this.getTopHeading()
    this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
      // Scroll to top on view load
      let currentUrl = event.url.split('/')
      this.currentUrl = currentUrl
      this.path = currentUrl[currentUrl.length - 1].toLowerCase()
      //this.topHeading = this.headingArray[this.path]
      this.getTopHeading()
      document.querySelector('.main-content').scrollTop = 0;
      this.runOnRouteChange();
    });
  }

  ngAfterViewInit(): void  {
    setTimeout(_ => this.runOnRouteChange());
  }

  ngOnDestroy() {
    this._router.unsubscribe();
  }

  ngOnChanges(){
    setTimeout(_ => this.runOnRouteChange());
  }

  goToMarktPlace(){
    
    this.router.navigate([`/admin/listedProducts`], { queryParams: {} });

  }
  gToMarktPlace(){
    this.router.navigate([`/admin/marketPlaceData`], { queryParams: {} });

  }
  runOnRouteChange(): void {
    if (this.isOver() || this.router.url === '/maps/fullscreen') {
      this.isOpened = false;
    }
    let currentUrl = this.router.url.split('/')
    this.currentUrl = currentUrl
    this.path = currentUrl.slice(-1)[0].toLowerCase()
    this.mainMenu = currentUrl[2] ? currentUrl[2].toLowerCase() : null
 
    this.getTopHeading()
    // alert(this.topHeading)
    this.route.children.forEach((route: ActivatedRoute) => {
      let activeRoute: ActivatedRoute = route;
      while (activeRoute.firstChild) {
        activeRoute = activeRoute.firstChild;
      }
      this.options = activeRoute.snapshot.data;
    });

    if (this.options) {
      if (this.options.hasOwnProperty('heading')) {
        this.setTitle(this.options.heading);
      }
    }
  }

  getTopHeading(){
    console.log(this.currentUrl)
    if(this.currentUrl.indexOf('doctors') != -1){
      this.topHeading = "Doctor Management"
    }
    else if(this.currentUrl.indexOf('patients') != -1){
      this.topHeading = "Patient Management"
    }
    else if(this.currentUrl.indexOf('customers') != -1){
      this.topHeading = "Customer Management"
    }
    else if(this.currentUrl.indexOf('labs') != -1){
      this.topHeading = "Lab Management"
    } else if(this.currentUrl.indexOf('activities') != -1){
      this.topHeading = "Activity Log"
    }else{
      this.topHeading = ""
    }
  }

  setTitle( newTitle: string) {
    this.titleService.setTitle( 'Decima - Bootstrap 4 Angular Admin Template | ' + newTitle );
  }

  toogleSidebar(): void {
    this.isOpened = !this.isOpened;
  }

  isOver(): boolean {
    return window.matchMedia(`(max-width: 991px)`).matches;
  }

  
  openSearch(search) {
    this.modalService.open(search, { windowClass: 'search', backdrop: false });
  }
  open(content, customClass:any = '') {
    this.modalService.open(content, {size: customClass}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      // return 'by clicking on a backdrop dasdasdasdasdad';
    } else {
      // return  `with: ${reason}`;
    }
  }
  addLabelClass(e) {
    $(e.target.parentElement.children[1]).addClass('animatedLabel')
  }
  removeLabelClass(e){
    if(e.target.value == '') {
      $(e.target.parentElement.children[1]).removeClass('animatedLabel')
    }
  }

  logout(){
   this.authService.deleteAdminToken(0)
   //this.router.navigate(['/admin/login'])
  }

}
